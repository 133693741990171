export const queryKeys = {
    auth: 'auth',
    roles: 'roles',
    designations: 'designations',
    departments: 'departments',
    users: 'users',
    entities: 'entities',
    tests: 'tests',
    services: 'services',
    metadata: 'metadata',
    publicMetaData: 'publicMetaData',
    searchPatients: 'searchPatients',
    patients: 'patients',
    opds: 'opds',
    employees: 'employees',
    labTests: 'labTests',
    labTest: 'labTest',
    testList: 'testList',
    testID: 'testID',
    reports: 'reports',
    investigationID: 'investigationID',
    permissions: 'permissions',
    dashboardDatas: 'dashboardDatas',
    user: 'user',
    patientOPD: 'patientOPD',
    patientProfile: 'patientProfile',
    patientLabInvestigations: 'patientLabInvestigations',
    patientLabTest: 'patientLabTest',
    clinic: 'clinic',
    opdFile: 'opdFile-',
    floors: 'floors',
    rooms: 'rooms',
    beds: 'beds',
    ipds: 'ipds',
    ipd: 'ipd-',
    ipdServices: 'ipdServices',
    ipdService: 'ipdService',
    labInventory: 'labInventory',
    otServices: 'otServices',
    otService: 'otService-',
    ipdOT: 'ipdOT',
    ipdDeposit: 'ipdDeposit',
    ipdRefund: 'ipdRefund',
    minotServices: 'minotServices',
    expenses: 'expenses',
    payouts: 'payouts',
    ipdBeds: 'ipdBeds',
    summaries: 'summaries',
    summary: 'summary',
    bill: 'bill',
    finalbill: 'finalbill',
    analytics: 'analytics',
    analytic: 'analytic',
    mot: 'mot',
    doctorCollections: 'doctorCollections',
    serviceReport: 'serviceReport',
    labReports: 'labReports',
    labInventories: 'labInventories',
}