import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { addIPDOT, deleteIPDOT, getIPDOT, updateIPDOT } from "../../../services/api";

export function useGetIPDOT(otID) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.ipdOT],
        queryFn: () => getIPDOT(otID),
        enabled: otID ? true : false,
    });

    return { ipdOTData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

export function useAddIPDOT() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: ({ otID, body }) => addIPDOT({ otID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdOT] });
            if (data?.success) {
                showNotification("success", `IPD-OT service added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addIPDOT: mutate, isLoading };
}

export function useUpdateIPDOT() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ otID, body }) => updateIPDOT({ otID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdOT] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { updateIPDOT: mutate, isLoading };
}

export function useDeleteIPDOT() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (otID) => deleteIPDOT(otID),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdOT] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteIPDOT: mutate, isLoading };
}
