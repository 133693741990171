import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineReload,
} from "react-icons/ai";
// Import the custom hooks you created
import Typography from "@mui/material/Typography";
import { Pagination, TextField, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { getPermissionsByModule, showNotification } from "../services/helper";
import LoaderLogo from "./common/LoaderLogo";
import { IoMdAdd } from "react-icons/io";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import ReusableButton from "./common/ReusableButton";
import RedButton from "./common/RedButton";
import {
  useAddMOTService,
  useDeleteMOTService,
  useEditMOTService,
  useGetMOTServices,
} from "../query/hooks/minotServices/minorOTServices";
import {
  clearMot,
  selectMotParams,
  setMotParams,
} from "../redux/features/filters/motFilterSlice";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const columns = [
  { id: "id", label: "ID", minWidth: 10, align: "center" },
  { id: "name", label: "Name", minWidth: 100, align: "center" },
  { id: "charge", label: "Charge", minWidth: 100, align: "center" },
  { id: "created_on", label: "Created Date", minWidth: 100, align: "center" },
  { id: "actions", label: "Actions", align: "center" },
];

const initial = {
  name: "",
  charge: 0,
};

const MinotOTServices = () => {
  const dispatch = useDispatch();

  const currentAuth = useSelector(selectCurrentAuth);
  const permissionsList = currentAuth?.user?.permissions;
  const ipdsPermissions = getPermissionsByModule(1008, permissionsList);
  const { add_permission, update_permission, delete_permission } =
    ipdsPermissions;
  const params = useSelector(selectMotParams);

  const {
    servicesData,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
    isRefetching,
  } = useGetMOTServices(params);
  const { addMOTService, isLoading: isAdding } = useAddMOTService();
  const { editMOTService, isLoading: isUpdating } = useEditMOTService();
  const { deleteMOTService, isLoading: isDeleting } = useDeleteMOTService();

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editedService, setEditedService] = useState({});
  const [toDelete, setToDelete] = useState("");
  const [service, setService] = useState({ ...initial });
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataToRender, setDataToRender] = useState([]);
  const [page, setPage] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setService((prevService) => ({
      ...prevService,
      [name]: name === "charge" ? parseFloat(value) || 0 : value,
    }));
  };

  const validateService = (service) => {
    const errors = {};

    if (!service.name || service.name.trim() === "") {
      errors.name = "Service name is required";
    }

    if (isNaN(service.charge) || service.charge <= 0) {
      errors.charge = "Invalid charge amount";
    }

    return errors;
  };

  const handleAdd = () => {
    const errors = validateService(service);

    if (Object.keys(errors).length > 0) {
      if (errors.name) {
        showNotification("error", errors.name);
      }
      if (errors.charge) {
        showNotification("error", errors.charge);
      }
    } else {
      addMOTService(service);
      if (!isLoading) {
        setService({ ...initial });
      }
      handleClose();
    }
  };

  const handleCancel = () => {
    setService({ ...initial });
    handleClose();
  };

  const handleEditClick = (row) => {
    setEditedService(row);
    setEditOpen(true);
  };

  const handleEditSave = () => {
    const updatedTest = {
      id: editedService.id,
      name: editedService.name,
      charge: parseFloat(editedService.charge) || 0,
    };

    editMOTService(updatedTest);
    setEditOpen(false);
  };

  const handleOpenDeleteDialog = (row) => {
    setOpenDeleteDialog(true);
    setToDelete(row);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmed = () => {
    deleteMOTService(toDelete);
    handleCloseDeleteDialog();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    dispatch(
      setMotParams({
        ...params,
        offset: (value - 1) * params.limit,
      })
    );
  };

  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };

  const handleOptionChange = (event, newValue) => {
    dispatch(
      setMotParams({
        ...params,
        name: newValue.name,
      })
    );
    setSelectedOption(newValue);
  };

  const handleClear = () => {
    setSelectedOption(null);
    setInputValue("");
    dispatch(clearMot());
  };

  return (
    <section>
      <div className="">
        <div className="flex justify-between px-10 m-4">
          <div>
            <div className="flex items-center space-x-3">
              <Typography variant="h5">Minor OT Services</Typography>

              <Tooltip title="Refresh" placement="bottom-start">
                <button
                  onClick={refetch}
                  className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                  style={{ cursor: "pointer" }}
                >
                  <AiOutlineReload size={20} />
                </button>
              </Tooltip>
            </div>
          </div>
          {/* filter&Search */}
          <div className="flex items-center">
            <div className="w-[100%] ml-4">
              <div className="">
                <Autocomplete
                  className="w-[350px]"
                  options={servicesData?.result?.list || []}
                  getOptionLabel={(option) => option.name}
                  value={selectedOption}
                  onChange={handleOptionChange}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  disableClearable
                  isOptionEqualToValue={(option, value) =>
                    option.name === value?.name
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Service"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {selectedOption && (
                              <ClearIcon
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={handleClear}
                              />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          {add_permission === 1 && (
            <Tooltip title="Add Service" placement="bottom-start">
              <button
                onClick={handleOpen}
                className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
              >
                <IoMdAdd size={18} />
              </button>
            </Tooltip>
          )}
        </div>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "15px",
          }}
          elevation={24}
        >
          <TableContainer
            sx={{
              height: 520,
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                width: 0,
              },
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bold",
                        background: "#eeeeee",
                        textTransform: "uppercase",
                        backgroundColor: "#27272A",
                        color: "white",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {servicesData?.result?.list.map((row) => {
                  return (
                    <React.Fragment key={row.id}>
                      {servicesData?.result?.list ? (
                        <TableRow hover role="checkbox" align="center">
                          <TableCell align="center">{row.id}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">₹ {row.charge}</TableCell>
                          <TableCell align="center">
                            {dayjs(row.created_on).format(
                              "DD-MM-YYYY,h:mm:ss A"
                            )}
                          </TableCell>
                          <TableCell align="center" sx={{ width: 120 }}>
                            <div className="flex items-center justify-center space-x-4">
                              {update_permission === 1 && (
                                <Tooltip title="Edit" placement="bottom-start">
                                  <button
                                    onClick={() => handleEditClick(row)}
                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                  >
                                    <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                  </button>
                                </Tooltip>
                              )}
                              {delete_permission === 1 && (
                                <Tooltip
                                  title="Delete"
                                  placement="bottom-start"
                                >
                                  <button
                                    onClick={() =>
                                      handleOpenDeleteDialog(row.id)
                                    }
                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                  >
                                    <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                  </button>
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            align="center"
                            sx={{ width: 120 }}
                          >
                            <div className="flex items-center justify-center">
                              <LoaderLogo />
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {!isLoading && !isFetching && !isRefetching && (
            <div className="flex items-center justify-center my-4">
              <Pagination
                count={Math.ceil(servicesData?.result?.count / params.limit)}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  "& .MuiSelect-select": {
                    display: "none !important",
                  },
                  "& > div.MuiToolbar-root > div.MuiInputBase-root > svg": {
                    display: "none !important",
                  },
                }}
              />
            </div>
          )}
        </Paper>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                marginBottom: 2,
              }}
            >
              Add New Minor OT Service
            </Typography>

            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={service.name}
              onChange={handleChange}
            />
            <TextField
              required
              margin="dense"
              id="charge"
              name="charge"
              label="Charge"
              type="text"
              fullWidth
              value={service.charge}
              onChange={handleChange}
            />

            <div className="flex justify-between p-6">
              <ReusableButton
                isDisabled={isAdding}
                onClick={handleAdd}
                title="Save"
              />

              <RedButton
                isDisabled={isAdding}
                onClick={handleCancel}
                title="Cancel"
              />
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editOpen}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                marginBottom: 2,
              }}
            >
              Edit Minor OT Service
            </Typography>

            <TextField
              required
              autoFocus
              margin="dense"
              id="editedRole"
              label="Edit MOT"
              type="text"
              fullWidth
              value={editedService.name} // Set the initial value to the role name
              onChange={(e) =>
                setEditedService({
                  ...editedService,
                  name: e.target.value,
                })
              }
            />
            <TextField
              required
              autoFocus
              margin="dense"
              id="editedRole"
              label=" Charge"
              type="text"
              fullWidth
              value={editedService.charge} // Set the initial value to the role name
              onChange={(e) =>
                setEditedService({
                  ...editedService,
                  charge: e.target.value,
                })
              }
            />

            <div className="flex justify-between p-6">
              <ReusableButton
                isDisabled={isUpdating}
                onClick={handleEditSave}
                title="Save"
              />

              <RedButton
                isDisabled={isUpdating}
                onClick={() => setEditOpen(false)}
                title="Cancel"
              />
            </div>
          </Box>
        </Fade>
      </Modal>

      <CommonDeleteModal
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onCancel={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmed}
        isDeleting={isDeleting}
      />
    </section>
  );
};

export default MinotOTServices;
