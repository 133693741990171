import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    defaultParams: {
        name: "",
        offset: 0,
        limit: 10
    },
};

const otsServiceSlice = createSlice({
    name: "otsServices",
    initialState,
    reducers: {
        setMotParams: (state, action) => {
            state.defaultParams = action.payload;
        },
        clearMot: (state) => {
            return initialState;
        },
    },
    extraReducers: {},
});

export const selectOtServicesParams = (state) => state.otsServices.defaultParams;

export const { setOtsParams, clearOts } = otsServiceSlice.actions;
export default otsServiceSlice.reducer;
