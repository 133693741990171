import React, { useEffect, useState } from "react";
// import Sidebar from "../components/Sidebar";
import Sidebar from "../components/common/Sidebar2";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Departments from "../components/Departments";
import Roles from "../components/Roles";
import Designation from "../components/Designation";
import Tests from "../components/Tests";
import Services from "../components/Services";
import PageNotFound from "./PageNotFound";
import Dashboard from "../components/Dashboard";
import Users from "../components/Users";
import Patients from "../components/Patients";
import OpdBooking from "../components/OpdBooking";
import TestBooking from "../components/TestBooking";
import Employee from "../components/Employee";
import { useDispatch, useSelector } from "react-redux";
import {
    selectCurrentAuth,
    selectCurrentUser,
    setCurrentUser,
} from "../redux/features/auth/authSlice";
import Profile from "../components/Profile";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import Logout from "@mui/icons-material/Logout";

import { useLogout } from "../query/hooks/auth/authHook";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import ClinicProfile from "../components/ClinicProfile";
import PasswordChange from "../components/PasswordChange";
import {
    clearLocalStorage,
    getItem,
    getPermissionsByModule,
    showNotification,
} from "../services/helper";
import Room from "../components/Room";
import Floors from "../components/Floors";
import Roomtype from "../components/Roomtype";
import Inventory from "../components/Inventory";
import { AccountCircle } from "@mui/icons-material";
import IPD from "../components/common/IPD";
import Analytics from "../components/Analytics";
import IPDInfo from "../components/common/IPDInfo";
import InventoryCategories from "../components/InventoryCategories";
import Beds from "../components/Beds";
import OtServices from "../components/OtServices";
import MinotOTServices from "../components/MinotOTServices";
import MinorOT from "../components/MinorOT";
import PayOuts from "../components/PayOuts";
import DailyExpenses from "../components/DailyExpenses";
import DischargeSummary from "../components/common/TabViews/DischargeSummary";
import IPDBills from "../components/IPDBills";
import AccountReports from "../components/AccountReports";
import PtHistory from "../components/PtHistory";
import { getUser, skynet } from "../services/api";
import { useGetCurrentUser } from "../query/hooks/users/usersHook";

// import Dashboard from "../components/Dashboard";

const AdminPanel = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);
    const { mutate: logout, isSuccess } = useLogout();
    const [openDialog, setOpenDialog] = useState(false);
    const [user, setUser] = useState(null);
    const [userID, setUserID] = useState(null);
    const { currentUserData, refetch, isLoading } = useGetCurrentUser();

    useEffect(() => {
        if (!currentUser) {
            const user = getItem("user");
            const fetchUserData = async () => {
                if (user) {
                    try {
                        const res = await getUser(user.id);
                        if (res.success) {
                            setUser(res.result);
                            dispatch(setCurrentUser(res.result));
                        } else {
                            showNotification("warning", "User Not Found");
                        }
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    // show.log("No user found in storage");
                }
            };
            fetchUserData();
        } else {
            setUser(currentUser);
        }
    }, [currentUser]);

    const navigate = useNavigate();
    const currentAuth = useSelector(selectCurrentAuth);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const hanldeNavProfile = () => {
        setAnchorEl(null);
        navigate("/admin/profile");
    };
    const hanldePwChange = () => {
        setAnchorEl(null);
        navigate("/admin/changepassword");
    };

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleLogout = () => {
        const user = getItem("user");
        if (user) {
            logout(user.token);
            clearLocalStorage("user");
            if (isSuccess) {
                handleCloseDialog();
                navigate("/");
            }
        } else {
            clearLocalStorage("user");
        }
    };
    const permissionsList = currentAuth?.user?.permissions;
    const dashboardPermissions = getPermissionsByModule(1001, permissionsList);

    const departmentsPermissions = getPermissionsByModule(
        1002,
        permissionsList
    );
    const rolesPermissions = getPermissionsByModule(1003, permissionsList);
    const designationsPermissions = getPermissionsByModule(
        1004,
        permissionsList
    );
    const employeesPermissions = getPermissionsByModule(1005, permissionsList);
    const testsPermissions = getPermissionsByModule(1006, permissionsList);
    const usersPermissions = getPermissionsByModule(1007, permissionsList);
    const servicesPermissions = getPermissionsByModule(1008, permissionsList);
    const patientsPermissions = getPermissionsByModule(1009, permissionsList);
    const opdsPermissions = getPermissionsByModule(1010, permissionsList);
    const labTestsPermissions = getPermissionsByModule(1011, permissionsList);
    const entityProfilePermissions = getPermissionsByModule(
        1012,
        permissionsList
    );
    const floorsPermissions = getPermissionsByModule(1013, permissionsList);
    const roomsPermissions = getPermissionsByModule(1014, permissionsList);
    const bedsPermissions = getPermissionsByModule(1015, permissionsList);
    const ipdsPermissions = getPermissionsByModule(1016, permissionsList);

    const otsPermissions = getPermissionsByModule(1017, permissionsList);
    const paymentsPermissions = getPermissionsByModule(1018, permissionsList);
    const expensesPermissions = getPermissionsByModule(1019, permissionsList);
    const motservicesPermissions = getPermissionsByModule(
        1020,
        permissionsList
    );
    const motsPermissions = getPermissionsByModule(1021, permissionsList);
    const analyticsPermissions = getPermissionsByModule(1022, permissionsList);
    const billsPermissions = getPermissionsByModule(1023, permissionsList);
    return (
        <div className="relative flex h-screen bg-white">
            <Sidebar />
            <section className="flex-1 w-full overflow-y-scroll bg-white ">
                <div className="sticky top-0 z-50 flex items-center justify-end w-full p-2 bg-white">
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                        >
                            {user && user?.image ? (
                                <Avatar
                                    alt="profile"
                                    src={`${skynet}/${user?.image}` || null}
                                    sx={{ width: 36, height: 36 }}
                                    className="object-contain"
                                />
                            ) : (
                                <Avatar
                                    sx={{ width: 36, height: 36 }}
                                    alt={user?.name
                                        .split(" ")
                                        .map((part) => part.charAt(0))
                                        .join("")}
                                >
                                    {user?.name
                                        .split(" ")
                                        .map((part) => part.charAt(0))
                                        .join("")}
                                </Avatar>
                            )}
                        </IconButton>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                width: 200,
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                        }}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                        }}
                    >
                        <MenuItem onClick={hanldeNavProfile}>Profile</MenuItem>

                        <MenuItem onClick={hanldePwChange}>
                            Change Password
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleClickOpen}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </div>
                <div className="px-5 pb-5 overflow-y-scroll">
                    <Routes>
                        <Route
                            index
                            element={
                                dashboardPermissions?.view_permission === 1 ? (
                                    <Dashboard />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="departments"
                            element={
                                departmentsPermissions?.view_permission ===
                                1 ? (
                                    <Departments />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="ipds"
                            element={
                                ipdsPermissions?.view_permission === 1 ? (
                                    <IPD />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="discharge"
                            element={
                                billsPermissions?.view_permission === 1 ? (
                                    <DischargeSummary />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="motservices"
                            element={
                                motservicesPermissions?.view_permission ===
                                1 ? (
                                    <MinotOTServices />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="expenses"
                            element={
                                expensesPermissions?.view_permission === 1 ? (
                                    <DailyExpenses />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="ipdbills"
                            element={
                                billsPermissions?.view_permission === 1 ? (
                                    <IPDBills />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="accounting"
                            element={
                                analyticsPermissions?.view_permission === 1 ? (
                                    <Analytics />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="ipds/:id"
                            element={
                                ipdsPermissions?.view_permission === 1 ? (
                                    <IPDInfo />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="otservices"
                            element={
                                otsPermissions?.view_permission === 1 ? (
                                    <OtServices />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="floors"
                            element={
                                floorsPermissions?.view_permission === 1 ? (
                                    <Floors />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />

                        <Route
                            path="rooms"
                            element={
                                roomsPermissions?.view_permission === 1 ? (
                                    <Room />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="inventory"
                            element={
                                departmentsPermissions?.view_permission ===
                                1 ? (
                                    <Inventory />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        {/* <Route
                            path="invcategory"
                            element={
                                departmentsPermissions?.view_permission === 1 ? (
                                    <InventoryCategories />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        /> */}
                        <Route
                            path="paymentshistory"
                            element={
                                paymentsPermissions?.view_permission === 1 ? (
                                    <PayOuts />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="accountreports"
                            element={
                                analyticsPermissions?.view_permission === 1 ? (
                                    <AccountReports />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />

                        <Route
                            path="beds"
                            element={
                                bedsPermissions?.view_permission === 1 ? (
                                    <Beds />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="profile"
                            // element={
                            //     currentAuth?.user?.permissions.find(
                            //         (permission) =>
                            //             permission.module_name === "profile"
                            //     )?.view_permission === 1 ? (
                            //         <Profile />
                            //     ) : (
                            //         <Navigate to="/admin" />
                            //     )
                            // }
                            element={<Profile />}
                        />
                        <Route
                            path="designations"
                            element={
                                designationsPermissions?.view_permission ===
                                1 ? (
                                    <Designation />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="patients"
                            element={
                                patientsPermissions?.view_permission === 1 ? (
                                    <Patients />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="patients/:id"
                            element={
                                patientsPermissions?.view_permission === 1 ? (
                                    <PtHistory />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="tests"
                            element={
                                testsPermissions?.view_permission === 1 ? (
                                    <Tests />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="users"
                            element={
                                usersPermissions?.view_permission === 1 ? (
                                    <Users />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="services"
                            element={
                                servicesPermissions?.view_permission === 1 ? (
                                    <Services />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="opdbooking"
                            element={
                                opdsPermissions?.view_permission === 1 ? (
                                    <OpdBooking />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="investigations"
                            element={
                                labTestsPermissions?.view_permission === 1 ? (
                                    <TestBooking />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="minorots"
                            element={
                                motsPermissions?.view_permission === 1 ? (
                                    <MinorOT />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="employees"
                            element={
                                employeesPermissions?.view_permission === 1 ? (
                                    <Employee />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route
                            path="clinicprofile"
                            element={
                                entityProfilePermissions?.view_permission ===
                                1 ? (
                                    <ClinicProfile />
                                ) : (
                                    <Navigate to="/admin" />
                                )
                            }
                        />
                        <Route path="/*" element={<PageNotFound />} />
                        <Route
                            path="/changepassword"
                            element={<PasswordChange />}
                        />
                    </Routes>
                </div>
            </section>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Logout"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleLogout} color="error" autoFocus>
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AdminPanel;
