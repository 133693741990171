import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import {
    addTest,
    updateTest,
    getTests,
    deleteTest,
    getByIDClinicProfile,
    updateClinicProfile,
} from "../../../services/api";
import { showNotification } from "../../../services/helper";
import { store } from "../../../redux/store";
import { setClinic } from "../../../redux/features/auth/authSlice";
import { useEffect } from "react";

export function useGetClinic() {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } =
        useQuery({
            queryKey: [`${queryKeys.clinic}`],
            queryFn: () => getByIDClinicProfile(),
        });
    // store.dispatch(setClinic(data?.result));
    useEffect(() => {
        if (isSuccess && data) {
            store.dispatch(setClinic(data.result));
        }
    }, [isSuccess, data]);

    return {
        clinicData: data,
        isLoading,
        isSuccess,
        isFetching,
        refetch,
        isRefetching,
    };
}

export function useUpdateClinic(func) {
    const queryClient = useQueryClient();

    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: (postData) => updateClinicProfile(postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.clinic] });
            if (data?.success) {
                showNotification("success", data.message);
                func(false);
            } else {
                showNotification("error", data.message);
            }
        },
    });

    return { updateClinic: mutate, isLoading, isSuccess };
}
