import React from "react";
import { Modal, Fade, Box, Typography } from "@mui/material";
import RedButton from "./RedButton";
import ReusableButton from "./ReusableButton";

const CommonDeleteModal = ({
    open,
    onClose,
    title,
    contentText,
    onCancel,
    onConfirm,
    isDeleting,
}) => {
    return (
        <Modal open={open} onClose={onClose} closeAfterTransition>
            <Fade in={open}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 500,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: "5px",
                    }}
                >
                    <Typography
                        variant="h6"
                        component="h2"
                        sx={{
                            textAlign: "center",
                            marginBottom: 2,
                        }}
                    >
                        {title || "Confirm Delete"}
                    </Typography>
                    <Typography>
                        {contentText ||
                            "Are you sure you want to delete this? This action cannot be undone."}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            mt: 3,
                        }}
                    >
                        <ReusableButton
                            isDisabled={isDeleting}
                            onClick={onConfirm}
                            title="Confirm"
                        />

                        <RedButton
                            isDisabled={isDeleting}
                            onClick={onCancel}
                            title="Cancel"
                        />
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default CommonDeleteModal;
