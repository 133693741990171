import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineReload,
} from "react-icons/ai";

import Typography from "@mui/material/Typography";
import { Autocomplete, Pagination, TextField, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import {
  useAddTest,
  useDeleteTest,
  useEditTest,
  useGetTests,
} from "../query/hooks/test/testHook";
import { chunkArray, showNotification } from "../services/helper";
import LoaderLogo from "./common/LoaderLogo";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import { IoMdAdd } from "react-icons/io";
import ClearIcon from "@mui/icons-material/Clear";
import {
  clearTest,
  selectTestParam,
  setTestParams,
} from "../redux/features/filters/testSlice";
import ReusableButton from "./common/ReusableButton";
import RedButton from "./common/RedButton";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const columns = [
  { id: "id", label: "ID", minWidth: 10, align: "center" },
  { id: "name", label: "Name", minWidth: 100, align: "center" },
  { id: "price", label: "Price", minWidth: 100, align: "center" },
  { id: "created_on", label: "Created Date", minWidth: 100, align: "center" },
  { id: "actions", label: "Actions", align: "center" },
];

const initial = {
  name: "",
  charge: 0,
};

const Tests = () => {
  const dispatch = useDispatch();
  const testParams = useSelector(selectTestParam);
  const currentAuth = useSelector(selectCurrentAuth);
  const [dataToRender, setDataToRender] = useState([]);

  const testPermission = currentAuth?.user?.permissions.find(
    (permission) => permission.module === 1006
  );
  const { add_permission, update_permission, delete_permission } =
    testPermission;
  const { testsData, isLoading, isFetching, isRefetching, refetch } =
    useGetTests(testParams);
  const { testsData: allTestData } = useGetTests();

  useEffect(() => {
    setCurrentPage(testParams.offset / testParams.limit + 1);
  }, [testParams]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editedTest, setEditedTest] = useState("");
  const [toDelete, setToDelete] = useState("");

  const [test, setTest] = useState({ ...initial });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Check if the name is 'charge' and value is a valid number
    if (name === "charge") {
      const parsedValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
      setTest((prevTest) => ({
        ...prevTest,
        [name]: parsedValue,
      }));
    } else {
      // For other fields, update the state directly
      setTest((prevTest) => ({
        ...prevTest,
        [name]: value,
      }));
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTest({ initial });
  };
  const { addNewTest, isLoading: isAddingTest } = useAddTest(handleClose);
  const { editTest, isLoading: isEditingRole } = useEditTest(setEditOpen);
  const { deleteTest, isLoading: isDeleting } = useDeleteTest();

  const validateTest = (test) => {
    if (
      !test ||
      !test.name ||
      typeof test.name !== "string" ||
      !test.name.trim() ||
      isNaN(test.charge) ||
      test.charge <= 0
    ) {
      return false;
    }
    return true;
  };

  const handleAdd = () => {
    if (!validateTest(test)) {
      showNotification(
        "error",
        "Invalid test details. Please provide a valid name and charge."
      );
      return;
    }
    addNewTest(test);
    if (!isAddingTest) {
      setTest({ ...initial });
    }
  };

  const handleCancel = () => {
    setTest({ initial });
    handleClose();
  };

  const handleEditClick = (row) => {
    setEditedTest(row);
    setEditOpen(true);
  };

  const handleEditSave = (row) => {
    const updatedTest = {
      id: editedTest.id,
      name: editedTest.name,
      charge: parseFloat(editedTest.charge),
    };
    editTest(updatedTest);
    setEditedTest("");
  };

  const handleOpenDeleteDialog = (row) => {
    setOpenDeleteDialog(true);
    setToDelete(row);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmed = () => {
    deleteTest(toDelete);
    handleCloseDeleteDialog();
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    dispatch(
      setTestParams({
        ...testParams,
        offset: (value - 1) * testParams.limit,
      })
    );
  };
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };

  const handleOptionChange = (event, newValue) => {
    dispatch(
      setTestParams({
        ...testParams,
        name: newValue.name,
      })
    );
    setSelectedOption(newValue);
  };

  const handleClear = () => {
    setSelectedOption(null);
    setInputValue("");
    dispatch(clearTest());
  };
  if (!testsData)
    return (
      <div className="flex items-center justify-center">
        <LoaderLogo />
      </div>
    );
  return (
    <section>
      <div className="">
        <div className="flex justify-between px-10 m-4">
          <div>
            <div className="flex items-center space-x-3">
              <Typography variant="h5">Tests</Typography>
              <Tooltip title="Refresh" placement="bottom-start">
                <button
                  onClick={refetch}
                  className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                  style={{ cursor: "pointer" }}
                >
                  <AiOutlineReload size={20} />
                </button>
              </Tooltip>
            </div>
          </div>
          {/* filter&Search */}
          <div className="flex items-center">
            <div className="w-[100%]">
              <div className="">
                <Autocomplete
                  className="w-[350px]"
                  options={allTestData?.result?.list || []}
                  getOptionLabel={(option) => option.name}
                  value={selectedOption}
                  onChange={handleOptionChange}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Name"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {selectedOption && (
                              <ClearIcon
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={handleClear}
                              />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          {add_permission === 1 && (
            <Tooltip title="Add Test" placement="bottom-start">
              <button
                onClick={handleOpen}
                className="flex items-center h-10 px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
              >
                <IoMdAdd size={18} />
              </button>
            </Tooltip>
          )}
        </div>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "15px",
          }}
          elevation={24}
        >
          <TableContainer
            sx={{
              height: 520,
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                width: 0,
              },
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bold",
                        background: "#eeeeee",
                        textTransform: "uppercase",
                        backgroundColor: "#27272A",
                        color: "white",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {!isLoading && !isFetching && !isRefetching ? (
                <TableBody>
                  {testsData?.result?.list?.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        key={row.id}
                        align="center"
                      >
                        <TableCell align="center">{row?.id}</TableCell>
                        <TableCell align="center">{row?.name}</TableCell>
                        <TableCell align="center">{row?.charge}</TableCell>
                        <TableCell align="center">
                          {dayjs(row?.created_on).format(
                            "DD-MM-YYYY,h:mm:ss A"
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: 120,
                          }}
                        >
                          <div className="flex items-center justify-center space-x-4">
                            {update_permission === 1 && (
                              <Tooltip title="Edit" placement="bottom-start">
                                <button
                                  onClick={() => handleEditClick(row)}
                                  className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                >
                                  <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                </button>
                              </Tooltip>
                            )}
                            {delete_permission === 1 && (
                              <Tooltip title="Delete" placement="bottom-start">
                                <button
                                  onClick={() => handleOpenDeleteDialog(row.id)}
                                  className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                >
                                  <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                </button>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <div className="flex items-center justify-center">
                        <LoaderLogo />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {!isLoading && !isFetching && !isRefetching && (
            <div className="flex items-center justify-center my-4">
              <Pagination
                count={Math.ceil(testsData?.result?.count / testParams.limit)}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  "& .MuiSelect-select": {
                    display: "none !important",
                  },
                  "& > div.MuiToolbar-root > div.MuiInputBase-root > svg": {
                    display: "none !important",
                  },
                }}
              />
            </div>
          )}
        </Paper>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                marginBottom: 2,
              }}
            >
              Add New Test
            </Typography>

            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={test.name}
              onChange={handleChange}
            />
            <TextField
              required
              margin="dense"
              id="charge"
              name="charge"
              label="Charge"
              type="text"
              fullWidth
              value={test.charge}
              onChange={handleChange}
            />

            <div className="flex justify-between p-6">
              <ReusableButton
                isDisabled={isAddingTest}
                onClick={handleAdd}
                title="Save"
              />

              <RedButton
                isDisabled={isAddingTest}
                onClick={handleCancel}
                title="Cancel"
              />
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editOpen}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                marginBottom: 2,
              }}
            >
              Edit Test
            </Typography>

            <TextField
              required
              autoFocus
              margin="dense"
              id="editedRole"
              label="Edit Test"
              type="text"
              fullWidth
              value={editedTest.name} // Set the initial value to the role name
              onChange={(e) =>
                setEditedTest({
                  ...editedTest,
                  name: e.target.value,
                })
              }
            />
            <TextField
              required
              autoFocus
              margin="dense"
              id="editedRole"
              label="Charge"
              type="text"
              fullWidth
              value={editedTest.charge} // Set the initial value to the role name
              onChange={(e) =>
                setEditedTest({
                  ...editedTest,
                  charge: e.target.value,
                })
              }
            />

            <div className="flex justify-between p-6">
              <ReusableButton
                isDisabled={isEditingRole}
                onClick={handleEditSave}
                title="Save"
              />

              <RedButton
                isDisabled={isEditingRole}
                onClick={() => setEditOpen(false)}
                title="Cancel"
              />
            </div>
          </Box>
        </Fade>
      </Modal>

      <CommonDeleteModal
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onCancel={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmed}
        isDeleting={isDeleting}
      />
    </section>
  );
};

export default Tests;
