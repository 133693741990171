// summarySlice.js

import { createSlice } from "@reduxjs/toolkit";

const summaryInitialState = {
    defaultParams: {
        startDate: "",
        endDate: "",
        name: "",
        hid: "",
        ipd: "",
        mobile: "",
        offset: 0,
        limit: 10,
    },
};

const summarySlice = createSlice({
    name: "summary",
    initialState: summaryInitialState,
    reducers: {
        setSummaryParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },

        clearSummary: (state, action) => {
            state.defaultParams = summaryInitialState.defaultParams;
        },
    },
    extraReducers: {},
});

export const selectSummaryParam = (state) => state.summary.defaultParams;
export const { setSummaryParams, clearSummary } = summarySlice.actions;
export default summarySlice.reducer;
