export const validateIPD = (body) => {
    const requiredFields = ['patient', 'department', 'doctor', 'admission_charge', 'payment_mode', 'floor', 'room', 'bed'];
    const emptyFields = [];
    const zeroValueFields = [];

    requiredFields.forEach(field => {
        const fieldValue = body[field];
        const trimmedValue = typeof fieldValue === 'string' ? fieldValue.trim() : String(fieldValue);

        if (!trimmedValue || trimmedValue === '') {
            emptyFields.push(field);
        }

        if (field === 'admission_charge' || field === 'floor' || field === 'room' || field === 'bed') {
            const numericValue = parseFloat(trimmedValue);
            if (isNaN(numericValue) || numericValue === 0) {
                zeroValueFields.push(field);
            }
        }
    });

    return { emptyFields, zeroValueFields };
};
