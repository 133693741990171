import {
    Backdrop,
    Box,
    Fade,
    Modal,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import ReusableButton from "../ReusableButton";
import RedButton from "../RedButton";
import { useParams } from "react-router-dom";
import { useGetIPDOT } from "../../../query/hooks/ipd/ots";
import dayjs from "dayjs";
import {
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineReload,
} from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    getPermissionsByModule,
    getStartingTimeOfDate,
    showNotification,
} from "../../../services/helper";

import {
    useAddSummary,
    useDeleteSummary,
    useGetSummaries,
    useGetSummary,
    useUpdateSummary,
} from "../../../query/hooks/summaries/summariesHook";
import CommonDeleteModal from "../CommonDeleteModal";
import { useGetIPD } from "../../../query/hooks/ipd/ipds";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../../../redux/features/auth/authSlice";
import {
    clearSummary,
    selectSummaryParam,
    setSummaryParams,
} from "../../../redux/features/filters/summarySlice";
import ClearIcon from "@mui/icons-material/Clear";
import ClearButton from "../ClearButton";
import { getIPD } from "../../../services/api";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const initialFormState = {
    diagnosis: "",
    treatment: "",
    findings: "",
    post_operative_course: "",
    patient_condition: "",
    medicines: "",
    advice: "",
    investigation: "",
    checkup_date: null,
};
// Generate header array based on summariesData fields
const columns = [
    {
        id: "patient_name",
        label: "Patient Name",
        minWidth: 100,
        align: "center",
    },
    { id: "hid", label: "HID", minWidth: 100, align: "center" },
    {
        id: "patient_mobile",
        label: "Patient Mobile",
        minWidth: 100,
        align: "center",
    },
    { id: "doctor_name", label: "Doctor Name", minWidth: 100, align: "center" },
    {
        id: "department_name",
        label: "Department Name",
        minWidth: 100,
        align: "center",
    },
    {
        id: "actions",
        label: "Action",
        minWidth: 100,
        align: "center",
    },
];

// const columns = [
//     { id: "ot", label: "OT" },
//     { id: "totalAmount", label: "Total Amount" },
//     { id: "ConsultantDoctor", label: "Consultant Doctor" },
//     { id: "date", label: "Date" },
//     { id: "actions", label: "Action" },
// ];
const DischargeSummary = () => {
    const dispatch = useDispatch();
    const currentAuth = useSelector(selectCurrentAuth);
    const permissionsList = currentAuth?.user?.permissions;
    const summaryParams = useSelector(selectSummaryParam);
    const billsPermissions = getPermissionsByModule(1023, permissionsList);
    const {
        view_permission,
        add_permission,
        update_permission,
        delete_permission,
    } = billsPermissions;
    const params = useParams();

    const [formData, setFormData] = useState(initialFormState);

    const [open, setOpen] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [editForm, setEditForm] = useState("");
    const [toDelete, setToDelete] = useState("");
    // const { ipdData } = useGetIPD(ipdID);

    const [searchIPDForm, setSearchIPDForm] = useState({ ipdID: "" });
    const [ipdID, setIpdID] = useState("");

    useEffect(() => {
        const fetchIPD = async () => {
            if (ipdID) {
                const res = await getIPD();
                if (res.success) {
                    setStep(2);
                } else {
                    showNotification("error", "IPD not found");
                }
            }
        };

        fetchIPD(); // Call fetchIPD initially and whenever ipdID changes
    }, [ipdID]);

    const handleSearchIPDChange = useCallback((event) => {
        const { name, value } = event.target;
        setSearchIPDForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }, []);

    // const handleSearchIPD = useCallback(() => {
    //     const currentIPDID = searchIPDForm.ipdID.trim();
    //     if (!currentIPDID || isNaN(currentIPDID)) {
    //         showNotification("error", "Enter a valid IPD ID");
    //     } else {

    //         setIpdID(currentIPDID);
    //         setStep(2);
    //     }
    // }, [searchIPDForm]);
    const handleSearchIPD = async () => {
        const currentIPDID = searchIPDForm.ipdID.trim();
        if (!currentIPDID || isNaN(currentIPDID)) {
            showNotification("error", "Enter a valid IPD ID");
        } else {
            if (currentIPDID) {
                const res = await getIPD(currentIPDID);

                if (res.success && res.result) {
                    setStep(2);
                } else {
                    showNotification("error", "IPD not found");
                }
            }
        }
    };

    const [step, setStep] = useState(1);

    const [searchParams, setSearchParams] = useState({
        name: "",
        hid: "",
        ipd: "",
        mobile: "",
    });

    const handleSearchParams = (event) => {
        const { name, value } = event.target;
        setSearchParams((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSearchFunc = () => {
        dispatch(setSummaryParams(searchParams));
    };

    const { summariesData, isLoading } = useGetSummaries(summaryParams);
    const { addSummary, isLoading: isAdding } = useAddSummary();

    const {
        updateSummary,
        isLoading: isUpdating,
        isSuccess,
    } = useUpdateSummary();

    const { deleteSummary, isLoading: isDeleting } = useDeleteSummary();
    const {
        summaryData,
        isLoading: isFetchingData,
        refetch,
    } = useGetSummary(ipdID);

    const [summaryExist, setSummaryExist] = useState(false);
    useEffect(() => {
        if (summaryData?.result?.list?.length > 0) {
            setFormData(summaryData?.result?.list[0]);
            setSummaryExist(true);
        }
    }, [ipdID, summaryData]);

    const handleOpen = () => {
        setOpen(true);
        setStep(1);
    };

    const handleClose = () => {
        setSummaryExist(false);
        setOpen(false);
        setStep(1);
        setIpdID("");
        setSearchIPDForm({
            ipdID: "",
        });
        setFormData(initialFormState);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
        const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
        setFormData({
            ...formData,
            checkup_date: formattedDate,
        });
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteConfirmed = () => {
        deleteSummary(toDelete);
        handleCloseDeleteDialog();
    };
    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };
    const validateFormData = (formData) => {
        // Define your validation criteria here
        const {
            diagnosis,
            treatment,
            findings,
            post_operative_course,
            patient_condition,
            medicines,
            advice,
            investigation,
            checkup_date,
        } = formData;

        // Check if any field is empty
        if (
            !diagnosis ||
            !treatment ||
            !findings ||
            !post_operative_course ||
            !patient_condition ||
            !medicines ||
            !advice ||
            !investigation ||
            !checkup_date
        ) {
            return false; // If any field is empty, return false
        }

        // You can add more specific validation criteria here if needed

        return true; // If all validation criteria pass, return true
    };
    const handleAdd = () => {
        if (validateFormData(formData)) {
            addSummary({ ipdID: searchIPDForm.ipdID, body: formData });
        } else {
            // Handle validation failure, such as displaying an error message
            showNotification(
                "error",
                "Please ensure all required fields are filled"
            );
        }
        // addSummary({ ipdID: searchForm.ipdID, body: formData });
    };
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        // width: "90%",
        width: step > 1 ? "90%" : 600,
        height: step > 1 ? 600 : "auto",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "5px",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 0,
        }, // Add this line for vertical scrolling
    };
    const [editOpen, setEditOpen] = useState(false);
    const [editSummary, setEditSummary] = useState("");
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditSummary((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleEditClick = (row) => {
        setEditSummary(row); // Set the role to be edited
        setEditOpen(true); // Open the edit modal
    };

    const [selecteEditdDate, setSelecteEditdDate] = useState(new Date());
    const handleEditClose = () => {
        setEditSummary(""); // Set the role to be edited
        setEditOpen(false);
        setSelecteEditdDate(new Date()); // Open the edit modal
    };
    const handleEditDateChange = (date) => {
        setSelecteEditdDate(date);
        setEditSummary({
            ...editSummary,
            checkup_date: getStartingTimeOfDate(dayjs(date)),
        });
    };
    const handleEditSave = () => {
        const updatedSummary = {
            diagnosis: editSummary.diagnosis,
            treatment: editSummary.treatment,
            findings: editSummary.findings,
            post_operative_course: editSummary.post_operative_course,
            patient_condition: editSummary.patient_condition,
            medicines: editSummary.medicines,
            advice: editSummary.advice,
            investigation: editSummary.investigation,
            checkup_date: editSummary.checkup_date,
        };
        if (validateFormData(updatedSummary)) {
            updateSummary({ summaryID: editSummary.id, body: editSummary });
            if (isSuccess) {
                handleEditClose();
            }
        } else {
            showNotification(
                "error",
                "Please ensure all required fields are filled"
            );
        }
    };

    const [currentPage, setCurrentPage] = useState(1);

    const handlePaginationChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setSummaryParams({
                ...summaryParams,
                offset: (value - 1) * summaryParams.limit,
            })
        );
    };
    const handleClear = (field) => {
        setSearchParams({ ...searchParams, [field]: "" });
        dispatch(
            setSummaryParams({
                ...summaryParams,
                [field]: "",
            })
        );
    };
    const handleClearAll = () => {
        setSearchParams({ name: "", hid: "", ipd: "", mobile: "" });
        dispatch(clearSummary());
    };
    return (
        <>
            <section>
                <div className="mb-4">
                    <div className="flex justify-between px-10 mb-5">
                        <div>
                            <div className="flex items-center space-x-3">
                                <Typography variant="h5">
                                    Discharge Summary
                                </Typography>
                                <Tooltip
                                    title="Refresh"
                                    placement="bottom-start"
                                >
                                    <button
                                        onClick={refetch}
                                        className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <AiOutlineReload size={20} />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        {add_permission === 1 && (
                            <ReusableButton title="Add" onClick={handleOpen} />
                        )}
                    </div>
                </div>
                <section>
                    <div className="flex items-center mb-3 space-x-4">
                        <TextField
                            name="name"
                            label="Name"
                            value={searchParams.name}
                            onChange={handleSearchParams}
                            style={{ width: "20%" }}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {searchParams.name && (
                                            <ClearIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    handleClear("name")
                                                }
                                            />
                                        )}
                                    </>
                                ),
                            }}
                        />
                        <TextField
                            name="hid"
                            label="HID"
                            value={searchParams.hid}
                            onChange={handleSearchParams}
                            style={{ width: "20%" }}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {searchParams.hid && (
                                            <ClearIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    handleClear("hid")
                                                }
                                            />
                                        )}
                                    </>
                                ),
                            }}
                        />
                        <TextField
                            name="ipd"
                            label="IPD"
                            value={searchParams.ipd}
                            onChange={handleSearchParams}
                            style={{ width: "20%" }}
                            InputProps={{
                                inputProps: {
                                    maxLength: 10,
                                    pattern: "[0-9]*",
                                },
                                endAdornment: (
                                    <>
                                        {searchParams.ipd && (
                                            <ClearIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    handleClear("ipd")
                                                }
                                            />
                                        )}
                                    </>
                                ),
                            }}
                        />
                        <TextField
                            label="Search Phone Number"
                            variant="outlined"
                            name="mobile"
                            value={searchParams.mobile}
                            onChange={handleSearchParams}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                );
                            }}
                            InputProps={{
                                inputProps: {
                                    maxLength: 10,
                                    pattern: "[0-9]*",
                                },
                                endAdornment: (
                                    <>
                                        {searchParams.mobile && (
                                            <ClearIcon
                                                style={{ cursor: "pointer" }}
                                                // onClick={handleClearMobile}
                                                onClick={() =>
                                                    handleClear("mobile")
                                                }
                                            />
                                        )}
                                    </>
                                ),
                            }}
                        />
                        <ReusableButton
                            title="Search"
                            onClick={handleSearchFunc}
                        />
                        <ClearButton title="Clear" onClick={handleClearAll} />
                    </div>
                </section>
                <section>
                    <Paper
                        elevation={24}
                        sx={{
                            width: "100%",
                            overflow: "hidden",
                            borderRadius: "15px",
                        }}
                    >
                        <TableContainer
                            sx={{
                                height: 520,
                                overflowX: "scroll",
                                "&::-webkit-scrollbar": {
                                    width: 0,
                                },
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align="center"
                                                style={{
                                                    fontWeight: "bold",
                                                    background: "#eeeeee",
                                                    textTransform: "uppercase",
                                                    backgroundColor: "#27272A",
                                                    color: "white",
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {summariesData?.result?.list?.map(
                                        (item, index) => (
                                            <TableRow key={item?.id}>
                                                <TableCell align="center">
                                                    {item?.patient_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {item?.hid}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {item?.patient_mobile}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {item?.doctor_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {item?.department_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div className="flex items-center justify-center space-x-4">
                                                        {update_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Edit"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleEditClick(
                                                                            item
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                        {delete_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Delete"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleOpenDeleteDialog(
                                                                            item?.id
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                                >
                                                                    <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="flex items-center justify-center my-4">
                            <Pagination
                                count={
                                    Math.ceil(
                                        summariesData?.result?.count /
                                            summaryParams.limit
                                    ) || 0
                                }
                                page={currentPage}
                                sx={{
                                    "& .MuiSelect-select": {
                                        display: "none !important",
                                    },
                                    "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                        {
                                            display: "none !important",
                                        },
                                }}
                                onChange={handlePaginationChange}
                            />
                        </div>
                    </Paper>
                </section>
            </section>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <>
                            {step === 1 && (
                                <>
                                    <section>
                                        <Typography
                                            variant="h5"
                                            textAlign="center"
                                            className="pb-6"
                                        >
                                            Search IPD ID
                                        </Typography>
                                        <section className="flex flex-col  items-center space-y-4">
                                            <TextField
                                                sx={{
                                                    width: "70%",
                                                }}
                                                disabled={summaryExist}
                                                name="ipdID"
                                                label="Search IPD ID"
                                                value={searchIPDForm.ipdID}
                                                onChange={handleSearchIPDChange}
                                            />
                                            <div className="mt-4 w-full flex items-center justify-around">
                                                <ReusableButton
                                                    title="Search"
                                                    onClick={handleSearchIPD}
                                                />
                                                <RedButton
                                                    title="Cancel"
                                                    onClick={handleClose}
                                                />
                                            </div>
                                        </section>
                                    </section>
                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <Typography
                                        variant="h5"
                                        textAlign="center"
                                        className="py-6"
                                    >
                                        Discharge Summary
                                    </Typography>
                                    <form className="grid items-center grid-cols-2 overflow-auto gap-y-4">
                                        <TextField
                                            sx={{
                                                width: "90%",
                                            }}
                                            disabled={summaryExist}
                                            name="diagnosis"
                                            label="Diagnosis"
                                            multiline
                                            rows={3}
                                            value={formData.diagnosis}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            sx={{
                                                width: "90%",
                                            }}
                                            disabled={summaryExist}
                                            name="treatment"
                                            label="Treatment"
                                            multiline
                                            rows={3}
                                            value={formData.treatment}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            sx={{
                                                width: "90%",
                                            }}
                                            disabled={summaryExist}
                                            name="findings"
                                            label="Findings"
                                            multiline
                                            rows={3}
                                            value={formData.findings}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            sx={{
                                                width: "90%",
                                            }}
                                            disabled={summaryExist}
                                            name="post_operative_course"
                                            label="Post Operative Course"
                                            multiline
                                            rows={3}
                                            value={
                                                formData.post_operative_course
                                            }
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            sx={{
                                                width: "90%",
                                            }}
                                            disabled={summaryExist}
                                            name="patient_condition"
                                            label="Patient Condition"
                                            multiline
                                            rows={3}
                                            value={formData.patient_condition}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            sx={{
                                                width: "90%",
                                            }}
                                            disabled={summaryExist}
                                            name="medicines"
                                            label="Medicines"
                                            multiline
                                            rows={3}
                                            value={formData.medicines}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            sx={{
                                                width: "90%",
                                            }}
                                            disabled={summaryExist}
                                            name="advice"
                                            label="Advice"
                                            multiline
                                            rows={3}
                                            value={formData.advice}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            sx={{
                                                width: "90%",
                                            }}
                                            disabled={summaryExist}
                                            name="investigation"
                                            label="Investigation"
                                            multiline
                                            rows={3}
                                            value={formData.investigation}
                                            onChange={handleChange}
                                        />
                                        <DatePicker
                                            maxDate={dayjs().toDate()}
                                            className="px-6 py-4 w-[310px] border border-gray-500 rounded-lg"
                                            // selected={formData.date}
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            disabled={summaryExist}
                                            dateFormat="yyyy/MM/dd"
                                            placeholderText="Select Date"
                                        />
                                    </form>
                                    <div className="flex w-full py-2 justify-evenly ">
                                        {!summaryExist && (
                                            <ReusableButton
                                                title="ADD"
                                                onClick={handleAdd}
                                            />
                                        )}
                                        <RedButton
                                            title="Cancel"
                                            onClick={handleClose}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    </Box>
                </Fade>
            </Modal>
            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeleting}
            />
            <Modal
                open={editOpen}
                onClose={() => setEditOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={editOpen}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "100%",
                            height: "100%",
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                            borderRadius: "5px",
                            overflowY: "scroll",
                            "&::-webkit-scrollbar": {
                                width: 3,
                            }, // Add this line for vertical scrolling
                        }}
                    >
                        <>
                            <Typography
                                variant="h5"
                                textAlign="center"
                                className="py-6"
                            >
                                Discharge Summary
                            </Typography>
                            <section className="flex flex-col items-start justify-start flex-1 pl-3 border border-black rounded-lg w-[95%] mx-auto">
                                <Typography
                                    variant="body1"
                                    className="py-2 capitalize"
                                >
                                    Patient Name: {editSummary?.patient_title}{" "}
                                    {editSummary?.patient_gender}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="py-2 capitalize"
                                >
                                    Patient Age: {editSummary?.patient_age}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="py-2 capitalize"
                                >
                                    Patient Mobile:{" "}
                                    {editSummary?.patient_mobile}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="py-2 capitalize"
                                >
                                    Doctor Name: {editSummary?.doctor_name}
                                </Typography>
                            </section>
                            <form className="grid items-center grid-cols-2 pt-4 overflow-auto gap-y-4 place-items-center">
                                <TextField
                                    sx={{
                                        width: "90%",
                                    }}
                                    disabled={isUpdating}
                                    name="diagnosis"
                                    label="Diagnosis"
                                    multiline
                                    rows={3}
                                    value={editSummary?.diagnosis}
                                    onChange={handleEditChange}
                                />
                                <TextField
                                    sx={{
                                        width: "90%",
                                    }}
                                    disabled={isUpdating}
                                    name="treatment"
                                    label="Treatment"
                                    multiline
                                    rows={3}
                                    value={editSummary?.treatment}
                                    onChange={handleEditChange}
                                />
                                <TextField
                                    sx={{
                                        width: "90%",
                                    }}
                                    disabled={isUpdating}
                                    name="findings"
                                    label="Findings"
                                    multiline
                                    rows={3}
                                    value={editSummary?.findings}
                                    onChange={handleEditChange}
                                />
                                <TextField
                                    sx={{
                                        width: "90%",
                                    }}
                                    disabled={isUpdating}
                                    name="post_operative_course"
                                    label="Post Operative Course"
                                    multiline
                                    rows={3}
                                    value={editSummary?.post_operative_course}
                                    onChange={handleEditChange}
                                />
                                <TextField
                                    sx={{
                                        width: "90%",
                                    }}
                                    disabled={isUpdating}
                                    name="patient_condition"
                                    label="Patient Condition"
                                    multiline
                                    rows={3}
                                    value={editSummary?.patient_condition}
                                    onChange={handleEditChange}
                                />
                                <TextField
                                    sx={{
                                        width: "90%",
                                    }}
                                    disabled={isUpdating}
                                    name="medicines"
                                    label="Medicines"
                                    multiline
                                    rows={3}
                                    value={editSummary?.medicines}
                                    onChange={handleEditChange}
                                />
                                <TextField
                                    sx={{
                                        width: "90%",
                                    }}
                                    disabled={isUpdating}
                                    name="advice"
                                    label="Advice"
                                    multiline
                                    rows={3}
                                    value={editSummary?.advice}
                                    onChange={handleEditChange}
                                />
                                <TextField
                                    sx={{
                                        width: "90%",
                                    }}
                                    disabled={isUpdating}
                                    name="investigation"
                                    label="Investigation"
                                    multiline
                                    rows={3}
                                    value={editSummary?.investigation}
                                    onChange={handleEditChange}
                                />
                                <DatePicker
                                    maxDate={dayjs().toDate()}
                                    className="px-6 py-4 w-[310px] border border-gray-500 rounded-lg"
                                    // selected={editSummary?.date}
                                    selected={selecteEditdDate}
                                    onChange={handleEditDateChange}
                                    dateFormat="yyyy/MM/dd"
                                    placeholderText="Select Date"
                                />
                            </form>
                            <div className="flex w-full py-2 justify-evenly ">
                                <ReusableButton
                                    title="Save"
                                    onClick={handleEditSave}
                                    isDisabled={isUpdating}
                                />

                                <RedButton
                                    title="Cancel"
                                    isDisabled={isUpdating}
                                    onClick={handleEditClose}
                                />
                            </div>
                        </>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default DischargeSummary;
