import { isError, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { getDepartments, addDepartment, updateDepartment, deleteDepartment } from "../../../services/api";

import { showNotification } from "../../../services/helper";


// Custom hook for fetching departments
export function useGetDepartments(params = { offset: 0, limit: 1000, name: "" }) {
    const { offset, limit, name } = params;

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError, error } = useQuery({
        queryKey: [queryKeys.departments, { offset, limit, name }],
        queryFn: () => getDepartments({ offset, limit, name }),
        if(isError) {
            showNotification('error', error)
        }
    });
    return { departmentsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError, error };
}

// Custom hook for adding a department
export function useAddDepartment() {

    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (departmentData) => addDepartment(departmentData), // Assuming you have a  in your Redux state

        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.departments] });
            if (data?.success) {
                showNotification("success", `Department added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addDepartment: mutate, isLoading };
}

// Custom hook for editing a department
export function useEditDepartment() {

    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updateDepartment(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.departments] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { editDepartment: mutate, isLoading };
}

// Custom hook for deleting a department
export function useDeleteDepartment() {

    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (departmentId) => deleteDepartment(departmentId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.departments] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteDepartment: mutate, isLoading };
}
