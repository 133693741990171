import { createSlice } from "@reduxjs/toolkit";
import { clearAuthHeader, setAuthHeader } from "../../../services/api";

const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, clinic: null, currentUser: null },
    reducers: {
        setCredentials: (state, action) => {
            state.user = action.payload;
            setAuthHeader(action.payload.token);
        },
        setClinic: (state, action) => {
            state.clinic = action.payload;
        },
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        logOut: (state, action) => {
            state.user = null;
            state.clinic = null;
            clearAuthHeader();
        }
    },
});

export const { setCredentials, logOut, setClinic, setCurrentUser } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentAuth = (state) => state.auth;
export const selectCurrentClinic = (state) => state.auth.clinic;
export const selectCurrentUser = (state) => state.auth.setCurrentUser;
