import {
    Backdrop,
    Box,
    Fade,
    Modal,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineReload,
} from "react-icons/ai";
import dayjs from "dayjs";
import { IoMdAdd } from "react-icons/io";
import LoaderLogo from "./common/LoaderLogo";
import {
    getEndingTimeOfDate,
    getPermissionsByModule,
    getStartingTimeOfDate,
    showNotification,
} from "../services/helper";
import { useDispatch, useSelector } from "react-redux";
import { useGetEmployees } from "../query/hooks/employee/employeeHooks";
import { selectEmployeesParam } from "../redux/features/filters/employeeSlice";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useGetMetaData } from "../query/hooks/metadata/metadataHook";
import ReusableButton from "./common/ReusableButton";
import RedButton from "./common/RedButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    useAddExpense,
    useDeleteExpense,
    useGetExpenses,
    useUpdateExpense,
} from "../query/hooks/expenses/expensesHook";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import ClearButton from "./common/ClearButton";
import {
    clearExpenses,
    selectExpensesParams,
    setExpensesParams,
} from "../redux/features/filters/expensesSlice";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
const columns = [
    {
        id: "2",
        label: "Expenses",
        minWidth: 120,
        align: "center",
    },

    {
        id: "4",
        label: "Type",
        minWidth: 120,
        align: "center",
    },
    { id: "5", label: "Date", minWidth: 100, align: "center" },
    { id: "7", label: "Actions", minWidth: 100, align: "center" },
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};
const intState = {
    date: null,
    type: "",
    amount: 0,
    remarks: "",
};
const DailyExpenses = () => {
    const currentAuth = useSelector(selectCurrentAuth);
    const dispatch = useDispatch();
    const expenseParams = useSelector(selectExpensesParams);

    const permissionsList = currentAuth?.user?.permissions;
    const expensesPermissions = getPermissionsByModule(1019, permissionsList);
    const { add_permission, update_permission, delete_permission } =
        expensesPermissions;
    const { metaData } = useGetMetaData();
    const [formData, setFormData] = useState({ ...intState });
    const {
        expensesData,
        isLoading,
        isSuccess,
        isFetching,
        refetch,
        isRefetching,
    } = useGetExpenses(expenseParams);

    const { addExpense, isLoading: isAddingExpense } = useAddExpense();
    const { updateExpense, isLoading: isUpdatingExpense } = useUpdateExpense();
    const { deleteExpense, isLoading: isDeletingExpense } = useDeleteExpense();
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const employeeParams = useSelector(selectEmployeesParam);
    const { employeesData } = useGetEmployees(employeeParams);
    const [addView, setAddView] = useState(false);
    const [editView, setEditView] = useState(false);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedDept, setSelectedDept] = useState(null);
    const [inputDeptValue, setInputDeptValue] = useState("");
    const handleOpen = () => {
        setOpen(true);
        setAddView(true);
        setEditView(false);
    };
    const handleClose = () => {
        setSelectedDoctor(null);
        setAddView(false);
        setEditView(false);
        setSelectedDept(null);
        setInputDeptValue(null);
        setFormData({ ...intState });
        setOpen(false);
    };
    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setFormData({ ...formData, date: getStartingTimeOfDate(dayjs(date)) });
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        const parsedValue = name === "amount" ? parseInt(value) || 0 : value;
        setFormData((prevState) => ({ ...prevState, [name]: parsedValue }));
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleDeleteConfirmed = () => {
        deleteExpense(toDelete);
        handleCloseDeleteDialog();
    };
    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };
    const handleAddServices = () => {
        if (!formData.date) {
            showNotification("error", "Please select a date.");
            return;
        }

        if (!formData.type.trim()) {
            showNotification("error", "Please enter the type of service.");
            return;
        }

        const amount = parseInt(formData.amount);
        if (isNaN(amount) || amount <= 0) {
            showNotification("error", "Please enter a valid amount.");
            return;
        }

        // If all validations pass, proceed with adding services
        const parsedState = {
            date: formData.date,
            amount: amount,
            type: formData.type.trim(),
            remarks: formData.remarks.trim(),
        };

        addExpense(parsedState);
        handleClose();
    };
    const [editFormData, setEditFormData] = useState("");
    const handleEditClick = (row) => {
        setSelectedEditDate(new Date(row.date));
        setEditFormData(row);
        setOpen(true);
        setEditView(true);
        setAddView(false);
    };
    const handleEditChange = (event) => {
        const { name, value } = event.target;
        const parsedValue = name === "amount" ? parseInt(value) || 0 : value;
        setEditFormData((prevState) => ({ ...prevState, [name]: parsedValue }));
    };
    const [selectedEditDate, setSelectedEditDate] = useState(null);
    const handleEditDate = (date) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
        setSelectedEditDate(date);
        setEditFormData({
            ...editFormData,
            date: formattedDate,
        });
    };
    const handleEditService = () => {
        if (!editFormData.date) {
            showNotification("error", "Please select a date.");
            return;
        }

        if (!editFormData.type.trim()) {
            showNotification("error", "Please enter the type of service.");
            return;
        }

        const amount = parseInt(editFormData.amount);
        if (isNaN(amount) || amount <= 0) {
            showNotification("error", "Please enter a valid amount.");
            return;
        }

        const parsedState = {
            id: editFormData.id,
            date: editFormData.date,
            amount: amount,
            type: editFormData.type.trim(),
            remarks: editFormData.remarks.trim(),
        };

        updateExpense(parsedState);
        handleClose();
    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleSearchDate = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const clearSearch = () => {
        dispatch(clearExpenses());
        setStartDate(null);
        setEndDate(null);
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(
            setExpensesParams({
                ...expenseParams,
                startDate: startDate
                    ? getStartingTimeOfDate(dayjs(startDate))
                    : "",
                endDate: endDate ? getEndingTimeOfDate(dayjs(endDate)) : "",
            })
        );
    };
    const [currentPage, setCurrentPage] = useState(1);

    const handlePaginationChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setExpensesParams({
                ...expenseParams,
                offset: (value - 1) * expenseParams.limit,
            })
        );
    };
    if (!expensesData)
        return (
            <div className="flex items-center justify-center">
                <LoaderLogo />
            </div>
        );

    return (
        <>
            <div className="flex justify-between px-10 mb-5">
                <div>
                    <div className="flex items-center space-x-3">
                        <Typography variant="h5">Daily Expenses</Typography>

                        <Tooltip title="Refresh" placement="bottom-start">
                            <button
                                // onClick={refetch}
                                className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                style={{ cursor: "pointer" }}
                            >
                                <AiOutlineReload size={20} />
                            </button>
                        </Tooltip>
                    </div>
                </div>
                {add_permission === 1 && (
                    <Tooltip title="Add Payment" placement="bottom-start">
                        <button
                            onClick={handleOpen}
                            className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                        >
                            <IoMdAdd size={18} />
                        </button>
                    </Tooltip>
                )}
            </div>
            <div className="w-[100%] my-4 ">
                <form
                    onSubmit={handleSubmit}
                    className="flex items-center space-x-5"
                >
                    <div style={{ zIndex: "999" }}>
                        <DatePicker
                            maxDate={dayjs().toDate()}
                            className="px-6 py-4 w-[320px] border border-gray-300 rounded-xl"
                            dateFormat="yyyy/MM/dd"
                            placeholderText="StartDate - EndDate"
                            selectsRange={true}
                            isClearable
                            selected={startDate}
                            onChange={handleSearchDate}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </div>

                    <ReusableButton
                        title="Search"
                        onClick={handleSubmit}
                        type="submit"
                    />
                    <ClearButton title="Clear" onClick={clearSearch} />
                </form>
            </div>
            <section>
                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "15px",
                    }}
                    elevation={24}
                >
                    <TableContainer
                        sx={{
                            height: 520,
                            overflowX: "scroll",
                            "&::-webkit-scrollbar": {
                                width: 0,
                            },
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => (
                                        <TableCell
                                            key={index}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                                color: "white",
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {expensesData?.result?.list?.map(
                                    (item, index) => (
                                        <TableRow
                                            key={index}
                                            style={{
                                                backgroundColor:
                                                    index % 2 === 0
                                                        ? "#E4E4E7"
                                                        : "white",
                                            }}
                                        >
                                            <TableCell align="center">
                                                {item.amount}{" "}
                                                {/* Assuming this should be the name of the doctor */}
                                            </TableCell>

                                            <TableCell align="center">
                                                {item.type}{" "}
                                            </TableCell>

                                            <TableCell align="center">
                                                {item.date}{" "}
                                            </TableCell>

                                            <TableCell align="center">
                                                <div className="flex items-center justify-center space-x-4">
                                                    {update_permission ===
                                                        1 && (
                                                        <Tooltip
                                                            title="Edit"
                                                            placement="bottom-start"
                                                        >
                                                            <button
                                                                className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                onClick={() =>
                                                                    handleEditClick(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                            </button>
                                                        </Tooltip>
                                                    )}
                                                    {delete_permission ===
                                                        1 && (
                                                        <Tooltip
                                                            title="Delete"
                                                            placement="bottom-start"
                                                        >
                                                            <button
                                                                onClick={() =>
                                                                    handleOpenDeleteDialog(
                                                                        item.id
                                                                    )
                                                                }
                                                                className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                            >
                                                                <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                            </button>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="flex items-center justify-center my-4">
                        <Pagination
                            count={Math.ceil(
                                expensesData?.result?.count /
                                    expenseParams.limit
                            )}
                            page={currentPage}
                            sx={{
                                "& .MuiSelect-select": {
                                    display: "none !important",
                                },
                                "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                    {
                                        display: "none !important",
                                    },
                            }}
                            onChange={handlePaginationChange}
                        />
                    </div>
                </Paper>
            </section>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {addView && (
                            <>
                                <div className="grid w-full grid-cols-2 gap-4">
                                    <TextField
                                        name="type"
                                        label="Type"
                                        value={formData.type}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />

                                    <TextField
                                        name="amount"
                                        label="Amount"
                                        value={formData.amount}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <div style={{ zIndex: "999" }}>
                                        <DatePicker
                                            maxDate={dayjs().toDate()}
                                            className="px-6 py-4 w-[310px] border border-gray-500 rounded-lg"
                                            // selected={formData.date}
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="yyyy/MM/dd"
                                            placeholderText="Select Date"
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            name="remarks"
                                            label="Remarks"
                                            value={formData.remarks}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            multiline
                                            rows={4} // Adjust the number of rows as needed
                                        />
                                    </div>
                                </div>
                                <section className="flex py-5 justify-evenly">
                                    <ReusableButton
                                        title="Save"
                                        onClick={handleAddServices}
                                    />
                                    <RedButton
                                        title="Cancel"
                                        onClick={handleClose}
                                    />
                                </section>
                            </>
                        )}

                        {editView && (
                            <>
                                <div className="grid w-full grid-cols-2 gap-4">
                                    <TextField
                                        name="type"
                                        label="Type"
                                        value={editFormData?.type}
                                        onChange={handleEditChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />

                                    <TextField
                                        name="amount"
                                        label="Amount"
                                        value={editFormData?.amount}
                                        onChange={handleEditChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <div style={{ zIndex: "999" }}>
                                        <DatePicker
                                            maxDate={dayjs().toDate()}
                                            className="px-6 py-4 w-[310px] border border-gray-500 rounded-lg"
                                            // selected={editForm?.date}
                                            selected={selectedEditDate}
                                            onChange={handleEditDate}
                                            dateFormat="yyyy/MM/dd"
                                            placeholderText="Select Date"
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            name="remarks"
                                            label="Remarks"
                                            value={editFormData?.remarks}
                                            onChange={handleEditChange}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            multiline
                                            rows={4} // Adjust the number of rows as needed
                                        />
                                    </div>
                                </div>
                                <section className="flex py-5 justify-evenly">
                                    <ReusableButton
                                        title="Save"
                                        onClick={handleEditService}
                                    />
                                    <RedButton
                                        title="Cancel"
                                        onClick={handleClose}
                                    />
                                </section>
                            </>
                        )}
                    </Box>
                </Fade>
            </Modal>

            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeletingExpense}
            />
        </>
    );
};

export default DailyExpenses;
