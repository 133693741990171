import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import {
    addIPDService,
    deleteIPDService,
    getIPDService,
    getIPDServices,
    updateIPDService,
} from "../../../services/api";

// export function useGetIPDServices() {
//     const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } =
//         useQuery({
//             queryKey: [queryKeys.ipdServices],
//             queryFn: () => getIPDServices(),
//         });

//     return {
//         ipdServicesData: data,
//         isLoading,
//         isSuccess,
//         isFetching,
//         refetch,
//         isRefetching,
//     };
// }

export function useGetIPDService(ipdServiceID) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } =
        useQuery({
            queryKey: [queryKeys.ipdServices],
            queryFn: () => getIPDService(ipdServiceID),
            enabled: ipdServiceID ? true : false,
        });

    return {
        ipdServiceData: data,
        isLoading,
        isSuccess,
        isFetching,
        refetch,
        isRefetching,
    };
}

export function useAddIPDService() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: ({ ipdId, ipdServiceData }) =>
            addIPDService({ ipdID: ipdId, body: ipdServiceData }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: [queryKeys.ipdServices],
            });
            if (data?.success) {
                showNotification("success", `IPD service added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addIPDService: mutate, isLoading };
}

export function useEditIPDService() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ serviceID, ipdServiceData }) =>
            updateIPDService({ serviceId: serviceID, body: ipdServiceData }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: [queryKeys.ipdServices],
            });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { editIPDService: mutate, isLoading };
}
export function useDeleteIPDService() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation(
        (serviceID) => deleteIPDService(serviceID),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([queryKeys.ipdServices]);
                if (data?.success) {
                    showNotification("success", data.message);
                } else {
                    showNotification("error", data.message);
                    console.error("Error:", data.message);
                }
            },
        }
    );

    return { deleteIPDService: mutate, isLoading };
}
