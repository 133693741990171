import { useQuery, useMutation, useQueryClient, isError } from "@tanstack/react-query";
import { showNotification } from "../../../services/helper";
import { queryKeys } from "../../keys";
import { getEmployees, addEmployee, updateEmployee, deleteEmployee } from "../../../services/api"; // Import your API functions
import { store } from "../../../redux/store";


export function useGetEmployees(employeeParams = {
    name: "",
    email: "",
    mobile: "",
    department: "",
    offset: 0,
    limit: 10000,
}) {
    const { name, email, mobile, department, offset, limit } = employeeParams;

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.employees, {
            offset,
            mobile,
            name,
            email,
            department,
        }],
        queryFn: () => getEmployees({
            offset,
            limit,
            name,
            email,
            mobile,
            department,
        }),
    });

    return { employeesData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}



// Custom hook for adding an employee
export function useAddEmployee() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (employeeData) => addEmployee(employeeData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.employees] });
            if (data?.success) {
                showNotification("success", `Employee added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addEmployee: mutate, isLoading };
}

// Custom hook for editing an employee
export function useEditEmployee() {
    const queryClient = useQueryClient();
    const state = store.getState();

    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updateEmployee(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.employees, { offset: state.employees.defaultParams.offset }] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });

    return { editEmployee: mutate, isLoading };
}

// Custom hook for deleting an employee
export function useDeleteEmployee() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (employeeId) => deleteEmployee(employeeId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.employees] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { deleteEmployee: mutate, isLoading };
}
