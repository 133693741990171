import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { addIPDDeposit, deleteIPDDeposit, getIPDDeposit, updateIPDDeposit } from "../../../services/api";

// IPD Deposit Hooks
export function useGetIPDDeposit(depositID) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.ipdDeposit],
        queryFn: () => getIPDDeposit(depositID),
        enabled: depositID ? true : false,
    });

    return { ipdDepositData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

export function useAddIPDDeposit() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: ({ depositID, body }) => addIPDDeposit({ depositID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdDeposit] });
            if (data?.success) {
                showNotification("success", `IPD deposit added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addIPDDeposit: mutate, isLoading };
}

export function useUpdateIPDDeposit() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ depositID, body }) => updateIPDDeposit({ depositID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdDeposit] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { updateIPDDeposit: mutate, isLoading };
}

export function useDeleteIPDDeposit() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (depositID) => deleteIPDDeposit(depositID),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdDeposit] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteIPDDeposit: mutate, isLoading };
}
