import { addReport, getReports } from "../../../services/api";
import { showNotification } from "../../../services/helper";
import { useQuery, useMutation, useQueryClient, isError } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { queryKeys } from "../../keys";
// Custom hook for fetching reports
export function useGetReport(reportId) {

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.report, reportId],
        queryFn: () => getReports(reportId),
    });

    return { reportData: data, isLoading, isSuccess,isFetching, refetch, isRefetching };
}

export function useAddReport() {
    const queryClient = useQueryClient();

    const { mutate, isLoading, isSuccess, isFetching, refetch, isRefetching } = useMutation({
        mutationFn: (reportData) => addReport(reportData),

        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.reports] });
            if (data?.success) {
                showNotification("success", `Report added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addReport: mutate, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

// Custom hook for editing a report
// export function useEditReport() {

//     const queryClient = useQueryClient();
//     const { mutate, isLoading } = useMutation({
//         mutationFn: (postData) => updateReport(postData.id, postData),
//         onSuccess: (data) => {
//             queryClient.invalidateQueries({ queryKey: [queryKeys.reports] });
//             if (data?.success) {
//                 showNotification("success", data.message);
//             } else {
//                 showNotification("error", data.message);
//             }
//         },
//     });
//     return { mutate, isLoading };
// }

// // Custom hook for deleting a report
// export function useDeleteReport() {

//     const queryClient = useQueryClient();
//     const { mutate, isLoading } = useMutation({
//         mutationFn: (reportId) => deleteReport(reportId),
//         onSuccess: (data) => {
//             queryClient.invalidateQueries({ queryKey: [queryKeys.reports] });
//             if (data?.success) {
//                 showNotification("success", data.message);
//             } else {
//                 showNotification("error", data.message);
//                 console.error("Error:", data.message);
//             }
//         },
//     });
//     return { deleteReport: mutate, isLoading };
// }
