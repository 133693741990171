// testReportSlice.js

import { createSlice } from "@reduxjs/toolkit";

const testReportInitialState = {
  defaultParams: {
    name: "",
    hid: "",
    offset: 0,
    limit: 10,
    startDate: "",
    endDate: "",
    ptId: ""
  },
  // Additional state properties for TestReport can be added here
};

const testReportSlice = createSlice({
  name: "testReport",
  initialState: testReportInitialState,
  reducers: {
    setTestReportParams: (state, action) => {
      state.defaultParams = { ...state.defaultParams, ...action.payload };
    },

    // Additional reducers for TestReport can be added here
    clearTestReport: (state, action) => {
      return testReportInitialState;
    },
  },
  extraReducers: {},
});

export const selectTestReportParams = (state) => state.testReport.defaultParams;
export const { setTestReportParams, clearTestReport } = testReportSlice.actions;
export default testReportSlice.reducer;
