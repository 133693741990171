
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { addIPDRefund, deleteIPDRefund, getIPDRefund, updateIPDRefund } from "../../../services/api";

export function useGetIPDRefund(refundID) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.ipdRefund],
        queryFn: () => getIPDRefund(refundID),
        enabled: refundID ? true : false,
    });

    return { ipdRefundData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

export function useAddIPDRefund() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: ({ refundID, body }) => addIPDRefund({ refundID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdRefund] });
            if (data?.success) {
                showNotification("success", `IPD refund added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addIPDRefund: mutate, isLoading };
}

export function useUpdateIPDRefund() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ refundID, body }) => updateIPDRefund({ refundID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdRefund] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { updateIPDRefund: mutate, isLoading };
}

export function useDeleteIPDRefund() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (refundID) => deleteIPDRefund(refundID),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdRefund] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteIPDRefund: mutate, isLoading };
}