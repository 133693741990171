import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    Fade,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { RiFileDownloadLine } from "react-icons/ri";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import {
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineReload,
} from "react-icons/ai";
import {
    useAddOPD,
    useDeleteOPD,
    useEditOPD,
    useGetOPDs,
} from "../query/hooks/opd/opdHook";
import dayjs from "dayjs";
import {
    getEndingTimeOfDate,
    getStartingTimeOfDate,
    sanitizeValue,
    showNotification,
    validateAddOPD,
    validateAddPatient,
} from "../services/helper";
import { useGetMetaData } from "../query/hooks/metadata/metadataHook";
import { useGetEmployees } from "../query/hooks/employee/employeeHooks";
import LoaderLogo from "./common/LoaderLogo";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import EditOPDModal from "./common/EditOPDModal";

import {
    useAddPatient,
    useAddPatientEntity,
} from "../query/hooks/patients/patientsHook";
import {
    addOPDFile,
    getOPDFile,
    searchPatients,
    skynet,
    uploadFile,
} from "../services/api";
import { IoMdAdd } from "react-icons/io";
import PdfFormat from "./common/PdfFormat";
import { FaFileUpload } from "react-icons/fa";
import { RiAiGenerate } from "react-icons/ri";
import {
    clearOPD,
    selectOPDParam,
    setOPDParams,
} from "../redux/features/filters/opdSlice";
import { selectEmployeesParam } from "../redux/features/filters/employeeSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReusableButton from "./common/ReusableButton";
import ClearButton from "./common/ClearButton";
import RedButton from "./common/RedButton";
import AddPatientForm from "./common/AddPatientForm";
import OPDSheet from "./common/OPDSheet";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-55%, -50%)",
    minWidth: 800,
    maxWidth: 1200,
    minHeight: 200,
    maxHeight: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
        width: 0,
    }, // Add this line for vertical scrolling
};

// const paymentModes = ["cash", "card"];
const columns = [
    { id: "id", label: "ID", minWidth: 90, align: "center" },
    { id: "hid", label: "HID", minWidth: 150, align: "center" },
    {
        id: "patient_name",
        label: "patient name",
        minWidth: 150,
        align: "left",
        marginLeft: "10px",
    },
    // {
    //     id: "entity_name",
    //     label: "Hospital/Clinic",
    //     minWidth: 170,
    //     align: "center",
    // },
    { id: "patient_mobile", label: "Mobile", minWidth: 170, align: "center" },
    {
        id: "department_name",
        label: "department",
        minWidth: 170,
        align: "center",
    },
    {
        id: "payable_amount",
        label: "payable amount",
        minWidth: 170,
        align: "center",
    },
    // { id: "email", label: "Email", align: "center" },
    {
        id: "createdDate",
        label: "Created Date",
        minWidth: 120,
        align: "center",
    },

    { id: "actions", label: "Actions", minWidth: 200, align: "center" },
];
const initialData = {
    aadhaar: "",
    title: "",
    name: "",
    mobile: "",
    email: "",
    age: 0,
    months: 0,
    days: 0,
    gender: "",
    marital_status: "",
    care_of: "",
    relationship: "",
    occupation: "",
    address: "",
    district: "",
    pincode: "",
    remarks: "",
};
const initialPatientState = {
    patient: 0,
    department: "",
    doctor: "",
    charge: 0.0,
    payable_amount: 0.0,
    payment_mode: "",
    payment_reference_number: "",
    discount_amount: 0.0,
    discount_percent: 0.0,
    discount_remark: "",
};

const OpdBooking = () => {
    const opdParams = useSelector(selectOPDParam);
    const [ptID, setPtID] = useState();
    const [step, setStep] = useState(1);
    const currentAuth = useSelector(selectCurrentAuth);
    const opdsPermission = currentAuth?.user?.permissions.find(
        (permission) => permission.module === 1010
    );
    const {
        add_permission,
        update_permission,
        delete_permission,
        view_permission,
    } = opdsPermission;
    const { metaData } = useGetMetaData();
    const {
        opdsData,
        isLoading,
        isSuccess,
        isFetching,
        isRefetching,
        refetch,
    } = useGetOPDs(opdParams);
    const employeeParams = useSelector(selectEmployeesParam);
    const { employeesData } = useGetEmployees(employeeParams);
    const { editOPD, isLoading: isEditingOPD } = useEditOPD();
    const { deleteOPD, isLoading: isDeletingOPD } = useDeleteOPD();
    const [open, setOpen] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [toDelete, setToDelete] = useState("");

    const handleClose = () => {
        setOpen(false);
        setOpenOPD({});
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "doctor") {
            setOpenOPD((prevState) => ({
                ...prevState,
                doctor: value.id,
                department: value.department,
            }));
        } else {
            const numericValue =
                name === "charge" ||
                name === "payableAmount" ||
                name === "discountAmount" ||
                name === "discountPercent"
                    ? parseFloat(value.replace(/[^\d.]/g, ""))
                    : value;

            const updatedState = {
                ...openOPD,
                [name]: numericValue,
            };

            // Calculate discount and payable amount
            const { charge, discount_amount } = updatedState;

            let discount_percent = (discount_amount / charge) * 100;
            discount_percent = isNaN(discount_percent)
                ? 0
                : parseFloat(discount_percent).toFixed(2);
            const payable_amount = charge - discount_amount;

            // Update state with new values
            setOpenOPD({
                ...updatedState,
                discount_percent: discount_percent,
                payable_amount: payable_amount,
            });
        }
    };

    const handleOPDSubmit = (event) => {
        event.preventDefault();
        const editedOPDS = {
            id: parseInt(openOPD.id),
            patient: parseInt(openOPD.patient),
            department: parseInt(openOPD.department),
            doctor: parseInt(openOPD.doctor),
            charge: parseFloat(openOPD.charge),
            payable_amount: parseFloat(openOPD.payable_amount),
            payment_mode: openOPD.payment_mode,
            payment_reference_number: openOPD.payment_reference_number,
            discount_amount: parseFloat(openOPD.discount_amount),
            discount_percent: parseFloat(openOPD.discount_percent),
            discount_remark: openOPD.discount_remark,
        };

        const { emptyFields, zeroValueFields } = validateAddOPD(editedOPDS);
        setValErr({ emptyFields, zeroValueFields });

        if (emptyFields.length > 0 || zeroValueFields.length > 0) {
            showNotification("error", "Fill All Fields Before Submitting");
        } else {
            editOPD(editedOPDS);
            handleClose();
        }
    };

    const filteredDoctors = employeesData?.result?.list?.filter(
        (employee) => employee.designation_name === "Doctor"
    );

    const [selectedOPD, setSelectedOPD] = useState(null);

    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };

    // Function to close the delete confirmation dialog
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    // Function to handle the delete action when confirmed
    const handleDeleteConfirmed = () => {
        // Close the dialog
        deleteOPD(toDelete);
        handleCloseDeleteDialog();
    };
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const handleOpenEditModal = () => {
        setIsEditModalOpen(true);
    };

    // Function to close the modal
    const handleEditCloseModal = () => {
        setIsEditModalOpen(false);
    };
    const handleEditClick = (row) => {
        setOpenOPD(row);
        setSelectedOPD(row);
        handleOpenEditModal(); // Set the role to be edited
        setOpen(true);
    };
    const [patient, setPatient] = useState({ ...initialData });
    const handlePTOpen = () => setPtOpen(true);

    const handlePTClose = () => {
        setPatient({ ...initialData });
        setStep(1);
        setPtOpen(false);
        handleClear();
    };

    const {
        addPatient,
        isLoading: isAddingPatient,
        isSuccess: isAdded,
    } = useAddPatient(setPtID, handlePTClose, setStep);
    const {
        addPatientEntity,
        isLoading: isAddingPatientEntity,
        isSuccess: isAddedPtEntity,
    } = useAddPatientEntity(setStep);
    const [ptOpen, setPtOpen] = useState(false);
    const initialPatientId = { aadhaar: "", hid: "" };
    const [patientId, setPatientId] = useState(initialPatientId);
    const [isAadharDisabled, setIsAadharDisabled] = useState(false);
    const [isHidDisabled, setIsHidDisabled] = useState(false);

    const handleAddPatient = () => {
        const isValid = validateAddPatient(patient);
        if (isValid) {
            patient.months = sanitizeValue(patient.months);
            patient.day = sanitizeValue(patient.day);
            patient.age = sanitizeValue(patient.age);
            addPatient(patient);
            if (step === 3) {
                setPatient("");
                setPatient({ ...initialData });

                setPatientState((prevState) => ({
                    ...prevState,
                    patient: ptID,
                }));
                handleClear();
            }
        } else {
            showNotification(
                "error",
                "Make Sure All Required Field Are Filled"
            );
        }
    };
    useEffect(() => {
        if (ptID) {
            setPatientState((prevState) => ({
                ...prevState,
                patient: ptID,
            }));
        }
    }, [ptID]);
    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;
        const numericValue =
            (name === "age" || name === "months" || name === "days") &&
            !isNaN(parseInt(value, 10))
                ? parseInt(value, 10)
                : name === "mobile"
                ? value.replace(/\D/g, "").slice(0, 10)
                : name === "aadhaar"
                ? value.replace(/\D/g, "").slice(0, 12)
                : value;

        setPatient((prevPatient) => ({
            ...prevPatient,
            [name]: numericValue,
        }));
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setPatient((prevPatient) => ({
            ...prevPatient,
            [name]: value,
        }));
    };

    const handleAadharChange = (e) => {
        const value = e.target.value;
        setPatientId((prev) => ({
            ...prev,
            aadhaar: value,
        }));
        setIsHidDisabled(value !== "");
    };

    const handleHidChange = (e) => {
        const value = e.target.value;
        setPatientId((prev) => ({
            ...prev,
            hid: value,
        }));
        setIsAadharDisabled(value !== "");
    };

    const handleClear = () => {
        setPatientId(initialPatientId);
        setIsAadharDisabled(false);
        setIsHidDisabled(false);
    };
    const [ptExist, setPtExist] = useState(false);

    const handleSearchPatient = async () => {
        try {
            if (!patientId.aadhaar && !patientId.hid) {
                showNotification(
                    "error",
                    "Please provide either aadhaar or HID."
                );
                return;
            }
            const result = await searchPatients(
                patientId.aadhaar,
                patientId.hid
            );

            if (result.success) {
                setPtExist(true);
                setPatientState((prevState) => ({
                    ...prevState,
                    patient: result.result.id,
                }));
                const updatedPatient = {
                    aadhar: result.result.aadhar || "",
                    title: result.result.title || "",
                    name: result.result.name || "",
                    mobile: result.result.mobile || "",
                    email: result.result.email || "",
                    age: result.result.age || 0,
                    months: result.result.months || 0,
                    days: result.result.days || 0,
                    gender: result.result.gender || "",
                    marital_status: result.result.marital_status || "",
                    care_of: result.result.care_of || "",
                    relationship: result.result.relationship || "",
                    occupation: result.result.occupation || "",
                    address: result.result.address || "",
                    district: result.result.district || "",
                    pincode: result.result.pincode || "",
                    remarks: result.result.remarks || "",
                };
                setPatient({ ...updatedPatient });
                const foundPt = {
                    patient: result.result.id,
                };
                addPatientEntity(foundPt);
                setStep(2);
            } else {
                setStep(2);
                setPtExist(false);
                showNotification("error", "Patient not Found, Add New Entry");

                setPatient({ ...initialData });
            }
        } catch (error) {
            console.error("Error occurred during search:", error);
        }
    };
    const handleCloseOPD = () => {
        if (finalStep) {
            setPatientState({ ...initialPatientState });
            setValErr([]);
            setStep(1);
            setPtExist(false);
            // setFinalStep(false);
            handlePTClose();
        } else {
            setPatientState({ ...initialPatientState });
            setValErr([]);
            setStep(1);
            setPtExist(false);
            setFinalStep(false);
            handlePTClose();
        }
    };
    const [finalStep, setFinalStep] = useState(false);
    const { addOPD, isLoading: isAddingOPD } = useAddOPD(
        setFinalStep,
        handleCloseOPD
    );

    const [patientState, setPatientState] = useState({
        ...initialPatientState,
    });

    useEffect(() => {
        const total = parseFloat(patientState.charge);
        const discount = parseFloat(patientState.discount_amount);

        // Check if discount amount is greater than the total charge
        if (!isNaN(total) && !isNaN(discount) && discount <= total) {
            let payable = total - discount;

            setPatientState((prevLabTest) => ({
                ...prevLabTest,
                payable_amount: payable,
            }));
        } else {
            showNotification(
                "error",
                "Discount Amount cannot be greater than Charge"
            );
            setPatientState((prevLabTest) => ({
                ...prevLabTest,
                payable_amount: 0,
                discount_amount: 0,
            }));
        }
    }, [patientState.discount_amount, patientState.charge]);
    useEffect(() => {
        // Calculate discount percentage based on total charge and discount amount
        const total = parseFloat(patientState.charge);
        const discount = parseFloat(patientState.discount_amount);

        if (!isNaN(total) && !isNaN(discount) && total > 0) {
            const discountPercent = (discount / total) * 100;

            // Update the state with the calculated discount percentage
            setPatientState((prevLabTest) => ({
                ...prevLabTest,
                discount_percent: discountPercent.toFixed(2),
            }));
        } else {
            // If invalid input, reset the discount percentage to 0
            setPatientState((prevLabTest) => ({
                ...prevLabTest,
                discount_percent: 0,
            }));
        }
    }, [patientState.discount_amount, patientState.charge]);

    const [openOPD, setOpenOPD] = useState(false);
    const [valErr, setValErr] = useState([]);
    const [selectedOPDGenBill, setSelectedOPDGenBill] = useState("");

    const handleOPDInputChange = (event) => {
        const { name, value } = event.target;

        if (name === "doctor") {
            setPatientState((prevState) => ({
                ...prevState,
                doctor: value.id,
                department: value.department,
            }));
        } else {
            const numericValue =
                name === "charge" ||
                // name === "payable_amount" ||
                name === "discount_amount" ||
                name === "discount_percent"
                    ? parseFloat(value.replace(/[^\d.]/g, ""))
                    : value;

            // Update state with new values
            setPatientState((prevState) => ({
                ...prevState,
                [name]: numericValue,
            }));
        }
    };

    const handleAddOPDSubmit = (event) => {
        event.preventDefault();
        const addOPDS = {
            // id: parseInt(patientState.id),
            patient: parseInt(patientState.patient),
            department: parseInt(patientState.department),
            doctor: parseInt(patientState.doctor),
            charge: parseFloat(patientState.charge),
            payable_amount: parseFloat(patientState.payable_amount),
            payment_mode: patientState.payment_mode,
            payment_reference_number: patientState.payment_reference_number,
            discount_amount: parseFloat(patientState.discount_amount),
            discount_percent: parseFloat(patientState.discount_percent),
            discount_remark: patientState.discount_remark,
        };
        event.preventDefault();
        const { emptyFields, zeroValueFields } = validateAddOPD(addOPDS);
        setValErr({ emptyFields, zeroValueFields });

        if (emptyFields.length > 0 || zeroValueFields.length > 0) {
            showNotification("error", "Fill All Fields Before Submitting");
        } else {
            addOPD(addOPDS);
            handlePTClose();
        }
    };
    const handleNextStep = () => {
        setStep(3);
    };

    const [isBillModalOpen, setIsBillModalOpen] = useState(false);

    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const [hovered, setHovered] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const uploadImage = async (img) => {
        try {
            const formData = new FormData();
            formData.append("file", img);
            const response = await uploadFile(formData);
            setIsUploading(true);
            if (response.status === 200 && response.data.success) {
                // setUrl(response.data.result);
                setImage(null);
                setIsUploading(false);
                return response.data.result;
            }
            showNotification("success", "Image uploaded successfully");
        } catch (error) {
            console.error(error);
            showNotification("error", "Cannot Upload Image");
            return;
        }
    };
    const [isFileUploading, setIsFileUploading] = useState(false);
    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        setImage(file);
        setIsFileUploading(true);
        try {
            const url = await uploadImage(file);
            setTimeout(() => {
                // setEditedDpt((prevDpt) => ({ ...prevDpt, image: url }))
                setIsFileUploading(false);
                setImageUrl(url);
            }, 2000);
        } catch (error) {
            console.warn(error);
        }
    };

    const handleClearImage = () => {
        setImage(null);
        setImageUrl(null);
    };

    const handleGenerateBill = (row) => {
        setSelectedOPDGenBill(row);
        setIsBillModalOpen(true);
    };
    const closeBillModal = () => {
        setIsBillModalOpen(false);
        setSelectedOPDGenBill("");
    };
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [selectedOPDUpload, setSelectedOPDUpload] = useState("");

    const handleOPDUpload = (row) => {
        setIsUploadModalOpen(true);
        setSelectedOPDUpload(row);
    };

    const handleOPDUploadClose = () => {
        setIsUploadModalOpen(false);
        setIsFileUploading(false);
        setImage(null);
        setCaption("");
        setImageUrl(null);
        setIsFileUploading(false);
    };
    const [caption, setCaption] = useState("");

    const handleCaptionChange = (event) => {
        if (event.target.value.length <= 100) {
            setCaption(event.target.value);
        }
    };

    const handleFileSubmit = async (event) => {
        try {
            if (
                !imageUrl ||
                !caption ||
                typeof imageUrl !== "string" ||
                typeof caption !== "string"
            ) {
                showNotification("error", "Please Try Again Later.");
                handleOPDUploadClose();
                return;
            }

            const obj = {
                title: caption,
                file: imageUrl,
            };

            const res = await addOPDFile(obj, selectedOPDUpload.id);

            if (res.success) {
                showNotification("success", "File Uploaded Successfully");
                handleOPDUploadClose();
            }
        } catch (error) {
            console.warn(error);
        }
    };
    const handleGetOPDFile = async () => {
        setIsFileUploading(true);
        try {
            const res = await getOPDFile(selectedOPDUpload?.id);
            if (res.success) {
                setImageUrl(res.result.list[0].file);
                setIsFileUploading(false);
            } else {
                setIsFileUploading(false);
            }
        } catch (error) {
            console.warn(error);
        }
    };
    useEffect(() => {
        if (selectedOPDUpload) {
            handleGetOPDFile();
        }
    }, [selectedOPDUpload]);

    const [searchParams, setSearchParams] = useState({
        name: "",
        id: "",
        hid: "",
        department: "",
        mobile: "",
    });

    const dispatch = useDispatch();
    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            [name]: value,
        }));
        // dispatch(setOPDParams({ [name]: value }));
    };
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            dispatch(
                setOPDParams({
                    ...opdParams,
                    startDate: getStartingTimeOfDate(dayjs(start)) || "",
                    endDate: getEndingTimeOfDate(dayjs(end)) || "",
                })
            );
        }
    };

    const handleClearSearch = () => {
        setSearchParams({
            name: "",
            id: "",
            hid: "",
            department: "",
            mobile: "",
        });
        dispatch(clearOPD());
        setSelectedOption(null);
        setInputValue("");
        setStartDate();
        setEndDate();
    };
    const [currentPage, setCurrentPage] = useState(1);
    const handlePaginationChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setOPDParams({
                ...opdParams,
                offset: (value - 1) * opdParams.limit,
            })
        );
    };
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState("");

    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            department: newValue.id,
        }));
        // dispatch(
        //     setOPDParams({
        //         ...opdParams,
        //         department: parseInt(newValue.id),
        //     })
        // );
    };

    const handleInputChange2 = (event, newInputValue) => {
        setInputValue(newInputValue);
    };
    const handleClearDept = () => {
        setSelectedOption(null);
        setInputValue("");
        dispatch(
            setOPDParams({
                ...opdParams,
                department: "",
            })
        );
    };
    const isValidMobileNumber = (mobile) => {
        // Remove non-numeric characters
        const cleanedMobile = mobile.replace(/\D/g, "");

        // Check if the cleaned mobile number has exactly 10 digits
        return cleanedMobile.length === 10;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // Check if startDate is a valid date
        const isStartDateValid =
            startDate instanceof Date && !isNaN(startDate.getTime());

        // Check if endDate is a valid date
        const isEndDateValid =
            endDate instanceof Date && !isNaN(endDate.getTime());

        dispatch(
            setOPDParams({
                ...opdParams,
                id: searchParams.id || "",
                name: searchParams.name || "",
                hid: searchParams.hid,
                department: searchParams.department || "",
                mobile: isValidMobileNumber(searchParams.mobile)
                    ? searchParams.mobile
                    : "",
                startDate: isStartDateValid
                    ? getStartingTimeOfDate(dayjs(startDate))
                    : "",
                endDate: isEndDateValid
                    ? getEndingTimeOfDate(dayjs(endDate))
                    : "",
            })
        );
    };
    const handleClearHID = () => {
        setSearchParams({ ...searchParams, hid: "" });
    };
    const handleClearID = () => {
        setSearchParams({ ...searchParams, id: "" });
    };

    const handleClearMobile = () => {
        setSearchParams({ ...searchParams, mobile: "" });
    };

    const handleClearDepartment = () => {
        setSelectedOption(null);
        setInputValue("");
    };

    const handleClearDate = () => {
        setStartDate(null);
        setEndDate(null);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault(); // Prevent form submission
        handleSubmit();
    };
    const isOptionEqualToValue = (option, value) => option.id === value.id;

    const handleKeyPress = (event) => {
        if (event.key === "Escape") {
            event.preventDefault(); // Prevent default Esc behavior
            handleClearSearch();
        }
    };
    const [selectedDoctor, setSelectedDoctor] = useState("");

    const handleDoctorChange = (event, value) => {
        setSelectedDoctor(value);
        setPatientState((prevState) => ({
            ...prevState,
            doctor: value.id,
            department: value.department,
        }));
    };
    const handleDoctorClear = () => {
        setSelectedDoctor("");
        setPatientState((prevState) => ({
            ...prevState,
            doctor: "",
            department: "",
        }));
    };
    const handleKeyPress2 = (event) => {
        if (event.key === "Enter") {
            if (!ptExist) {
                handleAddPatient();
            } else {
                handleNextStep();
            }
        }
    };
    const [sheetModal, setSheetModal] = useState(false);
    const [sheetData, setSheetData] = useState("");
    const handleOPDSheet = (row) => {
        setSheetModal(true);
        setSheetData(row);
    };
    const handleCloseOPDSheet = (row) => {
        setSheetModal(false);
        setSheetData("");
    };
    return (
        <>
            <OPDSheet
                billData={sheetData}
                closeBillModal={handleCloseOPDSheet}
                isBillModalOpen={sheetModal}
            />
            <PdfFormat
                billData={selectedOPDGenBill}
                closeBillModal={closeBillModal}
                isBillModalOpen={isBillModalOpen}
            />

            <div className="flex justify-between px-10 mb-5">
                <div>
                    <div className="flex items-center space-x-3">
                        <Typography variant="h5">OPDs</Typography>
                        {add_permission === 1 && (
                            <Tooltip title="Refresh" placement="bottom-start">
                                <button
                                    onClick={refetch}
                                    className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                    style={{ cursor: "pointer" }}
                                >
                                    <AiOutlineReload size={20} />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <Tooltip title="Add " placement="bottom-start">
                    <button
                        onClick={handlePTOpen}
                        className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                    >
                        <IoMdAdd size={18} />
                    </button>
                </Tooltip>
            </div>
            <form onSubmit={handleSubmit}>
                <div
                    className="flex items-center justify-around"
                    style={{ position: "relative" }}
                    onKeyDown={handleKeyPress}
                    tabIndex={0}
                >
                    <TextField
                        sx={{
                            width: "150px",
                        }}
                        label="Search ID"
                        variant="outlined"
                        name="id"
                        value={searchParams.id}
                        onChange={handleSearch}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {searchParams.id && (
                                        <ClearIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={handleClearID}
                                        />
                                    )}
                                </>
                            ),
                        }}
                        InputLabelProps={{ shrink: Boolean(searchParams.id) }} // Here's the addition
                    />
                    <TextField
                        label="Search HID"
                        variant="outlined"
                        name="hid"
                        value={searchParams.hid}
                        onChange={handleSearch}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {searchParams.hid && (
                                        <ClearIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={handleClearHID}
                                        />
                                    )}
                                </>
                            ),
                        }}
                    />
                    <TextField
                        label="Search Phone Number"
                        variant="outlined"
                        name="mobile"
                        value={searchParams.mobile}
                        onChange={handleSearch}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                            );
                        }}
                        InputProps={{
                            inputProps: {
                                maxLength: 10,
                                pattern: "[0-9]*",
                            },
                            endAdornment: (
                                <>
                                    {searchParams.mobile && (
                                        <ClearIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={handleClearMobile}
                                        />
                                    )}
                                </>
                            ),
                        }}
                    />
                    <Autocomplete
                        className="w-[220px]"
                        options={metaData?.result?.departments || []}
                        getOptionLabel={(option) => option.name}
                        value={selectedOption}
                        onChange={handleOptionChange}
                        inputValue={inputValue}
                        onInputChange={handleInputChange2}
                        disableClearable
                        isOptionEqualToValue={(option, value) =>
                            option.id === value?.id
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search Department"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {selectedOption && (
                                                <ClearIcon
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={
                                                        handleClearDepartment
                                                    }
                                                />
                                            )}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                    <div style={{ zIndex: "999" }}>
                        <DatePicker
                            maxDate={dayjs().toDate()}
                            className="px-6 py-4 w-[220px] border border-gray-300 rounded-xl"
                            dateFormat="yyyy/MM/dd"
                            placeholderText="StartDate - EndDate"
                            selectsRange={true}
                            isClearable
                            selected={startDate}
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </div>
                    <ReusableButton
                        title="Search"
                        type="submit"
                        // onClick={handleSubmit}
                    />
                    <ClearButton title="Clear" onClick={handleClearSearch} />
                </div>
            </form>
            <section className="mt-10">
                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "15px",
                    }}
                    elevation={24}
                >
                    <TableContainer
                        sx={{
                            height: 520,
                            overflowX: "scroll",
                            "&::-webkit-scrollbar": {
                                width: 0,
                            },
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                                color: "white",
                                                marginRight: column.marginLeft,
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {!isLoading && !isFetching && !isRefetching ? (
                                <TableBody>
                                    {opdsData?.result?.list?.map((row) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                key={row.id}
                                                align="center"
                                            >
                                                <TableCell
                                                    align="center"
                                                    size="medium"
                                                >
                                                    {row.id}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    size="medium"
                                                >
                                                    {row.hid}
                                                </TableCell>
                                                <TableCell
                                                    align="justify"
                                                    size="medium"
                                                >
                                                    {row.patient_name}
                                                </TableCell>
                                                {/* <TableCell align="center">
                                                    {row.entity_name}
                                                </TableCell> */}
                                                <TableCell align="center">
                                                    {row.patient_mobile}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.department_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    ₹ {row.payable_amount}
                                                </TableCell>
                                                {/* <TableCell align="center">
                                                {row.email}
                                            </TableCell> */}
                                                <TableCell align="center">
                                                    {dayjs(
                                                        row.created_on
                                                    ).format(
                                                        "DD-MM-YYYY,h:mm:ss A"
                                                    )}
                                                </TableCell>

                                                <TableCell
                                                    align="center"
                                                    sx={{
                                                        width: 100,
                                                    }}
                                                >
                                                    <div className="flex items-center space-x-2 justify-evenly">
                                                        {update_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Edit"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleEditClick(
                                                                            row
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                                </button>
                                                            </Tooltip>
                                                        )}

                                                        {view_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Generate OPD Sheet"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleOPDSheet(
                                                                            row
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <RiFileDownloadLine className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                        {view_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Generate Bill"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleGenerateBill(
                                                                            row
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <RiAiGenerate className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                        {update_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Upload OPD File"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleOPDUpload(
                                                                            row
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <FaFileUpload className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                        {delete_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Delete"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleOpenDeleteDialog(
                                                                            row.id
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                                >
                                                                    <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            colSpan={7}
                                            align="center"
                                            sx={{
                                                width: 120,
                                            }}
                                        >
                                            <div className="flex items-center justify-center">
                                                <LoaderLogo />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    {!isLoading && !isFetching && !isRefetching && (
                        <div className="flex items-center justify-center my-4">
                            <Pagination
                                count={Math.ceil(
                                    opdsData?.result?.count / opdParams.limit
                                )}
                                page={currentPage}
                                sx={{
                                    "& .MuiSelect-select": {
                                        display: "none !important",
                                    },
                                    "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                        {
                                            display: "none !important",
                                        },
                                }}
                                onChange={handlePaginationChange}
                            />
                        </div>
                    )}
                </Paper>
            </section>

            {selectedOPD && (
                <EditOPDModal
                    open={isEditModalOpen}
                    handleClose={handleEditCloseModal}
                    handleInputChange={handleInputChange}
                    handleOPDSubmit={handleOPDSubmit}
                    selectedOPD={selectedOPD}
                    valErr={valErr}
                />
            )}

            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeletingOPD}
            />
            <Modal
                open={ptOpen}
                onClose={handlePTClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={ptOpen}>
                    <Box sx={styles}>
                        <>
                            {step === 1 && (
                                <div className="justify-center w-full space-y-4 ">
                                    <Typography variant="h4">
                                        Search Patient
                                    </Typography>
                                    <TextField
                                        required
                                        name="aadhaar"
                                        className="col-span-2"
                                        label="Enter Patient Aadhaar"
                                        variant="outlined"
                                        fullWidth
                                        value={patientId.aadhaar}
                                        onChange={handleAadharChange}
                                        disabled={isAadharDisabled}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    sx={{
                                                        visibility:
                                                            patientId.aadhaar
                                                                ? "visible"
                                                                : "hidden",
                                                    }}
                                                    variant="outlined"
                                                    onClick={handleClear}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        inputProps={{
                                            inputMode: "numeric",
                                            pattern: "[0-9]*", // Allow only numeric input
                                            maxLength: 12,
                                        }}
                                        onInput={(e) => {
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                ); // Remove non-numeric characters
                                        }}
                                    />
                                    <TextField
                                        required
                                        name="hid"
                                        className="col-span-2"
                                        label="Enter Patient HID"
                                        variant="outlined"
                                        fullWidth
                                        value={patientId.hid}
                                        onChange={handleHidChange}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    sx={{
                                                        visibility:
                                                            patientId.hid
                                                                ? "visible"
                                                                : "hidden",
                                                    }}
                                                    variant="outlined"
                                                    onClick={handleClear}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        disabled={isHidDisabled}
                                    />

                                    <div className="flex w-full py-2 justify-evenly ">
                                        <ReusableButton
                                            title="Search"
                                            onClick={handleSearchPatient}
                                        />

                                        <RedButton
                                            title="Cancel"
                                            onClick={handlePTClose}
                                        />
                                    </div>
                                </div>
                            )}
                            {step === 2 && (
                                <AddPatientForm
                                    handleKeyPress2={handleKeyPress2}
                                    handleSelectChange={handleSelectChange}
                                    handleTextFieldChange={
                                        handleTextFieldChange
                                    }
                                    handleAddPatient={handleAddPatient}
                                    handleNextStep={handleNextStep}
                                    handlePTClose={handlePTClose}
                                    patient={patient}
                                    ptExist={ptExist}
                                />
                            )}
                            {step === 3 && (
                                <div>
                                    <Typography
                                        variant="h5"
                                        className="text-center"
                                    >
                                        Add OPD
                                    </Typography>
                                    <FormControl fullWidth>
                                        <div className="flex flex-wrap gap-5 p-2">
                                            <TextField
                                                required
                                                label="Patient ID"
                                                name="patient"
                                                value={patientState.patient}
                                                onChange={handleOPDInputChange}
                                                variant="outlined"
                                                margin="normal"
                                                disabled
                                            />
                                            <div className="flex items-center w-full space-x-5">
                                                {/* <FormControl
                                                    sx={{
                                                        width: "40%",
                                                    }}
                                                    variant="outlined"
                                                    margin="dense"
                                                    error={valErr?.emptyFields?.includes(
                                                        "doctor"
                                                    )}
                                                >
                                                    <InputLabel id="payment-mode-label">
                                                        Doctor
                                                    </InputLabel>
                                                    <Select
                                                        label="Doctor"
                                                        id="doctor"
                                                        name="doctor"
                                                        value={
                                                            filteredDoctors?.filter(
                                                                (doctor) =>
                                                                    doctor.id ===
                                                                    patientState.doctor
                                                            )[0]
                                                        }
                                                        onChange={
                                                            handleOPDInputChange
                                                        }
                                                    >
                                                        {filteredDoctors?.map(
                                                            (doctor) => (
                                                                <MenuItem
                                                                    key={
                                                                        doctor.id
                                                                    }
                                                                    value={
                                                                        doctor
                                                                    }
                                                                    className="capitalize"
                                                                >
                                                                    {
                                                                        doctor.name
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                    <FormHelperText>
                                                        {valErr?.emptyFields?.includes(
                                                            "doctor"
                                                        )
                                                            ? "Doctor is required"
                                                            : undefined}
                                                    </FormHelperText>
                                                </FormControl> */}
                                                <Autocomplete
                                                    sx={{
                                                        width: "40%",
                                                    }}
                                                    options={filteredDoctors}
                                                    disableClearable
                                                    getOptionLabel={(option) =>
                                                        option
                                                            ? option.name
                                                                  .charAt(0)
                                                                  .toUpperCase() +
                                                              option.name.slice(
                                                                  1
                                                              )
                                                            : ""
                                                    }
                                                    value={selectedDoctor}
                                                    onChange={
                                                        handleDoctorChange
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Search Doctor"
                                                        />
                                                    )}
                                                    isOptionEqualToValue={
                                                        isOptionEqualToValue
                                                    }
                                                />
                                                <FormControl
                                                    sx={{
                                                        width: "40%",
                                                    }}
                                                    margin="dense"
                                                    error={valErr?.emptyFields?.includes(
                                                        "department"
                                                    )}
                                                >
                                                    <InputLabel id="department-label">
                                                        Department
                                                    </InputLabel>
                                                    <Select
                                                        id="department"
                                                        label="Department"
                                                        name="department"
                                                        value={
                                                            patientState.department ||
                                                            ""
                                                        }
                                                        onChange={
                                                            handleOPDInputChange
                                                        }
                                                        disabled
                                                    >
                                                        {metaData?.result?.departments?.map(
                                                            (department) => (
                                                                <MenuItem
                                                                    key={
                                                                        department.id
                                                                    }
                                                                    value={
                                                                        department.id
                                                                    }
                                                                    className="capitalize"
                                                                >
                                                                    {
                                                                        department.name
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                    <FormHelperText>
                                                        {valErr?.emptyFields?.includes(
                                                            "department"
                                                        )
                                                            ? "Department is required"
                                                            : undefined}
                                                    </FormHelperText>
                                                </FormControl>
                                            </div>
                                            <div className="flex items-center w-full space-x-5 ">
                                                <TextField
                                                    required
                                                    label="Charge"
                                                    name="charge"
                                                    value={
                                                        patientState.charge || 0
                                                    }
                                                    onChange={
                                                        handleOPDInputChange
                                                    }
                                                    variant="outlined"
                                                    margin="dense"
                                                    helperText={
                                                        valErr?.zeroValueFields?.includes(
                                                            "charge"
                                                        )
                                                            ? "Charge is required"
                                                            : null
                                                    }
                                                    error={valErr?.zeroValueFields?.includes(
                                                        "charge"
                                                    )}
                                                />

                                                <TextField
                                                    required
                                                    label="Payable Amount"
                                                    name="payable_amount"
                                                    value={
                                                        patientState.payable_amount ||
                                                        0
                                                    }
                                                    onChange={
                                                        handleOPDInputChange
                                                    }
                                                    variant="outlined"
                                                    margin="dense"
                                                    disabled
                                                    helperText={
                                                        valErr?.zeroValueFields?.includes(
                                                            "payable_amount"
                                                        )
                                                            ? "Payable Amount is required"
                                                            : null
                                                    }
                                                    error={valErr?.zeroValueFields?.includes(
                                                        "payable_amount"
                                                    )}
                                                />
                                            </div>
                                            <div className="flex items-center w-full space-x-4">
                                                <FormControl
                                                    sx={{
                                                        width: "40%",
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="dense"
                                                    error={valErr?.emptyFields?.includes(
                                                        "payment_mode"
                                                    )}
                                                >
                                                    <InputLabel id="payment-mode-label">
                                                        Payment Mode
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId="payment-mode-label"
                                                        label="Payment Mode"
                                                        name="payment_mode"
                                                        value={(
                                                            patientState.payment_mode ||
                                                            ""
                                                        ).toLowerCase()} // Convert to lowercase and then capitalize
                                                        onChange={
                                                            handleOPDInputChange
                                                        }
                                                    >
                                                        {metaData?.result?.payment_modes?.map(
                                                            (mode) => (
                                                                <MenuItem
                                                                    key={mode}
                                                                    value={mode}
                                                                    className="capitalize"
                                                                >
                                                                    {mode
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase() +
                                                                        mode.slice(
                                                                            1
                                                                        )}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>

                                                    <FormHelperText>
                                                        {valErr?.emptyFields?.includes(
                                                            "payment_mode"
                                                        )
                                                            ? "Payment Mode is required"
                                                            : undefined}
                                                    </FormHelperText>
                                                </FormControl>
                                                <TextField
                                                    label="Discount Amount"
                                                    name="discount_amount"
                                                    value={
                                                        patientState.discount_amount ||
                                                        0
                                                    }
                                                    onChange={
                                                        handleOPDInputChange
                                                    }
                                                    variant="outlined"
                                                    margin="dense"
                                                />
                                                <TextField
                                                    required
                                                    label="Discount Percentage"
                                                    name="discount_percent"
                                                    value={
                                                        patientState.discount_percent
                                                    }
                                                    disabled
                                                    onChange={
                                                        handleOPDInputChange
                                                    }
                                                    variant="outlined"
                                                    margin="dense"
                                                />
                                            </div>
                                            <div className="flex items-center w-full space-x-4">
                                                <TextField
                                                    sx={{
                                                        width: "40%",
                                                    }}
                                                    label="Payment Reference Number"
                                                    name="payment_reference_number"
                                                    value={
                                                        patientState.payment_reference_number
                                                    }
                                                    onChange={
                                                        handleOPDInputChange
                                                    }
                                                    variant="outlined"
                                                    margin="dense"
                                                />
                                                <TextField
                                                    sx={{
                                                        width: "40%",
                                                    }}
                                                    label="Discount Remark"
                                                    name="discount_remark"
                                                    value={
                                                        patientState.discount_remark
                                                    }
                                                    onChange={
                                                        handleOPDInputChange
                                                    }
                                                    variant="outlined"
                                                    margin="dense"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-evenly">
                                            <ReusableButton
                                                title="Submit"
                                                onClick={handleAddOPDSubmit}
                                            />
                                            <RedButton
                                                onClick={handleCloseOPD}
                                                title="Cancel"
                                            />
                                        </div>
                                    </FormControl>
                                </div>
                            )}
                        </>
                    </Box>
                </Fade>
            </Modal>

            <Modal
                open={isUploadModalOpen}
                onClose={handleOPDUploadClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isUploadModalOpen}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-55%, -50%)",
                            minWidth: 400,
                            minHeight: 200,
                            maxWidth: 1200,
                            maxHeight: 650,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                            borderRadius: "5px",
                            overflowY: "scroll",
                            "&::-webkit-scrollbar": {
                                width: 0,
                            }, // Add this line for vertical scrolling
                        }}
                    >
                        <>
                            <p className="text-3xl font-semibold text-center">
                                Upload OPD Reports
                            </p>
                            <input
                                accept="image/jpeg,image/png,image/webp"
                                style={{ display: "none" }}
                                id="image-upload"
                                type="file"
                                onChange={handleImageChange}
                            />

                            <div className="flex items-center justify-center w-full py-2 mt-6">
                                <label htmlFor="image-upload">
                                    <Button
                                        variant="contained"
                                        component="span"
                                    >
                                        Select File
                                    </Button>
                                </label>
                            </div>

                            {imageUrl && (
                                <section
                                    style={{
                                        width: "100%",
                                        position: "relative",
                                        overflow: "hidden",
                                    }}
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                >
                                    <div
                                        className="flex items-center justify-center"
                                        style={{
                                            width: "300px",
                                            height: "300px",
                                        }}
                                    >
                                        <img
                                            src={`${skynet}/${imageUrl}`}
                                            alt="Preview"
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                        {hovered && (
                                            <div className="absolute top-0 right-0 bottom-0 left-0 bg-opacity-23 bg-gray-500/10 shadow-md backdrop-blur-[6.4px] border-1 border-gray-700/30 flex items-center justify-center transition-backdrop-filter duration-300 ease-in-out">
                                                <IconButton
                                                    onClick={handleClearImage}
                                                    disabled={isFileUploading}
                                                    style={{
                                                        color: "red", // Set the color based on your design
                                                    }}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </div>
                                        )}
                                    </div>
                                </section>
                            )}

                            <TextField
                                multiline
                                rows={4} // Set the number of rows
                                fullWidth
                                variant="outlined"
                                label="Title"
                                value={caption}
                                onChange={handleCaptionChange}
                                helperText={`${caption.length}/100 characters`}
                            />
                            <div className="flex items-center justify-center">
                                <ReusableButton
                                    title="Submit"
                                    onClick={handleFileSubmit}
                                    disabled={isFileUploading}
                                />
                            </div>
                        </>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default OpdBooking;
