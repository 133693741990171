// Import necessary dependencies
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { getMOTServices, addMOTService, updateMOTService, deleteMOTService } from "../../../services/api";
import { showNotification } from "../../../services/helper";

// Custom hook for fetching minor OT services
export function useGetMOTServices(params = { offset: 0, limit: 1000, name: "" }) {
    const { offset, limit, name } = params;
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.minotServices, { offset, limit, name }],
        queryFn: () => getMOTServices({ offset, limit, name }),
    });
    return { servicesData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

// Custom hook for adding a minor OT service
export function useAddMOTService() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (serviceData) => addMOTService(serviceData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.minotServices] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.mot] });
            if (data?.success) {
                showNotification("success", "Minor OT service added");
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addMOTService: mutate, isLoading };
}

// Custom hook for editing a minor OT service
export function useEditMOTService() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updateMOTService(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.minotServices] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.mot] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { editMOTService: mutate, isLoading };
}

// Custom hook for deleting a minor OT service
export function useDeleteMOTService() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (serviceId) => deleteMOTService(serviceId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.minotServices] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.mot] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { deleteMOTService: mutate, isLoading };
}
