// patientFilterSlice.js

import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for patientFilter
const patientFilterInitialState = {
    defaultParams: {
        name: "",
        hid: "",
        mobile: "",
        aadhaar: "",
        offset: 0,
        limit: 10,
    },
};

// Create a patientFilter slice using createSlice from Redux Toolkit
const patientFilterSlice = createSlice({
    name: "patientFilter",
    initialState: patientFilterInitialState,
    reducers: {
        // Define a reducer to set patient filter parameters
        setPatientFilterParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },
        // Define a reducer to clear patient filter parameters
        clearPatientFilter: (state) => {
            state.defaultParams = { ...patientFilterInitialState.defaultParams };
        },
    },
    extraReducers: {},
});

// Export selectors and actions for use in other parts of the application
export const selectPatientFilterParams = (state) => state.patientFilter.defaultParams;
export const { setPatientFilterParams, clearPatientFilter } = patientFilterSlice.actions;

// Export the reducer function for use in configuring the Redux store
export default patientFilterSlice.reducer;
