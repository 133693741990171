import {
    Backdrop,
    Box,
    Fade,
    Modal,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AiOutlineReload } from "react-icons/ai";
import AlternatingRowTable from "./common/AlternatingRowTable";
import RedButton from "./common/RedButton";
import ReusableButton from "./common/ReusableButton";
import { IoPersonAdd } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getStartingTimeOfDate } from "../services/helper";
import dayjs from "dayjs";

const jsonData = [
    {
        itemName: "Chair",
        category: "Furniture",
        quantity: 4,
        purchaseDate: "2023-10-15",
        price: 50,
        details: "Wooden chair with cushion seat",
        actions: "Edit/Delete",
    },
    {
        itemName: "Table",
        category: "Furniture",
        quantity: 1,
        purchaseDate: "2023-09-20",
        price: 120,
        details: "Large wooden table with drawers",
        actions: "Edit/Delete",
    },
    {
        itemName: "Lamp",
        category: "Lighting",
        quantity: 2,
        purchaseDate: "2023-11-05",
        price: 30,
        details: "Desk lamp with adjustable arm",
        actions: "Edit/Delete",
    },
    {
        itemName: "Bookshelf",
        category: "Furniture",
        quantity: 1,
        purchaseDate: "2023-08-25",
        price: 80,
        details: "Wooden bookshelf with multiple shelves",
        actions: "Edit/Delete",
    },
    {
        itemName: "Sofa",
        category: "Furniture",
        quantity: 1,
        purchaseDate: "2023-07-12",
        price: 300,
        details: "Fabric sofa with reclining feature",
        actions: "Edit/Delete",
    },
    {
        itemName: "Curtains",
        category: "Home Decor",
        quantity: 2,
        purchaseDate: "2023-06-18",
        price: 40,
        details: "Blackout curtains for bedroom",
        actions: "Edit/Delete",
    },
    {
        itemName: "Coffee Table",
        category: "Furniture",
        quantity: 1,
        purchaseDate: "2023-05-30",
        price: 90,
        details: "Glass coffee table with metal frame",
        actions: "Edit/Delete",
    },
    {
        itemName: "Dining Table",
        category: "Furniture",
        quantity: 1,
        purchaseDate: "2023-04-10",
        price: 200,
        details: "Wooden dining table with chairs",
        actions: "Edit/Delete",
    },
    {
        itemName: "Mirror",
        category: "Home Decor",
        quantity: 1,
        purchaseDate: "2023-03-22",
        price: 60,
        details: "Wall-mounted mirror with decorative frame",
        actions: "Edit/Delete",
    },
    {
        itemName: "Desk Chair",
        category: "Furniture",
        quantity: 1,
        purchaseDate: "2023-02-05",
        price: 70,
        details: "Ergonomic desk chair with lumbar support",
        actions: "Edit/Delete",
    },
];

const columns = [
    { id: "itemName", label: "Item Name", minWidth: 100, align: "center" },
    { id: "category", label: "Category", minWidth: 100, align: "center" },
    { id: "quantity", label: "Quantity", minWidth: 100, align: "center" },
    {
        id: "purchaseDate",
        label: "Purchase Date",
        minWidth: 120,
        align: "center",
    },
    { id: "price", label: "Price($)", minWidth: 100, align: "center" },
    { id: "details", label: "Details", minWidth: 150, align: "center" },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};
const Inventory = () => {
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const initialFields = {
        itemName: "",
        category: "",
        quantity: "",
        purchaseDate: new Date(),
        price: "",
        details: "",
    };

    const [fields, setFields] = useState(initialFields);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
        if (date) {
            // Assuming fields.purchaseDate is a string date in 'yyyy/MM/dd' format
            setFields((prevFields) => ({
                ...prevFields,
                purchaseDate: new Date(date),
            }));
        } else {
            setFields((prevFields) => ({
                ...prevFields,
                purchaseDate: null, // Assuming purchaseDate should be null when date is cleared
            }));
        }
    };
    const handleAdd = () => {
      
    };
    const handleCancel = () => {
        handleClose();
        setFields(initialFields);
    };

    return (
        <>
            <section className="mt-3">
                <div className="flex items-center space-x-3">
                    <Typography variant="h5">Inventory</Typography>
                    <Tooltip title="Refresh" placement="bottom-start">
                        <button
                            className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                            style={{ cursor: "pointer" }}
                        >
                            <AiOutlineReload size={20} />
                        </button>
                    </Tooltip>
                    <Tooltip title="Add User" placement="bottom-start">
                        <button
                            onClick={handleOpen}
                            className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                        >
                            <IoPersonAdd size={18} />
                        </button>
                    </Tooltip>
                </div>

                <div className="mt-4 ">
                    <AlternatingRowTable data={jsonData} columns={columns} />
                </div>
            </section>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            variant="h4"
                            component="h4"
                            sx={{
                                marginBottom: 2,
                            }}
                            className="text-center"
                        >
                            Add Inventory
                        </Typography>

                        <div className="grid grid-cols-2 gap-4 px-5">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="itemName"
                                name="itemName"
                                label="Item Name"
                                type="text"
                                required
                                fullWidth
                                value={fields.itemName}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                required
                                id="category"
                                name="category"
                                label="Category"
                                type="text"
                                fullWidth
                                value={fields.category}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                required
                                id="quantity"
                                name="quantity"
                                label="Quantity"
                                type="text"
                                fullWidth
                                value={fields.quantity}
                                onChange={handleChange}
                            />
                            <DatePicker  maxDate={dayjs().toDate()}
                                className="z-50 w-full px-6 py-4 border border-gray-300 rounded-xl "
                                dateFormat="yyyy/MM/dd"
                                placeholderText="Select Purchase Date"
                                isClearable
                                selected={fields.purchaseDate}
                                onCalendarOpen={handleDateChange}
                            />

                            <TextField
                                margin="dense"
                                required
                                id="price"
                                name="price"
                                label="Price"
                                type="text"
                                fullWidth
                                value={fields.price}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                required
                                id="details"
                                name="details"
                                label="Details"
                                type="text"
                                fullWidth
                                value={fields.details}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="flex p-6 justify-evenly">
                            <ReusableButton
                                // isDisabled={isAddingUser}
                                onClick={handleAdd}
                                title="Save"
                            />

                            <RedButton
                                // isDisabled={isAddingUser}
                                onClick={handleCancel}
                                title="Cancel"
                            />
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default Inventory;
