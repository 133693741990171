// roomSlice.js

import { createSlice } from "@reduxjs/toolkit";

const roomInitialState = {
    defaultParams: {
        name: "",
        startDate: "",
        endDate: "",
        offset: 0,
        limit: 10,
    },
};

const roomSlice = createSlice({
    name: "room",
    initialState: roomInitialState,
    reducers: {
        setRoomParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },

        clearRoom: (state, action) => {
            state.defaultParams = roomInitialState.defaultParams;
        },
    },
    extraReducers: {},
});

export const selectRoomParam = (state) => state.room.defaultParams;
export const { setRoomParams, clearRoom } = roomSlice.actions;
export default roomSlice.reducer;
