import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import { getItem } from "../services/helper";
import dayjs from "dayjs";
import {
    Backdrop,
    Box,
    Fade,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    selectCurrentClinic,
    setClinic,
} from "../redux/features/auth/authSlice";
import { useGetClinic } from "../query/hooks/clinic/clinicHook";
import { skynet } from "../services/api";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
const refundColumns = [
    { id: "hid", label: "HID", width: 150, align: "center" },
    { id: "patient_name", label: "PT NAME", minWidth: 70, align: "left" },

    {
        id: "refunded_amount",
        label: "Refunded Amount",
        minWidth: 70,
        align: "center",
    },
    { id: "created_on", label: "Created On", minWidth: 70, align: "center" },
];
const opdColumn = [
    { id: "hid", label: "HID", minWidth: 70, align: "center" },
    {
        id: "patient_name",
        label: "PT NAME",
        minWidth: 70,
        align: "left",
    },
    { id: "charge", label: "Charge", minWidth: 70, align: "center" },
    {
        id: "discount_amount",
        label: "Discount Amount",
        minWidth: 70,
        align: "center",
    },
    { id: "doctor_name", label: "Doctor Name", minWidth: 70, align: "center" },
    {
        id: "payment_mode",
        label: "Payment Mode",
        minWidth: 70,
        align: "center",
    },
];
const ipdsColumn = [
    { id: "hid", label: "HID", minWidth: 10, align: "center" },
    {
        id: "patient_name",
        label: "PT NAME",
        width: 120,
        align: "left",
    },
    {
        id: "admission_charge",
        label: "Admission Charge",
        minWidth: 70,
        align: "center",
    },
    {
        id: "payment_mode",
        label: "Payment Mode",
        minWidth: 70,
        align: "center",
    },
];
const ipdBillColumns = [
    { id: "hid", label: "HID", minWidth: 10, align: "center" },
    {
        id: "patient_name",
        label: "PT NAME",
        minWidth: 70,
        align: "center",
    },

    { id: "doctor_name", label: "Doctor Name", minWidth: 70, align: "center" },

    {
        id: "payable_amount",
        label: "Payable Amount",
        minWidth: 70,
        align: "center",
    },
    {
        id: "payment_mode",
        label: "Payment Mode",
        minWidth: 70,
        align: "center",
    },
    { id: "paid_on", label: "Paid On", minWidth: 70, align: "center" },
];

const motColumn = [
    { id: "id", label: "ID", minWidth: 10, align: "center" },
    {
        id: "payable_amount",
        label: "Payable Amount",
        minWidth: 70,
        align: "center",
    },

    {
        id: "payment_mode",
        label: "Payment Mode",
        minWidth: 70,
        align: "center",
    },
];
const labInvColumns = [
    { id: "id", label: "ID", minWidth: 10, align: "center" },
    {
        id: "payable_amount",
        label: "Payable Amount",
        minWidth: 70,
        align: "center",
    },
    {
        id: "discount_amount",
        label: "Discount Amount",
        minWidth: 70,
        align: "center",
    },
    {
        id: "payment_mode",
        label: "Payment Mode",
        minWidth: 70,
        align: "center",
    },
];
const depositColumns = [
    { id: "id", label: "ID", minWidth: 10, align: "center" },
    { id: "amount", label: "Amount", minWidth: 70, align: "center" },
    {
        id: "payment_mode",
        label: "Payment Mode",
        minWidth: 70,
        align: "center",
    },
];
const doctorPaymentsColumns = [
    { id: "id", label: "ID", minWidth: 10, align: "center" },
    { id: "doctor_ name", label: "Doctor", minWidth: 70, align: "center" },
    { id: "amount", label: "Amount", minWidth: 100, align: "center" },
    {
        id: "created_on",
        label: "Paid on",
        minWidth: 70,
        align: "center",
    },
];
const expenseColumn = [
    { id: "id", label: "ID", minWidth: 10, align: "center" },
    { id: "amount", label: "Amount", minWidth: 70, align: "center" },
    { id: "type", label: "Type", minWidth: 70, align: "center" },
    { id: "date", label: "Date", minWidth: 70, align: "center" },
];
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-55%, -50%)",
    minWidth: 800,
    maxWidth: 1200,
    minHeight: 200,
    maxHeight: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "2px",
    p: 4,
    borderRadius: "5px",
    "&::-webkit-scrollbar": {
        width: 0,
    }, // Add this line for vertical scrolling
};

const AccountBills = ({ analyticsData, isBillModalOpen, closeBillModal }) => {
    const dispatch = useDispatch();
    const currentClinic = useSelector(selectCurrentClinic);

    const { clinicData, isLoading } = useGetClinic();
    useEffect(() => {
        if (!currentClinic) {
            dispatch(setClinic(clinicData?.result));
        }
    }, [clinicData]);

    const billRef = useRef();
    const params = useParams();
    const getTotal = (data) => {
        return data.reduce((total, item) => total + item.amount, 0);
    };
    const getTotalPayable = (data) => {
        return data.reduce((total, item) => total + item.payable_amount, 0);
    };
    const getTotalIPDCharge = (data) => {
        return data.reduce((total, item) => total + item.admission_charge, 0);
    };

    // Assuming labTestReportData?.result is your data array
    const totalDeposit = getTotal(analyticsData?.result?.deposits || []);

    const totals = (analyticsData?.result?.deposits || []).reduce(
        (totals, deposit) => {
            if (deposit.payment_mode === "cash") {
                totals.totalCashDeposits += deposit.amount;
            } else if (deposit.payment_mode === "card") {
                totals.totalCardDeposits += deposit.amount;
            }
            return totals;
        },
        { totalCashDeposits: 0, totalCardDeposits: 0 }
    );
    const { totalCashDeposits, totalCardDeposits } = totals;

    const totalDoctorPayout = getTotal(
        analyticsData?.result?.doctorPayments || []
    );
    const totalExpenses = getTotal(analyticsData?.result?.expenses || []);
    const totalIPDBills = getTotalPayable(
        analyticsData?.result?.ipdBills || []
    );

    const totalPaidByCash = analyticsData?.result?.ipds?.reduce(
        (total, item) => {
            if (item.payment_mode === "cash") {
                return total + item.admission_charge;
            }
            return total;
        },
        0
    );

    const totalPaidByCard = analyticsData?.result?.ipds?.reduce(
        (total, item) => {
            if (item.payment_mode === "card") {
                return total + item.admission_charge;
            }
            return total;
        },
        0
    );

    const totalIPD = getTotalIPDCharge(analyticsData?.result?.ipds || []);

    const { cashTotal, cardTotal } = (
        analyticsData?.result?.ipdBills || []
    ).reduce(
        (totals, bill) => {
            if (bill.payment_mode === "cash") {
                totals.cashTotal += bill.payable_amount;
            } else {
                totals.cardTotal += bill.payable_amount;
            }
            return totals;
        },
        { cashTotal: 0, cardTotal: 0 }
    );

    const labInvTotals = (
        analyticsData?.result?.labInvestigations || []
    ).reduce(
        (totals, item) => {
            if (item.payment_mode === "cash") {
                totals.totalCashPayable += item.payable_amount;
            } else if (item.payment_mode === "card") {
                totals.totalCardPayable += item.payable_amount;
            }
            return totals;
        },
        { totalCashPayable: 0, totalCardPayable: 0 }
    );
    const { totalCashPaidForMot, totalCardPaidForMot } = (
        analyticsData?.result?.minorOTs || []
    ).reduce(
        (totals, item) => {
            if (item.payment_mode === "cash") {
                totals.totalCashPaidForMot +=
                    item.payable_amount - item.discount_amount;
            } else if (item.payment_mode === "card") {
                totals.totalCardPaidForMot +=
                    item.payable_amount - item.discount_amount;
            }
            return totals;
        },
        { totalCashPaidForMot: 0, totalCardPaidForMot: 0 }
    );

    const { totalCashPaidForOpd, totalCardPaidForOpd } = (
        analyticsData?.result?.opds || []
    ).reduce(
        (totals, item) => {
            if (item.payment_mode === "cash") {
                totals.totalCashPaidForOpd +=
                    item.payable_amount - item.discount_amount;
            } else if (item.payment_mode === "card") {
                totals.totalCardPaidForOpd +=
                    item.payable_amount - item.discount_amount;
            }
            return totals;
        },
        { totalCashPaidForOpd: 0, totalCardPaidForOpd: 0 }
    );
    const totalRefundedAmount = (analyticsData?.result?.refunds || []).reduce(
        (total, item) => {
            return total + item.refunded_amount;
        },
        0
    );

    return (
        <>
            <Modal
                open={isBillModalOpen}
                onClose={closeBillModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isBillModalOpen}>
                    <Box sx={style}>
                        <div className="flex flex-col items-center ">
                            <section className="overflow-y-auto max-h-[550px] border rounded-sm border-slate-600 custom-scrollbar">
                                <div
                                    ref={billRef}
                                    id="opdbill"
                                    className="w-[210mm] min-h-[297mm] px-[8mm] py-[4mm] bg-white border "
                                >
                                    <div className="flex space-x-3 border-b-4 border-b-black">
                                        <img
                                            className="w-[60px] object-contain"
                                            src={`${skynet}/${clinicData?.result?.logo}`}
                                            alt=""
                                        />
                                        <div className="flex justify-between w-full">
                                            <div className="flex flex-col items-start justify-start flex-1">
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {clinicData?.result?.name}
                                                </Typography>

                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    {clinicData?.result?.email}
                                                </Typography>

                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    {clinicData?.result?.address}
                                                </Typography>

                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    {clinicData?.result?.city},{" "}
                                                    {clinicData?.result?.state},{" "}
                                                    {clinicData?.result?.pincode}
                                                </Typography>
                                            </div>
                                            <div className="flex flex-col items-start justify-end w-[35%]">
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    <span className="font-medium">
                                                        GSTIN:
                                                    </span>{" "}
                                                    {clinicData?.result?.gst}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    <span className="font-medium">
                                                        Reg No:
                                                    </span>{" "}
                                                    {clinicData?.result?.reg_no}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <section className="mt-4 space-y-6">
                                        <section className="">
                                            <Typography variant="h6" py={1}>
                                                Deposits
                                            </Typography>

                                            <TableContainer>
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {depositColumns.map(
                                                                (column) => (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                column.minWidth,
                                                                            width: column.width,
                                                                            fontWeight:
                                                                                "bold",
                                                                            textTransform:
                                                                                "uppercase",
                                                                            borderBottom:
                                                                                "4px solid black",
                                                                            borderLeft: 0,
                                                                            borderTop: 0,
                                                                            borderRight: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.label
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analyticsData?.result?.deposits?.map(
                                                            (deposit) => (
                                                                <TableRow
                                                                    key={
                                                                        deposit.id
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {
                                                                            deposit.id
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        ₹{" "}
                                                                        {
                                                                            deposit.amount
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        className="capitalize"
                                                                    >
                                                                        {
                                                                            deposit.payment_mode
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={3}
                                                                align="right"
                                                            >
                                                                <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                                    {totalCashDeposits >
                                                                        0 && (
                                                                        <div className="flex items-center justify-end space-x-4">
                                                                            <p>
                                                                                Total
                                                                                Paid
                                                                                by
                                                                                Cash:
                                                                            </p>

                                                                            <p>
                                                                                ₹{" "}
                                                                                {
                                                                                    totalCashDeposits
                                                                                }{" "}
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    {totalCardDeposits >
                                                                        0 && (
                                                                        <div className="flex items-center justify-end space-x-4">
                                                                            <p>
                                                                                Total
                                                                                Paid
                                                                                by
                                                                                Card:
                                                                            </p>
                                                                            <p>
                                                                                ₹{" "}
                                                                                {
                                                                                    totalCardDeposits
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}

                                                                    <div className="flex items-center justify-end space-x-4 ">
                                                                        <p>
                                                                            Total:
                                                                        </p>
                                                                        <p>
                                                                            ₹{" "}
                                                                            {
                                                                                totalDeposit
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section className="">
                                            <Typography variant="h6" py={1}>
                                                Doctor Payouts
                                            </Typography>

                                            <TableContainer
                                            // sx={{
                                            //     overflowX: "scroll",
                                            //     "&::-webkit-scrollbar": {
                                            //         width: 0,
                                            //     },
                                            // }}
                                            >
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {doctorPaymentsColumns.map(
                                                                (column) => (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                column.minWidth,
                                                                            width: column.width,
                                                                            fontWeight:
                                                                                "bold",
                                                                            textTransform:
                                                                                "uppercase",
                                                                            borderBottom:
                                                                                "4px solid black",
                                                                            borderLeft: 0,
                                                                            borderTop: 0,
                                                                            borderRight: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.label
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analyticsData?.result?.doctorPayments?.map(
                                                            (payment) => (
                                                                <TableRow
                                                                    key={
                                                                        payment.id
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {
                                                                            payment.id
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            payment.doctor_name
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        ₹{" "}
                                                                        {
                                                                            payment.amount
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {dayjs(
                                                                            payment.created_on
                                                                        ).format(
                                                                            "DD-MM-YYYY,h:mm:ss A"
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={3}
                                                                align="right"
                                                            >
                                                                <div className="flex items-center justify-end space-x-10 text-base mr-9">
                                                                    <p>
                                                                        Total:
                                                                    </p>
                                                                    <p>
                                                                        ₹{" "}
                                                                        {
                                                                            totalDoctorPayout
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section className="">
                                            <Typography variant="h6" py={1}>
                                                Expenses
                                            </Typography>

                                            <TableContainer
                                            // sx={{
                                            //     overflowX: "scroll",
                                            //     "&::-webkit-scrollbar": {
                                            //         width: 0,
                                            //     },
                                            // }}
                                            >
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {expenseColumn.map(
                                                                (column) => (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                column.minWidth,
                                                                            width: column.width,
                                                                            fontWeight:
                                                                                "bold",
                                                                            textTransform:
                                                                                "uppercase",
                                                                            borderBottom:
                                                                                "4px solid black",
                                                                            borderLeft: 0,
                                                                            borderTop: 0,
                                                                            borderRight: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.label
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analyticsData?.result?.expenses?.map(
                                                            (expense) => (
                                                                <TableRow
                                                                    key={
                                                                        expense.id
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {
                                                                            expense.id
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            expense.type
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        ₹{" "}
                                                                        {
                                                                            expense.amount
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {dayjs(
                                                                            expense.date
                                                                        ).format(
                                                                            "DD-MM-YYYY,h:mm:ss A"
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={4}
                                                                align="right"
                                                            >
                                                                <div className="flex items-center justify-end space-x-10 text-base mr-9">
                                                                    <p>
                                                                        Total:
                                                                    </p>
                                                                    <p>
                                                                        ₹{" "}
                                                                        {
                                                                            totalExpenses
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section className="">
                                            <Typography variant="h6" py={1}>
                                                IPD Bills
                                            </Typography>

                                            <TableContainer>
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {ipdBillColumns.map(
                                                                (column) => (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                column.minWidth,
                                                                            width: column.width,
                                                                            fontWeight:
                                                                                "bold",
                                                                            textTransform:
                                                                                "uppercase",
                                                                            borderBottom:
                                                                                "4px solid black",
                                                                            borderLeft: 0,
                                                                            borderTop: 0,
                                                                            borderRight: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.label
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analyticsData?.result?.ipdBills?.map(
                                                            (ipdBill) => (
                                                                <TableRow
                                                                    key={
                                                                        ipdBill.id
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {
                                                                            ipdBill.hid
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            ipdBill.patient_name
                                                                        }
                                                                    </TableCell>

                                                                    <TableCell align="center">
                                                                        {
                                                                            ipdBill.doctor_name
                                                                        }
                                                                    </TableCell>

                                                                    <TableCell align="center">
                                                                        ₹{" "}
                                                                        {
                                                                            ipdBill.payable_amount
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        className="capitalize"
                                                                    >
                                                                        {
                                                                            ipdBill.payment_mode
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {dayjs(
                                                                            ipdBill.paid_on
                                                                        ).format(
                                                                            "DD-MM-YYYY,h:mm:ss A"
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={9}
                                                                align="right"
                                                            >
                                                                <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                                    {cashTotal >
                                                                        0 && (
                                                                        <div className="flex items-center justify-end space-x-4">
                                                                            <p>
                                                                                Total
                                                                                Paid
                                                                                by
                                                                                Cash:
                                                                            </p>

                                                                            <p>
                                                                                ₹{" "}
                                                                                {
                                                                                    cashTotal
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    {cardTotal >
                                                                        0 && (
                                                                        <div className="flex items-center justify-end space-x-4">
                                                                            <p>
                                                                                Total
                                                                                Paid
                                                                                by
                                                                                Card:
                                                                            </p>
                                                                            <p>
                                                                                ₹{" "}
                                                                                {
                                                                                    cardTotal
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}

                                                                    <div className="flex items-center justify-end space-x-4 ">
                                                                        <p>
                                                                            Total:
                                                                        </p>
                                                                        <p>
                                                                            ₹{" "}
                                                                            {
                                                                                totalIPDBills
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section className="">
                                            <Typography variant="h6" py={1}>
                                                IPD
                                            </Typography>

                                            <TableContainer>
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {ipdsColumn.map(
                                                                (column) => (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                column.minWidth,
                                                                            width: column.width,
                                                                            fontWeight:
                                                                                "bold",
                                                                            textTransform:
                                                                                "uppercase",
                                                                            borderBottom:
                                                                                "4px solid black",
                                                                            borderLeft: 0,
                                                                            borderTop: 0,
                                                                            borderRight: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.label
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analyticsData?.result?.ipds?.map(
                                                            (ipdBill) => (
                                                                <TableRow
                                                                    key={
                                                                        ipdBill.id
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {
                                                                            ipdBill.hid
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {
                                                                            ipdBill.patient_name
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        ₹{" "}
                                                                        {
                                                                            ipdBill.admission_charge
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        className="capitalize"
                                                                    >
                                                                        {
                                                                            ipdBill.payment_mode
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={4}
                                                                align="right"
                                                            >
                                                                <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                                    {totalPaidByCash >
                                                                        0 && (
                                                                        <div className="flex items-center justify-end space-x-4">
                                                                            <p>
                                                                                Total
                                                                                Paid
                                                                                by
                                                                                Cash:
                                                                            </p>

                                                                            <p>
                                                                                ₹{" "}
                                                                                {
                                                                                    totalPaidByCash
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    {totalPaidByCard >
                                                                        0 && (
                                                                        <div className="flex items-center justify-end space-x-4">
                                                                            <p>
                                                                                Total
                                                                                Paid
                                                                                by
                                                                                Card:
                                                                            </p>
                                                                            <p>
                                                                                ₹{" "}
                                                                                {
                                                                                    totalPaidByCard
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}

                                                                    <div className="flex items-center justify-end space-x-4 ">
                                                                        <p>
                                                                            Total:
                                                                        </p>
                                                                        <p>
                                                                            ₹{" "}
                                                                            {
                                                                                totalIPD
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section className="">
                                            <Typography variant="h6" py={1}>
                                                Lab Investigations
                                            </Typography>

                                            <TableContainer>
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {labInvColumns.map(
                                                                (column) => (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                column.minWidth,
                                                                            width: column.width,
                                                                            fontWeight:
                                                                                "bold",
                                                                            textTransform:
                                                                                "uppercase",
                                                                            borderBottom:
                                                                                "4px solid black",
                                                                            borderLeft: 0,
                                                                            borderTop: 0,
                                                                            borderRight: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.label
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analyticsData?.result?.labInvestigations?.map(
                                                            (lab) => (
                                                                <TableRow
                                                                    key={lab.id}
                                                                >
                                                                    <TableCell align="center">
                                                                        {lab.id}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        ₹{" "}
                                                                        {
                                                                            lab.payable_amount
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        ₹{" "}
                                                                        {
                                                                            lab.discount_amount
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        className="capitalize"
                                                                    >
                                                                        {
                                                                            lab.payment_mode
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={4}
                                                                align="right"
                                                            >
                                                                <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                                    {labInvTotals?.totalCashPayable >
                                                                        0 && (
                                                                        <div className="flex items-center justify-end space-x-4">
                                                                            <p>
                                                                                Total
                                                                                Paid
                                                                                by
                                                                                Cash:
                                                                            </p>

                                                                            <p>
                                                                                ₹{" "}
                                                                                {
                                                                                    labInvTotals.totalCashPayable
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    {labInvTotals?.totalCardPayable >
                                                                        0 && (
                                                                        <div className="flex items-center justify-end space-x-4">
                                                                            <p>
                                                                                Total
                                                                                Paid
                                                                                by
                                                                                Card:
                                                                            </p>
                                                                            <p>
                                                                                ₹{" "}
                                                                                {
                                                                                    labInvTotals?.totalCardPayable
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}

                                                                    <div className="flex items-center justify-end space-x-4 ">
                                                                        <p>
                                                                            Total:
                                                                        </p>
                                                                        <p>
                                                                            ₹{" "}
                                                                            {labInvTotals.totalCardPayable +
                                                                                labInvTotals.totalCashPayable}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section className="">
                                            <Typography variant="h6" py={1}>
                                                Minor OTs
                                            </Typography>

                                            <TableContainer>
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {motColumn.map(
                                                                (column) => (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                column.minWidth,
                                                                            width: column.width,
                                                                            fontWeight:
                                                                                "bold",
                                                                            textTransform:
                                                                                "uppercase",
                                                                            borderBottom:
                                                                                "4px solid black",
                                                                            borderLeft: 0,
                                                                            borderTop: 0,
                                                                            borderRight: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.label
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analyticsData?.result?.minorOTs?.map(
                                                            (mot) => (
                                                                <TableRow
                                                                    key={mot.id}
                                                                >
                                                                    <TableCell align="center">
                                                                        {mot.id}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        ₹{" "}
                                                                        {
                                                                            mot.payable_amount
                                                                        }
                                                                    </TableCell>

                                                                    <TableCell
                                                                        align="center"
                                                                        className="capitalize"
                                                                    >
                                                                        {
                                                                            mot.payment_mode
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={4}
                                                                align="right"
                                                            >
                                                                <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                                    {totalCashPaidForMot >
                                                                        0 && (
                                                                        <div className="flex items-center justify-end space-x-4">
                                                                            <p>
                                                                                Total
                                                                                Paid
                                                                                by
                                                                                Cash:
                                                                            </p>

                                                                            <p>
                                                                                ₹{" "}
                                                                                {
                                                                                    totalCashPaidForMot
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    {totalCardPaidForMot >
                                                                        0 && (
                                                                        <div className="flex items-center justify-end space-x-4">
                                                                            <p>
                                                                                Total
                                                                                Paid
                                                                                by
                                                                                Card:
                                                                            </p>
                                                                            <p>
                                                                                ₹{" "}
                                                                                {
                                                                                    totalCardPaidForMot
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}

                                                                    <div className="flex items-center justify-end space-x-4 ">
                                                                        <p>
                                                                            Total:
                                                                        </p>
                                                                        <p>
                                                                            ₹{" "}
                                                                            {totalCardPaidForMot +
                                                                                totalCashPaidForMot}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section className="">
                                            <Typography variant="h6" py={1}>
                                                OPDs
                                            </Typography>

                                            <TableContainer>
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {opdColumn.map(
                                                                (column) => (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                column.minWidth,
                                                                            width: column.width,
                                                                            fontWeight:
                                                                                "bold",
                                                                            textTransform:
                                                                                "uppercase",
                                                                            borderBottom:
                                                                                "4px solid black",
                                                                            borderLeft: 0,
                                                                            borderTop: 0,
                                                                            borderRight: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.label
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analyticsData?.result?.opds?.map(
                                                            (opd) => (
                                                                <TableRow
                                                                    key={opd.id}
                                                                >
                                                                    <TableCell align="center">
                                                                        {
                                                                            opd.hid
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {
                                                                            opd.patient_title
                                                                        }
                                                                        {". "}
                                                                        {
                                                                            opd.patient_name
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        ₹{" "}
                                                                        {
                                                                            opd.charge
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        ₹{" "}
                                                                        {
                                                                            opd.discount_amount
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            opd.doctor_name
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        className="capitalize"
                                                                    >
                                                                        {
                                                                            opd.payment_mode
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={6}
                                                                align="right"
                                                            >
                                                                <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                                    {totalCashPaidForOpd >
                                                                        0 && (
                                                                        <div className="flex items-center justify-end space-x-4">
                                                                            <p>
                                                                                Total
                                                                                Paid
                                                                                by
                                                                                Cash:
                                                                            </p>

                                                                            <p>
                                                                                ₹{" "}
                                                                                {
                                                                                    totalCashPaidForOpd
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    {totalCardPaidForOpd >
                                                                        0 && (
                                                                        <div className="flex items-center justify-end space-x-4">
                                                                            <p>
                                                                                Total
                                                                                Paid
                                                                                by
                                                                                Card:
                                                                            </p>
                                                                            <p>
                                                                                ₹{" "}
                                                                                {
                                                                                    totalCardPaidForOpd
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}

                                                                    <div className="flex items-center justify-end space-x-4 ">
                                                                        <p>
                                                                            Total:
                                                                        </p>
                                                                        <p>
                                                                            ₹{" "}
                                                                            {totalCashPaidForOpd +
                                                                                totalCardPaidForOpd}{" "}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section className="">
                                            <Typography variant="h6" py={1}>
                                                Refunds
                                            </Typography>

                                            <TableContainer>
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {refundColumns.map(
                                                                (column) => (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                column.minWidth,
                                                                            width: column.width,
                                                                            fontWeight:
                                                                                "bold",
                                                                            textTransform:
                                                                                "uppercase",
                                                                            borderBottom:
                                                                                "4px solid black",
                                                                            borderLeft: 0,
                                                                            borderTop: 0,
                                                                            borderRight: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.label
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analyticsData?.result?.refunds?.map(
                                                            (refund) => (
                                                                <TableRow
                                                                    key={
                                                                        refund.id
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {
                                                                            refund.hid
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {
                                                                            refund.patient_title
                                                                        }
                                                                        {". "}
                                                                        {
                                                                            refund.patient_name
                                                                        }
                                                                    </TableCell>

                                                                    <TableCell align="center">
                                                                        ₹{" "}
                                                                        {
                                                                            refund.refunded_amount
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {dayjs(
                                                                            refund.created_on
                                                                        ).format(
                                                                            "DD-MM-YYYY,h:mm:ss A"
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={4}
                                                                align="right"
                                                            >
                                                                <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                                    <div className="flex items-center justify-end space-x-4 ">
                                                                        <p>
                                                                            Total:
                                                                        </p>
                                                                        <p>
                                                                            ₹{" "}
                                                                            {
                                                                                totalRefundedAmount
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>
                                    </section>
                                </div>
                            </section>
                            <div className="w-[210mm] flex space-x-10 items-center pt-4">
                                <button
                                    onClick={useReactToPrint({
                                        content: () => billRef.current,
                                    })}
                                    className={`text-sm bg-green-500 text-white px-3 py-1.5 rounded shadow-lg-md`}
                                >
                                    Print
                                </button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default AccountBills;
