import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { FaRegEye } from "react-icons/fa";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import ClearIcon from "@mui/icons-material/Clear";
import {
    Autocomplete,
    Backdrop,
    Box,
    Fade,
    Modal,
    TextField,
    Tooltip,
} from "@mui/material";
import { useGetOTServices } from "../../../query/hooks/otservices/otServices";
import ReusableButton from "../ReusableButton";
import { useParams } from "react-router-dom";
import {
    useAddIPDOT,
    useDeleteIPDOT,
    useGetIPDOT,
    useUpdateIPDOT,
} from "../../../query/hooks/ipd/ots";
import { deleteTest } from "../../../services/api";
import CommonDeleteModal from "../CommonDeleteModal";
import RedButton from "../RedButton";
import { showNotification } from "../../../services/helper";
import dayjs from "dayjs";
import LoaderLogo from "../LoaderLogo";
import { useSelector } from "react-redux";
import { useGetEmployees } from "../../../query/hooks/employee/employeeHooks";
import { selectEmployeesParam } from "../../../redux/features/filters/employeeSlice";
import { useGetIPD } from "../../../query/hooks/ipd/ipds";

const operationsData = [
    {
        id: 1,
        ot: "OT-001",
        totalAmount: "$1500",
        ConsultantDoctor: "Dr. Smith",
        date: "2024-03-19",
        actions: "View",
    },
    {
        id: 2,
        ot: "OT-002",
        totalAmount: "$2000",
        ConsultantDoctor: "Dr. Johnson",
        date: "2024-03-18",
        actions: "View",
    },
    {
        id: 3,
        ot: "OT-003",
        totalAmount: "$1800",
        ConsultantDoctor: "Dr. Williams",
        date: "2024-03-17",
        actions: "View",
    },
    {
        id: 4,
        ot: "OT-004",
        totalAmount: "$2200",
        ConsultantDoctor: "Dr. Brown",
        date: "2024-03-16",
        actions: "View",
    },
    {
        id: 5,
        ot: "OT-005",
        totalAmount: "$2500",
        ConsultantDoctor: "Dr. Wilson",
        date: "2024-03-15",
        actions: "View",
    },
    // Add more sample data as needed
];
const intercolumns = [
    { id: "name", label: "Procedure Name", minWidth: 100, align: "center" },
    { id: "ot_charge", label: "OT Charge", minWidth: 100, align: "center" },
    {
        id: "surgeon_charge",
        label: "Surgeon Charge",
        minWidth: 100,
        align: "center",
    },
    {
        id: "assistant_surgeon_charge",
        label: "Assistant Surgeon Charge",
        minWidth: 100,
        align: "center",
    },
    { id: "pwf_charge", label: "PWF Charge", minWidth: 100, align: "center" },
    {
        id: "anaesthesia_charge",
        label: "Anaesthesia Charge",
        minWidth: 100,
        align: "center",
    },
];
const columns = [
    { id: "ot", label: "OT" },
    { id: "ConsultantDoctor", label: "Consultant Doctor" },
    { id: "totalAmount", label: "Total Amount" },
    { id: "date", label: "Date" },
    { id: "actions", label: "Action" },
];
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const Operations = () => {
    const limit = 1000;
    const [offset, setOffset] = useState(0);
    const [open, setOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [editForm, setEditForm] = useState("");
    const [toDelete, setToDelete] = useState("");

    const params = useParams();
    const { ipdOTData, isFetching, isLoading, refetch, isSuccess } =
        useGetIPDOT(params.id);

    const { ipdData, isLoading: isPtLoading } = useGetIPD(params.id);
    const employeeParams = useSelector(selectEmployeesParam);
    const { employeesData } = useGetEmployees(employeeParams);
    const { addIPDOT, isLoading: isAdding } = useAddIPDOT();
    const { updateIPDOT, isLoading: isEditing } = useUpdateIPDOT();
    const { deleteIPDOT, isLoading: isDeleting } = useDeleteIPDOT();
    const [selectedOT, setSelectedOT] = useState({
        service: "",
        doctor: "",
    });
    const [selectedOtOption, setSelectedOtOption] = useState(null);
    const [otInputValue, setOtInputValue] = useState("");
    const { otServicesData, isLoading: isDataLoading } = useGetOTServices();

    const [selectedDoctor, setSelectedDoctor] = useState("");

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setSelectedDoctor();
        setSelectedOT({
            service: "",
            doctor: "",
        });
        setSelectedOtOption(null);
        setOpen(false);

        setOtInputValue("");
    };

    const style = {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "auto",

        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "5px",
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteConfirmed = () => {
        deleteIPDOT(toDelete);
        handleCloseDeleteDialog();
    };
    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };
    const isOTValid = () => {
        return (
            selectedOT.service !== null &&
            selectedOT.doctor !== null &&
            !isNaN(selectedOT.service) &&
            !isNaN(selectedOT.doctor)
        );
    };
    const handleAddServices = () => {
        if (!selectedOT.doctor || !selectedOT.service) {
            return showNotification("error", "Please enter all fields");
        }
        addIPDOT({
            otID: ipdData?.result?.id,
            body: { ...selectedOT },
        });
        if (isSuccess) {
            handleClose();
        }
    };

    const handleOtOptionChange = (event, newValue) => {
        setSelectedOtOption(newValue);
        setSelectedOT((prev) => ({
            ...prev,
            service: newValue.id,
        }));
    };

    const handleOtInputChange = (event, newInputValue) => {
        setOtInputValue(newInputValue);
    };

    const handleOtClear = () => {
        setSelectedOtOption(null);
        setOtInputValue("");
    };

    const filteredDoctors = employeesData?.result?.list?.filter(
        (employee) => employee.designation_name === "Doctor"
    );

    const handleDoctorChange = (event, value) => {
        setSelectedDoctor(value);
        setSelectedOT((prev) => ({
            ...prev,
            doctor: value.id,
        }));
    };
    const handleDoctorClear = () => {
        setSelectedDoctor("");
        setSelectedOT((prev) => ({
            ...prev,
            doctor: "",
        }));
    };
    const isOptionEqualToValue = (option, value) => option.id === value.id;

    if (!ipdData)
        return (
            <div className="flex items-center justify-center">
                <LoaderLogo />
            </div>
        );
    return (
        <>
            <div className="mb-4">
                <ReusableButton title="Add OT" onClick={handleOpen} />
            </div>
            <Paper
                elevation={24}
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: "15px",
                }}
            >
                <TableContainer
                    sx={{
                        height: 520,
                        overflowX: "scroll",
                        "&::-webkit-scrollbar": {
                            width: 0,
                        },
                    }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align="center"
                                        style={{
                                            fontWeight: "bold",
                                            background: "#eeeeee",
                                            textTransform: "uppercase",
                                            backgroundColor: "#27272A",
                                            color: "white",
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ipdOTData?.result?.list?.map((item, index) => (
                                <TableRow key={item?.id}>
                                    <TableCell align="center">
                                        {item?.ot_procedure}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item?.doctor_name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {/* Assuming totalAmount is the sum of charges */}
                                        ₹ {""}{" "}
                                        {item?.ot_charge +
                                            item?.surgeon_charge +
                                            item?.assistant_surgeon_charge +
                                            item?.pwf_charge +
                                            item?.anaesthesia_charge}
                                    </TableCell>

                                    <TableCell align="center">
                                        {dayjs(item?.created_on).format(
                                            "DD-MM-YYYY,h:mm:ss A"
                                        )}
                                    </TableCell>

                                    <TableCell align="center">
                                        <div className="flex items-center justify-center space-x-4">
                                            <Tooltip
                                                title="Delete"
                                                placement="bottom-start"
                                            >
                                                <button
                                                    onClick={() =>
                                                        handleOpenDeleteDialog(
                                                            item?.id
                                                        )
                                                    }
                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                >
                                                    <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <>
                            <section className="flex flex-col items-center justify-center w-full">
                                <div className="w-full">
                                    <div className="flex items-center w-full space-x-2 justify-evenly">
                                        <Autocomplete
                                            sx={{
                                                width: "40%",
                                            }}
                                            options={
                                                otServicesData?.result?.list ||
                                                []
                                            }
                                            getOptionLabel={(option) =>
                                                option.ot_procedure
                                            }
                                            value={selectedOtOption}
                                            onChange={handleOtOptionChange}
                                            inputValue={otInputValue}
                                            onInputChange={handleOtInputChange}
                                            disableClearable
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.id === value?.id}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Search OT Service"
                                                    variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {selectedOtOption && (
                                                                    <ClearIcon
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={
                                                                            handleOtClear
                                                                        }
                                                                    />
                                                                )}
                                                                {
                                                                    params
                                                                        .InputProps
                                                                        .endAdornment
                                                                }
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />

                                        <Autocomplete
                                            sx={{
                                                width: "40%",
                                            }}
                                            options={filteredDoctors || []}
                                            disableClearable
                                            getOptionLabel={(option) =>
                                                option
                                                    ? option.name
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                      option.name.slice(1)
                                                    : ""
                                            }
                                            value={selectedDoctor}
                                            onChange={handleDoctorChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Search Doctor"
                                                />
                                            )}
                                            // isOptionEqualToValue={
                                            //     isOptionEqualToValue
                                            // }
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => {
                                                return option.id === value?.id;
                                            }}
                                        />
                                    </div>
                                </div>
                                <section className="mt-4">
                                    {otInputValue && (
                                        <Paper
                                            sx={{
                                                width: "100%",
                                                overflow: "hidden",
                                                borderRadius: "15px",
                                            }}
                                            elevation={24}
                                        >
                                            <TableContainer
                                                sx={{
                                                    height: "auto",
                                                    overflowX: "scroll",
                                                    "&::-webkit-scrollbar": {
                                                        width: 0,
                                                    },
                                                }}
                                            >
                                                <Table
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {intercolumns.map(
                                                                (column) => (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }
                                                                        style={{
                                                                            minWidth:
                                                                                column.minWidth,
                                                                            fontWeight:
                                                                                "bold",
                                                                            background:
                                                                                "#eeeeee",
                                                                            textTransform:
                                                                                "uppercase",
                                                                            backgroundColor:
                                                                                "#27272A",
                                                                            color: "white",
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.label
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            key={
                                                                selectedOtOption?.id
                                                            }
                                                            align="center"
                                                        >
                                                            <TableCell align="center">
                                                                {
                                                                    selectedOtOption?.ot_procedure
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                ₹{" "}
                                                                {
                                                                    selectedOtOption?.ot_charge
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                ₹{" "}
                                                                {
                                                                    selectedOtOption?.surgeon_charge
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                ₹{" "}
                                                                {
                                                                    selectedOtOption?.assistant_surgeon_charge
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                ₹{" "}
                                                                {
                                                                    selectedOtOption?.pwf_charge
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                ₹{" "}
                                                                {
                                                                    selectedOtOption?.anaesthesia_charge
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    )}
                                </section>
                            </section>
                            <section className="flex py-5 justify-evenly">
                                <ReusableButton
                                    title="Save"
                                    onClick={handleAddServices}
                                />
                                <RedButton
                                    title="Cancel"
                                    onClick={handleClose}
                                />
                            </section>
                        </>
                    </Box>
                </Fade>
            </Modal>
            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeleting}
            />
        </>
    );
};

export default Operations;
