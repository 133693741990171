import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { AiOutlineReload } from "react-icons/ai";
import Typography from "@mui/material/Typography";
import {
    Autocomplete,
    Button,
    IconButton,
    Pagination,
    TextField,
    Tooltip,
} from "@mui/material";
import {
    useAddDepartment,
    useDeleteDepartment,
    useEditDepartment,
    useGetDepartments,
} from "../query/hooks/department/departmentHook";
import dayjs from "dayjs";
import { showNotification } from "../services/helper";

import CommonDeleteModal from "./common/CommonDeleteModal";
import DeptCard from "./common/DeptCard";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import { BsBuilding } from "react-icons/bs";
import entLogo from "../assets/icons/ent.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { skynet, uploadFile } from "../services/api";
import {
    selectDepartmentParam,
    selectSelectedOption,
    setDepartmentParams,
    setDepartmentSearchField,
    setSelectedOption,
} from "../redux/features/filters/deptSlice";
import ReusableButton from "./common/ReusableButton";
import RedButton from "./common/RedButton";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    minHeight: 300,
    maxHeight: 600,
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
    overflowY: "auto",
};
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const Departments = () => {
    const dispatch = useDispatch();
    const deptParams = useSelector(selectDepartmentParam);
    const currentAuth = useSelector(selectCurrentAuth);
    const [dataToRender, setDataToRender] = useState([]);
    const departmentPermission = currentAuth?.user?.permissions.find(
        (permission) => permission.module === 1002
    );
    const { add_permission, update_permission, delete_permission } =
        departmentPermission;

    const {
        departmentsData,
        refetch: refetchDepartments,
        isSuccess,
    } = useGetDepartments();

    useEffect(() => {
        if (
            isSuccess &&
            departmentsData &&
            departmentsData.result &&
            departmentsData.result.list
        ) {
            const arr = chunk(departmentsData.result.list, 8);
            setDataToRender(arr[deptParams.offset / deptParams.limit] || []); // Added null check here
        } else {
            setDataToRender([]);
        }
    }, [departmentsData, deptParams.offset]);

    useEffect(() => {
        setCurrentPage(deptParams.offset / deptParams.limit + 1);
    }, [deptParams]);

    const { addDepartment, isLoading: isAddingDept } = useAddDepartment();
    const { editDepartment, isLoading: isEditingDept } = useEditDepartment();
    const { deleteDepartment, isLoading: isDeletingDept } =
        useDeleteDepartment();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editedDpt, setEditedDpt] = useState("");
    const [toDelete, setToDelete] = useState("");
    const [department, setDepartment] = useState("");
    const [open, setOpen] = useState(false);
    const [valErr, setValErr] = useState();
    const [image, setImage] = useState(null);
    const [editImage, setEditImage] = useState(null);
    const [editImgUrl, setEditImgUrl] = useState("");
    const [hovered, setHovered] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const chunk = (arr, size) => {
        if (arr)
            return Array.from(
                { length: Math.ceil(arr.length / size) },
                (v, i) => arr.slice(i * size, i * size + size)
            );
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    const handleClearImage = () => {
        setEditImage(null);
        setImage(null);
        setEditImgUrl("");
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const uploadImage = async (img) => {
        try {
            const formData = new FormData();
            formData.append("file", img);
            const response = await uploadFile(formData);
            setIsUploading(true);
            if (response.status === 200 && response.data.success) {
                // setUrl(response.data.result);
                setImage(null);
                setIsUploading(false);
                return response.data.result;
            }
            showNotification("success", "Image uploaded successfully");
        } catch (error) {
            console.error(error);
            showNotification("error", "Cannot Upload Image");
            return;
        }
    };
    const handleAdd = async () => {
        try {
            const url = await uploadImage(image);

            if (!department || department.trim() === "") {
                setValErr(true);
                showNotification("error", "Department name cannot be empty");
                return;
            }

            addDepartment({ name: department.trim(), image: url });
            setValErr(false);
            setDepartment("");
            handleClose();
        } catch (error) {
            console.error(error);

            showNotification("error", "Error while adding department");
        }
    };

    const handleCancel = () => {
        setDepartment("");
        handleClose();
        setValErr(false);
    };

    const handleEditImageChange = async (e) => {
        const file = e.target.files[0];
        setEditImage(file);
        try {
            const url = await uploadImage(file);
            setTimeout(() => {
                setEditedDpt((prevDpt) => ({ ...prevDpt, image: url }));
                setEditImgUrl(url);
            }, 2000);
        } catch (error) {
            console.warn(error);
        }
    };

    const handleEditClearImage = () => {
        setEditImage(null);
        setEditImgUrl("");
        setEditedDpt((prevDpt) => ({ ...prevDpt, image: null }));
    };
    const handleEditClick = (row) => {
        setEditedDpt(row); // Set the role to be edited
        setEditOpen(true); // Open the edit modal
    };
    const handleEditSave = () => {
        if (!editedDpt.name || editedDpt.name.trim() === "") {
            showNotification("error", "Department name cannot be empty");
            return;
        }
        const updatedRole = {
            id: editedDpt.id,
            name: editedDpt.name.trim(),
            image: editImgUrl || editedDpt.image,
        };
        editDepartment(updatedRole);
        setEditOpen(false);
        setEditedDpt(""); // Set the role to be edited
    };
    const handleEditClose = () => {
        setEditOpen(false);
        setEditedDpt("");
        setEditImgUrl("");
        setEditImage(null);
    };

    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };

    const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

    const handleDeleteConfirmed = () => {
        deleteDepartment(toDelete);
        handleCloseDeleteDialog();
    };

    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setDepartmentParams({
                ...deptParams,
                offset: (value - 1) * deptParams.limit,
            })
        );
    };

    const defaultParams = useSelector(
        (state) => state.departments.defaultParams
    );
    const selectedOption = useSelector(selectSelectedOption); // Get selectedOption from Redux

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(selectedOption);
            setDataToRender([selectedOption]);
        }
    }, [setSelectedOption]);

    const handleOptionChange = (event, newValue) => {
        dispatch(setSelectedOption(newValue));
        setDataToRender([newValue]);
        dispatch(
            setDepartmentParams({
                ...defaultParams,
                deptName: newValue?.name || "",
            })
        );
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        dispatch(setDepartmentSearchField({ fileValue: newInputValue }));
    };

    const handleClear = () => {
        if (departmentsData) {
            const arr = chunk(departmentsData?.result?.list, 10);
            setDataToRender(arr[deptParams.offset / deptParams.limit]);
        }
        dispatch(setSelectedOption(null));
        setInputValue("");
        dispatch(setDepartmentSearchField({ fileValue: null }));
    };
    return (
        <section>
            <div className="">
                <div className="flex justify-between px-10 m-4">
                    <div>
                        <div className="flex items-center space-x-3">
                            <Typography variant="h5">Departments</Typography>
                            <Tooltip title="Refresh" placement="bottom-start">
                                <button
                                    onClick={refetchDepartments}
                                    className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                    style={{ cursor: "pointer" }}
                                >
                                    <AiOutlineReload size={20} />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    {/* filter&Search */}
                    <div className="flex items-center">
                        <div className="w-[100%]">
                            <div className="">
                                <Autocomplete
                                    className="w-[350px]"
                                    options={
                                        departmentsData?.result?.list || []
                                    }
                                    getOptionLabel={(option) => option.name}
                                    value={selectedOption}
                                    onChange={handleOptionChange}
                                    inputValue={inputValue}
                                    onInputChange={handleInputChange}
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search Name"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {selectedOption && (
                                                            <ClearIcon
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={
                                                                    handleClear
                                                                }
                                                            />
                                                        )}
                                                        {
                                                            params.InputProps
                                                                .endAdornment
                                                        }
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    {add_permission === 1 && (
                        <Tooltip
                            title="Add Department"
                            placement="bottom-start"
                        >
                            <button
                                onClick={handleOpen}
                                className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                            >
                                <BsBuilding size={18} />
                            </button>
                        </Tooltip>
                    )}
                </div>
                <div className="flex items-center justify-end space-x-3">
                    {departmentsData?.result?.count > 0 && (
                        <Pagination
                            count={Math.ceil(
                                departmentsData?.result?.count /
                                    deptParams.limit
                            )}
                            size="medium"
                            page={currentPage}
                            onChange={handlePageChange}
                        />
                    )}
                    <p className="flex items-center space-x-2 font-medium text-slate-700">
                        <span>Total result:</span>
                        <span className="flex items-center justify-center px-2 py-1 text-lg font-bold text-black rounded-full bg-violet-200">
                            {departmentsData?.result?.count}
                        </span>
                    </p>
                </div>
                <div className="grid grid-cols-2 gap-5 md:grid-cols-3 lg:grid-cols-4">
                    {dataToRender?.map((row) => (
                        <div key={row.id} className="w-full p-5 ">
                            <DeptCard
                                imgSrc={
                                    row.image
                                        ? `${skynet}/${row.image}`
                                        : entLogo
                                }
                                name={row.name}
                                update_permission={
                                    update_permission === 1 ? true : false
                                }
                                delete_permission={
                                    delete_permission === 1 ? true : false
                                }
                                onEdit={() => handleEditClick(row)}
                                onDelete={() => handleOpenDeleteDialog(row.id)}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                                marginBottom: 2,
                            }}
                            className="text-center"
                        >
                            Add New Department
                        </Typography>
                        <input
                            accept="image/jpeg,image/png,image/webp"
                            style={{ display: "none" }}
                            id="image-upload"
                            type="file"
                            onChange={handleImageChange}
                        />
                        {image === null && (
                            <div className="flex items-center justify-center w-full py-2">
                                <label htmlFor="image-upload">
                                    <Button
                                        variant="contained"
                                        component="span"
                                    >
                                        Upload Image
                                    </Button>
                                </label>
                            </div>
                        )}

                        {image && (
                            <section
                                style={{
                                    width: "100%",
                                    position: "relative",
                                    overflow: "hidden",
                                }}
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                            >
                                <div
                                    className="flex items-center justify-center"
                                    style={{ width: "300px", height: "300px" }}
                                >
                                    <img
                                        src={URL.createObjectURL(image)}
                                        alt="Preview"
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            objectFit: "contain",
                                        }}
                                    />
                                    {hovered && (
                                        <div className="absolute top-0 right-0 bottom-0 left-0 bg-opacity-23 bg-gray-500/10 shadow-md backdrop-blur-[6.4px] border-1 border-gray-700/30 flex items-center justify-center transition-backdrop-filter duration-300 ease-in-out">
                                            <IconButton
                                                onClick={handleClearImage}
                                                style={{
                                                    color: "red", // Set the color based on your design
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                </div>
                            </section>
                        )}
                        <TextField
                            autoFocus
                            margin="dense"
                            id="department"
                            required
                            label="Department"
                            type="text"
                            fullWidth
                            value={department}
                            disabled={isAddingDept}
                            onChange={(e) => setDepartment(e.target.value)}
                            helperText={
                                valErr
                                    ? "Department Name is required."
                                    : undefined
                            }
                            error={valErr}
                        />

                        <div className="flex justify-between p-6">
                            <ReusableButton
                                isDisabled={isAddingDept}
                                onClick={handleAdd}
                                title="Save"
                            />

                            <RedButton
                                isDisabled={isAddingDept}
                                onClick={handleCancel}
                                title="Cancel"
                            />
                        </div>
                    </Box>
                </Fade>
            </Modal>

            <Modal
                open={editOpen}
                onClose={() => setEditOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={editOpen}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                                marginBottom: 2,
                            }}
                            className="text-center"
                        >
                            Edit Department
                        </Typography>

                        <input
                            accept="image/jpeg,image/png,image/webp"
                            style={{ display: "none" }}
                            id="image-upload"
                            type="file"
                            onChange={handleEditImageChange}
                        />
                        <div className="flex items-center justify-center w-full py-2">
                            <label htmlFor="image-upload">
                                <Button variant="contained" component="span">
                                    Upload Image
                                </Button>
                            </label>
                        </div>

                        {editedDpt.image && (
                            <section
                                style={{
                                    width: "100%",
                                    position: "relative",
                                    overflow: "hidden",
                                }}
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                            >
                                <div
                                    className="flex items-center justify-center"
                                    style={{ width: "300px", height: "300px" }}
                                >
                                    <img
                                        // src={URL.createObjectURL(image)}
                                        src={`${skynet}/${editedDpt.image}`}
                                        alt="Preview"
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            objectFit: "contain",
                                        }}
                                    />
                                    {hovered && (
                                        <div className="absolute top-0 right-0 bottom-0 left-0 bg-opacity-23 bg-gray-500/10 shadow-md backdrop-blur-[6.4px] border-1 border-gray-700/30 flex items-center justify-center transition-backdrop-filter duration-300 ease-in-out">
                                            <IconButton
                                                onClick={handleEditClearImage}
                                                style={{
                                                    color: "red", // Set the color based on your design
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                </div>
                            </section>
                        )}
                        <TextField
                            autoFocus
                            margin="dense"
                            id="editedDepartment"
                            label="Edit Department"
                            disabled={isEditingDept}
                            type="text"
                            fullWidth
                            value={editedDpt.name} // Set the initial value to the role name
                            onChange={(e) =>
                                setEditedDpt({
                                    ...editedDpt,
                                    name: e.target.value,
                                })
                            }
                        />

                        <div className="flex justify-between p-6">
                            <ReusableButton
                                isDisabled={isEditingDept}
                                onClick={handleEditSave}
                                title="Save"
                            />

                            <RedButton
                                isDisabled={isEditingDept}
                                onClick={handleEditClose}
                                title="Cancel"
                            />
                        </div>
                    </Box>
                </Fade>
            </Modal>

            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeletingDept}
            />
        </section>
    );
};

export default Departments;
