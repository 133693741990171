import React, { useLayoutEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Route, Routes, useNavigate } from "react-router-dom";
import PatientDashboard from "../components/mobile/PatientDashboard";
import PatientHistory from "../components/mobile/PatientHistory";
import PatientProfile from "../components/mobile/PatientProfile";
import PatientTestReport from "../components/mobile/PatientTestReport";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import TestDownload from "../components/mobile/TestDownload";
import logo from "../assets/logo.svg";
import LockRoundedIcon from "@mui/icons-material/LockRounded";

import { usePTLogout } from "../query/hooks/patientsSide/patientSideHooks";
import TestReportDownload from "../components/mobile/TestReportDownload";
import { useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import OpdFile from "../components/mobile/OpdFile";
import { clearLocalStorage, getItem } from "../services/helper";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
    const auth = useSelector(selectCurrentAuth);

    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);
    const { mutate: logout, isSuccess, isLoading: isLogout } = usePTLogout();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    useLayoutEffect(() => {
        if (auth && Object.keys(auth).length > 0) {
        } else {
            navigate("/");
        }
    }, []);
    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        handleDrawerClose();
    };
    const handleLogout = () => {
        const user = getItem("user");
        if (user) {
            logout(user.token);
            if (isSuccess) {
                clearLocalStorage("user");
                handleCloseDialog();
                navigate("/");
            }
        }
    };
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        navigate("/patient");
        handleDrawerClose();
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleListItemClick = (path) => {
        navigate(path);
        handleDrawerClose();
    };
    const routes = [
        {
            id: 1,
            label: "Home",
            path: "/patient",
            icon: <HomeIcon />,
            component: <PatientDashboard />,
        },
        {
            id: 3,
            label: "Profile",
            path: "/patient/profile",
            icon: <PortraitOutlinedIcon />,
            component: <PatientProfile />,
        },
        {
            id: 2,
            label: "OPD Reports",
            path: "/patient/history",
            icon: <ArticleIcon />,
            component: <PatientHistory />,
        },

        {
            id: 4,
            label: "Lab Tests",
            path: "/patient/testreports",
            icon: <MonitorHeartIcon />,
            component: <PatientTestReport />,
        },
        {
            id: 5,
            label: "LogOut",
            icon: <LockRoundedIcon size="40" />,
            // component: <PatientTestReport />,
        },
    ];
    return (
        <>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    open={open}
                    sx={{ backgroundColor: "#27272A" }}
                >
                    <Toolbar sx={{ justifyContent: "space-between" }}>
                        <div sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{ mr: 2, ...(open && { display: "none" }) }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <img
                                    src={logo}
                                    alt="Logo"
                                    style={{
                                        width: "32px", // Adjust the width as needed
                                        height: "32px", // Adjust the height as needed
                                        borderRadius: "50%", // Optional: Add border-radius for a circular shape
                                    }}
                                />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>

                <Drawer
                    sx={{
                        width: drawerWidth,
                        display: "flex",
                        justifyContent: "space-between",
                        flexShrink: 0,

                        "& .MuiDrawer-paper": {
                            width: drawerWidth,
                            boxSizing: "border-box",
                        },
                    }}
                    variant="persistent"
                    // anchor="left"
                    open={open}
                    PaperProps={{
                        sx: {
                            backgroundColor: "#27272A",
                        },
                    }}
                >
                    <DrawerHeader>
                        <Typography
                            align="center"
                            sx={{ color: "white", fontWeight: "bold" }}
                        ></Typography>
                        <IconButton
                            onClick={handleDrawerClose}
                            sx={{ color: "white" }}
                        >
                            {theme.direction === "ltr" ? (
                                <ChevronLeftIcon />
                            ) : (
                                <ChevronRightIcon />
                            )}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List
                        sx={{
                            backgroundColor: "#27272A",
                            color: "white",
                        }}
                    >
                        {routes.map(({ id, label, icon, path }) => (
                            <ListItem key={id} disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        if (label === "LogOut") {
                                            handleClickOpen();
                                        } else {
                                            handleListItemClick(path);
                                        }
                                    }}
                                >
                                    <ListItemIcon sx={{ color: "white" }}>
                                        {icon}
                                    </ListItemIcon>
                                    <ListItemText primary={label} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                </Drawer>
                <Main open={open} className="flex-1 w-full overflow-y-scroll">
                    <DrawerHeader />
                    <Routes>
                        <Route index element={<PatientDashboard />} />
                        <Route path="history" element={<PatientHistory />} />
                        <Route path="history/files/:id" element={<OpdFile />} />
                        <Route path="profile" element={<PatientProfile />} />
                        <Route
                            path="testreports"
                            element={<PatientTestReport />}
                        />
                        <Route
                            path="testreports/:id"
                            element={<TestDownload />}
                        />
                        <Route
                            path="testreports/downloads/:id1/:id2"
                            element={<TestReportDownload />}
                        />
                    </Routes>
                </Main>
            </Box>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                sx={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    width: "100%",
                    height: "100vh",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <DialogTitle>{"Confirm Logout"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleLogout}
                        color="error"
                        autoFocus
                        disabled={isLogout}
                    >
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
