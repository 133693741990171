// departmentsSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    defaultParams: {
        deptName: "",
        offset: 0,
        limit: 8
    },
    searchField: {
        fileValue: null,
    },
    selectedOption: null, // Add a new property to store the selected option
};

const deptSlice = createSlice({
    name: "departments",
    initialState,
    reducers: {
        setDepartmentParams: (state, action) => {
            state.defaultParams = action.payload;
        },
        setDepartmentSearchField: (state, action) => {
            const { fileValue } = action.payload;
            state.searchField.fileValue = fileValue;
        },
        setSelectedOption: (state, action) => {
            state.selectedOption = action.payload;
        },
        clearDepartment: (state, action) => {
            return initialState;
        },
    },
    extraReducers: {},
});

export const selectDepartmentParam = (state) => state.departments.defaultParams;
export const selectSearchFieldDepartment = (state) => state.departments.searchField;
export const selectSelectedOption = (state) => state.departments.selectedOption; // Add a selector for selectedOption
export const { setDepartmentParams, setDepartmentSearchField, setSelectedOption, clearDepartment } = deptSlice.actions;
export default deptSlice.reducer;
