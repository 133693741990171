import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { getBills, addBill, updateBill, deleteBill, getBill } from "../../../services/api";


export function useGetBill(billID) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.finalbill, billID], // Pass ipdID as part of the query key
        queryFn: () => getBill(billID),
        enabled: billID ? true : false,
    });

    return { summaryData: data, isLoading, isSuccess, refetch };
}

export function useGetBills(ipdParams = { name: "", hid: "", offset: 0, limit: 1000, startDate: "", endDate: "", ipd: "" }) {
    const { name, hid, offset, limit, startDate, endDate, ipd } = ipdParams;
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.finalbill, { name, hid, offset, limit, ipd, startDate, endDate }],
        queryFn: () => getBills({ name, hid, offset, limit, startDate, endDate, ipd }),

    });

    return { billData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

export function useAddBill() {
    const queryClient = useQueryClient();

    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: ({ ipdID, body }) => addBill({ ipdID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.finalbill] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipds] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipd] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdBeds] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdDeposit] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdOT] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdRefund] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdService] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdServices] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.labReports] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.useAddLabInventory] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addBill: mutate, isLoading };
}

export function useUpdateBill() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ billID, body }) => updateBill({ billID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.finalbill] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { updateBill: mutate, isLoading };
}

export function useDeleteBill() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (billID) => deleteBill(billID),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.finalbill] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteBill: mutate, isLoading };
}
