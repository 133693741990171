// import { getAllFiles, getFilesByFolder, uploadFile } from "../../services/api";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    defaultParams: {
        name: "",
        offset: 0,
        limit: 10,
    },
    searchField: {
        testValue: null,
    },
    selectedOption: null, // Adding selectedOption here
};

const testSlice = createSlice({
    name: "tests",
    initialState,
    reducers: {
        setTestParams: (state, action) => {
            state.defaultParams = action.payload;
        },
        setTestSearchField: (state, action) => {
            state.searchField = action.payload;
        },
        setSelectedTestOption: (state, action) => {
            state.selectedOption = action.payload;
        },
        clearTest: (state, action) => {
            return initialState;
        },
    },
    extraReducers: {},
});

export const selectTestParam = (state) => state.tests.defaultParams;
export const selectSearchFieldTest = (state) => state.tests.searchField;
export const selectSelectedOption = (state) => state.tests.selectedOption; // Selector for selectedOption
export const { setTestParams, setTestSearchField, setSelectedTestOption, clearTest } = testSlice.actions;
export default testSlice.reducer;
