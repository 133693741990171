// bedSlice.js

import { createSlice } from "@reduxjs/toolkit";

const bedInitialState = {
    defaultParams: {
        bed_number: "",
        offset: 0,
        limit: 10,
    },
};

const bedSlice = createSlice({
    name: "bed",
    initialState: bedInitialState,
    reducers: {
        setBedParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },

        clearBed: (state, action) => {
            state.defaultParams = bedInitialState.defaultParams;
        },
    },
    extraReducers: {},
});

export const selectBedParam = (state) => state.bed.defaultParams;
export const { setBedParams, clearBed } = bedSlice.actions;
export default bedSlice.reducer;
