import { createSlice } from "@reduxjs/toolkit";

const designationInitialState = {
    defaultParams: {
        designationName: "",
        offset: 0,
        limit: 10
    },
    searchField: {
        designationValue: null,
    },
    selectedOption: null,
};

const designationSlice = createSlice({
    name: "designations",
    initialState: designationInitialState,
    reducers: {
        setDesignationParams: (state, action) => {
            state.defaultParams = action.payload;
        },
        setSelectedDesignationOption: (state, action) => {
            state.selectedOption = action.payload;
        },
        clearDesignation: (state, action) => {
            return designationInitialState;
        },
    },
    extraReducers: {},
});

export const selectDesignationParam = (state) => state.designations.defaultParams;
export const selectSearchFieldDesignation = (state) => state.designations.searchField;
export const { setDesignationParams, setSelectedDesignationOption, clearDesignation } = designationSlice.actions;
export default designationSlice.reducer;
