// payoutSlice.js

import { createSlice } from "@reduxjs/toolkit";

const payoutInitialState = {
    defaultParams: {
        name: "",
        doctor: "",
        department: "",
        offset: 0,
        limit: 10,
        startDate: "",
        endDate: "",
    },
};

const payoutSlice = createSlice({
    name: "payout",
    initialState: payoutInitialState,
    reducers: {
        setPayoutParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },

        clearPayout: (state, action) => {
            state.defaultParams = payoutInitialState.defaultParams;
        },
    },
    extraReducers: {},
});

export const selectPayoutParam = (state) => state.payout.defaultParams;
export const { setPayoutParams, clearPayout } = payoutSlice.actions;
export default payoutSlice.reducer;
