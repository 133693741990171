import axios from "axios";

let baseURL;
let skynet;

if (process.env.NODE_ENV === 'development') {
    // if (process.env.REACT_APP_ENV === 'development') { 
    baseURL = 'http://192.168.1.12:9900/api/v1';
    skynet = 'http://192.168.1.12:4500/arc';
} else {
    baseURL = 'https://arcprofile.in/api/v1';
    skynet = 'https://skynet.codexp.in/arc';
}

axios.defaults.baseURL = baseURL;
axios.defaults.headers.put['Content-Type'] = 'application/json';

export { skynet };


export const setAuthHeader = (token) => {

    axios.defaults.headers["Authorization"] = token;
    return true;
};

export const clearAuthHeader = () => {
    axios.defaults.headers["Authorization"] = null;
    return true;
};
// UTILS

let fileUrl, fileAuth;
if (process.env.NODE_ENV === 'development') {
    // if (process.env.REACT_APP_ENV === 'development') {
    fileUrl = "http://192.168.1.12:4500/api/v1/upload?project=arc";
    fileAuth = "461584056acb8bd44632c30f58797171efc583c4f6dd0485b979e15a515aa1ab";
}
else {
    fileUrl = "https://skynet.codexp.in/api/v1/upload?project=arc";
    fileAuth = "29acb3b424898e07019dd4a3d50ca4faecc464218e9696ccdc37a165209b33a1";
}


export const getUtils = () => _callApi('/utils/metadata', 'GET',);
// export const skynet = "https://skynet.codexp.in/arc";
// // export const skynet = "http://192.168.1.12:4500/arc";
export const addReport = (body) => _callApi('/reports', 'POST', body);
export const getReports = (investigationId) => _callApi(`/reports?investigation=${investigationId}`, 'GET',);

export const uploadFile = form => axios.post(
    `${fileUrl}`,
    form,
    {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `${fileAuth}`
        }
    }
);

// PAYMENT
export const getPayments = ({ offset, limit, doctor, department, startDate, endDate }) => _callApi(`/payments?offset=${offset}&limit=${limit}&doctor=${doctor}&department=${department}&startDate=${startDate}&endDate=${endDate}`, 'GET');
// export const getPayments = ({ offset, limit, name, department, startDate, endDate }) => _callApi(`/payments?offset=${offset}&limit=${limit}&name=${name}&department=${department}&startDate=${startDate}&endDate=${endDate}`, 'GET');
export const addPayment = (body) => _callApi('/payments', 'POST', body);
export const updatePayment = (paymentId, body) => _callApi(`/payments/${paymentId}`, 'PUT', body);
export const deletePayment = (paymentId) => _callApi(`/payments/${paymentId}`, 'DELETE');

// EXPENSES
export const getExpenses = ({ offset, limit, startDate, endDate }) => _callApi(`/expenses?offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`, 'GET');
export const addExpense = (body) => _callApi('/expenses', 'POST', body);
export const updateExpense = (expenseId, body) => _callApi(`/expenses/${expenseId}`, 'PUT', body);
export const deleteExpense = (expenseId) => _callApi(`/expenses/${expenseId}`, 'DELETE');

// MOT
export const getMots = ({ offset, limit, name, hid, startDate, endDate }) => _callApi(`/mots?offset=${offset}&limit=${limit}&name=${name}&hid=${hid}&startDate=${startDate}&endDate=${endDate}`, 'GET');
export const getMotbyID = (modID) => _callApi(`/mots/${modID}/services`, 'GET');
export const addMot = (body) => _callApi('/mots', 'POST', body);
export const updateMot = (motId, body) => _callApi(`/mots/${motId}`, 'PUT', body);
export const deleteMot = (motId) => _callApi(`/mots/${motId}`, 'DELETE');

// IPD
// FLOOR
export const getFloors = ({ name, offset, limit }) => _callApi(`/floors?offset=${offset}&limit=${limit}&name=${name}`, 'GET');
export const addFloor = (body) => _callApi('/floors', 'POST', body);
export const updateFloor = (floorID, body) => _callApi(`/floors/${floorID}`, 'PUT', body);
export const deleteFloor = (floorID) => _callApi(`/floors/${floorID}`, 'DELETE');

//ROOMS
export const getRooms = ({ name, offset, limit }) => _callApi(`/rooms?offset=${offset}&limit=${limit}&name=${name}`, 'GET');
export const addRoom = (body) => _callApi('/rooms', 'POST', body);
export const updateRoom = (roomID, body) => _callApi(`/rooms/${roomID}`, 'PUT', body);
export const deleteRoom = (roomID) => _callApi(`/rooms/${roomID}`, 'DELETE');


// BEDS
export const getBeds = ({ bed_number, offset, limit }) => _callApi(`/beds?offset=${offset}&limit=${limit}&bed_number=${bed_number}`, 'GET');
export const addBed = (body) => _callApi('/beds', 'POST', body);
export const updateBed = (bedID, body) => _callApi(`/beds/${bedID}`, 'PUT', body);
export const deleteBed = (bedID) => _callApi(`/beds/${bedID}`, 'DELETE');


// DISCHARGE SUMMARIES
export const getSummary = (ipdID) => _callApi(`/summaries?ipd=${ipdID}`, 'GET');
export const getSummaries = ({ offset, limit, name, hid, ipd, mobile }) => _callApi(`/summaries?offset=${offset}&limit=${limit}&name=${name}&hid=${hid}&ipd=${ipd}&mobile=${mobile}`, 'GET');
export const addSummary = ({ ipdID, body }) => _callApi(`/summaries/${ipdID}`, 'POST', body);
export const updateSummary = ({ summaryID, body }) => _callApi(`/summaries/${summaryID}`, 'PUT', body);
export const deleteSummary = (summaryID) => _callApi(`/summaries/${summaryID}`, 'DELETE');

// BILL
export const getBills = ({ name, hid, offset, limit, startDate, endDate, ipd }) => _callApi(`/bills?offset=${offset}&limit=${limit}&name=${name}&hid=${hid}&startDate=${startDate}&endDate=${endDate}&ipd=${ipd}`, 'GET');
export const getBill = (ipdID) => _callApi(`/bills/${ipdID}`, 'GET');
export const addBill = ({ ipdID, body }) => _callApi(`/bills/${ipdID}`, 'POST', body);
export const updateBill = ({ billID, body }) => _callApi(`/bills/${billID}`, 'PUT', body);
export const deleteBill = (billID) => _callApi(`/bills/${billID}`, 'DELETE');



// ANALYTICS
export const getServiceReport = ({ startDate, endDate, serviceID }) => _callApi(`/analytics/reports/service/${serviceID}?startDate=${startDate}&endDate=${endDate}`, 'GET');
export const getLabTestReport = ({ startDate, endDate, testID }) => _callApi(`/analytics/reports/tests?test=${testID}&startDate=${startDate}&endDate=${endDate}`, 'GET');
export const getDoctorCollections = ({ startDate, endDate, doctorID }) => _callApi(`/analytics/doctor/collections/${doctorID}?startDate=${startDate}&endDate=${endDate}`, 'GET');
export const getAnalyticsByID = ({ startDate, endDate, userID }) => _callApi(`/analytics/accounts/${userID}?startDate=${startDate}&endDate=${endDate}`, 'GET');
export const getAnalytics = ({ startDate, endDate }) => _callApi(`/analytics/accounts?startDate=${startDate}&endDate=${endDate}`, 'GET');
export const getAnalytic = (analyticID) => _callApi(`/analytics/${analyticID}`, 'GET');
export const addAnalytic = ({ analyticID, body }) => _callApi(`/analytics/${analyticID}`, 'POST', body);
export const updateAnalytic = ({ analyticID, body }) => _callApi(`/analytics/${analyticID}`, 'PUT', body);
export const deleteAnalytic = (analyticID) => _callApi(`/analytics/${analyticID}`, 'DELETE');

// IPDS
export const getIPDs = ({ name, hid, department, mobile, offset, limit, startDate, endDate, ptId }) => _callApi(`/ipds?name=${name}&hid=${hid}&department=${department}&mobile=${mobile}&offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&patient=${ptId}`, 'GET');
export const getIPD = (ipdID) => _callApi(`/ipds/${ipdID}`, 'GET');
export const addIPD = (body) => _callApi('/ipds', 'POST', body);
export const updateIPD = ({ ipdID, body }) => _callApi(`/ipds/${ipdID}`, 'PUT', body);
export const deleteIPD = (ipdID) => _callApi(`/ipds/${ipdID}`, 'DELETE');

// IPD SERVICES
export const getIPDService = (ipdID) => _callApi(`/ipds/services/${ipdID}`, 'GET');
export const addIPDService = ({ ipdID, body }) => _callApi(`/ipds/services/${ipdID}`, 'POST', body);
export const updateIPDService = ({ serviceId, body }) => _callApi(`/ipds/services/${serviceId}`, 'PUT', body);
export const deleteIPDService = (serviceId) => _callApi(`/ipds/services/${serviceId}`, 'DELETE');

// IPD LAB INV
export const getIPDTest = (testID) => _callApi(`/ipds/tests/${testID}`, 'GET');
export const addIPDTest = ({ ipdID, body }) => _callApi(`/ipds/tests/${ipdID}`, 'POST', body);
export const updateIPDTest = ({ testID, body }) => _callApi(`/ipds/tests/${testID}`, 'PUT', body);
export const deleteIPDTest = (testID) => _callApi(`/ipds/tests/${testID}`, 'DELETE');

// IPB BED HISTORY
export const getIPDBeds = (ipdID) => _callApi(`/ipds/beds/${ipdID}`, 'GET');
export const addIPDBed = ({ ipdID, body }) => _callApi(`/ipds/beds/${ipdID}`, 'POST', body);
export const updateIPDBed = ({ bedID, body }) => _callApi(`/ipds/beds/${bedID}`, 'PUT', body);
export const deleteIPDBed = (bedID) => _callApi(`/ipds/beds/${bedID}`, 'DELETE');



// IPD OTS
export const getIPDOT = (otID) => _callApi(`/ipds/ots/${otID}`, 'GET');
export const addIPDOT = ({ otID, body }) => _callApi(`/ipds/ots/${otID}`, 'POST', body);
export const updateIPDOT = ({ otID, body }) => _callApi(`/ipds/ots/${otID}`, 'PUT', body);
export const deleteIPDOT = (otID) => _callApi(`/ipds/ots/${otID}`, 'DELETE');

// For IPD deposits
export const getIPDDeposit = (depositID) => _callApi(`/ipds/deposits/${depositID}`, 'GET');
export const addIPDDeposit = ({ depositID, body }) => _callApi(`/ipds/deposits/${depositID}`, 'POST', body);
export const updateIPDDeposit = ({ depositID, body }) => _callApi(`/ipds/deposits/${depositID}`, 'PUT', body);
export const deleteIPDDeposit = (depositID) => _callApi(`/ipds/deposits/${depositID}`, 'DELETE');

// IPD REFUND
export const getIPDRefund = (refundID) => _callApi(`/ipds/refunds/${refundID}`, 'GET');
export const addIPDRefund = ({ refundID, body }) => _callApi(`/ipds/refunds/${refundID}`, 'POST', body);
export const updateIPDRefund = ({ refundID, body }) => _callApi(`/ipds/refunds/${refundID}`, 'PUT', body);
export const deleteIPDRefund = (refundID) => _callApi(`/ipds/refunds/${refundID}`, 'DELETE');

// CLINIC PROFILE
export const getByIDClinicProfile = () => _callApi(`/entities/profile`, 'GET',);
export const updateClinicProfile = ({ body }) => _callApi(`/entities/profile`, 'PUT', body);


// DASHBOARD
export const getDashboard = ({ startDate, endDate }) => _callApi(`/dashboard?startDate=${startDate}&endDate=${endDate}`, 'GET',);

// Roles
export const getRoles = ({ offset, limit }) => _callApi(`/roles?offset=${offset}&limit=${limit}`, 'GET',);
export const addRole = (body) => _callApi('/roles', 'POST', body);
export const editRole = (roleId, body) => _callApi(`/roles/${roleId}`, 'PUT', body);
export const deleteRole = (roleId) => _callApi(`/roles/${roleId}`, 'DELETE',);

// Designations
export const getDesignations = ({ offset, limit }) => _callApi(`/designations?offset=${offset}&limit=${limit}`, 'GET',);
export const addDesignation = (body) => _callApi('/designations', 'POST', body);
export const updateDesignation = (designationId, body) => _callApi(`/designations/${designationId}`, 'PUT', body);
export const deleteDesignation = (designationId,) => _callApi(`/designations/${designationId}`, 'DELETE',);

// SERVICES
export const getServices = ({ offset, limit, name }) => _callApi(`/services?offset=${offset}&limit=${limit}&name=${name}`, 'GET',);
export const addService = (body) => _callApi('/services', 'POST', body);
export const updateService = (serviceId, body) => _callApi(`/services/${serviceId}`, 'PUT', body);
export const deleteService = (serviceId,) => _callApi(`/services/${serviceId}`, 'DELETE',);

// OT SERVICES
export const getOTServices = ({ offset, limit, ot_procedure, department }) => _callApi(`/ots?offset=${offset}&limit=${limit}&ot_procedure=${ot_procedure}&department=${department}`, 'GET');
export const addOTService = (body) => _callApi('/ots', 'POST', body);
export const updateOTService = ({ serviceId, body }) => _callApi(`/ots/${serviceId}`, 'PUT', body);
export const deleteOTService = (serviceId) => _callApi(`/ots/${serviceId}`, 'DELETE');


// MINOR OT SERVICE
export const getMOTServices = ({ offset, limit, name }) => _callApi(`/motservices?offset=${offset}&limit=${limit}&name=${name}`, 'GET');
export const addMOTService = (body) => _callApi('/motservices', 'POST', body);
export const updateMOTService = (serviceId, body) => _callApi(`/motservices/${serviceId}`, 'PUT', body);
export const deleteMOTService = (serviceId) => _callApi(`/motservices/${serviceId}`, 'DELETE');


// TEST
export const getTests = ({ offset, limit, name }) => _callApi(`/tests?offset=${offset}&limit=${limit}&name=${name}`, 'GET',);
export const addTest = (body) => _callApi('/tests', 'POST', body);
export const updateTest = (testId, body) => _callApi(`/tests/${testId}`, 'PUT', body);
export const deleteTest = (testId,) => _callApi(`/tests/${testId}`, 'DELETE',);

// DEPARTMENT
export const getDepartments = ({ offset, limit }) => _callApi(`/departments?offset=${offset}&limit=${limit}`, 'GET',);
export const addDepartment = (body) => _callApi('/departments', 'POST', body);
export const updateDepartment = (departmentId, body) => _callApi(`/departments/${departmentId}`, 'PUT', body);
export const deleteDepartment = (departmentId,) => _callApi(`/departments/${departmentId}`, 'DELETE',);

// USERS
export const getUser = (id) => _callApi(`/users/${id}`, 'GET',);
export const updatePwUser = ({ id, body }) => _callApi(`/users/${id}/password`, 'PUT', body);
export const getUsers = ({ offset, limit, name, email, mobile }) => _callApi(`/users?offset=${offset}&limit=${limit}&name=${name}&email=${email}&mobile=${mobile}`, 'GET',);
export const addUser = (body) => _callApi('/users', 'POST', body);
export const addUserPermission = (body) => _callApi('/users/permissions', 'POST', body);
export const getUserPermission = ({ id }) => _callApi(`/users/${id}/permissions`, 'GET');
export const updateUser = (userId, body) => _callApi(`/users/${userId}`, 'PUT', body);
export const deleteUser = (userId,) => _callApi(`/users/${userId}`, 'DELETE',);

// ENTITIES
export const getEntities = () => _callApi('/admin/entities', 'GET',);
export const addEntity = (body) => _callApi('/admin/entities', 'POST', body);
export const updateEntity = (entityId, body) => _callApi(`/admin/entities/${entityId}`, 'PUT', body);
export const deleteEntity = (entityId,) => _callApi(`/admin/entities/${entityId}`, 'DELETE',);

// PATIENTS
export const getPatients = ({ offset, limit, name, mobile, aadhaar, hid }) => _callApi(`/patients/entity/list?offset=${offset}&limit=${limit}&name=${name}&hid=${hid}&mobile=${mobile}&aadhaar=${aadhaar}`, 'GET',);
export const searchPatients = (aadhaar, ArcId) => _callApi(`/patients/utils/search/entity?aadhaar=${aadhaar}&hid=${ArcId}`, 'GET',);
export const addPatient = (body) => _callApi('/patients/', 'POST', body);
export const addPatientEntity = (body) => _callApi('/patients/entity', 'POST', body);
export const updatePatient = (patientId, body) => _callApi(`/patients/entity/list/${patientId}`, 'PUT', body);
export const deletePatient = (patientId) => _callApi(`/patients/entity/list/${patientId}`, 'DELETE',);

// EMPLOYEE

export const getEmployees = ({ offset, limit, name, email, mobile, department }) => _callApi(`/employees?offset=${offset}&limit=${limit}&name=${name}&email=${email}&mobile=${mobile}&department=${department}`, 'GET');
export const addEmployee = (body) => _callApi('/employees', 'POST', body);
export const updateEmployee = (employeeId, body) => _callApi(`/employees/${employeeId}`, 'PUT', body);
export const deleteEmployee = (employeeId) => _callApi(`/employees/${employeeId}`, 'DELETE');

// OPD ENTITY FUNCTIONS
export const getOPDs = ({ offset, limit, hid, name, mobile, department, startDate, endDate, ptId, id }) => _callApi(`/opds?offset=${offset}&limit=${limit}&hid=${hid}&name=${name}&department=${department}&mobile=${mobile}&startDate=${startDate}&endDate=${endDate}&patient=${ptId}&id=${id}`, 'GET');
export const addOPD = (body) => _callApi('/opds', 'POST', body);
export const addOPDFile = (body, id) => _callApi(`/opds/${id}/files`, 'POST', body);
export const getOPDFile = (id) => _callApi(`/opds/${id}/files`, 'GET');
export const updateOPD = (recordId, body) => _callApi(`/opds/${recordId}`, 'PUT', body);
export const deleteOPD = (recordId) => _callApi(`/opds/${recordId}`, 'DELETE');

// // LAB TEST
export const getLabTests = ({ offset, limit, hid, name, startDate, endDate, ptID }) => _callApi(`/labtests?offset=${offset}&limit=${limit}&hid=${hid}&name=${name}&startDate=${startDate}&endDate=${endDate}&patient=${ptID}`, 'GET');
export const getLabTestsList = (testID) => _callApi(`/labtests/tests/${testID}`, 'GET');
export const addLabTest = (body) => _callApi('/labtests', 'POST', body);
export const updateLabTest = (recordId, body) => _callApi(`/labtests/${recordId}`, 'PUT', body);
export const deleteInvestigationLabTest = (recordId, testID) => _callApi(`/labtests/tests/${recordId}/${testID}`, 'DELETE');
export const deleteLabTest = (recordId) => _callApi(`/labtests/${recordId}`, 'DELETE');


//LOGIN
export const login = (body) => _callApi('/auth/login', 'POST', body);
export const logout = () => _callApi('/auth/logout', 'POST',)
export const forgotPw = (body) => _callApi('/auth/forgotpassword', 'POST', body)

//PATIENT
export const ptLogin = (body) => _callApi('/auth/otp', 'POST', body);
export const otpVerify = (body) => _callApi('/auth/otp/verify', 'POST', body);
export const ptOPD = () => _callApi("/client/opds", "GET")
export const ptProfile = () => _callApi("/client/profile", "GET")
export const ptLabInvestigations = () => _callApi("/client/labtests", "GET")
export const ptLabTest = (investigationID) => _callApi(`/client/labtests/${investigationID}`, "GET")
export const ptLabReport = (investigationID, testID) => _callApi(`/client/reports/${investigationID}/${testID}`, "GET")
export const ptLogout = () => _callApi('/auth/logout/client', 'POST',)
export const getOPDFiles = (id) => _callApi(`/client/opds/${id}/files`, 'GET',)


// PUBLIC METADATA
export const publicMetaData = () => _callApi('/public/metadata', 'GET');


const _callApi = async (url, method = 'GET', body = {}) => {
    try {
        const response = await axios[method?.toLowerCase()](url, body);
        const { status, data } = response;
        if (status === 200 || status === 201) return data;
        else return { success: false };
    }
    catch (err) {

        return { success: false, message: err?.response?.data?.message };
    }
};