import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import {
    AiOutlineEdit,
    AiOutlineDelete,
    AiOutlineReload,
} from "react-icons/ai";
import {
    Backdrop,
    Box,
    Fade,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import RedButton from "./common/RedButton";
import ReusableButton from "./common/ReusableButton";
import { useGetRooms } from "../query/hooks/ipd/rooms";
import {
    useAddBed,
    useDeleteBed,
    useEditBed,
    useGetBeds,
} from "../query/hooks/ipd/beds";
import { getPermissionsByModule, showNotification } from "../services/helper";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useGetFloors } from "../query/hooks/ipd/floors";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import { IoMdAdd } from "react-icons/io";
import {
    selectBedParam,
    setBedParams,
} from "../redux/features/filters/bedSlice";

const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

const columns = [
    {
        id: "1",
        label: "Bed Number",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },
    {
        id: "2",
        label: "Floor",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },
    {
        id: "1",
        label: "Roomr",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },
    {
        id: "1",
        label: "Availability",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },

    {
        id: "actions",
        label: "",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },
];
const Beds = () => {
    const dispatch = useDispatch();
    const currentAuth = useSelector(selectCurrentAuth);
    const bedParams = useSelector(selectBedParam);
    const permissionsList = currentAuth?.user?.permissions;
    const bedsPermissions = getPermissionsByModule(1015, permissionsList);
    const {
        add_permission,
        update_permission,
        delete_permission,
        view_permission,
    } = bedsPermissions;
    const intState = {
        floor: 0,
        bed_number: 0,
        room: 0,
    };

    const {
        refetch,
        bedsData,
        isFetching: isFetchingBeds,
        isLoading: isLoadingBeds,
        isRefetching: isRefetchingBeds,
    } = useGetBeds(bedParams);
    const { roomsData } = useGetRooms();

    const { floorsData } = useGetFloors();
    const { deleteBed, isLoading: isDeletingBed } = useDeleteBed();
    const { editBed, isLoading: isUpdatingBed } = useEditBed();
    const { addBed, isLoading: isAddingBed } = useAddBed();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [formData, setFormData] = useState({ ...intState });

    const [open, setOpen] = useState(false);

    const [editOpen, setEditOpen] = useState(false);

    const [editBedData, setEditBedData] = useState(null);

    const [toDelete, setToDelete] = useState("");

    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData(intState);
        setEditBedData(null);
    };

    const handleDeleteConfirmed = () => {
        deleteBed(toDelete);
        handleCloseDeleteDialog();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateUpdatedBed = (updatedBed) => {
        const { id, room, bed_number, floor } = updatedBed;
        if (
            !id ||
            [room, bed_number, floor].some(isNaN) ||
            room <= 0 ||
            bed_number <= 0 ||
            floor <= 0
        ) {
            showNotification(
                "error",
                "Please make sure all fields are filled correctly"
            );
            return false;
        }
        return true;
    };
    const validateAdddBed = (formData) => {
        const { room, bed_number, floor } = formData;
        if (
            [room, bed_number, floor].some(isNaN) ||
            room <= 0 ||
            bed_number <= 0 ||
            floor <= 0
        ) {
            showNotification(
                "error",
                "Please make sure all fields are filled correctly"
            );
            return false;
        }
        return true;
    };
    const handleAddBed = () => {
        const { room, bed_number, floor } = formData;
        const updatedBed = {
            room: parseInt(room),
            bed_number: parseInt(bed_number),
            floor: parseInt(floor),
        };
        if (validateAdddBed(updatedBed)) {
            addBed(updatedBed);
            handleClose();
        }
    };

    const handleEditClick = (row) => {
        setEditBedData(row);
        setOpen(true);
        setEditOpen(true);
    };

    const handleUpdateBed = () => {
        const updatedBed = {
            id: parseInt(editBedData.id),
            room: parseInt(editBedData.room),
            bed_number: parseInt(editBedData.bed_number),
            floor: parseInt(editBedData.floor),
        };
        if (validateUpdatedBed(updatedBed)) {
            editBed(updatedBed);
            handleClose();
        } else {
            // showNotification(
            //     "error",
            //     "Please make sure all fields are filled correctly"
            // );
        }
    };

    const [currentPage, setCurrentPage] = useState(1);

    const handlePaginationChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setBedParams({
                ...bedParams,
                offset: (value - 1) * bedParams.limit,
            })
        );
    };
    return (
        <section>
            <div className="flex justify-between px-10 mb-5">
                <div>
                    <div className="flex items-center space-x-3">
                        <Typography variant="h5">Beds</Typography>

                        <Tooltip title="Refresh" placement="bottom-start">
                            <button
                                onClick={refetch}
                                className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                style={{ cursor: "pointer" }}
                            >
                                <AiOutlineReload size={20} />
                            </button>
                        </Tooltip>
                    </div>
                </div>
                {add_permission === 1 && (
                    <Tooltip title="Add Bed" placement="bottom-start">
                        <button
                            onClick={handleOpen}
                            className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                        >
                            <IoMdAdd size={18} />
                        </button>
                    </Tooltip>
                )}
            </div>
            <Paper
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: "15px",
                }}
                elevation={24}
            >
                <TableContainer
                    sx={{
                        height: 520,
                        overflowX: "scroll",
                        "&::-webkit-scrollbar": {
                            width: 0,
                        },
                    }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            paddingX: column.paddingX,
                                        }}
                                        style={{
                                            minWidth: column.minWidth,
                                            width: column.width,
                                            maxWidth: column.maxWidth,
                                            fontWeight: "bold",
                                            background: "#eeeeee",
                                            textTransform: "uppercase",
                                            backgroundColor: "#27272A",
                                            color: "white",
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bedsData?.result?.list?.map((item, index) => (
                                <TableRow
                                    key={index}
                                    style={{
                                        backgroundColor:
                                            index % 2 === 0
                                                ? "#E4E4E7"
                                                : "white",
                                    }}
                                >
                                    <TableCell align="center">
                                        {item.bed_number}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.floor_name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.room_name}
                                    </TableCell>

                                    <TableCell align="center">
                                        <div className="flex items-center justify-center font-semibold">
                                            {item.is_occupied === 0
                                                ? "Available"
                                                : "Occupied"}
                                        </div>
                                    </TableCell>
                                    <TableCell align="right">
                                        <div className="flex items-center justify-center space-x-4">
                                            {update_permission === 1 && (
                                                <Tooltip
                                                    title="Edit"
                                                    placement="bottom-start"
                                                >
                                                    <button
                                                        onClick={() =>
                                                            handleEditClick(
                                                                item
                                                            )
                                                        }
                                                        className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                    >
                                                        <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                    </button>
                                                </Tooltip>
                                            )}
                                            {delete_permission === 1 && (
                                                <Tooltip
                                                    title="Delete"
                                                    placement="bottom-start"
                                                >
                                                    <button
                                                        onClick={() =>
                                                            handleOpenDeleteDialog(
                                                                item.id
                                                            )
                                                        }
                                                        className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                    >
                                                        <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                    </button>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="flex items-center justify-center my-4">
                    <Pagination
                        count={Math.ceil(
                            bedsData?.result?.count / bedParams.limit
                        )}
                        page={currentPage}
                        sx={{
                            "& .MuiSelect-select": {
                                display: "none !important",
                            },
                            "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                {
                                    display: "none !important",
                                },
                        }}
                        onChange={handlePaginationChange}
                    />
                </div>
            </Paper>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {editBedData ? (
                            <div>
                                <Typography
                                    id="transition-modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                >
                                    Update Bed
                                </Typography>
                                <section className="mb-5 space-y-5">
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Bed Number"
                                            name="bed_number"
                                            value={editBedData.bed_number}
                                            onChange={(e) =>
                                                setEditBedData({
                                                    ...editBedData,
                                                    bed_number: e.target.value,
                                                })
                                            }
                                            fullWidth
                                            margin="normal"
                                            inputProps={{
                                                inputMode: "numeric",
                                                pattern: "[0-9]*", // Allow only numeric input
                                                maxLength: 10,
                                            }}
                                            onInvalid={(e) => {
                                                e.preventDefault(); // Prevent the default validation message
                                                showNotification(
                                                    "error",
                                                    "Please enter a valid number."
                                                );
                                            }}
                                            onInput={(e) => {
                                                e.target.value =
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ""
                                                    ); // Remove non-numeric characters
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>Floor</InputLabel>
                                        <Select
                                            disabled={isAddingBed}
                                            name="floor"
                                            label="Floor"
                                            value={editBedData.floor}
                                            onChange={(e) =>
                                                setEditBedData({
                                                    ...editBedData,
                                                    floor: e.target.value,
                                                })
                                            }
                                            fullWidth
                                        >
                                            {floorsData?.result?.list?.map(
                                                (floor) => (
                                                    <MenuItem
                                                        key={floor.id}
                                                        value={floor.id}
                                                    >
                                                        {floor.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Room</InputLabel>
                                        <Select
                                            id="demo-simple-select"
                                            name="room"
                                            label="Room"
                                            value={editBedData.room}
                                            onChange={(e) =>
                                                setEditBedData({
                                                    ...editBedData,
                                                    room: e.target.value,
                                                })
                                            }
                                        >
                                            {roomsData?.result?.list?.map(
                                                (room) => (
                                                    <MenuItem
                                                        key={room.id}
                                                        value={room.id}
                                                    >
                                                        {room.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </section>
                                <div className="flex items-center justify-evenly">
                                    <ReusableButton
                                        onClick={handleUpdateBed}
                                        title="Update"
                                    />
                                    <RedButton
                                        onClick={handleClose}
                                        title="Cancel"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Typography
                                    id="transition-modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                >
                                    Add Bed
                                </Typography>
                                <section className="mb-5 space-y-5">
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Bed Number"
                                            name="bed_number"
                                            value={formData.bed_number}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            inputProps={{
                                                inputMode: "numeric",
                                                pattern: "[0-9]*", // Allow only numeric input
                                                maxLength: 10,
                                            }}
                                            onInvalid={(e) => {
                                                e.preventDefault(); // Prevent the default validation message
                                                showNotification(
                                                    "error",
                                                    "Please enter a valid number."
                                                );
                                            }}
                                            onInput={(e) => {
                                                e.target.value =
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ""
                                                    ); // Remove non-numeric characters
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>Floor</InputLabel>
                                        <Select
                                            disabled={isAddingBed}
                                            name="floor"
                                            label="Floor"
                                            value={formData.floor}
                                            onChange={handleChange}
                                            fullWidth
                                        >
                                            {floorsData?.result?.list?.map(
                                                (floor) => (
                                                    <MenuItem
                                                        key={floor.id}
                                                        value={floor.id}
                                                    >
                                                        {floor.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Room</InputLabel>
                                        <Select
                                            id="demo-simple-select"
                                            name="room"
                                            label="Room"
                                            value={formData.room}
                                            onChange={handleChange}
                                        >
                                            {roomsData?.result?.list?.map(
                                                (room) => (
                                                    <MenuItem
                                                        key={room.id}
                                                        value={room.id}
                                                    >
                                                        {room.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </section>
                                <div className="flex items-center justify-evenly">
                                    <ReusableButton
                                        onClick={handleAddBed}
                                        title="Add"
                                    />
                                    <RedButton
                                        onClick={handleClose}
                                        title="Cancel"
                                    />
                                </div>
                            </div>
                        )}
                    </Box>
                </Fade>
            </Modal>
            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeletingBed}
            />
        </section>
    );
};

export default Beds;
