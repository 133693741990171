import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineReload,
} from "react-icons/ai";

import Typography from "@mui/material/Typography";
import { Pagination, TextField, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import {
  useAddService,
  useDeleteService,
  useEditService,
  useGetServices,
} from "../query/hooks/service/servicesHook";
import { getPermissionsByModule, showNotification } from "../services/helper";
import LoaderLogo from "./common/LoaderLogo";
import { IoMdAdd } from "react-icons/io";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import ReusableButton from "./common/ReusableButton";
import RedButton from "./common/RedButton";
import { useGetMetaData } from "../query/hooks/metadata/metadataHook";
import {
  useAddOTService,
  useDeleteOTService,
  useEditOTService,
  useGetOTServices,
} from "../query/hooks/otservices/otServices";
import ClearButton from "./common/ClearButton";
import {
  clearOtServices,
  selectOtServicesParam,
  setOtServicesParams,
} from "../redux/features/filters/otServicesSlice";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const columns = [
  { id: "name", label: "Procedure Name", minWidth: 100, align: "center" },
  { id: "ot_charge", label: "OT Charge", minWidth: 100, align: "center" },
  {
    id: "surgeon_charge",
    label: "Surgeon Charge",
    minWidth: 100,
    align: "center",
  },
  {
    id: "assistant_surgeon_charge",
    label: "Assistant Surgeon Charge",
    minWidth: 100,
    align: "center",
  },
  { id: "pwf_charge", label: "PWF Charge", minWidth: 100, align: "center" },
  {
    id: "anaesthesia_charge",
    label: "Anaesthesia Charge",
    minWidth: 100,
    align: "center",
  },

  { id: "department", label: "Department", align: "center", minWidth: 100 },
  { id: "action", label: "Actions", minWidth: 100, align: "center" },
];

const initial = {
  department: "",
  ot_charge: "",
  surgeon_charge: "",
  assistant_surgeon_charge: "",
  pwf_charge: "",
  anaesthesia_charge: "",
  ot_procedure: "",
};

const OtServices = () => {
  const dispatch = useDispatch();
  const otParams = useSelector(selectOtServicesParam);
  const { metaData } = useGetMetaData();
  const limit = 1000;
  const [offset, setOffset] = useState(0);
  const currentAuth = useSelector(selectCurrentAuth);
  const permissionsList = currentAuth?.user?.permissions;
  const [selectedOt, setSelectedOt] = useState({
    ot_procedure: "",
    department: "",
  });

  const otsPermissions = getPermissionsByModule(1017, permissionsList);
  const { add_permission, update_permission, delete_permission } =
    otsPermissions;

  const {
    otServicesData,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
    isRefetching,
  } = useGetOTServices(otParams);
  const { otServicesData: allOtServices } = useGetOTServices();
  const { addOTService, isLoading: isAddingOTService } = useAddOTService();
  const { editOTService, isLoading: isEditingOTService } = useEditOTService();
  const { deleteOTService, isLoading: isDeletingOTService } =
    useDeleteOTService();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [editOpen, setEditOpen] = useState(false);

  const [editedService, setEditedService] = useState({
    id: null,
    created_on: "",
    created_by: null,
    entity: null,
    surgeon_charge: "",
    assistant_surgeon_charge: "",
    pwf_charge: "",
    anaesthesia_charge: "",
    ot_charge: "",
    ot_procedure: "",
    department: null,
    department_name: "",
    created_by_name: "",
  });
  const [toDelete, setToDelete] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [service, setService] = useState({ ...initial });

  console.log("editedService", editedService);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "charge") {
      const parsedValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
      setService((prevService) => ({
        ...prevService,
        [name]: parsedValue,
      }));
    } else {
      setService((prevService) => ({
        ...prevService,
        [name]: value,
      }));
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedDept(null);
    setInputDeptValue("");
    setOpen(false);
  };

  const validateService = (service) => {
    const errors = {};

    if (!service.name || service.name.trim() === "") {
      errors.name = "Service name is required";
    }

    if (isNaN(service.charge) || service.charge <= 0) {
      errors.charge = "Invalid charge amount";
    }

    return errors;
  };

  const handleAdd = () => {
    const parsedData = { ...service };
    Object.keys(parsedData).forEach((key) => {
      if (key.includes("charge")) {
        parsedData[key] = parseInt(parsedData[key]) || 0;
      }
    });

    const validateData = (data) => {
      const {
        ot_charge,
        surgeon_charge,
        assistant_surgeon_charge,
        pwf_charge,
        anaesthesia_charge,
      } = data;
      const charges = [
        ot_charge,
        surgeon_charge,
        assistant_surgeon_charge,
        pwf_charge,
        anaesthesia_charge,
      ];

      const nullCharge = charges.some((charge) => charge === null);

      return nullCharge;
    };

    const nullCharge = validateData(parsedData);

    if (!nullCharge) {
      addOTService(parsedData);

      if (!isAddingOTService) {
        setService({ ...initial });
        setSelectedDept(null);
        setInputDeptValue("");
      }
      handleClose();
    } else {
      showNotification(
        "error",
        "Invalid charge detected. Please make sure all charges are provided."
      );
    }
  };

  const handleCancel = () => {
    setService({ initial });
    handleClose();
  };

  const handleEditClick = (row) => {
    setEditedService(row);
    setEditOpen(true);
    setSelectedEditDept(row?.department);
  };

  const handleEditSave = (row) => {
    const parsedData = { ...editedService };
    Object.keys(parsedData).forEach((key) => {
      if (key.includes("charge")) {
        parsedData[key] = parseInt(parsedData[key]) || 0;
      }
    });

    const validateData = (data) => {
      const {
        ot_charge,
        surgeon_charge,
        assistant_surgeon_charge,
        pwf_charge,
        anaesthesia_charge,
      } = data;
      const charges = [
        ot_charge,
        surgeon_charge,
        assistant_surgeon_charge,
        pwf_charge,
        anaesthesia_charge,
      ];

      const nullCharge = charges.some((charge) => charge === null);

      return nullCharge;
    };

    const nullCharge = validateData(parsedData);

    if (!nullCharge) {
      editOTService({
        serviceId: parsedData.id,
        otServiceData: parsedData,
      });

      if (!isEditingOTService) {
        setEditedService("");
      }
      handleEditClose();
    } else {
      showNotification(
        "error",
        "Invalid charge detected. Please make sure all charges are provided."
      );
    }
  };

  const handleOpenDeleteDialog = (row) => {
    setOpenDeleteDialog(true);
    setToDelete(row);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmed = () => {
    deleteOTService(toDelete);
    handleCloseDeleteDialog();
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleClear = () => {
    setSelectedOption(null);
    setInputValue("");
  };

  const [selectedDept, setSelectedDept] = useState(null);
  const [inputDeptValue, setInputDeptValue] = useState("");

  const [selectedEditDept, setSelectedEditDept] = useState(null);
  const [editDeptValue, setEditDeptValue] = useState("");

  const handleDeptChange = (event, newDeptValue) => {
    setSelectedDept(newDeptValue);
    setService((prevService) => ({
      ...prevService,
      department: newDeptValue.id,
    }));
  };

  const handleDeptInputChange = (event, newDeptInputValue) => {
    setInputDeptValue(newDeptInputValue);
  };
  const handleEdit = (e) => {
    const { name, value } = e.target;

    if (name === "ot_procedure" || name === "department") {
      setEditedService((prevService) => ({
        ...prevService,
        [name]: value,
      }));
    } else if (name.endsWith("_charge")) {
      const parsedValue = parseFloat(value);
      setEditedService((prevService) => ({
        ...prevService,
        [name]: isNaN(parsedValue) ? 0 : parsedValue,
      }));
    } else {
      console.warn(`Unhandled input name: ${name}`);
    }
  };

  const handleDeptChangeEdit = (event, newDeptValue) => {
    setEditedService((prevService) => ({
      ...prevService,
      department: newDeptValue.id,
    }));
    setSelectedEditDept(newDeptValue.id);
  };
  const handleEditDeptInputChange = (event, newDeptInputValue) => {
    setEditDeptValue(newDeptInputValue);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditedService("");
    setEditDeptValue(null);
  };
  const [selectedOtOption, setSelectedOtOption] = useState(null);
  const [otInputValue, setOtInputValue] = useState("");

  const handleOtOptionChange = (event, newValue) => {
    setSelectedOtOption(newValue);
    setOtInputValue(newValue.ot_procedure);
    setSelectedOt({
      ...selectedOt,
      ot_procedure: newValue.ot_procedure,
    });
    dispatch(
      setOtServicesParams({
        ...otParams,
        ot_procedure: newValue.ot_procedure,
      })
    );
  };

  const handleOtInputChange = (event, newInputValue) => {
    setOtInputValue(newInputValue);
  };

  const handleOtClear = () => {
    setSelectedOtOption(null);
    setOtInputValue("");
    setSelectedOt({
      ot_procedure: "",
    });
  };

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentInputValue, setDepartmentInputValue] = useState("");

  const handleClearSelectedDepartment = () => {
    setSelectedDepartment(null);
    setDepartmentInputValue(null);
    setSelectedOt((prevSearchParams) => ({
      ...prevSearchParams,
      department: "",
    }));
  };

  const handleDepartmentSelectionChange = (event, value) => {
    setSelectedDepartment(value);
    dispatch(
      setOtServicesParams({
        ...otParams,
        department: value ? value.id : "",
      })
    );
  };
  const clearSearch = () => {
    dispatch(clearOtServices());
    setSelectedOtOption(null);
    setOtInputValue("");
    setSelectedDepartment(null);
    setDepartmentInputValue("");
    setSelectedOt({
      ot_procedure: "",
      department: "",
    });
    dispatch(
      setOtServicesParams({
        ot_procedure: "",
        department: "",
      })
    );
  };
  const handleDepartmentInputValueChange = (event, value) => {
    setDepartmentInputValue(value);
  };
  const [currentPage, setCurrentPage] = useState(1);

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
    dispatch(
      setOtServicesParams({
        ...otParams,
        offset: (value - 1) * otParams.limit,
      })
    );
  };

  if (!otServicesData)
    return (
      <div className="flex items-center justify-center">
        <LoaderLogo />
      </div>
    );
  return (
    <section>
      <div className="">
        <div className="flex justify-between px-10 my-4">
          <div>
            <div className="flex items-center space-x-3">
              <Typography variant="h5">OT Services</Typography>

              <Tooltip title="Refresh" placement="bottom-start">
                <button
                  onClick={refetch}
                  className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                  style={{ cursor: "pointer" }}
                >
                  <AiOutlineReload size={20} />
                </button>
              </Tooltip>
            </div>
          </div>
          {/* filter&Search */}
          <div className="flex items-center">
            <div className="w-[100%] ml-4">
              <div className="flex items-center space-x-4 ">
                <Autocomplete
                  className="w-[350px]"
                  options={allOtServices?.result?.list || []}
                  getOptionLabel={(option) => option.ot_procedure}
                  value={selectedOtOption}
                  onChange={handleOtOptionChange}
                  inputValue={otInputValue}
                  onInputChange={handleOtInputChange}
                  disableClearable
                  isOptionEqualToValue={(option, value) =>
                    option.ot_procedure === value?.ot_procedure
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search OT Service"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {selectedOtOption && (
                              <ClearIcon
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={handleOtClear}
                              />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <Autocomplete
                  className="w-[250px]"
                  options={metaData?.result?.departments || []}
                  getOptionLabel={(option) => option.name}
                  value={selectedDepartment}
                  onChange={handleDepartmentSelectionChange}
                  inputValue={departmentInputValue}
                  onInputChange={handleDepartmentInputValueChange}
                  disableClearable
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Department"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {selectedDepartment && (
                              <ClearIcon
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={handleClearSelectedDepartment}
                              />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />

                <ClearButton title="Clear" onClick={clearSearch} />
              </div>
            </div>
          </div>
          {add_permission === 1 && (
            <Tooltip title="Add Service" placement="bottom-start">
              <button
                onClick={handleOpen}
                className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
              >
                <IoMdAdd size={18} />
              </button>
            </Tooltip>
          )}
        </div>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "15px",
          }}
          elevation={24}
        >
          <TableContainer
            sx={{
              height: 520,
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                width: 0,
              },
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bold",
                        background: "#eeeeee",
                        textTransform: "uppercase",
                        backgroundColor: "#27272A",
                        color: "white",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {!isLoading && !isFetching && !isRefetching ? (
                <TableBody>
                  {/* {otServicesData?.result?.list?.map((row) => { */}
                  {otServicesData?.result?.list?.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        key={row?.id}
                        align="center"
                      >
                        <TableCell align="center">
                          {row?.ot_procedure}
                        </TableCell>
                        <TableCell align="center">₹ {row?.ot_charge}</TableCell>
                        <TableCell align="center">
                          ₹ {row?.surgeon_charge}
                        </TableCell>
                        <TableCell align="center">
                          ₹ {row?.assistant_surgeon_charge}
                        </TableCell>
                        <TableCell align="center">
                          ₹ {row?.pwf_charge}
                        </TableCell>
                        <TableCell align="center">
                          ₹ {row?.anaesthesia_charge}
                        </TableCell>
                        <TableCell align="center">
                          {row?.department_name}
                        </TableCell>
                        {/* <TableCell align="center">
                                                        {dayjs(
                                                            row?.created_on
                                                        ).format(
                                                            "DD-MM-YYYY,h:mm:ss A"
                                                        )}
                                                    </TableCell> */}
                        <TableCell
                          align="center"
                          sx={{
                            width: 120,
                          }}
                        >
                          <div className="flex items-center justify-center space-x-4">
                            {update_permission === 1 && (
                              <Tooltip title="Edit" placement="bottom-start">
                                <button
                                  onClick={() => handleEditClick(row)}
                                  className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                >
                                  <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                </button>
                              </Tooltip>
                            )}
                            {delete_permission === 1 && (
                              <Tooltip title="Delete" placement="bottom-start">
                                <button
                                  onClick={() =>
                                    handleOpenDeleteDialog(row?.id)
                                  }
                                  className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                >
                                  <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                </button>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      sx={{
                        width: 120,
                      }}
                    >
                      <div className="flex items-center justify-center">
                        <LoaderLogo />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <div className="flex items-center justify-center my-4">
            <Pagination
              count={Math.ceil(otServicesData?.result?.count / otParams.limit)}
              page={currentPage}
              sx={{
                "& .MuiSelect-select": {
                  display: "none !important",
                },
                "& > div.MuiToolbar-root > div.MuiInputBase-root > svg": {
                  display: "none !important",
                },
              }}
              onChange={handlePaginationChange}
            />
          </div>
        </Paper>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                marginBottom: 2,
              }}
              textAlign="center"
            >
              Add OT Service
            </Typography>
            <section className="grid w-full grid-cols-2 gap-4">
              <TextField
                required
                margin="dense"
                id="ot_procedure"
                name="ot_procedure"
                label="OT Procedure"
                type="text"
                fullWidth
                value={service.ot_procedure}
                onChange={handleChange}
              />
              <Autocomplete
                fullWidth
                margin="dense"
                sx={{
                  mt: 1,
                }}
                options={metaData?.result?.departments || []}
                getOptionLabel={(option) => option.name}
                value={selectedDept}
                onChange={handleDeptChange}
                inputValue={inputDeptValue}
                onInputChange={handleDeptInputChange}
                disableClearable
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Department"
                    variant="outlined"
                  />
                )}
              />
              <TextField
                required
                margin="dense"
                id="ot_charge"
                name="ot_charge"
                label="OT Charge"
                type="text"
                fullWidth
                value={service.ot_charge}
                onChange={handleChange}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                }}
              />
              <TextField
                required
                margin="dense"
                id="surgeon_charge"
                name="surgeon_charge"
                label="Surgeon Charge"
                type="text"
                fullWidth
                value={service.surgeon_charge}
                onChange={handleChange}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                }}
              />
              <TextField
                required
                margin="dense"
                id="assistant_surgeon_charge"
                name="assistant_surgeon_charge"
                label="Assistant Surgeon Charge"
                type="text"
                fullWidth
                value={service.assistant_surgeon_charge}
                onChange={handleChange}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                }}
              />
              <TextField
                required
                margin="dense"
                id="pwf_charge"
                name="pwf_charge"
                label="PWF Charge"
                type="text"
                fullWidth
                value={service.pwf_charge}
                onChange={handleChange}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                }}
              />
              <TextField
                required
                margin="dense"
                id="anaesthesia_charge"
                name="anaesthesia_charge"
                label="Anaesthesia Charge"
                type="text"
                fullWidth
                value={service.anaesthesia_charge}
                onChange={handleChange}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                }}
              />
            </section>
            <div className="flex justify-between p-6">
              <ReusableButton
                isDisabled={isAddingOTService}
                onClick={handleAdd}
                title="Save"
              />

              <RedButton
                isDisabled={isAddingOTService}
                onClick={handleCancel}
                title="Cancel"
              />
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editOpen}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                marginBottom: 2,
              }}
            >
              Edit Service
            </Typography>

            <section className="grid w-full grid-cols-2 gap-4">
              <TextField
                required
                margin="dense"
                id="ot_procedure"
                name="ot_procedure"
                label="OT Procedure"
                fullWidth
                value={editedService?.ot_procedure}
                onChange={handleEdit}
              />

              <Autocomplete
                fullWidth
                margin="dense"
                sx={{ mt: 1 }}
                options={metaData?.result?.departments || []}
                getOptionLabel={(option) => option?.name}
                filterOptions={(x) => x}
                value={
                  metaData?.result?.departments.filter(
                    (dept) => dept.id === selectedEditDept
                  )[0] || null
                }
                onChange={handleDeptChangeEdit}
                inputValue={editDeptValue}
                onInputChange={handleEditDeptInputChange}
                disableClearable
                isOptionEqualToValue={(option, value) => {
                  if (value) {
                    return option.id === value.id;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Department"
                    variant="outlined"
                  />
                )}
              />

              <TextField
                required
                margin="dense"
                id="ot_charge"
                name="ot_charge"
                label="OT Charge"
                type="text"
                fullWidth
                value={editedService?.ot_charge}
                onChange={handleEdit}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                }}
              />

              <TextField
                required
                margin="dense"
                id="surgeon_charge"
                name="surgeon_charge"
                label="Surgeon Charge"
                type="text"
                fullWidth
                value={editedService?.surgeon_charge}
                onChange={handleEdit}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                }}
              />

              <TextField
                required
                margin="dense"
                id="assistant_surgeon_charge"
                name="assistant_surgeon_charge"
                label="Assistant Surgeon Charge"
                type="text"
                fullWidth
                value={editedService?.assistant_surgeon_charge}
                onChange={handleEdit}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                }}
              />

              <TextField
                required
                margin="dense"
                id="pwf_charge"
                name="pwf_charge"
                label="PWF Charge"
                type="text"
                fullWidth
                value={editedService?.pwf_charge}
                onChange={handleEdit}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                }}
              />

              <TextField
                required
                margin="dense"
                id="anaesthesia_charge"
                name="anaesthesia_charge"
                label="Anaesthesia Charge"
                type="text"
                fullWidth
                value={editedService?.anaesthesia_charge}
                onChange={handleEdit}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    pattern: "[0-9]*",
                  },
                }}
              />
            </section>

            <div className="flex justify-between p-6">
              <ReusableButton
                isDisabled={isEditingOTService}
                onClick={handleEditSave}
                title="Save"
              />

              <RedButton
                isDisabled={isEditingOTService}
                onClick={handleEditClose}
                title="Cancel"
              />
            </div>
          </Box>
        </Fade>
      </Modal>

      <CommonDeleteModal
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onCancel={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmed}
        isDeleting={isDeletingOTService}
      />
    </section>
  );
};

export default OtServices;
