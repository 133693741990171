import './index.css';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from "./query/queryClient";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from 'react-hot-toast';

// const theme = createTheme({
// 	palette: {
// 		mode: "light",
// 		customColors: {
// 			tableHead: '#000000',
// 			tableBody: '#1F1F1F',
// 			tableBodyHover: '#30343f'
// 		}
// 	},
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <ToastContainer />
            <BrowserRouter>

                <App />
                <Toaster />

            </BrowserRouter>
            <ReactQueryDevtools />
        </QueryClientProvider>
    </Provider>
);