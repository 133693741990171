import React from "react";
import {
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from "@mui/material";
import ReusableButton from "./ReusableButton";
import ClearButton from "./ClearButton";
import RedButton from "./RedButton";
const AddPatientForm = ({
    handleKeyPress2,
    handleSelectChange,
    handleTextFieldChange,
    handleAddPatient,
    handleNextStep,
    handlePTClose,
    patient,
    ptExist,
}) => {
    return (
        <>
            <Typography className="text-center" variant="h5">
                Add 
            </Typography>
            <div
                className="grid grid-cols-3 gap-5 p-6"
                onKeyDown={handleKeyPress2}
            >
                <FormControl fullWidth variant="outlined" required>
                    <InputLabel htmlFor="title">Title</InputLabel>
                    <Select
                        disabled={ptExist}
                        label="Title"
                        id="title"
                        name="title"
                        required
                        value={patient.title}
                        onChange={handleSelectChange}
                    >
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Mrs">Mrs</MenuItem>
                        <MenuItem value="Miss">Miss</MenuItem>
                        <MenuItem value="Ms">Ms</MenuItem>
                        {/* Add more title options as needed */}
                    </Select>
                </FormControl>
                <TextField
                    disabled={ptExist}
                    required
                    name="name"
                    className="col-span-2"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={patient.name}
                    onChange={handleTextFieldChange}
                />
                <FormControl variant="outlined">
                    {/* <Inputlabel htmlFor="gender">Gender</Inputlabel> */}
                    <InputLabel>Gender</InputLabel>
                    <Select
                        disabled={ptExist}
                        required
                        name="gender"
                        value={patient.gender}
                        onChange={handleSelectChange}
                        label="Gender"
                    >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Marital Status</InputLabel>
                    <Select
                        disabled={ptExist}
                        name="marital_status"
                        value={patient.marital_status}
                        onChange={handleSelectChange}
                        label="Marital Status"
                    >
                        <MenuItem value="unmarried">Single</MenuItem>
                        <MenuItem value="married">Married</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    disabled={ptExist}
                    required
                    name="mobile"
                    label="Mobile"
                    variant="outlined"
                    fullWidth
                    value={patient.mobile}
                    onChange={handleTextFieldChange}
                    // inputProps={{ maxLength: 10 }}
                    // onInput = {(e) =>{
                    //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                    // }}
                />
                <TextField
                    disabled={ptExist}
                    name="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={patient.email}
                    onChange={handleTextFieldChange}
                />

                <TextField
                    disabled={ptExist}
                    required
                    className="col-span-2"
                    name="aadhaar"
                    label="Aadhaar"
                    variant="outlined"
                    fullWidth
                    value={patient.aadhaar}
                    onChange={handleTextFieldChange}
                />
                <TextField
                    disabled={ptExist}
                    required
                    name="age"
                    label="Age"
                    variant="outlined"
                    fullWidth
                    value={patient.age}
                    onChange={handleTextFieldChange}
                />
                <TextField
                    disabled={ptExist}
                    name="months"
                    label="Months"
                    variant="outlined"
                    fullWidth
                    value={patient.months}
                    onChange={handleTextFieldChange}
                />
                <TextField
                    disabled={ptExist}
                    name="days"
                    label="Days"
                    variant="outlined"
                    fullWidth
                    value={patient.days}
                    onChange={handleTextFieldChange}
                />
                <TextField
                    disabled={ptExist}
                    name="care_of"
                    label="Care Of"
                    variant="outlined"
                    fullWidth
                    value={patient.care_of}
                    onChange={handleTextFieldChange}
                />
                <TextField
                    disabled={ptExist}
                    name="relationship"
                    label="Relationship"
                    variant="outlined"
                    fullWidth
                    value={patient.relationship}
                    onChange={handleTextFieldChange}
                />
                <TextField
                    disabled={ptExist}
                    name="occupation"
                    label="Occupation"
                    variant="outlined"
                    fullWidth
                    value={patient.occupation}
                    onChange={handleTextFieldChange}
                />
                <TextField
                    disabled={ptExist}
                    className="col-span-3"
                    name="address"
                    label="Address"
                    variant="outlined"
                    fullWidth
                    value={patient.address}
                    onChange={handleTextFieldChange}
                />
                <TextField
                    disabled={ptExist}
                    name="district"
                    label="District"
                    variant="outlined"
                    fullWidth
                    value={patient.district}
                    onChange={handleTextFieldChange}
                />
                <TextField
                    disabled={ptExist}
                    name="pincode"
                    label="Pincode"
                    variant="outlined"
                    fullWidth
                    value={patient.pincode}
                    onChange={handleTextFieldChange}
                />

                <div className="col-span-3">
                    <TextField
                        disabled={ptExist}
                        name="remarks"
                        label="Remarks"
                        variant="outlined"
                        fullWidth
                        value={patient.remarks}
                        onChange={handleTextFieldChange}
                    />
                </div>
            </div>
            <div className="flex justify-between px-10">
                {!ptExist && (
                    <ReusableButton
                        title="Add"
                        onClick={handleAddPatient}
                        type="submit"
                    />
                )}
                {ptExist && (
                    <ReusableButton
                        title="Next"
                        onClick={handleNextStep}
                        type="submit"
                    />
                )}
                <RedButton title="Cancel" onClick={handlePTClose} />
            </div>
        </>
    );
};

export default AddPatientForm;
