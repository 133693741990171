import { isError, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { getUsers, addUser, updateUser, deleteUser, addUserPermission, getUserPermission, getUser, updatePwUser } from "../../../services/api";
import { useSelector } from "react-redux";
import { showNotification } from "../../../services/helper";
import { useGetQuery } from "../commonQueryFunction";

export function useGetCurrentUser(id) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.user],
        queryFn: () => getUser(id),
        enabled: id ? true : false,
    });

    return { currentUserData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

// export function useGetUsers(userParams) {
//     return useGetQuery('users', () => getUsers({ offset: 0, limit: 10 })); // Assuming getUsers is your actual query function
// }

export function useGetUsers(userParams = {
    name: "",
    email: "",
    mobile: "",
    offset: 0,
    limit: 10000,
}) {
    const { name, email, mobile, offset, limit } = userParams;

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.users, { offset }, { limit }, { name }, { email }, { mobile }],
        queryFn: () => getUsers({ offset, limit, name, email, mobile }),
    });

    return { data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

// export function useGetPermission(userId) {
//     const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
//         queryKey: [queryKeys.permissions],
//         queryFn: (userId) => getUserPermission({ id: userId }),
//     });
//     return { permissionData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
// }

export function useAddUser() {
    // Replace with how you access your application state
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (userData) => addUser(userData), // Assuming you have a  in your Redux state
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.roles] });
            if (data?.success) {
                showNotification("success", `User added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addUser: mutate, isLoading };
}

export function useAddUserPermission(func) {
    // Replace with how you access your application state
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (userData) => addUserPermission(userData), // Assuming you have a  in your Redux state
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.roles] });
            if (data?.success) {
                showNotification("success", data.message);
                func();

            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addPermission: mutate, isLoading };
}


export function useEditUser() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updateUser(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.roles] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { editUser: mutate, isLoading };
}
export function useUpdatePassword(setFormData) {
    const queryClient = useQueryClient();
    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: (postData) => updatePwUser(postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.roles] });
            if (data?.success) {
                showNotification("success", data.message);
                setFormData({
                    email: "",
                    password: "",
                    newPassword: "",
                    confirmPassword: "",
                })
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { updatePw: mutate, isLoading, isSuccess, };
}

export function useDeleteUser() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (userId) => deleteUser(userId,),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.users] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteUser: mutate, isLoading };
}
