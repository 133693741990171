import React from "react";
import logo from "../../assets/logo.svg";

const LoaderLogo = () => {
    return (
        <div className="w-[500px] flex justify-center items-center p-2">
            <img
                src={logo}
                alt="Logo"
                className=" w-[70%]  h-auto opacity-40 animate-spin animate-infinite animate-duration-[40000ms] animate-fill-forwards"
            />
        </div>
    );
};

export default LoaderLogo;
