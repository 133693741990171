import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  FormControl,
  InputLabel,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import { useNavigate } from "react-router-dom";
import { Button, TextField, MenuItem } from "@mui/material";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoFilterSharp } from "react-icons/io5";
import { useGetPatientLabInvestigations } from "../../query/hooks/patientsSide/patientSideHooks";
import dayjs from "dayjs";
import NoDataFound from "../common/NoDataFound";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const PatientTestReport = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedHospital, setSelectedHospital] = useState("");
  const [testNameFilter, setTestNameFilter] = useState("");
  const { data: patientLabData, isLoading: isPatientOPDLoading } =
    useGetPatientLabInvestigations();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = () => {
    handleClose();
  };
  const handleViewClick = (id) => {
    navigate(`/patient/testreports/${id}`);
  };

  const LabCard = ({ data, onViewClick, count }) => {
    return (
      <div className="shadow-2xl rounded-xl">
        <div className="p-5 bg-[rgb(39,39,42)] text-white rounded-xl">
          <div className="space-y-1 overflow-clip">
            <Typography variant="body1">
              <strong>Name: </strong>
              {data.patient_name}
            </Typography>
            <Typography variant="body1">
              <strong>Hospital/Clinic: </strong> {data.entity_name}
            </Typography>
            <Typography variant="body1">
              <strong>Number of Test: </strong> {count}
            </Typography>
            <Typography variant="body2">
              <strong>Test Date: </strong>
              {dayjs(data.created_on).format("DD/MM/YYYY")}
            </Typography>
          </div>

          <div className="flex items-center w-full mt-6 justify-evenly">
            <Tooltip title="VIEW" placement="bottom-start">
              <button
                onClick={() => onViewClick(data.id)}
                className="px-6 py-2 font-semibold duration-300 bg-green-600 rounded-2xl group hover:bg-green-500"
              >
                {/* <VisibilityIcon /> */}
                VIEW/DOWNLOAD
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex items-center justify-between px-5 mb-5">
        <Typography variant="h6">
          <MonitorHeartIcon fontSize="medium" sx={{ mr: 1 }} />
          <strong>Lab Test Reports</strong>
        </Typography>
        <Button variant="outlined" onClick={handleOpen}>
          <IoFilterSharp />
        </Button>
      </div>
      <section>
        {patientLabData?.result?.count === 0 ? (
          <div className="mx-auto">
            <NoDataFound />
          </div>
        ) : (
          patientLabData?.result?.list.map((testData) => (
            <LabCard
              key={testData.id}
              data={testData}
              count={patientLabData.result.count}
              onViewClick={handleViewClick}
            />
          ))
        )}
      </section>
      <div>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -40%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              p: 2,
            }}
            className="space-y-4"
          >
            <h2>Filters</h2>
            <div className="flex flex-col space-y-3">
              <DatePicker
                maxDate={dayjs().toDate()}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                className="px-3 py-2 border border-gray-300 rounded-xl"
              />
              <DatePicker
                maxDate={dayjs().toDate()}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="End Date"
                className="px-3 py-2 border border-gray-300 rounded-xl"
              />
            </div>
            <div>
              <FormControl sx={{ width: "80%" }}>
                <InputLabel>Hospital</InputLabel>
                <Select
                  value={selectedHospital}
                  onChange={(e) => setSelectedHospital(e.target.value)}
                >
                  <MenuItem value="">All Hospitals</MenuItem>
                  <MenuItem value="hospital1">Hospital 1</MenuItem>
                  <MenuItem value="hospital2">Hospital 2</MenuItem>
                  {/* Add more hospitals as needed */}
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                label="Test Name"
                type="text"
                value={testNameFilter}
                sx={{ width: "80%" }}
                onChange={(e) => setTestNameFilter(e.target.value)}
                placeholder="Search test name"
              />
            </div>
            <Button onClick={handleSearch}>Search</Button>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default PatientTestReport;
