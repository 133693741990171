import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { getAnalytics, addAnalytic, updateAnalytic, deleteAnalytic, getAnalytic, getAnalyticsByID, getDoctorCollections, getServiceReport, getLabTestReport } from "../../../services/api";

export function useGetAnalytic(analyticID) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.analytics, analyticID],
        queryFn: () => getAnalytic(analyticID),
        enabled: analyticID ? true : false,
    });

    return { analyticData: data, isLoading, isSuccess, refetch };
}

export function useGetAnalytics(analyticsParams) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError, error } = useQuery({
        queryKey: [queryKeys.analytics, { startDate: analyticsParams.startDate }, { endDate: analyticsParams.endDate }],
        queryFn: () => getAnalytics({
            startDate: analyticsParams.startDate,
            endDate: analyticsParams.endDate
        }),
        enabled: analyticsParams.startDate !== null && analyticsParams.endDate !== null,
        onError: (error) => {
            showNotification('error', error)
        }
    });

    return { analyticsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError };
}

export function useGetAnalyticsByID(analyticsParams) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError, error } = useQuery({
        queryKey: [queryKeys.analytic, { startDate: analyticsParams.startDate }, { endDate: analyticsParams.endDate }, { userID: analyticsParams.userID }],
        queryFn: () => getAnalyticsByID({
            startDate: analyticsParams.startDate,
            endDate: analyticsParams.endDate,
            userID: analyticsParams.userID,
        }),
        enabled: analyticsParams.startDate !== null && analyticsParams.endDate !== null && analyticsParams.userID !== null,
        onError: (error) => {
            showNotification('error', error)
        }
    });

    return { analyticsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError };
}

export function useGetLabTestReport(params = { startDate: "", endDate: "", testID: "" }) {
    const { startDate, endDate, testID } = params;

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError, error } = useQuery({
        queryKey: [queryKeys.labReports, { startDate, endDate, testID }],
        queryFn: () => getLabTestReport({
            startDate,
            endDate,
            testID,
        }),
        enabled: startDate !== null && endDate !== null && testID !== null,
        onError: (error) => {
            showNotification('error', error);
        }
    });

    return { labTestReportData: data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError };
}
export function useGetServiceReports(params = { startDate: "", endDate: "", serviceID: "" }) {
    const { startDate, endDate, serviceID } = params;

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError, error } = useQuery({
        queryKey: [queryKeys.serviceReport, { startDate }, { endDate }, { serviceID }],
        queryFn: () => getServiceReport({
            startDate,
            endDate,
            serviceID,
        }),
        enabled: startDate !== null && endDate !== null && serviceID !== null,
        onError: (error) => {
            showNotification('error', error);
        }
    });

    return { serviceReportData: data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError };
}

export function useGetDoctorCollection(params) {

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError, error } = useQuery({
        queryKey: [queryKeys.doctorCollections, { startDate: params.startDate }, { endDate: params.endDate }, { doctorID: params.doctorID }],
        queryFn: () => getDoctorCollections({
            startDate: params.startDate,
            endDate: params.endDate,
            doctorID: params.doctorID,
        }),
        enabled: params.startDate !== null && params.endDate !== null && params.doctorID !== null,
        onError: (error) => {
            showNotification('error', error)
        }
    });

    return { collectionData: data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError };
}

export function useAddAnalytic() {
    const queryClient = useQueryClient();

    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: ({ analyticID, body }) => addAnalytic({ analyticID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.analytics] });
            if (data?.success) {
                showNotification("success", `Analytic added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addAnalytic: mutate, isLoading };
}

export function useUpdateAnalytic() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ analyticID, body }) => updateAnalytic({ analyticID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.analytics] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { updateAnalytic: mutate, isLoading };
}

export function useDeleteAnalytic() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (analyticID) => deleteAnalytic(analyticID),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.analytics] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteAnalytic: mutate, isLoading };
}
