// permissionsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const permissionsSlice = createSlice({
    name: "permissions",
    initialState,
    reducers: {
        setPermissions: (state, action) => {
            return action.payload;
        },
        updatePermission: (state, action) => {
            const { module, ...permissions } = action.payload;
            const index = state.findIndex((p) => p.module === module);
            if (index !== -1) {
                state[index] = { ...state[index], ...permissions };
            }
        },
    },
});

export const { setPermissions, updatePermission } = permissionsSlice.actions;
export default permissionsSlice.reducer;
