import Lottie from "lottie-react";
import React from "react";
import NotFound from "../assets/notFound.json";

const PageNotFound = () => {
    return (
        <div className="w-full h-screen flex justify-center">
            <Lottie animationData={NotFound} loop={true}   style={{ height: '880px', width: '900px' }}/>
        </div>
    );
};

export default PageNotFound;
