import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { FaRegEye } from "react-icons/fa";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { useGetMetaData } from "../../../query/hooks/metadata/metadataHook";
import { useGetIPDDeposit } from "../../../query/hooks/ipd/deposit";
import {
    useAddIPDRefund,
    useDeleteIPDRefund,
    useGetIPDRefund,
    useUpdateIPDRefund,
} from "../../../query/hooks/ipd/refund";
import CommonDeleteModal from "../CommonDeleteModal";
import ReusableButton from "../ReusableButton";
import RedButton from "../RedButton";
import { Backdrop, Box, Fade, Modal, TextField } from "@mui/material";
import { showNotification } from "../../../services/helper";
import { useGetIPD } from "../../../query/hooks/ipd/ipds";
import dayjs from "dayjs";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

// deposit_amount
// ajusted_amount
// refunded_amount

const columns = [
    {
        id: 2,
        label: "Deposit",
        align: "center",
        width: 190,
    },
    {
        id: 4,
        label: "Refunded Amount",
        align: "center",
        minWidth: 120,
    },
    {
        id: 3,
        label: "Adjusted Amount",
        align: "center",
        minWidth: 120,
    },

    {
        id: 5,
        label: "Date",
        align: "center",
        width: 190,
    },
    {
        id: 6,
        label: "Action",
        align: "center",
        minWidth: 120,
    },
];
const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    // height:  ? "60%" : "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};
const Refunds = () => {
    const params = useParams();
    const { ipdData, isLoading: isPtLoading } = useGetIPD(params.id);
    const { ipdDepositData } = useGetIPDDeposit(params.id);
    const { ipdRefundData, isLoading, isSuccess, refetch } = useGetIPDRefund(
        params.id
    );
    const { addIPDRefund, isLoading: isAdding } = useAddIPDRefund();
    const { isLoading: isEditing, updateIPDRefund } = useUpdateIPDRefund();
    const { deleteIPDRefund, isLoading: isDeleting } = useDeleteIPDRefund();
    const [open, setOpen] = useState(false);
    const [refund, setRefund] = useState({
        deposit_amount: 0,
        adjusted_amount: 0,
        refunded_amount: 0,
    });

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [addView, setAddView] = useState(false);
    const [editView, setEditView] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [editForm, setEditForm] = useState("");

    const handleOpen = () => {
        setAddView(true);
        setEditView(false);
        setOpen(true);
        setRefund((prevState) => ({
            ...prevState,
            deposit_amount: totalAmount,
        }));
    };

    const handleClose = () => {
        setAddView(false);
        setEditView(false);
        setOpen(false);
        setRefund({
            deposit_amount: 0,
            adjusted_amount: 0,
            refunded_amount: 0,
        });
        setEditForm("");
    };
    const handleEditClick = (row) => {
        setEditForm(row);
        setEditView(true);
        setAddView(false);
        setOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteConfirmed = () => {
        deleteIPDRefund(toDelete);
        handleCloseDeleteDialog();
    };
    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };
    const totalAmount = ipdDepositData
        ? ipdDepositData.result?.list.reduce(
              (acc, curr) => acc + curr.amount,
              0
          )
        : 0;

    useEffect(() => {
        if (totalAmount) {
            setRefund((prevState) => ({
                ...prevState,
                deposit_amount: totalAmount,
            }));
        }
    }, [totalAmount]);
    useEffect(() => {
        if (refund.adjusted_amount > 0) {
            setRefund((prevState) => ({
                ...prevState,
                refunded_amount: totalAmount - refund.adjusted_amount,
            }));
        }
    }, [refund.adjusted_amount, totalAmount]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // Parse the value to an integer or keep it as 0 if it's not a valid number
        const parsedValue = /^\d+$/.test(value) ? parseInt(value, 10) : 0;
        setRefund((prevState) => ({
            ...prevState,
            [name]: parsedValue,
        }));
    };

    const handleAddRefund = () => {
        if (
            isNaN(refund.deposit_amount) ||
            isNaN(refund.adjusted_amount) ||
            isNaN(refund.refunded_amount) ||
            refund.deposit_amount === 0
        ) {
            // If any of the amounts is null or NaN, show an error message or perform any appropriate action
            showNotification(
                "error",
                "Please enter valid amounts for all fields."
            );
            return;
        }
        const updatedState = {
            deposit_amount: parseInt(refund.deposit_amount),
            adjusted_amount: parseInt(refund.adjusted_amount),
            refunded_amount: parseInt(refund.refunded_amount),
        };

        addIPDRefund({ refundID: ipdData?.result?.id, body: updatedState });

        handleClose();
    };
    const handleChangeEditForm = (e) => {
        const { name, value } = e.target;
        // Parse the value to an integer or keep it as 0 if it's not a valid number
        const parsedValue = /^\d+$/.test(value) ? parseInt(value, 10) : 0;
        setEditForm((prevState) => ({
            ...prevState,
            [name]: parsedValue,
        }));
    };
    const handleEditRefund = () => {
        if (
            isNaN(editForm.deposit_amount) ||
            isNaN(editForm.adjusted_amount) ||
            isNaN(editForm.refunded_amount) ||
            editForm.deposit_amount === 0
        ) {
            // If any of the amounts is null or NaN, show an error message or perform any appropriate action
            showNotification(
                "error",
                "Please enter valid amounts for all fields."
            );
            return;
        }
        const updatedState = {
            deposit_amount: parseInt(editForm.deposit_amount),
            adjusted_amount: parseInt(editForm.adjusted_amount),
            refunded_amount: parseInt(editForm.refunded_amount),
        };

        updateIPDRefund({ refundID: editForm.id, body: updatedState });

        handleClose();
    };

    useEffect(() => {
        if (editForm.adjusted_amount) {
            setEditForm((prevState) => ({
                ...prevState,
                refunded_amount:
                    editForm.deposit_amount - editForm.adjusted_amount,
            }));
        }
    }, [editForm.adjusted_amount, editForm.deposit_amount]);

    return (
        <>
            <div className="mb-4">
                <ReusableButton title="Add Refund" onClick={handleOpen} />
            </div>
            <Paper
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: "15px",
                }}
                elevation={24}
            >
                <TableContainer
                    component={Paper}
                    sx={{
                        height: 520,
                        overflowX: "scroll",
                        "&::-webkit-scrollbar": {
                            width: 0,
                        },
                    }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            paddingX: column.paddingX,
                                        }}
                                        style={{
                                            minWidth: column.minWidth,
                                            width: column.width,
                                            maxWidth: column.maxWidth,
                                            fontWeight: "bold",
                                            background: "#eeeeee",
                                            textTransform: "uppercase",
                                            backgroundColor: "#27272A",
                                            color: "white",
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ipdRefundData?.result?.list?.map((item, index) => (
                                <TableRow
                                    key={item.id}
                                    style={{
                                        backgroundColor:
                                            index % 2 === 0
                                                ? "#E4E4E7"
                                                : "white",
                                    }}
                                >
                                    <TableCell align="center">
                                        Rs. {item.deposit_amount} /-
                                    </TableCell>
                                    <TableCell align="center">
                                        Rs. {item.adjusted_amount} /-
                                    </TableCell>
                                    <TableCell align="center">
                                        Rs. {item.refunded_amount} /-
                                    </TableCell>

                                    <TableCell align="center">
                                        {dayjs(item?.created_on).format(
                                            "DD-MM-YYYY,h:mm:ss A"
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        <div className="flex items-center justify-center space-x-4">
                                            <Tooltip
                                                title="Edit"
                                                placement="bottom-start"
                                            >
                                                <button
                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                    onClick={() =>
                                                        handleEditClick(item)
                                                    }
                                                >
                                                    <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                </button>
                                            </Tooltip>
                                            <Tooltip
                                                title="Delete"
                                                placement="bottom-start"
                                            >
                                                <button
                                                    onClick={() =>
                                                        handleOpenDeleteDialog(
                                                            item.id
                                                        )
                                                    }
                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                >
                                                    <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeleting}
            />
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {addView && (
                            <>
                                <section className="flex flex-col items-center space-y-4">
                                    <TextField
                                        sx={{
                                            width: "70%",
                                        }}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        value={refund.deposit_amount || 0}
                                        label="Deposit Amount"
                                        name="deposit_amount"
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        sx={{
                                            width: "70%",
                                        }}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        value={refund.adjusted_amount || 0}
                                        label="Adjusted Amount"
                                        name="adjusted_amount"
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        sx={{
                                            width: "70%",
                                        }}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={refund.refunded_amount || 0}
                                        label="Refunded Amount"
                                        name="refunded_amount"
                                        // onChange={handleChange}
                                    />
                                </section>
                                <section className="flex py-5 justify-evenly">
                                    <ReusableButton
                                        title="Save"
                                        onClick={handleAddRefund}
                                    />
                                    <RedButton
                                        title="Cancel"
                                        onClick={handleClose}
                                    />
                                </section>
                            </>
                        )}
                        {editView && (
                            <>
                                <section className="flex flex-col items-center space-y-4">
                                    <TextField
                                        sx={{
                                            width: "70%",
                                        }}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        value={editForm.deposit_amount}
                                        label="Deposit Amount"
                                        name="deposit_amount"
                                        onChange={handleChangeEditForm}
                                    />
                                    <TextField
                                        sx={{
                                            width: "70%",
                                        }}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        value={editForm.adjusted_amount}
                                        label="Adjusted Amount"
                                        name="adjusted_amount"
                                        onChange={handleChangeEditForm}
                                    />
                                    <TextField
                                        sx={{
                                            width: "70%",
                                        }}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={editForm.refunded_amount}
                                        label="Refunded Amount"
                                        name="refunded_amount"
                                        // onChange={handleChange}
                                    />
                                </section>
                                <section className="flex py-5 justify-evenly">
                                    <ReusableButton
                                        title="Save"
                                        onClick={handleEditRefund}
                                    />
                                    <RedButton
                                        title="Cancel"
                                        onClick={handleClose}
                                    />
                                </section>
                            </>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default Refunds;
