// motsSlice.js

import { createSlice } from "@reduxjs/toolkit";

const motsInitialState = {
  defaultParams: {
    name: "",
    hid: "",
    offset: 0,
    limit: 10,
    startDate: "",
    endDate: "",
  },
};

const motsSlice = createSlice({
  name: "mots",
  initialState: motsInitialState,
  reducers: {
    setMotsParams: (state, action) => {
      state.defaultParams = { ...state.defaultParams, ...action.payload };
    },
    clearMots: (state, action) => {
      return motsInitialState;
    },
  },
  extraReducers: {},
});

export const selectMotsParams = (state) => state.mots.defaultParams;
export const { setMotsParams, clearMots } = motsSlice.actions;
export default motsSlice.reducer;
