import React from "react";
import {
    Modal,
    Fade,
    Box,
    FormControl,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Button,
    Backdrop,
} from "@mui/material";
import { useGetMetaData } from "../../query/hooks/metadata/metadataHook";
import { useGetEmployees } from "../../query/hooks/employee/employeeHooks";
import { useSelector } from "react-redux";
import { selectEmployeesParam } from "../../redux/features/filters/employeeSlice";

const EditOPDModal = ({
    open,
    handleClose,
    handleInputChange,
    handleOPDSubmit,
    selectedOPD,
    valErr,
}) => {
    const { metaData } = useGetMetaData();
    const employeeParams = useSelector(selectEmployeesParam);
    const { employeesData } = useGetEmployees(employeeParams);
    const filteredDoctors = employeesData?.result?.list?.filter(
        (employee) => employee.designation_name === "Doctor"
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "45%",
                        left: "50%",
                        transform: "translate(-45%, -50%)",
                        minWidth: 600,
                        maxWidth: 800,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: "5px",
                    }}
                >
                    <div>
                        <FormControl fullWidth>
                            <div className="grid grid-cols-2 gap-5 p-2">
                                <TextField
                                    required
                                    label="Patient ID"
                                    name="patient"
                                    value={selectedOPD.patient}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    margin="normal"
                                    disabled
                                />

                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    error={valErr?.emptyFields?.includes(
                                        "doctor"
                                    )}
                                >
                                    <InputLabel id="payment-mode-label">
                                        Doctor
                                    </InputLabel>
                                    <Select
                                        label="Doctor"
                                        id="doctor"
                                        name="doctor"
                                        value={
                                            filteredDoctors?.filter(
                                                (doctor) =>
                                                    doctor.id ===
                                                    selectedOPD.doctor
                                            )[0]
                                        }
                                        onChange={handleInputChange}
                                    >
                                        {filteredDoctors?.map((doctor) => (
                                            <MenuItem
                                                key={doctor.id}
                                                value={doctor}
                                                className="capitalize"
                                            >
                                                {doctor.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>
                                        {valErr?.emptyFields?.includes("doctor")
                                            ? "Doctor is required"
                                            : undefined}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    disabled
                                    error={valErr?.emptyFields?.includes(
                                        "department"
                                    )}
                                >
                                    <InputLabel id="department-label">
                                        Department
                                    </InputLabel>
                                    <Select
                                        disabled
                                        id="department"
                                        label="Department"
                                        name="department"
                                        value={selectedOPD.department || ""}
                                        onChange={handleInputChange}
                                    >
                                        {metaData?.result?.departments?.map(
                                            (department) => (
                                                <MenuItem
                                                    key={department.id}
                                                    value={department.id}
                                                    className="capitalize"
                                                >
                                                    {department.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    <FormHelperText>
                                        {valErr?.emptyFields?.includes(
                                            "department"
                                        )
                                            ? "Department is required"
                                            : undefined}
                                    </FormHelperText>
                                </FormControl>
                                <TextField
                                    required
                                    label="Charge"
                                    name="charge"
                                    value={selectedOPD.charge || 0}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    margin="normal"
                                    helperText={
                                        valErr?.zeroValueFields?.includes(
                                            "charge"
                                        )
                                            ? "Charge is required"
                                            : null
                                    }
                                    error={valErr?.zeroValueFields?.includes(
                                        "charge"
                                    )}
                                />

                                <TextField
                                    required
                                    label="Payable Amount"
                                    name="payable_amount"
                                    disabled
                                    value={selectedOPD.payable_amount || 0}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    margin="normal"
                                    helperText={
                                        valErr?.zeroValueFields?.includes(
                                            "payable_amount"
                                        )
                                            ? "Payable Amount is required"
                                            : null
                                    }
                                    error={valErr?.zeroValueFields?.includes(
                                        "payable_amount"
                                    )}
                                />
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    error={valErr?.emptyFields?.includes(
                                        "payment_mode"
                                    )}
                                >
                                    <InputLabel id="payment-mode-label">
                                        Payment Mode
                                    </InputLabel>
                                    <Select
                                        labelId="payment-mode-label"
                                        label="Payment Mode"
                                        name="payment_mode"
                                        value={selectedOPD.payment_mode || ""}
                                        onChange={handleInputChange}
                                    >
                                        {metaData?.result?.payment_modes.map(
                                            (mode) => (
                                                <MenuItem
                                                    key={mode}
                                                    value={mode}
                                                    className="capitalize"
                                                >
                                                    {mode}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    <FormHelperText>
                                        {valErr?.emptyFields?.includes(
                                            "payment_mode"
                                        )
                                            ? "Payment Mode is required"
                                            : undefined}
                                    </FormHelperText>
                                </FormControl>

                                <TextField
                                    required
                                    label="Payment Reference Number"
                                    name="payment_reference_number"
                                    value={selectedOPD.payment_reference_number}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    margin="normal"
                                />
                                <TextField
                                    required
                                    label="Discount Amount"
                                    name="discount_amount"
                                    value={selectedOPD.discount_amount || ""}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    margin="normal"
                                />
                                <TextField
                                    required
                                    label="Discount Percentage"
                                    name="discount_percent"
                                    value={`${
                                        selectedOPD.discount_percent || 0
                                    }%`}
                                    disabled
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    margin="normal"
                                />
                                <TextField
                                    required
                                    label="Discount Remark"
                                    name="discount_remark"
                                    value={selectedOPD.discount_remark}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    margin="normal"
                                />
                            </div>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleOPDSubmit}
                            >
                                Submit
                            </Button>
                        </FormControl>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
};

export default EditOPDModal;
