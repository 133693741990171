import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    Typography,
    TextField,
    Autocomplete,
    Pagination,
} from "@mui/material";
import {
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineReload,
} from "react-icons/ai";
import { RiAiGenerate } from "react-icons/ri";
import IPDFinalBill from "./common/IPDFinalBill";
import { useGetBills } from "../query/hooks/ipd/bill";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import {
    getEndingTimeOfDate,
    getPermissionsByModule,
    getStartingTimeOfDate,
} from "../services/helper";
import ClearIcon from "@mui/icons-material/Clear";
import {
    clearIPDBills,
    selectIPDBillsParam,
    setIPDBillsParams,
} from "../redux/features/filters/ipdBillsSlice";
import dayjs from "dayjs";
import { useGetMetaData } from "../query/hooks/metadata/metadataHook";
import ReusableButton from "./common/ReusableButton";
import ClearButton from "./common/ClearButton";

const headerArr = [
    { id: "ipd", label: "IPD", width: 100 },
    { id: "hid", label: "HID", width: 150 },
    { id: "patient_name", label: "Deposit ", width: 120 },

    { id: "payable_amount", label: "Payable ", width: 120 },

    { id: "payment_mode", label: "Payment Mode", width: 120 },
    { id: "created_by_name", label: "Created By", width: 120 },
    { id: "action", label: "Actions", width: 120 },
];
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const IPDBills = () => {
    const dispatch = useDispatch();
    const { metaData } = useGetMetaData();
    const billsParams = useSelector(selectIPDBillsParam);
    const currentAuth = useSelector(selectCurrentAuth);
    const permissionsList = currentAuth?.user?.permissions;
    const billsPermissions = getPermissionsByModule(1016, permissionsList);
    const {
        add_permission,
        update_permission,
        delete_permission,
        view_permission,
    } = billsPermissions;
    const { billData, isLoading, refetch, isFetching } =
        useGetBills(billsParams);
    const [isBillModalOpen, setIsBillModalOpen] = useState(false);
    const [selectedIPD, setSelectedIPD] = useState("");
    const [searchParams, setSearchParams] = useState({
        name: "",
        hid: "",
        ipd: "",
    });
 

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            [name]: value,
        }));
    };
    const [startDate, setStartDate] = useState(null); // Initialize with null
    const [endDate, setEndDate] = useState(null); // Initialize with null

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleClearSearch = () => {
        setSearchParams({
            name: "",
            hid: "",
            ipd: "",
        });
        dispatch(clearIPDBills());
        setStartDate("");
        setEndDate("");
    };
    const [currentPage, setCurrentPage] = useState(1);
    const handlePaginationChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setIPDBillsParams({
                ...billsParams,
                offset: (value - 1) * billsParams.limit,
            })
        );
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     const isStartDateValid =
    //         startDate instanceof Date && !isNaN(startDate.getTime());

    //     const isEndDateValid =
    //         endDate instanceof Date && !isNaN(endDate.getTime());

    //     dispatch(
    //         setIPDBillsParams({
    //             ...billsParams,
    //             name: searchParams.name || "",
    //             ipd: searchParams.ipd || "",
    //             hid: searchParams.hid,
    //             startDate: isStartDateValid
    //                 ? getStartingTimeOfDate(dayjs(startDate))
    //                 : "",
    //             endDate: isEndDateValid
    //                 ? getEndingTimeOfDate(dayjs(endDate))
    //                 : "",
    //         })
    //     );
    // };

    const handleSubmit = (e) => {
        e.preventDefault();

        const isStartDateValid =
            startDate instanceof Date && !isNaN(startDate.getTime());
        const isEndDateValid =
            endDate instanceof Date && !isNaN(endDate.getTime());

        dispatch(
            setIPDBillsParams({
                ...billsParams,
                ipd: searchParams.ipd || "",
                name: searchParams.name || "",
                hid: searchParams.hid,
                startDate: isStartDateValid
                    ? getStartingTimeOfDate(dayjs(startDate))
                    : "",
                endDate: isEndDateValid
                    ? getEndingTimeOfDate(dayjs(endDate))
                    : "",
            })
        );
    };

    const handleClearHID = () => {
        setSearchParams({ ...searchParams, hid: "" });
        setIPDBillsParams({
            ...billsParams,
            hid: "",
        });
    };
    const handleClearName = () => {
        setSearchParams({ ...searchParams, name: "" });
        setIPDBillsParams({
            ...billsParams,
            name: "",
        });
    };

    const handleClearIpd = () => {
        setSearchParams({ ...searchParams, ipd: "" });
        setIPDBillsParams({
            ...billsParams,
            ipd: "",
        });
    };

    const closeBillModal = () => {
        setIsBillModalOpen(false);
        setSelectedIPD("");
    };
    const handleGenerateBill = (row) => {
        setSelectedIPD(row);
        setIsBillModalOpen(true);
    };

    return (
        <>
            <IPDFinalBill
                billData={selectedIPD}
                closeBillModal={closeBillModal}
                isBillModalOpen={isBillModalOpen}
            />
            <div className="flex justify-between px-10 mb-5">
                <div>
                    <div className="flex items-center space-x-3">
                        <Typography variant="h5">IPD Bills</Typography>
                        {add_permission === 1 && (
                            <Tooltip title="Refresh" placement="bottom-start">
                                <button
                                    onClick={refetch}
                                    className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                    style={{ cursor: "pointer" }}
                                >
                                    <AiOutlineReload size={20} />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
            <section className="my-4">
                <form onSubmit={handleSubmit}>
                    <div
                        className="flex items-center justify-around"
                        style={{ position: "relative" }}
                        // onKeyDown={handleKeyPress}
                        tabIndex={0}
                    >
                        <TextField
                            label="Search By HID"
                            variant="outlined"
                            name="hid"
                            value={searchParams.hid}
                            onChange={handleSearch}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {searchParams.hid && (
                                            <ClearIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={handleClearHID}
                                            />
                                        )}
                                    </>
                                ),
                            }}
                        />
                        <TextField
                            label="Search By Name"
                            variant="outlined"
                            name="name"
                            value={searchParams.name}
                            onChange={handleSearch}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {searchParams.name && (
                                            <ClearIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={handleClearName}
                                            />
                                        )}
                                    </>
                                ),
                            }}
                        />
                        <TextField
                            label="Search By IPD"
                            variant="outlined"
                            name="ipd"
                            value={searchParams.ipd}
                            onChange={handleSearch}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                );
                            }}
                            InputProps={{
                                inputProps: {
                                    // maxLength: 10,
                                    pattern: "[0-9]*",
                                },
                                endAdornment: (
                                    <>
                                        {searchParams.ipd && (
                                            <ClearIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={handleClearIpd}
                                            />
                                        )}
                                    </>
                                ),
                            }}
                        />

                        <div style={{ zIndex: "999" }}>
                            <DatePicker
                                maxDate={dayjs().toDate()}
                                className="px-6 py-4 w-[220px] border border-gray-300 rounded-xl"
                                dateFormat="yyyy/MM/dd"
                                placeholderText="StartDate - EndDate"
                                selectsRange={true}
                                isClearable
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        </div>
                        <ReusableButton
                            title="Search"
                            type="submit"
                            onClick={handleSubmit}
                        />
                        <ClearButton
                            title="Clear"
                            onClick={handleClearSearch}
                        />
                    </div>
                </form>
            </section>

            <section>
                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "15px",
                    }}
                    elevation={24}
                >
                    <TableContainer
                        sx={{
                            height: 520,
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {headerArr.map((column, index) => (
                                        <TableCell
                                            key={index}
                                            align="center"
                                            style={{
                                                width: column.width,
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                                color: "white",
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {billData?.result?.list?.map((item, index) => (
                                    <TableRow key={item.id}>
                                        <TableCell align="center">
                                            {item.ipd}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.hid}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.patient_title}
                                            {". "}
                                            {item.patient_name}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.payable_amount}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.payment_mode}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.created_by_name}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                width: 120,
                                            }}
                                        >
                                            <div className="flex items-center justify-center space-x-4">
                                                {view_permission === 1 && (
                                                    <Tooltip
                                                        title="Generate Bill"
                                                        placement="bottom-start"
                                                    >
                                                        <button
                                                            onClick={() =>
                                                                handleGenerateBill(
                                                                    item
                                                                )
                                                            }
                                                            className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                        >
                                                            <RiAiGenerate className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                        </button>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="flex items-center justify-center my-4">
                        <Pagination
                            count={Math.ceil(
                                billData?.result?.count / billsParams.limit
                            )}
                            page={currentPage}
                            sx={{
                                "& .MuiSelect-select": {
                                    display: "none !important",
                                },
                                "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                    {
                                        display: "none !important",
                                    },
                            }}
                            onChange={handlePaginationChange}
                        />
                    </div>
                </Paper>
            </section>
        </>
    );
};

export default IPDBills;
