import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { showNotification } from "../../../services/helper";
import { queryKeys } from "../../keys";
import { getPatients, addPatient, updatePatient, deletePatient, addPatientEntity } from "../../../services/api"; // Import your API functions

// Custom hook for fetching patients
export function useGetPatients(patientParams) {

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.patients, { offset: patientParams.offset, limit: patientParams.limit, hid: patientParams.hid, name: patientParams.name, mobile: patientParams.mobile, aadhaar: patientParams.aadhaar }],
        queryFn: () => getPatients({ offset: patientParams.offset, limit: patientParams.limit, hid: patientParams.hid, name: patientParams.name, mobile: patientParams.mobile, aadhaar: patientParams.aadhaar }),
    });

    return { patientsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

export function useAddPatientEntity(setStep) {
    // Replace with how you access your application state
    const queryClient = useQueryClient();

    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: (patientData) => addPatientEntity(patientData), // Assuming you have a in your Redux state
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.patients] });
            if (data?.success) {
                setStep(2);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addPatientEntity: mutate, isLoading, isSuccess };
}
export function useAddPatient(setPtID, handleClose, setStep) {
    // Replace with how you access your application state
    const queryClient = useQueryClient();

    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: (patientData) => addPatient(patientData), // Assuming you have a in your Redux state

        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.patients] });
            if (data?.success) {
                setPtID(data?.result?.id);
                setStep(3)
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addPatient: mutate, isLoading, isSuccess };
}

// Custom hook for editing a patient
export function useEditPatient() {

    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updatePatient(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.patients] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);

            }
        },
    });
    return { mutate, isLoading };
}

// Custom hook for deleting a patient
export function useDeletePatient() {

    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (patientId) => deletePatient(patientId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.patients] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deletePatient: mutate, isLoading };
}