import { useQuery, useMutation, useQueryClient, isError } from "@tanstack/react-query";

import { showNotification } from "../../../services/helper";
import { queryKeys } from "../../keys";
import { getDesignations, addDesignation, updateDesignation, deleteDesignation } from "../../../services/api"; // Import your API functions

// Custom hook for fetching designations
export function useGetDesignations() {

    const { data, isLoading, isSuccess, isRefetching, refetch, isFetching } = useQuery({
        queryKey: [queryKeys.designations],
        queryFn: () => getDesignations({ offset: 0, limit: 200 }),
    });

    return { designationsData: data, isLoading, isSuccess, isRefetching, refetch, isFetching };
}

// Custom hook for adding a designation
export function useAddDesignation() {
    // Replace with how you access your application state
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (designationData) => addDesignation(designationData), // Assuming you have a  in your Redux state

        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.designations] });
            if (data?.success) {
                showNotification("success", "Designation added");
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addDesignation: mutate, isLoading };
}

// Custom hook for editing a designation
export function useEditDesignation() {

    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updateDesignation(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.designations] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);

            }
        },
    });
    return { mutate, isLoading };
}

// Custom hook for deleting a designation
export function useDeleteDesignation() {

    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (designationId) => deleteDesignation(designationId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.designations] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteDesignation: mutate, isLoading };
}


