import React, { useEffect, useState } from "react";
import TabView from "./TabView";
import BedHistory from "./TabViews/BedHistory";
import LabInvestigation from "./TabViews/LabInvestigation";
import Operations from "./TabViews/Operations";
import PtProfile from "./TabViews/PtProfile";
import Deposits from "./TabViews/Deposits";
import Refunds from "./TabViews/Refunds";
import FinalBill from "./TabViews/FinalBill";

import ServicesTab from "./TabViews/ServicesTab";
import { useNavigate, useParams } from "react-router-dom";
import { useGetIPD } from "../../query/hooks/ipd/ipds";
import { useSelector } from "react-redux";
import { selectCurrentAuth } from "../../redux/features/auth/authSlice";
import { getPermissionsByModule } from "../../services/helper";

const IPDInfo = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { ipdData, isLoading, isFetching, refetch } = useGetIPD(params.id);
    const currentAuth = useSelector(selectCurrentAuth);
    const permissionsList = currentAuth?.user?.permissions;
    const ipdsPermissions = getPermissionsByModule(1016, permissionsList);
    const {
        add_permission,
        update_permission,
        delete_permission,
        view_permission,
    } = ipdsPermissions;
    // useEffect(() => {
    //     if (!isLoading) {
    //         if (!ipdData?.success && !ipdData?.result) {
    //             navigate("/admin/ipds");
    //         }
    //     }
    // }, [params]);

    const pages = [
        {
            tabName: "Profile",
            content: <PtProfile ipdData={ipdData} />,
        },
        { tabName: "Bed History", content: <BedHistory /> },
        { tabName: "Lab Investigation", content: <LabInvestigation /> },
        { tabName: "Services", content: <ServicesTab /> },
        { tabName: "OT", content: <Operations /> },
        { tabName: "Deposits", content: <Deposits /> },
        { tabName: "Refunds", content: <Refunds /> },
        { tabName: "Final Bill", content: <FinalBill /> },
    ];
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <div>
                <div className="container mx-auto">
                    {view_permission === 1 && <TabView pages={pages} />}
                </div>
            </div>
        </>
    );
};

export default IPDInfo;
