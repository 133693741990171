import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { AiOutlineEdit, AiOutlineReload } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import Typography from "@mui/material/Typography";
import { Autocomplete, Pagination, TextField, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import {
  useAddDesignation,
  useDeleteDesignation,
  useEditDesignation,
  useGetDesignations,
} from "../query/hooks/designation/designationHook";
import { chunkArray, showNotification } from "../services/helper";
import LoaderLogo from "./common/LoaderLogo";

import CustomModal from "./common/CommonModal";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaBriefcase } from "react-icons/fa";
import {
  selectDesignationParam,
  setDesignationParams,
} from "../redux/features/filters/designationSlice";
import ReusableButton from "./common/ReusableButton";
import RedButton from "./common/RedButton";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const columns = [
  { id: "id", label: "ID", width: 10, align: "center" },
  { id: "designation", label: "designation", minWidth: 400, align: "center" },
  { id: "actions", label: "Actions", minWidth: 200, align: "center" },
];

const Designations = () => {
  const dispatch = useDispatch();
  const designationParams = useSelector(selectDesignationParam);
  const [dataToRender, setDataToRender] = useState([]);

  const currentAuth = useSelector(selectCurrentAuth);
  const designationPermission = currentAuth?.user?.permissions.find(
    (permission) => permission.module === 1004
  );
  const { add_permission, update_permission, delete_permission } =
    designationPermission;

  const {
    designationsData,
    isSuccess,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  } = useGetDesignations();
  useEffect(() => {
    if (designationsData) {
      const arr = chunkArray(designationsData?.result?.list, 10);
      setDataToRender(arr[designationParams.offset / designationParams.limit]);
    }
  }, [designationsData, designationParams.offset]);
  useEffect(() => {
    setCurrentPage(designationParams.offset / designationParams.limit + 1);
  }, [designationParams]);
  const { addDesignation, isLoading: isAdding } = useAddDesignation();

  const { mutate: editDesignation, isLoading: isEditing } =
    useEditDesignation();

  const { deleteDesignation, isLoading: isDeleting } = useDeleteDesignation();

  const [open, setOpen] = useState(false);
  const [designation, setDesignation] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [editedDesignation, setEditedDesignation] = useState("");
  const [toDelete, setToDelete] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAdd = () => {
    if (!designation || designation.trim() === "") {
      showNotification("error", "Designation name cannot be empty.");
      return;
    }
    addDesignation({ name: designation });
    setDesignation("");
    handleClose();
  };

  const handleCancel = () => {
    setDesignation("");
    handleClose();
  };

  const handleEditClick = (row) => {
    setEditedDesignation(row);
    setEditOpen(true);
  };

  const handleEditSave = (row) => {
    const updatedData = {
      id: editedDesignation.id,
      name: editedDesignation.name,
    };

    editDesignation(updatedData);
    setEditOpen(false);
  };

  const handleOpenDeleteDialog = (row) => {
    setOpenDeleteDialog(true);
    setToDelete(row);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmed = () => {
    deleteDesignation(toDelete);
    handleCloseDeleteDialog();
  };
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    dispatch(
      setDesignationParams({
        ...designationParams,
        offset: (value - 1) * designationParams.limit,
      })
    );
  };
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
    setDataToRender([newValue]);
  };
  const handleClear = () => {
    setSelectedOption(null);
    setInputValue("");
    if (designationsData) {
      const arr = chunkArray(designationsData?.result?.list, 10);
      setDataToRender(arr[designationParams.offset / designationParams.limit]);
    }
  };
  return (
    <section>
      <div className="">
        <div className="flex items-center justify-between px-10 m-4">
          <div className="flex items-center space-x-3">
            <Typography variant="h5">Designations</Typography>

            {add_permission === 1 && (
              <Tooltip title="Refresh" placement="bottom-start">
                <button
                  onClick={refetch}
                  className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                  style={{ cursor: "pointer" }}
                >
                  <AiOutlineReload size={20} />
                </button>
              </Tooltip>
            )}
          </div>
          <div className="flex justify-between w-[50%]">
            <div className="">
              <Autocomplete
                className="w-[350px]"
                options={designationsData?.result?.list || []}
                getOptionLabel={(option) => option.name}
                value={selectedOption}
                onChange={handleOptionChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Designation"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {selectedOption && (
                            <ClearIcon
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={handleClear}
                            />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </div>

            {add_permission === 1 && (
              <Tooltip title="Add Designation" placement="bottom-start">
                <button
                  onClick={handleOpen}
                  className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                >
                  <FaBriefcase size={18} />
                </button>
              </Tooltip>
            )}
          </div>
        </div>

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "15px",
          }}
          elevation={24}
        >
          <TableContainer
            sx={{
              height: 520,
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                width: 0,
              },
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#525252",
                  }}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bold",
                        background: "#eeeeee",
                        textTransform: "uppercase",
                        backgroundColor: "#27272A",
                        color: "white",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {!isLoading && !isFetching ? (
                <TableBody>
                  {dataToRender?.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        key={row.id}
                        align="center"
                      >
                        <TableCell align="center">{row.id}</TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: 120,
                          }}
                        >
                          <div className="flex items-center justify-center space-x-4">
                            {update_permission === 1 && (
                              <Tooltip title="Edit" placement="bottom-start">
                                <button
                                  onClick={() => handleEditClick(row)}
                                  className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                >
                                  <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                </button>
                              </Tooltip>
                            )}
                            {delete_permission === 1 && (
                              <Tooltip title="Delete" placement="bottom-start">
                                <button
                                  onClick={() => handleOpenDeleteDialog(row.id)}
                                  className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                >
                                  <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                </button>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      align="center"
                      sx={{
                        width: 120,
                      }}
                    >
                      <div className="flex items-center justify-center">
                        <LoaderLogo />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {!isLoading && !isFetching && (
            <div className="flex items-center justify-center my-4">
              <Pagination
                count={Math.ceil(
                  designationsData?.result?.count / designationParams.limit
                )}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  "& .MuiSelect-select": {
                    display: "none !important",
                  },
                  "& > div.MuiToolbar-root > div.MuiInputBase-root > svg": {
                    display: "none !important",
                  },
                }}
              />
            </div>
          )}
        </Paper>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                marginBottom: 2,
              }}
            >
              Add New Designation
            </Typography>

            <TextField
              required
              disabled={isAdding}
              autoFocus
              margin="dense"
              id="designation"
              label="Designation Name"
              type="text"
              fullWidth
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
            />
            <div className="flex justify-between p-6">
              <ReusableButton
                isDisabled={isAdding}
                onClick={handleAdd}
                title="Save"
              />

              <RedButton
                isDisabled={isAdding}
                onClick={handleCancel}
                title="Cancel"
              />
            </div>
          </Box>
        </Fade>
      </Modal>

      <CustomModal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        containerStyle={{ width: 500, borderRadius: 2 }}
      >
        <Typography
          id="transition-modal-title"
          variant="h6"
          component="h2"
          sx={{
            marginBottom: 2,
          }}
          className="text-center"
        >
          Edit Designation
        </Typography>

        <TextField
          required
          disabled={isEditing}
          autoFocus
          margin="dense"
          id="editedRole"
          label="Edited Designation"
          type="text"
          fullWidth
          value={editedDesignation.name} // Set the initial value to the role name
          onChange={(e) =>
            setEditedDesignation({
              ...editedDesignation,
              name: e.target.value,
            })
          }
        />

        <div className="flex justify-between p-6">
          <ReusableButton
            isDisabled={isEditing}
            onClick={handleEditSave}
            title="Save"
          />

          <RedButton
            isDisabled={isEditing}
            onClick={() => setEditOpen(false)}
            title="Cancel"
          />
        </div>
      </CustomModal>

      <CommonDeleteModal
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onCancel={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmed}
        isDeleting={isDeleting}
      />
    </section>
  );
};

export default Designations;
