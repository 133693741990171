import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { FaRegEye } from "react-icons/fa";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { useGetServices } from "../../../query/hooks/service/servicesHook";
import DeleteIcon from "@mui/icons-material/Delete";

import {
    Autocomplete,
    Backdrop,
    Box,
    Fade,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import ReusableButton from "../ReusableButton";
import RedButton from "../RedButton";

import { useGetIPD } from "../../../query/hooks/ipd/ipds";
import { useParams } from "react-router-dom";
import {
    useAddIPDService,
    useDeleteIPDService,
    useEditIPDService,
    useGetIPDService,
} from "../../../query/hooks/ipd/ipdServices";
import { showNotification } from "../../../services/helper";
import dayjs from "dayjs";
import CommonDeleteModal from "../CommonDeleteModal";
import LoaderLogo from "../LoaderLogo";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const columns = [
    {
        id: "id",
        label: "Sl No",
        align: "center",
        paddingX: 2,
        width: 80,
    },
    {
        id: "name",
        label: "Name",
        align: "center",
        paddingX: 2,
        minWidth: 120,
    },
    {
        id: "charge",
        label: "Charge",
        align: "center",
        paddingX: 2,
        minWidth: 120,
    },
    {
        id: "quantity",
        label: "Quantity",
        align: "center",
        paddingX: 2,
        minWidth: 120,
    },
    {
        id: "totalAmount",
        label: "Total Amount",
        align: "center",
        paddingX: 2,
        minWidth: 120,
    },
    {
        id: "Date",
        label: "Date",
        align: "center",
        paddingX: 2,
        minWidth: 120,
    },
    {
        id: "CreatedBy",
        label: "Created By",
        align: "center",
        paddingX: 2,
        minWidth: 120,
    },
    {
        id: "actions",
        label: "Action",
        align: "center",
        paddingX: 2,
        minWidth: 120,
    },
];

const ServicesTab = () => {
    // service, service_charge, quantity
    const [open, setOpen] = useState(false);
    const limit = 1000;
    const [offset, setOffset] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [addView, setAddView] = useState(false);
    const [editView, setEditView] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [editForm, setEditForm] = useState("");
    const [toDelete, setToDelete] = useState("");

    const params = useParams();
    const { ipdData, isLoading: isPtLoading } = useGetIPD(params.id);
    const { servicesData } = useGetServices();
    const { addIPDService, isLoading: isAdding } = useAddIPDService();
    const { editIPDService, isLoading: isEditing } = useEditIPDService();
    const { deleteIPDService, isLoading: isDeleting } = useDeleteIPDService();
    const {
        ipdServiceData,
        isLoading: isServiceLoading,
        isSuccess,
    } = useGetIPDService(ipdData?.result?.id);

    const handleOpen = () => {
        setOpen(true);
        setAddView(true);
    };

    const handleClose = () => {
        setOpen(false);
        setAddView(false);
        setEditView(false);
        setSelectedServices([]);
    };

    const [selectedServices, setSelectedServices] = useState([]);

    const handleServiceSelect = (event, value) => {
        if (value) {
            const updatedServices = value.map((service) => ({
                ...service,
                quantity: 1, // Default quantity value
            }));
            setSelectedServices(updatedServices);
        } else {
            setSelectedServices([]);
        }
    };

    const handleRemoveService = (serviceId) => {
        setSelectedServices(
            selectedServices.filter((service) => service.id !== serviceId)
        );
    };

    const handleQuantityChange = (event, serviceId) => {
        const updatedServices = selectedServices.map((service) => {
            if (service.id === serviceId) {
                return {
                    ...service,
                    quantity: parseInt(event.target.value) || 1, // Ensure quantity is a valid number
                };
            }
            return service;
        });
        setSelectedServices(updatedServices);
    };
    const handleAddServices = () => {
        if (selectedServices.length === 0) {
            showNotification("error", "No services selected.");
            return;
        }
        const services = selectedServices.map((item) => ({
            service: parseInt(item.id),
            quantity: parseInt(item.quantity),
            service_charge: parseInt(item.charge),
        }));

        addIPDService({
            ipdId: ipdData.result.id,
            ipdServiceData: { services },
        });
        if (isSuccess) {
            handleClose();
        }
    };

    const handleEditClick = (row) => {
        setEditForm(row);
        setOpen(true);
        setEditView(true);
    };

    const handleEditQuantity = (event) => {
        const newQuantity = event.target.value;
        setEditForm((prevState) => ({
            ...prevState,
            quantity: newQuantity,
        }));
    };
    const style = {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: addView ? "70%" : editView ? "35%" : "80%",
        height: editView ? "60%" : "auto",

        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "5px",
    };
    const handleEditService = () => {
        if (isNaN(editForm.quantity) || editForm.quantity <= 0) {
            showNotification("error", "Quantity is invalid.");
            return;
        }
        const service = {
            service: parseInt(editForm.id),
            quantity: parseInt(editForm.quantity),
            service_charge: parseInt(editForm.service_charge),
        };
        editIPDService({
            serviceID: editForm.id,
            ipdServiceData: service,
        });

        if (!isEditing) {
            handleClose();
        }
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteConfirmed = () => {
        deleteIPDService(toDelete);
        handleCloseDeleteDialog();
    };
    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };
    if (!ipdData)
        return (
            <div className="flex items-center justify-center">
                <LoaderLogo />
            </div>
        );
    return (
        <>
            {ipdData.result.id && (
                <>
                    <div className="mb-4">
                        <ReusableButton
                            title="Add Services"
                            onClick={handleOpen}
                        />
                    </div>
                    <Paper
                        sx={{
                            width: "100%",
                            overflow: "hidden",
                            borderRadius: "15px",
                        }}
                        elevation={24}
                    >
                        <TableContainer
                            component={Paper}
                            sx={{
                                height: 520,
                                overflowX: "scroll",
                                "&::-webkit-scrollbar": {
                                    width: 0,
                                },
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                sx={{
                                                    paddingX: column.paddingX,
                                                }}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    width: column.width,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: "bold",
                                                    background: "#eeeeee",
                                                    textTransform: "uppercase",
                                                    backgroundColor: "#27272A",
                                                    color: "white",
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!isServiceLoading &&
                                        ipdServiceData?.result?.list?.map(
                                            (item, index) => (
                                                <TableRow
                                                    key={index}
                                                    style={{
                                                        backgroundColor:
                                                            index % 2 === 0
                                                                ? "#E4E4E7"
                                                                : "white",
                                                    }}
                                                >
                                                    <TableCell align="center">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {item?.service_name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        ₹ {""}
                                                        {item?.service_charge}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {item?.quantity}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        ₹ {""}{" "}
                                                        {item?.service_charge *
                                                            item?.quantity}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {dayjs(
                                                            item?.created_on
                                                        ).format("DD-MM-YYYY,h:mm:ss A")}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {item?.created_by_name}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <div className="flex items-center justify-center space-x-4">
                                                            <Tooltip
                                                                title="Edit"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                    onClick={() =>
                                                                        handleEditClick(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title="Delete"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleOpenDeleteDialog(
                                                                            item.id
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                                >
                                                                    <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {addView && (
                            <>
                                <section className="flex flex-col items-center justify-center">
                                    <Autocomplete
                                        sx={{
                                            width: "70%",
                                        }}
                                        multiple
                                        options={servicesData?.result.list?.filter(
                                            (service) =>
                                                !selectedServices.some(
                                                    (selected) =>
                                                        selected.id ===
                                                        service.id
                                                )
                                        )}
                                        getOptionLabel={(option) => option.name}
                                        onChange={handleServiceSelect}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select Services"
                                                placeholder="Services"
                                            />
                                        )}
                                    />
                                    {selectedServices.length > 0 && (
                                        <Paper
                                            sx={{
                                                width: "70%",
                                                overflow: "hidden",
                                                borderRadius: "15px",
                                                mt: 3,
                                            }}
                                            elevation={24}
                                        >
                                            <TableContainer
                                                component={Paper}
                                                sx={{
                                                    height: 320,
                                                    overflowX: "scroll",
                                                    "&::-webkit-scrollbar": {
                                                        width: 0,
                                                    },
                                                }}
                                            >
                                                <Table
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow
                                                            style={{
                                                                background:
                                                                    "#eeeeee",
                                                                backgroundColor:
                                                                    "#27272A",
                                                            }}
                                                        >
                                                            <TableCell
                                                                align="center"
                                                                style={{
                                                                    width: "40%",
                                                                    fontWeight:
                                                                        "bold",
                                                                    background:
                                                                        "#eeeeee",
                                                                    textTransform:
                                                                        "uppercase",
                                                                    backgroundColor:
                                                                        "#27272A",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                Name
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                style={{
                                                                    width: "30%",
                                                                    fontWeight:
                                                                        "bold",
                                                                    background:
                                                                        "#eeeeee",
                                                                    textTransform:
                                                                        "uppercase",
                                                                    backgroundColor:
                                                                        "#27272A",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                Charge
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                style={{
                                                                    width: "40%",
                                                                    fontWeight:
                                                                        "bold",
                                                                    background:
                                                                        "#eeeeee",
                                                                    textTransform:
                                                                        "uppercase",
                                                                    backgroundColor:
                                                                        "#27272A",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                Quantity
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {selectedServices.map(
                                                            (service) => (
                                                                <TableRow
                                                                    key={
                                                                        service.id
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {
                                                                            service.name
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        ₹ {""}
                                                                        {
                                                                            service.charge
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Select
                                                                            sx={{
                                                                                width: 120,
                                                                            }}
                                                                            value={
                                                                                service.quantity
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                handleQuantityChange(
                                                                                    event,
                                                                                    service.id
                                                                                )
                                                                            }
                                                                        >
                                                                            {[
                                                                                ...Array(
                                                                                    10
                                                                                ),
                                                                            ].map(
                                                                                (
                                                                                    _,
                                                                                    index
                                                                                ) => (
                                                                                    <MenuItem
                                                                                        key={
                                                                                            index +
                                                                                            1
                                                                                        }
                                                                                        value={
                                                                                            index +
                                                                                            1
                                                                                        }
                                                                                    >
                                                                                        {index +
                                                                                            1}
                                                                                    </MenuItem>
                                                                                )
                                                                            )}
                                                                        </Select>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    )}
                                </section>
                                <section className="flex py-5 justify-evenly">
                                    <ReusableButton
                                        title="Save"
                                        onClick={handleAddServices}
                                    />
                                    <RedButton
                                        title="Cancel"
                                        onClick={handleClose}
                                    />
                                </section>
                            </>
                        )}
                        {editView && (
                            <section className="p-4">
                                <Typography variant="h4" textAlign="center">
                                    Edit Service
                                </Typography>
                                <div className="mt-4">
                                    <Stack
                                        direction="column"
                                        useFlexGap
                                        flexWrap="wrap"
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={editForm.service_name}
                                            label="Service"
                                            name="service"
                                        />
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={dayjs(
                                                editForm.created_on
                                            ).format("DD-MM-YYYY,h:mm:ss A")}
                                            label="Created On"
                                            name="created_on"
                                        />
                                    </Stack>
                                    <Select
                                        className="mt-4"
                                        sx={{
                                            width: 210,
                                        }}
                                        value={editForm.quantity}
                                        onChange={(event) =>
                                            handleEditQuantity(event)
                                        }
                                    >
                                        {[...Array(10)].map((_, index) => (
                                            <MenuItem
                                                key={index + 1}
                                                value={index + 1}
                                            >
                                                {index + 1}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div className="flex py-5 justify-evenly">
                                    <ReusableButton
                                        title="Save"
                                        onClick={handleEditService}
                                    />
                                    <RedButton
                                        title="Cancel"
                                        onClick={handleClose}
                                    />
                                </div>
                            </section>
                        )}
                    </Box>
                </Fade>
            </Modal>
            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeleting}
            />
        </>
    );
};

export default ServicesTab;
