import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { getIPDTest, addIPDTest, updateIPDTest, deleteIPDTest } from "../../../services/api";


// export function useGetIPDServices() {
//     const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } =
//         useQuery({
//             queryKey: [queryKeys.ipdServices],
//             queryFn: () => getIPDServices(),
//         });

//     return {
//         ipdServicesData: data,
//         isLoading,
//         isSuccess,
//         isFetching,
//         refetch,
//         isRefetching,
//     };
// }

export function useGetLabInventory(labInventoryID) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.labInventories],
        queryFn: () => getIPDTest(labInventoryID),
        enabled: labInventoryID ? true : false,
    });

    return {
        labInventoryData: data,
        isLoading,
        isSuccess,
        isFetching,
        refetch,
        isRefetching,
    };
}

export function useAddLabInventory() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ ipdID, body }) => addIPDTest({ ipdID: ipdID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.labInventories] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.labTests] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.labTest] });
            if (data?.success) {
                showNotification("success", `Lab Inventory added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addLabInventory: mutate, isLoading };
}

export function useEditLabInventory() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ testID, ipdTestData }) => updateIPDTest({ testID: testID, body: ipdTestData }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.labInventories] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.labTests] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.labTest] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { editLabInventory: mutate, isLoading };
}

export function useDeleteLabInventory() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (labInventoryId) => deleteIPDTest(labInventoryId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.labInventories] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteLabInventory: mutate, isLoading };
}
