import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../redux/features/auth/authSlice";
import permissionsReducer from "../redux/features/permission/permissionsSlice";
import deptReducer from "../redux/features/filters/deptSlice";
import testReducer from "../redux/features/filters/testSlice";
import employeesReducer from "../redux/features/filters/employeeSlice";
import designationReducer from "../redux/features/filters/designationSlice";
import testReportReducer from "../redux/features/filters/testReportSlice";
import opdsReducer from "../redux/features/filters/opdSlice";
import userReducer from "../redux/features/filters/userSlice";
import dashboardReducer from "../redux/features/filters/dashboardSlice";
import patientFilterReducer from "../redux/features/filters/patientFilterSlice";
import servicesReducer from "../redux/features/filters/servicesFilterSlice";
import analyticsReducer from "../redux/features/analytics/analyticsSlice";
import motServiceReducer from "../redux/features/filters/motFilterSlice";
import otServiceReducer from "../redux/features/filters/otsServiceSlice";
import payoutReducer from "../redux/features/filters/payoutSlice";
import ipdReducer from "../redux/features/filters/ipdSlice";
import motsReducer from "../redux/features/filters/motsSlice";
import expensesReducer from "../redux/features/filters/expensesSlice";
import ipdBillsReducer from "../redux/features/filters/ipdBillsSlice";
import roomReducer from "../redux/features/filters/roomSlice";
import floorReducer from "../redux/features/filters/floorSlice";

import summaryReducer from "../redux/features/filters/summarySlice";
import bedReducer from "../redux/features/filters/bedSlice";
import otServicesReducer from "../redux/features/filters/otServicesSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    permissions: permissionsReducer,
    departments: deptReducer,
    tests: testReducer,
    employees: employeesReducer,
    designations: designationReducer,
    testReport: testReportReducer,
    opd: opdsReducer,
    users: userReducer,
    dashboard: dashboardReducer,
    patientFilter: patientFilterReducer,
    analytics: analyticsReducer,
    servicesFilter: servicesReducer,
    motFilters: motServiceReducer,
    otsServices: otServiceReducer,
    payout: payoutReducer,
    ipd: ipdReducer,
    mots: motsReducer,
    expenses: expensesReducer,
    ipdBills: ipdBillsReducer,
    room: roomReducer,
    floor: floorReducer,
    bed: bedReducer,
    summary: summaryReducer,
    otServices: otServicesReducer,
  },
});

