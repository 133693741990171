import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { addIPDBed, deleteIPDBed, getIPDBeds, updateIPDBed } from "../../../services/api";


export function useGetIPDBeds(ipdID) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.ipdBeds],
        queryFn: () => getIPDBeds(ipdID),
        enabled: ipdID ? true : false,
    });

    return { ipdBedsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

export function useAddIPDBed() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: ({ ipdID, body }) => addIPDBed({ ipdID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdBeds] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.beds] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.floors ] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.rooms] });
            if (data?.success) {
                showNotification("success", `IPD bed added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addIPDBed: mutate, isLoading };
}

export function useUpdateIPDBed() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ bedID, body }) => updateIPDBed({ bedID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdBeds] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.beds] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.floors ] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.rooms] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { updateIPDBed: mutate, isLoading };
}

export function useDeleteIPDBed() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (bedID) => deleteIPDBed(bedID),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdBeds] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.beds] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.floors ] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.rooms] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteIPDBed: mutate, isLoading };
}
