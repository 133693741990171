import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { addRoom, deleteRoom, getRooms, updateRoom } from "../../../services/api";

export function useGetRooms(params = {
    name: "",
    offset: 0,
    limit: 1000,
}) {
    const { name, offset, limit } = params;

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.rooms, { name, offset, limit }],
        queryFn: () => getRooms({ name, offset, limit }),
    });

    return { roomsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

export function useAddRoom() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (roomData) => addRoom(roomData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.rooms] });
            if (data?.success) {
                showNotification("success", `Room added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addRoom: mutate, isLoading };
}

export function useEditRoom() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updateRoom(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.rooms] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { editRoom: mutate, isLoading };
}

export function useDeleteRoom() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (roomId) => deleteRoom(roomId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.rooms] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteRoom: mutate, isLoading };
}
