import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEditIPD, useGetIPD } from "../../../query/hooks/ipd/ipds";
import { useParams } from "react-router-dom";
import { useGetMetaData } from "../../../query/hooks/metadata/metadataHook";
import { useGetEmployees } from "../../../query/hooks/employee/employeeHooks";
import { useSelector } from "react-redux";
import { selectEmployeesParam } from "../../../redux/features/filters/employeeSlice";
import ClearIcon from "@mui/icons-material/Clear";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ReusableButton from "../ReusableButton";
import RedButton from "../RedButton";
import { getStartingTimeOfDate } from "../../../services/helper";
import dayjs from "dayjs";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const PtProfile = () => {
    const { metaData } = useGetMetaData();
    const params = useParams();
    const employeeParams = useSelector(selectEmployeesParam);
    const { employeesData } = useGetEmployees(employeeParams);
    const { ipdData, isLoading, isFetching, refetch } = useGetIPD(params.id);
    const { editIPD, isLoading: isEditing, isSuccess } = useEditIPD();
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [editable, setEditable] = useState(true);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const [editForm, setEditForm] = useState({});
    const [selectedEditDate, setSelectedEditDate] = useState(new Date());
    useEffect(() => {
        if (!isLoading) {
            setEditForm({ ...ipdData.result });
        }
    }, []);

    const filteredDoctors = employeesData?.result?.list?.filter(
        (employee) => employee.designation_name === "Doctor"
    );

    const departments = [metaData?.result?.departments];

    const handleInputChange = (fieldName, value) => {
        setEditForm((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }));
    };
    // const handleEditDate = (date) => {
    //     const updatedForm = {
    //         ...editForm,
    //         created_on: getStartingTimeOfDate(dayjs(date)),
    //     };
    //     setSelectedEditDate(date);
    //     setEditForm(updatedForm);
    // };
    // const handleEditDate = (date) => {
    //     setSelectedEditDate(date[0]); // Assuming you want only the start date
    //     setEditForm((prevState) => ({
    //         ...prevState,
    //         created_on: getStartingTimeOfDate(dayjs(date[0])), // Update only the start date
    //     }));
    // };
    const handleEditDate = (date) => {
        setSelectedEditDate(date);
        setEditForm((prevState) => ({
            ...editForm,
            created_on: getStartingTimeOfDate(dayjs(date)), // Update only the start date
        }));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditForm((prevService) => ({
            ...prevService,
            [name]: value,
        }));
    };

    const [selectedDoctor, setSelectedDoctor] = useState("");

    useEffect(() => {
        if (ipdData?.result) {
            setSelectedDoctor(ipdData?.result.doctor);
        }
    }, [ipdData]);

    const handleDoctorChange = (event, value) => {
        setSelectedDoctor(value.id);
        if (value) {
            setSelectedOption(value.department);
            setEditForm((prevState) => ({
                ...prevState,
                doctor: value.id,
                department: value.department,
            }));
        }
    };
    const handleEditToggle = () => {
        setEditable(false);
        setSelectedEditDate(new Date(editForm.created_on));
    };

    const handleCancel = () => {
        setEditable(true);
    };

    const handleEditSave = () => {
        const updatedIPD = {
            patient: parseInt(editForm.patient),
            department: parseInt(editForm.department),
            doctor: parseInt(editForm.doctor),
            admission_charge: parseFloat(editForm.admission_charge),
            payment_mode: editForm.payment_mode,
            reference: editForm.reference || "",
        };
        editIPD({ ipdID: editForm.id, ipdData: updatedIPD });

        setEditable(true);
    };

    return (
        <div>
            <Paper
                elevation={12}
                sx={{
                    padding: 5,
                }}
            >
                <div className="container mt-4">
                    <p className="font-semibold">PATIENT INFO</p>
                    <br />
                    <Grid container>
                        <Grid item xs={12} sm={4}>
                            <label>HID</label>
                            <div className="form-control margin-top-small">
                                <p className="font-semibold">
                                    {ipdData?.result?.hid}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <label>Name</label>
                            <div className="form-control margin-top-small">
                                <p className="font-semibold capitalize">
                                    {ipdData?.result?.title}{" "}
                                    {ipdData?.result?.patient_name}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <label>Age</label>
                            <div className="form-control margin-top-small">
                                <p className="font-semibold">
                                    {ipdData?.result?.patient_age} Years{" "}
                                    {ipdData?.result?.months || 0} Months{" "}
                                    {ipdData?.result?.days || 0} Days
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <label>Gender</label>
                            <div className="form-control margin-top-small">
                                <p className="font-semibold capitalize">
                                    {ipdData?.result?.patient_gender}
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <br />
                    <hr />
                </div>
                <section className="grid items-center w-full grid-cols-2 gap-4">
                    <div>
                        {filteredDoctors?.length > 0 && (
                            <div className="space-y-2">
                                <label className="my-2">
                                    Consultant Doctor
                                </label>
                                <div>
                                    <Autocomplete
                                        disabled={editable}
                                        margin="dense"
                                        sx={{
                                            width: "80%",
                                        }}
                                        options={filteredDoctors || []}
                                        disableClearable
                                        getOptionLabel={(option) => option.name}
                                        value={
                                            filteredDoctors?.filter(
                                                (doctor) =>
                                                    doctor.id === selectedDoctor
                                            )[0] || null
                                        }
                                        onChange={handleDoctorChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Search Doctor"
                                            />
                                        )}
                                        isOptionEqualToValue={(
                                            option,
                                            value
                                        ) => {
                                            if (value) {
                                                return option.id === value.id;
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        {departments && ipdData?.result?.department ? (
                            <div className="form-control">
                                <div>
                                    <InputLabel id="department-label">
                                        Department
                                    </InputLabel>
                                    <Select
                                        sx={{
                                            width: "80%",
                                        }}
                                        id="department"
                                        label="Department"
                                        name="department"
                                        value={editForm.department || ""}
                                        disabled
                                    >
                                        {metaData?.result?.departments?.map(
                                            (department) => (
                                                <MenuItem
                                                    key={department.id}
                                                    value={department.id}
                                                    className="capitalize"
                                                >
                                                    {department.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </div>
                            </div>
                        ) : (
                            <span></span>
                        )}
                    </div>

                    <div>
                        <div>
                            <FormControl
                                sx={{
                                    width: "80%",
                                }}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                disabled={editable}
                            >
                                <InputLabel id="payment-mode-label">
                                    Payment Mode
                                </InputLabel>
                                <Select
                                    required
                                    disabled={editable}
                                    labelId="payment-mode-label"
                                    label="Payment Mode"
                                    name="payment_mode"
                                    value={editForm?.payment_mode || ""} // Convert to lowercase and then capitalize
                                    onChange={(e) =>
                                        handleInputChange(
                                            "payment_mode",
                                            e.target.value
                                        )
                                    }
                                >
                                    {metaData?.result?.payment_modes?.map(
                                        (mode) => (
                                            <MenuItem
                                                key={mode}
                                                value={mode}
                                                className="capitalize"
                                            >
                                                {mode.charAt(0).toUpperCase() +
                                                    mode.slice(1)}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="">
                        <label>Admission Date</label>

                        {/* <DatePicker  maxDate={dayjs().toDate()}
                            className="px-6 py-4 w-[220px] border border-gray-300 rounded-lg"
                            dateFormat="yyyy/MM/dd"
                            placeholderText="Select date"
                            selectsRange={true}
                            isClearable
                            disabled={editable}
                            selected={selectedEditDate}
                            onChange={handleEditDate}
                        /> */}
                        <div>
                            <DatePicker  maxDate={dayjs().toDate()}
                                disabled
                                className="px-6 py-4 w-[310px] border border-gray-500 rounded-lg"
                                selected={selectedEditDate}
                                onChange={handleEditDate}
                                dateFormat="yyyy/MM/dd"
                                placeholderText="Select Date"
                            />
                        </div>
                    </div>

                    <div>
                        <label>Admission Charge</label>
                        <TextField
                            sx={{
                                width: "80%",
                            }}
                            disabled={editable}
                            name="admission_charge"
                            fullWidth
                            value={editForm.admission_charge}
                            onChange={handleChange}
                        />
                    </div>
                </section>
                <section className="pt-5">
                    {editable ? (
                        <ReusableButton
                            title="Edit Profile"
                            onClick={handleEditToggle}
                            isDisabled={isEditing}
                        />
                    ) : (
                        <div className="flex items-center justify-center space-x-10">
                            <ReusableButton
                                isDisabled={isEditing}
                                title="Save"
                                onClick={handleEditSave}
                            />
                            <RedButton
                                title="Cancel"
                                isDisabled={isEditing}
                                onClick={handleCancel}
                            />
                        </div>
                    )}
                </section>
            </Paper>
        </div>
    );
};

export default PtProfile;
