import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { addSummary, deleteSummary, getSummaries, getSummary, updateSummary } from "../../../services/api";

export function useGetSummary(ipdID) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.summary, ipdID], // Pass ipdID as part of the query key
        queryFn: () => getSummary(ipdID),
        enabled: ipdID ? true : false,
    });

    return { summaryData: data, isLoading, isSuccess, refetch };
}

export function useGetSummaries(params = { offset: 0, limit: 1000, name: "", hid: "", ipd: "", mobile: "" }) {
    const { offset, limit, name, hid, ipd, mobile } = params;
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.summaries, { offset, limit, name, hid, ipd, mobile }],
        queryFn: () => getSummaries({ offset, limit, name, hid, ipd, mobile }),
        // enabled: ipdID ? true : false,
    });

    return { summariesData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

export function useAddSummary() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: ({ ipdID, body }) => addSummary({ ipdID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.summaries] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.summary] });
            if (data?.success) {
                showNotification("success", `Summary added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addSummary: mutate, isLoading };
}

export function useUpdateSummary() {
    const queryClient = useQueryClient();
    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: ({ summaryID, body }) => updateSummary({ summaryID, body }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.summaries] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.summary] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { updateSummary: mutate, isLoading, isSuccess };
}

export function useDeleteSummary() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (summaryID) => deleteSummary(summaryID),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.summaries] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.summary] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteSummary: mutate, isLoading };
}
