import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineReload,
} from "react-icons/ai";
import {
  useGetRoles,
  useAddRole,
  useEditRole,
  useDeleteRole,
} from "../query/hooks/roles/rolesHook"; // Import the custom hooks you created
import Typography from "@mui/material/Typography";
import { TextField, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { showNotification } from "../services/helper";
import LoaderLogo from "./common/LoaderLogo";
import { IoPersonAdd } from "react-icons/io5";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const columns = [
  { id: "id", label: "Id", minWidth: 170, align: "center" },
  // { id: "name", label: "Name", minWidth: 170, align: "center" },
  { id: "role", label: "Role", minWidth: 170, align: "center" },
  {
    id: "createdDate",
    label: "Created Date",
    minWidth: 170,
    align: "center",
  },

  { id: "actions", label: "Actions", align: "center" },
];

const Roles = () => {
  const limit = 10;
  const [offset, setOffset] = useState(10);
  const currentAuth = useSelector(selectCurrentAuth);
  const rolesPermission = currentAuth?.user?.permissions.find(
    (permission) => permission.module === 1003
  );
  const { add_permission, update_permission, delete_permission } =
    rolesPermission;
  const {
    rolesData,
    isLoading,
    isFetching,
    isRefetching,
    refetch: refetchRoles,
  } = useGetRoles({ offset, limit });

  const { addRole, isLoading: isAddingRole } = useAddRole();

  const { mutate: editRole, isLoading: isEditingRole } = useEditRole();

  const { mutate: deleteRole, isLoading: isDeletingRole } = useDeleteRole();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editedRole, setEditedRole] = useState("");
  const [toDelete, setToDelete] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [role, setRole] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCancel = () => {
    setRole("");
    handleClose();
  };
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleEditClick = (row) => {
    setEditedRole(row);
    setEditOpen(true);
  };
  const handleEditSave = () => {
    if (!editedRole.name || editedRole.name.trim() === "") {
      console.error("Role name cannot be empty.");
      return;
    }
    const updatedRole = {
      id: editedRole.id,
      name: editedRole.name.trim(),
    };
    editRole(updatedRole);
    setEditOpen(false);
  };
  const handleAdd = () => {
    if (!role || role.trim() === "") {
      showNotification("error", "Role name cannot be empty.");
      return;
    }
    addRole({ name: role.trim() });
    setRole("");
    handleClose();
  };
  const handleOpenDeleteDialog = (row) => {
    setOpenDeleteDialog(true);
    setToDelete(row);
  };
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);
  const handleDeleteConfirmed = () => {
    deleteRole(toDelete);
    handleCloseDeleteDialog();
  };

  return (
    <section>
      <div className="">
        <div className="flex justify-between px-10 mb-5">
          <div>
            <div className="flex items-center space-x-3">
              <Typography variant="h5">Roles</Typography>
              <Tooltip title="Refresh" placement="bottom-start">
                <button
                  onClick={refetchRoles}
                  className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                  style={{ cursor: "pointer" }}
                >
                  <AiOutlineReload size={20} />
                </button>
              </Tooltip>
            </div>
          </div>
          {add_permission === 1 && (
            <Tooltip title="Add Role" placement="bottom-start">
              <button
                onClick={handleOpen}
                className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
              >
                <IoPersonAdd size={18} />
              </button>
            </Tooltip>
          )}
        </div>

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "15px",
          }}
          elevation={24}
        >
          <TableContainer
            sx={{
              height: 520,
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                width: 0,
              },
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bold",
                        background: "#eeeeee",
                        textTransform: "uppercase",
                        backgroundColor: "#27272A",
                        color: "white",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {!isLoading && !isFetching && !isRefetching ? (
                <TableBody>
                  {rolesData?.result?.list?.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        key={row.id}
                        align="center"
                      >
                        <TableCell align="center">{row.id}</TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">
                          {dayjs(row.created_on).format("DD-MM-YYYY,h:mm:ss A")}
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{
                            width: 120,
                          }}
                        >
                          <div className="flex items-center justify-center space-x-4">
                            {update_permission === 1 && (
                              <Tooltip title="Edit" placement="bottom-start">
                                <button
                                  onClick={() => handleEditClick(row)}
                                  className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                >
                                  <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                </button>
                              </Tooltip>
                            )}
                            {delete_permission === 1 && (
                              <Tooltip title="Delete" placement="bottom-start">
                                <button
                                  onClick={() => handleOpenDeleteDialog(row.id)}
                                  className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                >
                                  <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                </button>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      sx={{
                        width: 120,
                      }}
                    >
                      <div className="flex items-center justify-center">
                        {/* <Lottie
                                                    animationData={Loading1}
                                                    loop={true}
                                                    style={{
                                                        height: "380px",
                                                        width: "400px",
                                                    }}
                                                /> */}
                        <LoaderLogo />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {!isLoading && !isFetching && !isRefetching && (
            <TablePagination
              count={rolesData?.result?.count || 0}
              component="div"
              rowsPerPage={10}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage=""
              sx={{
                "& .MuiSelect-select": {
                  display: "none !important",
                },
                "& > div.MuiToolbar-root > div.MuiInputBase-root > svg": {
                  display: "none !important",
                },
              }}
            />
          )}
        </Paper>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                marginBottom: 2,
              }}
            >
              Add New Role
            </Typography>

            <TextField
              required
              autoFocus
              margin="dense"
              id="role"
              label="Role"
              type="text"
              fullWidth
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />

            <div className="flex justify-between p-6">
              <button
                onClick={handleAdd}
                className="px-5 py-2 text-white duration-300 bg-blue-600 hover:bg-blue-700 rounded-3xl"
              >
                Save
              </button>
              <button
                onClick={handleCancel}
                className="px-5 py-2 text-white duration-300 bg-red-600 hover:bg-red-700 rounded-3xl"
              >
                Cancel
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editOpen}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                marginBottom: 2,
              }}
            >
              Edit Role
            </Typography>

            <TextField
              required
              autoFocus
              margin="dense"
              id="editedRole"
              label=" Name"
              type="text"
              fullWidth
              value={editedRole.name} // Set the initial value to the role name
              onChange={(e) =>
                setEditedRole({
                  ...editedRole,
                  name: e.target.value,
                })
              }
            />

            <div className="flex justify-between p-6">
              <button
                onClick={handleEditSave} // Handle the edit save logic
                className="px-5 py-2 text-white duration-300 bg-blue-600 hover:bg-blue-700 rounded-3xl"
              >
                Save
              </button>
              <button
                onClick={() => setEditOpen(false)} // Close the edit modal
                className="px-5 py-2 text-white duration-300 bg-red-600 hover:bg-red-700 rounded-3xl"
              >
                Cancel
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>

      <CommonDeleteModal
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onCancel={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmed}
        isDeleting={isDeletingRole}
      />
    </section>
  );
};

export default Roles;
