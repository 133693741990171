import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import * as dayjs from "dayjs";
import {
    TableCell,
    TableRow,
    TableContainer,
    Table,
    TableHead,
    Backdrop,
    Modal,
    Fade,
    Box,
    Typography,
    Paper,
    TableBody,
    Divider,
    TextField,
} from "@mui/material";

import { skynet } from "../../services/api";
import { useGetLabTestList } from "../../query/hooks/labtest/labtestHook";
import {
    selectCurrentClinic,
    setClinic,
} from "../../redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../services/helper";
import { useGetClinic } from "../../query/hooks/clinic/clinicHook";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-55%, -50%)",
    minWidth: 800,
    maxWidth: 1200,
    minHeight: 200,
    maxHeight: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "2px",
    p: 4,
    borderRadius: "5px",
    "&::-webkit-scrollbar": {
        width: 0,
    }, // Add this line for vertical scrolling
};

const TestBill = ({
    handleRefEdit,
    billData,
    isBillModalOpen,
    closeBillModal,
    handleRefByChange,
    refBy,
    handleSave,
    view,
}) => {
    const dispatch = useDispatch();
    const currentClinic = useSelector(selectCurrentClinic);

    const { clinicData, isLoaing } = useGetClinic();
    useEffect(() => {
        if (!currentClinic) {
            dispatch(setClinic(clinicData?.result?.result));
        }
    }, [clinicData]);

    const { labTestListData, isLoading } = useGetLabTestList(billData.id);
    const billRef = useRef();
    return (
        <>
            <Modal
                open={isBillModalOpen}
                onClose={closeBillModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isBillModalOpen}>
                    <Box sx={style}>
                        <div className="">
                            <div>
                                <section className="overflow-y-auto max-h-[550px] border rounded-sm border-slate-600 custom-scrollbar">
                                    <div
                                        ref={billRef}
                                        id="opdbill"
                                        className="w-[210mm] min-h-[297mm] px-[8mm] py-[4mm] bg-white border "
                                    >
                                        {/* Header */}
                                        <div className="flex space-x-3 border-b-4 border-b-black">
                                            <img
                                                className="w-[60px] object-contain"
                                                src={`${skynet}/${clinicData?.result?.logo}`}
                                                alt="logo"
                                            />
                                            <div className="flex justify-between w-full">
                                                <div className="flex flex-col items-start justify-start flex-1">
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {
                                                            clinicData?.result
                                                                ?.name
                                                        }
                                                    </Typography>

                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: "9px",
                                                        }}
                                                    >
                                                        {
                                                            clinicData?.result
                                                                ?.email
                                                        }
                                                    </Typography>

                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: "9px",
                                                        }}
                                                    >
                                                        {
                                                            clinicData?.result
                                                                ?.address
                                                        }
                                                    </Typography>

                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: "9px",
                                                        }}
                                                    >
                                                        {
                                                            clinicData?.result
                                                                ?.city
                                                        }
                                                        ,{" "}
                                                        {
                                                            clinicData?.result
                                                                ?.state
                                                        }
                                                        ,{" "}
                                                        {
                                                            clinicData?.result
                                                                ?.pincode
                                                        }
                                                    </Typography>
                                                </div>
                                                <div className="flex flex-col items-start justify-end w-[35%]">
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: "9px",
                                                        }}
                                                    >
                                                        <span className="font-medium">
                                                            GSTIN:
                                                        </span>{" "}
                                                        {
                                                            clinicData?.result
                                                                ?.gst
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: "9px",
                                                        }}
                                                    >
                                                        <span className="font-medium">
                                                            Reg No:
                                                        </span>{" "}
                                                        {
                                                            clinicData?.result
                                                                ?.reg_no
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: "9px",
                                                        }}
                                                    >
                                                        <span className="font-medium">
                                                            Collection Point:
                                                        </span>{" "}
                                                        {
                                                            billData?.department_name
                                                        }
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-start justify-between p-4 my-5 border border-solid rounded-lg border-slate-800">
                                            <div className="flex space-x-3">
                                                <div>
                                                    <Typography
                                                        variant="subtitle2"
                                                        className="font-semibold capitalize "
                                                        sx={{
                                                            fontWeight: "12px",
                                                        }}
                                                    >
                                                        <span className="font-semibold">
                                                            Name:
                                                        </span>{" "}
                                                        {
                                                            billData?.patient_title
                                                        }
                                                        {". "}
                                                        {billData?.patient_name}
                                                    </Typography>

                                                    <Typography variant="subtitle2">
                                                        <span className="font-semibold">
                                                            Gender:
                                                        </span>{" "}
                                                        {
                                                            billData?.patient_gender
                                                        }
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        <span className="font-semibold">
                                                            Age:
                                                        </span>{" "}
                                                        {billData?.patient_age}
                                                    </Typography>
                                                    <div className="flex items-center">
                                                        <p className="mr-2 font-semibold ">
                                                            Ref By:{" "}
                                                        </p>
                                                        {!view && (
                                                            <TextField
                                                                margin="dense"
                                                                size="small"
                                                                variant="outlined"
                                                                value={refBy}
                                                                inputProps={{
                                                                    style: {
                                                                        height: "10px",
                                                                    },
                                                                }}
                                                                onChange={
                                                                    handleRefByChange
                                                                }
                                                            />
                                                        )}
                                                        {view && <p>{refBy}</p>}
                                                        {!view && (
                                                            <button
                                                                className={`text-sm bg-blue-500 text-white px-3 py-1 rounded shadow-lg-md ml-3`}
                                                                onClick={
                                                                    handleSave
                                                                }
                                                            >
                                                                Save
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-start justify-end">
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{ fontWeight: "9px" }}
                                                >
                                                    <span className="font-semibold">
                                                        HID:
                                                    </span>{" "}
                                                    {billData?.hid}
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    <span className="font-semibold">
                                                        Mobile:
                                                    </span>{" "}
                                                    {billData?.patient_mobile}
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    <span className="font-semibold">
                                                        Date:
                                                    </span>{" "}
                                                    {dayjs(
                                                        billData?.created_on
                                                    ).format("LL")}
                                                </Typography>
                                            </div>
                                        </div>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead
                                                    sx={{
                                                        fontWeight: "bold",
                                                        textTransform:
                                                            "uppercase",
                                                        backgroundColor: "gray",
                                                        color: "white",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            sx={{
                                                                width: "10%",
                                                                color: "white",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Sl No
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                width: "70%",
                                                                color: "white",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Tests
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            sx={{
                                                                width: "15%",
                                                                color: "white",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Price
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {labTestListData?.result?.list.map(
                                                        (data, index) => (
                                                            <TableRow
                                                                height={40}
                                                                key={data.id}
                                                            >
                                                                <TableCell align="center">
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    {
                                                                        data.test_name
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    ₹{" "}
                                                                    {
                                                                        data.charge
                                                                    }{" "}
                                                                    /-
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <div className="flex items-end justify-end px-4 mt-10">
                                            <div className="flex justify-between w-[40%]">
                                                {/* Labels */}
                                                <div className="flex flex-col justify-center ">
                                                    <Typography
                                                        variant="body1"
                                                        className="text-gray-500"
                                                    >
                                                        Total Charge:
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="text-gray-500"
                                                    >
                                                        Discount:
                                                    </Typography>
                                                    <Divider />
                                                    <Typography
                                                        variant="body1"
                                                        className="text-gray-500"
                                                    >
                                                        Payable Amount:
                                                    </Typography>
                                                </div>

                                                {/* Values */}
                                                <div className="flex flex-col justify-center ">
                                                    <Typography variant="body1">
                                                        ₹{billData.total_amount}{" "}
                                                        /-
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        ₹
                                                        {
                                                            billData.discount_amount
                                                        }{" "}
                                                        /-
                                                    </Typography>

                                                    <Typography variant="body1">
                                                        ₹
                                                        {
                                                            billData.payable_amount
                                                        }{" "}
                                                        /-
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="w-[210mm] flex justify-end space-x-10 pt-4 items-center">
                                    <button
                                        onClick={useReactToPrint({
                                            content: () => billRef.current,
                                        })}
                                        className={`text-sm bg-green-500 text-white px-3 py-1.5 rounded shadow-lg-md`}
                                    >
                                        Print
                                    </button>
                                    <button
                                        onClick={handleRefEdit}
                                        className={`text-sm bg-blue-500 text-white px-3 py-1.5 rounded shadow-lg-md`}
                                    >
                                        Edit Ref
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default TestBill;
