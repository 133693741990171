import {
  Autocomplete,
  Backdrop,
  Box,
  Fade,
  Modal,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineReload,
} from "react-icons/ai";
import dayjs from "dayjs";
import { IoMdAdd } from "react-icons/io";
import PdfFormat from "./common/PdfFormat";
import { FaFileUpload } from "react-icons/fa";
import { RiAiGenerate } from "react-icons/ri";
import LoaderLogo from "./common/LoaderLogo";
import {
  getEndingTimeOfDate,
  getPermissionsByModule,
  getStartingTimeOfDate,
  showNotification,
} from "../services/helper";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetEmployees } from "../query/hooks/employee/employeeHooks";
import { selectEmployeesParam } from "../redux/features/filters/employeeSlice";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useGetMetaData } from "../query/hooks/metadata/metadataHook";
import ReusableButton from "./common/ReusableButton";
import RedButton from "./common/RedButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useAddPayment,
  useDeletePayment,
  useGetPayments,
  useUpdatePayment,
} from "../query/hooks/payments/paymentsHook";
import ClearButton from "./common/ClearButton";
import {
  clearPayout,
  selectPayoutParam,
  setPayoutParams,
} from "../redux/features/filters/payoutSlice";
import ClearIcon from "@mui/icons-material/Clear";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
const columns = [
  { id: "1", label: "Doctor", minWidth: 100, align: "center" },

  {
    id: "2",
    label: "Payment For",
    minWidth: 120,
    align: "center",
  },

  {
    id: "4",
    label: "Department",
    minWidth: 120,
    align: "center",
  },
  { id: "5", label: "Date", minWidth: 100, align: "center" },
  { id: "5", label: "Amount", minWidth: 100, align: "center" },
  { id: "7", label: "Actions", minWidth: 100, align: "center" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};
const PayOuts = () => {
  const currentAuth = useSelector(selectCurrentAuth);
  const permissionsList = currentAuth?.user?.permissions;

  const paymentsPermissions = getPermissionsByModule(1018, permissionsList);
  const {
    add_permission,
    update_permission,
    delete_permission,
    view_permission,
  } = paymentsPermissions;
  const dispatch = useDispatch();
  const { metaData } = useGetMetaData();
  const payOutParams = useSelector(selectPayoutParam);
  const [payout, setPayout] = useState({
    date: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    department: "",
    doctor: "",
    payment_for: "",
    amount: 0,
  });
  //     date: null,
  //    type: ''
  //     amount: 0,

  const {
    paymentsData,
    isLoading: isPaymentsLoading,
    refetch: refetchPayments,
  } = useGetPayments(payOutParams);

  const { addPayment, isLoading: isAddingPayment } = useAddPayment();
  const { updatePayment, isLoading: isUpdatingPayment } = useUpdatePayment();
  const { deletePayment, isLoading: isDeletingPayment } = useDeletePayment();
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [toDelete, setToDelete] = useState("");
  const { employeesData } = useGetEmployees();
  const [addView, setAddView] = useState(false);
  const [editView, setEditView] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [searchDoctor, setSearchDoctor] = useState(null);
  const [searchDoctorId, setSearchDoctorId] = useState(null);
  const [selectedDept, setSelectedDept] = useState(null);
  const [inputDeptValue, setInputDeptValue] = useState("");

  const [selectedEditDept, setSelectedEditDept] = useState(null);

  const handleOpen = () => {
    setOpen(true);
    setAddView(true);
    setEditView(false);
  };

  const handleClose = () => {
    setSelectedDoctor(null);
    setAddView(false);
    setEditView(false);
    setSelectedDept(null);
    setInputDeptValue(null);
    setPayout({
      date: null,
      department: "",
      doctor: "",
      payment_for: "",
      amount: 0,
    });
    setOpen(false);
  };
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    setSelectedDate(date);
    setPayout({ ...payout, date: formattedDate });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPayout({ ...payout, [name]: value });
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmed = () => {
    deletePayment(toDelete);
    handleCloseDeleteDialog();
  };
  const handleOpenDeleteDialog = (row) => {
    setOpenDeleteDialog(true);
    setToDelete(row);
  };

  const handleAddServices = () => {
    if (
      !payout.date ||
      isNaN(payout.doctor) ||
      isNaN(payout.department) ||
      !payout.payment_for ||
      isNaN(payout.amount) ||
      payout.amount <= 0
    ) {
      showNotification("error", "Please fill in all fields correctly.");
      return;
    }

    // Parse doctor, department, and amount
    const parsedPayout = {
      ...payout,
      doctor: parseInt(payout.doctor),
      department: parseInt(payout.department),
      amount: parseInt(payout.amount),
    };

    addPayment(parsedPayout);
    handleClose();
  };

  const filteredDoctors = employeesData?.result?.list?.filter(
    (employee) => employee.designation_name === "Doctor"
  );

  const handleDoctorClear = () => {
    setSelectedDoctor("");
    setPayout((prevService) => ({
      ...prevService,
      doctor: "",
    }));
  };
  const isOptionEqualToValue = (option, value) => option.id === value.id;

  const handleDoctorChange = (event, value) => {
    setSelectedDoctor(value);
    if (value) {
      setSelectedDept(value.department); // Set the selected department when doctor is selected
      setPayout((prevService) => ({
        ...prevService,
        doctor: value.id,
        department: value.department,
      }));
    }
  };

  const handleDeptChange = (event, value) => {
    setSelectedDept(value);
    setInputDeptValue(value ? value.name : ""); // Update input value when department is selected
  };

  const handleDeptInputChange = (event, value) => {
    setInputDeptValue(value);
  };
  const [editedPayout, setEditedPayout] = useState("");
  const handleEditClick = (row) => {
    setEditedPayout(row);
    setOpen(true); // Set the role to be edited
    setEditView(true);
    setAddView(false);
    setSelectedEditDept(row.department);
    setSelectedEditDoctor(row.doctor); // Open the edit modal
  };
  const handleEditChange = (event) => {
    const { name, value } = event.target;

    const parsedValue = name === "amount" ? parseInt(value) || 0 : value;

    setEditedPayout((prevState) => ({
      ...prevState,
      [name]: parsedValue,
    }));
  };

  const [selectedEditDoctor, setSelectedEditDoctor] = useState(null);

  const handleDoctorEditChange = (event, value) => {
    setSelectedEditDoctor(value.id);
    if (value) {
      setSelectedEditDept(value.department); // Set the selected department when doctor is selected
      setEditedPayout((prevService) => ({
        ...prevService,
        doctor: value.id,
        department: value.department,
      }));
    }
  };
  const [selectedEditDate, setSelectedEditDate] = useState(null);
  const handleEditDate = (date) => {
    setSelectedEditDate(date);
    setPayout({ ...payout, date: getStartingTimeOfDate(dayjs(date)) });
  };
  const handleEditService = () => {
    if (
      !editedPayout.date ||
      isNaN(editedPayout.doctor) ||
      isNaN(editedPayout.department) ||
      !editedPayout.payment_for ||
      isNaN(editedPayout.amount) ||
      editedPayout.amount <= 0
    ) {
      showNotification("error", "Please fill in all fields correctly.");
      return;
    }

    // Parse doctor, department, and amount
    const parsedPayout = {
      ...editedPayout,
      doctor: parseInt(editedPayout.doctor),
      department: parseInt(editedPayout.department),
      amount: parseInt(editedPayout.amount),
    };
    updatePayment(parsedPayout);
    handleClose();
  };
  const [searchParams, setSearchParams] = useState({
    doctor: "",
    department: "",
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDoctorSearch = (event, value) => {
    setSearchDoctorId(value);
    if (value) {
      setSearchParams((prevSearchParams) => ({
        ...prevSearchParams,
        doctor: value.id,
      }));
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [name]: value,
    }));
  };

  const handleSearchDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const clearSearch = () => {
    dispatch(clearPayout());
    setSearchDoctor(null);
    setSearchDoctorId(null);
    setSelectedOption(null);
    setInputValue("");
    setStartDate(null);
    setEndDate(null);
    setSearchParams({
      doctor: "",
      department: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      setPayoutParams({
        ...payOutParams,
        doctor: searchParams.doctor || "",
        department: searchParams.department || "",
        startDate: startDate ? getStartingTimeOfDate(dayjs(startDate)) : "",
        endDate: endDate ? getEndingTimeOfDate(dayjs(endDate)) : "",
      })
    );
  };
  const handleClearDept = () => {
    setSelectedOption(null);
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      department: "",
    }));
  };

  const handleOptionChange = (event, value) => {
    setSelectedOption(value);
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      department: value ? value.name : "",
    }));
  };

  const handleInputChange2 = (event, value) => {
    setInputValue(value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
    dispatch(
      setPayoutParams({
        ...payOutParams,
        offset: (value - 1) * payOutParams.limit,
      })
    );
  };

  if (!paymentsData)
    return (
      <div className="flex items-center justify-center">
        <LoaderLogo />
      </div>
    );

  return (
    <>
      <div className="flex justify-between px-10 mb-5">
        <div>
          <div className="flex items-center space-x-3">
            <Typography variant="h5">Doctor Payouts</Typography>

            <Tooltip title="Refresh" placement="bottom-start">
              <button
                // onClick={refetch}
                className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                style={{ cursor: "pointer" }}
              >
                <AiOutlineReload size={20} />
              </button>
            </Tooltip>
          </div>
        </div>
        {add_permission === 1 && (
          <Tooltip title="Add Payment" placement="bottom-start">
            <button
              onClick={handleOpen}
              className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
            >
              <IoMdAdd size={18} />
            </button>
          </Tooltip>
        )}
      </div>
      <div className="w-[100%] my-4">
        <form
          onSubmit={handleSubmit}
          onKeyPress={handleKeyPress}
          className="flex items-center space-x-5"
        >
          <Autocomplete
            margin="dense"
            className="w-[250px]"
            options={filteredDoctors}
            disableClearable
            getOptionLabel={(option) =>
              option
                ? option?.name.charAt(0).toUpperCase() + option?.name.slice(1)
                : ""
            }
            value={searchDoctorId}
            onChange={handleDoctorSearch}
            renderInput={(params) => (
              <TextField {...params} label="Search Doctor" />
            )}
            isOptionEqualToValue={isOptionEqualToValue}
          />
          <div
            style={{
              zIndex: 999,
            }}
          >
            <DatePicker
              maxDate={dayjs().toDate()}
              className="px-6 py-4 w-[220px] border border-gray-300 rounded-xl"
              dateFormat="yyyy/MM/dd"
              placeholderText="StartDate - EndDate"
              selectsRange={true}
              isClearable
              selected={startDate}
              onChange={handleSearchDate}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <Autocomplete
            className="w-[250px]"
            options={metaData?.result?.departments || []}
            getOptionLabel={(option) => option.name}
            value={selectedOption}
            onChange={handleOptionChange}
            inputValue={inputValue}
            onInputChange={handleInputChange2}
            disableClearable
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Department"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {selectedOption && (
                        <ClearIcon
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={handleClearDept}
                        />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <ReusableButton title="Search" onClick={handleSubmit} type="submit" />
          <ClearButton title="Clear" onClick={clearSearch} />
        </form>
      </div>
      <section>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "15px",
          }}
          elevation={24}
        >
          <TableContainer
            sx={{
              height: 520,
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bold",
                        background: "#eeeeee",
                        textTransform: "uppercase",
                        backgroundColor: "#27272A",
                        color: "white",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentsData?.result?.list?.map((item, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#E4E4E7" : "white",
                    }}
                  >
                    <TableCell align="center">
                      {item.doctor_name}{" "}
                      {/* Assuming this should be the name of the doctor */}
                    </TableCell>

                    <TableCell align="center">{item.payment_for} </TableCell>
                    <TableCell align="center">
                      {item.department_name}{" "}
                    </TableCell>
                    <TableCell align="center">{item.date} </TableCell>
                    <TableCell align="center">{item.amount} </TableCell>
                    <TableCell align="center">
                      <div className="flex items-center justify-center space-x-4">
                        {update_permission === 1 && (
                          <Tooltip title="Edit" placement="bottom-start">
                            <button
                              className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                              onClick={() => handleEditClick(item)}
                            >
                              <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                            </button>
                          </Tooltip>
                        )}
                        {delete_permission && (
                          <Tooltip title="Delete" placement="bottom-start">
                            <button
                              onClick={() => handleOpenDeleteDialog(item.id)}
                              className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                            >
                              <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                            </button>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="flex items-center justify-center my-4">
            <Pagination
              count={Math.ceil(
                paymentsData?.result?.count / payOutParams.limit
              )}
              page={currentPage}
              sx={{
                "& .MuiSelect-select": {
                  display: "none !important",
                },
                "& > div.MuiToolbar-root > div.MuiInputBase-root > svg": {
                  display: "none !important",
                },
              }}
              onChange={handlePaginationChange}
            />
          </div>
        </Paper>
      </section>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {addView && (
              <>
                <div className="grid w-full grid-cols-2 gap-4">
                  <TextField
                    name="payment_for"
                    label="Payment For"
                    value={payout.payment_for}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />

                  <TextField
                    name="amount"
                    label="Amount"
                    value={payout.amount}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />

                  <Autocomplete
                    margin="dense"
                    fullWidth
                    options={filteredDoctors}
                    disableClearable
                    getOptionLabel={(option) =>
                      option
                        ? option?.name.charAt(0).toUpperCase() +
                          option?.name.slice(1)
                        : ""
                    }
                    value={selectedDoctor}
                    onChange={handleDoctorChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Search Doctor" />
                    )}
                    isOptionEqualToValue={isOptionEqualToValue}
                  />

                  <Autocomplete
                    fullWidth
                    margin="dense"
                    options={metaData?.result?.departments || []}
                    getOptionLabel={(option) => option.name}
                    // value={selectedDept}
                    value={
                      metaData?.result?.departments.filter(
                        (dept) => dept.id === selectedDept
                      )[0] || null
                    }
                    onChange={handleDeptChange}
                    inputValue={inputDeptValue}
                    onInputChange={handleDeptInputChange}
                    disableClearable
                    isOptionEqualToValue={(option, value) => {
                      if (value) {
                        return option.id === value.id;
                      }

                      // option.id === value?.id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Department"
                        variant="outlined"
                      />
                    )}
                  />
                  <DatePicker
                    maxDate={dayjs().toDate()}
                    className="px-6 py-4 w-[310px] border border-gray-500 rounded-lg"
                    // selected={payout.date}
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy/MM/dd"
                    placeholderText="Select Date"
                  />
                </div>
                <section className="flex py-5 justify-evenly">
                  <ReusableButton title="Save" onClick={handleAddServices} />
                  <RedButton title="Cancel" onClick={handleClose} />
                </section>
              </>
            )}

            {editView && (
              <>
                <div className="grid w-full grid-cols-2 gap-4">
                  <TextField
                    name="payment_for"
                    label="Payment For"
                    value={editedPayout?.payment_for}
                    onChange={handleEditChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />

                  <TextField
                    name="amount"
                    label="Amount"
                    value={editedPayout?.amount}
                    onChange={handleEditChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />

                  <Autocomplete
                    margin="dense"
                    fullWidth
                    options={filteredDoctors || []}
                    disableClearable
                    getOptionLabel={(option) => option.name}
                    value={
                      filteredDoctors?.filter(
                        (doctor) => doctor.id === selectedEditDoctor
                      )[0] || null
                    }
                    onChange={handleDoctorEditChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Search Doctor" />
                    )}
                    isOptionEqualToValue={(option, value) => {
                      if (value) {
                        return option.id === value.id;
                      }
                    }}
                  />
                  <Autocomplete
                    fullWidth
                    margin="dense"
                    options={metaData?.result?.departments || []}
                    getOptionLabel={(option) => option.name}
                    value={
                      metaData?.result?.departments.filter(
                        (dept) => dept.id === selectedEditDept
                      )[0] || null
                    }
                    onChange={handleDeptChange}
                    inputValue={inputDeptValue}
                    onInputChange={handleDeptInputChange}
                    disableClearable
                    isOptionEqualToValue={(option, value) => {
                      if (value) {
                        return option.id === value.id;
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Department"
                        variant="outlined"
                      />
                    )}
                  />
                  <DatePicker
                    maxDate={dayjs().toDate()}
                    className="px-6 py-4 w-[310px] border border-gray-500 rounded-lg"
                    // selected={editedPayout?.date}
                    selected={selectedEditDate}
                    onChange={handleEditDate}
                    dateFormat="yyyy/MM/dd"
                    placeholderText="Select Date"
                  />
                </div>
                <section className="flex py-5 justify-evenly">
                  <ReusableButton title="Save" onClick={handleEditService} />
                  <RedButton title="Cancel" onClick={handleClose} />
                </section>
              </>
            )}
          </Box>
        </Fade>
      </Modal>

      <CommonDeleteModal
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onCancel={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmed}
        isDeleting={isDeletingPayment}
      />
    </>
  );
};

export default PayOuts;
