import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import * as dayjs from "dayjs";
import { Backdrop, Modal, Fade, Box, Typography } from "@mui/material";
import { getItem } from "../../services/helper";
import { skynet } from "../../services/api";
import { useGetClinic } from "../../query/hooks/clinic/clinicHook";
import { useDispatch, useSelector } from "react-redux";
import {
    selectCurrentClinic,
    setClinic,
} from "../../redux/features/auth/authSlice";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 800,
    maxWidth: 1200,
    height: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "2px",
    p: 4,
    borderRadius: "5px",
    // overflowY: "scroll",
    "&::-webkit-scrollbar": {
        width: 0,
    }, // Add this line for vertical scrolling
};

const OPDSheet = ({ billData, isBillModalOpen, closeBillModal }) => {
    const dispatch = useDispatch();
    const currentClinic = useSelector(selectCurrentClinic);

    const { clinicData, isLoading } = useGetClinic();
    useEffect(() => {
        if (!currentClinic && !isLoading) {
            dispatch(setClinic(clinicData?.result));
        }
    }, [clinicData]);

    const billRef = useRef();

    return (
        <>
            <Modal
                open={isBillModalOpen}
                onClose={closeBillModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isBillModalOpen}>
                    <Box sx={style}>
                        <div className="">
                            <div>
                                <section className="overflow-y-auto max-h-[550px] border rounded-sm border-black custom-scrollbar">
                                    <div
                                        ref={billRef}
                                        id="opdbill"
                                        className="w-[210mm] min-h-[297mm] px-[8mm] py-[4mm] bg-white"
                                    >
                                        {/* Header */}
                                        <div className="flex pb-5 space-x-3 border-b-4 border-b-black">
                                            <img
                                                className="w-[60px] object-contain"
                                                src={`${skynet}/${clinicData?.result?.logo}`}
                                                alt="logo"
                                            />
                                            <div className="flex justify-between w-full">
                                                <div className="flex flex-col items-start justify-start flex-1">
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {
                                                            clinicData?.result
                                                                ?.name
                                                        }
                                                    </Typography>

                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: "9px",
                                                        }}
                                                    >
                                                        {
                                                            clinicData?.result
                                                                ?.email
                                                        }
                                                    </Typography>

                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: "9px",
                                                        }}
                                                    >
                                                        {
                                                            clinicData?.result
                                                                ?.address
                                                        }
                                                    </Typography>

                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: "9px",
                                                        }}
                                                    >
                                                        {
                                                            clinicData?.result
                                                                ?.city
                                                        }
                                                        ,{" "}
                                                        {
                                                            clinicData?.result
                                                                ?.state
                                                        }
                                                        ,{" "}
                                                        {
                                                            clinicData?.result
                                                                ?.pincode
                                                        }
                                                    </Typography>
                                                </div>
                                                <div className="flex flex-col items-start justify-end w-[35%]">
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: "9px",
                                                        }}
                                                    >
                                                        <span className="font-medium">
                                                            GSTIN:
                                                        </span>{" "}
                                                        {
                                                            clinicData?.result
                                                                ?.gst
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: "9px",
                                                        }}
                                                    >
                                                        <span className="font-medium">
                                                            Reg No:
                                                        </span>{" "}
                                                        {
                                                            clinicData?.result
                                                                ?.reg_no
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontWeight: "9px",
                                                        }}
                                                    >
                                                        <span className="font-medium">
                                                            Department:
                                                        </span>{" "}
                                                        {
                                                            billData?.department_name
                                                        }
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-start justify-between w-full p-4 my-5 border border-solid rounded-lg border-slate-800">
                                            <div className="flex space-x-3">
                                                <div>
                                                    <Typography
                                                        variant="subtitle2"
                                                        className="font-semibold capitalize"
                                                        sx={{
                                                            fontWeight: "12px",
                                                        }}
                                                    >
                                                        <span className="font-semibold">
                                                            Name:
                                                        </span>{" "}
                                                        {
                                                            billData?.patient_title
                                                        }
                                                        {". "}
                                                        {billData?.patient_name}
                                                    </Typography>

                                                    <Typography
                                                        variant="subtitle2"
                                                        className="capitalize"
                                                    >
                                                        <span className="font-semibold">
                                                            Gender:
                                                        </span>{" "}
                                                        {
                                                            billData?.patient_gender
                                                        }
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        <span className="font-semibold">
                                                            Date:
                                                        </span>{" "}
                                                        {dayjs(
                                                            billData?.created_on
                                                        ).format(
                                                            "DD-MM-YYYY,h:mm:ss A"
                                                        )}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-start justify-end">
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{ fontWeight: "9px" }}
                                                >
                                                    <span className="font-semibold">
                                                        OPD-ID:
                                                    </span>{" "}
                                                    {billData?.id}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{ fontWeight: "9px" }}
                                                >
                                                    <span className="font-semibold">
                                                        HID:
                                                    </span>{" "}
                                                    {billData?.hid}
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    <span className="font-semibold">
                                                        Mobile:
                                                    </span>{" "}
                                                    {billData?.patient_mobile}
                                                </Typography>
                                            </div>
                                        </div>
                                        <section className="flex flex-col items-end space-y-3">
                                            <Typography variant="subtitle2">
                                                <span className="font-semibold">
                                                    Consulting Doctor:
                                                </span>{" "}
                                                _________________________
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                <span className="font-semibold">
                                                    BP:
                                                </span>{" "}
                                                _________________________
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                <span className="font-semibold">
                                                    SPO2:
                                                </span>{" "}
                                                _________________________
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                <span className="font-semibold">
                                                    Pulse:
                                                </span>{" "}
                                                _________________________
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                <span className="font-semibold">
                                                    RR:
                                                </span>{" "}
                                                _________________________
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                <span className="font-semibold">
                                                    Temp:
                                                </span>{" "}
                                                _________________________
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                <span className="font-semibold">
                                                    Weight:
                                                </span>{" "}
                                                _________________________
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                <span className="font-semibold">
                                                    OFC:
                                                </span>{" "}
                                                _________________________
                                            </Typography>
                                        </section>
                                    </div>
                                </section>

                                <div className="w-[210mm] flex justify-end space-x-10 items-center pt-4">
                                    <button
                                        onClick={useReactToPrint({
                                            content: () => billRef.current,
                                        })}
                                        className={`text-sm bg-green-500 text-white px-3 py-1.5 rounded shadow-lg-md`}
                                    >
                                        Print
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default OPDSheet;
