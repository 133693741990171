// ForgetPassword.js
import React, { useState } from "react";
import {
    TextField,
    Button,
    Paper,
    Typography,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
import ticked from "../../assets/ticked.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { useForgotPw } from "../../query/hooks/auth/authHook";
import { useGetPublicMetaData } from "../../query/hooks/metadata/metadataHook";
const ForgetPassword = () => {
    const navigate = useNavigate();

    const [data, setData] = useState({
        email: "",
        entity: "",
    });

    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const { forgotPwFunc, isSuccess } = useForgotPw(setEmailSubmitted);

    const { publicMetaData: metaData, isLoading } = useGetPublicMetaData();
    const handleHome = () => {
        // You can implement the logic to verify the OTP here
        navigate("/");
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleEmailSubmit = () => {
        forgotPwFunc(data);
        // setEmailSubmitted(true);
    };
    return (
        <div className="relative flex items-center justify-center min-h-screen bg-black">
            <Paper
                elevation={3}
                className="p-6"
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -70%)",
                    boxShadow: 24,
                    borderRadius: "5px",
                    backgroundColor: "#27272A",
                    width: 500,
                    mt: 10,
                }}
            >
                <h2 className="mb-4 text-2xl font-bold text-center text-white">
                    {emailSubmitted ? "Password Sent" : "Forgot Password"}
                </h2>

                {!emailSubmitted && (
                    <div className="space-y-4">
                        <TextField
                            value={data.email}
                            // onChange={(e) => setEmail(e.target.value)}
                            onChange={handleChange}
                            className="w-full"
                            variant="outlined"
                            size="medium"
                            InputProps={{
                                className: "text-white rounded-3xl",
                                style: {
                                    color: "white", // Set the text color to white
                                    "&::placeholder": {
                                        color: "white", // Set the placeholder color to white
                                    },
                                },
                            }}
                            placeholder="Enter Registed Email"
                            type="email"
                            name="email"
                            required
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "white", // White border
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "white", // White border on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "white", // White border when focused
                                    },
                                },
                            }}
                        />
                        <FormControl
                            sx={{
                                width: "100%", // Set the same width as the textfields
                                backgroundColor: "transparent", // Set background color to transparent
                                borderRadius: "5px", // Set the same border radius as the textfields
                                border: "1px solid white", // Add a white border
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none !important",
                                },
                            }}
                            size="medium"
                        >
                            <Select
                                sx={{
                                    border: "none",
                                    color: "white", // Set text color to white
                                    "&:focus": {
                                        backgroundColor: "transparent", // Set background color on focus to transparent
                                    },
                                    "& .MuiSelect-icon": {
                                        color: "white", // Set arrow color to white
                                    },
                                }}
                                value={data.entity}
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                name="entity"
                            >
                                <MenuItem value="">
                                    <em>Choose</em>
                                </MenuItem>
                                {metaData?.result?.entities?.map((entity) => (
                                    <MenuItem key={entity.id} value={entity.id}>
                                        {entity.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )}

                {emailSubmitted && (
                    <>
                        <div className="flex items-center justify-center">
                            <Lottie
                                animationData={ticked}
                                loop={false}
                                style={{
                                    width: "200px",
                                }}
                            />
                        </div>
                        <Typography className="mb-4 text-lg font-semibold text-white">
                            We've sent a new password to your email. Please
                            check your inbox.
                        </Typography>
                        <Typography className="mb-4 text-lg font-semibold text-white">
                            Remember to update your password in your profile
                            after logging in.
                        </Typography>
                    </>
                )}
                <div className="flex justify-center mt-8">
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            color: "#27272A",
                            fontWeight: "bold",
                            backgroundColor: "white",
                        }} // Add this line
                        onClick={
                            emailSubmitted ? handleHome : handleEmailSubmit
                        }
                    >
                        {emailSubmitted ? "Go Home" : "Submit Email"}
                    </Button>
                </div>
            </Paper>
        </div>
    );
};

export default ForgetPassword;
