import React, { useState } from "react";
import {
  List,
  ListItem,
  IconButton,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  ButtonGroup,
  Button,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import { SaveAlt as SaveAltIcon } from "@mui/icons-material";
import {
  useGetPatientLabInvestigations,
  useGetPatientOPD,
} from "../../query/hooks/patientsSide/patientSideHooks";
import dayjs from "dayjs";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import NoDataFound from "../common/NoDataFound";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
const routes = [
  {
    id: 3,
    label: "Profile",
    path: "/patient/profile",
    icon: <AccountCircleOutlinedIcon />,
  },
  {
    id: 2,
    label: "OPD Reports",
    path: "/patient/history",
    icon: <ArticleIcon />,
  },
  {
    id: 1,
    label: "Lab Test Reports",
    path: "/patient/testreports",
    icon: <MonitorHeartIcon />,
  },
];

const PatientDashboard = () => {
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState(1);
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };
  const { data: patientLabData, isLoading: isPatientTestLoading } =
    useGetPatientLabInvestigations();
  const { data: patientOPDData, isLoading: isPatientOPDLoading } =
    useGetPatientOPD();
  const handleDownload = (opd) => {
    navigate(`/patient/history/files/${opd.id}`);
  };
  const text = {
    color: "white",
  };
  const handleViewClick = (id) => {
    navigate(`/patient/testreports/${id}`);
  };
  return (
    <section className="h-screen overflow-auto">
      <div className="flex justify-center w-full ">
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="button group"
        >
          <Button
            onClick={() => handleButtonClick(1)}
            variant={selectedButton === 1 ? "contained" : "outlined"}
          >
            OPD
          </Button>
          <Button
            onClick={() => handleButtonClick(2)}
            variant={selectedButton === 2 ? "contained" : "outlined"}
          >
            Test Report
          </Button>
        </ButtonGroup>
      </div>
      {selectedButton === 1 && (
        <div>
          {" "}
          {/* Use div instead of section */}
          <List>
            {patientOPDData?.result?.count === 0 ? (
              <NoDataFound />
            ) : (
              patientOPDData?.result?.list?.map((opd, index) => (
                <div key={opd.id}>
                  <ListItem
                    sx={{
                      backgroundColor: "#27272A",
                      color: "#fff",
                      border: "1px solid #fff",
                      borderRadius: "5px",
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        color: "#fff",
                      }}
                      primary={opd.patient_name}
                      secondary={
                        <>
                          <Typography>{`Department: ${opd.department_name}`}</Typography>
                          <Typography>{`Created on: ${dayjs(
                            opd.created_on
                          ).format("DD/MM/YYYY")}`}</Typography>
                        </>
                      }
                      secondaryTypographyProps={{
                        color: "#fff",
                      }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="start"
                        onClick={() => handleDownload(opd)}
                        sx={{ color: "#fff" }}
                      >
                        <SaveAltIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {index < patientOPDData?.result?.list?.length - 1 && (
                    <Divider
                      sx={{ backgroundColor: "#fff" }}
                      variant="inset"
                      component="li"
                    />
                  )}
                </div>
              ))
            )}
          </List>
        </div>
      )}
      {selectedButton === 2 && (
        <div>
          <List>
            {patientLabData?.result?.count === 0 ? (
              <NoDataFound />
            ) : (
              patientLabData?.result?.list?.map((opd, index) => (
                <div key={opd.id}>
                  <ListItem
                    sx={{
                      backgroundColor: "#27272A",
                      color: "#fff",
                      border: "1px solid #fff",
                      borderRadius: "5px",
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        color: "#fff",
                      }}
                      primary={opd.patient_name}
                      secondary={
                        <>
                          <Typography>{`Hospital/Clinic: ${opd.entity_name}`}</Typography>
                          <Typography>{`Created on: ${dayjs(
                            opd.created_on
                          ).format("DD/MM/YYYY")}`}</Typography>
                        </>
                      }
                      secondaryTypographyProps={{
                        color: "#fff",
                      }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="start"
                        onClick={() => handleViewClick(opd.id)}
                        sx={{ color: "#fff" }}
                      >
                        <RemoveRedEyeSharpIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {index < patientLabData?.result?.list?.length - 1 && (
                    <Divider
                      sx={{ backgroundColor: "#fff" }}
                      variant="inset"
                      component="li"
                    />
                  )}
                </div>
              ))
            )}
          </List>
        </div>
      )}
    </section>
  );
};

export default PatientDashboard;
