import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { FaRegEye } from "react-icons/fa";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { useGetIPD } from "../../../query/hooks/ipd/ipds";
import { useNavigate, useParams } from "react-router-dom";
import { useGetIPDBeds } from "../../../query/hooks/ipd/bedHistoryHook";
import { useGetIPDDeposit } from "../../../query/hooks/ipd/deposit";
import { useGetLabInventory } from "../../../query/hooks/ipd/labInvestigation";
import { useGetIPDOT } from "../../../query/hooks/ipd/ots";
import { useGetIPDRefund } from "../../../query/hooks/ipd/refund";
import { useGetIPDService } from "../../../query/hooks/ipd/ipdServices";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getStartingTimeOfDate,
  showNotification,
} from "../../../services/helper";
import ReusableButton from "../ReusableButton";
import RedButton from "../RedButton";
import { useGetMetaData } from "../../../query/hooks/metadata/metadataHook";
import { useAddBill } from "../../../query/hooks/ipd/bill";
import Lottie from "lottie-react";
import tickAnim from "../../../assets/donetick.json";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const OTheaders = [
  "OT Procedure",
  "OT Charge",
  "Surgeon Charge",
  "Assistant Surgeon Charge",
  "PWF Charge",
  "Anaesthesia Charge",
];
const totalHeaders = ["Charge Types", "Totals"];
const depositheaders = ["Created On", "Amount", "Payment Mode"];
const serviceHeaders = ["Service Charge", "Quantity", "Service Name"];
const columnsBeds = [
  { id: "bed_number", label: "Bed Number" },
  { id: "bed_charge", label: "Bed Charge" },
  { id: "nurse_charge", label: "Nurse Charge" },
];
const refundHeaders = [
  { id: "deposit_amount", label: "Deposit Amount" },
  { id: "refund_amount", label: "Refunded Amount" },

  { id: "created_on", label: "Created On" },
];
const FinalBill = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { ipdData, isLoading: isPtLoading } = useGetIPD(params.id);
  const { metaData } = useGetMetaData();

  const { ipdBedsData } = useGetIPDBeds(ipdData?.result?.id);
  const { ipdDepositData } = useGetIPDDeposit(ipdData?.result?.id);
  const { labInventoryData } = useGetLabInventory(ipdData?.result?.id);
  const { ipdOTData } = useGetIPDOT(ipdData?.result?.id);
  const { ipdRefundData } = useGetIPDRefund(ipdData?.result?.id);
  const { ipdServiceData } = useGetIPDService(ipdData?.result?.id);

  const [bedtotal, setBedTotal] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalOt, setTotalOt] = useState(0);
  const [totalInvestigationCharge, setTotalInvestigationCharge] = useState(0);
  const [totalService, setTotalService] = useState(0);
  const [totalRefunds, setTotalRefunds] = useState(0);

  const [formData, setFormData] = useState({
    total_amount: 0,
    deposit_amount: 0,
    refund_amount: 0,
    discount_amount: 0,
    payable_amount: 0,
    payment_mode: "",
    payment_reference_number: "",
    discharge_date: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  });

  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (ipdBedsData?.result?.list) {
      const bedChargeTotal = ipdBedsData.result.list.reduce(
        (acc, bed) => acc + bed.bed_charge + bed.nurse_charge,
        0
      );
      setBedTotal(bedChargeTotal);
    }
  }, [ipdBedsData, params]);

  useEffect(() => {
    if (ipdOTData?.result?.list) {
      const otTotal = ipdOTData.result.list.reduce(
        (acc, ot) =>
          acc +
          ot.ot_charge +
          ot.surgeon_charge +
          ot.assistant_surgeon_charge +
          ot.pwf_charge +
          ot.anaesthesia_charge,
        0
      );
      setTotalOt(otTotal);
    }
  }, [ipdOTData, params]);

  useEffect(() => {
    if (ipdServiceData?.result?.list?.length > 0) {
      const serviceTotal = ipdServiceData.result.list.reduce(
        (acc, item) => acc + item.service_charge * item.quantity,
        0
      );
      setTotalService(serviceTotal);
    }
  }, [ipdServiceData, params]);

  useEffect(() => {
    if (labInventoryData?.result?.list?.length > 0) {
      const investigationTotal = labInventoryData.result.list.reduce(
        (acc, investigation) => acc + investigation.test_charge,
        0
      );
      setTotalInvestigationCharge(investigationTotal);
    }
  }, [labInventoryData, params]);

  useEffect(() => {
    if (ipdDepositData?.result?.list) {
      const depositTotal = ipdDepositData.result.list.reduce(
        (acc, deposit) => acc + deposit.amount,
        0
      );
      setTotalDeposit(depositTotal);
    }
  }, [ipdDepositData, params]);

  useEffect(() => {
    if (ipdRefundData?.result?.list) {
      const refundTotal = ipdRefundData.result.list.reduce(
        (acc, refunds) => acc + refunds.refunded_amount,
        0
      );
      setTotalRefunds(refundTotal);
    }
  }, [ipdRefundData, params]);

  useEffect(() => {
    const newPayableAmount =
      formData.total_amount -
      formData.deposit_amount -
      formData.discount_amount +
      formData.refund_amount;
    setFormData((prevState) => ({
      ...prevState,
      payable_amount: newPayableAmount,
    }));
  }, [
    formData.total_amount,
    formData.deposit_amount,
    formData.discount_amount,
    formData.refund_amount,
  ]);

  useEffect(() => {
    if (formData.discount_amount) {
      setFormData((prevState) => ({
        ...prevState,
        payable_amount:
          formData.total_amount -
          formData.discount_amount -
          formData.deposit_amount +
          formData.refund_amount,
      }));
    }
  }, [formData.discount_amount]);

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");

    setSelectedDate(date);
    setFormData({
      ...formData,
      discharge_date: formattedDate,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "discount_amount") {
      const parsedValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
      setFormData((prevService) => ({
        ...prevService,
        [name]: parsedValue,
      }));
    } else {
      setFormData((prevService) => ({
        ...prevService,
        [name]: value,
      }));
    }
  };

  const { addBill, isLoading: isAddingBill, isSuccess } = useAddBill();

  // const handleAddFinalBill = () => {
  //     const parsedFormData = {
  //         total_amount: parseInt(formData.total_amount),
  //         deposit_amount: parseInt(formData.deposit_amount),
  //         refund_amount: parseInt(formData.refund_amount),
  //         discount_amount: parseInt(formData.discount_amount),
  //         payable_amount: parseInt(formData.payable_amount),
  //         payment_mode: formData.payment_mode.trim(),
  //         payment_reference_number: formData.payment_reference_number.trim(),
  //         discharge_date: formData.discharge_date,
  //     };
  //     console.log(`parsedFormData`,parsedFormData)
  //     if (!ipdData?.result?.id) {
  //         showNotification("error", "Please Try Again Later");
  //         return;
  //     }

  //     for (const key in parsedFormData) {
  //         if (
  //             key === "discharge_date" ||
  //             key === "payment_mode" ||
  //             key === "payment_reference_number"
  //         ) {
  //             continue;
  //         }
  //         if (isNaN(parsedFormData[key]) || parsedFormData[key] < 0) {
  //             showNotification(
  //                 "error",
  //                 "Please Ensure you have filled all the required fields "
  //             );
  //             return;
  //         }
  //     }

  //     addBill({ ipdID: ipdData.result.id, body: parsedFormData });
  //     navigate("/admin/ipds");
  // };

  const handleAddFinalBill = () => {
    const parsedFormData = {
      total_amount: parseInt(formData.total_amount),
      deposit_amount: parseInt(formData.deposit_amount),
      refund_amount: parseInt(formData.refund_amount),
      discount_amount: parseInt(formData.discount_amount),
      payable_amount: parseInt(formData.payable_amount), // Allowing negative values
      payment_mode: formData.payment_mode.trim(),
      payment_reference_number: formData.payment_reference_number.trim(),
      discharge_date: formData.discharge_date,
    };

    console.log(`parsedFormData`, parsedFormData);

    // Check if IPD data is available
    if (!ipdData?.result?.id) {
      showNotification("error", "Please Try Again Later");
      return;
    }

    // Validate numeric fields except for payable_amount
    for (const key of [
      "total_amount",
      "deposit_amount",
      "refund_amount",
      "discount_amount",
    ]) {
      if (isNaN(parsedFormData[key]) || parsedFormData[key] < 0) {
        showNotification(
          "error",
          "Please ensure all monetary fields are filled correctly."
        );
        return;
      }
    }

    // Validate non-numeric fields
    if (!parsedFormData.payment_mode) {
      showNotification("error", "Payment mode is required.");
      return;
    }

    // If all validations pass, proceed to add the bill
    addBill({ ipdID: ipdData.result.id, body: parsedFormData });
    navigate("/admin/ipds");
  };

  const handleCancel = () => {
    setFormData({
      total_amount: "",
      deposit_amount: "",
      refund_amount: "",
      discount_amount: "",
      payable_amount: "",
      payment_mode: "",
      payment_reference_number: "",
      discharge_date: null,
    });
  };

  const totalAmt = totalService + bedtotal + totalOt + totalInvestigationCharge;

  useEffect(() => {
    if (totalAmt > 0) {
      setFormData((prevState) => ({
        ...prevState,
        total_amount: totalAmt,
        deposit_amount: totalDeposit,
        refund_amount: totalRefunds,
      }));
    }
  }, [totalAmt, totalDeposit, totalRefunds]);
  const totalCharge =
    totalService +
      bedtotal +
      totalOt +
      totalRefunds +
      totalInvestigationCharge -
      totalDeposit || 0;
  return (
    <div className="space-y-4">
      <div className="p-5 rounded-lg shadow-lg">
        <section className="pt-3">
          <Typography variant="h6" pt={1}>
            Bed Charges
          </Typography>
          <Divider />
          <TableContainer
            sx={{
              height: "auto",
            }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columnsBeds.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      // style={{
                      //     fontWeight: "bold",
                      //     background: "#eeeeee",
                      //     textTransform: "uppercase",
                      //     backgroundColor: "#27272A",
                      //     color: "white",
                      // }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ipdBedsData?.result?.list?.map((item, index) => (
                  <TableRow key={item?.id}>
                    <TableCell key={item.id} align="center">
                      {item.bed_number}
                    </TableCell>
                    <TableCell key={item.id} align="center">
                      ₹ {""} {item.bed_charge}
                    </TableCell>
                    <TableCell key={item.id} align="center">
                      ₹ {""}
                      {item.nurse_charge}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell rowSpan={2} />
                  <TableCell align="center" colSpan={1}>
                    Total Bed Charge
                  </TableCell>
                  <TableCell align="center">{`₹ ${bedtotal}/-`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </section>
        <br />
        <section className="pt-3">
          <Typography variant="h6" pt={1}>
            Lab Investigations
          </Typography>{" "}
          <Divider />
          <TableContainer
            sx={{
              height: "auto",
            }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Test Name</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Test Charge</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {labInventoryData?.result?.list?.map((item, index) => (
                  <TableRow key={item?.id}>
                    <TableCell align="center">{item?.test_name}</TableCell>
                    <TableCell align="center">{item?.quantity}</TableCell>
                    <TableCell align="center">
                      ₹ {""} {item?.test_charge}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell rowSpan={3} colSpan={1} />
                  <TableCell align="center" colSpan={1}>
                    Total Lab Investigation Charge
                  </TableCell>
                  <TableCell align="center">
                    {`₹ ${totalInvestigationCharge}/-`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </section>
        <br />
        <section className="pt-3">
          <Typography variant="h6" pt={1}>
            OTs
          </Typography>
          <Divider />
          <TableContainer
            sx={{
              height: "auto",
            }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {OTheaders.map((header, index) => (
                    <TableCell key={index} align="center">
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ipdOTData?.result?.list?.map((item, index) => (
                  <TableRow key={item?.id}>
                    <TableCell align="center">{item?.ot_procedure}</TableCell>
                    <TableCell align="center">
                      ₹ {""} {item?.ot_charge}
                    </TableCell>
                    <TableCell align="center">
                      ₹ {""} {item?.surgeon_charge}
                    </TableCell>
                    <TableCell align="center">
                      ₹ {""} {item?.assistant_surgeon_charge}
                    </TableCell>
                    <TableCell align="center">
                      ₹ {""} {item?.pwf_charge}
                    </TableCell>
                    <TableCell align="center">
                      ₹ {""} {item?.anaesthesia_charge}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell rowSpan={5} colSpan={4} />
                  <TableCell align="center" colSpan={1}>
                    Total OT Charge
                  </TableCell>
                  <TableCell align="center">{`₹ ${totalOt}/-`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </section>
        <br />
        <section>
          <Typography variant="h6" pt={1}>
            Services
          </Typography>
          <Divider />
          <TableContainer
            sx={{
              height: "auto",
            }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {serviceHeaders.map((header, index) => (
                    <TableCell key={index} align="center">
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ipdServiceData?.result?.list?.map((item, index) => (
                  <TableRow key={item?.id}>
                    <TableCell align="center">
                      ₹ {""} {item?.service_charge * item?.quantity}
                    </TableCell>
                    <TableCell align="center">{item?.quantity}</TableCell>
                    <TableCell align="center">{item?.service_name}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell rowSpan={2} />
                  <TableCell align="center" colSpan={1}>
                    Total Service Charge
                  </TableCell>
                  <TableCell align="center">{`₹ ${totalService}/-`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </section>
        <br />
        <section>
          <Typography variant="h6" pt={1}>
            Deposits
          </Typography>{" "}
          <Divider />
          <TableContainer
            sx={{
              height: "auto",
            }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {depositheaders.map((header, index) => (
                    <TableCell key={index} align="center">
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ipdDepositData?.result?.list?.map((item, index) => (
                  <TableRow key={item?.id}>
                    <TableCell align="center">
                      {dayjs(item?.created_on).format("DD-MM-YYYY,h:mm:ss A")}
                    </TableCell>
                    <TableCell align="center">
                      ₹ {""} {item?.amount}
                    </TableCell>
                    <TableCell align="center">{item?.payment_mode}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell rowSpan={2} />
                  <TableCell align="center" colSpan={1}>
                    Total Deposits
                  </TableCell>
                  <TableCell align="center">{`₹ ${totalDeposit}/-`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
        </section>
        <br />
        <section className="">
          <Typography variant="h6" pt={1}>
            Refunds
          </Typography>{" "}
          <Divider />
          <TableContainer
            sx={{
              height: "auto",
            }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {refundHeaders.map((column) => (
                    <TableCell key={column.id} align="center">
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ipdRefundData?.result?.list?.map((item, index) => (
                  <TableRow key={item?.id}>
                    <TableCell align="center">
                      ₹ {""} {item?.deposit_amount}
                    </TableCell>
                    {/* <TableCell align="center">
                                                {item?.adjusted_amount}
                                            </TableCell> */}
                    <TableCell align="center">
                      ₹ {""} {item?.refunded_amount}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(item?.created_on).format("DD-MM-YYYY,h:mm:ss A")}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell align="center">Total Refund</TableCell>
                  <TableCell align="center">{`₹ ${totalRefunds}/-`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </section>
        <br />
        <section className="">
          <Typography variant="h6" className="pt-2">
            Total Charges
          </Typography>
          <Divider />
          <TableContainer
            sx={{
              height: "auto",
            }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {totalHeaders.map((column, index) => (
                    <TableCell key={index} align="center">
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">Total Bed Charge</TableCell>
                  <TableCell align="center">
                    ₹ {""} {bedtotal}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Total Deposits</TableCell>
                  <TableCell align="center">
                    ₹ {""}
                    {totalDeposit}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Total Ot Charge</TableCell>
                  <TableCell align="center">
                    ₹ {""} {totalOt}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    Total Lab Investigation Charge
                  </TableCell>
                  <TableCell align="center">
                    ₹ {""}
                    {totalInvestigationCharge}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Total Service</TableCell>
                  <TableCell align="center">
                    ₹ {""} {totalService}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Total Refunds</TableCell>
                  <TableCell align="center">
                    ₹ {""} {totalRefunds}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="center">Total Charge</TableCell>
                  <TableCell align="center">
                    {/* {`₹ ${
                                            totalService +
                                            bedtotal +
                                            totalOt +
                                            totalRefunds +
                                            totalInvestigationCharge -
                                            totalDeposit
                                        }/-`} */}
                    ₹ {""}
                    {totalCharge}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </section>
        <br />
        <section className="h-[500px]  w-full pt-5">
          {isSuccess ? (
            <Lottie
              animationData={tickAnim}
              loop={false}
              style={{
                height: "380px",
                width: "400px",
              }}
            />
          ) : (
            <>
              <div className="grid grid-cols-2 gap-3 place-items-center">
                <TextField
                  sx={{
                    width: "80%",
                  }}
                  margin="dense"
                  label="Total Charges"
                  name="total_amount"
                  value={formData.total_amount || 0}
                  // onChange={handleChange}
                />
                <TextField
                  sx={{
                    width: "80%",
                  }}
                  margin="dense"
                  label="Deposit Amount"
                  name="deposit_amount"
                  value={formData.deposit_amount || 0}
                  // onChange={handleChange}
                />
                <TextField
                  sx={{
                    width: "80%",
                  }}
                  margin="dense"
                  label="Refund"
                  name="refund_amount"
                  value={formData.refund_amount || 0}
                  // onChange={handleChange}
                />
                <TextField
                  sx={{
                    width: "80%",
                  }}
                  margin="dense"
                  label="Total Discount"
                  name="discount_amount"
                  value={formData.discount_amount || 0}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  InputProps={{
                    inputProps: {
                      maxLength: 16,
                      pattern: "[0-9]*",
                    },
                  }}
                />
                <TextField
                  sx={{
                    width: "80%",
                  }}
                  margin="dense"
                  label="Payable Amount"
                  name="payable_amount"
                  // disabled
                  value={formData.payable_amount || 0}
                  // onChange={handleChange}
                />
                <FormControl
                  sx={{
                    width: "80%",
                  }}
                  variant="outlined"
                  margin="normal"
                >
                  <InputLabel id="payment-mode-label">Payment Mode</InputLabel>
                  <Select
                    labelId="payment-mode-label"
                    label="Payment Mode"
                    name="payment_mode"
                    value={formData.payment_mode || ""}
                    onChange={handleChange}
                    className="capitalize"
                  >
                    {metaData?.result?.payment_modes?.map((mode) => (
                      <MenuItem key={mode} value={mode} className="capitalize">
                        {mode}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  sx={{
                    width: "80%",
                  }}
                  margin="dense"
                  label="Payment Reference"
                  name="payment_reference_number"
                  value={formData.payment_reference_number || ""}
                  onChange={handleChange}
                />

                <DatePicker
                  maxDate={dayjs().toDate()}
                  className="px-6 py-4 border border-gray-500 rounded-lg "
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Select Date"
                />
              </div>
              <div className="flex items-center pt-5 justify-evenly">
                <ReusableButton onClick={handleAddFinalBill} title="Add" />
                <RedButton onClick={handleCancel} title="Cancel" />
              </div>
            </>
          )}
        </section>
        <br />
      </div>
    </div>
  );
};

export default FinalBill;
