// employeesSlice.js

import { createSlice } from "@reduxjs/toolkit";

const employeesInitialState = {
    defaultParams: {
        name: "",
        email: "",
        mobile: "",
        department: "",
        offset: 0,
        limit: 10,
    },
    searchField: {
        fileValue: null,
    },
    selectedOption: null,
};

const employeesSlice = createSlice({
    name: "employees",
    initialState: employeesInitialState,
    reducers: {
        setEmployeesParams: (state, action) => {
            state.defaultParams = action.payload;
        },
        setSearchFieldEmployees: (state, action) => {
            const { fileValue } = action.payload;
            state.searchField.fileValue = fileValue;
        },
        setSelectedEmployeesOption: (state, action) => {
            state.selectedOption = action.payload;
        },
        clearEmployees: (state, action) => {
            return employeesInitialState;
        },
    },
    extraReducers: {},
});

export const selectEmployeesParam = (state) => state.employees.defaultParams;
export const selectSearchFieldEmployees = (state) => state.employees.searchField;
export const selectSelectedEmployeesOption = (state) => state.employees.selectedOption;
export const { setEmployeesParams, setSearchFieldEmployees, setSelectedEmployeesOption, clearEmployees } = employeesSlice.actions;
export default employeesSlice.reducer;
