import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { getDashboard } from "../../../services/api";

import { showNotification } from "../../../services/helper";

export function useGetDashboard(dashboardParams) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError, error } = useQuery({
        queryKey: [queryKeys.dashboardDatas, { startDate: dashboardParams.startDate }, { endDate: dashboardParams.endDate }],
        queryFn: () => getDashboard({
            startDate: dashboardParams.startDate,
            endDate: dashboardParams.endDate
        }),
        if(isError) {
            showNotification('error', error)
        }
    });

    return { dashboardData: data, isLoading, isSuccess, isFetching, refetch, isRefetching, isError, error };
}
