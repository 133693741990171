import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    defaultParams: {
        name: "",
        email: "",
        mobile: "",
        offset: 0,
        limit: 10
    },
    selectedUser: null,
};

const userSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setUserParams: (state, action) => {
            state.defaultParams = action.payload;
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
        },
        clearUser: (state, action) => {
            return initialState;
        },
    },
    extraReducers: {},
});

export const selectUserParams = (state) => state.users.defaultParams;
export const selectSelectedUser = (state) => state.users.selectedUser;  // Corrected export name
export const { setUserParams, setSelectedUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
