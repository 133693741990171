import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { FaRegEye } from "react-icons/fa";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { showNotification } from "../../../services/helper";
import { useGetIPD } from "../../../query/hooks/ipd/ipds";
import { useParams } from "react-router-dom";
import { useGetTests } from "../../../query/hooks/test/testHook";
import CommonDeleteModal from "../CommonDeleteModal";
import {
  Autocomplete,
  Backdrop,
  Box,
  Stack,
  Fade,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useAddLabInventory,
  useDeleteLabInventory,
  useEditLabInventory,
  useGetLabInventory,
} from "../../../query/hooks/ipd/labInvestigation";
import ReusableButton from "../ReusableButton";
import RedButton from "../RedButton";

import dayjs from "dayjs";
import LoaderLogo from "../LoaderLogo";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const columns = [
  {
    id: 1,
    name: "Sr No",
    align: "center",
    width: 100,
  },
  {
    id: 2,
    name: "Test",
    align: "center",
    minWidth: 120,
  },
  {
    id: 3,
    name: "Charge",
    align: "center",
    minWidth: 120,
  },
  {
    id: 4,
    name: "Quantity",
    align: "center",
    minWidth: 120,
  },
  {
    id: 5,
    name: "Total",
    align: "center",
    minWidth: 120,
  },
  {
    id: 6,
    name: "Created On",
    align: "center",
    minWidth: 120,
  },

  {
    id: 8,
    name: "Action",
    align: "center",
    minWidth: 120,
  },
];

const LabInvestigation = () => {
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [addView, setAddView] = useState(false);
  const [editView, setEditView] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editForm, setEditForm] = useState("");
  const [toDelete, setToDelete] = useState("");

  const params = useParams();
  const { ipdData, isLoading: isPtLoading } = useGetIPD(params.id);
  const { testsData } = useGetTests();

  const {
    labInventoryData,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
    isRefetching,
  } = useGetLabInventory(ipdData?.result?.id);

  const { addLabInventory, isLoading: isAdding } = useAddLabInventory();
  const { editLabInventory, isLoading: isEditing } = useEditLabInventory();
  const { deleteLabInventory, isLoading: isDeleting } = useDeleteLabInventory();
  const handleOpen = () => {
    setOpen(true);
    setAddView(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAddView(false);
    setEditView(false);
    setSelectedTest([]);
  };

  const [selectedTest, setSelectedTest] = useState([]);

  const handleTestSelect = (event, value) => {
    if (value) {
      const updatedServices = value.map((test) => ({
        ...test,
        quantity: 1, // Default quantity value
      }));
      setSelectedTest(updatedServices);
    } else {
      setSelectedTest([]);
    }
  };

  const handleRemoveService = (testID) => {
    setSelectedTest(selectedTest.filter((test) => test.id !== testID));
  };

  const handleQuantityChange = (event, testID) => {
    const updatedServices = selectedTest.map((test) => {
      if (test.id === testID) {
        return {
          ...test,
          quantity: parseInt(event.target.value) || 1, // Ensure quantity is a valid number
        };
      }
      return test;
    });
    setSelectedTest(updatedServices);
  };
  const handleAddServices = () => {
    if (selectedTest.length === 0) {
      showNotification("error", "No services selected.");
      return;
    }
    const tests = selectedTest.map((item) => ({
      test: parseInt(item.id),
      quantity: parseInt(item.quantity),
      test_charge: parseInt(item.charge),
    }));

    addLabInventory({
      ipdID: params.id,
      body: { tests },
    });
    if (isSuccess) {
      handleClose();
    }
  };

  const handleEditClick = (row) => {
    setEditForm(row);
    setOpen(true);
    setEditView(true);
  };

  const handleEditQuantity = (event) => {
    const newQuantity = event.target.value;
    setEditForm((prevState) => ({
      ...prevState,
      quantity: newQuantity,
    }));
  };
  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: addView ? "70%" : editView ? "35%" : "80%",
    height: editView ? "60%" : "auto",

    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
  };
  const handleEditService = () => {
    if (isNaN(editForm.quantity) || editForm.quantity <= 0) {
      showNotification("error", "Quantity is invalid.");
      return;
    }
    const test = {
      test: parseInt(editForm.test),
      quantity: parseInt(editForm.quantity),
      test_charge: parseInt(editForm.test_charge),
    };
    editLabInventory({
      testID: editForm.id,
      ipdTestData: test,
    });

    if (!isEditing) {
      handleClose();
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmed = () => {
    deleteLabInventory(toDelete);
    handleCloseDeleteDialog();
  };
  const handleOpenDeleteDialog = (row) => {
    setOpenDeleteDialog(true);
    setToDelete(row);
  };
  if (!ipdData)
    return (
      <div className="flex items-center justify-center">
        <LoaderLogo />
      </div>
    );
  return (
    <>
      <div className="mb-4">
        <ReusableButton title="Add Lab Investigation" onClick={handleOpen} />
      </div>
      <Paper
        elevation={24}
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "15px",
        }}
      >
        <TableContainer
          sx={{
            height: 520,
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
              width: 0,
            },
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      paddingX: column.paddingX,
                    }}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                      fontWeight: "bold",
                      background: "#eeeeee",
                      textTransform: "uppercase",
                      backgroundColor: "#27272A",
                      color: "white",
                    }}
                  >
                    {column.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {labInventoryData?.result?.list?.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item.test_name}</TableCell>
                  <TableCell align="center">{item.test_charge}/-</TableCell>
                  <TableCell align="center">{item.quantity}</TableCell>
                  <TableCell align="center">
                    {item.quantity * item.test_charge}/-
                  </TableCell>
                  <TableCell align="center">
                    {dayjs(item?.created_on).format("DD-MM-YYYY,h:mm:ss A")}
                  </TableCell>

                  <TableCell align="center">
                    <div className="flex items-center justify-center space-x-4">
                      <Tooltip title="Edit" placement="bottom-start">
                        <button
                          className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                          onClick={() => handleEditClick(item)}
                        >
                          <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                        </button>
                      </Tooltip>
                      <Tooltip title="Delete" placement="bottom-start">
                        <button
                          onClick={() => handleOpenDeleteDialog(item.id)}
                          className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                        >
                          <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                        </button>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {addView && (
              <>
                <section className="flex flex-col items-center justify-center">
                  <Autocomplete
                    sx={{
                      width: "70%",
                    }}
                    multiple
                    options={testsData?.result.list?.filter(
                      (test) =>
                        !selectedTest.some(
                          (selected) => selected.id === test.id
                        )
                    )}
                    getOptionLabel={(option) => option.name}
                    onChange={handleTestSelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Lab Investigation"
                        placeholder="Lab Investigation"
                      />
                    )}
                  />
                  {selectedTest.length > 0 && (
                    <Paper
                      sx={{
                        width: "70%",
                        overflow: "hidden",
                        borderRadius: "15px",
                        mt: 3,
                      }}
                      elevation={24}
                    >
                      <TableContainer
                        component={Paper}
                        sx={{
                          height: 320,
                          overflowX: "scroll",
                          "&::-webkit-scrollbar": {
                            width: 0,
                          },
                        }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow
                              style={{
                                background: "#eeeeee",
                                backgroundColor: "#27272A",
                              }}
                            >
                              <TableCell
                                align="center"
                                style={{
                                  width: "40%",
                                  fontWeight: "bold",
                                  background: "#eeeeee",
                                  textTransform: "uppercase",
                                  backgroundColor: "#27272A",
                                  color: "white",
                                }}
                              >
                                Name
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  background: "#eeeeee",
                                  textTransform: "uppercase",
                                  backgroundColor: "#27272A",
                                  color: "white",
                                }}
                              >
                                Charge
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  width: "40%",
                                  fontWeight: "bold",
                                  background: "#eeeeee",
                                  textTransform: "uppercase",
                                  backgroundColor: "#27272A",
                                  color: "white",
                                }}
                              >
                                Quantity
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selectedTest.map((test) => (
                              <TableRow key={test.id}>
                                <TableCell align="center">
                                  {test.name}
                                </TableCell>
                                <TableCell align="center">
                                  {test.charge}
                                  /-
                                </TableCell>
                                <TableCell align="center">
                                  <Select
                                    sx={{
                                      width: 120,
                                    }}
                                    value={test.quantity}
                                    onChange={(event) =>
                                      handleQuantityChange(event, test.id)
                                    }
                                  >
                                    {[...Array(10)].map((_, index) => (
                                      <MenuItem
                                        key={index + 1}
                                        value={index + 1}
                                      >
                                        {index + 1}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  )}
                </section>
                <section className="flex py-5 justify-evenly">
                  <ReusableButton title="Save" onClick={handleAddServices} />
                  <RedButton title="Cancel" onClick={handleClose} />
                </section>
              </>
            )}
            {editView && (
              <section className="p-4">
                <Typography variant="h4" textAlign="center">
                  Edit Service
                </Typography>
                <div className="mt-4">
                  <Stack
                    direction="column"
                    useFlexGap
                    flexWrap="wrap"
                    spacing={2}
                    alignItems="center"
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={editForm.test_name}
                      label="Service"
                      name="test"
                    />
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={dayjs(editForm.created_on).format(
                        "DD-MM-YYYY,h:mm:ss A"
                      )}
                      label="Created On"
                      name="created_on"
                    />
                  </Stack>
                  <Select
                    className="mt-4"
                    sx={{
                      width: 210,
                    }}
                    value={editForm.quantity}
                    onChange={(event) => handleEditQuantity(event)}
                  >
                    {[...Array(10)].map((_, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="flex py-5 justify-evenly">
                  <ReusableButton title="Save" onClick={handleEditService} />
                  <RedButton title="Cancel" onClick={handleClose} />
                </div>
              </section>
            )}
          </Box>
        </Fade>
      </Modal>
      <CommonDeleteModal
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onCancel={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmed}
        isDeleting={isDeleting}
      />
    </>
  );
};

export default LabInvestigation;
