import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { addExpense, deleteExpense, getExpenses, updateExpense } from "../../../services/api";

export function useGetExpenses(params = { offset: 0, limit: 1000, startDate: "", endDate: "" }) {
    const { offset, limit, startDate, endDate } = params;
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery(
        [queryKeys.expenses, { offset, limit, startDate, endDate }],
        () => getExpenses({ offset, limit, startDate, endDate }),
        {
            enabled: startDate !== null && endDate !== null,
            onError: (error) => {
                // Assuming showNotification is a custom function to display notifications
                showNotification('error', error);
            }
        }
    );

    return { expensesData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}



export function useAddExpense() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (expenseData) => addExpense(expenseData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.expenses] });
            if (data?.success) {
                showNotification("success", `Expense added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addExpense: mutate, isLoading };
}

export function useUpdateExpense() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (expenseData) => updateExpense(expenseData.id, expenseData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.expenses] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { updateExpense: mutate, isLoading };
}

export function useDeleteExpense() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (expenseId) => deleteExpense(expenseId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.expenses] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteExpense: mutate, isLoading };
}
