// otServicesSlice.js

import { createSlice } from "@reduxjs/toolkit";

const otServicesInitialState = {
    defaultParams: {
        ot_procedure: "",
        department: "",
        offset: 0,
        limit: 10,
    },
};

const otServicesSlice = createSlice({
    name: "otServices",
    initialState: otServicesInitialState,
    reducers: {
        setOtServicesParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },

        clearOtServices: (state, action) => {
            state.defaultParams = otServicesInitialState.defaultParams;
        },
    },
    extraReducers: {},
});

export const selectOtServicesParam = (state) => state.otServices.defaultParams;
export const { setOtServicesParams, clearOtServices } = otServicesSlice.actions;
export default otServicesSlice.reducer;
