import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import dayjs from "dayjs";
import {
    Backdrop,
    Box,
    Fade,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    selectCurrentClinic,
    setClinic,
} from "../../../redux/features/auth/authSlice";
import { useGetClinic } from "../../../query/hooks/clinic/clinicHook";
import { skynet } from "../../../services/api";
import { getItem } from "../../../services/helper";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const otColumn = [
    { id: "hid", label: "HID", minWidth: 10, align: "center" },
    { id: "patient_name", label: "Patient", minWidth: 100, align: "center" },
    {
        id: "ot_procedure",
        label: "OT Procedure",
        minWidth: 100,
        align: "center",
    },
    { id: "doctor_name", label: "Doctor", minWidth: 100, align: "center" },
    {
        id: "surgeon_charge",
        label: "Surgeon Charge",
        minWidth: 100,
        align: "center",
    },
    {
        id: "anaesthesia_charge",
        label: "Anaesthesia Charge",
        minWidth: 100,
        align: "center",
    },
    { id: "Total", label: "Total Amount", minWidth: 100, align: "center" },
];
const OPDHeaders = [
    { id: "hid", label: "HID", minWidth: 10, align: "center" },
    { id: "patient_name", label: "Patient", minWidth: 100, align: "center" },
    { id: "doctor_name", label: "Doctor", minWidth: 100, align: "center" },
    {
        id: "charge",
        label: "Surgeon Charge",
        minWidth: 100,
        align: "center",
    },
    {
        id: "discount_amount",
        label: "Discount Amount",
        minWidth: 100,
        align: "center",
    },
    { id: "Payable_Amt", label: "Payable Amt", minWidth: 100, align: "center" },
    {
        id: "created_on",
        label: "Created On",
        minWidth: 100,
        align: "center",
    },
];

const MotHeader = [
    { id: "hid", label: "HID", minWidth: 10, align: "center" },
    { id: "patient_name", label: "Patient", minWidth: 100, align: "center" },
    {
        id: "service_names",
        label: "Service Names",
        minWidth: 100,
        align: "center",
    },
    { id: "doctor_name", label: "Doctor", minWidth: 100, align: "center" },
    {
        id: "payable_amount",
        label: "Payable Amount",
        minWidth: 100,
        align: "center",
    },

    { id: "Total", label: "Total Amount", minWidth: 100, align: "center" },
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-55%, -50%)",
    minWidth: 800,
    maxWidth: 1200,
    minHeight: 200,
    maxHeight: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "2px",
    p: 4,
    borderRadius: "5px",
    "&::-webkit-scrollbar": {
        width: 0,
    },
};
const DoctorCollectionReport = ({
    collectionData,
    isBillModalOpen,
    closeBillModal,
}) => {
    const [entity, setEntity] = useState("");
    const dispatch = useDispatch();
    const user = getItem("user");
    useEffect(() => {
        if (user) {
            setEntity(user.entity);
        }
    }, [user]);
    const currentClinic = useSelector(selectCurrentClinic);

    const { clinicData, isLoading } = useGetClinic();

    useEffect(() => {
        if (!currentClinic) {
            dispatch(setClinic(clinicData?.result));
        }
    }, [clinicData]);

    const billRef = useRef();

    const getTotal = (data) => {
        return data.reduce(
            (total, opd) => total + (opd.charge - opd.discount_amount),
            0
        );
    };
    const getTotalPayableAmount = (data) => {
        return data.reduce((total, item) => total + item.payable_amount, 0);
    };
    const getTotalOPD = (data) => {
        return data.reduce(
            (total, item) =>
                total + (item.surgeon_charge + item.anaesthesia_charge),
            0
        );
    };

    const totalMot = getTotalPayableAmount(collectionData?.result?.mots || []);
    const totalOPD = getTotal(collectionData?.result?.opds || []);
    const totalOTS = getTotalOPD(collectionData?.result?.ots || []);

    return (
        <>
            <Modal
                open={isBillModalOpen}
                onClose={closeBillModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isBillModalOpen}>
                    <Box sx={style}>
                        <div className="flex flex-col items-center ">
                            <section className="overflow-y-auto max-h-[550px] border rounded-sm border-slate-600 custom-scrollbar">
                                <div
                                    ref={billRef}
                                    id="opdbill"
                                    className="w-[210mm] min-h-[297mm] px-[8mm] py-[4mm] bg-white border "
                                >
                                    <div className="flex space-x-3 border-b-4 border-b-black">
                                        <img
                                            className="w-[60px] object-contain"
                                         
                                            src={
                                                `${skynet}/${clinicData?.result?.logo}`
                                            }
                                            alt="logo"
                                        />
                                        <div className="flex justify-between w-full">
                                            <div className="flex flex-col items-start justify-start flex-1">
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {clinicData?.result?.name}
                                                </Typography>

                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    {clinicData?.result?.email}
                                                </Typography>

                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    {clinicData?.result?.address}
                                                </Typography>

                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    {clinicData?.result?.city},{" "}
                                                    {clinicData?.result?.state},{" "}
                                                    {clinicData?.result?.pincode}
                                                </Typography>
                                            </div>
                                            <div className="flex flex-col items-start justify-end w-[35%]">
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    <span className="font-medium">
                                                        GSTIN:
                                                    </span>{" "}
                                                    {clinicData?.result?.gst}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    <span className="font-medium">
                                                        Reg No:
                                                    </span>{" "}
                                                    {clinicData?.result?.reg_no}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <section className="mx-auto mt-4 space-y-6">
                                        {collectionData?.result?.ots?.length >
                                        0 ? (
                                            <>
                                                <Typography variant="h6" py={1}>
                                                    OTS Report
                                                </Typography>
                                                <TableContainer
                                                    sx={{
                                                        height: "auto",
                                                    }}
                                                >
                                                    <Table
                                                        size="small"
                                                        stickyHeader
                                                        aria-label="sticky table"
                                                    >
                                                        <TableHead>
                                                            <TableRow>
                                                                {otColumn.map(
                                                                    (
                                                                        column
                                                                    ) => (
                                                                        <TableCell
                                                                            key={
                                                                                column.id
                                                                            }
                                                                            align={
                                                                                column.align
                                                                            }
                                                                            style={{
                                                                                minWidth:
                                                                                    column.minWidth,
                                                                                width: column.width,
                                                                                fontWeight:
                                                                                    "bold",
                                                                                textTransform:
                                                                                    "uppercase",
                                                                                borderBottom:
                                                                                    "4px solid black",
                                                                                borderLeft: 0,
                                                                                borderTop: 0,
                                                                                borderRight: 0,
                                                                            }}
                                                                        >
                                                                            {
                                                                                column.label
                                                                            }
                                                                        </TableCell>
                                                                    )
                                                                )}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {collectionData?.result?.ots?.map(
                                                                (ot) => (
                                                                    <TableRow
                                                                        key={
                                                                            ot.id
                                                                        }
                                                                    >
                                                                        <TableCell align="center">
                                                                            {
                                                                                ot.hid
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="center"
                                                                            className="capitalize"
                                                                        >
                                                                            {
                                                                                ot.patient_title
                                                                            }{" "}
                                                                            {
                                                                                ot.patient_name
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="center"
                                                                            className="capitalize"
                                                                        >
                                                                            {
                                                                                ot.ot_procedure
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="center"
                                                                            className="capitalize"
                                                                        >
                                                                            {
                                                                                ot.doctor_name
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {
                                                                                ot.surgeon_charge
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {
                                                                                ot.anaesthesia_charge
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {ot.surgeon_charge +
                                                                                ot.anaesthesia_charge}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )}
                                                            <TableRow>
                                                                <TableCell
                                                                    colSpan={7}
                                                                    align="right"
                                                                >
                                                                    <div className="flex items-center justify-end space-x-10 text-xl font-semibold mr-9">
                                                                        <p>
                                                                            Total:
                                                                        </p>
                                                                        <p>
                                                                            {
                                                                                totalOTS
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                        ) : null}

                                        {collectionData?.result?.opds?.length >
                                        0 ? (
                                            <>
                                                <Typography variant="h6" py={1}>
                                                    OPDS Report
                                                </Typography>
                                                <TableContainer
                                                    sx={{
                                                        maxHeight: 480,
                                                        overflowX: "scroll",
                                                        "&::-webkit-scrollbar":
                                                            {
                                                                width: 0,
                                                            },
                                                    }}
                                                >
                                                    <Table
                                                        size="small"
                                                        stickyHeader
                                                        aria-label="sticky table"
                                                    >
                                                        <TableHead>
                                                            <TableRow>
                                                                {OPDHeaders.map(
                                                                    (
                                                                        column
                                                                    ) => (
                                                                        <TableCell
                                                                            key={
                                                                                column.id
                                                                            }
                                                                            align={
                                                                                column.align
                                                                            }
                                                                            style={{
                                                                                minWidth:
                                                                                    column.minWidth,
                                                                                width: column.width,
                                                                                fontWeight:
                                                                                    "bold",
                                                                                textTransform:
                                                                                    "uppercase",
                                                                                borderBottom:
                                                                                    "4px solid black",
                                                                                borderLeft: 0,
                                                                                borderTop: 0,
                                                                                borderRight: 0,
                                                                            }}
                                                                        >
                                                                            {
                                                                                column.label
                                                                            }
                                                                        </TableCell>
                                                                    )
                                                                )}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {collectionData
                                                                ?.result?.opds
                                                                ?.length > 0 ? (
                                                                <>
                                                                    {collectionData?.result?.opds?.map(
                                                                        (
                                                                            opd
                                                                        ) => (
                                                                            <TableRow
                                                                                key={
                                                                                    opd.id
                                                                                }
                                                                            >
                                                                                <TableCell align="center">
                                                                                    {
                                                                                        opd.hid
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="center"
                                                                                    className="capitalize"
                                                                                >
                                                                                    {
                                                                                        opd.patient_title
                                                                                    }{" "}
                                                                                    {
                                                                                        opd.patient_name
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {
                                                                                        opd.doctor_name
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="center"
                                                                                    className="capitalize"
                                                                                >
                                                                                    {
                                                                                        opd.charge
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {
                                                                                        opd.discount_amount
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {opd.charge -
                                                                                        opd.discount_amount}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {dayjs(
                                                                                        opd.created_on
                                                                                    ).format(
                                                                                        "DD-MM-YYYY,h:mm:ss A"
                                                                                    )}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    )}
                                                                    <TableRow>
                                                                        <TableCell
                                                                            colSpan={
                                                                                7
                                                                            }
                                                                            align="right"
                                                                        >
                                                                            <div className="flex items-center justify-end space-x-10 text-xl font-semibold mr-9">
                                                                                <p>
                                                                                    Total:
                                                                                </p>
                                                                                <p>
                                                                                    {
                                                                                        totalOPD
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            ) : (
                                                                <TableRow>
                                                                    <TableCell
                                                                        colSpan={
                                                                            7
                                                                        }
                                                                        align="center"
                                                                    >
                                                                        No data
                                                                        available
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                        ) : null}

                                        {collectionData?.result?.mots?.length >
                                        0 ? (
                                            <>
                                                <Typography variant="h6" py={1}>
                                                    Minor OT Report
                                                </Typography>
                                                <TableContainer
                                                    sx={{
                                                        height: "auto",
                                                    }}
                                                >
                                                    <Table
                                                        size="small"
                                                        stickyHeader
                                                        aria-label="sticky table"
                                                    >
                                                        <TableHead>
                                                            <TableRow>
                                                                {MotHeader.map(
                                                                    (
                                                                        column
                                                                    ) => (
                                                                        <TableCell
                                                                            key={
                                                                                column.id
                                                                            }
                                                                            align={
                                                                                column.align
                                                                            }
                                                                            style={{
                                                                                minWidth:
                                                                                    column.minWidth,
                                                                                width: column.width,
                                                                                fontWeight:
                                                                                    "bold",
                                                                                textTransform:
                                                                                    "uppercase",
                                                                                borderBottom:
                                                                                    "4px solid black",
                                                                                borderLeft: 0,
                                                                                borderTop: 0,
                                                                                borderRight: 0,
                                                                            }}
                                                                        >
                                                                            {
                                                                                column.label
                                                                            }
                                                                        </TableCell>
                                                                    )
                                                                )}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {collectionData
                                                                ?.result?.mots
                                                                ?.length > 0 ? (
                                                                <>
                                                                    {collectionData?.result?.mots?.map(
                                                                        (
                                                                            mot
                                                                        ) => (
                                                                            <TableRow
                                                                                key={
                                                                                    mot.id
                                                                                }
                                                                            >
                                                                                <TableCell align="center">
                                                                                    {
                                                                                        mot.hid
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="center"
                                                                                    className="capitalize"
                                                                                >
                                                                                    {
                                                                                        mot.patient_title
                                                                                    }{" "}
                                                                                    {
                                                                                        mot.patient_name
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {
                                                                                        mot.service_names
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="center"
                                                                                    className="capitalize"
                                                                                >
                                                                                    {
                                                                                        mot.doctor_name
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {
                                                                                        mot.payable_amount
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {dayjs(
                                                                                        mot.created_on
                                                                                    ).format(
                                                                                        "DD-MM-YYYY,h:mm:ss A"
                                                                                    )}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    )}
                                                                    <TableRow>
                                                                        <TableCell
                                                                            colSpan={
                                                                                6
                                                                            }
                                                                            align="right"
                                                                        >
                                                                            <div className="flex items-center justify-end space-x-10 text-xl font-semibold mr-9">
                                                                                <p>
                                                                                    Total:
                                                                                </p>
                                                                                <p>
                                                                                    {
                                                                                        totalMot
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            ) : (
                                                                <TableRow>
                                                                    <TableCell
                                                                        colSpan={
                                                                            6
                                                                        }
                                                                        align="center"
                                                                    >
                                                                        No data
                                                                        available
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                        ) : null}
                                    </section>
                                </div>
                            </section>

                            <div className="w-[210mm] flex space-x-10 items-center pt-4">
                                <button
                                    onClick={useReactToPrint({
                                        content: () => billRef.current,
                                    })}
                                    className={`text-sm bg-green-500 text-white px-3 py-1.5 rounded shadow-lg-md`}
                                >
                                    Print
                                </button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default DoctorCollectionReport;
