import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { addBed, deleteBed, getBeds, updateBed } from "../../../services/api";

export function useGetBeds(params = {
    bed_number: "",
    offset: 0,
    limit: 10,
}) {
    const { bed_number, offset, limit } = params;

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.beds, params],
        queryFn: () => getBeds(params),
    });

    return { bedsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
};

export function useAddBed() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (bedData) => addBed(bedData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.beds] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdBeds] });
            if (data?.success) {
                showNotification("success", `Bed added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addBed: mutate, isLoading };
}

export function useEditBed() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updateBed(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.beds] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdBeds] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { editBed: mutate, isLoading };
}

export function useDeleteBed() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (bedId) => deleteBed(bedId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.beds] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdBeds] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteBed: mutate, isLoading };
}
