import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Box, Modal, Tooltip, Typography } from "@mui/material";
import {
    useGetClinic,
    useUpdateClinic,
} from "../query/hooks/clinic/clinicHook";
import { getItem, setItem, showNotification } from "../services/helper";
import { skynet, uploadFile } from "../services/api";
import LoaderLogo from "./common/LoaderLogo";
import { useSelector } from "react-redux";
import {
    selectCurrentAuth,
    selectCurrentClinic,
} from "../redux/features/auth/authSlice";
import ReusableButton from "./common/ReusableButton";
import RedButton from "./common/RedButton";
const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
        width: 0,
    },
};
const ClinicProfile = () => {
    const currentAuth = useSelector(selectCurrentAuth);
    const clinicPermission = currentAuth?.user?.permissions.find(
        (permission) => permission.module === 1005
    );
    const { add_permission, update_permission, delete_permission } =
        clinicPermission;

    const user = getItem("user");
    const [enableEdit, setEnableEdit] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [entity, setEntity] = useState("");
    const [isFileUploading, setIsFileUploading] = useState(false);
    const currentClinic = useSelector(selectCurrentClinic);
    const [imageUrl, setImageUrl] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [image, setImage] = useState(null);
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [open, setOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setImage(null);
        setSelectedImage(null);
        setOpen(false);
    };
    const [clinicInfo, setClinicInfo] = useState({
        created_on: "",
        name: "",
        contact: "",
        type: "",
        address: "",
        city: "",
        state: "",
        pincode: null,
        logo: null,
        gst: null,
        reg_no: null,
    });

    useEffect(() => {
        if (!currentClinic && user) {
            setEntity(user.entity);
        }
    }, [currentClinic]);

    useEffect(() => {
        if (currentClinic) {
            setClinicInfo({
                ...clinicData.result,
            });
        }
    }, [currentClinic]);

    const { clinicData, isLoading } = useGetClinic();

    const { isLoading: isUpdating, updateClinic } =
        useUpdateClinic(setEnableEdit);

    // const uploadImage = async (img) => {
    //     try {
    //         setIsUploading(true);
    //         const formData = new FormData();
    //         formData.append("file", img);
    //         const response = await uploadFile(formData);
    //         if (response.status === 200 && response.data.success) {
    //             setIsUploading(false);
    //             setImage(null);
    //             return response.data.result;
    //         }
    //         showNotification("success", "Image uploaded successfully");
    //     } catch (error) {
    //         console.error(error);
    //         showNotification("error", "Cannot Upload Image");
    //         return null;
    //     }
    // };

    const handleEditToggle = () => {
        setEnableEdit((prev) => !prev);
    };

    const handleChange = (field) => (event) => {
        setClinicInfo((prev) => ({
            ...prev,
            [field]: event.target.value,
        }));
    };

    const handleValidation = () => {
        const requiredFields = [
            "name",
            "email",
            "type",
            "address",
            "city",
            "state",
        ];
        const emptyFields = requiredFields.filter(
            (field) => !clinicInfo[field] || clinicInfo[field] === ""
        );
        return emptyFields.length === 0;
    };

    const handleImageValidation = () => {
        const requiredFields = [
            "name",
            "email",
            "type",
            "address",
            "city",
            "state",
        ];
        const emptyFields = requiredFields.filter(
            (field) => !clinicInfo[field] || clinicInfo[field] === ""
        );
        console.log("Empty fields:", emptyFields);
        return emptyFields.length === 0;
    };

    // const handleFileChange = async (e) => {
    //     setIsFileUploading(true);
    //     const file = e.target.files[0];
    //     try {
    //         if (handleImageValidation()) {
    //             const url = await uploadImage(file);
    //             if (url) {
    //                 setImageUrl(url);
    //                 updateClinic({
    //                     id: user.entity,
    //                     body: { ...clinicInfo, logo: url },
    //                 });
    //             }
    //         } else {
    //             setEnableEdit(false);
    //             showNotification("error", "Make sure all fields are filled.");
    //         }
    //         setIsFileUploading(false);
    //     } catch (error) {
    //         console.warn(error);
    //         setIsFileUploading(false);
    //     }
    // };

    const handleSubmit = () => {
        if (handleValidation()) {
            updateClinic({ body: clinicInfo });
        } else {
            showNotification("error", "Please check if all fields are filled.");
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        previewImage(file);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        previewImage(file);
    };

    const previewImage = (file) => {
        if (file && file.type && /^image\/(jpeg|jpg|png)$/.test(file.type)) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setSelectedImage(null);
            showNotification(
                "error",
                "Please select a valid JPEG, JPG, or PNG image file."
            );
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const uploadImage = async () => {
        if (!image) return;

        try {
            setIsUploading(true);
            const formData = new FormData();
            formData.append("file", image);
            const response = await uploadFile(formData);
            if (response.status === 200 && response.data.success) {
                setUploadedImageUrl(response.data.result);
                setTimeout(() => {
                    const updatedClinic = {
                        ...clinicInfo,
                        logo: response.data.result,
                    };
                    updateClinic({ id: user.entity, body: updatedClinic });
                    setIsUploading(false);
                    setSelectedImage(null);
                }, 3000);
                handleClose();
                showNotification("success", "Image uploaded successfully");
            }
        } catch (error) {
            console.error(error);
            setIsUploading(false);
            showNotification("error", "Cannot Upload Image");
        }
    };
    return (
        <>
            <div className="relative flex items-center justify-center h-screen p-10 overflow-y-scroll">
                <Paper
                    elevation={22}
                    className="p-8 space-y-6 rounded-md shadow-md "
                    // style={{
                    //     height: "auto",
                    //     padding: 12,
                    //     mt: 10,
                    //     display: "flex",
                    //     flexDirection: "column",
                    //     alignItems: "center",
                    //     boxShadow: 24,
                    //     borderRadius: "5px",
                    //     marginTop: 30,
                    //     paddingTop: 10,
                    //     marginBottom: 10,
                    //     width: "700px",
                    // }}
                    sx={{
                        boxShadow: 24,
                        borderRadius: "5px",
                        marginTop: 30,
                        paddingTop: 10,
                        marginBottom: 10,
                        width: "700px",
                    }}
                >
                    <section className="flex justify-center items-center">
                        <div
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            className="relative inline-block"
                        >
                            <Avatar
                                alt="profile"
                                // src={editedFields.image || ""}
                                src={
                                    `${skynet}/${clinicData?.result?.logo}` ||
                                    ""
                                }
                                sx={{ width: 256, height: 256 }}
                                className="object-contain"
                            />
                            {isHovered && (
                                <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full transition-opacity duration-300 bg-black bg-opacity-50 rounded-full">
                                    <Tooltip
                                        title="Upload New Image"
                                        placement="bottom-start"
                                    >
                                        <EditIcon
                                            onClick={handleOpen}
                                            style={{
                                                color: "white",
                                                fontSize: 42,
                                            }}
                                            className="duration-300 cursor-pointer"
                                        />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    </section>

                    {update_permission === 1 && (
                        <div className="flex justify-end gap-4 mb-4">
                            <Button
                                variant="outlined"
                                onClick={handleEditToggle}
                                disabled={isUpdating}
                                style={{
                                    color: enableEdit ? "red" : "", // Text color
                                    borderColor: enableEdit ? "red" : "", // Border color
                                }}
                            >
                                {enableEdit ? "Cancel" : "Edit"}
                            </Button>
                            {enableEdit && (
                                <Button
                                    disabled={isUpdating}
                                    variant="outlined"
                                    onClick={handleSubmit}
                                    className="animate-scale-in-center "
                                >
                                    Save
                                </Button>
                            )}
                        </div>
                    )}
                    <section className="grid grid-cols-2 gap-5">
                        <TextField
                            label="Clinic Name"
                            variant="outlined"
                            fullWidth
                            disabled={!enableEdit}
                            value={clinicInfo.name}
                            onChange={handleChange("name")}
                        />
                        <TextField
                            label="Address"
                            variant="outlined"
                            fullWidth
                            disabled={!enableEdit}
                            value={clinicInfo.address}
                            onChange={handleChange("address")}
                        />
                        <TextField
                            label="City"
                            variant="outlined"
                            fullWidth
                            disabled={!enableEdit}
                            value={clinicInfo.city}
                            onChange={handleChange("city")}
                        />

                        <TextField
                            label="Pincode"
                            variant="outlined"
                            fullWidth
                            disabled={!enableEdit}
                            value={clinicInfo.pincode || ""}
                            inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                maxLength: 6,
                            }}
                            onInvalid={(e) => {
                                e.preventDefault();
                                showNotification(
                                    "error",
                                    "Please enter a valid number."
                                );
                            }}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                );
                            }}
                            onChange={handleChange("pincode")}
                        />

                        <TextField
                            label="State"
                            variant="outlined"
                            fullWidth
                            disabled={!enableEdit}
                            value={clinicInfo.state}
                            onChange={handleChange("state")}
                        />

                        <TextField
                            label="Registration Number"
                            variant="outlined"
                            fullWidth
                            disabled={!enableEdit}
                            value={(clinicInfo.reg_no || "").toUpperCase()}
                            onChange={(e) => {
                                const sanitizedValue = e.target.value
                                    .replace(/[^a-zA-Z0-9]/g, "")
                                    .slice(0, 15);
                                handleChange("reg_no")({
                                    target: { value: sanitizedValue },
                                });
                            }}
                        />

                        <TextField
                            label="Phone Number"
                            variant="outlined"
                            fullWidth
                            disabled={!enableEdit}
                            value={clinicInfo.contact || ""}
                            onChange={handleChange("contact")}
                            inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                maxLength: 10,
                            }}
                            onInvalid={(e) => {
                                e.preventDefault();
                                showNotification(
                                    "error",
                                    "Please enter a valid number."
                                );
                            }}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                );
                            }}
                        />

                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            disabled={!enableEdit}
                            value={clinicInfo.email || ""}
                            onChange={handleChange("email")}
                        />
                        <TextField
                            label="GST"
                            variant="outlined"
                            fullWidth
                            disabled={!enableEdit}
                            value={(clinicInfo.gst || "").toUpperCase()}
                            onChange={(e) => {
                                const sanitizedValue = e.target.value
                                    .replace(/[^a-zA-Z0-9]/g, "")
                                    .slice(0, 15);
                                handleChange("gst")({
                                    target: { value: sanitizedValue },
                                });
                            }}
                        />

                        <TextField
                            label="Type"
                            variant="outlined"
                            fullWidth
                            disabled={!enableEdit}
                            value={clinicInfo.type || ""}
                            onChange={handleChange("type")}
                        />
                    </section>
                </Paper>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography textAlign="center" variant="h5">
                        Upload New Image
                    </Typography>
                    {isUploading ? (
                        <div className="flex items-start justify-center mt-8">
                            <LoaderLogo />
                        </div>
                    ) : (
                        <>
                            <div className="flex flex-wrap justify-center items-center gap-4">
                                {selectedImage ? null : (
                                    <div
                                        className="p-4 border w-[200px] h-[200px] border-gray-400 border-dashed rounded-lg cursor-pointer"
                                        onClick={() => {
                                            document
                                                .getElementById("fileInput")
                                                .click();
                                        }}
                                        onDrop={handleDrop}
                                        onDragOver={handleDragOver}
                                    >
                                        <input
                                            type="file"
                                            accept="image/jpeg,image/jpg,image/png"
                                            onChange={handleImageChange}
                                            id="fileInput"
                                            className="hidden"
                                        />
                                        <p className="text-center">
                                            Click to open file manager or drag &
                                            drop file here
                                        </p>
                                    </div>
                                )}
                                {selectedImage && (
                                    <div className="flex items-center justify-center p-2">
                                        <img
                                            src={selectedImage}
                                            alt="Selected"
                                            className="object-contain object-center w-[256px]  h-[256px] border-2 border-blue-400 rounded-full"
                                            // style={{ w-[200px] }}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="flex items-center justify-around my-5">
                                <ReusableButton
                                    title="Upload"
                                    onClick={uploadImage}
                                    isDisabled={isUploading || !selectedImage}
                                />
                                <RedButton
                                    title="Cancel"
                                    onClick={handleClose}
                                />
                            </div>
                        </>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default ClinicProfile;
