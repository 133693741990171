import {
    Autocomplete,
    Backdrop,
    Box,
    Fade,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useGetMetaData } from "../query/hooks/metadata/metadataHook";
import {
    useAddEmployee,
    useDeleteEmployee,
    useEditEmployee,
    useGetEmployees,
} from "../query/hooks/employee/employeeHooks";
import {
    chunkArray,
    showNotification,
    validateAddEmployee,
} from "../services/helper";
import dayjs from "dayjs";
import {
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineReload,
} from "react-icons/ai";
import LoaderLogo from "./common/LoaderLogo";
import { IoPersonAdd } from "react-icons/io5";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import {
    clearEmployees,
    selectEmployeesParam,
    selectSelectedEmployeesOption,
    setEmployeesParams,
} from "../redux/features/filters/employeeSlice";
import ReusableButton from "./common/ReusableButton";
import ClearButton from "./common/ClearButton";
import RedButton from "./common/RedButton";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const initial = {
    name: "",
    address: "",
    email: "",
    employee_id: "",
    mobile: "",
    designation: "",
    department: "",
    aadhaar: "",
};
const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -40%)",
    minWidth: 500,
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};
const columns = [
    { id: "employee_id", label: "Employee ID", minWidth: 120, align: "left" },

    { id: "name", label: "Name", minWidth: 150, align: "left" },
    { id: "designation", label: "Designation", minWidth: 150, align: "left" },
    {
        id: "department",
        label: "Department",
        minWidth: 150,
        align: "left",
    },
    { id: "mobile", label: "Mobile", minWidth: 170, align: "left" },
    { id: "email", label: "Email", align: "left" },

    { id: "actions", label: "Actions", minWidth: 200, align: "center" },
];

const Employee = () => {
    const dispatch = useDispatch();
    const currentAuth = useSelector(selectCurrentAuth);
    const employeeParams = useSelector(selectEmployeesParam);
    const selectedEmp = useSelector(selectSelectedEmployeesOption);
    const [dataToRender, setDataToRender] = useState([]);
    const { metaData } = useGetMetaData();
    const {
        employeesData,
        isLoading,
        isSuccess,
        isFetching,
        refetch: refetchEmployee,
        isRefetching,
    } = useGetEmployees(employeeParams);
    const employeePermission = currentAuth?.user?.permissions.find(
        (permission) => permission.module === 1005
    );
    const { add_permission, update_permission, delete_permission } =
        employeePermission;

    // useEffect(() => {
    //     if (employeesData) {
    //         const arr = chunkArray(employeesData?.result?.list, 10);
    //         setDataToRender(
    //             arr[employeeParams?.offset / employeeParams?.limit]
    //         );
    //     }
    // }, [employeesData, employeeParams.offset]);

    // useEffect(() => {
    //     setCurrentPage(employeeParams.offset / employeeParams.limit + 1);
    // }, [employeeParams]);

    const { addEmployee, isLoading: isAddingEmployee } = useAddEmployee(); // Use the custom hook for adding an employee
    const { editEmployee, isLoading: isEditingEmployee } = useEditEmployee(); // Use the custom hook for editing an employee
    const { deleteEmployee, isLoading: isDeletingEmployee } =
        useDeleteEmployee();

    const [employee, setEmployee] = useState({ ...initial });
    const [valErr, setValErr] = useState([]);
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [editOpen, setEditOpen] = useState(false);
    const [editedEmployee, setEditedEmployee] = useState("");

    const handleEditClick = (row) => {
        setEditedEmployee(row); // Set the role to be edited
        setEditOpen(true); // Open the edit modal
    };
    const handleClose = () => {
        setOpen(false);
        setEmployee({ ...initial });
        setValErr([]);
    };
    const handleOpen = () => setOpen(true);
    const handleCancel = () => {
        setEmployee("");
        handleClose();
        setValErr([]);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;

        let transformedValue = value;

        if (name === "mobile") {
            transformedValue = value.replace(/\D/g, "").slice(0, 10);
        }

        setEmployee((prevEmployee) => ({
            ...prevEmployee,
            [name]: transformedValue,
        }));
    };

    const handleAdd = () => {
        const emptyFields = validateAddEmployee(employee);
        setValErr(emptyFields);

        if (emptyFields.length > 0) {
            // Handle empty fields, e.g., display error messages to the user

            const capitalizeFirstLetter = (string) => {
                return string.charAt(0).toUpperCase() + string.slice(1);
            };

            const capitalizedFields = emptyFields.map((fieldName) =>
                fieldName === "employee_id"
                    ? "Employee ID"
                    : capitalizeFirstLetter(fieldName)
            );
            const missingFieldsMessage = capitalizedFields
                .join(", ")
                .replace(/, ([^,]*)$/, " and $1");
            showNotification(
                "error",
                `Fill the ${missingFieldsMessage} field${
                    capitalizedFields.length > 1 ? "s" : ""
                }.`
            );
        } else {
            addEmployee(employee);
            setEmployee({ ...initial });
            handleClose();
        }
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedEmployee({
            ...editedEmployee,
            [name]: value,
        });
    };

    const handleEditSave = (row) => {
        const emptyFields = validateAddEmployee(editedEmployee);
        if (emptyFields.length > 0) {
            const capitalizeFirstLetter = (string) => {
                return string.charAt(0).toUpperCase() + string.slice(1);
            };
            const capitalizedFields = emptyFields.map((fieldName) =>
                capitalizeFirstLetter(fieldName)
            );
            const missingFieldsMessage = capitalizedFields
                .join(", ")
                .replace(/, ([^,]*)$/, " and $1");
            showNotification(
                "error",
                `Fill the ${missingFieldsMessage} field${
                    capitalizedFields.length > 1 ? "s" : ""
                }.`
            );
        } else {
            editEmployee(editedEmployee);

            setEditOpen(false);
        }
    };
    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleDeleteConfirmed = () => {
        deleteEmployee(toDelete);
        handleCloseDeleteDialog();
    };
    // State and State Update Functions
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState({
        name: "",
        email: "",
        mobile: "",
        department: "",
    });
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState("");

    // Search and Pagination Functions
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            [name]: value,
        }));
    };

    const isValidMobileNumber = (mobile) => {
        // Remove non-numeric characters
        const cleanedMobile = mobile.replace(/\D/g, "");

        // Check if the cleaned mobile number has exactly 10 digits
        return cleanedMobile.length === 10;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if mobile number is valid
        const isMobileValid = isValidMobileNumber(searchParams.mobile);

        // Check if at least one field is filled
        const atLeastOneFieldFilled =
            searchParams.name.trim() ||
            searchParams.email.trim() ||
            searchParams.department ||
            isMobileValid;

        if (atLeastOneFieldFilled) {
            dispatch(
                setEmployeesParams({
                    ...employeeParams,
                    name: searchParams.name || "",
                    email: searchParams.email || "",
                    department: searchParams.department || "",
                    mobile: isMobileValid ? searchParams.mobile : "",
                })
            );
        } else {
            // Handle case where no field is filled
            showNotification("error", "Please fill in at least one field.");
        }
    };

    // Pagination Functions
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setEmployeesParams({
                ...employeeParams,
                offset: (value - 1) * employeeParams.limit,
            })
        );
    };

    // Clear and Reset Functions
    const clearSearch = () => {
        setSelectedOption(null);
        setInputValue("");
        dispatch(clearEmployees());
        setSearchParams({
            name: "",
            email: "",
            mobile: "",
            department: "",
        });
    };

    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            department: parseInt(newValue.id),
        }));
    };

    const handleInputChange2 = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleClearDept = () => {
        setSelectedOption(null);
        setInputValue("");
        dispatch(
            setEmployeesParams({
                ...employeeParams,
                department: "",
            })
        );
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSubmit(event);
        }
    };
    const handleKeyPressClear = (event) => {
        if (event.key === "Escape") {
            clearSearch();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", handleKeyPressClear);
        return () => {
            document.removeEventListener("keydown", handleKeyPressClear);
        };
    }, []);
    return (
        <>
            <section>
                <div className="flex justify-between px-10 mb-5">
                    <div>
                        <div className="flex items-center space-x-3">
                            <Typography variant="h5">Employees</Typography>
                            <Tooltip title="Refresh" placement="bottom-start">
                                <button
                                    onClick={refetchEmployee}
                                    className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                    style={{ cursor: "pointer" }}
                                >
                                    <AiOutlineReload size={20} />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    {/* filter&Search */}
                    <div className="flex items-center">
                        <div className="w-[100%] ml-4"></div>
                    </div>
                    {add_permission === 1 && (
                        <Tooltip title="Add Employee" placement="bottom-start">
                            <button
                                onClick={handleOpen}
                                className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                            >
                                <IoPersonAdd size={18} />
                            </button>
                        </Tooltip>
                    )}
                </div>
                <div className="w-[100%] my-4">
                    <form
                        onSubmit={handleSubmit}
                        onKeyPress={handleKeyPress}
                        className="flex items-center space-x-5"
                    >
                        <TextField
                            label="Name"
                            variant="outlined"
                            name="name"
                            value={searchParams.name}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Email"
                            variant="outlined"
                            name="email"
                            value={searchParams.email}
                            onChange={handleInputChange}
                        />
                        <TextField
                            label="Phone Number"
                            variant="outlined"
                            name="mobile"
                            value={searchParams.mobile}
                            onChange={handleInputChange}
                            inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*", // Allow only numeric input
                                maxLength: 10,
                            }}
                            onInvalid={(e) => {
                                e.preventDefault(); // Prevent the default validation message
                                showNotification(
                                    "error",
                                    "Please enter a valid number."
                                );
                            }}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                ); // Remove non-numeric characters
                            }}
                        />

                        <Autocomplete
                            className="w-[250px]"
                            options={metaData?.result?.departments || []}
                            getOptionLabel={(option) => option.name}
                            value={selectedOption}
                            onChange={handleOptionChange}
                            inputValue={inputValue}
                            onInputChange={handleInputChange2}
                            disableClearable
                            isOptionEqualToValue={(option, value) =>
                                option.id === value?.id
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Department"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {selectedOption && (
                                                    <ClearIcon
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={
                                                            handleClearDept
                                                        }
                                                    />
                                                )}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <ReusableButton
                            title="Search"
                            onClick={handleSubmit}
                            type="submit"
                        />

                        <ClearButton title="Clear" onClick={clearSearch} />
                    </form>
                </div>
                <section>
                    <Paper
                        sx={{
                            width: "100%",
                            overflow: "scroll",
                            borderRadius: "15px",
                        }}
                        elevation={24}
                    >
                        <TableContainer
                            sx={{
                                height: 520,
                                overflowX: "scroll",
                                "&::-webkit-scrollbar": {
                                    width: 0,
                                },
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                sx={{
                                                    ...(column.label ===
                                                        "Employee ID" && {
                                                        paddingX: 5,
                                                    }),
                                                }}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    fontWeight: "bold",
                                                    background: "#eeeeee",
                                                    textTransform: "uppercase",
                                                    backgroundColor: "#27272A",
                                                    color: "white",
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {!isLoading && !isFetching && !isRefetching ? (
                                    <TableBody>
                                        {/* {employeesData?.result?.list?.map( */}
                                        {employeesData?.result?.list?.map(
                                            (row) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        key={row.id}
                                                        // align="left"
                                                    >
                                                        <TableCell
                                                            align="left"
                                                            sx={{
                                                                paddingX: 5,
                                                            }}
                                                        >
                                                            {row.employee_id}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                row.designation_name
                                                            }
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                row.department_name
                                                            }
                                                        </TableCell>
                                                        {/* <TableCell align="left">
                                                            {row.entity_name}
                                                        </TableCell> */}
                                                        <TableCell align="left">
                                                            {row.mobile}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row.email}
                                                        </TableCell>
                                                        {/* <TableCell align="center">
                                                            {dayjs(
                                                                row.created_on
                                                            ).format("l")}
                                                        </TableCell> */}

                                                        <TableCell
                                                            align="center"
                                                            sx={{
                                                                width: 120,
                                                            }}
                                                        >
                                                            <div className="flex items-center justify-center space-x-4">
                                                                {update_permission ===
                                                                    1 && (
                                                                    <Tooltip
                                                                        title="Edit"
                                                                        placement="bottom-start"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                handleEditClick(
                                                                                    row
                                                                                )
                                                                            }
                                                                            className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                        >
                                                                            <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                                        </button>
                                                                    </Tooltip>
                                                                )}
                                                                {delete_permission ===
                                                                    1 && (
                                                                    <Tooltip
                                                                        title="Delete"
                                                                        placement="bottom-start"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                handleOpenDeleteDialog(
                                                                                    row.id
                                                                                )
                                                                            }
                                                                            className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                                        >
                                                                            <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                                        </button>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                colSpan={9}
                                                align="center"
                                            >
                                                <div className="flex items-center justify-center w-full">
                                                    <LoaderLogo />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                        {!isLoading && !isFetching && !isRefetching && (
                            <div className="flex items-center justify-center my-4">
                                <Pagination
                                    count={Math.ceil(
                                        employeesData?.result?.count /
                                            employeeParams?.limit || 0
                                    )}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    sx={{
                                        "& .MuiSelect-select": {
                                            display: "none !important",
                                        },
                                        "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                            {
                                                display: "none !important",
                                            },
                                    }}
                                />
                            </div>
                        )}
                    </Paper>
                </section>
            </section>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            Add New Employee
                        </Typography>
                        <div className="grid grid-cols-2 gap-5">
                            <TextField
                                required
                                autoFocus
                                margin="dense"
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                fullWidth
                                value={employee.name}
                                onChange={handleChange}
                                helperText={
                                    valErr.includes("name")
                                        ? "Name is required."
                                        : undefined
                                }
                                error={valErr.includes("name")}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="address"
                                name="address"
                                label="Address"
                                type="text"
                                fullWidth
                                helperText={
                                    valErr.includes("address")
                                        ? "Address is required."
                                        : undefined
                                }
                                value={employee.address}
                                onChange={handleChange}
                                error={valErr.includes("address")}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="email"
                                name="email"
                                label="Email"
                                type="text"
                                fullWidth
                                helperText={
                                    valErr.includes("email")
                                        ? "Email is required."
                                        : undefined
                                }
                                value={employee.email}
                                error={valErr.includes("email")}
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="employee_id"
                                name="employee_id"
                                label="Employee ID"
                                type="text"
                                fullWidth
                                helperText={
                                    valErr.includes("employee_id")
                                        ? "Employee Id Number is required."
                                        : undefined
                                }
                                value={employee.employee_id}
                                error={valErr.includes("employee_id")}
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="mobile"
                                name="mobile"
                                label="Mobile"
                                type="text"
                                fullWidth
                                helperText={
                                    valErr.includes("mobile")
                                        ? "Mobile Number is required."
                                        : undefined
                                }
                                value={employee.mobile}
                                error={valErr.includes("mobile")}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Aadhaar"
                                variant="outlined"
                                name="aadhaar"
                                id="aadhaar"
                                onChange={handleChange}
                                inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*", // Allow only numeric input
                                    maxLength: 12,
                                }}
                                onInvalid={(e) => {
                                    e.preventDefault(); // Prevent the default validation message
                                    showNotification(
                                        "error",
                                        "Please enter a valid Aadhaar."
                                    );
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                    ); // Remove non-numeric characters
                                }}
                                fullWidth
                                helperText={
                                    valErr.includes("mobile")
                                        ? "Mobile Number is required."
                                        : undefined
                                }
                                value={employee.aadhaar}
                                error={valErr.includes("aadhaar")}
                            />
                            <FormControl
                                fullWidth
                                margin="dense"
                                error={valErr.includes("designation")}
                            >
                                <InputLabel id="designation-label">
                                    Designation
                                </InputLabel>
                                <Select
                                    required
                                    labelId="designation-label"
                                    id="designation"
                                    label="Designation"
                                    name="designation"
                                    value={employee.designation || ""}
                                    onChange={handleChange}
                                >
                                    {metaData?.result?.designations?.map(
                                        (designation) => (
                                            <MenuItem
                                                key={designation.id}
                                                value={designation.id || ""}
                                                className="capitalize"
                                            >
                                                {designation.name}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                <FormHelperText>
                                    {valErr.includes("designation")
                                        ? "Designation is required."
                                        : undefined}
                                </FormHelperText>
                            </FormControl>
                            <FormControl
                                fullWidth
                                margin="dense"
                                error={valErr.includes("department")}
                            >
                                <InputLabel id="department-label">
                                    Department
                                </InputLabel>
                                <Select
                                    required
                                    labelId="department-label"
                                    id="department"
                                    label="Department"
                                    name="department"
                                    value={employee.department}
                                    onChange={handleChange}
                                >
                                    {metaData?.result?.departments?.map(
                                        (department) => (
                                            <MenuItem
                                                key={department.id}
                                                value={department.id}
                                                className="capitalize"
                                            >
                                                {department.name}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                <FormHelperText>
                                    {valErr.includes("department")
                                        ? "Department is required."
                                        : undefined}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="flex justify-between p-6">
                            <ReusableButton
                                isDisabled={isAddingEmployee}
                                onClick={handleAdd}
                                title="Save"
                            />

                            <RedButton
                                isDisabled={isAddingEmployee}
                                onClick={handleCancel}
                                title="Cancel"
                            />
                        </div>
                    </Box>
                </Fade>
            </Modal>

            {/* <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this role? This action
                        cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmed} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog> */}
            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeletingEmployee}
            />
            <Modal
                open={editOpen}
                onClose={() => setEditOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={editOpen}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            Edit Employee
                        </Typography>
                        <div className="grid grid-cols-2 gap-5 p-4 ">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="editedEmployee"
                                label="Name"
                                type="text"
                                fullWidth
                                value={editedEmployee.name}
                                name="name"
                                onChange={handleEditChange}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="Mobile"
                                label="Mobile"
                                type="text"
                                fullWidth
                                value={editedEmployee.mobile} // Set the initial value to the role name
                                name="mobile"
                                onChange={handleEditChange}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="email"
                                label="Email"
                                type="text"
                                fullWidth
                                value={editedEmployee.email} // Set the initial value to the role name
                                name="email"
                                onChange={handleEditChange}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="Employee Id"
                                label="Employee Id"
                                type="text"
                                fullWidth
                                value={editedEmployee.employee_id} // Set the initial value to the role name
                                name="employee_id"
                                onChange={handleEditChange}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="aadhaar"
                                label="Aadhaar"
                                type="text"
                                fullWidth
                                className="col-span-2"
                                value={editedEmployee.aadhaar} // Set the initial value to the role name
                                name="aadhaar"
                                onChange={handleEditChange}
                                inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*", // Allow only numeric input
                                    maxLength: 12,
                                }}
                                onInvalid={(e) => {
                                    e.preventDefault(); // Prevent the default validation message
                                    showNotification(
                                        "error",
                                        "Please enter a valid Aadhaar."
                                    );
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                    ); // Remove non-numeric characters
                                }}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="address"
                                label="Address"
                                type="text"
                                fullWidth
                                className="col-span-2"
                                value={editedEmployee.address} // Set the initial value to the role name
                                name="address"
                                onChange={handleEditChange}
                            />

                            <FormControl
                                fullWidth
                                margin="dense"
                                error={valErr.includes("designation")}
                            >
                                <InputLabel id="designation-label">
                                    Designation
                                </InputLabel>
                                <Select
                                    required
                                    labelId="designation-label"
                                    id="designation"
                                    label="Designation"
                                    name="designation"
                                    value={editedEmployee.designation || ""}
                                    onChange={handleEditChange}
                                >
                                    {metaData?.result?.designations?.map(
                                        (designation) => (
                                            <MenuItem
                                                key={designation.id}
                                                value={designation.id || ""}
                                                className="capitalize"
                                            >
                                                {designation.name}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                <FormHelperText>
                                    {valErr.includes("designation")
                                        ? "Designation is required."
                                        : undefined}
                                </FormHelperText>
                            </FormControl>
                            <FormControl
                                fullWidth
                                margin="dense"
                                error={valErr.includes("department")}
                            >
                                <InputLabel id="department-label">
                                    Department
                                </InputLabel>
                                <Select
                                    required
                                    labelId="department-label"
                                    id="department"
                                    label="Department"
                                    name="department"
                                    value={editedEmployee.department}
                                    onChange={handleEditChange}
                                >
                                    {metaData?.result?.departments?.map(
                                        (department) => (
                                            <MenuItem
                                                key={department.id}
                                                value={department.id}
                                                className="capitalize"
                                            >
                                                {department.name}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                <FormHelperText>
                                    {valErr.includes("department")
                                        ? "Department is required."
                                        : undefined}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="flex justify-between p-6">
                            <ReusableButton
                                isDisabled={isEditingEmployee}
                                onClick={handleEditSave}
                                title="Save"
                            />

                            <RedButton
                                isDisabled={isEditingEmployee}
                                onClick={() => setEditOpen(false)}
                                title="Cancel"
                            />
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default Employee;
