import {
    Autocomplete,
    Backdrop,
    Box,
    Fade,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import {
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineReload,
} from "react-icons/ai";
import { BiTestTube } from "react-icons/bi";
import { useGetMetaData } from "../query/hooks/metadata/metadataHook";
import { searchPatients } from "../services/api";
import {
    getEndingTimeOfDate,
    getPermissionsByModule,
    getStartingTimeOfDate,
    showNotification,
    validateAddPatient,
} from "../services/helper";
import { FaRegFile } from "react-icons/fa";
import LoaderLogo from "./common/LoaderLogo";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import {
    useAddPatient,
    useAddPatientEntity,
} from "../query/hooks/patients/patientsHook";
import { IoMdAdd } from "react-icons/io";
import ClearIcon from "@mui/icons-material/Clear";
import {
    clearTestReport,
    selectTestReportParams,
    setTestReportParams,
} from "../redux/features/filters/testReportSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReusableButton from "./common/ReusableButton";
import ClearButton from "./common/ClearButton";
import RedButton from "./common/RedButton";
import AddPatientForm from "./common/AddPatientForm";
import { useGetMOTServices } from "../query/hooks/minotServices/minorOTServices";
import MinotOtModal from "./common/MinotOtModal";
import {
    useAddMot,
    useDeleteMot,
    useEditMot,
    useGetMotbyiD,
    useGetMots,
} from "../query/hooks/mots/motsHook";
import { selectEmployeesParam } from "../redux/features/filters/employeeSlice";
import { useGetEmployees } from "../query/hooks/employee/employeeHooks";
import {
    clearMots,
    selectMotsParams,
    setMotsParams,
} from "../redux/features/filters/motsSlice";
import MotBill from "./common/MotBill";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-55%, -50%)",
    minWidth: 800,
    maxWidth: 1200,
    minHeight: 200,
    maxHeight: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
        width: 0,
    }, // Add this line for vertical scrolling
};
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 900,
    maxWidth: 1800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

const columns = [
    { id: "hid", label: "HID", minWidth: 100, align: "center" },
    { id: "name", label: "Name", minWidth: 100, align: "center" },
    {
        id: "total_amount",
        label: "Total Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "payable_amount",
        label: "Payable Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "createdDate",
        label: "Created Date",
        minWidth: 100,
        align: "center",
    },
    { id: "actions", label: "Actions", align: "center" },
];

const initialState = {
    patient: 0,
    total_amount: 0,
    discount_amount: 0,
    discount_percent: 0,
    payable_amount: 0,
    payment_mode: "",
    doctor: "",
    department: "",
    payment_reference_number: "",
    mot_services: [
        {
            minor_ot: 0,
            minor_ot_service: 0,
            charge: 0,
        },
    ],
};
const initialData = {
    aadhaar: "",
    title: "",
    name: "",
    mobile: "",
    email: "",
    age: 0,
    months: 0,
    days: 0,
    gender: "",
    marital_status: "",
    care_of: "",
    relationship: "",
    occupation: "",
    address: "",
    district: "",
    pincode: "",
    remarks: "",
};
const initialPatientState = {
    patient: 0,
    department: "",
    doctor: "",
    charge: 0.0,
    payable_amount: 0.0,
    payment_mode: "",
    payment_reference_number: "",
    discount_amount: 0.0,
    discount_percent: 0.0,
    discount_remark: "",
};
const initialPatientId = { aadhaar: "", hid: "" };
const initialLab = {
    patient: 0,
    total_amount: 0,
    discount_amount: 0,
    discount_percent: 0,
    payable_amount: 0,
    payment_mode: "",
    payment_reference_number: "",
    doctor: "",
    department: "",
    mot_services: [
        {
            minor_ot: 0,
            minor_ot_service: 0,
            charge: 0,
        },
    ],
};
const MinorOT = () => {
    const motParams = useSelector(selectMotsParams);
    const { employeesData } = useGetEmployees();
    const dispatch = useDispatch();

    const filteredDoctors = employeesData?.result?.list?.filter(
        (employee) => employee.designation_name === "Doctor"
    );
    const [selectedmot, setSelectedMot] = useState([]);
    const [labTest, setLabTest] = useState({ ...initialLab });
    const [motID, setMotID] = useState(null);
    const [ptID, setPtID] = useState();
    const [step, setStep] = useState(1);
    const [ptOpen, setPtOpen] = useState(false);
    const [patientId, setPatientId] = useState(initialPatientId);
    const [isAadharDisabled, setIsAadharDisabled] = useState(false);
    const [isHidDisabled, setIsHidDisabled] = useState(false);
    const [patient, setPatient] = useState({ ...initialData });
    const [openOPD, setOpenOPD] = useState(false);
    const [valErr, setValErr] = useState([]);
    const [ptExist, setPtExist] = useState(false);
    const [finalStep, setFinalStep] = useState(false);
    const [labTestId, setLabTestId] = useState("");
    const [mots, addMots] = useState({ ...initialState });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [editedData, setEditedData] = useState({});
    const [labList, setLabList] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [investigationID, setInvestigationID] = useState("");
    const [updatedReport, setUpdatedReport] = useState({
        test: "",
        investigation: "",
        report: "",
    });
    const [InvID, setInvID] = useState("");
    const [parentUploadedList, setParentUploadedList] = useState({});
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [isBillModalOpen, setIsBillModalOpen] = useState(false);
    const [selectedMOTGenBill, setSelectedMOTGenBill] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState({ name: "", hid: "" });
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedDept, setSelectedDept] = useState(null);
    const [inputDeptValue, setInputDeptValue] = useState("");
    const [editedSelectedDoctor, setEditedSelectedDoctor] = useState(null);
    const [editedSelectedDept, setEditedSelectedDept] = useState(null);
    const [editedInputDeptValue, setEditedInputDeptValue] = useState("");
    const [editOpen, setEditOpen] = useState(false);

    const {
        motServicesData,
        isSuccess: isMotDataReady,
        isLoading: isMOTLoading,
    } = useGetMotbyiD(motID);
    const [patientState, setPatientState] = useState({
        ...initialPatientState,
    });

    const handlePTOpen = () => setPtOpen(true);
    const handlePTClose = () => {
        setPatient({ ...initialData });
        setLabTest({ ...initialLab });
        setStep(1);
        setPtOpen(false);
        handleClear();
    };

    const labtestParamms = useSelector(selectTestReportParams);
    const currentAuth = useSelector(selectCurrentAuth);
    const { servicesData, isLoading: isMinorOtLoading } = useGetMOTServices();
    const {
        motsData,
        isLoading: isMotsLoading,
        isFetching,
        isRefetching,
        refetch: refetchMots,
    } = useGetMots(motParams);

    const {
        addMot,
        isLoading: isAddMotLoading,
        isSuccess,
    } = useAddMot(handlePTClose);

    const handleEditModalClose = () => {
        setEditOpen(false);
        setEditedData({});
    };
    const {
        editMot,
        isLoading: isEditMotLoading,
        isSuccess: isEditingSuccess,
    } = useEditMot(handleEditModalClose);

    const { deleteMot, isLoading: isDeleteMotLoading } = useDeleteMot();
    const permissionsList = currentAuth?.user?.permissions;
    const motsPermissions = getPermissionsByModule(1021, permissionsList);
    const {
        add_permission,
        update_permission,
        delete_permission,
        view_permission,
    } = motsPermissions;

    const { metaData } = useGetMetaData();

    useEffect(() => {
        if (editedData) {
            const discountAmount = parseFloat(editedData?.discount_amount);
            const totalAmount = parseFloat(editedData?.total_amount);

            // Check if discountAmount and totalAmount are valid numbers
            if (
                !isNaN(discountAmount) &&
                !isNaN(totalAmount) &&
                totalAmount > 0
            ) {
                // Ensure discount_amount is not greater than total_amount
                const validDiscountAmount = Math.min(
                    discountAmount,
                    totalAmount
                );

                if (validDiscountAmount !== discountAmount) {
                    // Log an error to the console when discount_amount is greater than total_amount
                    showNotification(
                        "error",
                        "Discount amount cannot be greater than total amount."
                    );
                }

                const discount_percentage =
                    (validDiscountAmount / totalAmount) * 100;

                // Calculate payable_amount after discount
                const discountedAmount = totalAmount - validDiscountAmount;

                setEditedData((prevData) => ({
                    ...prevData,
                    discount_amount: validDiscountAmount, // Update with valid discount amount
                    discount_percent: parseFloat(
                        discount_percentage.toFixed(2)
                    ),
                    payable_amount: discountedAmount,
                }));
            } else {
                setEditedData((prevData) => ({
                    ...prevData,
                    discount_amount: 0,
                    discount_percentage: 0,
                    payable_amount: 0,
                }));
            }
        }
    }, [editedData.discount_amount, editedData.total_amount]);

    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    // Function to handle the delete action when confirmed
    const handleDeleteConfirmed = () => {
        deleteMot(toDelete);
        if (isDeleteMotLoading) {
            setOpenDeleteDialog(false);
        }
    };

    const openModal = (row) => {
        setLabTestId(row.id);

        setInvestigationID(row);
        setModalOpen(true);
    };

    const handleOpenReportModal = (row) => {
        setInvID(row.id);
        setIsReportModalOpen(true);
    };
    const handleGenerateBill = (row) => {
        setSelectedMOTGenBill(row);
        setIsBillModalOpen(true);
    };

    const handleAddPatient = () => {
        const isValid = validateAddPatient(patient);
        if (isValid) {
            addPatient(patient);
            if (step === 3) {
                setPatient("");
                setPatient({ ...initialData });
                setLabTest((prevState) => ({
                    ...prevState,
                    patient: ptID,
                }));
                handleClear();
            }
        } else {
            showNotification(
                "error",
                "Make Sure All Required Field Are Filled"
            );
        }
    };
    useEffect(() => {
        if (ptID) {
            setLabTest((prevState) => ({
                ...prevState,
                patient: ptID,
            }));
        }
    }, [ptID]);

    const handleCloseOPD = () => {
        if (finalStep) {
            setPatientState({ ...initialPatientState });
            setValErr([]);
            setStep(1);
            setPtExist(false);
            handlePTClose();
        }
    };
    const {
        addPatient,
        isLoading: isAddingPatient,
        isSuccess: isAdded,
    } = useAddPatient(setPtID, handlePTClose, setStep);
    const {
        addPatientEntity,
        isLoading: isAddingPatientEntity,
        isSuccess: isAddedPtEntity,
    } = useAddPatientEntity(setStep);

    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;

        const numericValue =
            (name === "age" || name === "months" || name === "days") &&
            !isNaN(parseInt(value, 10))
                ? parseInt(value, 10)
                : name === "mobile"
                ? value.replace(/\D/g, "").slice(0, 10)
                : name === "aadhaar"
                ? value.replace(/\D/g, "").slice(0, 12)
                : value;

        setPatient((prevPatient) => ({
            ...prevPatient,
            [name]: numericValue,
        }));
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setPatient((prevPatient) => ({
            ...prevPatient,
            [name]: value,
        }));
    };

    const handleAadharChange = (e) => {
        const value = e.target.value;
        setPatientId((prev) => ({
            ...prev,
            aadhaar: value,
        }));
        setIsHidDisabled(value !== "");
    };

    const handleHidChange = (e) => {
        const value = e.target.value;
        setPatientId((prev) => ({
            ...prev,
            hid: value,
        }));
        setIsAadharDisabled(value !== "");
    };

    const handleClear = () => {
        setPatientId(initialPatientId);
        setIsAadharDisabled(false);
        setIsHidDisabled(false);
    };
    const handleSearchPatient = async () => {
        try {
            if (!patientId.aadhaar && !patientId.hid) {
                showNotification(
                    "error",
                    "Please provide either Aadhaar or HID."
                );
                return;
            }
            const result = await searchPatients(
                patientId.aadhaar,
                patientId.hid
            );

            if (result.success) {
                setPtExist(true);
                setLabTest((prevState) => ({
                    ...prevState,
                    patient: result.result.id,
                }));
                const updatedPatient = {
                    aadhar: result.result.aadhar || "",
                    title: result.result.title || "",
                    name: result.result.name || "",
                    mobile: result.result.mobile || "",
                    email: result.result.email || "",
                    age: result.result.age || 0,
                    months: result.result.months || 0,
                    days: result.result.days || 0,
                    gender: result.result.gender || "",
                    marital_status: result.result.marital_status || "",
                    care_of: result.result.care_of || "",
                    relationship: result.result.relationship || "",
                    occupation: result.result.occupation || "",
                    address: result.result.address || "",
                    district: result.result.district || "",
                    pincode: result.result.pincode || "",
                    remarks: result.result.remarks || "",
                };
                setPatient({ ...updatedPatient });
                const foundPt = {
                    patient: result.result.id,
                };
                addPatientEntity(foundPt);
                setStep(2);
            } else {
                setStep(2);
                setPtExist(false);
                showNotification("error", "Patient not Found, Add New Entry");

                setPatient({ ...initialData });
            }
        } catch (error) {
            console.error("Error occurred during search:", error);
        }
    };
    const handleChangeTests2 = (arr) => {
        const obj = { ...labTest };
        obj.mot_services = arr;
        setLabTest(obj);
    };

    const handleChangeLab2 = (e) => {
        const { name, value } = e.target;
        if (name === "tests" && labTest.total_amount === 0) {
            setLabTest((prevLabTest) => ({
                ...prevLabTest,
                discount_amount: 0,
                discount_percent: 0,
                payable_amount: 0,
                [name]: value,
            }));
        } else {
            setLabTest((prevLabTest) => ({
                ...prevLabTest,
                [name]: value,
            }));
        }
    };
    const handleNextStep = () => {
        setStep(3);
    };
    const handleAddLabTest = (e) => {
        e.preventDefault();

        const requiredFields = [
            "patient",
            "total_amount",
            "payable_amount",
            "payment_mode",
        ];

        const missingFields = requiredFields.filter((field) => !labTest[field]);

        if (missingFields.length > 0 || labTest.mot_services.length === 0) {
            const errorMessage =
                missingFields.length > 0
                    ? `${missingFields
                          .map(
                              (field) =>
                                  field.charAt(0).toUpperCase() +
                                  field.slice(1).replace(/_/g, " ")
                          )
                          .join(", ")} field${
                          missingFields.length > 1 ? "s" : ""
                      } is required`
                    : "At least one test is required";

            showNotification("error", errorMessage);
            return;
        }

        const areTestsValid = labTest.mot_services.every(
            (test) => test.id && test.charge
        );

        if (!areTestsValid) {
            showNotification("error", "Invalid test data");
            return;
        }

        const parsedState = {
            mot: {
                patient: parseInt(labTest.patient),
                doctor: parseInt(labTest.doctor),
                department: parseInt(labTest.department),
                total_amount:
                    labTest.mot_services.length > 0
                        ? parseFloat(labTest.total_amount)
                        : 0,
                discount_amount:
                    labTest.mot_services.length > 0
                        ? parseFloat(labTest.discount_amount)
                        : 0,
                discount_percent: parseFloat(labTest.discount_percent),
                payable_amount: parseFloat(labTest.payable_amount),
                payment_mode: labTest.payment_mode,
                payment_reference_number: labTest.payment_reference_number,
            },
            mot_services: labTest.mot_services.map((mot) => ({
                minor_ot_service: parseInt(mot.id),
                charge: parseFloat(mot.charge),
            })),
        };

        addMot(parsedState);
        if (isSuccess) {
            setLabTest({ ...initialLab });
            handlePTClose();
        }
    };
    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            [name]: value,
        }));
    };
    const handleSearchSubmit = (e) => {
        const { hid, name } = searchParams;
        e.preventDefault();
        dispatch(
            setMotsParams({
                ...motParams,
                name: name || "",
                hid: hid || "",
                // startDate: startDate ? getStartingTimeOfDate(dayjs(startDate)) : "",
                // endDate: endDate ? getEndingTimeOfDate(dayjs(endDate)) : "",
            })
        );
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            dispatch(
                setMotsParams({
                    ...motParams,
                    startDate: getStartingTimeOfDate(dayjs(start)) || "",
                    endDate: getEndingTimeOfDate(dayjs(end)) || "",
                })
            );
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const isStartDateValid =
            startDate instanceof Date && !isNaN(startDate.getTime());

        const isEndDateValid =
            endDate instanceof Date && !isNaN(endDate.getTime());
        const allFieldsEmptyOrInvalid =
            (!searchParams.name || !searchParams.name.trim()) &&
            (!searchParams.hid || !searchParams.hid.trim()) &&
            (!startDate || isNaN(startDate.getTime())) &&
            (!endDate || isNaN(endDate.getTime()));

        if (!allFieldsEmptyOrInvalid) {
            dispatch(
                setTestReportParams({
                    ...labtestParamms,
                    name: searchParams.name || "",
                    hid: searchParams.hid || "",
                    startDate: isStartDateValid
                        ? getStartingTimeOfDate(dayjs(startDate))
                        : "",
                    endDate: isEndDateValid
                        ? getEndingTimeOfDate(dayjs(endDate))
                        : "",
                })
            );
        } else {
            showNotification("error", "Please fill in at least one field.");
        }
    };

    const handleClearSearch = () => {
        dispatch(clearMots());
        setStartDate();
        setEndDate();
        setSearchParams({
            name: "",
            hid: "",
        });
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setTestReportParams({
                ...labtestParamms,
                offset: (value - 1) * labtestParamms.limit,
            })
        );
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSubmit(event);
        }
    };
    const handleKeyPressClear = (event) => {
        if (event.key === "Escape") {
            handleClearSearch();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", handleKeyPressClear);
        return () => {
            document.removeEventListener("keydown", handleKeyPressClear);
        };
    }, []);

    const handleKeyPress2 = (event) => {
        if (event.key === "Enter") {
            if (!ptExist) {
                handleAddPatient();
            } else {
                handleNextStep();
            }
        }
    };
    const handleDoctorClear = () => {
        setSelectedDoctor("");
        setLabTest((prevService) => ({
            ...prevService,
            doctor: "",
        }));
    };

    const handleDoctorChange = (event, value) => {
        setSelectedDoctor(value);
        if (value) {
            setSelectedDept(value.department); // Set the selected department when doctor is selected
            setLabTest((prevService) => ({
                ...prevService,
                doctor: value.id,
                department: value.department,
            }));
        }
    };

    const handleDeptChange = (event, value) => {
        setSelectedDept(value);
        setInputDeptValue(value ? value.name : "");
    };

    const handleDeptInputChange = (event, value) => {
        setInputDeptValue(value);
    };
    useEffect(() => {
        let total = 0;
        for (let item of labTest.mot_services) {
            total += isNaN(parseFloat(item.charge))
                ? 0
                : parseFloat(item.charge);
        }

        const newTotalAmount = isNaN(total) ? 0 : parseFloat(total);
        const newPayableAmount = isNaN(total - labTest.discount_amount)
            ? 0
            : parseFloat(total - labTest.discount_amount);

        setLabTest((prevLabTest) => ({
            ...prevLabTest,
            total_amount: newTotalAmount,
            payable_amount: newPayableAmount,
        }));
    }, [labTest.mot_services, labTest.discount_amount]);

    useEffect(() => {
        const total = labTest.mot_services.reduce((acc, item) => {
            return acc + parseFloat(item.charge);
        }, 0);

        const discountAmount = parseFloat(labTest.discount_amount);
        const discountPercent =
            total !== 0 ? (discountAmount / total) * 100 : 0;
        const payableAmount = total - discountAmount;

        setLabTest((prevLabTest) => ({
            ...prevLabTest,
            total_amount: total,
            discount_percent: parseFloat(discountPercent.toFixed(2)),
            payable_amount: parseFloat(payableAmount.toFixed(2)),
        }));
    }, [labTest.mot_services, labTest.discount_amount]);

    useEffect(() => {
        // Calculate payable amount and discount percentage when editedData.discount changes
        const calculatePayableAmount = () => {
            const discount = editedData.discount;
            const totalAmount = editedData.total_amount; // Assuming you have total_amount in your editedData

            // Calculate discount percentage
            const discountPercent = (discount / totalAmount) * 100;

            // Calculate payable amount
            const payableAmount = totalAmount - discount;

            // Update editedData with the calculated values
            setEditedData((prevLabTest) => ({
                ...prevLabTest,
                discount_percent: discountPercent,
                payable_amount: payableAmount,
            }));
        };

        calculatePayableAmount();
    }, [editedData.discount]); // This useEffect will run whenever editedData.discount changes

    const handleEditMOTChange = (e) => {
        const { name, value } = e.target;
        if (name === "tests" && labTest.total_amount === 0) {
            setEditedData((prevLabTest) => ({
                ...prevLabTest,
                discount_amount: 0,
                discount_percent: 0,
                payable_amount: 0,
                [name]: value,
            }));
        } else {
            setEditedData((prevLabTest) => ({
                ...prevLabTest,
                [name]: value,
            }));
        }
    };

    const handleEditClose = () => {
        setEditedData("");
        setStep(1);
        setPtOpen(false);
        handleClear();
        handleEditModalClose();
    };
    const [editTotal, setEditTotal] = useState(0);
    const [editDiscount, setEditDiscount] = useState(0);
    const [editPercent, setEditPercent] = useState(0);
    const [payableAmount, setPayableAmount] = useState(0);
    const handleEditOpen = (row) => {
        setEditTotal(row.total_amount);
        setEditDiscount(row.discount_amount);
        setEditPercent(row.discount_percent);
        setPayableAmount(row.payable_amount);
        setEditedData(row);
        setEditedSelectedDoctor(row.doctor);
        setEditedSelectedDept(row.department);
        setEditOpen(true);
        setMotID(row.id);
    };

    // useEffect(() => {
    //     if (editedData.discount_amount > 0) {
    //         setEditDiscount(editedData.total_amount);
    //     }
    //     if (editedData.discount_percent > 0) {
    //         setEditPercent(editedData.discount_percent);
    //     }
    //     if (editedData.total_amount > 0) {
    //         setEditTotal(editedData.total_amount);
    //     }
    //     calculatePayableAmount(editTotal, editDiscount);
    // }, [editedData]);
    const calculatePayableAmount = (total, discount) => {
        const discountPercent = (discount / total) * 100;
        setEditPercent(discountPercent);
        const payable = total - discount;
        setPayableAmount(payable);
    };
    const handleEditDiscount = (event) => {
        const newDiscount = parseFloat(event.target.value);
        setEditDiscount(newDiscount);
        calculatePayableAmount(editTotal, newDiscount);

        setEditedData((prevData) => ({
            ...prevData,
            discount_amount: newDiscount,
            discount_percent: (newDiscount / editTotal) * 100,
            payable_amount: editTotal - newDiscount,
        }));
    };

    const handleEditMinorOTs = (event, newValue) => {
        setSelectedMot(newValue);
        const totalCharge = newValue.reduce(
            (acc, item) => acc + item.charge,
            0
        );
        setEditTotal(totalCharge);
    };
    const handleEditDoctorChange = (event, value) => {
        setEditedSelectedDoctor(value);
        if (value) {
            setEditedSelectedDept(value.department); // Set the selected department when doctor is selected
            setEditedData((prevService) => ({
                ...prevService,
                doctor: value.id,
                department: value.department,
            }));
        }
    };

    const handleEditDeptChange = (event, value) => {
        setEditedSelectedDept(value);
        setEditedInputDeptValue(value ? value.name : "");
    };

    const handleEditDeptInputChange = (event, value) => {
        setEditedInputDeptValue(value);
    };
    const handleEditSave = (e) => {
        e.preventDefault();

        const {
            id,
            patient,
            doctor,
            department,
            total_amount,
            discount_amount,
            discount_percent,
            payable_amount,
            payment_mode,
            payment_reference_number,
        } = editedData;

        const motServices = editedData.mot_services || [];

        const parsedState = {
            mot: {
                id: parseInt(id),
                patient: parseInt(patient),
                doctor: parseInt(doctor),
                department: parseInt(department),
                total_amount:
                    motServices.length > 0 ? parseFloat(total_amount) : 0,
                discount_amount:
                    motServices.length > 0 ? parseFloat(discount_amount) : 0,
                discount_percent: parseFloat(discount_percent),
                payable_amount: parseFloat(payable_amount),
                payment_mode,
                payment_reference_number,
            },
            mot_services: selectedmot.map((mot) => ({
                minor_ot_service: parseInt(mot.id),
                charge: parseFloat(mot.charge),
            })),
        };

        const isValidData = () => {
            const { mot_services } = parsedState;
            const invalidFields = [];

            // Check individual fields for validity
            if (isNaN(parsedState.mot.patient)) invalidFields.push("patient");
            if (isNaN(parsedState.mot.doctor)) invalidFields.push("doctor");
            if (isNaN(parsedState.mot.department))
                invalidFields.push("department");
            if (isNaN(parsedState.mot.discount_percent))
                invalidFields.push("discount_percent");
            if (isNaN(parsedState.mot.payable_amount))
                invalidFields.push("payable_amount");
            if (!parsedState.mot.payment_mode)
                invalidFields.push("payment_mode");
            if (!parsedState.mot.payment_reference_number)
                invalidFields.push("payment_reference_number");
            if (!parsedState.mot_services || mot_services.length === 0)
                invalidFields.push("mot_services");

            // Check mot_services array for validity
            if (mot_services) {
                for (let i = 0; i < mot_services.length; i++) {
                    const motService = mot_services[i];
                    if (
                        isNaN(motService.minor_ot_service) ||
                        isNaN(motService.charge)
                    ) {
                        invalidFields.push(`mot_services[${i}]`);
                    }
                }
            }

            if (invalidFields.length > 0) {
                return false;
            }

            return true;
        };

        if (isValidData()) {
            editMot(parsedState);
        } else {
            showNotification("error", "Invalid data. Please check all fields.");
        }
    };

    const matchingMinorOtServices = motServicesData?.result?.list?.map(
        (item) => item.minor_ot_service
    );

    const filteredServiceData = servicesData?.result?.list.filter((item) =>
        matchingMinorOtServices?.includes(item.id)
    );
    useEffect(() => {
        if (filteredServiceData?.length > 0) {
            setSelectedMot([...filteredServiceData]);
        }
    }, [motID]);
    const closeBillModal = () => {
        setIsBillModalOpen(false);
        setSelectedMOTGenBill("");
    };
    return (
        <>
            <MotBill
                billData={selectedMOTGenBill}
                closeBillModal={closeBillModal}
                isBillModalOpen={isBillModalOpen}
            />

            <div className="flex justify-between px-10 mb-5">
                <div>
                    <div className="flex items-center space-x-3">
                        <Typography variant="h5">Minor OTs</Typography>
                        {add_permission === 1 && (
                            <Tooltip title="Refresh" placement="bottom-start">
                                <button
                                    onClick={refetchMots}
                                    className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                    style={{ cursor: "pointer" }}
                                >
                                    <AiOutlineReload size={20} />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>

                <Tooltip title="Add" placement="bottom-start">
                    <button
                        onClick={handlePTOpen}
                        className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                    >
                        <IoMdAdd size={18} />
                    </button>
                </Tooltip>
            </div>
            <div
                className="flex items-center space-x-8"
                style={{ position: "relative", zIndex: "999" }}
            >
                <form
                    onSubmit={handleSearchSubmit}
                    onKeyPress={handleKeyPress}
                    className="flex items-center space-x-4"
                >
                    <TextField
                        label="Name"
                        variant="outlined"
                        name="name"
                        value={searchParams.name}
                        onChange={handleSearchChange}
                    />
                    <TextField
                        label="HID"
                        variant="outlined"
                        name="hid"
                        value={searchParams.hid}
                        onChange={handleSearchChange}
                    />
                    <DatePicker
                        maxDate={dayjs().toDate()}
                        className="px-6 py-4 w-[280px] border border-gray-300 rounded-xl"
                        dateFormat="yyyy/MM/dd"
                        placeholderText="StartDate - EndDate"
                        selectsRange={true}
                        isClearable
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                    />
                    <ReusableButton
                        title="Search"
                        // onClick={handleSearchSubmit}
                        type="submit"
                    />

                    <ClearButton title="Clear" onClick={handleClearSearch} />
                </form>
            </div>
            <div className="mt-10">
                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "15px",
                    }}
                    elevation={24}
                >
                    <TableContainer
                        sx={{
                            height: 520,
                            overflowX: "scroll",
                            "&::-webkit-scrollbar": {
                                width: 0,
                            },
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                                color: "white",
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {!isMotsLoading && !isFetching && !isRefetching ? (
                                <TableBody>
                                    {motsData?.result?.list?.map((row) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                key={row.id}
                                                align="center"
                                            >
                                                <TableCell
                                                    align="center"
                                                    className="capitalize"
                                                >
                                                    {row.hid}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className="capitalize"
                                                >
                                                    {row.patient_name}
                                                </TableCell>

                                                <TableCell align="center">
                                                    ₹ {row.total_amount}
                                                </TableCell>
                                                <TableCell align="center">
                                                    ₹ {row.payable_amount}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {dayjs(
                                                        row.created_on
                                                    ).format(
                                                        "DD-MM-YYYY,h:mm:ss A"
                                                    )}
                                                </TableCell>

                                                <TableCell
                                                    align="center"
                                                    sx={{
                                                        width: 120,
                                                    }}
                                                >
                                                    <div className="flex items-center justify-center space-x-4">
                                                        <Tooltip
                                                            title="Download Bill"
                                                            placement="bottom-start"
                                                        >
                                                            <button
                                                                onClick={() =>
                                                                    handleGenerateBill(
                                                                        row
                                                                    )
                                                                }
                                                                className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                            >
                                                                <FaRegFile className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                            </button>
                                                        </Tooltip>
                                                        {/* {update_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Test Reports"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        openModal(
                                                                            row
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <BiTestTube className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        )} */}

                                                        {update_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Edit Minor OT"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleEditOpen(
                                                                            row
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                        {delete_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Delete Test"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleOpenDeleteDialog(
                                                                            row.id
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                                >
                                                                    <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            colSpan={7}
                                            align="center"
                                            sx={{
                                                width: 120,
                                            }}
                                        >
                                            <div className="flex items-center justify-center">
                                                {/* <Lottie
                                                    animationData={Loading1}
                                                    loop={true}
                                                    style={{
                                                        height: "380px",
                                                        width: "400px",
                                                    }}
                                                /> */}
                                                <LoaderLogo />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <div className="flex items-center justify-center my-4">
                        <Pagination
                            count={
                                Math.ceil(
                                    motsData?.result?.count /
                                        labtestParamms.limit
                                ) || 0
                            }
                            page={currentPage}
                            onChange={handlePageChange}
                            sx={{
                                "& .MuiSelect-select": {
                                    display: "none !important",
                                },
                                "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                    {
                                        display: "none !important",
                                    },
                            }}
                        />
                    </div>
                </Paper>
            </div>

            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeleteMotLoading}
            />

            <Modal
                open={ptOpen}
                onClose={handlePTClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={ptOpen}>
                    <Box sx={styles}>
                        <>
                            {step === 1 && (
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSearchPatient();
                                    }}
                                    className="justify-center w-full space-y-4 "
                                >
                                    <Typography variant="h4">
                                        Search Patient
                                    </Typography>
                                    <TextField
                                        required
                                        name="aadhaar"
                                        className="col-span-2"
                                        label="Enter Patient Aadhaar"
                                        variant="outlined"
                                        fullWidth
                                        value={patientId.aadhaar}
                                        onChange={handleAadharChange}
                                        disabled={isAadharDisabled}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    sx={{
                                                        visibility:
                                                            patientId.aadhaar
                                                                ? "visible"
                                                                : "hidden",
                                                    }}
                                                    variant="outlined"
                                                    onClick={handleClear}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        inputProps={{
                                            inputMode: "numeric",
                                            pattern: "[0-9]*", // Allow only numeric input
                                            maxLength: 12,
                                        }}
                                        onInput={(e) => {
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                ); // Remove non-numeric characters
                                        }}
                                    />
                                    <TextField
                                        required
                                        name="hid"
                                        className="col-span-2"
                                        label="Enter Patient HID"
                                        variant="outlined"
                                        fullWidth
                                        value={patientId.hid}
                                        onChange={handleHidChange}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    sx={{
                                                        visibility:
                                                            patientId.hid
                                                                ? "visible"
                                                                : "hidden",
                                                    }}
                                                    variant="outlined"
                                                    onClick={handleClear}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        disabled={isHidDisabled}
                                    />

                                    <div className="flex w-full py-2 justify-evenly ">
                                        <ReusableButton
                                            title="Add"
                                            type="submit"
                                        />

                                        <RedButton
                                            title="Cancel"
                                            onClick={handlePTClose}
                                        />
                                    </div>
                                </form>
                            )}
                            {step === 2 && (
                                <AddPatientForm
                                    handleKeyPress2={handleKeyPress2}
                                    handleSelectChange={handleSelectChange}
                                    handleTextFieldChange={
                                        handleTextFieldChange
                                    }
                                    handleAddPatient={handleAddPatient}
                                    handleNextStep={handleNextStep}
                                    handlePTClose={handlePTClose}
                                    patient={patient}
                                    ptExist={ptExist}
                                />
                            )}
                            {step === 3 && (
                                <>
                                    <MinotOtModal
                                        labTest={labTest}
                                        handleChange={handleChangeLab2}
                                        handleChangeTests={handleChangeTests2}
                                        handleClose={handlePTClose}
                                        metaData={metaData}
                                        handleAdd={handleAddLabTest}
                                        selectedDoctor={selectedDoctor}
                                        handleDoctorChange={handleDoctorChange}
                                        handleDeptChange={handleDeptChange}
                                        inputDeptValue={inputDeptValue}
                                        handleDeptInputChange={
                                            handleDeptInputChange
                                        }
                                        selectedDept={selectedDept}
                                    />
                                </>
                            )}
                        </>
                    </Box>
                </Fade>
            </Modal>

            <Modal
                open={editOpen}
                onClose={handleEditModalClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={editOpen}>
                    <Box sx={style}>
                        <div>
                            <>
                                <Typography
                                    variant="h5"
                                    className="text-center"
                                >
                                    Edit Minor OT
                                </Typography>

                                <div className="grid grid-cols-2 gap-5">
                                    <TextField
                                        required
                                        label="Patient"
                                        name="patient"
                                        value={editedData.patient}
                                        disabled
                                        onChange={handleEditMOTChange}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <div className="flex items-center justify-center">
                                        <Autocomplete
                                            fullWidth
                                            multiple
                                            autoComplete
                                            options={
                                                servicesData?.result?.list || []
                                            }
                                            getOptionLabel={(option) =>
                                                option.name || ""
                                            }
                                            value={selectedmot || []}
                                            filterOptions={(x) => x}
                                            onChange={(event, newValue) =>
                                                handleEditMinorOTs(
                                                    event,
                                                    newValue
                                                )
                                            }
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.id === value.id}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Type to search test"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </div>
                                    <TextField
                                        required
                                        label="Total Amount"
                                        name="total_amount"
                                        value={editedData.total_amount}
                                        disabled
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        label="Discount Amount"
                                        name="discount_amount"
                                        value={editedData.discount_amount}
                                        onChange={handleEditMOTChange}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        label="Discount Percent"
                                        name="discount_percent"
                                        value={editedData.discount_percent}
                                        disabled
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        label="Payable Amount"
                                        name="payable_amount"
                                        value={editedData.payable_amount}
                                        disabled
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />

                                    <Autocomplete
                                        margin="dense"
                                        fullWidth
                                        options={filteredDoctors || []}
                                        disableClearable
                                        getOptionLabel={(option) =>
                                            option
                                                ? option?.name
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                  option?.name.slice(1)
                                                : ""
                                        }
                                        value={
                                            filteredDoctors?.filter(
                                                (doctor) =>
                                                    doctor.id ===
                                                    editedData.doctor
                                            )[0] || null
                                        }
                                        onChange={handleEditDoctorChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Search Doctor"
                                            />
                                        )}
                                        isOptionEqualToValue={(
                                            option,
                                            value
                                        ) => {
                                            if (value) {
                                                return option.id === value.id;
                                            }
                                        }}
                                    />
                                    <Autocomplete
                                        fullWidth
                                        disabled
                                        margin="dense"
                                        options={
                                            metaData?.result?.departments || []
                                        }
                                        getOptionLabel={(option) => option.name}
                                        value={
                                            metaData?.result?.departments?.filter(
                                                (department) =>
                                                    department.id ===
                                                    editedData.department
                                            )[0] || null
                                        }
                                        onChange={handleEditDeptChange}
                                        inputValue={editedInputDeptValue}
                                        onInputChange={
                                            handleEditDeptInputChange
                                        }
                                        disableClearable
                                        isOptionEqualToValue={(
                                            option,
                                            value
                                        ) => {
                                            if (value) {
                                                return option.id === value.id;
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Department"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                    >
                                        <InputLabel id="payment-mode-label">
                                            Payment Mode
                                        </InputLabel>
                                        <Select
                                            labelId="payment-mode-label"
                                            label="Payment Mode"
                                            name="payment_mode"
                                            value={
                                                editedData.payment_mode || ""
                                            }
                                            onChange={handleEditMOTChange}
                                            className="capitalize"
                                        >
                                            {metaData?.result?.payment_modes?.map(
                                                (mode) => (
                                                    <MenuItem
                                                        key={mode}
                                                        value={mode}
                                                        className="capitalize"
                                                    >
                                                        {mode}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        label="Payment Reference Number"
                                        name="payment_reference_number"
                                        value={
                                            editedData.payment_reference_number
                                        }
                                        onChange={handleEditMOTChange}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </div>
                                <div className="flex py-4 justify-evenly">
                                    <ReusableButton
                                        title="Save Changes"
                                        onClick={handleEditSave}
                                        type="submit"
                                    />

                                    <RedButton
                                        title="Cancel"
                                        onClick={handleEditClose}
                                    />
                                </div>
                            </>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default MinorOT;
