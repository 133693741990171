import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import { useGetPatientOPD } from "../../query/hooks/patientsSide/patientSideHooks";
import dayjs from "dayjs";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Navigate, useNavigate } from "react-router-dom";
import NoDataFound from "../common/NoDataFound";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const NoDataCard = () => (
  <Paper elevation={3} className="no-data-card">
    <Box p={2}>
      <p>No data available</p>
    </Box>
  </Paper>
);

const PatientHistory = () => {
  const navigate = useNavigate();
  const { data: patientOPDData, isLoading: isPatientOPDLoading } =
    useGetPatientOPD();
  const handleViewClick = (id) => {
    navigate(`/patient/testreports/${id}`);
  };

  const AppointmentCard = ({ opd, onViewClick }) => {
    return (
      <div className="shadow-2xl rounded-xl">
        <div className="p-5 bg-[rgb(39,39,42)] text-white rounded-xl">
          <div className="space-y-1 overflow-clip">
            <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
              <strong>Name: </strong>
              {opd.patient_name}
            </Typography>
            <Typography variant="body1">
              <strong>Hospital: </strong> {opd.entity_name}
            </Typography>
            <Typography variant="body1">
              <strong>Department: </strong> {opd.department_name}
            </Typography>
            <Typography variant="body2">
              <strong>Appointment Date: </strong>
              {dayjs(opd.created_on).format("LL")}
            </Typography>
          </div>

          <div className="flex items-center w-full mt-6 justify-evenly">
            <Tooltip title="VIEW REPORT" placement="bottom-start">
              <button
                onClick={() => onViewClick(opd.id)}
                className="p-2 font-semibold duration-300 bg-green-600 rounded-lg group hover:bg-green-500"
              >
                VIEW/DOWNLOAD
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Typography
          variant="h6"
          sx={{
            marginBottom: 3,
          }}
        >
          <ArticleIcon fontSize="medium" sx={{ mr: 1 }} />
          <strong>OPD </strong>(
          <span className="ml-1">{patientOPDData?.result?.count}</span>)
        </Typography>
      </div>
      <section>
        {patientOPDData?.result?.count === 0 ? (
          <div className="mx-auto">
            <NoDataFound />
          </div>
        ) : (
          <div className="space-y-4">
            {patientOPDData?.result?.list.map((opd) => (
              <AppointmentCard
                key={opd.id}
                opd={opd}
                onViewClick={handleViewClick}
              />
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

export default PatientHistory;
