import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentAuth } from "../../redux/features/auth/authSlice";

const PrivateRoute = () => {
    const auth = useSelector(selectCurrentAuth);
    const location = useLocation();

    return auth?.user?.token && auth?.user?.entity ? (
        <Outlet />
    ) : (
        <Navigate to="/" state={{ from: location }} replace />
    );
};
export default PrivateRoute;
