import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { FaRegEye } from "react-icons/fa";
import {
    AiOutlineEdit,
    AiOutlineDelete,
    AiOutlineReload,
} from "react-icons/ai";
import {
    Backdrop,
    Box,
    Button,
    Fade,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import RedButton from "./common/RedButton";
import ReusableButton from "./common/ReusableButton";
import {
    useAddFloor,
    useDeleteFloor,
    useEditFloor,
    useGetFloors,
} from "../query/hooks/ipd/floors";
import { getPermissionsByModule, showNotification } from "../services/helper";
import dayjs from "dayjs";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { deleteFloor } from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import {
    selectFloorParam,
    setFloorParams,
} from "../redux/features/filters/floorSlice";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

const columns = [
    {
        id: "1",
        label: "ID",
        align: "center",
        paddingX: 2,
        width: 120,
        maxWidth: "none",
    },
    {
        id: "2",
        label: "Floors",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },
    {
        id: "3",
        label: "Created On",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },

    {
        id: "4",
        label: "Actions",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },
];
const initialState = {
    name: "",
};
const Floors = () => {
    const dispatch = useDispatch();
    const currentAuth = useSelector(selectCurrentAuth);
    const floorParams = useSelector(selectFloorParam);
    const permissionsList = currentAuth?.user?.permissions;
    const floorsPermissions = getPermissionsByModule(1013, permissionsList);
    const {
        add_permission,
        update_permission,
        delete_permission,
        view_permission,
    } = floorsPermissions;

    const { floorsData, isFetching, isLoading, isRefetching, refetch } =
        useGetFloors(floorParams);
    const { deleteFloor, isLoading: isDeletingFloor } = useDeleteFloor();
    const { editFloors, isLoading: isUpdating } = useEditFloor();
    const { addFloor, isLoading: isAdding } = useAddFloor();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [formData, setFormData] = useState({
        initialState,
    });
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editFloor, setEditFloor] = useState(null);
    const [toDelete, setToDelete] = useState("");

    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData(initialState);
        setEditFloor(null);
    };

    const handleDeleteConfirmed = () => {
        deleteFloor(toDelete);
        handleCloseDeleteDialog();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddFloor = () => {
        if (formData.name) {
            addFloor(formData);
            handleClose();
        } else {
            showNotification("error", "Please enter a valid floor name.");
        }
    };

    const handleEditClick = (row) => {
        setEditFloor(row);
        setOpen(true);
        setEditOpen(true);
    };
    const handleUpdateFloor = () => {
        const updatedFloor = {
            name: editFloor.name,
        };
        if (!updatedFloor || !updatedFloor.name) {
            showNotification("error", "Floor name is required.");
            return;
        }

        editFloors(editFloor);
        handleClose();
    };
    const [currentPage, setCurrentPage] = useState(1);

    const handlePaginationChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setFloorParams({
                ...floorParams,
                offset: (value - 1) * floorParams.limit,
            })
        );
    };
    return (
        <section>
            <div className="my-4">
                <div className="flex items-center justify-between space-x-3">
                    <div className="flex items-center space-x-3">
                        <Typography variant="h5">Floors</Typography>

                        <Tooltip title="Refresh" placement="bottom-start">
                            <button
                                onClick={refetch}
                                className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                style={{ cursor: "pointer" }}
                            >
                                <AiOutlineReload size={20} />
                            </button>
                        </Tooltip>
                    </div>
                    {add_permission === 1 && (
                        <ReusableButton
                            onClick={handleOpen}
                            title="Add Floor"
                        />
                    )}
                </div>
            </div>
            <Paper
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: "15px",
                }}
                elevation={24}
            >
                <TableContainer
                    sx={{
                        height: 520,
                        overflowX: "scroll",
                        "&::-webkit-scrollbar": {
                            width: 0,
                        },
                    }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            paddingX: column.paddingX,
                                        }}
                                        style={{
                                            minWidth: column.minWidth,
                                            width: column.width,
                                            maxWidth: column.maxWidth,
                                            fontWeight: "bold",
                                            background: "#eeeeee",
                                            textTransform: "uppercase",
                                            backgroundColor: "#27272A",
                                            color: "white",
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {floorsData?.result?.list?.map((item, index) => (
                                <TableRow
                                    key={item.id}
                                    style={{
                                        backgroundColor:
                                            index % 2 === 0
                                                ? "#E4E4E7"
                                                : "white",
                                    }}
                                >
                                    <TableCell align="center">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {dayjs(item.created_on).format(
                                            "DD-MM-YYYY,h:mm:ss A"
                                        )}
                                    </TableCell>

                                    <TableCell align="right">
                                        <div className="flex items-center justify-center space-x-4">
                                            {update_permission === 1 && (
                                                <Tooltip
                                                    title="Edit"
                                                    placement="bottom-start"
                                                >
                                                    <button
                                                        onClick={() =>
                                                            handleEditClick(
                                                                item
                                                            )
                                                        }
                                                        className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                    >
                                                        <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                    </button>
                                                </Tooltip>
                                            )}
                                            {delete_permission === 1 && (
                                                <Tooltip
                                                    title="Delete"
                                                    placement="bottom-start"
                                                >
                                                    <button
                                                        onClick={() =>
                                                            handleOpenDeleteDialog(
                                                                item.id
                                                            )
                                                        }
                                                        className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                    >
                                                        <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                    </button>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="flex items-center justify-center my-4">
                    <Pagination
                        count={Math.ceil(
                            floorsData?.result?.count / floorParams.limit
                        )}
                        page={currentPage}
                        sx={{
                            "& .MuiSelect-select": {
                                display: "none !important",
                            },
                            "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                {
                                    display: "none !important",
                                },
                        }}
                        onChange={handlePaginationChange}
                    />
                </div>
            </Paper>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {editFloor ? (
                            <div>
                                <Typography
                                    id="transition-modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                >
                                    Update Floor
                                </Typography>
                                <section className="mb-5 space-y-5">
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={isUpdating}
                                            label="Name"
                                            name="name"
                                            value={editFloor.name}
                                            onChange={(e) =>
                                                setEditFloor({
                                                    ...editFloor,
                                                    name: e.target.value,
                                                })
                                            }
                                            fullWidth
                                            margin="normal"
                                        />
                                    </FormControl>
                                </section>
                                <div className="flex items-center justify-evenly">
                                    <ReusableButton
                                        onClick={handleUpdateFloor}
                                        title="Add"
                                        isDisabled={isUpdating}
                                    />
                                    <RedButton
                                        isDisabled={isUpdating}
                                        onClick={handleClose}
                                        title="Cancel"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Typography
                                    id="transition-modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                >
                                    Add Floor
                                </Typography>
                                <section className="mb-5 space-y-5">
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </FormControl>
                                </section>
                                <div className="flex items-center justify-evenly">
                                    <ReusableButton
                                        onClick={handleAddFloor}
                                        title="Add"
                                        isDisabled={isAdding}
                                    />
                                    <RedButton
                                        isDisabled={isAdding}
                                        onClick={handleClose}
                                        title="Cancel"
                                    />
                                </div>
                            </div>
                        )}
                    </Box>
                </Fade>
            </Modal>
            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeletingFloor}
            />
        </section>
    );
};

export default Floors;
