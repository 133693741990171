import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Paper, Tooltip } from "@mui/material";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import { useGetPatientLabTest } from "../../query/hooks/patientsSide/patientSideHooks";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineFolderOff } from "react-icons/md";
import NoDataFound from "../common/NoDataFound";

const TestDownload = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [labId, setLabId] = useState("");

  useEffect(() => {
    if (!id) {
      navigate(-1);
    } else {
      setLabId(id);
    }
  }, [id]);
  const { data: patientLabData, isLoading: isPatientLabLoading } =
    useGetPatientLabTest(labId);

  const handleViewClick = (id) => {
    navigate(`/patient/testreports/downloads/${labId}/${id}`);
  };
  const TestCard = ({ data, onViewClick }) => {
    return (
      <div className="shadow-2xl rounded-xl">
        <div className="p-5 bg-[rgb(39,39,42)] text-white rounded-xl">
          <div className="space-y-1 overflow-clip">
            <Typography variant="body1">
              <strong>Test Name: </strong>
              {data.test_name}
            </Typography>

            <Typography variant="body2">
              <strong>Test Date: </strong>
              {data.created_on}
            </Typography>
          </div>

          <div className="flex items-center w-full mt-6 justify-evenly">
            <Tooltip title="VIEW" placement="bottom-start">
              <button
                onClick={() => onViewClick(data.test)}
                className="px-6 py-2 font-semibold duration-300 bg-green-600 rounded-2xl group hover:bg-green-500"
              >
                DOWNLOAD
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div>
        <Typography
          variant="h6"
          sx={{
            marginBottom: 3,
          }}
        >
          <MonitorHeartIcon fontSize="medium" sx={{ mr: 1 }} />
          <strong>Test Report Downloads</strong>
        </Typography>
      </div>
      <div className="space-y-3">
        {patientLabData?.result?.count > 0 ? (
          patientLabData.result.list.map((test) => (
            <TestCard key={test.id} data={test} onViewClick={handleViewClick} />
          ))
        ) : (
          <NoDataFound />
        )}
      </div>
    </div>
  );
};

export default TestDownload;
