import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { addTest, updateTest, getTests, deleteTest, addReport } from "../../../services/api";
import { showNotification } from "../../../services/helper";

// Custom hook for fetching tests
export function useGetTests(params = { offset: 0, limit: 1000, name: '' }) {
    const { offset, limit, name } = params;
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.tests, { offset, limit, name }],
        queryFn: () => getTests({ offset, limit, name }),
    });

    return { testsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

export function useAddReport() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (testData) => addReport(testData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.tests] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.test] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addNewReport: mutate, isLoading };
}


export function useAddTest(handleClose) {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (testData) => addTest(testData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.tests] });
            if (data?.success) {
                showNotification("success", "Test added");
                handleClose();
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addNewTest: mutate, isLoading };
}

export function useEditTest(setEditOpen) {

    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updateTest(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.tests] });
            if (data?.success) {
                setEditOpen(false);
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { editTest: mutate, isLoading };
}

export function useDeleteTest() {


    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (testId) => deleteTest(testId,),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.tests] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteTest: mutate, isLoading };
}
