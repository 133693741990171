import { createSlice } from "@reduxjs/toolkit";

const ipdBillsInitialState = {
    defaultParams: {
        name: "",
        hid: "",
        offset: 0,
        limit: 10,
        startDate: "",
        endDate: "",
        ipd: ""
    },
};

const ipdBillsSlice = createSlice({
    name: "ipdBills",
    initialState: ipdBillsInitialState,
    reducers: {
        setIPDBillsParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },

        clearIPDBills: (state, action) => {
            state.defaultParams = ipdBillsInitialState.defaultParams;
        },
    },
    extraReducers: {},
});

export const selectIPDBillsParam = (state) => state.ipdBills.defaultParams;
export const { setIPDBillsParams, clearIPDBills } = ipdBillsSlice.actions;
export default ipdBillsSlice.reducer;
