import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserParams } from "../redux/features/filters/userSlice";
import { getPermissionsByModule } from "../services/helper";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import { useParams } from "react-router-dom";
import {
    useGetLabTestList,
    useGetLabTests,
} from "../query/hooks/labtest/labtestHook";
import {
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import LoaderLogo from "./common/LoaderLogo";
import {
    selectTestReportParams,
    setTestReportParams,
} from "../redux/features/filters/testReportSlice";
import dayjs from "dayjs";
import { useGetOPDs } from "../query/hooks/opd/opdHook";
import {
    selectOPDParam,
    setOPDParams,
} from "../redux/features/filters/opdSlice";
import { selectIPDParam } from "../redux/features/filters/ipdSlice";
import { useGetIPDs } from "../query/hooks/ipd/ipds";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
const ipdHeaders = [
    { id: 1, name: "IPD ID" },
    { id: 2, name: "HID" },
    { id: 3, name: "Name" },
    { id: 4, name: "Room" },
    { id: 5, name: "Doctor" },
    { id: 6, name: "Department" },
    // { id: 6, name: "Reference" },
    { id: 7, name: "Created By" },
    { id: 8, name: "Admission Date" },
];
const opdColumns = [
    { id: "id", label: "ID", minWidth: 90, align: "center" },
    { id: "hid", label: "HID", minWidth: 150, align: "center" },
    {
        id: "patient_name",
        label: "patient name",

        align: "left",
        marginLeft: "10px",
    },

    { id: "patient_mobile", label: "Mobile", minWidth: 170, align: "center" },
    {
        id: "department_name",
        label: "department",
        minWidth: 170,
        align: "center",
    },
    {
        id: "payable_amount",
        label: "payable amount",
        minWidth: 170,
        align: "center",
    },
    {
        id: "createdDate",
        label: "Created Date",
        minWidth: 120,
        align: "center",
    },
];
const columns = [
    { id: "hid", label: "HID", minWidth: 100, align: "center" },
    { id: "name", label: "Name", minWidth: 100, align: "center" },
    {
        id: "total_amount",
        label: "Total Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "payable_amount",
        label: "Payable Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "createdDate",
        label: "Created Date",
        minWidth: 100,
        align: "center",
    },
];
const buttonName = [
    { id: 1, label: "Deposits" },
    { id: 2, label: "Doctor Payments" },
    { id: 3, label: "Expenses" },
    { id: 4, label: "IPD Bills" },
    { id: 5, label: "IPDs" },
    { id: 6, label: "Lab Investigations" },
    { id: 7, label: "Minor OTs" },
    { id: 8, label: "OPDs" },
    { id: 9, label: "Refunds" },
];
const tabsName = [
    { id: 1, label: "Lab Investigations" },
    { id: 2, label: "OPDs" },
    { id: 3, label: "IPDS" },
];

const PtHistory = () => {
    const params = useParams();
    const opdParams = useSelector(selectOPDParam);
    const [ptID, setPtID] = useState();

    const { opdsData, refetch } = useGetOPDs({ ...opdParams, ptId: params.id });
    const dispatch = useDispatch();
    const labtestParamms = useSelector(selectTestReportParams);
    const currentAuth = useSelector(selectCurrentAuth);
    const permissionsList = currentAuth?.user?.permissions;
    const analyticsPermissions = getPermissionsByModule(1022, permissionsList);
    const [selectedOption, setSelectedOption] = useState(null);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [activeButton, setActiveButton] = useState(1);
    const [activeTab, setActiveTab] = useState(1);

    const { labTestsData, isLoading } = useGetLabTests({
        ...labtestParamms,
        ptID: params.id,
    });
    const ipdParams = useSelector(selectIPDParam);

    const { ipdsData } = useGetIPDs({ ...ipdParams, ptId: params.id });

    const handleClick = (id) => {
        setActiveButton(id);
    };
    const handleTabClick = (id) => {
        setActiveTab(id);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setTestReportParams({
                ...labtestParamms,
                offset: (value - 1) * labtestParamms.limit,
            })
        );
    };
    const [opdCurrentPage, setOpdCurrentPage] = useState(1);
    const handlePaginationChangeForOPD = (event, value) => {
        setOpdCurrentPage(value);
        dispatch(
            setOPDParams({
                ...opdParams,
                offset: (value - 1) * opdParams.limit,
            })
        );
    };
    return (
        <>
            <section>
                <Typography variant="h5">Patient History</Typography>

                <section className="flex items-center mt-2 rounded-lg">
                    {tabsName.map((item, index) => (
                        <div key={item.id} className="relative">
                            <button
                                className={` px-8  cursor-pointer font-semibold text-lg py-3 border-b-4 transition-all duration-300
                    ${
                        activeTab === item.id
                            ? "border-red-600  animate-slide-in-bottom"
                            : ""
                    }
                `}
                                onClick={() => handleTabClick(item.id)}
                            >
                                {item.label}
                            </button>
                            {index !== tabsName.length - 1 && (
                                <div
                                    className="absolute top-0 right-0 w-1 h-full "
                                    style={{ transform: "translateX(50%)" }}
                                ></div>
                            )}
                        </div>
                    ))}
                </section>
            </section>
            {activeTab === 1 && (
                <div className="mt-10">
                    <Paper
                        sx={{
                            width: "100%",
                            overflow: "hidden",
                            borderRadius: "15px",
                        }}
                        elevation={24}
                    >
                        <TableContainer
                            sx={{
                                height: 520,
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    fontWeight: "bold",
                                                    background: "#eeeeee",
                                                    textTransform: "uppercase",
                                                    backgroundColor: "#27272A",
                                                    color: "white",
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {labTestsData?.result?.list.length > 0 ? (
                                    <TableBody>
                                        {labTestsData?.result?.list?.map(
                                            (row) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        key={row.id}
                                                        align="center"
                                                    >
                                                        <TableCell
                                                            align="center"
                                                            className="capitalize"
                                                        >
                                                            {row.hid}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            className="capitalize"
                                                        >
                                                            {row.patient_name}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            ₹ {row.total_amount}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {row.payable_amount}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {dayjs(
                                                                row.created_on
                                                            ).format(
                                                                "DD-MM-YYYY,h:mm:ss A"
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                colSpan={7}
                                                align="center"
                                                sx={{
                                                    width: 120,
                                                }}
                                            >
                                                No Data Available
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                        <div className="flex items-center justify-center my-4">
                            <Pagination
                                count={
                                    Math.ceil(
                                        labTestsData?.result?.count /
                                            labtestParamms.limit
                                    ) || 0
                                }
                                page={currentPage}
                                onChange={handlePageChange}
                                sx={{
                                    "& .MuiSelect-select": {
                                        display: "none !important",
                                    },
                                    "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                        {
                                            display: "none !important",
                                        },
                                }}
                            />
                        </div>
                    </Paper>
                </div>
            )}

            {activeTab === 2 && (
                <section className="mt-10">
                    <Paper
                        sx={{
                            width: "100%",
                            overflow: "hidden",
                            borderRadius: "15px",
                        }}
                        elevation={24}
                    >
                        <TableContainer
                            sx={{
                                height: 520,
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {opdColumns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    fontWeight: "bold",
                                                    background: "#eeeeee",
                                                    textTransform: "uppercase",
                                                    backgroundColor: "#27272A",
                                                    color: "white",
                                                    marginRight:
                                                        column.marginLeft,
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {opdsData?.result?.list?.length > 0 ? (
                                    <TableBody>
                                        {opdsData?.result?.list?.map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    key={row.id}
                                                    align="center"
                                                >
                                                    <TableCell
                                                        align="center"
                                                        size="medium"
                                                    >
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        size="medium"
                                                    >
                                                        {row.hid}
                                                    </TableCell>
                                                    <TableCell
                                                        align="justify"
                                                        size="medium"
                                                        className="capitalize"
                                                    >
                                                        {row.patient_name}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {row.patient_mobile}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.department_name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        ₹ {row.payable_amount}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {dayjs(
                                                            row.created_on
                                                        ).format(
                                                            "DD-MM-YYYY,h:mm:ss A"
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                colSpan={7}
                                                align="center"
                                                sx={{
                                                    width: 120,
                                                }}
                                            >
                                                No Data Available
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                        {opdsData?.result?.list?.length > 0 && (
                            <div className="flex items-center justify-center my-4">
                                <Pagination
                                    count={Math.ceil(
                                        opdsData?.result?.count /
                                            opdParams.limit
                                    )}
                                    page={opdCurrentPage}
                                    sx={{
                                        "& .MuiSelect-select": {
                                            display: "none !important",
                                        },
                                        "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                            {
                                                display: "none !important",
                                            },
                                    }}
                                    onChange={handlePaginationChangeForOPD}
                                />
                            </div>
                        )}
                    </Paper>
                </section>
            )}
            {activeTab === 3 && (
                <div className="mt-10">
                    <Paper
                        sx={{
                            width: "100%",
                            overflow: "hidden",
                            borderRadius: "15px",
                        }}
                        elevation={24}
                    >
                        <TableContainer
                            sx={{
                                height: 520,
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {ipdHeaders.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align="center"
                                                style={{
                                                    minWidth: column.minWidth,
                                                    fontWeight: "bold",
                                                    background: "#eeeeee",
                                                    textTransform: "uppercase",
                                                    backgroundColor: "#27272A",
                                                    color: "white",
                                                    marginRight:
                                                        column.marginLeft,
                                                }}
                                            >
                                                {column.name}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                {ipdsData?.result?.list > 0 ? (
                                    <TableBody>
                                        {ipdsData?.result?.list?.map(
                                            (item, index) => (
                                                <TableRow
                                                    key={item.id}
                                                    style={{
                                                        backgroundColor:
                                                            index % 2 === 0
                                                                ? "#E4E4E7"
                                                                : "white",
                                                    }}
                                                >
                                                    <TableCell align="center">
                                                        {item.id}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {item.hid}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {item.patient_name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {item.room_name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {item.doctor_name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {item.department_name}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {item.created_by_name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {dayjs(
                                                            item.created_on
                                                        ).format(
                                                            "DD-MM-YYYY,h:mm:ss A"
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                colSpan={8}
                                                align="center"
                                            >
                                                No Data Available
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            )}
        </>
    );
};

export default PtHistory;
