import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPatientLabReport } from "../../query/hooks/patientsSide/patientSideHooks";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { skynet } from "../../services/api";
import { MdOutlineFolderOff } from "react-icons/md";
import NoDataFound from "../common/NoDataFound";
const accordionStyle = {
  background: "#27272A",
  color: "white",
};
const TestReportDownload = () => {
  const { id1, id2 } = useParams();
  const navigate = useNavigate();
  const [testId, setTestId] = useState("");

  useEffect(() => {
    if (!id2) {
      navigate(-1);
    } else {
      setTestId(id2);
    }
  }, [id2]);

  const { data: patientTestData, isLoading: isPatientTestLoading } =
    useGetPatientLabReport(id1, testId);

  const handleDownloadReport = (lab) => {
    const reportUrl = `${skynet}/${lab.report}`;
    window.open(reportUrl, "_blank");
  };
  return (
    <div>
      {patientTestData?.result?.count > 0 ? (
        patientTestData.result.list.map((report, index) => (
          <Accordion key={report.id} style={accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            >
              {`${report.test_name}`}
            </AccordionSummary>
            <AccordionDetails>
              <div className="space-y-5">
                <div>
                  <p>Test: {report.test_name}</p>
                  <p>Entity: {report.entity_name}</p>
                  <p>Created on: {report.created_on}</p>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleDownloadReport(report)}
                >
                  Download Report
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <NoDataFound />
      )}
    </div>
  );
};

export default TestReportDownload;
