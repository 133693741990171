import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { addIPD, deleteIPD, getIPD, getIPDs, updateIPD } from "../../../services/api";

export function useGetIPDs(ipdParams = { name: "", hid: "", department: "", mobile: "", offset: 0, limit: 10, startDate: "", endDate: "", ptId: "" }) {
    const { name, hid, department, mobile, offset, limit, startDate, endDate, ptId } = ipdParams;
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.ipds, { ...ipdParams }],
        queryFn: () => getIPDs({ name, hid, department, mobile, offset, limit, startDate, endDate, ptId }),
        // enabled: startDate !== null && endDate !== null,
    });

    return { ipdsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

export function useGetIPD(ipdID) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } =
        useQuery({
            queryKey: [`${queryKeys.ipd}-${ipdID}`],
            queryFn: () => getIPD(ipdID),
            enabled: ipdID ? true : false,
        });

    return {
        ipdData: data,
        isLoading,
        isSuccess,
        isFetching,
        refetch,
        isRefetching,
    };
}


export function useAddIPD() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (ipdData) => addIPD(ipdData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipds] });
            if (data?.success) {
                showNotification("success", `IPD added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addIPD: mutate, isLoading };
}

export function useEditIPD() {
    const queryClient = useQueryClient();
    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: ({ ipdID, ipdData }) => updateIPD({ ipdID: ipdID, body: ipdData }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipds] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { editIPD: mutate, isLoading, isSuccess };
}

export function useDeleteIPD() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (ipdId) => deleteIPD(ipdId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipds] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteIPD: mutate, isLoading };
}
