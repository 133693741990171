import React, { useState } from "react";
import { useGetServices } from "../query/hooks/service/servicesHook";
import { Autocomplete, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ReusableButton from "./common/ReusableButton";
import ClearButton from "./common/ClearButton";
import { useDispatch, useSelector } from "react-redux";
import {
    clearServiceParams,
    selectServiceParams,
    setServiceParams,
} from "../redux/features/analytics/analyticsSlice";
import { useGetServiceReports } from "../query/hooks/analytics/analyticsHook";
import {
    getEndingTimeOfDate,
    getStartingTimeOfDate,
    showNotification,
} from "../services/helper";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import ServiceReportDownload from "./common/Reports/ServiceReportDownload";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const header = [
    { id: "hid", label: "HID", minWidth: 10, align: "center" },
    { id: "patient_name", label: "Patient", minWidth: 100, align: "center" },
    {
        id: "service_name",
        label: "Service",
        minWidth: 100,
        align: "center",
    },
    { id: "service_charge", label: "Charge", minWidth: 100, align: "center" },
    {
        id: "quantity",
        label: "Quantity",
        minWidth: 100,
        align: "center",
    },
    {
        id: "total",
        label: "Total",
        minWidth: 100,
        align: "center",
    },

    {
        id: "created_on",
        label: "Created On",
        minWidth: 100,
        align: "center",
    },
];
const ServiceReport = () => {
    const dispatch = useDispatch();
    const serviceParams = useSelector(selectServiceParams);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState("");

    const { serviceReportData, isFetching, isLoading, refetch, isRefetching } =
        useGetServiceReports(serviceParams);
    const { servicesData: allServiceData } = useGetServices();

    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
    };
    const handleClear = () => {
        setSelectedOption(null);
        setInputValue("");
        setStartDate(null);
        setEndDate(null)
        dispatch(clearServiceParams());
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const handleSearch = () => {
        if (!selectedOption || !startDate || !endDate || !selectedOption.id) {
            showNotification(
                "error",
                "Please provide all required parameters."
            );
            return;
        }
        dispatch(
            setServiceParams({
                ...serviceParams,
                serviceID: selectedOption.id || "",
                startDate: getStartingTimeOfDate(dayjs(startDate)) || "",
                endDate: getEndingTimeOfDate(dayjs(endDate)) || "",
            })
        );
    };
    const getTotal = (data) => {
        return data.reduce(
            (total, service) =>
                total + service.service_charge * service.quantity,
            0
        );
    };

    // Assuming serviceReportData?.result is your data array
    const total = getTotal(serviceReportData?.result || []);
    const [isBillModalOpen, setIsBillModalOpen] = useState(false);
    const [selectedIPD, setSelectedIPD] = useState("");

    const closeBillModal = () => {
        setIsBillModalOpen(false);

        setSelectedIPD("");
    };
    const handleGenerateBill = (row) => {
        setIsBillModalOpen(true);
    };
    return (
        <>
            <ServiceReportDownload
                serviceReportData={serviceReportData}
                isBillModalOpen={isBillModalOpen}
                closeBillModal={closeBillModal}
            />
            <section className="flex items-center mt-4 space-x-4">
                <div style={{ zIndex: "999" }}>
                    <DatePicker
                        maxDate={dayjs().toDate()}
                        className="px-8 py-3.5 w-[250px] border border-gray-700 rounded-xl z-50"
                        dateFormat="yyyy/MM/dd"
                        placeholderText="StartDate - EndDate"
                        selectsRange={true}
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
                <Autocomplete
                    className="w-[350px]"
                    options={allServiceData?.result?.list || []}
                    getOptionLabel={(option) => option.name}
                    value={selectedOption}
                    onChange={handleOptionChange}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    disableClearable
                    isOptionEqualToValue={(option, value) =>
                        option.name === value?.name
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search Service"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {selectedOption && (
                                            <ClearIcon
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleClear}
                                            />
                                        )}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
                <ReusableButton title="Search" onClick={handleSearch} />
                <ClearButton title="Clear" onClick={handleClear} />
                {serviceReportData && (
                    <ReusableButton
                        title="Export as PDF"
                        onClick={handleGenerateBill}
                    />
                )}
            </section>
            <section className="mt-4">
                {serviceReportData && (
                    <Paper
                        sx={{
                            width: "100%",
                            overflow: "hidden",
                            // borderRadius: "15px",
                        }}
                        // elevation={12}
                    >
                        <TableContainer
                            sx={{
                                maxHeight: 480,
                                overflowX: "auto",
                                "&::-webkit-scrollbar": {
                                    width: 0,
                                    height: "2px",
                                },
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {header.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    fontWeight: "bold",
                                                    background: "#eeeeee",
                                                    textTransform: "uppercase",
                                                    backgroundColor: "#27272A",
                                                    color: "white",
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {serviceReportData?.result?.map(
                                        (service) => (
                                            <TableRow key={service.id}>
                                                <TableCell align="center">
                                                    {service.hid}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className="capitalize"
                                                >
                                                    {service.patient_title}{" "}
                                                    {service.patient_name}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className="capitalize"
                                                >
                                                    {service.service_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {service.service_charge}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {service.quantity}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {service.service_charge *
                                                        service.quantity}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {service.created_on}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}

                                    <TableRow>
                                        <TableCell colSpan={7} align="right">
                                            <div className="flex items-center justify-end space-x-10 text-xl font-semibold mr-9">
                                                <p>Total:</p>
                                                <p>{total}</p>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                )}
            </section>
        </>
    );
};

export default ServiceReport;
