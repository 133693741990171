import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { addPayment, deletePayment, getPayments, updatePayment } from "../../../services/api";

// export function useGetPayments(params = { offset: 0, limit: 1000, name: "", department: "", startDate: null, endDate: null }) {
//     const { offset, limit, name, department, startDate, endDate } = params;
//     const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
//         queryKey: [queryKeys.payouts, { offset, limit, name, department, startDate, endDate }],
//         queryFn: () => getPayments({ offset, limit, name, department, startDate, endDate }),
//     });

//     return { paymentsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
// }

export function useGetPayments(params = { offset: 0, limit: 1000, doctor: "", department: "", startDate: null, endDate: null }) {

    const { offset, limit, doctor, department, startDate, endDate } = params;
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching, error } = useQuery({
        queryKey: [queryKeys.payouts, { offset, limit, doctor, department, startDate, endDate }],
        queryFn: () => getPayments({ offset, limit, doctor, department, startDate, endDate }),
        // enabled: startDate !== null && endDate !== null,
    });
    return { paymentsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching, error };
}



export function useAddPayment() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (paymentData) => addPayment(paymentData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.payouts] });
            if (data?.success) {
                showNotification("success", `Payment added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addPayment: mutate, isLoading };
}

export function useUpdatePayment() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updatePayment(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.payouts] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { updatePayment: mutate, isLoading };
}

export function useDeletePayment() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (paymentId) => deletePayment(paymentId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.payouts] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deletePayment: mutate, isLoading };
}
