// servicesFilterSlice.js

import { createSlice } from "@reduxjs/toolkit";

const servicesFilterInitialState = {
    defaultFilters: {
        name: "",
        offset: 0,
        limit: 10,
    },
};

const servicesFilterSlice = createSlice({
    name: "servicesFilter",
    initialState: servicesFilterInitialState,
    reducers: {
        setServicesFilterParams: (state, action) => {
            state.defaultFilters = { ...state.defaultFilters, ...action.payload };
        },
        clearServicesFilter: (state, action) => {
            state.defaultFilters = servicesFilterInitialState.defaultFilters;
        },
    },
    extraReducers: {},
});

export const selectServicesFilterParams = (state) => state.servicesFilter.defaultFilters;
export const { setServicesFilterParams, clearServicesFilter } = servicesFilterSlice.actions;
export default servicesFilterSlice.reducer;
