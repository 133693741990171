import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Typography from "@mui/material/Typography";
import { Checkbox, Pagination, TextField, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import {
    useAddUser,
    useAddUserPermission,
    useDeleteUser,
    useEditUser,
    useGetUsers,
} from "../query/hooks/users/usersHook";
import { useGetMetaData } from "../query/hooks/metadata/metadataHook";
import { showNotification } from "../services/helper";
import LoaderLogo from "./common/LoaderLogo";
import { MdOutlineLockPerson } from "react-icons/md";
import { IoPersonAdd } from "react-icons/io5";
import { AiOutlineReload } from "react-icons/ai";
import { getUserPermission } from "../services/api";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import {
    clearUser,
    selectUserParams,
    setUserParams,
} from "../redux/features/filters/userSlice";
import ReusableButton from "./common/ReusableButton";
import ClearButton from "./common/ClearButton";
import RedButton from "./common/RedButton";

const localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};
const headers = [
    { label: "Modules", align: "left" },
    { label: "Add", align: "center" },
    { label: "Update", align: "center" },
    { label: "View", align: "center" },
    { label: "Delete", align: "center" },
];
const columns = [
    { id: "id", label: "ID", minWidth: 10, align: "center" },
    { id: "name", label: "Name", minWidth: 100, align: "center" },
    { id: "mobile", label: "Mobile", minWidth: 100, align: "center" },
    { id: "email", label: "Email", minWidth: 100, align: "center" },

    { id: "actions", label: "Actions", align: "center" },
];

const initial = {
    name: "",
    email: "",
    mobile: "",
};

const Users = () => {
    const dispatch = useDispatch();
    const currentAuth = useSelector(selectCurrentAuth);
    const userParams = useSelector(selectUserParams);
    const usersPermission = currentAuth?.user?.permissions.find(
        (permission) => permission.module === 1007
    );
    const { add_permission, update_permission, delete_permission } =
        usersPermission;

    const {
        data: usersData,
        isLoading,
        isFetching,
        isRefetching,
        refetch: refetchUsers,
    } = useGetUsers(userParams);

    const { addUser, isLoading: isAddingUser } = useAddUser();

    const { editUser, isLoading: isEditingUser } = useEditUser();
    const { deleteUser, isLoading: isDeletingUser } = useDeleteUser();
    const { metaData } = useGetMetaData();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [user, setUser] = useState({ ...initial });
    const [editOpen, setEditOpen] = useState(false);
    const [editedUser, setEditedUser] = useState({});
    const [toDelete, setToDelete] = useState("");
    const [permissions, setPermissions] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let transformedValue = value;

        if (name === "mobile") {
            transformedValue = value.replace(/\D/g, "").slice(0, 10);
        }
        setUser((prevUser) => ({
            ...prevUser,

            [name]: transformedValue,
        }));
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleAdd = () => {
        const validateUser = (user) => {
            const errors = {};

            if (!user.name || user.name.trim() === "") {
                errors.name = "Name is required";
            }

            if (!user.email || user.email.trim() === "") {
                errors.email = "Email is required";
            }

            if (!user.mobile || user.mobile.trim() === "") {
                errors.mobile = "Mobile number is required";
            }

            return errors;
        };

        // Validate the user input
        const errors = validateUser(user);

        // Check if there are any errors
        if (Object.keys(errors).length > 0) {
            // Display error notifications for specific fields
            if (errors.name) {
                showNotification("error", errors.name);
            }
            if (errors.email) {
                showNotification("error", errors.email);
            }
            if (errors.mobile) {
                showNotification("error", errors.mobile);
            }
        } else {
            // If no errors, proceed with adding the user
            addUser(user);

            if (!isLoading) {
                setUser({ ...initial });
            }
            handleClose();
        }
    };

    const handleCancel = () => {
        setUser({ initial });
        handleClose();
    };

    const handleEditClick = (row) => {
        setEditedUser(row);
        setEditOpen(true);
    };

    const handleEditSave = (row) => {
        const updatedUser = {
            id: editedUser.id,
            name: editedUser.name,
            email: editedUser.email,
            mobile: editedUser.mobile,
        };

        editUser(updatedUser);
        setEditOpen(false);
    };

    // Function to open the delete confirmation dialog
    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };

    // Function to close the delete confirmation dialog
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteConfirmed = () => {
        deleteUser(toDelete);
        handleCloseDeleteDialog();
    };

    const [openPermissionModal, setOpenPermissionModal] = useState(false);
    const [roleID, setRoleID] = useState(null);
    const [permissionData, setPermissionData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const handleOpenPermissionModal = async (row) => {
        setOpenPermissionModal(true);
        setRoleID(row.id);
    };

    useEffect(() => {
        const fetchPermissionData = async () => {
            try {
                if (roleID) {
                    const res = await getUserPermission({ id: roleID });
                    if (res.result.count === 0) {
                        const arr = [];
                        for (let item of metaData?.result?.modules) {
                            arr.push({
                                user: roleID,
                                module: item.id,
                                add_permission: 0,
                                view_permission: 0,
                                update_permission: 0,
                                delete_permission: 0,
                            });
                        }
                        setPermissions(arr);
                    } else {
                        const allPermissions = [...metaData?.result?.modules];

                        const addMissingPermissions = () => {
                            const updatedUserPermission = [...res.result.list];

                            allPermissions.forEach((permission) => {
                                const isModulePresent = res?.result?.list.some(
                                    (userPerm) =>
                                        userPerm.module === permission.id
                                );

                                if (!isModulePresent) {
                                    const newPermission = {
                                        user: roleID, // Set the user ID as needed
                                        module: permission.id,
                                        view_permission: 0,
                                        add_permission: 0,
                                        update_permission: 0,
                                        delete_permission: 0,
                                        user_name:
                                            res?.result?.list[0]?.user_name,
                                        module_name: permission.name,
                                    };

                                    updatedUserPermission.push(newPermission);
                                }
                            });

                            setPermissions(updatedUserPermission);
                        };
                        addMissingPermissions();
                        // const isModule1012Present = res?.result?.list.some(
                        //     (permission) => permission.module === 1012
                        // );

                        // if (!isModule1012Present) {
                        //     const entityPermission = {
                        //         user: roleID,
                        //         module: 1012,
                        //         view_permission: 0,
                        //         add_permission: 0,
                        //         update_permission: 0,
                        //         delete_permission: 0,
                        //         user_name: res?.result?.list[0]?.user_name,
                        //         module_name: "Entity Profile",
                        //     };
                        //     setPermissions([
                        //         ...res.result.list,
                        //         entityPermission,
                        //     ]);
                        // } else {
                        //     setPermissions([...res.result.list]);
                        // }
                    }
                    setPermissionData(res);
                } else {
                    return;
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchPermissionData();
    }, [roleID]);

    const handleClosePermissionModal = () => {
        setOpenPermissionModal(false);
        setRoleID(null);
    };

    const { addPermission } = useAddUserPermission(handleClosePermissionModal);

    const handleCheckboxChange = (index, actionType) => {
        const arr = [...permissions];
        let value = arr[index][actionType];
        arr[index][actionType] = value === 1 ? 0 : 1;
        setPermissions(arr);
    };

    const handleSubmitPersmission = () => {
        addPermission({ permissions });
        setPermissions([]);
    };
    const [searchParams, setSearchParams] = useState({
        name: "",
        email: "",
        mobile: "",
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            [name]: value,
        }));
    };

    const handlePaginationChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setUserParams({
                ...userParams,
                offset: (value - 1) * userParams.limit,
            })
        );
    };
    const isValidMobileNumber = (mobile) => {
        // Remove non-numeric characters
        const cleanedMobile = mobile.replace(/\D/g, "");

        // Check if the cleaned mobile number has exactly 10 digits
        return cleanedMobile.length === 10;
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(
            setUserParams({
                ...userParams,
                name: searchParams.name || "",
                email: searchParams.email || "",
                mobile: isValidMobileNumber(searchParams.mobile)
                    ? searchParams.mobile
                    : "",
            })
        );
    };
    const clearSearch = () => {
        dispatch(clearUser());
        setSearchParams({
            name: "",
            email: "",
            mobile: "",
        });
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSubmit(event);
        }
    };
    const handleKeyPressClear = (event) => {
        if (event.key === "Escape") {
            clearSearch();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", handleKeyPressClear);
        return () => {
            document.removeEventListener("keydown", handleKeyPressClear);
        };
    }, []);
    return (
        <>
            <div className="">
                <div className="flex justify-between px-10 mb-5">
                    <div>
                        <div className="flex items-center space-x-3">
                            <Typography variant="h5">Users</Typography>
                            <Tooltip title="Refresh" placement="bottom-start">
                                <button
                                    onClick={refetchUsers}
                                    className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                    style={{ cursor: "pointer" }}
                                >
                                    <AiOutlineReload size={20} />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    {/* filter&Search */}

                    {add_permission === 1 && (
                        <Tooltip title="Add User" placement="bottom-start">
                            <button
                                onClick={handleOpen}
                                className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                            >
                                <IoPersonAdd size={18} />
                            </button>
                        </Tooltip>
                    )}
                </div>
                <div className="flex items-center w-full my-4">
                    <div>
                        <form
                            onKeyPress={handleKeyPress}
                            onSubmit={handleSubmit}
                            className="flex items-center space-x-5"
                        >
                            <TextField
                                label="Name"
                                variant="outlined"
                                name="name"
                                value={searchParams.name}
                                onChange={handleInputChange}
                            />
                            <TextField
                                label="Email"
                                variant="outlined"
                                name="email"
                                value={searchParams.email}
                                onChange={handleInputChange}
                            />
                            <TextField
                                label="Phone Number"
                                variant="outlined"
                                name="mobile"
                                value={searchParams.mobile}
                                onChange={handleInputChange}
                                inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*", // Allow only numeric input
                                    maxLength: 10,
                                }}
                                onInvalid={(e) => {
                                    e.preventDefault(); // Prevent the default validation message
                                    showNotification(
                                        "error",
                                        "Please enter a valid number."
                                    );
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                    ); // Remove non-numeric characters
                                }}
                            />
                            <ReusableButton
                                title="Search"
                                onClick={handleSubmit}
                            />

                            <ClearButton title="Clear" onClick={clearSearch} />
                        </form>
                    </div>
                </div>

                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "15px",
                    }}
                    elevation={24}
                >
                    <TableContainer
                        sx={{
                            height: 520,
                            overflowX: "scroll",
                            "&::-webkit-scrollbar": {
                                width: 0,
                            },
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                                color: "white",
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {!isLoading && !isFetching && !isRefetching ? (
                                <TableBody>
                                    {usersData?.result?.list?.map((row) => {
                                        return (
                                            <TableRow
                                                hover
                                                key={row.id}
                                                align="center"
                                            >
                                                <TableCell align="center">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.mobile}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.email}
                                                </TableCell>

                                                <TableCell
                                                    align="center"
                                                    sx={{
                                                        width: 120,
                                                    }}
                                                >
                                                    <div className="flex items-center justify-center space-x-4">
                                                        {update_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Edit Permissions"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleOpenPermissionModal(
                                                                            row
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <MdOutlineLockPerson className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                        {update_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Edit"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleEditClick(
                                                                            row
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                        {delete_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Delete"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleOpenDeleteDialog(
                                                                            row.id
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                                >
                                                                    <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            colSpan={6}
                                            align="center"
                                            sx={{
                                                width: 120,
                                            }}
                                        >
                                            <div className="flex items-center justify-center">
                                                {/* <Lottie
                                                    animationData={Loading1}
                                                    loop={true}
                                                    style={{
                                                        height: "380px",
                                                        width: "400px",
                                                    }}
                                                /> */}
                                                <LoaderLogo />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    {!isLoading && !isFetching && !isRefetching && (
                        <div className="flex items-center justify-center my-4">
                            <Pagination
                                count={Math.ceil(
                                    usersData?.result?.count / userParams.limit
                                )}
                                page={currentPage}
                                sx={{
                                    "& .MuiSelect-select": {
                                        display: "none !important",
                                    },
                                    "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                        {
                                            display: "none !important",
                                        },
                                }}
                                onChange={handlePaginationChange}
                            />
                        </div>
                    )}
                </Paper>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            Add New User
                        </Typography>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label="Name"
                            type="text"
                            required
                            fullWidth
                            value={user.name}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            required
                            id="email"
                            name="email"
                            label="Email"
                            type="text"
                            fullWidth
                            value={user.email}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            required
                            id="mobile"
                            name="mobile"
                            label="Mobile"
                            type="text"
                            fullWidth
                            value={user.mobile}
                            onChange={handleChange}
                        />

                        <div className="flex justify-between p-6">
                            <ReusableButton
                                isDisabled={isAddingUser}
                                onClick={handleAdd}
                                title="Save"
                            />

                            <RedButton
                                isDisabled={isAddingUser}
                                onClick={handleCancel}
                                title="Cancel"
                            />
                        </div>
                    </Box>
                </Fade>
            </Modal>

            <Modal
                open={editOpen}
                onClose={() => setEditOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={editOpen}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                            required
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            Edit User
                        </Typography>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="editedUser"
                            required
                            label="Username"
                            type="text"
                            fullWidth
                            value={editedUser.name}
                            onChange={(e) =>
                                setEditedUser({
                                    ...editedUser,
                                    name: e.target.value,
                                })
                            }
                        />
                        <TextField
                            required
                            autoFocus
                            margin="dense"
                            id="Email"
                            label="Email"
                            type="text"
                            fullWidth
                            value={editedUser.email}
                            onChange={(e) =>
                                setEditedUser({
                                    ...editedUser,
                                    email: e.target.value,
                                })
                            }
                        />

                        <TextField
                            required
                            autoFocus
                            margin="dense"
                            id="Mobile"
                            label="Mobile"
                            type="text"
                            fullWidth
                            value={editedUser.mobile}
                            onChange={(e) =>
                                setEditedUser({
                                    ...editedUser,
                                    mobile: e.target.value,
                                })
                            }
                        />

                        <div className="flex justify-between p-6">
                            <ReusableButton
                                isDisabled={isEditingUser}
                                onClick={handleEditSave}
                                title="Save"
                            />

                            <RedButton
                                isDisabled={isEditingUser}
                                onClick={() => setEditOpen(false)}
                                title="Cancel"
                            />
                        </div>
                    </Box>
                </Fade>
            </Modal>

            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeletingUser}
            />
            <Modal
                open={openPermissionModal}
                onClose={handleClosePermissionModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openPermissionModal}>
                    <Box>
                        <Paper
                            sx={{
                                width: "70%",
                                overflow: "hidden",
                                borderRadius: "15px",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",

                                bgcolor: "background.paper",
                                boxShadow: 24,
                                p: 4,
                            }}
                            elevation={24}
                        >
                            <TableContainer
                                sx={{
                                    height: 520,
                                    overflowX: "scroll",
                                    "&::-webkit-scrollbar": {
                                        width: 0,
                                    },
                                }}
                            >
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header, index) => (
                                                <TableCell
                                                    key={index}
                                                    align={header.align}
                                                    style={{
                                                        // minWidth:
                                                        //     header.minWidth,
                                                        fontWeight: "bold",
                                                        background: "#eeeeee",
                                                        textTransform:
                                                            "uppercase",
                                                    }}
                                                >
                                                    {header.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {metaData?.result?.modules?.map(
                                            (module, index) => (
                                                <TableRow key={module.id}>
                                                    <TableCell
                                                        style={{
                                                            fontWeight: "bold",
                                                            textTransform:
                                                                "uppercase",
                                                        }}
                                                    >
                                                        {module.name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Checkbox
                                                            checked={
                                                                permissions[
                                                                    index
                                                                ]
                                                                    ?.add_permission ===
                                                                1
                                                            }
                                                            onChange={() =>
                                                                handleCheckboxChange(
                                                                    index,
                                                                    "add_permission"
                                                                )
                                                            }
                                                        />
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Checkbox
                                                            checked={
                                                                permissions[
                                                                    index
                                                                ]
                                                                    ?.update_permission ===
                                                                1
                                                            }
                                                            onChange={() =>
                                                                handleCheckboxChange(
                                                                    index,
                                                                    "update_permission"
                                                                )
                                                            }
                                                        />
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Checkbox
                                                            checked={
                                                                permissions[
                                                                    index
                                                                ]
                                                                    ?.view_permission ===
                                                                1
                                                            }
                                                            onChange={() =>
                                                                handleCheckboxChange(
                                                                    index,
                                                                    "view_permission"
                                                                )
                                                            }
                                                        />
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Checkbox
                                                            checked={
                                                                permissions[
                                                                    index
                                                                ]
                                                                    ?.delete_permission ===
                                                                1
                                                            }
                                                            onChange={() =>
                                                                handleCheckboxChange(
                                                                    index,
                                                                    "delete_permission"
                                                                )
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>{" "}
                            <div className="flex items-center justify-around pt-6 pb-4">
                                <ReusableButton
                                    isDisabled={isEditingUser}
                                    onClick={handleSubmitPersmission}
                                    title="Save"
                                />

                                <RedButton
                                    isDisabled={isEditingUser}
                                    onClick={handleClosePermissionModal}
                                    title="Close"
                                />
                            </div>
                        </Paper>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default Users;
