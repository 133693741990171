import React from "react";
import logo from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="sticky h-[70px] top-0 z-50 flex items-center justify-end bg-black">
                <button
                    onClick={() => navigate("/")}
                    className="h-full px-4 mb-2 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    Home
                </button>
                <button
                    onClick={() => navigate("/aboutus")}
                    className="h-full px-4 mb-2 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    About Us
                </button>
                <button
                    onClick={() => navigate("/terms")}
                    className="h-full px-4 mb-2 mr-4 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    Terms & Conditions
                </button>
                <button
                    onClick={() => navigate("/contactus")}
                    className="h-full px-4 mb-2 mr-4 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    Contact Us
                </button>
            </div>
            <section className="relative flex items-center justify-center h-screen py-16 bg-black">
                <img
                    className="fixed inset-0 object-cover object-center mx-auto my-auto w-[40%] opacity-10"
                    src={logo}
                    alt="logo"
                />
                <div className="container mx-auto px-[20%] relative z-10">
                    <h2 className="mb-8 text-4xl font-bold text-center text-white">
                        About Us
                    </h2>

                    <p className="mb-8 text-lg leading-relaxed text-white">
                        At ARC, we are driven by the conviction that everyone is
                        entitled to the highest quality healthcare services,
                        irrespective of their geographical location. Our mission
                        is to eliminate the distance between patients and
                        top-tier hospital care by offering cutting-edge medical
                        software solutions.
                    </p>

                    <p className="mb-8 text-lg leading-relaxed text-white">
                        We offer a broad spectrum of services aimed at assisting
                        you in managing your medical records. This encompasses
                        record-keeping, storage, and retrieval. Our
        http://localhost:3005/admin/ipds                      state-of-the-art facilities are fortified with the
                        latest technology to ensure the security of your records
                        and their accessibility at all times.
                    </p>

                    <p className="mb-8 text-lg leading-relaxed text-white">
                        We extend our services to healthcare centres and testing
                        laboratories, serves as the reliable backbone that
                        supports a multitude of tasks, from department
                        management to patient care and billing processes.
                    </p>

                    <p className="mb-8 text-lg leading-relaxed text-white">
                        With our software, hospitals can navigate the
                        complexities of healthcare delivery with confidence.
                        It’s like having a steady hand guiding you through a
                        labyrinth, ensuring you never lose your way. The
                        stability it offers is not just about maintaining the
                        status quo, but about enabling growth and improvement in
                        a controlled and suitable manner.
                    </p>
                </div>
            </section>
        </div>
    );
};

export default AboutUs;
