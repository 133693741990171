import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getOPDFiles,
  logout,
  otpVerify,
  ptLabInvestigations,
  ptLabReport,
  ptLabTest,
  ptLogin,
  ptLogout,
  ptOPD,
  ptProfile,
} from "../../../services/api";
import { queryKeys } from "../../keys";
import {
  clearLocalStorage,
  setItem,
  showNotification,
} from "../../../services/helper";
import { logOut, setCredentials } from "../../../redux/features/auth/authSlice";
import { store } from "../../../redux/store";

const usePatientQuery = (queryKey, queryFn, enabled = true) => {
  const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } =
    useQuery({
      queryKey,
      queryFn,
      enabled,
    });

  return {
    data,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
    isRefetching,
  };
};

export function useGetPatientOPD() {
  return usePatientQuery([queryKeys.patientOPD], () => ptOPD());
}

export function useGetPatientProfile() {
  return usePatientQuery([queryKeys.patientProfile], () => ptProfile());
}

export function useGetPatientLabInvestigations() {
  return usePatientQuery([queryKeys.patientLabInvestigations], () =>
    ptLabInvestigations()
  );
}

export function useGetPatientLabTest(investigationID) {
  return usePatientQuery([queryKeys.patientLabTest, investigationID], () =>
    ptLabTest(investigationID)
  );
}

export function useGetPatientLabReport(investigationID, testID) {
  const enabled = Boolean(investigationID && testID);

  return usePatientQuery(
    [queryKeys.patientLabReport, testID],
    () => ptLabReport(investigationID, testID),
    enabled
  );
}

export const useGetPtOTP = (setLoginStep) => {
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isLoading } = useMutation({
    mutationFn: (body) => ptLogin(body),
    onSuccess: (data) => {
      if (data?.success) {
        setLoginStep(2);
        showNotification("success", data.message);
      } else {
        showNotification("error", data.message);
      }
    },
  });

  return { mutate, isSuccess, isLoading };
};

export const useVerifyPtOTP = (navigate) => {
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isLoading } = useMutation({
    mutationFn: (body) => otpVerify(body),
    onSuccess: (data) => {
      if (data?.success) {
        queryClient.invalidateQueries({ queryKey: [queryKeys.auth] });
        store.dispatch(setCredentials(data.result));
        setItem("user", data.result);
        navigate("/patient");
      } else {
        showNotification("error", data.message);
      }
    },
  });

  return { mutate, isSuccess, isLoading };
};

export function usePTLogout() {
  const queryClient = useQueryClient();

  const { mutate, isSuccess, isLoading } = useMutation({
    mutationFn: () => ptLogout(),

    onSuccess: (data) => {
      queryClient.invalidateQueries();
      if (data?.success) {
        store.dispatch(logOut());
        clearLocalStorage("user");
      }
    },
  });

  return { mutate, isSuccess, isLoading };
}

export function useGetOPDFile(opdID) {
  const { data, isLoading, isError } = useQuery({
    queryKey: [queryKeys.opdFile],
    queryFn: () => getOPDFiles(opdID),
    enabled: opdID ? true : false,
  });
  return { opdFileData: data, isLoading: false, isError: false };
}
