import React, { useState, useEffect } from "react";
import { useGetDashboard } from "../query/hooks/dashboard/dashboardHook";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Card,
    CardContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { FaRupeeSign } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { ImLab } from "react-icons/im";
import { BiSolidClinic } from "react-icons/bi";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { FaHome } from "react-icons/fa";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
    clearDashboard,
    selectDashboardParams,
    setDashboardParams,
} from "../redux/features/filters/dashboardSlice";
import { getEndingTimeOfDate, getStartingTimeOfDate } from "../services/helper";
import ClearButton from "./common/ClearButton";

const Dashboard = () => {
    const dispatch = useDispatch();
    const dashboardParams = useSelector(selectDashboardParams);
    const [dateArray, setDateArray] = useState([]);

    useEffect(() => {
        // Function to generate an array of dates
        const generateDateArray = (startDate, days) => {
            const array = [];
            for (let i = 0; i < days; i++) {
                array.push(startDate.subtract(i, "day").format("YYYY-MM-DD"));
            }
            return array;
        };

        // Get today's date
        const today = dayjs();

        // Generate an array of dates for the past 7 days
        const newArray = generateDateArray(today, 7);

        // Update state with the array of dates
        setDateArray(newArray);
    }, []);

    const { dashboardData, isLoading, refetch } =
        useGetDashboard(dashboardParams);
    const [labChartData, setLabChartData] = useState(null);
    const [opdChartData, setOpdChartData] = useState(null);
    const [generatedDates, setGeneratedDates] = useState([]);

    useEffect(() => {
        if (dashboardData?.result) {
            let dateArr = [];
            let valueArr = [];
            for (let item of dashboardData.result.labEarningSummary) {
                dateArr.push(item.date);
                valueArr.push(item.total_amount);
            }
            setLabChartData({ dateArr, valueArr });

            dateArr = [];
            valueArr = [];
            for (let item of dashboardData.result.opdEarningSummary) {
                dateArr.push(item.date);
                valueArr.push(item.total_amount);
            }
            setOpdChartData({ dateArr, valueArr });
        }
    }, [dashboardData]);

    useEffect(() => {
        if (labChartData?.dateArr) {
            const startDate = new Date(labChartData?.dateArr[0]);
            const endDate = new Date(
                labChartData.dateArr[labChartData?.dateArr.length - 1]
            );
            const generatedDateArray = [];

            for (
                let currentDate = startDate;
                currentDate <= endDate;
                currentDate.setDate(currentDate.getDate() + 4)
            ) {
                generatedDateArray.push(
                    currentDate.toISOString().split("T")[0]
                );
            }

            setGeneratedDates(generatedDateArray);
        }
    }, [labChartData]);

    const chartConfig = {
        credits: {
            enabled: false,
        },
        title: {
            text: "Revenue Trend",
        },
        xAxis: {
            categories: dateArray,
        },
        yAxis: {
            title: {
                text: "Revenue (INR)",
            },
        },
        series: [
            {
                name: "OPD",
                data: opdChartData?.valueArr || [],
            },
            {
                name: "Lab",
                data: labChartData?.valueArr || [],
            },
        ],
        chart: {
            type: "line",

            height: 400,
        },
    };
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            dispatch(
                setDashboardParams({
                    ...dashboardParams,
                    startDate: getStartingTimeOfDate(dayjs(start)) || "",
                    endDate: getEndingTimeOfDate(dayjs(end)) || "",
                })
            );
        }
    };
    const handleClear = () => {
        dispatch(clearDashboard());
        setStartDate();
        setEndDate();
    };
    return (
        <>
            <div className="flex items-center mb-3 space-x-3">
                <FaHome className="text-2xl" />
                <Typography variant="h5" className="">
                    Dashboard
                </Typography>
            </div>
            <div className="flex flex-wrap justify-around gap-5">
                <Card
                    sx={{
                        width: 250,
                        height: 120,
                        background: "#27272A",
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    <CardContent
                        sx={{
                            display: "Flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            <Typography
                                sx={{ fontSize: 14, color: "white" }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Total Revenue
                            </Typography>

                            <Typography
                                sx={{
                                    mb: 1.5,
                                    fontSize: 24,
                                    fontWeight: "bold",
                                    color: "white",
                                }}
                                color="text.secondary"
                            >
                                {dashboardData?.result?.opdAmount
                                    ? `₹ ${dashboardData.result.opdAmount}`
                                    : "Not Available"}
                            </Typography>
                            <Typography
                                sx={{ mb: 1.5, color: "white" }}
                                color="text.secondary"
                            >
                                Till Today
                            </Typography>
                        </div>
                        <div className="flex items-center">
                            <div className="flex items-center justify-center w-[60px] h-[60px] -mt-6 bg-green-600 rounded-full">
                                <FaRupeeSign className="text-[40px] font-semibold text-white" />
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <Card
                    sx={{
                        width: 250,
                        height: 120,
                        background: "#27272A",
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    <CardContent
                        sx={{
                            display: "Flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            <Typography
                                sx={{ fontSize: 14, color: "white" }}
                                color="text.secondary"
                                gutterBottom
                            >
                                OPDs
                            </Typography>

                            <Typography
                                sx={{
                                    mb: 1.5,
                                    fontSize: 24,
                                    fontWeight: "bold",
                                    color: "white",
                                }}
                                color="text.secondary"
                            >
                                {dashboardData?.result?.opdCount ||
                                    "Not Available"}
                            </Typography>
                            <Typography
                                sx={{ mb: 1.5, color: "white" }}
                                color="text.secondary"
                            >
                                Till Today
                            </Typography>
                        </div>
                        <div className="flex items-center justify-center">
                            <div className="flex items-center justify-center w-[60px] -mt-6 h-[60px] bg-[#1D4ED8] rounded-full">
                                <BiSolidClinic className="text-[40px] font-semibold text-white" />
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <Card
                    sx={{
                        width: 250,
                        height: 120,
                        background: "#27272A",
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    <CardContent
                        sx={{
                            display: "Flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            <Typography
                                sx={{ fontSize: 14, color: "white" }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Total Patients
                            </Typography>

                            <Typography
                                sx={{
                                    mb: 1.5,
                                    fontSize: 24,
                                    fontWeight: "bold",
                                    color: "white",
                                }}
                                color="text.secondary"
                            >
                                {dashboardData?.result?.patientCount ||
                                    "Not Available"}
                            </Typography>
                            <Typography
                                sx={{ mb: 1.5, color: "white" }}
                                color="text.secondary"
                            >
                                Till Today
                            </Typography>
                        </div>
                        <div className="flex items-center justify-center">
                            <div className="flex items-center justify-center w-[60px] -mt-6 h-[60px] bg-[#DB2777] rounded-full">
                                <IoPerson className="text-[40px] font-semibold text-white" />
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <Card
                    sx={{
                        width: 250,
                        height: 120,
                        background: "#27272A",
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    <CardContent
                        sx={{
                            display: "Flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            <Typography
                                sx={{ fontSize: 14, color: "white" }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Test
                            </Typography>

                            <Typography
                                sx={{
                                    mb: 1.5,
                                    fontSize: 24,
                                    fontWeight: "bold",
                                    color: "white",
                                }}
                                color="text.secondary"
                            >
                                {dashboardData?.result?.labSummary
                                    .total_count || "Not Available"}
                            </Typography>
                            <Typography
                                sx={{ mb: 1.5, color: "white" }}
                                color="text.secondary"
                            >
                                Till Today
                            </Typography>
                        </div>
                        <div className="flex items-center justify-center">
                            <div className="flex items-center justify-center w-[60px] -mt-6 h-[60px] bg-[#6D28D9] rounded-full">
                                <ImLab className="text-[40px] font-semibold text-white" />
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>

            <div className="flex justify-end mt-12 space-x-2">
                <DatePicker  maxDate={dayjs().toDate()}
                    className="px-8 py-3 w-[250px] border border-gray-700 rounded-xl"
                    dateFormat="yyyy/MM/dd"
                    placeholderText="StartDate - EndDate"
                    selectsRange={true}
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                />
                <ClearButton title="Clear" onClick={handleClear} />
            </div>
            <section className="mt-4">
                <div>
                    {opdChartData && (
                        <div>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chartConfig}
                            />
                        </div>
                    )}
                </div>
            </section>
            <section className="flex w-full pb-10 space-x-6">
                <section className="w-full ">
                    <Paper
                        sx={{
                            mt: 5,
                            width: "100%",
                            overflow: "hidden",
                            borderRadius: "15px",
                        }}
                        elevation={24}
                    >
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                backgroundColor: "#27272A",
                                color: "white",
                                fontWeight: "bold",
                                padding: "2px",
                                paddingLeft: "15px",
                                mb: -1,
                            }}
                        >
                            OPD Details
                        </Typography>
                        <TableContainer
                            component={Paper}
                            sx={{
                                height: 321,
                                overflowX: "scroll",
                                "&::-webkit-scrollbar": {
                                    width: 0,
                                },
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow
                                        sx={{ backgroundColor: "#27272A" }}
                                    >
                                        <TableCell
                                            sx={{
                                                color: "white",
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                            }}
                                        >
                                            Department
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "white",
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                            }}
                                        >
                                            Total Count
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "white",
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                            }}
                                        >
                                            Total Amount
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dashboardData?.result?.opdSummary ? (
                                        dashboardData.result.opdSummary.map(
                                            (row) => (
                                                <TableRow key={row.test}>
                                                    <TableCell>
                                                        {row.department_name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.total_count}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.total_amount}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={3}
                                                align="center"
                                            >
                                                No Data Available
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </section>
                <section className="w-full ">
                    <Paper
                        sx={{
                            mt: 5,
                            width: "100%",
                            overflow: "hidden",
                            borderRadius: "15px",
                        }}
                        elevation={24}
                    >
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                backgroundColor: "#27272A",
                                color: "white",
                                fontWeight: "bold",
                                padding: "2px",
                                paddingLeft: "15px",
                                mb: -1,
                            }}
                        >
                            Lab Details
                        </Typography>
                        <TableContainer
                            component={Paper}
                            sx={{
                                height: 321,
                                overflowX: "scroll",
                                "&::-webkit-scrollbar": {
                                    width: 0,
                                },
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow
                                        style={{ backgroundColor: "#27272A" }}
                                    >
                                        <TableCell
                                            sx={{
                                                color: "white",
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                            }}
                                        >
                                            Test Name
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "white",
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                            }}
                                        >
                                            Total Count
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dashboardData?.result?.testSummary ? (
                                        dashboardData.result.testSummary.map(
                                            (row) => (
                                                <TableRow key={row.test}>
                                                    <TableCell>
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.total_count}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={2}
                                                align="center"
                                                sx={{ height: "100%" }}
                                            >
                                                No Data Available
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </section>
            </section>
        </>
    );
};

export default Dashboard;
