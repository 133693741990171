import { isError, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { store } from "../../../redux/store";
import { getServices, addService, updateService, deleteService } from "../../../services/api";
import { useSelector } from "react-redux";
import { showNotification } from "../../../services/helper";


export function useGetServices(params = { offset: 0, limit: 1000, name: "" }) {
    const { offset, limit, name } = params;

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.services, { offset, limit, name }],
        queryFn: () => getServices({ offset, limit, name }),
    });

    return { servicesData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}


export function useAddService() {

    
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (serviceData) => addService(serviceData), 

        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.services] });
            if (data?.success) {
                showNotification("success", `Service added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addService: mutate, isLoading };
}


export function useEditService() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updateService(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.services] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { editService: mutate, isLoading };
}


export function useDeleteService() {


    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (serviceId) => deleteService(serviceId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.services] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteService: mutate, isLoading };
}
