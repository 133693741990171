// PasswordChange.js

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { getItem, showNotification } from "../services/helper";
import { useUpdatePassword } from "../query/hooks/users/usersHook";

const PasswordChange = () => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
    });

    const [error, setError] = useState("");
    const user = getItem("user");

    const { updatePw, isLoading, isSuccess } = useUpdatePassword(setFormData);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePasswordChange = () => {
        // Validate passwords
        if (formData.email !== user.email) {
            showNotification(
                "error",
                "Make sure you entered your registered Email."
            );
            return;
        }
        if (
            formData.newPassword.length < 8 ||
            !/\d/.test(formData.newPassword) ||
            formData.newPassword !== formData.confirmPassword
        ) {
            showNotification(
                "error",
                formData.newPassword.length < 8
                    ? "New password must be at least 8 characters long."
                    : !/\d/.test(formData.newPassword)
                    ? "New password must contain at least 1 numeric character."
                    : "New password and confirm password do not match."
            );
            return;
        }

        const updatedData = {
            email: formData.email,
            password: formData.password,
            newPassword: formData.newPassword,
        };
        updatePw({ id: user.id, body: updatedData });
        if (isSuccess) {
            setFormData({
                email: "",
                password: "",
                newPassword: "",
                confirmPassword: "",
            });
        }
    };

    return (
        <div className="relative flex items-center justify-center min-h-screen">
            <Paper
                elevation={3}
                className="p-6"
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -100%)",
                    boxShadow: 24,
                    borderRadius: "5px",
                    width: 500,
                    mt: 10,
                }}
            >
                <form>
                    <TextField
                        label="Registered Email"
                        type="email"
                        fullWidth
                        disabled={isLoading}
                        margin="normal"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Current Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                    />
                    <TextField
                        disabled={isLoading}
                        label="New Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        name="newPassword"
                        value={formData.newPassword}
                        onChange={handleChange}
                    />
                    <TextField
                        disabled={isLoading}
                        label="Confirm New Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                    />
                    {error && <div className="text-red-500">{error}</div>}
                    <div className="flex items-center justify-center w-full my-6">
                        <Button
                            disabled={isLoading}
                            variant="contained"
                            color="primary"
                            onClick={handlePasswordChange}
                        >
                            Change Password
                        </Button>
                    </div>
                </form>
            </Paper>
        </div>
    );
};

export default PasswordChange;
