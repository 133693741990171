// opdSlice.js

import { createSlice } from "@reduxjs/toolkit";

const opdInitialState = {
    defaultParams: {
        id: "",
        name: "",
        hid: "",
        department: "",
        mobile: "",
        offset: 0,
        limit: 10,
        startDate: "",
        endDate: "",
        ptId: ""
    },
    searchField: {
        fileValue: "",
    },
    selectedOption: "",
};

const opdSlice = createSlice({
    name: "opd",
    initialState: opdInitialState,
    reducers: {
        setOPDParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },
        setSearchFieldOPD: (state, action) => {
            const { fileValue } = action.payload;
            state.searchField.fileValue = fileValue;
        },
        setSelectedOPDOption: (state, action) => {
            state.selectedOption = action.payload;
        },
        clearOPD: (state, action) => {
            state.defaultParams = opdInitialState.defaultParams;
            state.searchField = opdInitialState.searchField;
            state.selectedOption = opdInitialState.selectedOption;
        },
    },
    extraReducers: {},
});

export const selectOPDParam = (state) => state.opd.defaultParams;
export const selectSearchFieldOPD = (state) => state.opd.searchField;
export const selectSelectedOPDOption = (state) => state.opd.selectedOption;
export const { setOPDParams, setSearchFieldOPD, setSelectedOPDOption, clearOPD } = opdSlice.actions;
export default opdSlice.reducer;
