import React, { useState, useRef } from "react";
import { addReport, uploadFile } from "../../services/api";
import { showNotification } from "../../services/helper";
import { BsCloudUpload } from "react-icons/bs";
import { Tooltip } from "@mui/material";
import { useAddReport } from "../../query/hooks/test/testHook";
const PdfUploadButton = ({
    lab,
    investigationID,
    uploadedList,
    onUploadedListChange,
}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const { addNewReport, isLoading } = useAddReport();

    const uploadPdf = async (file) => {
        try {
            const formData = new FormData();
            formData.append("file", file);

            const response = await uploadFile(formData);
            if (response.status === 200 && response.data.success) {
                const updatedReport = {
                    test: lab.id,
                    investigation: investigationID.id,
                    report: response.data.result,
                };

                onUploadedListChange((prevObject) => ({
                    ...prevObject,
                    [lab.id]: { test: lab.id },
                }));

                // await addReport(updatedReport);
                addNewReport(updatedReport);
            }
            showNotification("success", "File uploaded successfully");
        } catch (error) {
            console.error(error);
            showNotification("error", "Cannot Upload Document");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/pdf") {
            setSelectedFile(file);
            uploadPdf(file);
        } else {
            setSelectedFile(null);
            showNotification("error", "Please select a valid PDF file.");
        }
    };
    return (
        <div className="">
            <input
                type="file"
                ref={fileInputRef}
                accept=".pdf"
                onChange={handleFileChange}
                className="hidden"
            />
            {selectedFile ? (
                <p>{selectedFile.name}</p>
            ) : (
                <Tooltip title="Upload Report" placement="bottom-start">
                    <button
                        className="px-4 py-2 text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-600"
                        onClick={() => {
                            fileInputRef.current.click();
                        }}
                    >
                        <BsCloudUpload />
                    </button>
                </Tooltip>
            )}
        </div>
    );
};

export default PdfUploadButton;
