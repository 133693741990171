import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useGetDoctorCollection } from "../query/hooks/analytics/analyticsHook";
import {
  selectEmployeesParam,
  setEmployeesParams,
} from "../redux/features/filters/employeeSlice";
import { useGetEmployees } from "../query/hooks/employee/employeeHooks";
import {
  clearDoctorParams,
  selectDoctorParams,
  setDoctorParams,
} from "../redux/features/analytics/analyticsSlice";
import ClearButton from "./common/ClearButton";
import {
  getEndingTimeOfDate,
  getStartingTimeOfDate,
  showNotification,
} from "../services/helper";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ReusableButton from "./common/ReusableButton";
import DoctorCollectionReport from "./common/Reports/DoctorCollectionReport";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const otColumn = [
  { id: "hid", label: "HID", minWidth: 10, align: "center" },
  { id: "patient_name", label: "Patient", minWidth: 100, align: "center" },
  {
    id: "ot_procedure",
    label: "OT Procedure",
    minWidth: 100,
    align: "center",
  },
  { id: "doctor_name", label: "Doctor", minWidth: 100, align: "center" },
  {
    id: "surgeon_charge",
    label: "Surgeon Charge",
    minWidth: 100,
    align: "center",
  },
  {
    id: "anaesthesia_charge",
    label: "Anaesthesia Charge",
    minWidth: 100,
    align: "center",
  },
  { id: "Total", label: "Total Amount", minWidth: 100, align: "center" },
];
const OPDHeaders = [
  { id: "hid", label: "HID", minWidth: 10, align: "center" },
  { id: "patient_name", label: "Patient", minWidth: 100, align: "center" },
  { id: "doctor_name", label: "Doctor", minWidth: 100, align: "center" },
  {
    id: "charge",
    label: "Surgeon Charge",
    minWidth: 100,
    align: "center",
  },
  {
    id: "discount_amount",
    label: "Discount Amount",
    minWidth: 100,
    align: "center",
  },
  { id: "Payable_Amt", label: "Payable Amt", minWidth: 100, align: "center" },
  {
    id: "created_on",
    label: "Created On",
    minWidth: 100,
    align: "center",
  },
];

const MotHeader = [
  { id: "hid", label: "HID", minWidth: 10, align: "center" },
  { id: "patient_name", label: "Patient", minWidth: 100, align: "center" },
  {
    id: "service_names",
    label: "Service Names",
    minWidth: 100,
    align: "center",
  },
  { id: "doctor_name", label: "Doctor", minWidth: 100, align: "center" },
  {
    id: "payable_amount",
    label: "Payable Amount",
    minWidth: 100,
    align: "center",
  },

  { id: "Total", label: "Total Amount", minWidth: 100, align: "center" },
];

const tabsName = [
  { id: 1, label: "OTS" },
  { id: 2, label: "OPDS" },
  { id: 3, label: "MOTS" },
];
const DoctorCollections = () => {
  const dispatch = useDispatch();
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const employeeParams = useSelector(selectEmployeesParam); // Assuming you have this selector
  const doctorParams = useSelector(selectDoctorParams);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(); // Assuming you have this selector
  const [newParams, setNewParams] = useState({ ...employeeParams });

  const { employeesData } = useGetEmployees(); // Assuming you have this custom hook

  const { collectionData, isLoading, isFetching, refetch } =
    useGetDoctorCollection(doctorParams); // Assuming you have this custom hook
  console.log("collectionData", collectionData);
  // Filter out doctors from employeesData
  const filteredDoctors = employeesData?.result?.list?.filter(
    (employee) => employee.designation_name === "Doctor"
  );
  const handleTabClick = (id) => {
    setActiveTab(id);
  };
  const handleClearAnalytics = () => {
    dispatch(clearDoctorParams());
    setStartDate(null);
    setEndDate(null);
    setSelectedDoctor(null);
  };
  const handleDoctorChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedDoctor(selectedValue);

    // dispatch(
    //     setDoctorParams({
    //         ...doctorParams,
    //         doctorID: selectedValue,
    //     })
    // );
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // if (start && end) {
    //     dispatch(
    //         setDoctorParams({
    //             ...doctorParams,
    //             startDate: getStartingTimeOfDate(dayjs(start)) || "",
    //             endDate: getEndingTimeOfDate(dayjs(end)) || "",
    //         })
    //     );
    // }
  };
  const handleSearch = () => {
    // Check if all three values exist
    if (!startDate || !endDate || !selectedDoctor) {
      // If any of the values are missing, show an error or handle it as needed
      showNotification("error", "Please provide all required parameters.");
      // You can also return or throw an error, or show a message to the user
      return;
    }
    dispatch(
      setDoctorParams({
        ...doctorParams,
        startDate: getStartingTimeOfDate(dayjs(startDate)) || "",
        endDate: getEndingTimeOfDate(dayjs(endDate)) || "",
        doctorID: selectedDoctor,
      })
    );
  };
  const getTotal = (data) => {
    return data.reduce(
      (total, opd) => total + (opd.charge - opd.discount_amount),
      0
    );
  };
  const getTotalPayableAmount = (data) => {
    return data.reduce((total, item) => total + item.payable_amount, 0);
  };
  const getTotalOTSCharges = (data) => {
    return data.reduce(
      (total, ot) => total + (ot.surgeon_charge + ot.anaesthesia_charge),
      0
    );
  };

  const totalOTSCharges = getTotalOTSCharges(collectionData?.result?.ots || []);

  const totalMot = getTotalPayableAmount(collectionData?.result?.mots || []);
  const totalOPD = getTotal(collectionData?.result?.opds || []);
  const [isBillModalOpen, setIsBillModalOpen] = useState(false);
  const [selectedIPD, setSelectedIPD] = useState("");

  const closeBillModal = () => {
    setIsBillModalOpen(false);

    setSelectedIPD("");
  };
  const handleGenerateBill = (row) => {
    setIsBillModalOpen(true);
  };

  const checkArraysNotEmpty = (collections) => {
    if (collections && collections.result) {
      const { mots = [], opds = [], ots = [] } = collections.result;
      return mots.length !== 0 || opds.length !== 0 || ots.length !== 0;
    }
    return false;
  };
  const result = checkArraysNotEmpty(collectionData);

  return (
    <>
      <DoctorCollectionReport
        collectionData={collectionData}
        isBillModalOpen={isBillModalOpen}
        closeBillModal={closeBillModal}
      />
      <div>
        <div className="flex my-2 space-x-2">
          <div style={{ zIndex: "999" }}>
            <DatePicker
              maxDate={dayjs().toDate()}
              className="px-8 py-3.5 w-[250px] border border-gray-700 rounded-xl z-50"
              dateFormat="yyyy/MM/dd"
              placeholderText="StartDate - EndDate"
              selectsRange={true}
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <FormControl
            sx={{
              width: "30%",
            }}
            variant="outlined"
            margin="none"
          >
            <InputLabel id="doctor-label">Doctor</InputLabel>
            <Select
              label="Doctor"
              id="doctor"
              value={selectedDoctor || ""}
              onChange={handleDoctorChange}
            >
              {filteredDoctors?.map((doctor) => (
                <MenuItem
                  key={doctor.id}
                  value={doctor.id}
                  className="capitalize"
                >
                  {doctor.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ReusableButton title="Search" onClick={handleSearch} />

          {result && (
            <ClearButton title="Clear" onClick={handleClearAnalytics} />
          )}
          {result && (
            <ReusableButton
              title="Export as PDF"
              onClick={handleGenerateBill}
            />
          )}
        </div>
      </div>
      <section className="mt-4">
        {collectionData && (
          <>
            <section className="flex items-center my-6 space-x-5">
              {tabsName.map((item) => (
                <button
                  key={item.id}
                  className={`
                        cursor-pointer px-2 py-2 rounded-lg border-b-[4px] border-[1px]
                        transition-all duration-300
                        ${
                          activeTab === item.id
                            ? "bg-white text-black border-black"
                            : "bg-blue-500 text-white border-blue-600"
                        }
                    `}
                  onClick={() => handleTabClick(item.id)}
                >
                  {item.label}
                </button>
              ))}
            </section>
            {activeTab === 1 && (
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  // borderRadius: "15px",
                }}
                elevation={12}
              >
                <TableContainer
                  sx={{
                    minHeight: 120,
                    maxHeight: 480,
                    overflowX: "scroll",
                    "&::-webkit-scrollbar": {
                      width: 0,
                    },
                  }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {otColumn.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              fontWeight: "bold",
                              background: "#eeeeee",
                              textTransform: "uppercase",
                              backgroundColor: "#27272A",
                              color: "white",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {collectionData?.result?.ots?.length > 0 ? (
                        <>
                          {collectionData?.result?.ots?.map((ot) => (
                            <TableRow key={ot.id}>
                              <TableCell align="center">{ot.hid}</TableCell>
                              <TableCell align="center" className="capitalize">
                                {ot.patient_title} {ot.patient_name}
                              </TableCell>
                              <TableCell align="center" className="capitalize">
                                {ot.ot_procedure}
                              </TableCell>
                              <TableCell align="center" className="capitalize">
                                {ot.doctor_name}
                              </TableCell>
                              <TableCell align="center">
                                {ot.surgeon_charge}
                              </TableCell>
                              <TableCell align="center">
                                {ot.anaesthesia_charge}
                              </TableCell>
                              <TableCell align="center">
                                {ot.surgeon_charge + ot.anaesthesia_charge}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell colSpan={7} align="right">
                              <div className="flex items-center justify-end space-x-10 text-xl font-semibold mr-9">
                                <p>Total:</p>
                                <p>{totalOTSCharges}</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            No data available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
            {activeTab === 2 && (
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  // borderRadius: "15px",
                }}
                // elevation={12}
              >
                <TableContainer
                  sx={{
                    maxHeight: 480,
                    overflowX: "scroll",
                    "&::-webkit-scrollbar": {
                      width: 0,
                    },
                  }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {OPDHeaders.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              fontWeight: "bold",
                              background: "#eeeeee",
                              textTransform: "uppercase",
                              backgroundColor: "#27272A",
                              color: "white",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {collectionData?.result?.opds?.length > 0 ? (
                        <>
                          {collectionData?.result?.opds?.map((opd) => (
                            <TableRow key={opd.id}>
                              <TableCell align="center">{opd.hid}</TableCell>
                              <TableCell align="center" className="capitalize">
                                {opd.patient_title} {opd.patient_name}
                              </TableCell>
                              <TableCell align="center">
                                {opd.doctor_name}
                              </TableCell>
                              <TableCell align="center" className="capitalize">
                                {opd.charge}
                              </TableCell>
                              <TableCell align="center">
                                {opd.discount_amount}
                              </TableCell>
                              <TableCell align="center">
                                {opd.charge - opd.discount_amount}
                              </TableCell>
                              <TableCell align="center">
                                {opd.created_on}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell colSpan={7} align="right">
                              <div className="flex items-center justify-end space-x-10 text-xl font-semibold mr-9">
                                <p>Total:</p>
                                <p>{totalOPD}</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            No data available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
            {activeTab === 3 && (
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  // borderRadius: "15px",
                }}
                // elevation={12}
              >
                <TableContainer
                  sx={{
                    maxHeight: 480,
                    overflowX: "scroll",
                    "&::-webkit-scrollbar": {
                      width: 0,
                    },
                  }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {MotHeader.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              fontWeight: "bold",
                              background: "#eeeeee",
                              textTransform: "uppercase",
                              backgroundColor: "#27272A",
                              color: "white",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {collectionData?.result?.mots?.length > 0 ? (
                        <>
                          {collectionData?.result?.mots?.map((mot) => (
                            <TableRow key={mot.id}>
                              <TableCell align="center">{mot.hid}</TableCell>
                              <TableCell align="center" className="capitalize">
                                {mot.patient_title} {mot.patient_name}
                              </TableCell>
                              <TableCell align="center">
                                {mot.service_names}
                              </TableCell>
                              <TableCell align="center" className="capitalize">
                                {mot.doctor_name}
                              </TableCell>
                              <TableCell align="center">
                                {mot.payable_amount}
                              </TableCell>
                              <TableCell align="center">
                                {mot.created_on}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell colSpan={6} align="right">
                              <div className="flex items-center justify-end space-x-10 text-xl font-semibold mr-9">
                                <p>Total:</p>
                                <p>{totalMot}</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            No data available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default DoctorCollections;
