import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";

const TabPanel = ({ children, value, index }) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

const TabView = ({ pages }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                // Change the indicator color to match the background color
            >
                {pages.map((page, index) => (
                    <Tab
                        key={index}
                        label={page.tabName}
                        sx={{
                            fontWeight: "bold", // Make the tab label bold
                        }}
                    />
                ))}
            </Tabs>
            {pages.map((page, index) => (
                <TabPanel key={index} value={value} index={index}>
                    {page.content}
                </TabPanel>
            ))}
        </div>
    );
};

export default TabView;
