import React, { useEffect, useState } from 'react'
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import AdminPanel from './pages/AdminPanel'
import PageNotFound from './pages/PageNotFound'
import { getItem, setItem } from './services/helper'
import { useDispatch } from 'react-redux'
import { setClinic, setCredentials } from './redux/features/auth/authSlice'
import PrivateRoute from './components/common/PrivateRoute'
import LoaderLogo from './components/common/LoaderLogo'
import PatientDashboard from './components/mobile/PatientDashboard'
import PatientsPanel from './pages/PatientsPanel'
import PatientPrivateRoute from './components/common/PatientPrivateRoute'
import ForgetPassword from './components/common/ForgotPassword'
import AboutUs from './components/AboutUs'
import Terms from './components/Terms'
import ContactUs from './components/ContactUs'
import { useGetClinic } from './query/hooks/clinic/clinicHook'


const App = () => {
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [entity, setEntity] = useState("");


	useEffect(() => {
		const user = getItem('user');
		if (user) {
			dispatch(setCredentials(user));
			if (user.token && user.entity) {
				if (location.pathname === '/' && location.pathname !== '/aboutus' && location.pathname !== '/terms' && location.pathname !== '/contactus') {
					navigate("/admin");
				}
			} else if (user.token && user.hid) {

				if (location.pathname === '/' && location.pathname !== '/aboutus' && location.pathname !== '/terms' && location.pathname !== '/contactus') {
					navigate("/patient");
				}
			}
			setIsLoading(false);

			return;
		} else {

			if (location.pathname !== '/aboutus' && location.pathname !== '/terms') {
				navigate("/");
			}
			setIsLoading(false);
		}
	}, []);




	// if (isLoading) return <p>Loading...</p>;
	if (isLoading) return (
		<div className='flex items-center justify-center'>
			<LoaderLogo />
		</div>)
	return (
		<div className='font-monserrat'>
			<Routes path='/' element={<Outlet />} >
				<Route index element={<LoginPage />} />
				<Route path="/aboutus" element={<AboutUs />} />

				<Route path="/terms" element={<Terms />} />
				<Route path="/contactus" element={<ContactUs />} />
				<Route path="/accountrecovery" element={<ForgetPassword />} />

				<Route element={<PatientPrivateRoute />}>
					<Route path="/patient/*" element={<PatientsPanel />} />
				</Route>

				<Route element={<PrivateRoute />}>
					<Route path="/admin/*" element={<AdminPanel />} />
				</Route>

				<Route path="*" element={< PageNotFound />} />
				<Route />
			</Routes>
		</div>
	)
}

export default App