import React, { useState } from "react";
import {
    useAddAnalytic,
    useDeleteAnalytic,
    useGetAnalytics,
    useUpdateAnalytic,
} from "../query/hooks/analytics/analyticsHook";
import { useDispatch, useSelector } from "react-redux";
import {
    clearAnalytics,
    selectAnalyticsParams,
    setAnalyticsParams,
} from "../redux/features/analytics/analyticsSlice";
import {
    getEndingTimeOfDate,
    getPermissionsByModule,
    getStartingTimeOfDate,
} from "../services/helper";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClearButton from "./common/ClearButton";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from "@mui/material";
import ReusableButton from "./common/ReusableButton";
import AccountBills from "./AccountBills";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const refundColumns = [
    { id: "hid", label: "HID", minWidth: 100, align: "center" },
    { id: "patient_name", label: "Patient Name", minWidth: 100, align: "left" },
    {
        id: "deposit_amount",
        label: "Deposit Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "adjusted_amount",
        label: "Adjusted Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "refunded_amount",
        label: "Refunded Amount",
        minWidth: 100,
        align: "center",
    },
    { id: "created_on", label: "Created On", minWidth: 100, align: "center" },
];
const opdColumn = [
    { id: "hid", label: "HID", minWidth: 100, align: "center" },
    {
        id: "patient_name",
        label: "Patient Name",
        minWidth: 100,
        align: "left",
    },
    { id: "charge", label: "Charge", minWidth: 100, align: "center" },
    {
        id: "discount_amount",
        label: "Discount Amount",
        minWidth: 100,
        align: "center",
    },
    { id: "doctor_name", label: "Doctor Name", minWidth: 100, align: "center" },
    {
        id: "payment_mode",
        label: "Payment Mode",
        minWidth: 100,
        align: "center",
    },
];
const ipdsColumn = [
    { id: "hid", label: "HID", minWidth: 10, align: "center" },
    {
        id: "patient_name",
        label: "Patient Name",
        width: 120,
        align: "left",
    },
    {
        id: "admission_charge",
        label: "Admission Charge",
        minWidth: 100,
        align: "center",
    },
    {
        id: "payment_mode",
        label: "Payment Mode",
        minWidth: 100,
        align: "center",
    },
];
const ipdBillColumns = [
    { id: "hid", label: "HID", minWidth: 10, align: "center" },
    {
        id: "patient_name",
        label: "Patient Name",
        minWidth: 100,
        align: "center",
    },
    { id: "doctor_name", label: "Doctor Name", minWidth: 100, align: "center" },
    {
        id: "total_amount",
        label: "Total Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "deposit_amount",
        label: "Deposit Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "discount_amount",
        label: "Discount Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "payable_amount",
        label: "Payable Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "payment_mode",
        label: "Payment Mode",
        minWidth: 100,
        align: "center",
    },
    { id: "paid_on", label: "Paid On", minWidth: 100, align: "center" },
];

const motColumn = [
    { id: "id", label: "ID", minWidth: 10, align: "center" },
    {
        id: "payable_amount",
        label: "Payable Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "discount_amount",
        label: "Discount Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "payment_mode",
        label: "Payment Mode",
        minWidth: 100,
        align: "center",
    },
];
const labInvColumns = [
    { id: "id", label: "ID", minWidth: 10, align: "center" },
    {
        id: "payable_amount",
        label: "Payable Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "discount_amount",
        label: "Discount Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "payment_mode",
        label: "Payment Mode",
        minWidth: 100,
        align: "center",
    },
];
const depositColumns = [
    { id: "id", label: "ID", minWidth: 10, align: "center" },
    { id: "amount", label: "Amount", minWidth: 100, align: "center" },
    {
        id: "payment_mode",
        label: "Payment Mode",
        minWidth: 100,
        align: "center",
    },
];
const doctorPaymentsColumns = [
    { id: "id", label: "ID", minWidth: 10, align: "center" },
    { id: "doctor_name", label: "Doctor", minWidth: 100, align: "center" },
    { id: "amount", label: "Amount", minWidth: 100, align: "center" },
    {
        id: "created_on",
        label: "Paid on",
        minWidth: 100,
        align: "center",
    },
];
const expenseColumn = [
    { id: "id", label: "ID", minWidth: 10, align: "center" },
    { id: "type", label: "Type", minWidth: 100, align: "center" },
    { id: "amount", label: "Amount", minWidth: 100, align: "center" },
    { id: "date", label: "Date", minWidth: 100, align: "center" },
];
const buttonName = [
    { id: 1, label: "Deposits" },
    { id: 2, label: "Doctor Payments" },
    { id: 3, label: "Expenses" },
    { id: 4, label: "IPD Bills" },
    { id: 5, label: "IPDs" },
    { id: 6, label: "Lab Investigations" },
    { id: 7, label: "Minor OTs" },
    { id: 8, label: "OPDs" },
    { id: 9, label: "Refunds" },
];
const Analytics = () => {
    const currentAuth = useSelector(selectCurrentAuth);
    const permissionsList = currentAuth?.user?.permissions;
    const analyticsPermissions = getPermissionsByModule(1022, permissionsList);
    const {
        add_permission,
        update_permission,
        delete_permission,
        view_permission,
    } = analyticsPermissions;

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const dispatch = useDispatch();
    const analyticsParams = useSelector(selectAnalyticsParams);
    const [activeButton, setActiveButton] = useState(1);
    const handleClearAnalytics = () => {
        dispatch(clearAnalytics());
        setStartDate(null);
        setEndDate(null);
    };

    const { analyticsData, isLoading: isAnalyticsLoading } =
        useGetAnalytics(analyticsParams);

    const { addAnalytic, isLoading: isAddingAnalytic } = useAddAnalytic();

    const { updateAnalytic, isLoading: isUpdatingAnalytic } =
        useUpdateAnalytic();

    const { deleteAnalytic, isLoading: isDeletingAnalytic } =
        useDeleteAnalytic();

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            dispatch(
                setAnalyticsParams({
                    ...analyticsParams,
                    startDate: getStartingTimeOfDate(dayjs(start)) || "",
                    endDate: getEndingTimeOfDate(dayjs(end)) || "",
                })
            );
        }
    };

    const handleClick = (id) => {
        setActiveButton(id);
    };
    const [isBillModalOpen, setIsBillModalOpen] = useState(false);
    const [selectedIPD, setSelectedIPD] = useState("");

    const closeBillModal = () => {
        setIsBillModalOpen(false);

        setSelectedIPD("");
    };
    const handleGenerateBill = (row) => {
        setIsBillModalOpen(true);
    };
    const getTotal = (data) => {
        return data.reduce((total, item) => total + item.amount, 0);
    };
    const getTotalPayable = (data) => {
        return data.reduce((total, item) => total + item.payable_amount, 0);
    };
    const getTotalIPDCharge = (data) => {
        return data.reduce((total, item) => total + item.admission_charge, 0);
    };

    // Assuming labTestReportData?.result is your data array
    const totalDeposit = getTotal(analyticsData?.result?.deposits || []);

    const totals = (analyticsData?.result?.deposits || []).reduce(
        (totals, deposit) => {
            if (deposit.payment_mode === "cash") {
                totals.totalCashDeposits += deposit.amount;
            } else if (deposit.payment_mode === "card") {
                totals.totalCardDeposits += deposit.amount;
            }
            return totals;
        },
        { totalCashDeposits: 0, totalCardDeposits: 0 }
    );
    const { totalCashDeposits, totalCardDeposits } = totals;

    const totalDoctorPayout = getTotal(
        analyticsData?.result?.doctorPayments || []
    );
    const totalExpenses = getTotal(analyticsData?.result?.expenses || []);
    const totalIPDBills = getTotalPayable(
        analyticsData?.result?.ipdBills || []
    );

    const totalPaidByCash = analyticsData?.result?.ipds?.reduce(
        (total, item) => {
            if (item.payment_mode === "cash") {
                return total + item.admission_charge;
            }
            return total;
        },
        0
    );

    const totalPaidByCard = analyticsData?.result?.ipds?.reduce(
        (total, item) => {
            if (item.payment_mode === "card") {
                return total + item.admission_charge;
            }
            return total;
        },
        0
    );

    const totalIPD = getTotalIPDCharge(analyticsData?.result?.ipds || []);

    const { cashTotal, cardTotal } = (
        analyticsData?.result?.ipdBills || []
    ).reduce(
        (totals, bill) => {
            if (bill.payment_mode === "cash") {
                totals.cashTotal += bill.payable_amount;
            } else {
                totals.cardTotal += bill.payable_amount;
            }
            return totals;
        },
        { cashTotal: 0, cardTotal: 0 }
    );

    const labInvTotals = (
        analyticsData?.result?.labInvestigations || []
    ).reduce(
        (totals, item) => {
            if (item.payment_mode === "cash") {
                totals.totalCashPayable += item.payable_amount;
            } else if (item.payment_mode === "card") {
                totals.totalCardPayable += item.payable_amount;
            }
            return totals;
        },
        { totalCashPayable: 0, totalCardPayable: 0 }
    );
    const { totalCashPaidForMot, totalCardPaidForMot } = (
        analyticsData?.result?.minorOTs || []
    ).reduce(
        (totals, item) => {
            if (item.payment_mode === "cash") {
                totals.totalCashPaidForMot +=
                    item.payable_amount - item.discount_amount;
            } else if (item.payment_mode === "card") {
                totals.totalCardPaidForMot +=
                    item.payable_amount - item.discount_amount;
            }
            return totals;
        },
        { totalCashPaidForMot: 0, totalCardPaidForMot: 0 }
    );

    const { totalCashPaidForOpd, totalCardPaidForOpd } = (
        analyticsData?.result?.opds || []
    ).reduce(
        (totals, item) => {
            if (item.payment_mode === "cash") {
                totals.totalCashPaidForOpd +=
                    item.payable_amount - item.discount_amount;
            } else if (item.payment_mode === "card") {
                totals.totalCardPaidForOpd +=
                    item.payable_amount - item.discount_amount;
            }
            return totals;
        },
        { totalCashPaidForOpd: 0, totalCardPaidForOpd: 0 }
    );
    const totalRefundedAmount = (analyticsData?.result?.refunds || []).reduce(
        (total, item) => {
            return total + item.refunded_amount;
        },
        0
    );

    return (
        <>
            <AccountBills
                analyticsData={analyticsData}
                closeBillModal={closeBillModal}
                isBillModalOpen={isBillModalOpen}
            />
            <div>
                <div className="flex items-center space-x-3">
                    <Typography variant="h5">Accounting</Typography>
                </div>
            </div>
            <div className="flex justify-between mt-4 space-x-2">
                <div style={{ zIndex: "999" }} className="space-x-4">
                    <DatePicker
                        maxDate={dayjs().toDate()}
                        className="px-8 py-3 w-[250px] border border-gray-700 rounded-xl z-50"
                        dateFormat="yyyy/MM/dd"
                        placeholderText="StartDate - EndDate"
                        selectsRange={true}
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                    />
                    <ClearButton title="Clear" onClick={handleClearAnalytics} />
                </div>
                <div>
                    {view_permission === 1 && (
                        <ReusableButton
                            title="Download as PDF"
                            onClick={handleGenerateBill}
                        />
                    )}
                </div>
            </div>

            {analyticsData?.result && view_permission === 1 && (
                <div>
                    <section className="flex items-center mt-6 space-x-5">
                        {buttonName.map((item) => (
                            <button
                                key={item.id}
                                className={`
                        cursor-pointer px-2 py-2 rounded-lg border-b-[4px] border-[1px]
                        transition-all duration-300
                        ${
                            activeButton === item.id
                                ? "bg-white text-black border-black"
                                : "bg-blue-500 text-white border-blue-600"
                        }
                    `}
                                onClick={() => handleClick(item.id)}
                            >
                                {item.label}
                            </button>
                        ))}
                    </section>
                    <section className="mt-8">
                        {activeButton === 1 && (
                            <Paper
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    borderRadius: "15px",
                                }}
                                elevation={24}
                            >
                                <TableContainer
                                    sx={{
                                        minHeight: 120,
                                        maxHeight: 480,
                                        overflowX: "scroll",
                                        "&::-webkit-scrollbar": {
                                            width: 0,
                                        },
                                    }}
                                >
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {depositColumns.map(
                                                    (column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth:
                                                                    column.minWidth,
                                                                fontWeight:
                                                                    "bold",
                                                                background:
                                                                    "#eeeeee",
                                                                textTransform:
                                                                    "uppercase",
                                                                backgroundColor:
                                                                    "#27272A",
                                                                color: "white",
                                                            }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.result?.deposits?.map(
                                                (deposit) => (
                                                    <TableRow key={deposit.id}>
                                                        <TableCell align="center">
                                                            {deposit.id}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹ {deposit.amount}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                deposit.payment_mode
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                            <TableRow>
                                                <TableCell
                                                    colSpan={3}
                                                    align="right"
                                                >
                                                    <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                        {totalCashDeposits >
                                                            0 && (
                                                            <div className="flex items-center justify-end space-x-4">
                                                                <p>
                                                                    Total Paid
                                                                    by Cash:
                                                                </p>

                                                                <p>
                                                                    ₹{" "}
                                                                    {
                                                                        totalCashDeposits
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}
                                                        {totalCardDeposits >
                                                            0 && (
                                                            <div className="flex items-center justify-end space-x-4">
                                                                <p>
                                                                    Total Paid
                                                                    by Card:
                                                                </p>
                                                                <p>
                                                                    ₹{" "}
                                                                    {
                                                                        totalCardDeposits
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}

                                                        <div className="flex items-center justify-end space-x-4 ">
                                                            <p>Total:</p>
                                                            <p>
                                                                ₹ {totalDeposit}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        )}
                        {activeButton === 2 && (
                            <Paper
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    borderRadius: "15px",
                                }}
                                elevation={24}
                            >
                                <TableContainer
                                    sx={{
                                        minHeight: 120,
                                        maxHeight: 480,
                                        overflowX: "scroll",
                                        "&::-webkit-scrollbar": {
                                            width: 0,
                                        },
                                    }}
                                >
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {doctorPaymentsColumns.map(
                                                    (column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth:
                                                                    column.minWidth,
                                                                fontWeight:
                                                                    "bold",
                                                                background:
                                                                    "#eeeeee",
                                                                textTransform:
                                                                    "uppercase",
                                                                backgroundColor:
                                                                    "#27272A",
                                                                color: "white",
                                                            }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.result?.doctorPayments?.map(
                                                (payment) => (
                                                    <TableRow key={payment.id}>
                                                        <TableCell align="center">
                                                            {payment.id}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                payment.doctor_name
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹ {payment.amount}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {dayjs(
                                                                payment.created_on
                                                            ).format(
                                                                "DD-MM-YYYY,h:mm:ss A"
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                            <TableRow>
                                                <TableCell
                                                    colSpan={4}
                                                    align="right"
                                                >
                                                    <div className="flex items-center justify-end space-x-10 text-xl font-semibold mr-9">
                                                        <p>Total:</p>
                                                        <p>
                                                            ₹{" "}
                                                            {totalDoctorPayout}
                                                        </p>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        )}
                        {activeButton === 3 && (
                            <Paper
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    borderRadius: "15px",
                                }}
                                elevation={24}
                            >
                                <TableContainer
                                    sx={{
                                        minHeight: 120,
                                        maxHeight: 480,
                                        overflowX: "scroll",
                                        "&::-webkit-scrollbar": {
                                            width: 0,
                                        },
                                    }}
                                >
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {expenseColumn.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            minWidth:
                                                                column.minWidth,
                                                            fontWeight: "bold",
                                                            background:
                                                                "#eeeeee",
                                                            textTransform:
                                                                "uppercase",
                                                            backgroundColor:
                                                                "#27272A",
                                                            color: "white",
                                                        }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.result?.expenses?.map(
                                                (expense) => (
                                                    <TableRow key={expense.id}>
                                                        <TableCell align="center">
                                                            {expense.id}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {expense.type}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹ {expense.amount}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {dayjs(
                                                                expense.date
                                                            ).format(
                                                                "DD-MM-YYYY,h:mm:ss A"
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                            <TableRow>
                                                <TableCell
                                                    colSpan={4}
                                                    align="right"
                                                >
                                                    <div className="flex items-center justify-end space-x-10 text-xl font-semibold mr-9">
                                                        <p>Total:</p>
                                                        <p>₹ {totalExpenses}</p>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        )}
                        {activeButton === 4 && (
                            <Paper
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    borderRadius: "15px",
                                }}
                                elevation={24}
                            >
                                <TableContainer
                                    sx={{
                                        minHeight: 120,
                                        maxHeight: 480,
                                        overflowX: "scroll",
                                        "&::-webkit-scrollbar": {
                                            width: 0,
                                        },
                                    }}
                                >
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {ipdBillColumns.map(
                                                    (column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth:
                                                                    column.minWidth,
                                                                fontWeight:
                                                                    "bold",
                                                                background:
                                                                    "#eeeeee",
                                                                textTransform:
                                                                    "uppercase",
                                                                backgroundColor:
                                                                    "#27272A",
                                                                color: "white",
                                                            }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.result?.ipdBills?.map(
                                                (ipdBill) => (
                                                    <TableRow key={ipdBill.id}>
                                                        <TableCell align="center">
                                                            {ipdBill.hid}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                ipdBill.patient_name
                                                            }
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {
                                                                ipdBill.doctor_name
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {
                                                                ipdBill.total_amount
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {
                                                                ipdBill.deposit_amount
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {
                                                                ipdBill.discount_amount
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {
                                                                ipdBill.payable_amount
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                ipdBill.payment_mode
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {dayjs(
                                                                ipdBill.paid_on
                                                            ).format(
                                                                "DD-MM-YYYY,h:mm:ss A"
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                            <TableRow>
                                                <TableCell
                                                    colSpan={9}
                                                    align="right"
                                                >
                                                    <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                        {cashTotal > 0 && (
                                                            <div className="flex items-center justify-end space-x-4">
                                                                <p>
                                                                    Total Paid
                                                                    by Cash:
                                                                </p>

                                                                <p>
                                                                    ₹{" "}
                                                                    {cashTotal}
                                                                </p>
                                                            </div>
                                                        )}
                                                        {cardTotal > 0 && (
                                                            <div className="flex items-center justify-end space-x-4">
                                                                <p>
                                                                    Total Paid
                                                                    by Card:
                                                                </p>
                                                                <p>
                                                                    ₹{" "}
                                                                    {cardTotal}
                                                                </p>
                                                            </div>
                                                        )}

                                                        <div className="flex items-center justify-end space-x-4 ">
                                                            <p>Total:</p>
                                                            <p>
                                                                ₹{" "}
                                                                {totalIPDBills}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        )}
                        {activeButton === 5 && (
                            <Paper
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    borderRadius: "15px",
                                }}
                                elevation={24}
                            >
                                <TableContainer
                                    sx={{
                                        minHeight: 120,
                                        maxHeight: 480,
                                        overflowX: "scroll",
                                        "&::-webkit-scrollbar": {
                                            width: 0,
                                        },
                                    }}
                                >
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {ipdsColumn.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            minWidth:
                                                                column.minWidth,
                                                            fontWeight: "bold",
                                                            background:
                                                                "#eeeeee",
                                                            textTransform:
                                                                "uppercase",
                                                            backgroundColor:
                                                                "#27272A",
                                                            color: "white",
                                                        }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.result?.ipds?.map(
                                                (ipdBill) => (
                                                    <TableRow key={ipdBill.id}>
                                                        <TableCell align="center">
                                                            {ipdBill.hid}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                ipdBill.patient_name
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {
                                                                ipdBill.admission_charge
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                ipdBill.payment_mode
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                            <TableRow>
                                                <TableCell
                                                    colSpan={4}
                                                    align="right"
                                                >
                                                    <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                        {totalPaidByCash >
                                                            0 && (
                                                            <div className="flex items-center justify-end space-x-4">
                                                                <p>
                                                                    Total Paid
                                                                    by Cash:
                                                                </p>

                                                                <p>
                                                                    ₹{" "}
                                                                    {
                                                                        totalPaidByCash
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}
                                                        {totalPaidByCard >
                                                            0 && (
                                                            <div className="flex items-center justify-end space-x-4">
                                                                <p>
                                                                    Total Paid
                                                                    by Card:
                                                                </p>
                                                                <p>
                                                                    ₹{" "}
                                                                    {
                                                                        totalPaidByCard
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}

                                                        <div className="flex items-center justify-end space-x-4 ">
                                                            <p>Total:</p>
                                                            <p>₹ {totalIPD}</p>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        )}
                        {activeButton === 6 && (
                            <Paper
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    borderRadius: "15px",
                                }}
                                elevation={24}
                            >
                                <TableContainer
                                    sx={{
                                        minHeight: 120,
                                        maxHeight: 480,
                                        overflowX: "scroll",
                                        "&::-webkit-scrollbar": {
                                            width: 0,
                                        },
                                    }}
                                >
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {labInvColumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            minWidth:
                                                                column.minWidth,
                                                            fontWeight: "bold",
                                                            background:
                                                                "#eeeeee",
                                                            textTransform:
                                                                "uppercase",
                                                            backgroundColor:
                                                                "#27272A",
                                                            color: "white",
                                                        }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.result?.labInvestigations?.map(
                                                (lab) => (
                                                    <TableRow key={lab.id}>
                                                        <TableCell align="center">
                                                            {lab.id}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {lab.payable_amount}{" "}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {
                                                                lab.discount_amount
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {lab.payment_mode}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                            <TableRow>
                                                <TableCell
                                                    colSpan={4}
                                                    align="right"
                                                >
                                                    <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                        {labInvTotals?.totalCashPayable >
                                                            0 && (
                                                            <div className="flex items-center justify-end space-x-4">
                                                                <p>
                                                                    Total Paid
                                                                    by Cash:
                                                                </p>

                                                                <p>
                                                                    ₹{" "}
                                                                    {
                                                                        labInvTotals.totalCashPayable
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}
                                                        {labInvTotals?.totalCardPayable >
                                                            0 && (
                                                            <div className="flex items-center justify-end space-x-4">
                                                                <p>
                                                                    Total Paid
                                                                    by Card:
                                                                </p>
                                                                <p>
                                                                    ₹{" "}
                                                                    {
                                                                        labInvTotals?.totalCardPayable
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}

                                                        <div className="flex items-center justify-end space-x-4 ">
                                                            <p>Total:</p>
                                                            <p>
                                                                ₹{" "}
                                                                {labInvTotals.totalCardPayable +
                                                                    labInvTotals.totalCashPayable}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        )}
                        {activeButton === 7 && (
                            <Paper
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    borderRadius: "15px",
                                }}
                                elevation={24}
                            >
                                <TableContainer
                                    sx={{
                                        minHeight: 120,
                                        maxHeight: 480,
                                        overflowX: "scroll",
                                        "&::-webkit-scrollbar": {
                                            width: 0,
                                        },
                                    }}
                                >
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {motColumn.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            minWidth:
                                                                column.minWidth,
                                                            fontWeight: "bold",
                                                            background:
                                                                "#eeeeee",
                                                            textTransform:
                                                                "uppercase",
                                                            backgroundColor:
                                                                "#27272A",
                                                            color: "white",
                                                        }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.result?.minorOTs?.map(
                                                (mot) => (
                                                    <TableRow key={mot.id}>
                                                        <TableCell align="center">
                                                            {mot.id}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {mot.payable_amount}{" "}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {
                                                                mot.discount_amount
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {mot.payment_mode}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                            <TableRow>
                                                <TableCell
                                                    colSpan={4}
                                                    align="right"
                                                >
                                                    <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                        {totalCashPaidForMot >
                                                            0 && (
                                                            <div className="flex items-center justify-end space-x-4">
                                                                <p>
                                                                    Total Paid
                                                                    by Cash:
                                                                </p>

                                                                <p>
                                                                    ₹{" "}
                                                                    {
                                                                        totalCashPaidForMot
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}
                                                        {totalCardPaidForMot >
                                                            0 && (
                                                            <div className="flex items-center justify-end space-x-4">
                                                                <p>
                                                                    Total Paid
                                                                    by Card:
                                                                </p>
                                                                <p>
                                                                    ₹{" "}
                                                                    {
                                                                        totalCardPaidForMot
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}

                                                        <div className="flex items-center justify-end space-x-4 ">
                                                            <p>Total:</p>
                                                            <p>
                                                                ₹{" "}
                                                                {totalCardPaidForMot +
                                                                    totalCashPaidForMot}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        )}
                        {activeButton === 8 && (
                            <Paper
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    borderRadius: "15px",
                                }}
                                elevation={24}
                            >
                                <TableContainer
                                    sx={{
                                        minHeight: 120,
                                        maxHeight: 480,
                                        overflowX: "scroll",
                                        "&::-webkit-scrollbar": {
                                            width: 0,
                                        },
                                    }}
                                >
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {opdColumn.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            minWidth:
                                                                column.minWidth,
                                                            fontWeight: "bold",
                                                            background:
                                                                "#eeeeee",
                                                            textTransform:
                                                                "uppercase",
                                                            backgroundColor:
                                                                "#27272A",
                                                            color: "white",
                                                        }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.result?.opds?.map(
                                                (opd) => (
                                                    <TableRow key={opd.id}>
                                                        <TableCell align="center">
                                                            {opd.hid}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {opd.patient_title}
                                                            {". "}
                                                            {opd.patient_name}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {opd.charge}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {
                                                                opd.discount_amount
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {opd.doctor_name}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {opd.payment_mode}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}{" "}
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    align="right"
                                                >
                                                    <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                        {totalCashPaidForOpd >
                                                            0 && (
                                                            <div className="flex items-center justify-end space-x-4">
                                                                <p>
                                                                    Total Paid
                                                                    by Cash:
                                                                </p>

                                                                <p>
                                                                    ₹{" "}
                                                                    {
                                                                        totalCashPaidForOpd
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}
                                                        {totalCardPaidForOpd >
                                                            0 && (
                                                            <div className="flex items-center justify-end space-x-4">
                                                                <p>
                                                                    Total Paid
                                                                    by Card:
                                                                </p>
                                                                <p>
                                                                    ₹{" "}
                                                                    {
                                                                        totalCardPaidForOpd
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}

                                                        <div className="flex items-center justify-end space-x-4 ">
                                                            <p>Total:</p>
                                                            <p>
                                                                ₹{" "}
                                                                {totalCashPaidForOpd +
                                                                    totalCardPaidForOpd}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        )}
                        {activeButton === 9 && (
                            <Paper
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    borderRadius: "15px",
                                }}
                                elevation={24}
                            >
                                <TableContainer
                                    sx={{
                                        minHeight: 120,
                                        maxHeight: 480,
                                        overflowX: "scroll",
                                        "&::-webkit-scrollbar": {
                                            width: 0,
                                        },
                                    }}
                                >
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {refundColumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            minWidth:
                                                                column.minWidth,
                                                            fontWeight: "bold",
                                                            background:
                                                                "#eeeeee",
                                                            textTransform:
                                                                "uppercase",
                                                            backgroundColor:
                                                                "#27272A",
                                                            color: "white",
                                                        }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analyticsData?.result?.refunds?.map(
                                                (refund) => (
                                                    <TableRow key={refund.id}>
                                                        <TableCell align="center">
                                                            {refund.hid}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                refund.patient_title
                                                            }
                                                            {". "}
                                                            {
                                                                refund.patient_name
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {
                                                                refund.deposit_amount
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {
                                                                refund.adjusted_amount
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            ₹{" "}
                                                            {
                                                                refund.refunded_amount
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {dayjs(
                                                                refund.created_on
                                                            ).format(
                                                                "DD-MM-YYYY,h:mm:ss A"
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    align="right"
                                                >
                                                    <div className="flex flex-col justify-end space-x-10 text-base mr-9">
                                                        <div className="flex items-center justify-end space-x-4 ">
                                                            <p>Total:</p>
                                                            <p>
                                                                ₹{" "}
                                                                {
                                                                    totalRefundedAmount
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        )}
                    </section>
                </div>
            )}
        </>
    );
};

export default Analytics;
