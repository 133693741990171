import React from "react";
import logo from "../assets/logo.svg";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
    const navigate = useNavigate();
    return (
        <section>
            <div className="sticky h-[70px] top-0 z-50 flex items-center justify-end bg-black">
                <button
                    onClick={() => navigate("/")}
                    className="h-full px-4 mb-2 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    Home
                </button>
                <button
                    onClick={() => navigate("/aboutus")}
                    className="h-full px-4 mb-2 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    About Us
                </button>
                <button
                    onClick={() => navigate("/terms")}
                    className="h-full px-4 mb-2 mr-4 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    Terms & Conditions
                </button>
                <button
                    onClick={() => navigate("/contactus")}
                    className="h-full px-4 mb-2 mr-4 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    Contact Us
                </button>
            </div>
            <div className="relative flex flex-col items-center justify-start w-full h-screen overflow-hidden bg-black lg:flex-row lg:justify-evenly">
                <div className="w-1/2 mt-8 lg:mt-5">
                    <div className="flex items-center justify-center w-full">
                        <img
                            src={logo}
                            alt="Logo"
                            className="lg:w-[80%] w-[60%] max-w-[500px] h-auto opacity-80 animate-rotateAndScale"
                        />
                    </div>
                </div>
                <div className="w-[30%] py-5 shadow-lg bg-opacity-10 rounded-xl">
                    <div className="flex flex-col items-start space-y-4 text-white">
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                letterSpacing: "0.05em",
                            }}
                        >
                            <span
                                style={{ fontWeight: "bold" }}
                                className="text-2xl"
                            >
                                Phone:
                            </span>{" "}
                            +918729899491
                        </Typography>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                letterSpacing: "0.05em",
                            }}
                        >
                            <span
                                style={{ fontWeight: "bold" }}
                                className="text-2xl"
                            >
                                Email:
                            </span>{" "}
                            arcprofilety@gmail.com
                        </Typography>

                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                letterSpacing: "0.05em",
                            }}
                        >
                            <span
                                style={{ fontWeight: "bold" }}
                                className="mb-2 text-2xl"
                            >
                                Company Address:
                            </span>{" "}
                            <br />
                            Luwangsangbam Matai Mamang Leikai
                            <br />
                            Imphal East
                            <br />
                            Manipur
                            <br />
                            795002
                        </Typography>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
