// dashboardSlice.js

import { createSlice } from "@reduxjs/toolkit";

const dashboardInitialState = {
    defaultParams: {
        startDate: "",
        endDate: "",
    },
    searchField: "", // Add this line to define searchField in the initial state
    selectedOption: "", // Add this line to define selectedOption in the initial state
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: dashboardInitialState,
    reducers: {
        setDashboardParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },
        setSearchFieldDashboard: (state, action) => {
            state.searchField = action.payload;
        },
        setSelectedDashboardOption: (state, action) => {
            state.selectedOption = action.payload;
        },
        clearDashboard: (state, action) => {
            state.defaultParams = dashboardInitialState.defaultParams;
            state.searchField = dashboardInitialState.searchField; // Clear searchField
            state.selectedOption = dashboardInitialState.selectedOption; // Clear selectedOption
        },
    },
    extraReducers: {},
});

export const selectDashboardParams = (state) => state.dashboard.defaultParams;
export const selectSearchFieldDashboard = (state) => state.dashboard.searchField;
export const selectSelectedDashboardOption = (state) => state.dashboard.selectedOption;

export const {
    setDashboardParams,
    setSearchFieldDashboard,
    setSelectedDashboardOption,
    clearDashboard,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
