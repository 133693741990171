import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    defaultParams: {
        startDate: null,
        endDate: null,
        userID: null
    },
    doctorParams: {
        startDate: null,
        endDate: null,
        doctorID: null
    },
    serviceParams: {
        startDate: null,
        endDate: null,
        serviceID: null
    },
    labTestParams: {
        startDate: null,
        endDate: null,
        testID: null
    }
};

const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        setAnalyticsParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },
        setDoctorParams: (state, action) => {
            state.doctorParams = { ...state.doctorParams, ...action.payload };
        },
        setServiceParams: (state, action) => {
            state.serviceParams = { ...state.serviceParams, ...action.payload };
        },
        clearServiceParams: (state) => {
            state.serviceParams = initialState.serviceParams;
        },
        setLabTestParams: (state, action) => {
            state.labTestParams = { ...state.labTestParams, ...action.payload };
        },
        clearLabTestParams: (state) => {
            state.labTestParams = initialState.labTestParams;
        },
        clearAnalytics: (state) => {
            state.defaultParams = initialState.defaultParams;
        },
        clearDoctorParams: (state) => {
            state.doctorParams = initialState.doctorParams;
        },
    },
    extraReducers: {},
});

export const selectAnalyticsParams = (state) => state.analytics.defaultParams;
export const selectDoctorParams = (state) => state.analytics.doctorParams;
export const selectServiceParams = (state) => state.analytics.serviceParams;
export const selectTestParams = (state) => state.analytics.labTestParams;

export const {
    setLabTestParams,
    clearLabTestParams,
    setServiceParams,
    clearServiceParams,
    setAnalyticsParams,
    clearAnalytics,
    setDoctorParams,
    clearDoctorParams,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
