// floorSlice.js

import { createSlice } from "@reduxjs/toolkit";

const floorInitialState = {
    defaultParams: {
        name: "",
        startDate: "",
        endDate: "",
        offset: 0,
        limit: 10,
    },
};

const floorSlice = createSlice({
    name: "floor",
    initialState: floorInitialState,
    reducers: {
        setFloorParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },

        clearFloor: (state, action) => {
            state.defaultParams = floorInitialState.defaultParams;
        },
    },
    extraReducers: {},
});

export const selectFloorParam = (state) => state.floor.defaultParams;
export const { setFloorParams, clearFloor } = floorSlice.actions;
export default floorSlice.reducer;
