import { useQuery, } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { getUtils, publicMetaData } from "../../../services/api";
import { useSelector } from "react-redux";


// Custom hook for fetching entities
export function useGetMetaData() {

    const { data, isLoading } = useQuery({
        queryKey: [queryKeys.metadata],
        queryFn: () => getUtils(),
    });

    return { metaData: data, isLoading };
}
export function useGetPublicMetaData() {
    const { data, isLoading } = useQuery({
        queryKey: [queryKeys.publicMetaData],
        queryFn: () => publicMetaData(),
    });

    return { publicMetaData: data, isLoading };
}

// Custom hook for adding an entity
// export function useAddEntity() {
//     const state = useSelector((state) => state); // Replace with how you access your application state
//     const queryClient = useQueryClient();

//     const { mutate, isLoading } = useMutation({
//         mutationFn: (entityData) => addEntity(state.auth.token, entityData), // Assuming you have a token in your Redux state

//         onSuccess: (data) => {
//             queryClient.invalidateQueries({ queryKey: [queryKeys.entities] });
//             if (data?.success) {
//                 showNotification("success", `Entity ${data?.result?.name} added`);
//             } else {
//                 showNotification("error", data.message);
//                 console.error("Error:", data.message);
//             }
//         },
//     });

//     return { addEntity: mutate, isLoading };
// }

// // Custom hook for editing an entity
// export function useEditEntity() {
//     const queryClient = useQueryClient();
//     const { mutate, isLoading } = useMutation({
//         mutationFn: (postData) => updateEntity(postData.id, postData),
//         onSuccess: (data) => {
//             queryClient.invalidateQueries({ queryKey: [queryKeys.entities] });
//             if (data?.success) {
//                 showNotification("success", data.message);
//             } else {
//                 showNotification("error", data.message);
//             }
//         },
//     });
//     return { editEntity: mutate, isLoading };
// }

// // Custom hook for deleting an entity
// export function useDeleteEntity() {
//     const queryClient = useQueryClient();
//     const { mutate, isLoading } = useMutation({
//         mutationFn: (entityId) => deleteEntity(entityId),
//         onSuccess: (data) => {
//             queryClient.invalidateQueries({ queryKey: [queryKeys.entities] });
//             if (data?.success) {
//                 showNotification("success", data.message);
//             } else {
//                 showNotification("error", data.message);
//                 console.error("Error:", data.message);
//             }
//         },
//     });
//     return { deleteEntity: mutate, isLoading };
// }
