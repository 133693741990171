import { QueryCache, useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { forgotPw, login, logout } from "../../../services/api";
import { store } from "../../../redux/store";
import { clearLocalStorage, setItem, showNotification } from "../../../services/helper";
import { logOut, setCredentials } from "../../../redux/features/auth/authSlice";

export function useLogin(navigate) {
    const queryClient = useQueryClient();
    const { mutate, isSuccess, isLoading } = useMutation({
        mutationFn: (body) => login(body),
        onSuccess: (data) => {
            if (data?.success) {
                queryClient.invalidateQueries({ queryKey: [queryKeys.auth] });
                store.dispatch(setCredentials(data.result));
                setItem("user", data.result)
                navigate('/admin')

            } else {
                showNotification("error", "Please Check Your Credentials");
            }

        }
    });
    return { mutate, isSuccess, isLoading };
}
export function useForgotPw(func) {
    const queryClient = useQueryClient();
    const { mutate, isSuccess, isLoading } = useMutation({
        mutationFn: (body) => forgotPw(body),
        onSuccess: (data) => {
            if (data?.success) {
                func(true)
                // queryClient.invalidateQueries({ queryKey: [queryKeys.auth] });
                showNotification("success", `${data?.message}`);
            } else {
                showNotification("error", "This email is not registered");
            }

        }
    });
    return { forgotPwFunc: mutate, isSuccess, isLoading };
}

export function useLogout() {
    const queryClient = useQueryClient();
    const { mutate, isSuccess } = useMutation({
        mutationFn: () => logout(),
        onSuccess: (data) => {
            queryClient.invalidateQueries();
            if (data?.success) {
                clearLocalStorage("user");
                clearLocalStorage("clinic");
                store.dispatch(logOut())
            }
        }
    })

    return { mutate, isSuccess };
}