import React from "react";
import logo from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";
const Terms = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="sticky h-[70px] top-0 z-50 flex items-center justify-end bg-black">
                <button
                    onClick={() => navigate("/")}
                    className="h-full px-4 mb-2 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    Home
                </button>
                <button
                    onClick={() => navigate("/aboutus")}
                    className="h-full px-4 mb-2 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    About Us
                </button>
                <button
                    onClick={() => navigate("/terms")}
                    className="h-full px-4 mb-2 mr-4 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    Terms & Conditions
                </button>
                <button
                    onClick={() => navigate("/contactus")}
                    className="h-full px-4 mb-2 mr-4 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    Contact Us
                </button>
            </div>
            <section className="relative flex items-center justify-center h-full py-16 bg-black">
                <img
                    className="fixed inset-0 object-cover object-center mx-auto my-auto w-[40%] opacity-10"
                    src={logo}
                    alt="logo"
                />
                <div className="container mx-auto px-[20%] relative z-10">
                    <h2 className="mb-8 text-4xl font-bold text-center text-white">
                        Terms & Conditions
                    </h2>

                    <div>
                        <p className="mb-8 text-lg leading-relaxed text-white">
                            By utilizing our services, you agree to the
                            collection and utilization of your medical data in
                            accordance with the following terms and conditions:
                        </p>
                    </div>
                    <div>
                        <h2 className="mb-3 text-xl font-bold text-white">
                            Data Collection
                        </h2>
                        <p className="mb-8 text-lg leading-relaxed text-white">
                            We collect medical data directly provided by you.
                            This may encompass, but is not limited to, your
                            name, date of birth, contact information, health
                            history, and other pertinent health-related details.
                        </p>
                    </div>
                    <div>
                        <h2 className="mb-3 text-xl font-bold text-white">
                            Data Use
                        </h2>
                        <p className="mb-8 text-lg leading-relaxed text-white">
                            Your medical data is employed for the purpose of
                            providing, maintaining, and enhancing our services.
                            Additionally, we may use your data for
                            communication, such as notifying you about any
                            changes to our services.
                        </p>
                    </div>
                    <div>
                        <h2 className="mb-3 text-xl font-bold text-white">
                            Data Protection
                        </h2>
                        <p className="mb-8 text-lg leading-relaxed text-white">
                            Data Protection: To ensure the security of your
                            personal information, we have implemented various
                            measures. Your personal information is securely
                            stored behind protected networks and is accessible
                            only by a limited number of individuals with
                            specific access rights. This complies with the
                            Information Technology Act, 2000, and the
                            Information Technology (Reasonable Security
                            Practices and Procedures and Sensitive Personal Data
                            or Information) Rules, 2011.
                        </p>
                    </div>
                    <div>
                        <h2 className="mb-3 text-xl font-bold text-white">
                            Data Sharing
                        </h2>
                        <p className="mb-8 text-lg leading-relaxed text-white">
                            We do not engage in selling, trading, or
                            transferring your personally identifiable
                            information to external parties without providing
                            advance notice. Exceptions include website hosting
                            partners and other entities assisting us in website
                            operations, business management, or service
                            provision, on the condition that they commit to
                            maintaining the confidentiality of this information.
                        </p>
                    </div>
                    <div>
                        <h2 className="mb-3 text-xl font-bold text-white">
                            Changes to These Terms
                        </h2>
                        <p className="mb-8 text-lg leading-relaxed text-white">
                            We retain the right to modify these terms and
                            conditions at any time. In the event of changes, we
                            will notify you by updating the date at the top of
                            these terms and/or sending a notification.
                        </p>
                    </div>
                    <div>
                        <h2 className="mb-3 text-xl font-bold text-white">
                            Contact Us
                        </h2>
                        <p className="mb-8 text-lg leading-relaxed text-white">
                            For any queries regarding these terms and
                            conditions, please reach out to us at
                            arcprofilety@gmail.com / +918729899491.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Terms;
