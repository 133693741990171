import React, { useState } from "react";
import { useGetServices } from "../query/hooks/service/servicesHook";
import { Autocomplete, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ReusableButton from "./common/ReusableButton";
import ClearButton from "./common/ClearButton";
import { useDispatch, useSelector } from "react-redux";
import {
    clearLabTestParams,
    clearServiceParams,
    selectServiceParams,
    selectTestParams,
    setLabTestParams,
    setServiceParams,
} from "../redux/features/analytics/analyticsSlice";
import {
    useGetLabTestReport,
    useGetServiceReports,
} from "../query/hooks/analytics/analyticsHook";
import {
    getEndingTimeOfDate,
    getStartingTimeOfDate,
    showNotification,
} from "../services/helper";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import ServiceReportDownload from "./common/Reports/ServiceReportDownload";
import { useGetTests } from "../query/hooks/test/testHook";
import LabTestReportDownload from "./common/Reports/LabTestReportDownload";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const header = [
    { id: "hid", label: "HID", minWidth: 10, align: "center" },
    { id: "patient_name", label: "Patient", minWidth: 100, align: "center" },
    {
        id: "test_name",
        label: "Test",
        minWidth: 100,
        align: "center",
    },
    { id: "charge", label: "Charge", minWidth: 100, align: "center" },
    {
        id: "created_on",
        label: "Created On",
        minWidth: 100,
        align: "center",
    },
];
const tabsName = [
    { id: 1, label: "IPD Investigations" },
    { id: 2, label: "Lab Investigations" },
];
const LabTestReports = () => {
    const dispatch = useDispatch();
    const labTestParams = useSelector(selectTestParams);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [activeTab, setActiveTab] = useState(1);

    const { labTestReportData, isFetching, isLoading, refetch, isRefetching } =
        useGetLabTestReport(labTestParams);
    const { testsData } = useGetTests();

    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
    };
    const handleClear = () => {
        setSelectedOption(null);
        setInputValue("");
        setStartDate(null);
        setEndDate(null);
        dispatch(clearLabTestParams());
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const handleSearch = () => {
        if (!selectedOption || !startDate || !endDate || !selectedOption.id) {
            showNotification(
                "error",
                "Please provide all required parameters."
            );
            return;
        }
        dispatch(
            setLabTestParams({
                ...labTestParams,
                startDate: getStartingTimeOfDate(dayjs(startDate)) || "",
                endDate: getEndingTimeOfDate(dayjs(endDate)) || "",
                testID: selectedOption?.id || "",
            })
        );
    };

    const getTotal = (data) => {
        return data.reduce((total, inv) => total + inv.charge, 0);
    };

    // Assuming labTestReportData?.result is your data array
    const total = getTotal(labTestReportData?.result?.ipd_investigations || []);
    const totalInv = getTotal(
        labTestReportData?.result?.lab_investigations || []
    );

    const [isBillModalOpen, setIsBillModalOpen] = useState(false);
    const [selectedIPD, setSelectedIPD] = useState("");

    const closeBillModal = () => {
        setIsBillModalOpen(false);

        setSelectedIPD("");
    };
    const handleGenerateBill = (row) => {
        setIsBillModalOpen(true);
    };
    const handleTabClick = (id) => {
        setActiveTab(id);
    };
    return (
        <>
            <LabTestReportDownload
                labTestReportData={labTestReportData}
                isBillModalOpen={isBillModalOpen}
                closeBillModal={closeBillModal}
            />

            <section className="flex items-center mt-4 space-x-4">
                <div style={{ zIndex: "999" }}>
                    <DatePicker
                        maxDate={dayjs().toDate()}
                        className="px-8 py-3.5 w-[250px] border border-gray-700 rounded-xl z-50"
                        dateFormat="yyyy/MM/dd"
                        placeholderText="StartDate - EndDate"
                        selectsRange={true}
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
                <Autocomplete
                    className="w-[350px]"
                    options={testsData?.result?.list || []}
                    getOptionLabel={(option) => option.name}
                    value={selectedOption}
                    onChange={handleOptionChange}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    disableClearable
                    isOptionEqualToValue={(option, value) =>
                        option.name === value?.name
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search Lab Test"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {selectedOption && (
                                            <ClearIcon
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleClear}
                                            />
                                        )}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
                <ReusableButton title="Search" onClick={handleSearch} />
                <ClearButton title="Clear" onClick={handleClear} />
                {labTestReportData && (
                    <ReusableButton
                        title="Export as PDF"
                        onClick={handleGenerateBill}
                    />
                )}
            </section>
            <section className="mt-4">
                {labTestReportData?.result?.ipd_investigations.length > 0 &&
                    labTestReportData?.result?.lab_investigations.length >
                        0 && (
                        <section className="flex items-center my-6 space-x-5">
                            {tabsName.map((item) => (
                                <button
                                    key={item.id}
                                    className={`
                        cursor-pointer px-2 py-2 rounded-lg border-b-[4px] border-[1px]
                        transition-all duration-300
                        ${
                            activeTab === item.id
                                ? "bg-white text-black border-black"
                                : "bg-blue-500 text-white border-blue-600"
                        }
                    `}
                                    onClick={() => handleTabClick(item.id)}
                                >
                                    {item.label}
                                </button>
                            ))}
                        </section>
                    )}

                {activeTab === 1 &&
                    labTestReportData?.result?.ipd_investigations && (
                        <Paper
                            sx={{
                                width: "100%",
                                overflow: "hidden",
                                // borderRadius: "15px",
                            }}
                            // elevation={12}
                        >
                            <TableContainer
                                sx={{
                                    maxHeight: 480,
                                    overflowX: "auto",
                                    "&::-webkit-scrollbar": {
                                        width: 0,
                                        height: "2px",
                                    },
                                }}
                            >
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {header.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        minWidth:
                                                            column.minWidth,
                                                        fontWeight: "bold",
                                                        background: "#eeeeee",
                                                        textTransform:
                                                            "uppercase",
                                                        backgroundColor:
                                                            "#27272A",
                                                        color: "white",
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {labTestReportData.result
                                            .ipd_investigations?.length > 0 ? (
                                            labTestReportData.result.ipd_investigations.map(
                                                (inv) => (
                                                    <TableRow key={inv.id}>
                                                        <TableCell align="center">
                                                            {inv.hid}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            className="capitalize"
                                                        >
                                                            {inv.patient_title}{" "}
                                                            {inv.patient_name}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            className="capitalize"
                                                        >
                                                            {inv.test_name}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {inv.charge} ₹
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {dayjs(
                                                                inv.created_on
                                                            ).format(
                                                                "DD-MM-YYYY,h:mm:ss A"
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={5}
                                                    align="center"
                                                >
                                                    No data available
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell
                                                colSpan={5}
                                                align="right"
                                            >
                                                <div className="flex items-center justify-end space-x-10 text-xl font-semibold mr-9">
                                                    <p>Total:</p>
                                                    <p>{total}</p>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    )}
                {activeTab === 2 &&
                    labTestReportData?.result?.lab_investigations && (
                        <Paper
                            sx={{
                                width: "100%",
                                overflow: "hidden",
                                // borderRadius: "15px",
                            }}
                            // elevation={12}
                        >
                            <TableContainer
                                sx={{
                                    maxHeight: 480,
                                    overflowX: "auto",
                                    "&::-webkit-scrollbar": {
                                        width: 0,
                                        height: "2px",
                                    },
                                }}
                            >
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {header.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        minWidth:
                                                            column.minWidth,
                                                        fontWeight: "bold",
                                                        background: "#eeeeee",
                                                        textTransform:
                                                            "uppercase",
                                                        backgroundColor:
                                                            "#27272A",
                                                        color: "white",
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {labTestReportData.result
                                            .lab_investigations?.length > 0 ? (
                                            labTestReportData.result.lab_investigations.map(
                                                (inv) => (
                                                    <TableRow key={inv.id}>
                                                        <TableCell align="center">
                                                            {inv.hid}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            className="capitalize"
                                                        >
                                                            {inv.patient_title}{" "}
                                                            {inv.patient_name}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            className="capitalize"
                                                        >
                                                            {inv.test_name}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {inv.charge} ₹
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {dayjs(
                                                                inv.created_on
                                                            ).format(
                                                                "DD-MM-YYYY,h:mm:ss A"
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={5}
                                                    align="center"
                                                >
                                                    No data available
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell
                                                colSpan={5}
                                                align="right"
                                            >
                                                <div className="flex items-center justify-end space-x-10 text-xl font-semibold mr-9">
                                                    <p>Total:</p>
                                                    <p>{totalInv}</p>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    )}
            </section>
        </>
    );
};

export default LabTestReports;
