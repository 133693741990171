import React, { useState } from "react";
// import { NavLink } from "react-router-dom";
import { MdOutlineDashboard } from "react-icons/md";
import { getItem, getPermissionsByModule } from "../../services/helper";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useLogout } from "../../query/hooks/auth/authHook";
import { useSelector } from "react-redux";
import { selectCurrentAuth } from "../../redux/features/auth/authSlice";

import logo from "../../assets/logo.svg";
import { RiBookletFill } from "react-icons/ri";
import { BsBuilding } from "react-icons/bs";
import { IoFlask } from "react-icons/io5";
import { FaBriefcase } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { FaAmbulance } from "react-icons/fa";
import { FaUserInjured } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa6";
import { IoDocumentText } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { FaClinicMedical } from "react-icons/fa";
import { FaHospitalUser } from "react-icons/fa6";
import { GiHospitalCross } from "react-icons/gi";
import { RiBillLine } from "react-icons/ri";
import { MdLocalHospital } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { MdMeetingRoom } from "react-icons/md";
import { BsBuildingFill } from "react-icons/bs";
import { IoBedSharp } from "react-icons/io5";
import { IoAlbumsOutline } from "react-icons/io5";
import { MdReceiptLong } from "react-icons/md";
import { RiFileHistoryFill } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";

import { FaScissors } from "react-icons/fa6";
import { BiSolidClinic } from "react-icons/bi";
import { SiNginxproxymanager } from "react-icons/si";
import { RiServiceFill } from "react-icons/ri";
import { GiBuyCard } from "react-icons/gi";

import { FaClipboardUser } from "react-icons/fa6";
import acounting from "../../assets/acounting.jpg";
import pay2 from "../../assets/pay2.png";

const Sidebar = () => {
    const currentAuth = useSelector(selectCurrentAuth);
    const permissionsList = currentAuth?.user?.permissions;
    const dashboardPermissions = getPermissionsByModule(1001, permissionsList);
    const departmentsPermissions = getPermissionsByModule(
        1002,
        permissionsList
    );
    const rolesPermissions = getPermissionsByModule(1003, permissionsList);
    const designationsPermissions = getPermissionsByModule(
        1004,
        permissionsList
    );
    const employeesPermissions = getPermissionsByModule(1005, permissionsList);
    const testsPermissions = getPermissionsByModule(1006, permissionsList);
    const usersPermissions = getPermissionsByModule(1007, permissionsList);
    const servicesPermissions = getPermissionsByModule(1008, permissionsList);
    const patientsPermissions = getPermissionsByModule(1009, permissionsList);
    const opdsPermissions = getPermissionsByModule(1010, permissionsList);
    const labTestsPermissions = getPermissionsByModule(1011, permissionsList);
    const entityProfilePermissions = getPermissionsByModule(
        1012,
        permissionsList
    );
    const floorsPermissions = getPermissionsByModule(1013, permissionsList);
    const roomsPermissions = getPermissionsByModule(1014, permissionsList);
    const bedsPermissions = getPermissionsByModule(1015, permissionsList);
    const ipdsPermissions = getPermissionsByModule(1016, permissionsList);

    const otsPermissions = getPermissionsByModule(1017, permissionsList);
    const paymentsPermissions = getPermissionsByModule(1018, permissionsList);
    const expensesPermissions = getPermissionsByModule(1019, permissionsList);
    const motservicesPermissions = getPermissionsByModule(
        1020,
        permissionsList
    );
    const motsPermissions = getPermissionsByModule(1021, permissionsList);
    const analyticsPermissions = getPermissionsByModule(1022, permissionsList);
    const billsPermissions = getPermissionsByModule(1023, permissionsList);

    const [open, setOpen] = useState(false);
    const [openBookings, setOpenBookings] = useState(false);
    const [openMng, setOpenMng] = useState(false);
    const [openIPD, setOpenIPD] = useState(false);
    const [openEMP, setOpenEMP] = useState(false);
    const [openView, setOpenView] = useState(false);

    const handleIPDClick = () => {
        setOpenIPD(!openIPD);
    };

    const handleMngClick = () => {
        setOpenMng(!openMng);
    };

    const handleEMPClick = () => {
        setOpenEMP(!openEMP);
    };

    const handleBookingsClick = () => {
        setOpenBookings(!openBookings);
    };

    const { mutate: logout, isSuccess } = useLogout();
    const [openDialog, setOpenDialog] = useState(false);
    const [isHovered, setIsHovered] = useState(false); // New state to track hover state

    const handleMouseEnter = () => {
        setIsHovered(true);
        setOpenView(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        // setOpenMng(false);
        // setOpenIPD(false);
        // setOpenEMP(false);
        // setOpenBookings(false);
        setOpenView(false);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleLogout = () => {
        const user = getItem("user");

        if (user) {
            logout(user.token);
            if (isSuccess) {
                handleClose();
            }
        }
    };

    return (
        <>
            <section className="relative flex pl-10">
                <section
                    className={`h-[90%] sticky top-6 rounded-t-lg rounded-b-lg mr-10 shadow-2xl bg-[#27272A] animate-fade-right ${
                        isHovered
                            ? "overflow-y-auto custom-scrollbar"
                            : "overflow-hidden"
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                        transition: "width 0.3s ease",
                    }}
                >
                    <style>
                        {`
                        @keyframes fadeDown {
                            from {
                            opacity: 0;
                            transform: translateY(-20px);
                            }
                            to {
                            opacity: 1;
                            transform: translateY(0);
                            }
                        }
                          `}
                    </style>
                    <div className="flex justify-center">
                        <div
                            className={`transition-width ease-in-out duration-300 mx-2 ${
                                isHovered ? "w-[220px]" : "w-16"
                            }`}
                            style={{
                                animation: isHovered
                                    ? "fadeDown 0.3s ease"
                                    : "none",
                            }}
                        >
                            <div className="flex items-center justify-center p-2">
                                <img
                                    src={logo}
                                    alt="logo"
                                    className="w-[40px]  object-contain object-center  ease-in-out duration-300"
                                />
                            </div>

                            <div
                            // className={`${
                            //     !isHovered ? "items-center" : ""
                            // } flex flex-col space-y-2 pb-4`}
                            >
                                <List
                                    sx={{
                                        width: "100%",
                                        maxWidth: 360,
                                        backgroundColor: "#27272A",

                                        // alignItems: "center",
                                    }}
                                    component="nav"
                                >
                                    {dashboardPermissions?.view_permission ===
                                        1 && (
                                        <ListItemButton
                                            component={NavLink}
                                            to="/admin"
                                        >
                                            <ListItemIcon>
                                                <MdOutlineDashboard
                                                    style={{
                                                        color: "white",
                                                    }}
                                                />
                                            </ListItemIcon>
                                            {openView && (
                                                <ListItemText
                                                    primary="Dashboard"
                                                    primaryTypographyProps={{
                                                        style: {
                                                            color: "white",
                                                        },
                                                    }}
                                                />
                                            )}
                                        </ListItemButton>
                                    )}

                                    {opdsPermissions?.view_permission === 1 && (
                                        <ListItemButton
                                            component={NavLink}
                                            to="/admin/opdbooking"
                                        >
                                            <ListItemIcon>
                                                <FaClinicMedical
                                                    style={{
                                                        color: "white",
                                                    }}
                                                />
                                            </ListItemIcon>
                                            {openView && (
                                                <ListItemText
                                                    primary="OPD"
                                                    primaryTypographyProps={{
                                                        style: {
                                                            color: "white",
                                                        },
                                                    }}
                                                />
                                            )}
                                        </ListItemButton>
                                    )}
                                    {motsPermissions?.view_permission === 1 && (
                                        <ListItemButton
                                            component={NavLink}
                                            to="/admin/minorots"
                                        >
                                            <ListItemIcon>
                                                <FaHospitalUser
                                                    style={{
                                                        color: "white",
                                                    }}
                                                />
                                            </ListItemIcon>{" "}
                                            {openView && (
                                                <ListItemText
                                                    primary="Minor OTs"
                                                    primaryTypographyProps={{
                                                        style: {
                                                            color: "white",
                                                        },
                                                    }}
                                                />
                                            )}
                                        </ListItemButton>
                                    )}
                                    {patientsPermissions?.view_permission ===
                                        1 && (
                                        <ListItemButton
                                            component={NavLink}
                                            to="/admin/patients"
                                        >
                                            <ListItemIcon>
                                                <FaUserInjured
                                                    style={{
                                                        color: "white",
                                                    }}
                                                />
                                            </ListItemIcon>
                                            {openView && (
                                                <ListItemText
                                                    primary="Patient"
                                                    primaryTypographyProps={{
                                                        style: {
                                                            color: "white",
                                                        },
                                                    }}
                                                />
                                            )}
                                        </ListItemButton>
                                    )}
                                    {labTestsPermissions?.view_permission ===
                                        1 && (
                                        <ListItemButton
                                            component={NavLink}
                                            to="/admin/investigations"
                                        >
                                            <ListItemIcon>
                                                <IoDocumentText
                                                    style={{
                                                        color: "white",
                                                    }}
                                                />
                                            </ListItemIcon>
                                            {openView && (
                                                <ListItemText
                                                    primary="Investigations"
                                                    primaryTypographyProps={{
                                                        style: {
                                                            color: "white",
                                                        },
                                                    }}
                                                />
                                            )}
                                        </ListItemButton>
                                    )}
                                    <ListItemButton
                                        sx={{
                                            backgroundColor: "#27272A",
                                            "&:hover": {
                                                backgroundColor: "#1c1c1f", // Darker shade for hover
                                            },
                                        }}
                                        onClick={handleIPDClick}
                                    >
                                        <ListItemIcon>
                                            <GiHospitalCross
                                                className="text-center"
                                                style={{ color: "white" }}
                                            />
                                        </ListItemIcon>
                                        {openView && (
                                            <ListItemText
                                                primary="IPD"
                                                primaryTypographyProps={{
                                                    style: {
                                                        color: "white",
                                                    },
                                                }}
                                            />
                                        )}
                                        {openIPD ? (
                                            <ExpandLess
                                                style={{ color: "white" }}
                                            />
                                        ) : (
                                            <ExpandMore
                                                style={{ color: "white" }}
                                            />
                                        )}
                                    </ListItemButton>
                                    <Collapse
                                        in={openIPD}
                                        timeout="auto"
                                        unmountOnExit
                                        sx={{
                                            backgroundColor: "#27272A",
                                            // "&:hover": {
                                            //     backgroundColor: "#1c1c1f", // Darker shade for hover
                                            // },
                                        }}
                                    >
                                        <List component="div" disablePadding>
                                            {/* Include specific booking items here */}
                                            {ipdsPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/ipds"
                                                >
                                                    <ListItemIcon>
                                                        <MdLocalHospital
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    {openView && (
                                                        <ListItemText
                                                            primary="IPD"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                            {billsPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/ipdbills"
                                                >
                                                    <ListItemIcon>
                                                        <RiBillLine
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    {openView && (
                                                        <ListItemText
                                                            primary="IPD Bills"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                            {roomsPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/rooms"
                                                >
                                                    <ListItemIcon>
                                                        <MdMeetingRoom
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Rooms"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                            {floorsPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/floors"
                                                >
                                                    <ListItemIcon>
                                                        <BsBuildingFill
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Floors"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                            {bedsPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/beds"
                                                >
                                                    <ListItemIcon>
                                                        <IoBedSharp
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Beds"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                            {billsPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/discharge"
                                                >
                                                    <ListItemIcon>
                                                        <IoAlbumsOutline
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Discharge Summary"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                        </List>
                                    </Collapse>

                                    <ListItemButton
                                        sx={{
                                            backgroundColor: "#27272A",
                                            "&:hover": {
                                                backgroundColor: "#1c1c1f", // Darker shade for hover
                                            },
                                        }}
                                        onClick={handleBookingsClick}
                                    >
                                        <ListItemIcon>
                                            {/* <RiBookletFill
                                                style={{ color: "white" }}
                                            /> */}

                                            {/* <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                width="25"
                                                height="25"
                                                viewBox="0,0,256,256"
                                                style={{ fill: "#FFFFFF" }}
                                            >
                                                <g
                                                    fill="#27272a"
                                                    fillRule="nonzero"
                                                    stroke="none"
                                                    strokeWidth="1"
                                                    strokeLinecap="butt"
                                                    strokeLinejoin="miter"
                                                    strokeMiterlimit="10"
                                                    strokeDasharray=""
                                                    strokeDashoffset="0"
                                                    fontFamily="none"
                                                    fontWeight="none"
                                                    fontSize="none"
                                                    textAnchor="none"
                                                    style={{
                                                        mixBlendMode: "normal",
                                                    }}
                                                >
                                                    <path
                                                        d="M0,256v-256h256v256z"
                                                        id="bgRectangle"
                                                    />
                                                </g>
                                                <g
                                                    fill="#ffffff"
                                                    fillRule="nonzero"
                                                    stroke="none"
                                                    strokeWidth="1"
                                                    strokeLinecap="butt"
                                                    strokeLinejoin="miter"
                                                    strokeMiterlimit="10"
                                                    strokeDasharray=""
                                                    strokeDashoffset="0"
                                                    fontFamily="none"
                                                    fontWeight="none"
                                                    fontSize="none"
                                                    textAnchor="none"
                                                    style={{
                                                        mixBlendMode: "normal",
                                                    }}
                                                >
                                                    <g transform="scale(5.12,5.12)">
                                                        <path d="M36,3.99h-22c-5.514,0 -10,4.486 -10,10v22c0,5.514 4.486,10 10,10h22c5.514,0 10,-4.486 10,-10v-22c0,-5.514 -4.486,-10 -10,-10zM29.993,27.883c-0.305,0.156 -0.68,0.156 -0.985,0c-5.744,-2.941 -9.008,-7.569 -9.008,-11.728c0,-4.786 5.26,-6.596 8.771,-3.347c0.413,0.383 1.044,0.383 1.457,0c3.512,-3.249 8.772,-1.439 8.772,3.347c0,4.159 -3.264,8.787 -9.007,11.728z" />
                                                    </g>
                                                </g>
                                            </svg> */}
                                            <img
                                                src={pay2}
                                                alt=""
                                                className="object-contain w-[25px] h-[25px]"
                                            />
                                        </ListItemIcon>
                                        {openView && (
                                            <ListItemText
                                                primary="Accounting"
                                                primaryTypographyProps={{
                                                    style: {
                                                        color: "white",
                                                    },
                                                }}
                                            />
                                        )}
                                        {openBookings ? (
                                            <ExpandLess
                                                style={{ color: "white" }}
                                            />
                                        ) : (
                                            <ExpandMore
                                                style={{ color: "white" }}
                                            />
                                        )}
                                    </ListItemButton>
                                    <Collapse
                                        in={openBookings}
                                        timeout="auto"
                                        unmountOnExit
                                        sx={{
                                            backgroundColor: "#27272A",
                                            "&:hover": {
                                                backgroundColor: "#1c1c1f", // Darker shade for hover
                                            },
                                        }}
                                    >
                                        <List component="div" disablePadding>
                                            {/* Include specific booking items here */}
                                            {expensesPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/expenses"
                                                >
                                                    <ListItemIcon>
                                                        <GiBuyCard
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Daily Expenses"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                            {analyticsPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/accounting"
                                                >
                                                    <ListItemIcon>
                                                        <MdReceiptLong
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Accounts"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                            {analyticsPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/accountreports"
                                                >
                                                    <ListItemIcon>
                                                        <TbReportAnalytics
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Account Reports"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                            {analyticsPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/paymentshistory"
                                                >
                                                    <ListItemIcon>
                                                        <RiFileHistoryFill
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Doctor Payments"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                        </List>
                                    </Collapse>

                                    <ListItemButton
                                        sx={{
                                            backgroundColor: "#27272A",
                                            "&:hover": {
                                                backgroundColor: "#1c1c1f", // Darker shade for hover
                                            },
                                        }}
                                        onClick={handleMngClick}
                                    >
                                        <ListItemIcon>
                                            <SiNginxproxymanager
                                                style={{ color: "white" }}
                                            />
                                        </ListItemIcon>
                                        {openView && (
                                            <ListItemText
                                                primary="Management"
                                                primaryTypographyProps={{
                                                    style: {
                                                        color: "white",
                                                    },
                                                }}
                                            />
                                        )}
                                        {openMng ? (
                                            <ExpandLess
                                                style={{ color: "white" }}
                                            />
                                        ) : (
                                            <ExpandMore
                                                style={{ color: "white" }}
                                            />
                                        )}
                                    </ListItemButton>
                                    <Collapse
                                        in={openMng}
                                        timeout="auto"
                                        unmountOnExit
                                        sx={{
                                            backgroundColor: "#27272A",
                                            "&:hover": {
                                                backgroundColor: "#1c1c1f", // Darker shade for hover
                                            },
                                        }}
                                    >
                                        <List component="div" disablePadding>
                                            {/* Include specific booking items here */}
                                            {entityProfilePermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/clinicprofile"
                                                >
                                                    <ListItemIcon>
                                                        <BiSolidClinic
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Clinic Profile"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                            {departmentsPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/departments"
                                                >
                                                    <ListItemIcon>
                                                        <BsBuilding
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Department"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}

                                            <ListItemButton
                                                sx={{ pl: 4 }}
                                                component={NavLink}
                                                to="/admin/otservices"
                                            >
                                                <ListItemIcon>
                                                    <FaScissors
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    />
                                                </ListItemIcon>{" "}
                                                {openView && (
                                                    <ListItemText
                                                        primary="OT Services"
                                                        primaryTypographyProps={{
                                                            style: {
                                                                color: "white",
                                                            },
                                                        }}
                                                    />
                                                )}
                                            </ListItemButton>

                                            {motservicesPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/motservices"
                                                >
                                                    <ListItemIcon>
                                                        <RiServiceFill
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Minor OT Services"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}

                                            {servicesPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/services"
                                                >
                                                    <ListItemIcon>
                                                        <FaAmbulance
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Services"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                            {testsPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/tests"
                                                >
                                                    <ListItemIcon>
                                                        <IoFlask
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Tests"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                        </List>
                                    </Collapse>

                                    <ListItemButton
                                        sx={{
                                            backgroundColor: "#27272A",
                                            "&:hover": {
                                                backgroundColor: "#1c1c1f", // Darker shade for hover
                                            },
                                        }}
                                        onClick={handleEMPClick}
                                    >
                                        <ListItemIcon>
                                            <FaBriefcase
                                                style={{ color: "white" }}
                                            />
                                        </ListItemIcon>
                                        {openView && (
                                            <ListItemText
                                                primary="Employee Management"
                                                primaryTypographyProps={{
                                                    style: {
                                                        color: "white",
                                                    },
                                                }}
                                            />
                                        )}
                                        {openEMP ? (
                                            <ExpandLess
                                                style={{ color: "white" }}
                                            />
                                        ) : (
                                            <ExpandMore
                                                style={{ color: "white" }}
                                            />
                                        )}
                                    </ListItemButton>
                                    <Collapse
                                        in={openEMP}
                                        timeout="auto"
                                        unmountOnExit
                                        sx={{
                                            backgroundColor: "#27272A",
                                            "&:hover": {
                                                backgroundColor: "#1c1c1f", // Darker shade for hover
                                            },
                                        }}
                                    >
                                        <List component="div" disablePadding>
                                            {/* Include specific booking items here */}

                                            {employeesPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/employees"
                                                >
                                                    <ListItemIcon>
                                                        <FaUserGroup
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Employee"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                            {usersPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/users"
                                                >
                                                    <ListItemIcon>
                                                        <FaUserCircle
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Users"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                            {designationsPermissions?.view_permission ===
                                                1 && (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    component={NavLink}
                                                    to="/admin/designations"
                                                >
                                                    <ListItemIcon>
                                                        <FaClipboardUser
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>{" "}
                                                    {openView && (
                                                        <ListItemText
                                                            primary="Designation"
                                                            primaryTypographyProps={{
                                                                style: {
                                                                    color: "white",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            )}
                                        </List>
                                    </Collapse>
                                </List>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Logout"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleLogout} color="error" autoFocus>
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Sidebar;
