import React, { useState } from "react";
import {
    Paper,
    Typography,
    Grid,
    Box,
    Button,
    AccordionDetails,
    Accordion,
    AccordionSummary,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TitleIcon from "@mui/icons-material/Title";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PhoneIcon from "@mui/icons-material/Phone";
import CakeIcon from "@mui/icons-material/Cake";
import FaceIcon from "@mui/icons-material/Face"; // Replace GenderIcon with FaceIcon
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetPatientProfile } from "../../query/hooks/patientsSide/patientSideHooks";

// const iconStyles = {
//     marginRight: "10px",
// };
const iconStyles = {
    fontSize: 20,
    marginRight: "10px",
};

const accordionStyle = {
    background: "#27272A",
    color: "white",
};
const PatientProfile = () => {
    const { data: patientProfileData, isLoading: isPatientProfileLoading } =
        useGetPatientProfile();
    const [expanded, setExpanded] = useState(false);
    const [expandedAccordion, setExpandedAccordion] = useState(null);
    const handleAccordionChange = (id) => {
        setExpandedAccordion(expandedAccordion === id ? null : id);
    };

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };
    const ProfilePage = ({ patientProfileData }) => {
        return (
            <Box
                sx={{
                    minHeight: "100vh",
                    padding: "20px",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        marginBottom: "20px",
                        textAlign: "left",
                        ml: 1,
                        fontWeight: "bold",
                    }}
                >
                    <PortraitOutlinedIcon fontSize="medium" sx={{ mr: 1 }} />
                    <strong>Profile Information</strong>
                </Typography>

                {patientProfileData?.result?.list.map((data) => (
                    <Accordion
                        key={data.id}
                        style={accordionStyle}
                        expanded={expandedAccordion === data.id}
                        onChange={() => handleAccordionChange(data.id)}
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon style={{ color: "white" }} />
                            }
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle1"
                                        className="capitalize"
                                        sx={{
                                            marginBottom: "14px",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <AccountCircleIcon
                                            sx={iconStyles}
                                            style={{ color: "#64B5F6" }}
                                        />
                                        <span className="mr-2 capitalize">
                                            {data.title}
                                        </span>
                                        {data.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="subtitle1">
                                <PermIdentityIcon
                                    sx={iconStyles}
                                    style={{ color: "#FFD700" }}
                                />
                                Name: {data.name}
                            </Typography>
                        </AccordionDetails>

                        <AccordionDetails>
                            <Typography variant="subtitle1">
                                <PhoneIcon
                                    sx={iconStyles}
                                    style={{ color: "#FF8C00" }}
                                />
                                Mobile: {data.mobile}
                            </Typography>
                        </AccordionDetails>

                        <AccordionDetails>
                            <Typography variant="subtitle1">
                                <CakeIcon
                                    sx={iconStyles}
                                    style={{ color: "#FF4500" }}
                                />
                                Age: {data.age}
                            </Typography>
                        </AccordionDetails>

                        <AccordionDetails>
                            <Typography variant="subtitle1">
                                <FaceIcon
                                    sx={iconStyles}
                                    style={{ color: "#9C27B0" }}
                                />
                                Gender: {data.gender}
                            </Typography>
                        </AccordionDetails>

                        <AccordionDetails>
                            <div className="flex justify-between">
                                <HomeIcon
                                    sx={iconStyles}
                                    style={{
                                        color: "#00BCD4",
                                        marginRight: "8px",
                                    }}
                                />
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        marginBottom: "14px",
                                        display: "flex",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        WebkitLineClamp: "2",
                                        WebkitBoxOrient: "vertical",
                                    }}
                                >
                                    <span style={{ flex: 1 }}>
                                        Address: {data.address}
                                    </span>
                                </Typography>
                            </div>
                        </AccordionDetails>

                        <AccordionDetails>
                            <Typography variant="subtitle1">
                                <LocationCityIcon
                                    sx={iconStyles}
                                    style={{ color: "#FF5722" }}
                                />
                                District: {data.district}
                            </Typography>
                        </AccordionDetails>

                        <AccordionDetails>
                            <Typography variant="subtitle1">
                                <LocationOnIcon
                                    sx={iconStyles}
                                    style={{ color: "#8BC34A" }}
                                />
                                Pincode: {data.pincode}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        );
    };

    return (
        <div>
            <ProfilePage patientProfileData={patientProfileData} />
        </div>
    );
};

export default PatientProfile;
