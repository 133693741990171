import React, { useEffect, useState } from "react";
import {
    Autocomplete,
    Backdrop,
    Box,
    Fade,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineReload,
} from "react-icons/ai";
import { GrFormView } from "react-icons/gr";
import { FaRegEye } from "react-icons/fa6";
import ReusableButton from "./ReusableButton";
import RedButton from "./RedButton";
import {
    getEndingTimeOfDate,
    getPermissionsByModule,
    getStartingTimeOfDate,
    sanitizeValue,
    showNotification,
    validateAddOPD,
    validateAddPatient,
} from "../../services/helper";
import {
    useAddPatient,
    useAddPatientEntity,
} from "../../query/hooks/patients/patientsHook";
import { searchPatients } from "../../services/api";
import { useAddOPD } from "../../query/hooks/opd/opdHook";
import ClearIcon from "@mui/icons-material/Clear";
import AddPatientForm from "./AddPatientForm";
import { selectEmployeesParam } from "../../redux/features/filters/employeeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetEmployees } from "../../query/hooks/employee/employeeHooks";
import { useGetMetaData } from "../../query/hooks/metadata/metadataHook";
import { IoMdAdd } from "react-icons/io";
import { useGetRooms } from "../../query/hooks/ipd/rooms";
import { useGetFloors } from "../../query/hooks/ipd/floors";
import { useGetBeds } from "../../query/hooks/ipd/beds";
import {
    useAddIPD,
    useDeleteIPD,
    useEditIPD,
    useGetIPDs,
} from "../../query/hooks/ipd/ipds";
import { validateIPD } from "../../services/validation";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import ClearButton from "./ClearButton";
import {
    clearIPD,
    selectIPDParam,
    setIPDParams,
} from "../../redux/features/filters/ipdSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { selectCurrentAuth } from "../../redux/features/auth/authSlice";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-55%, -50%)",
    minWidth: 800,
    maxWidth: 1200,
    minHeight: 200,
    maxHeight: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
        width: 0,
    }, // Add this line for vertical scrolling
};
const headers = [
    { id: 1, name: "IPD ID" },
    { id: 2, name: "HID" },
    { id: 3, name: "Name" },
    { id: 4, name: "Phone Number" },
    { id: 5, name: "Doctor" },
    { id: 6, name: "Department" },
    // { id: 6, name: "Reference" },
    { id: 7, name: "Created By" },
    { id: 8, name: "Admission Date" },
];

const initialData = {
    aadhaar: "",
    title: "",
    name: "",
    mobile: "",
    email: "",
    age: 0,
    months: 0,
    days: 0,
    gender: "",
    marital_status: "",
    care_of: "",
    relationship: "",
    occupation: "",
    address: "",
    district: "",
    pincode: "",
    remarks: "",
};
const initialPatientState = {
    patient: 0,
    department: "",
    doctor: "",
    admission_charge: 0.0,
    payment_mode: "",
    floor: 0,
    room: 0,
    bed: 0,
};
const IPD = () => {
    const currentAuth = useSelector(selectCurrentAuth);
    const permissionsList = currentAuth?.user?.permissions;
    const ipdsPermissions = getPermissionsByModule(1016, permissionsList);
    const { add_permission, update_permission, delete_permission } =
        ipdsPermissions;

    const navigate = useNavigate();
    const { metaData } = useGetMetaData();
    const dispatch = useDispatch();
    const ipdParams = useSelector(selectIPDParam);

    const {
        ipdsData,
        isFetching,
        isLoading,
        isRefetching,
        isSuccess,
        refetch,
    } = useGetIPDs(ipdParams);
    const { deleteIPD, isLoading: isDeletingRoom } = useDeleteIPD();
    const { editIPD, isLoading: isUpdatingRoom } = useEditIPD();
    const { addIPD, isLoading: isAddingRoom } = useAddIPD();
    const employeeParams = useSelector(selectEmployeesParam);
    const { employeesData } = useGetEmployees(employeeParams);
    const [patient, setPatient] = useState({ ...initialData });
    const { roomsData } = useGetRooms();
    const { floorsData } = useGetFloors();
    const { bedsData } = useGetBeds();

    const handlePTOpen = () => setPtOpen(true);
    const [ptID, setPtID] = useState();
    const [step, setStep] = useState(1);

    const handlePTClose = () => {
        setPatient({ ...initialData });
        setStep(1);
        setPtOpen(false);
        handleClear();
    };

    const {
        addPatient,
        isLoading: isAddingPatient,
        isSuccess: isAdded,
    } = useAddPatient(setPtID, handlePTClose, setStep);
    const {
        addPatientEntity,
        isLoading: isAddingPatientEntity,
        isSuccess: isAddedPtEntity,
    } = useAddPatientEntity(setStep);
    const [ptOpen, setPtOpen] = useState(false);
    const initialPatientId = { aadhaar: "", hid: "" };
    const [patientId, setPatientId] = useState(initialPatientId);
    const [isAadharDisabled, setIsAadharDisabled] = useState(false);
    const [isHidDisabled, setIsHidDisabled] = useState(false);

    const handleAddPatient = () => {
        const isValid = validateAddPatient(patient);
        if (isValid) {
            patient.months = sanitizeValue(patient.months);
            patient.day = sanitizeValue(patient.day);
            patient.age = sanitizeValue(patient.age);
            addPatient(patient);
            if (step === 3) {
                setPatient("");
                setPatient({ ...initialData });

                setPatientState((prevState) => ({
                    ...prevState,
                    patient: ptID,
                }));
                handleClear();
            }
        } else {
            showNotification(
                "error",
                "Make Sure All Required Field Are Filled"
            );
        }
    };
    useEffect(() => {
        if (ptID) {
            setPatientState((prevState) => ({
                ...prevState,
                patient: ptID,
            }));
        }
    }, [ptID]);
    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;
        const numericValue =
            (name === "age" || name === "months" || name === "days") &&
            !isNaN(parseInt(value, 10))
                ? parseInt(value, 10)
                : name === "mobile"
                ? value.replace(/\D/g, "").slice(0, 10)
                : name === "aadhaar"
                ? value.replace(/\D/g, "").slice(0, 12)
                : value;

        setPatient((prevPatient) => ({
            ...prevPatient,
            [name]: numericValue,
        }));
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setPatient((prevPatient) => ({
            ...prevPatient,
            [name]: value,
        }));
    };

    const handleAadharChange = (e) => {
        const value = e.target.value;
        setPatientId((prev) => ({
            ...prev,
            aadhaar: value,
        }));
        setIsHidDisabled(value !== "");
    };

    const handleHidChange = (e) => {
        const value = e.target.value;
        setPatientId((prev) => ({
            ...prev,
            hid: value,
        }));
        setIsAadharDisabled(value !== "");
    };

    const handleClear = () => {
        setPatientId(initialPatientId);
        setIsAadharDisabled(false);
        setIsHidDisabled(false);
    };
    const [ptExist, setPtExist] = useState(false);

    const handleSearchPatient = async () => {
        try {
            if (!patientId.aadhaar && !patientId.hid) {
                showNotification(
                    "error",
                    "Please provide either aadhaar or HID."
                );
                return;
            }
            const result = await searchPatients(
                patientId.aadhaar,
                patientId.hid
            );

            if (result.success) {
                setPtExist(true);
                setPatientState((prevState) => ({
                    ...prevState,
                    patient: result.result.id,
                }));
                const updatedPatient = {
                    aadhar: result.result.aadhar || "",
                    title: result.result.title || "",
                    name: result.result.name || "",
                    mobile: result.result.mobile || "",
                    email: result.result.email || "",
                    age: result.result.age || 0,
                    months: result.result.months || 0,
                    days: result.result.days || 0,
                    gender: result.result.gender || "",
                    marital_status: result.result.marital_status || "",
                    care_of: result.result.care_of || "",
                    relationship: result.result.relationship || "",
                    occupation: result.result.occupation || "",
                    address: result.result.address || "",
                    district: result.result.district || "",
                    pincode: result.result.pincode || "",
                    remarks: result.result.remarks || "",
                };
                setPatient({ ...updatedPatient });
                const foundPt = {
                    patient: result.result.id,
                };
                addPatientEntity(foundPt);
                setStep(2);
            } else {
                setStep(2);
                setPtExist(false);
                showNotification("error", "Patient not Found, Add New Entry");

                setPatient({ ...initialData });
            }
        } catch (error) {
            console.error("Error occurred during search:", error);
        }
    };
    const handleCloseOPD = () => {
        if (finalStep) {
            setPatientState({ ...initialPatientState });
            setValErr([]);
            setStep(1);
            setPtExist(false);
            // setFinalStep(false);
            handlePTClose();
        } else {
            setPatientState({ ...initialPatientState });
            setValErr([]);
            setStep(1);
            setPtExist(false);
            setFinalStep(false);
            handlePTClose();
        }
    };
    const [finalStep, setFinalStep] = useState(false);
    const { addOPD, isLoading: isAddingOPD } = useAddOPD(
        setFinalStep,
        handleCloseOPD
    );

    const [patientState, setPatientState] = useState({
        ...initialPatientState,
    });

    const [openOPD, setOpenOPD] = useState(false);
    const [valErr, setValErr] = useState([]);
    const [selectedOPDGenBill, setSelectedOPDGenBill] = useState("");

    const handleOPDInputChange = (event) => {
        const { name, value } = event.target;

        if (name === "doctor") {
            setPatientState((prevState) => ({
                ...prevState,
                doctor: value.id,
                department: value.department,
            }));
        } else {
            const numericValue =
                name === "admission_charge"
                    ? parseFloat(value.replace(/[^\d.]/g, ""))
                    : value;

            // Update state with new values
            setPatientState((prevState) => ({
                ...prevState,
                [name]: numericValue,
            }));
        }
    };

    const handleAddIPDSubmit = (event) => {
        event.preventDefault();
        const addIPDS = {
            // id: parseInt(patientState.id),
            patient: parseInt(patientState.patient),
            department: parseInt(patientState.department),
            doctor: parseInt(patientState.doctor),
            admission_charge: parseFloat(patientState.admission_charge),
            payment_mode: patientState.payment_mode,
            floor: parseFloat(patientState.floor),
            room: parseFloat(patientState.room),
            bed: parseFloat(patientState.bed),
        };
        const { emptyFields, zeroValueFields } = validateIPD(addIPDS);
        if (emptyFields.length > 0 || zeroValueFields.length > 0) {
            showNotification("error", "Fill All Fields Before Submitting");
        } else {
            addIPD(addIPDS);
            handlePTClose();
        }
    };
    const handleNextStep = () => {
        setStep(3);
    };
    const filteredDoctors = employeesData?.result?.list?.filter(
        (employee) => employee.designation_name === "Doctor"
    );

    const [selectedDoctor, setSelectedDoctor] = useState("");

    const handleDoctorChange = (event, value) => {
        setSelectedDoctor(value);
        setPatientState((prevState) => ({
            ...prevState,
            doctor: value.id,
            department: value.department,
        }));
    };
    const handleDoctorClear = () => {
        setSelectedDoctor("");
        setPatientState((prevState) => ({
            ...prevState,
            doctor: "",
            department: "",
        }));
    };

    const handleKeyPress2 = (event) => {
        if (event.key === "Enter") {
            if (!ptExist) {
                handleAddPatient();
            } else {
                handleNextStep();
            }
        }
    };
    const isOptionEqualToValue = (option, value) => option.id === value.id;

    const [selectedFloor, setSelectedFloor] = useState("");
    const [selectedRoom, setSelectedRoom] = useState("");
    // State to store filtered rooms
    const [filteredRooms, setFilteredRooms] = useState([]);
    const [filteredBeds, setFilteredBeds] = useState([]);

    // Handler for floor selection change
    const handleFloorChange = (event) => {
        const selectedFloorId = event.target.value;
        setSelectedFloor(selectedFloorId);
        setPatientState((prevState) => ({
            ...prevState,
            floor: selectedFloorId,
        }));

        // Filter rooms based on selected floor
        const filtered = roomsData?.result?.list?.filter(
            (room) => room.floor === parseInt(selectedFloorId)
        );
        setFilteredRooms(filtered || []);
    };

    useEffect(() => {
        setPatientState((prevState) => ({
            ...prevState,
            room: 0,
            bed: 0,
        }));
    }, [selectedFloor]);

    const handleRoomChange = (event) => {
        const selectedRoomId = event.target.value;
        setSelectedRoom(selectedRoomId);
        setPatientState((prevState) => ({
            ...prevState,
            room: selectedRoomId,
        }));
        const filtered = bedsData?.result?.list?.filter(
            (bed) => bed.room === parseInt(selectedRoomId)
        );
        setFilteredBeds(filtered || []);
    };
    const handleNavigateIPD = (items) => {
        navigate(`/admin/ipds/${items.id}`);
    };

    const [searchParams, setSearchParams] = useState({
        name: "",
        hid: "",
        department: "",
        mobile: "",
        startDate: "",
        endDate: "",
    });

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            [name]: value,
        }));
    };
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState("");

    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            department: newValue.id,
        }));
    };

    const handleInputChange2 = (event, newInputValue) => {
        setInputValue(newInputValue);
    };
    const handleClearDept = () => {
        setSelectedOption(null);
        setInputValue("");
        dispatch(
            setIPDParams({
                ...ipdParams,
                department: "",
            })
        );
    };
    const isValidMobileNumber = (mobile) => {
        // Remove non-numeric characters
        const cleanedMobile = mobile.replace(/\D/g, "");

        // Check if the cleaned mobile number has exactly 10 digits
        return cleanedMobile.length === 10;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isStartDateValid =
            startDate instanceof Date && !isNaN(startDate.getTime());
        const isEndDateValid =
            endDate instanceof Date && !isNaN(endDate.getTime());

        if (isStartDateValid && isEndDateValid) {
            dispatch(
                setIPDParams({
                    ...ipdParams,
                    name: searchParams.name || "",
                    hid: searchParams.hid || "",
                    department: searchParams.department || "",
                    mobile: isValidMobileNumber(searchParams.mobile)
                        ? searchParams.mobile
                        : "",
                    startDate: getStartingTimeOfDate(dayjs(startDate)) || "",
                    endDate: getEndingTimeOfDate(dayjs(endDate)) || "",
                })
            );
        } else {
            // showNotification("error", "Invalid date range")
            dispatch(
                setIPDParams({
                    ...ipdParams,
                    name: searchParams.name || "",
                    hid: searchParams.hid || "",
                    department: searchParams.department || "",
                    mobile: isValidMobileNumber(searchParams.mobile)
                        ? searchParams.mobile
                        : "",
                    startDate: "",
                    endDate: "",
                })
            );
        }
    };

    const handleClearHID = () => {
        setSearchParams({ ...searchParams, hid: "" });
    };
    const handleClearMobile = () => {
        setSearchParams({ ...searchParams, mobile: "" });
    };

    const handleClearDepartment = () => {
        setSelectedOption(null);
        setInputValue("");
    };

    const handleClearSearch = () => {
        setSearchParams({
            name: "",
            hid: "",
            department: "",
            mobile: "",
        });
        dispatch(clearIPD());
        setSelectedOption(null);
        setInputValue("");
        setStartDate();
        setEndDate();
    };
    const [currentPage, setCurrentPage] = useState(1);

    const handlePaginationChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setIPDParams({
                ...ipdParams,
                offset: (value - 1) * ipdParams.limit,
            })
        );
    };
    const handleKeyPress = (event) => {
        if (event.key === "Escape") {
            event.preventDefault(); // Prevent default Esc behavior
            handleClearSearch();
        }
    };
    return (
        <>
            <section>
                <div>
                    {/* <Tooltip title="Add IPD" placement="bottom-start">
                        <button
                            onClick={handlePTOpen}
                            className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                        >
                            <IoMdAdd size={18} />
                        </button>
                    </Tooltip> */}

                    <div className="flex justify-between px-10 mb-5">
                        <div>
                            <div className="flex items-center space-x-3">
                                <Typography variant="h5">IPDs</Typography>
                                <Tooltip
                                    title="Refresh"
                                    placement="bottom-start"
                                >
                                    <button
                                        onClick={refetch}
                                        className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <AiOutlineReload size={20} />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        {add_permission === 1 && (
                            <Tooltip title="Add " placement="bottom-start">
                                <button
                                    onClick={handlePTOpen}
                                    className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                                >
                                    <IoMdAdd size={18} />
                                    ADD IPD
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div
                        className="flex items-center justify-around mb-4"
                        style={{ position: "relative" }}
                        onKeyDown={handleKeyPress}
                        tabIndex={0}
                    >
                        <TextField
                            label="Search HID"
                            variant="outlined"
                            name="hid"
                            value={searchParams.hid}
                            onChange={handleSearch}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {searchParams.hid && (
                                            <ClearIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={handleClearHID}
                                            />
                                        )}
                                    </>
                                ),
                            }}
                        />
                        <TextField
                            label="Search Phone Number"
                            variant="outlined"
                            name="mobile"
                            value={searchParams.mobile}
                            onChange={handleSearch}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                );
                            }}
                            InputProps={{
                                inputProps: {
                                    maxLength: 10,
                                    pattern: "[0-9]*",
                                },
                                endAdornment: (
                                    <>
                                        {searchParams.mobile && (
                                            <ClearIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={handleClearMobile}
                                            />
                                        )}
                                    </>
                                ),
                            }}
                        />

                        <Autocomplete
                            className="w-[220px]"
                            options={metaData?.result?.departments || []}
                            getOptionLabel={(option) => option.name}
                            value={selectedOption}
                            onChange={handleOptionChange}
                            inputValue={inputValue}
                            onInputChange={handleInputChange2}
                            disableClearable
                            isOptionEqualToValue={(option, value) =>
                                option.id === value?.id
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Department"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {selectedOption && (
                                                    <ClearIcon
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={
                                                            handleClearDepartment
                                                        }
                                                    />
                                                )}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <div style={{ zIndex: "999" }}>
                            <DatePicker
                                maxDate={dayjs().toDate()}
                                className="px-6 py-4 w-[220px] border border-gray-300 rounded-xl"
                                dateFormat="yyyy/MM/dd"
                                placeholderText="StartDate - EndDate"
                                selectsRange={true}
                                isClearable
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        </div>
                        <ReusableButton
                            title="Search"
                            type="submit"
                            // onClick={handleSubmit}
                        />

                        <ClearButton
                            title="Clear"
                            onClick={handleClearSearch}
                        />
                    </div>
                </form>
                <div>
                    <Paper
                        sx={{
                            width: "100%",
                            overflow: "scroll",
                            borderRadius: "15px",
                        }}
                        elevation={24}
                    >
                        <TableContainer
                            sx={{
                                height: 520,
                                overflowX: "scroll",
                                "&::-webkit-scrollbar": {
                                    width: 0,
                                },
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {headers.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align="center"
                                                style={{
                                                    minWidth: 120,
                                                    fontWeight: "bold",
                                                    background: "#eeeeee",
                                                    textTransform: "uppercase",
                                                    backgroundColor: "#27272A",
                                                    color: "white",
                                                }}
                                            >
                                                {column.name}
                                            </TableCell>
                                        ))}
                                        <TableCell
                                            align="center"
                                            style={{
                                                minWidth: 120,
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                                color: "white",
                                            }}
                                        >
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ipdsData?.result?.list?.map(
                                        (item, index) => (
                                            <TableRow
                                                key={item.id}
                                                style={{
                                                    backgroundColor:
                                                        index % 2 === 0
                                                            ? "#E4E4E7"
                                                            : "white",
                                                }}
                                            >
                                                <TableCell align="center">
                                                    {item.id}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {item.hid}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className="capitalize"
                                                >
                                                    {item.patient_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {item.patient_mobile}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {item.doctor_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {item.department_name}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {item.created_by_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {dayjs(
                                                        item.created_on
                                                    ).format(
                                                        "DD-MM-YYYY,h:mm:ss A"
                                                    )}
                                                </TableCell>

                                                <TableCell align="center">
                                                    <div className="flex items-center justify-center space-x-4">
                                                        {update_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Edit"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                    onClick={() =>
                                                                        handleNavigateIPD(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                        {delete_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Delete"
                                                                placement="bottom-start"
                                                            >
                                                                <button className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500">
                                                                    <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="flex items-center justify-center my-4">
                            <Pagination
                                count={Math.ceil(
                                    ipdsData?.result?.count / ipdParams.limit
                                )}
                                page={currentPage}
                                sx={{
                                    "& .MuiSelect-select": {
                                        display: "none !important",
                                    },
                                    "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                        {
                                            display: "none !important",
                                        },
                                }}
                                onChange={handlePaginationChange}
                            />
                        </div>
                    </Paper>
                </div>
            </section>
            <Modal
                open={ptOpen}
                onClose={handlePTClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={ptOpen}>
                    <Box sx={styles}>
                        <>
                            {step === 1 && (
                                <div className="justify-center w-full space-y-4 ">
                                    <Typography variant="h4">
                                        Search Patient
                                    </Typography>
                                    <TextField
                                        required
                                        name="aadhaar"
                                        className="col-span-2"
                                        label="Enter Patient Aadhaar"
                                        variant="outlined"
                                        fullWidth
                                        value={patientId.aadhaar}
                                        onChange={handleAadharChange}
                                        disabled={isAadharDisabled}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    sx={{
                                                        visibility:
                                                            patientId.aadhaar
                                                                ? "visible"
                                                                : "hidden",
                                                    }}
                                                    variant="outlined"
                                                    onClick={handleClear}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        inputProps={{
                                            inputMode: "numeric",
                                            pattern: "[0-9]*", // Allow only numeric input
                                            maxLength: 12,
                                        }}
                                        onInput={(e) => {
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                ); // Remove non-numeric characters
                                        }}
                                    />
                                    <TextField
                                        required
                                        name="hid"
                                        className="col-span-2"
                                        label="Enter Patient HID"
                                        variant="outlined"
                                        fullWidth
                                        value={patientId.hid}
                                        onChange={handleHidChange}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    sx={{
                                                        visibility:
                                                            patientId.hid
                                                                ? "visible"
                                                                : "hidden",
                                                    }}
                                                    variant="outlined"
                                                    onClick={handleClear}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        disabled={isHidDisabled}
                                    />

                                    <div className="flex w-full py-2 justify-evenly ">
                                        <ReusableButton
                                            title="Search"
                                            onClick={handleSearchPatient}
                                        />

                                        <RedButton
                                            title="Cancel"
                                            onClick={handlePTClose}
                                        />
                                    </div>
                                </div>
                            )}
                            {step === 2 && (
                                <AddPatientForm
                                    handleKeyPress2={handleKeyPress2}
                                    handleSelectChange={handleSelectChange}
                                    handleTextFieldChange={
                                        handleTextFieldChange
                                    }
                                    handleAddPatient={handleAddPatient}
                                    handleNextStep={handleNextStep}
                                    handlePTClose={handlePTClose}
                                    patient={patient}
                                    ptExist={ptExist}
                                />
                            )}
                            {step === 3 && (
                                <div>
                                    <Typography
                                        variant="h5"
                                        className="text-center"
                                    >
                                        Add IPD
                                    </Typography>
                                    <FormControl fullWidth>
                                        <div className="flex flex-wrap gap-5 p-2">
                                            <TextField
                                                required
                                                label="Patient ID"
                                                name="patient"
                                                value={patientState.patient}
                                                onChange={handleOPDInputChange}
                                                variant="outlined"
                                                margin="normal"
                                                disabled
                                            />
                                            <div className="flex items-center w-full space-x-5">
                                                <Autocomplete
                                                    sx={{
                                                        width: "40%",
                                                    }}
                                                    options={filteredDoctors}
                                                    disableClearable
                                                    getOptionLabel={(option) =>
                                                        option
                                                            ? option.name
                                                                  .charAt(0)
                                                                  .toUpperCase() +
                                                              option.name.slice(
                                                                  1
                                                              )
                                                            : ""
                                                    }
                                                    value={selectedDoctor}
                                                    onChange={
                                                        handleDoctorChange
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Search Doctor"
                                                        />
                                                    )}
                                                    isOptionEqualToValue={
                                                        isOptionEqualToValue
                                                    }
                                                />
                                                <FormControl
                                                    sx={{
                                                        width: "40%",
                                                    }}
                                                    margin="dense"
                                                    error={valErr?.emptyFields?.includes(
                                                        "department"
                                                    )}
                                                >
                                                    <InputLabel id="department-label">
                                                        Department
                                                    </InputLabel>
                                                    <Select
                                                        id="department"
                                                        label="Department"
                                                        name="department"
                                                        value={
                                                            patientState.department ||
                                                            ""
                                                        }
                                                        onChange={
                                                            handleOPDInputChange
                                                        }
                                                        disabled
                                                    >
                                                        {metaData?.result?.departments?.map(
                                                            (department) => (
                                                                <MenuItem
                                                                    key={
                                                                        department.id
                                                                    }
                                                                    value={
                                                                        department.id
                                                                    }
                                                                    className="capitalize"
                                                                >
                                                                    {
                                                                        department.name
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                    <FormHelperText>
                                                        {valErr?.emptyFields?.includes(
                                                            "department"
                                                        )
                                                            ? "Department is required"
                                                            : undefined}
                                                    </FormHelperText>
                                                </FormControl>
                                            </div>
                                            <div className="flex items-center w-full space-x-5 ">
                                                <TextField
                                                    required
                                                    label="Admission Charge"
                                                    name="admission_charge"
                                                    value={
                                                        patientState.admission_charge ||
                                                        0
                                                    }
                                                    onChange={
                                                        handleOPDInputChange
                                                    }
                                                    variant="outlined"
                                                    margin="dense"
                                                    helperText={
                                                        valErr?.zeroValueFields?.includes(
                                                            "admission_charge"
                                                        )
                                                            ? "Charge is required"
                                                            : null
                                                    }
                                                    error={valErr?.zeroValueFields?.includes(
                                                        "admission_charge"
                                                    )}
                                                />
                                                <FormControl
                                                    sx={{
                                                        width: "40%",
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="dense"
                                                    error={valErr?.emptyFields?.includes(
                                                        "payment_mode"
                                                    )}
                                                >
                                                    <InputLabel id="payment-mode-label">
                                                        Payment Mode
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId="payment-mode-label"
                                                        label="Payment Mode"
                                                        name="payment_mode"
                                                        value={(
                                                            patientState.payment_mode ||
                                                            ""
                                                        ).toLowerCase()} // Convert to lowercase and then capitalize
                                                        onChange={
                                                            handleOPDInputChange
                                                        }
                                                    >
                                                        {metaData?.result?.payment_modes?.map(
                                                            (mode) => (
                                                                <MenuItem
                                                                    key={mode}
                                                                    value={mode}
                                                                    className="capitalize"
                                                                >
                                                                    {mode
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase() +
                                                                        mode.slice(
                                                                            1
                                                                        )}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>

                                                    <FormHelperText>
                                                        {valErr?.emptyFields?.includes(
                                                            "payment_mode"
                                                        )
                                                            ? "Payment Mode is required"
                                                            : undefined}
                                                    </FormHelperText>
                                                </FormControl>
                                            </div>
                                            <div className="flex items-center w-full space-x-4">
                                                <FormControl fullWidth>
                                                    <InputLabel>
                                                        Floor
                                                    </InputLabel>

                                                    <Select
                                                        name="floor"
                                                        label="Floor"
                                                        value={
                                                            patientState.floor ||
                                                            ""
                                                        }
                                                        onChange={
                                                            handleFloorChange
                                                        }
                                                    >
                                                        {floorsData?.result?.list?.map(
                                                            (floor) => (
                                                                <MenuItem
                                                                    key={
                                                                        floor.id
                                                                    }
                                                                    value={
                                                                        floor.id
                                                                    }
                                                                >
                                                                    {floor.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>

                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                >
                                                    <InputLabel>
                                                        Room
                                                    </InputLabel>
                                                    <Select
                                                        id="demo-simple-select"
                                                        name="room"
                                                        label="Room"
                                                        value={
                                                            patientState.room ||
                                                            ""
                                                        }
                                                        onChange={
                                                            handleRoomChange
                                                        }
                                                        disabled={
                                                            !filteredRooms
                                                        }
                                                    >
                                                        {filteredRooms.map(
                                                            (room) => (
                                                                <MenuItem
                                                                    key={
                                                                        room.id
                                                                    }
                                                                    value={
                                                                        room.id
                                                                    }
                                                                >
                                                                    {room.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>

                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                >
                                                    <InputLabel>Bed</InputLabel>
                                                    <Select
                                                        id="demo-simple-select"
                                                        disabled={!filteredBeds}
                                                        name="bed"
                                                        label="Bed"
                                                        value={
                                                            patientState.bed ||
                                                            ""
                                                        }
                                                        onChange={(e) =>
                                                            setPatientState({
                                                                ...patientState,
                                                                bed: e.target
                                                                    .value,
                                                            })
                                                        }
                                                    >
                                                        {filteredBeds
                                                            .filter(
                                                                (bed) =>
                                                                    bed.is_occupied !==
                                                                    1
                                                            )
                                                            .map((bed) => (
                                                                <MenuItem
                                                                    key={bed.id}
                                                                    value={
                                                                        bed.id
                                                                    }
                                                                >
                                                                    {
                                                                        bed.bed_number
                                                                    }
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-evenly">
                                            <ReusableButton
                                                title="Submit"
                                                onClick={handleAddIPDSubmit}
                                            />
                                            <RedButton
                                                onClick={handleCloseOPD}
                                                title="Cancel"
                                            />
                                        </div>
                                    </FormControl>
                                </div>
                            )}
                        </>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default IPD;
