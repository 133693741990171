import Lottie from "lottie-react";
import React from "react";
import NotFound from "../../assets/notfound2.json";
import { useNavigate } from "react-router-dom";

const NoDataFound = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    // <div className="mx-auto flex items-center mt-10">
    //   <Lottie
    //     animationData={NotFound}
    //     loop={true}
    //     style={{
    //       height: "520px",
    //       width: "450px",
    //     }}
    //   />
    // </div>
    <div className="mx-auto flex flex-col items-center mt-10 ">
      <Lottie
        animationData={NotFound}
        loop={true}
        style={{
          height: "300px", // Default for small screens
          width: "260px", // Default for small screens
        }}
      />
      <button
        onClick={handleBack}
        class="rounded relative inline-flex group items-center justify-center px-3.5 py-2 m-1 cursor-pointer border-b-4 border-l-2 active:border-purple-600 active:shadow-none shadow-lg bg-gradient-to-tr from-purple-600 to-purple-500 border-purple-700 text-white"
      >
        <span class="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-32 group-hover:h-32 opacity-10"></span>
        <span class="relative">Go Back</span>
      </button>
      {/* <button className="" onClick={handleBack}>
        Go Back
      </button> */}
    </div>
  );
};

export default NoDataFound;
