import { useQuery, useMutation, useQueryClient, isError } from "@tanstack/react-query";
import { showNotification } from "../../../services/helper";
import { queryKeys } from "../../keys";
import { getOPDs, addOPD, updateOPD, deleteOPD } from "../../../services/api"; // Import your API functions for OPD

// Custom hook for fetching OPD records
export function useGetOPDs(opdParams) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.opds, { id: opdParams.id, offset: opdParams.offset, limit: opdParams.limit, hid: opdParams.hid, name: opdParams.name, mobile: opdParams.mobile, startDate: opdParams.startDate, endDate: opdParams.endDate, department: opdParams.department, ptId: opdParams.ptId }],
        queryFn: () => getOPDs({
            offset: opdParams.offset,
            limit: opdParams.limit,
            name: opdParams.name,
            hid: opdParams.hid,
            department: opdParams.department,
            mobile: opdParams.mobile,
            startDate: opdParams.startDate,
            endDate: opdParams.endDate,
            ptId: opdParams.ptId,
            id: opdParams.id,
        }),
    });

    return { opdsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

// Custom hook for adding an OPD record
export function useAddOPD(setFinalStep, handleCloseOPD) {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (opdData) => addOPD(opdData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.opds] });
            if (data?.success) {
                handleCloseOPD();
                setFinalStep(true);
                showNotification("success", `OPD record added`);
            } else {
                setFinalStep(false)
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addOPD: mutate, isLoading };
}

// Custom hook for editing an OPD record
export function useEditOPD() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updateOPD(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.opds] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });

    return { editOPD: mutate, isLoading };
}

// Custom hook for deleting an OPD record
export function useDeleteOPD() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (opdId) => deleteOPD(opdId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.opds] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { deleteOPD: mutate, isLoading };
}
