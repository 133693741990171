import toast from 'react-hot-toast';
// import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import dayjs from 'dayjs';


export function getPermissionsByModule(moduleId, permissionsList = []) {
    const modulePermission = permissionsList?.find(
        (permission) => permission?.module === moduleId
    );

    if (modulePermission) {
        const {
            view_permission,
            add_permission,
            update_permission,
            delete_permission,
        } = modulePermission;
        return {
            view_permission,
            add_permission,
            update_permission,
            delete_permission,
        };
    } else {
        return null; // or handle the case where the module is not found
    }
}


export const chunkArray = (arr, size) => {
    if (arr)
        return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
            arr.slice(i * size, i * size + size)
        );
};

export const showNotification = (type, message, time = 1800) => {
    toast[type](message, {
        position: "top-right",
        reverseOrder: false,
        autoClose: time
    });
};

export const validateAddPatient = (body) => {
    const requiredFields = [
        "aadhaar",
        "title",
        "name",
        "mobile",
        "gender",
        "age",
        "marital_status"
    ];

    return _checkRequiredFields(body, requiredFields);
};
export const validateService = (body) => {
    const requiredFields = [
        "aadhaar",
        "title",
        "name",
        "mobile",
        "gender",
        "age",
        "marital_status"
    ];

    return _checkRequiredFields(body, requiredFields);
};
export const validateUser = (body) => {
    const requiredFields = [

        "name",
        "mobile",
        "email",
        "role",

    ];

    return _checkRequiredFields(body, requiredFields);
};
export const validateAddDept = (body) => {
    const requiredFields = [
        "name",

    ];

    return _checkRequiredFields(body, requiredFields);
};

// export const validateAddEmployee = (body) => {
//     const requiredFields = [
//         'name',
//         'mobile',
//         'designation',
//         'department',
//     ];

//     return _checkRequiredFields(body, requiredFields);
// };
export const validateAddEmployee = (body) => {
    const requiredFields = ['name', 'mobile', 'designation', 'department', 'address', 'email', 'employee_id'];
    const emptyFields = [];

    requiredFields.forEach(field => {
        const fieldValue = body[field];
        // Convert non-string values to strings before calling trim()
        const trimmedValue = typeof fieldValue === 'string' ? fieldValue.trim() : String(fieldValue);

        if (!trimmedValue || trimmedValue === '') {
            emptyFields.push(field);
        }
    });

    return emptyFields;
};
export const validateAddOPD = (body) => {
    const requiredFields = ['patient', 'department', 'doctor', 'charge', 'payable_amount', 'payment_mode'];
    const emptyFields = [];
    const zeroValueFields = [];

    requiredFields.forEach(field => {
        const fieldValue = body[field];
        const trimmedValue = typeof fieldValue === 'string' ? fieldValue.trim() : String(fieldValue);

        if (!trimmedValue || trimmedValue === '') {
            emptyFields.push(field);
        }

        if (field === 'charge' || field === 'payable_amount') {
            const numericValue = parseInt(trimmedValue, 10);
            if (numericValue === 0) {
                zeroValueFields.push(field);
            }
        }
    });

    return { emptyFields, zeroValueFields };
};


export const debouncer = function (func, wait) {
    let timeout;
    return function () {
        const context = this, args = arguments;
        const later = function () {
            timeout = null;
            func.apply(context, args);
        }
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export const setItem = (key, value) => localStorage.setItem(key, JSON.stringify(value));

export const getItem = (key) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
};

export const clearLocalStorage = (key) => {
    // Check if the key exists in local storage
    if (localStorage.getItem(key)) {
        // Remove the key from local storage
        localStorage.removeItem(key);
    }
}

const _checkRequiredFields = (body, requiredFields) => {
    for (let field of requiredFields) {
        if (isEmpty(body[field])) return false;
    }
    return true;
};
export const isEmpty = (value) => {
    return (
        value === null ||
        value === undefined ||
        value.toString().trim().length === 0
    );
};
export const sanitizeValue = (value) => isEmpty(value) || isNaN(value) ? 0 : value;
export const validateMobile = (mobile) => !(isEmpty(mobile) || mobile.length !== 10 || isNaN(mobile / 10));
export const validateAadhar = (aadhaar) => !(isEmpty(aadhaar) || aadhaar.length !== 12 || isNaN(aadhaar / 12));


export const encryptData = (name, data) => {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
    localStorage.setItem(name, encrypted);
}

export const decryptData = (name) => {
    const encrypted = localStorage.getItem(name) || "";
    if (!encrypted) {
        return JSON.parse("{}")
    }
    // const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
}

export const getStartingTimeOfDate = (date = dayjs()) => {
    const startingTimeOfDate = date.startOf("D").format("YYYY-MM-DD HH:mm:ss");
    return startingTimeOfDate;
}

export const getEndingTimeOfDate = (date = dayjs()) => {
    const endingTimeOfDate = date.endOf("D").format("YYYY-MM-DD HH:mm:ss");

    return endingTimeOfDate;
};

export const getYesterdayDate = (date = dayjs()) => {
    return date
        .subtract(1, "day")
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
};