import { useQuery, useMutation, useQueryClient, isError } from "@tanstack/react-query";
import { showNotification } from "../../../services/helper";
import { queryKeys } from "../../keys";
import { getLabTests, addLabTest, updateLabTest, deleteLabTest, getLabTestsList } from "../../../services/api"; // Import your API functions for LabTests

export function useGetLabTests(labTestParams = {}) {
    const {
        offset = 0,
        limit = 10000,
        hid = "",
        name = "",
        startDate = "",
        endDate = "",
        ptID = ""
    } = labTestParams;

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.labTests, { offset, limit, hid, name, startDate, endDate, ptID }],
        queryFn: () => getLabTests({ offset, limit, hid, name, startDate, endDate, ptID }),
    });

    return { labTestsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}


export function useGetLabTestList(testID) {
    const { data, isLoading, isError } = useQuery({
        queryKey: [queryKeys.labTest, testID],
        queryFn: () => getLabTestsList(testID),
        enabled: testID ? true : false,
    });

    if (isLoading) {
        return { labTestListData: [], isLoading: true, isError: false };
    }

    if (isError) {
        // Handle error state here (e.g., display an error message)
        console.error('Error fetching lab test data');
        return { labTestListData: [], isLoading: false, isError: true };
    }

    return { labTestListData: data, isLoading: false, isError: false };
}
// Custom hook for adding a LabTest record
export function useAddLabTest(func) {
    const queryClient = useQueryClient();

    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: (labTestData) => addLabTest(labTestData),
        onSuccess: (data) => {

            queryClient.invalidateQueries({ queryKey: [queryKeys.labTests] });
            if (data?.success) {
                func();
                showNotification("success", 'LabTest record added');

            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addLabTest: mutate, isLoading, isSuccess };
}

// Custom hook for editing a LabTest record
export function useEditLabTest(func) {
    const queryClient = useQueryClient();

    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: (postData) => updateLabTest(postData.investigation.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.labTests] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.labTest] });
            
            if (data?.success) {
                showNotification("success", data.message);
                func();
            } else {
                showNotification("error", data.message);
            }
        },
    });

    return { editLabTest: mutate, isLoading, isSuccess };
}

// Custom hook for deleting a LabTest record
export function useDeleteLabTest() {
    const queryClient = useQueryClient();

    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: (labTestId) => deleteLabTest(labTestId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.labTests] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { deleteLabTest: mutate, isLoading, isSuccess };
}
