import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    Divider,
    Fade,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Modal,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
    useAddLabTest,
    useDeleteLabTest,
    useEditLabTest,
    useGetLabTestList,
    useGetLabTests,
} from "../query/hooks/labtest/labtestHook";
import {
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineReload,
} from "react-icons/ai";
import { BiTestTube } from "react-icons/bi";
import { useGetMetaData } from "../query/hooks/metadata/metadataHook";
import {
    deleteInvestigationLabTest,
    searchPatients,
    skynet,
} from "../services/api";
import {
    getEndingTimeOfDate,
    getStartingTimeOfDate,
    showNotification,
    validateAddPatient,
} from "../services/helper";
import { useAddTest, useGetTests } from "../query/hooks/test/testHook";
import PdfUploadButton from "./common/PdfUploadButton";
import { useGetReport } from "../query/hooks/reports/reportsHook";
import Lottie from "lottie-react";
import Loading1 from "../assets/loading1.json";
import { LuDownloadCloud } from "react-icons/lu";
import { FaRegFile, FaTimes } from "react-icons/fa";
import LoaderLogo from "./common/LoaderLogo";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import { BiSearchAlt } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import {
    RiAiGenerate,
    RiArrowDropDownLine,
    RiArrowDropUpLine,
} from "react-icons/ri";
import {
    useAddPatient,
    useAddPatientEntity,
} from "../query/hooks/patients/patientsHook";
import { IoMdAdd } from "react-icons/io";
import { useAddOPD } from "../query/hooks/opd/opdHook";
import ClearIcon from "@mui/icons-material/Clear";
import LabTestModal from "./LabTestModal";
import TestBill from "./common/TestBill";
import {
    clearTestReport,
    selectTestReportParams,
    setTestReportParams,
} from "../redux/features/filters/testReportSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReusableButton from "./common/ReusableButton";
import ClearButton from "./common/ClearButton";
import RedButton from "./common/RedButton";
import AddPatientForm from "./common/AddPatientForm";
import {
    selectTestParam,
    setTestParams,
} from "../redux/features/filters/testSlice";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-55%, -50%)",
    minWidth: 800,
    maxWidth: 1200,
    minHeight: 200,
    maxHeight: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
        width: 0,
    }, // Add this line for vertical scrolling
};
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 900,
    maxWidth: 1800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

const columns = [
    { id: "id", label: "ID", minWidth: 100, align: "center" },
    { id: "hid", label: "HID", minWidth: 100, align: "center" },

    { id: "name", label: "Name", minWidth: 100, align: "center" },
    {
        id: "total_amount",
        label: "Total Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "payable_amount",
        label: "Payable Amount",
        minWidth: 100,
        align: "center",
    },
    {
        id: "createdDate",
        label: "Created Date",
        minWidth: 100,
        align: "center",
    },
    { id: "actions", label: "Actions", align: "center" },
];

const initialState = {
    patient: 0,
    total_amount: 0,
    discount_amount: 0,
    discount_percent: 0,
    payable_amount: 0,
    payment_mode: "",
    payment_reference_number: "",
    tests: [
        {
            test: 0,
            charge: 0,
        },
    ],
};
const TestBooking = () => {
    const dispatch = useDispatch();
    const labtestParamms = useSelector(selectTestReportParams);
    const currentAuth = useSelector(selectCurrentAuth);
    const labTestPermission = currentAuth?.user?.permissions.find(
        (permission) => permission.module === 1011
    );
    const {
        add_permission,
        update_permission,
        delete_permission,
        view_permission,
    } = labTestPermission;

    const { metaData } = useGetMetaData();

    const testParams = useSelector(selectTestParam);

    useEffect(() => {
        dispatch(
            setTestParams({
                ...testParams,
                limit: 10000,
            })
        );
    }, []);

    const {
        testsData,
        isLoading: isDataLoading,
        isFetching,
        isRefetching,
        refetch,
    } = useGetTests(testParams);

    const [labTestId, setLabTestId] = useState("");

    const [Test, addTest] = useState({ ...initialState });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [toDelete, setToDelete] = useState("");

    // const [editedData, setEditedInfo] = useState("");
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [editedData, setEditedData] = useState({}); // Store edited data here
    const initialData = {
        aadhaar: "",
        title: "",
        name: "",
        mobile: "",
        email: "",
        age: 0,
        months: 0,
        days: 0,
        gender: "",
        marital_status: "",
        care_of: "",
        relationship: "",
        occupation: "",
        address: "",
        district: "",
        pincode: "",
        remarks: "",
    };
    const initialPatientState = {
        patient: 0,
        department: "",
        doctor: "",
        charge: 0.0,
        payable_amount: 0.0,
        payment_mode: "",
        payment_reference_number: "",
        discount_amount: 0.0,
        discount_percent: 0.0,
        discount_remark: "",
    };
    const initialPatientId = { aadhaar: "", hid: "" };
    const initialLab = {
        patient: 0,
        total_amount: 0,
        discount_amount: 0,
        discount_percent: 0,
        payable_amount: 0,
        payment_mode: "",
        payment_reference_number: "",
        tests: [],
    };
    const [labTest, setLabTest] = useState({ ...initialLab });

    const [openLab, setOpenLab] = useState(false);
    const [ptID, setPtID] = useState();
    const [step, setStep] = useState(1);
    const [ptOpen, setPtOpen] = useState(false);
    const [patientId, setPatientId] = useState(initialPatientId);
    const [isAadharDisabled, setIsAadharDisabled] = useState(false);
    const [isHidDisabled, setIsHidDisabled] = useState(false);
    const [patient, setPatient] = useState({ ...initialData });
    const [openOPD, setOpenOPD] = useState(false);
    const [valErr, setValErr] = useState([]);
    const [ptExist, setPtExist] = useState(false);
    const [finalStep, setFinalStep] = useState(false);

    const [patientState, setPatientState] = useState({
        ...initialPatientState,
    });

    // Custom Hooks

    const handlePTOpen = () => setPtOpen(true);
    const handlePTClose = () => {
        setPatient({ ...initialData });
        setLabTest({ ...initialLab });
        setStep(1);
        setPtOpen(false);
        handleClear();
    };
    const handleClose = () => {
        setEditModalOpen(false);
        setEditedData({});
    };

    const { labTestListData, isLoading } = useGetLabTestList(labTestId);

    const [labList, setLabList] = useState([]);

    useEffect(() => {
        if (!isLoading && labTestListData?.result?.list) {
            let arr = [];
            for (let item of labTestListData.result.list) {
                arr.push({
                    id: item.test,
                    entity: item.entity,
                    charge: item.charge,
                    name: item.test_name,
                });
            }
            setLabList(arr);
        }
    }, [labTestListData, isLoading]);

    const {
        labTestsData,
        isLoading: loadingData,
        isSuccess,
    } = useGetLabTests(labtestParamms);

    const { addLabTest, isLoading: isAdding } = useAddLabTest(handlePTClose);
    const {
        deleteLabTest,
        isLoading: isDeleting,
        isSuccess: isDeleted,
    } = useDeleteLabTest();
    const {
        editLabTest,
        isLoading: isEditing,
        isSuccess: isSuccessEditing,
    } = useEditLabTest(handleClose);

    const handleEditOpen = (row) => {
        setEditedData(row);
        setLabTestId(row.id);
        setEditModalOpen(true);
    };

    useEffect(() => {
        const discountAmount = parseFloat(editedData.discount_amount);
        const totalAmount = parseFloat(editedData.total_amount);

        // Check if discountAmount and totalAmount are valid numbers
        if (!isNaN(discountAmount) && !isNaN(totalAmount) && totalAmount > 0) {
            // Ensure discount_amount is not greater than total_amount
            const validDiscountAmount = Math.min(discountAmount, totalAmount);

            if (validDiscountAmount !== discountAmount) {
                // Log an error to the console when discount_amount is greater than total_amount
                showNotification(
                    "error",
                    "Discount amount cannot be greater than total amount."
                );
            }

            // Calculate discount_percentage
            const discount_percentage =
                (validDiscountAmount / totalAmount) * 100;

            // Calculate payable_amount after discount
            const discountedAmount = totalAmount - validDiscountAmount;

            setEditedData((prevData) => ({
                ...prevData,
                discount_amount: validDiscountAmount, // Update with valid discount amount
                discount_percent: parseFloat(discount_percentage.toFixed(2)),
                payable_amount: discountedAmount,
            }));
        } else {
            setEditedData((prevData) => ({
                ...prevData,
                discount_amount: 0,
                discount_percentage: 0,
                payable_amount: 0,
            }));
        }
    }, [editedData.discount_amount, editedData.total_amount]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleChangeTests = async (arr2, clearedOptions) => {
        const arr1 = [...labList];
        if (clearedOptions?.length > 0) {
            const clearOptionPost = await deleteInvestigationLabTest(
                editedData.id,
                clearedOptions[0]?.id
            );
            if (clearOptionPost.success) {
                showNotification("success", clearOptionPost.message);
            }
        }

        for (let i = 0, l = arr2.length; i < l; i++) {
            for (let j = 0, ll = arr1.length; j < ll; j++) {
                if (arr2[i].id === arr1[j].id) {
                    arr2.splice(i, 1, arr1[j]);
                    break;
                }
            }
        }

        setLabList(arr2);
    };
    useEffect(() => {
        let total = 0;
        if (labList?.length > 0) {
            for (let item of labList) {
                if (!isNaN(item.charge)) {
                    total += parseFloat(item.charge);
                }
            }
        }

        setEditedData((prevData) => ({
            ...prevData,
            total_amount: total,
        }));
    }, [labList]);

    const handleEditClick = (row) => {
        const parsedState = {
            investigation: {
                id: parseInt(editedData.id),
                patient: parseInt(editedData.patient),
                total_amount: parseFloat(editedData.total_amount) || 0,
                discount_amount: parseFloat(editedData.discount_amount) || 0,
                discount_percent: parseFloat(editedData.discount_percent),
                payable_amount: parseFloat(editedData.payable_amount),
                payment_mode: editedData.payment_mode,
                payment_reference_number:
                    editedData.payment_reference_number || "",
            },
            tests: labList.map((test) => ({
                test: parseInt(test.id),
                charge: parseFloat(test.charge),
            })),
        };

        editLabTest(parsedState);
        if (isSuccessEditing) {
            // handleClose();
            setEditModalOpen(false);
            setEditedData({});
            setLabList([]);
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    // Function to handle the delete action when confirmed
    const handleDeleteConfirmed = () => {
        deleteLabTest(toDelete);
        if (isDeleted) {
            setOpenDeleteDialog(false);
        }
    };

    const [isModalOpen, setModalOpen] = useState(false);
    const [investigationID, setInvestigationID] = useState("");
    const [updatedReport, setUpdatedReport] = useState({
        test: "",
        investigation: "",
        report: "",
    });

    const openModal = (row) => {
        setLabTestId(row.id);

        setInvestigationID(row);
        setModalOpen(true);
    };

    const closeModal = () => {
        setLabTestId("");
        setInvestigationID("");
        setModalOpen(false);
    };

    const [InvID, setInvID] = useState("");
    const { reportData, isLoading: reportLoading } = useGetReport(InvID);

    const [parentUploadedList, setParentUploadedList] = useState({});

    const handleUploadedListChange = (newUploadedList) => {
        setParentUploadedList(newUploadedList);
    };
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);

    const handleOpenReportModal = (row) => {
        setInvID(row.id);
        setIsReportModalOpen(true);
    };

    const handleCloseReportModal = () => {
        setIsReportModalOpen(false);
        setInvID("");
    };

    const handleDownloadReport = (lab) => {
        const reportUrl = `${skynet}/${lab.report}`;
        window.open(reportUrl, "_blank");
    };

    const handleAddPatient = () => {
        const isValid = validateAddPatient(patient);
        if (isValid) {
            addPatient(patient);
            if (step === 3) {
                setPatient("");
                setPatient({ ...initialData });
                setLabTest((prevState) => ({
                    ...prevState,
                    patient: ptID,
                }));
                handleClear();
            }
        } else {
            showNotification(
                "error",
                "Make Sure All Required Field Are Filled"
            );
        }
    };
    useEffect(() => {
        if (ptID) {
            setLabTest((prevState) => ({
                ...prevState,
                patient: ptID,
            }));
        }
    }, [ptID]);
    useEffect(() => {
        let total = 0;
        for (let item of labTest.tests) {
            total += isNaN(parseFloat(item.charge))
                ? 0
                : parseFloat(item.charge);
        }

        const newTotalAmount = isNaN(total) ? 0 : parseFloat(total);
        const newPayableAmount = isNaN(total - labTest.discount_amount)
            ? 0
            : parseFloat(total - labTest.discount_amount);

        setLabTest((prevLabTest) => ({
            ...prevLabTest,
            total_amount: newTotalAmount,
            payable_amount: newPayableAmount,
        }));
    }, [labTest.tests]);

    useEffect(() => {
        const discountAmount = parseFloat(labTest.discount_amount);
        const totalAmount = parseFloat(labTest.total_amount);

        const discountPercent =
            !isNaN(discountAmount) && !isNaN(totalAmount)
                ? parseFloat((discountAmount / totalAmount) * 100).toFixed(2)
                : 0;

        const payableAmount =
            !isNaN(totalAmount) && !isNaN(discountAmount)
                ? parseFloat(totalAmount - discountAmount)
                : 0;

        setLabTest({
            ...labTest,
            discount_percent: discountPercent,
            payable_amount: payableAmount,
        });
    }, [labTest.discount_amount]);

    useEffect(() => {
        let total = 0;
        if (labTest.tests.length > 0) {
            for (let item of labTest.tests) {
                if (!isNaN(item.charge)) {
                    total += parseFloat(item.charge);
                }
            }
        }

        let discountAmount =
            labTest.tests.length > 0 &&
            !isNaN(parseFloat(labTest.discount_amount))
                ? parseFloat(labTest.discount_amount)
                : 0;

        let discountPercent =
            labTest.tests.length > 0 && total !== 0
                ? (discountAmount / total) * 100
                : 0;

        let payableAmount =
            labTest.tests.length > 0 && !isNaN(total) && !isNaN(discountAmount)
                ? total - discountAmount
                : 0;

        setLabTest((prevLabTest) => ({
            ...prevLabTest,
            total_amount: total,
            discount_amount: discountAmount,
            discount_percent: parseFloat(discountPercent.toFixed(2)),
            payable_amount: parseFloat(payableAmount.toFixed(2)),
        }));
    }, [labTest.tests, labTest.discount_amount]);

    const handleCloseOPD = () => {
        if (finalStep) {
            setPatientState({ ...initialPatientState });
            setValErr([]);
            setStep(1);
            setPtExist(false);
            handlePTClose();
        }
    };
    const {
        addPatient,
        isLoading: isAddingPatient,
        isSuccess: isAdded,
    } = useAddPatient(setPtID, handlePTClose, setStep);
    const {
        addPatientEntity,
        isLoading: isAddingPatientEntity,
        isSuccess: isAddedPtEntity,
    } = useAddPatientEntity(setStep);
    const { addNewTest, isLoading: isAddingOPD } = useAddTest(
        setFinalStep,
        handleCloseOPD
    );
    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;

        const numericValue =
            (name === "age" || name === "months" || name === "days") &&
            !isNaN(parseInt(value, 10))
                ? parseInt(value, 10)
                : name === "mobile"
                ? value.replace(/\D/g, "").slice(0, 10)
                : name === "aadhaar"
                ? value.replace(/\D/g, "").slice(0, 12)
                : value;

        setPatient((prevPatient) => ({
            ...prevPatient,
            [name]: numericValue,
        }));
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setPatient((prevPatient) => ({
            ...prevPatient,
            [name]: value,
        }));
    };

    const handleAadharChange = (e) => {
        const value = e.target.value;
        setPatientId((prev) => ({
            ...prev,
            aadhaar: value,
        }));
        setIsHidDisabled(value !== "");
    };

    const handleHidChange = (e) => {
        const value = e.target.value;
        setPatientId((prev) => ({
            ...prev,
            hid: value,
        }));
        setIsAadharDisabled(value !== "");
    };

    const handleClear = () => {
        setPatientId(initialPatientId);
        setIsAadharDisabled(false);
        setIsHidDisabled(false);
    };
    const handleSearchPatient = async () => {
        try {
            if (!patientId.aadhaar && !patientId.hid) {
                showNotification(
                    "error",
                    "Please provide either Aadhaar or HID."
                );
                return;
            }
            const result = await searchPatients(
                patientId.aadhaar,
                patientId.hid
            );

            if (result.success) {
                setPtExist(true);
                setLabTest((prevState) => ({
                    ...prevState,
                    patient: result.result.id,
                }));
                const updatedPatient = {
                    aadhar: result.result.aadhar || "",
                    title: result.result.title || "",
                    name: result.result.name || "",
                    mobile: result.result.mobile || "",
                    email: result.result.email || "",
                    age: result.result.age || 0,
                    months: result.result.months || 0,
                    days: result.result.days || 0,
                    gender: result.result.gender || "",
                    marital_status: result.result.marital_status || "",
                    care_of: result.result.care_of || "",
                    relationship: result.result.relationship || "",
                    occupation: result.result.occupation || "",
                    address: result.result.address || "",
                    district: result.result.district || "",
                    pincode: result.result.pincode || "",
                    remarks: result.result.remarks || "",
                };
                setPatient({ ...updatedPatient });
                const foundPt = {
                    patient: result.result.id,
                };
                addPatientEntity(foundPt);
                setStep(2);
            } else {
                setStep(2);
                setPtExist(false);
                showNotification("error", "Patient not Found, Add New Entry");

                setPatient({ ...initialData });
            }
        } catch (error) {
            console.error("Error occurred during search:", error);
        }
    };
    const handleChangeTests2 = (arr) => {
        const obj = { ...labTest };
        obj.tests = arr;
        setLabTest(obj);
    };

    const handleChangeLab2 = (e) => {
        const { name, value } = e.target;
        if (name === "tests" && labTest.total_amount === 0) {
            setLabTest((prevLabTest) => ({
                ...prevLabTest,
                discount_amount: 0,
                discount_percent: 0,
                payable_amount: 0,
                [name]: value,
            }));
        } else {
            setLabTest((prevLabTest) => ({
                ...prevLabTest,
                [name]: value,
            }));
        }
    };
    const handleNextStep = () => {
        setStep(3);
    };
    const handleAddLabTest = (e) => {
        e.preventDefault();

        const requiredFields = [
            "patient",
            "total_amount",
            "payable_amount",
            "payment_mode",
        ];

        const missingFields = requiredFields.filter((field) => !labTest[field]);

        if (missingFields.length > 0 || labTest.tests.length === 0) {
            const errorMessage =
                missingFields.length > 0
                    ? `${missingFields
                          .map(
                              (field) =>
                                  field.charAt(0).toUpperCase() +
                                  field.slice(1).replace(/_/g, " ")
                          )
                          .join(", ")} field${
                          missingFields.length > 1 ? "s" : ""
                      } is required`
                    : "At least one test is required";

            showNotification("error", errorMessage);
            return;
        }

        const areTestsValid = labTest.tests.every(
            (test) => test.id && test.charge
        );

        if (!areTestsValid) {
            showNotification("error", "Invalid test data");
            return;
        }

        const parsedState = {
            investigation: {
                patient: parseInt(labTest.patient),
                total_amount:
                    labTest.tests.length > 0
                        ? parseFloat(labTest.total_amount)
                        : 0,
                discount_amount:
                    labTest.tests.length > 0
                        ? parseFloat(labTest.discount_amount)
                        : 0,
                discount_percent: parseFloat(labTest.discount_percent),
                payable_amount: parseFloat(labTest.payable_amount),
                payment_mode: labTest.payment_mode,
                payment_reference_number: labTest.payment_reference_number,
            },
            tests: labTest.tests.map((test) => ({
                test: parseInt(test.id),
                charge: parseFloat(test.charge),
            })),
        };

        addLabTest(parsedState);
        if (isSuccess) {
            setLabTest({ ...initialLab });
            handlePTClose();
        }
    };
    const [isBillModalOpen, setIsBillModalOpen] = useState(false);
    const [selectedOPDGenBill, setSelectedOPDGenBill] = useState("");
    const [refBy, setRefBy] = useState("");
    const [view, setView] = useState(false);
    const handleGenerateBill = (row) => {
        setSelectedOPDGenBill(row);
        setIsBillModalOpen(true);
    };
    const closeBillModal = () => {
        setIsBillModalOpen(false);
        setView(false);
        setRefBy("");
        setSelectedOPDGenBill("");
    };
    const handleSave = () => {
        setView(true);
    };
    const handleRefEdit = () => {
        setView(false);
    };
    const handleRefByChange = (event) => {
        setRefBy(event.target.value);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState({
        name: "",
        hid: "",
    });
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            [name]: value,
        }));
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Check if startDate is a valid date
    //     const isStartDateValid =
    //         startDate instanceof Date && !isNaN(startDate.getTime());

    //     // Check if endDate is a valid date
    //     const isEndDateValid =
    //         endDate instanceof Date && !isNaN(endDate.getTime());

    //     dispatch(
    //         setTestReportParams({
    //             ...labtestParamms,
    //             name: searchParams.name || "",
    //             hid: searchParams.hid || "",
    //             startDate: isStartDateValid
    //                 ? getStartingTimeOfDate(dayjs(startDate))
    //                 : "",
    //             endDate: isEndDateValid
    //                 ? getEndingTimeOfDate(dayjs(endDate))
    //                 : "",
    //         })
    //     );
    // };
    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if startDate is a valid date
        const isStartDateValid =
            startDate instanceof Date && !isNaN(startDate.getTime());

        // Check if endDate is a valid date
        const isEndDateValid =
            endDate instanceof Date && !isNaN(endDate.getTime());

        // Check if all four fields are empty, NaN, or undefined
        const allFieldsEmptyOrInvalid =
            (!searchParams.name || !searchParams.name.trim()) &&
            (!searchParams.hid || !searchParams.hid.trim()) &&
            (!startDate || isNaN(startDate.getTime())) &&
            (!endDate || isNaN(endDate.getTime()));

        if (!allFieldsEmptyOrInvalid) {
            dispatch(
                setTestReportParams({
                    ...labtestParamms,
                    name: searchParams.name || "",
                    hid: searchParams.hid || "",
                    startDate: isStartDateValid
                        ? getStartingTimeOfDate(dayjs(startDate))
                        : "",
                    endDate: isEndDateValid
                        ? getEndingTimeOfDate(dayjs(endDate))
                        : "",
                })
            );
        } else {
            // Handle case where all fields are empty, NaN, or undefined
            showNotification("error", "Please fill in at least one field.");
        }
    };

    const handleClearSearch = () => {
        dispatch(clearTestReport());
        setStartDate();
        setEndDate();
        setSearchParams({
            name: "",
            hid: "",
        });
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setTestReportParams({
                ...labtestParamms,
                offset: (value - 1) * labtestParamms.limit,
            })
        );
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSubmit(event);
        }
    };
    const handleKeyPressClear = (event) => {
        if (event.key === "Escape") {
            handleClearSearch();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", handleKeyPressClear);
        return () => {
            document.removeEventListener("keydown", handleKeyPressClear);
        };
    }, []);

    const handleKeyPress2 = (event) => {
        if (event.key === "Enter") {
            if (!ptExist) {
                handleAddPatient();
            } else {
                handleNextStep();
            }
        }
    };
    console.log("labTestsData",labTestsData)
    return (
        <>
            <TestBill
                handleRefEdit={handleRefEdit}
                refBy={refBy}
                handleSave={handleSave}
                view={view}
                handleRefByChange={handleRefByChange}
                billData={selectedOPDGenBill}
                closeBillModal={closeBillModal}
                isBillModalOpen={isBillModalOpen}
            />
            <div className="flex justify-between px-10 mb-5">
                <div>
                    <div className="flex items-center space-x-3">
                        <Typography variant="h5">Investigations</Typography>
                        {add_permission === 1 && (
                            <Tooltip title="Refresh" placement="bottom-start">
                                <button
                                    onClick={refetch}
                                    className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                    style={{ cursor: "pointer" }}
                                >
                                    <AiOutlineReload size={20} />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>

                <Tooltip title="Add " placement="bottom-start">
                    <button
                        onClick={handlePTOpen}
                        className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                    >
                        <IoMdAdd size={18} />
                    </button>
                </Tooltip>
            </div>
            <div
                className="flex items-center space-x-8"
                style={{ position: "relative", zIndex: "999" }}
            >
                <form
                    onSubmit={handleSubmit}
                    onKeyPress={handleKeyPress}
                    className="flex items-center space-x-4"
                >
                    <TextField
                        label="Name"
                        variant="outlined"
                        name="name"
                        value={searchParams.name}
                        onChange={handleSearch}
                    />
                    <TextField
                        label="HID"
                        variant="outlined"
                        name="hid"
                        value={searchParams.hid}
                        onChange={handleSearch}
                    />
                    <DatePicker
                        maxDate={dayjs().toDate()}
                        className="px-6 py-4 w-[280px] border border-gray-300 rounded-xl"
                        dateFormat="yyyy/MM/dd"
                        placeholderText="StartDate - EndDate"
                        selectsRange={true}
                        isClearable
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                    />
                    <ReusableButton
                        title="Search"
                        onClick={handleSubmit}
                        type="submit"
                    />

                    <ClearButton title="Clear" onClick={handleClearSearch} />
                </form>
            </div>
            <div className="mt-10">
                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "15px",
                    }}
                    elevation={24}
                >
                    <TableContainer
                        sx={{
                            height: 520,
                            overflowX: "scroll",
                            "&::-webkit-scrollbar": {
                                width: 0,
                            },
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                                color: "white",
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {!isDataLoading && !isFetching && !isRefetching ? (
                                <TableBody>
                                    {labTestsData?.result?.list?.map((row) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                key={row.id}
                                                align="center"
                                            >
                                                <TableCell
                                                    align="center"
                                                    className="capitalize"
                                                >
                                                    {row.id}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className="capitalize"
                                                >
                                                    {row.hid}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className="capitalize"
                                                >
                                                    {row.patient_name}
                                                </TableCell>

                                                <TableCell align="center">
                                                    ₹ {row.total_amount}
                                                </TableCell>
                                                <TableCell align="center">
                                                    ₹ {row.payable_amount}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {dayjs(
                                                        row.created_on
                                                    ).format(
                                                        "DD-MM-YYYY,h:mm:ss A"
                                                    )}
                                                </TableCell>

                                                <TableCell
                                                    align="center"
                                                    sx={{
                                                        width: 120,
                                                    }}
                                                >
                                                    <div className="flex items-center justify-center space-x-4">
                                                        {view_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Download Report"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleOpenReportModal(
                                                                            row
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <FaRegFile className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                        {update_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Upload Test Reports"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        openModal(
                                                                            row
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <BiTestTube className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                        {view_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Generate Bill"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleGenerateBill(
                                                                            row
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <RiAiGenerate className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                        {update_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Edit Test"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleEditOpen(
                                                                            row
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                                >
                                                                    <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                        {delete_permission ===
                                                            1 && (
                                                            <Tooltip
                                                                title="Delete Test"
                                                                placement="bottom-start"
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleOpenDeleteDialog(
                                                                            row.id
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                                >
                                                                    <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                                </button>
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            colSpan={7}
                                            align="center"
                                            sx={{
                                                width: 120,
                                            }}
                                        >
                                            <div className="flex items-center justify-center">
                                                {/* <Lottie
                                                    animationData={Loading1}
                                                    loop={true}
                                                    style={{
                                                        height: "380px",
                                                        width: "400px",
                                                    }}
                                                /> */}
                                                <LoaderLogo />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <div className="flex items-center justify-center my-4">
                        <Pagination
                            count={
                                Math.ceil(
                                    labTestsData?.result?.count /
                                        labtestParamms.limit
                                ) || 0
                            }
                            page={currentPage}
                            onChange={handlePageChange}
                            sx={{
                                "& .MuiSelect-select": {
                                    display: "none !important",
                                },
                                "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                    {
                                        display: "none !important",
                                    },
                            }}
                        />
                    </div>
                </Paper>
            </div>

            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeleting}
            />
            <Modal open={isEditModalOpen} onClose={handleClose}>
                <Fade in={isEditModalOpen}>
                    <Box sx={style}>
                        <div className="flex space-x-2">
                            <div>
                                <div className="grid grid-cols-2 gap-5 p-3">
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        disabled
                                        label="Patient Name"
                                        name="patient_name"
                                        value={editedData.patient_name}
                                        onChange={handleInputChange}
                                    />

                                    <FormControl
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        disabled
                                    >
                                        <InputLabel>Gender</InputLabel>
                                        <Select
                                            required
                                            disabled
                                            name="patient_gender"
                                            value={
                                                editedData.patient_gender || ""
                                            }
                                            onChange={handleInputChange}
                                            label="Gender"
                                        >
                                            <MenuItem value="male">
                                                Male
                                            </MenuItem>
                                            <MenuItem value="female">
                                                Female
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        disabled
                                        label="Mobile"
                                        name="patient_mobile"
                                        value={editedData.patient_mobile}
                                        onChange={handleInputChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        disabled
                                        label="Age"
                                        name="patient_age"
                                        value={editedData.patient_age}
                                        onChange={handleInputChange}
                                    />
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                    >
                                        <InputLabel id="payment-mode-label">
                                            Payment Mode
                                        </InputLabel>
                                        <Select
                                            labelId="payment-mode-label"
                                            label="Payment Mode"
                                            name="payment_mode"
                                            value={
                                                editedData.payment_mode || ""
                                            }
                                            onChange={handleInputChange}
                                            className="capitalize"
                                        >
                                            {metaData?.result?.payment_modes?.map(
                                                (mode) => (
                                                    <MenuItem
                                                        key={mode}
                                                        value={mode}
                                                        className="capitalize"
                                                    >
                                                        {mode}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Total Amount"
                                        name="total_amount"
                                        value={editedData.total_amount}
                                        onChange={handleInputChange}
                                        disabled
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Discount Amount"
                                        name="discount_amount"
                                        value={editedData.discount_amount}
                                        onChange={handleInputChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        disabled
                                        label="Discount Percentage"
                                        name="discount_percent"
                                        value={editedData.discount_percent}
                                        onChange={handleInputChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Payable Amount"
                                        name="payable_amount"
                                        value={editedData.payable_amount}
                                        onChange={handleInputChange}
                                        disabled
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Payable Amount"
                                        name="payable_amount"
                                        value={editedData.discount_remark}
                                        onChange={handleInputChange}
                                        disabled
                                    />
                                </div>
                                <div className="flex justify-evenly">
                                    <ReusableButton
                                        onClick={handleEditClick}
                                        title="Save"
                                    />

                                    <RedButton
                                        onClick={handleClose}
                                        title="Cancel"
                                    />
                                </div>
                            </div>
                            <div className="w-1/2 mt-6">
                                <Autocomplete
                                    margin="normal"
                                    fullWidth
                                    id=""
                                    size="small"
                                    multiple
                                    border="none"
                                    sx={{ width: 300 }}
                                    getOptionLabel={(option) =>
                                        typeof option === "string"
                                            ? option
                                            : option.name
                                    }
                                    filterOptions={(x) => x}
                                    options={testsData?.result?.list || []}
                                    autoComplete
                                    disablePortal
                                    includeInputInList
                                    filterSelectedOptions
                                    value={labList || []}
                                    isOptionEqualToValue={(option, value) => {
                                        if (value) {
                                            return option.id === value.id;
                                        }
                                    }}
                                    onChange={(event, newValue) => {
                                        // dispatch(setItemSearchField({ ...itemSearchFields, categoryValue: newValue }));
                                        // changeParamsCategory(newValue?._id || "")
                                        const clearedOptions = labList.filter(
                                            (option) =>
                                                !newValue.some(
                                                    (selectedOption) =>
                                                        selectedOption.id ===
                                                        option.id
                                                )
                                        );
                                        handleChangeTests(
                                            newValue,
                                            clearedOptions
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Type to search test"
                                            fullWidth
                                        />
                                    )}
                                />
                                <div className="mt-4">
                                    <TableContainer
                                        component={Paper}
                                        sx={{
                                            height: 320,
                                        }}
                                    >
                                        <Table
                                            stickyHeader
                                            aria-label="sticky table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            fontWeight: "bold",
                                                            background:
                                                                "#eeeeee",
                                                            textTransform:
                                                                "uppercase",
                                                            backgroundColor:
                                                                "#27272A",
                                                            color: "white",
                                                        }}
                                                    >
                                                        Test Name
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            fontWeight: "bold",
                                                            background:
                                                                "#eeeeee",
                                                            textTransform:
                                                                "uppercase",
                                                            backgroundColor:
                                                                "#27272A",
                                                            color: "white",
                                                        }}
                                                    >
                                                        Charge
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {labList?.map((lab, index) => {
                                                    return (
                                                        <TableRow
                                                            key={index}
                                                            style={{
                                                                backgroundColor:
                                                                    index %
                                                                        2 ===
                                                                    0
                                                                        ? "#E4E4E7"
                                                                        : "white",
                                                            }}
                                                        >
                                                            <TableCell>
                                                                {lab.test_name
                                                                    ? lab.test_name
                                                                    : lab.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                ₹{""}
                                                                {lab.charge}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                <TableRow
                                                    style={{
                                                        position: "sticky",
                                                        bottom: 0,
                                                        zIndex: 999,
                                                        backgroundColor:
                                                            "white",
                                                    }}
                                                >
                                                    <TableCell
                                                        colSpan={1}
                                                        align="center"
                                                    >
                                                        <strong>
                                                            Total Charge:
                                                        </strong>
                                                    </TableCell>
                                                    <TableCell>
                                                        ₹{""}
                                                        {labList.reduce(
                                                            (acc, lab) =>
                                                                acc +
                                                                lab.charge,
                                                            0
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                {/* {labList?.map((lab) => {
                                    return (
                                        <div className="mt-4">
                                            <div className="flex items-center justify-between px-5 space-y-2 ">
                                                {lab.test_name ? (
                                                    <p>{lab.test_name}</p>
                                                ) : (
                                                    <p>{lab.name}</p>
                                                )}
                                                <p>{lab.charge}</p>
                                            </div>
                                        </div>
                                    );
                                })} */}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>

            <Modal open={isModalOpen} onClose={closeModal}>
                <Fade in={isModalOpen}>
                    <Box sx={style}>
                        <>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                style={{
                                                    fontWeight: "bold",
                                                    background: "#eeeeee",
                                                    textTransform: "uppercase",
                                                    backgroundColor: "#27272A",
                                                    color: "white",
                                                }}
                                            >
                                                Test Name
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{
                                                    fontWeight: "bold",
                                                    background: "#eeeeee",
                                                    textTransform: "uppercase",
                                                    backgroundColor: "#27272A",
                                                    color: "white",
                                                }}
                                            >
                                                Charge
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{
                                                    fontWeight: "bold",
                                                    background: "#eeeeee",
                                                    textTransform: "uppercase",
                                                    backgroundColor: "#27272A",
                                                    color: "white",
                                                }}
                                            >
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isLoading ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={3}
                                                    align="center"
                                                >
                                                    <Lottie
                                                        animationData={Loading1}
                                                        loop={true}
                                                        style={{
                                                            height: "380px",
                                                            width: "400px",
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            labList?.map((lab) => (
                                                <React.Fragment key={lab?.id}>
                                                    <TableRow>
                                                        <TableCell>
                                                            {lab.test_name
                                                                ? lab.test_name
                                                                : lab.name}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            ₹ {lab.charge}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            <PdfUploadButton
                                                                investigationID={
                                                                    investigationID
                                                                }
                                                                lab={lab}
                                                                updatedReport={
                                                                    updatedReport
                                                                }
                                                                setUpdatedReport={
                                                                    setUpdatedReport
                                                                }
                                                                uploadedList={
                                                                    parentUploadedList
                                                                }
                                                                onUploadedListChange={
                                                                    handleUploadedListChange
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {!isLoading && (
                                <div
                                    style={{
                                        textAlign: "center",
                                        marginTop: "16px",
                                    }}
                                >
                                    <button
                                        className="w-[10%] bg-red-600 hover:bg-red-700 text-white py-2 px-5 rounded-lg duration-300"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            )}
                        </>
                    </Box>
                </Fade>
            </Modal>

            <Modal open={isReportModalOpen} onClose={handleCloseReportModal}>
                <Fade in={isReportModalOpen}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "40%",
                            left: "50%",
                            transform: "translate(-50%, -40%)",
                            minWidth: 600,
                            maxWidth: 1000,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                            borderRadius: "5px",
                            minHeight: 50,
                            maxHeight: 600,
                        }}
                    >
                        <>
                            <div className="flex justify-center pb-4">
                                <label className="text-2xl font-semibold ">
                                    REPORTS
                                </label>
                            </div>
                            {reportLoading ? (
                                <div className="flex items-center justify-center">
                                    <Lottie
                                        animationData={Loading1}
                                        loop={true}
                                        style={{
                                            height: "380px",
                                            width: "400px",
                                        }}
                                    />
                                </div>
                            ) : (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        fontWeight: "bold",
                                                        background: "#eeeeee",
                                                        textTransform:
                                                            "uppercase",
                                                        backgroundColor:
                                                            "#27272A",
                                                        color: "white",
                                                    }}
                                                >
                                                    Test Name
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    style={{
                                                        fontWeight: "bold",
                                                        background: "#eeeeee",
                                                        textTransform:
                                                            "uppercase",
                                                        backgroundColor:
                                                            "#27272A",
                                                        color: "white",
                                                        fontSize: 16,
                                                    }}
                                                >
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportData?.result?.count !== 0 ? (
                                                reportData?.result?.list?.map(
                                                    (lab, index) => (
                                                        <TableRow
                                                            key={lab?.test}
                                                        >
                                                            <TableCell>
                                                                <div className="flex justify-between w-[60%]">
                                                                    <div>
                                                                        <Typography>
                                                                            {`${
                                                                                index +
                                                                                1
                                                                            }. ${
                                                                                lab.test_name
                                                                                    ? lab.test_name
                                                                                    : lab.name
                                                                            }`}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <div className="">
                                                                    <Tooltip
                                                                        title={
                                                                            lab.test_name
                                                                        }
                                                                        placement="bottom-start"
                                                                    >
                                                                        <Button
                                                                            onClick={() =>
                                                                                handleDownloadReport(
                                                                                    lab
                                                                                )
                                                                            }
                                                                            variant="contained"
                                                                            color="primary"
                                                                        >
                                                                            <LuDownloadCloud />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography>
                                                            No Records Found
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                            <div className="flex items-center justify-center pt-5">
                                <button
                                    onClick={handleCloseReportModal}
                                    className="w-[40%] bg-red-600 hover:bg-red-700 text-white py-2 px-5 rounded-lg duration-300"
                                >
                                    Close
                                </button>
                            </div>
                        </>
                    </Box>
                </Fade>
            </Modal>

            <Modal
                open={ptOpen}
                onClose={handlePTClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={ptOpen}>
                    <Box sx={styles}>
                        <>
                            {step === 1 && (
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSearchPatient();
                                    }}
                                    className="justify-center w-full space-y-4 "
                                >
                                    <Typography variant="h4">
                                        Search Patient
                                    </Typography>
                                    <TextField
                                        required
                                        name="aadhaar"
                                        className="col-span-2"
                                        label="Enter Patient Aadhaar"
                                        variant="outlined"
                                        fullWidth
                                        value={patientId.aadhaar}
                                        onChange={handleAadharChange}
                                        disabled={isAadharDisabled}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    sx={{
                                                        visibility:
                                                            patientId.aadhaar
                                                                ? "visible"
                                                                : "hidden",
                                                    }}
                                                    variant="outlined"
                                                    onClick={handleClear}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        inputProps={{
                                            inputMode: "numeric",
                                            pattern: "[0-9]*",
                                            maxLength: 12,
                                        }}
                                        onInput={(e) => {
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                );
                                        }}
                                    />
                                    <TextField
                                        required
                                        name="hid"
                                        className="col-span-2"
                                        label="Enter Patient HID"
                                        variant="outlined"
                                        fullWidth
                                        value={patientId.hid}
                                        onChange={handleHidChange}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    sx={{
                                                        visibility:
                                                            patientId.hid
                                                                ? "visible"
                                                                : "hidden",
                                                    }}
                                                    variant="outlined"
                                                    onClick={handleClear}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ),
                                        }}
                                        disabled={isHidDisabled}
                                    />

                                    <div className="flex w-full py-2 justify-evenly ">
                                        <ReusableButton
                                            title="Add"
                                            type="submit"
                                        />

                                        <RedButton
                                            title="Cancel"
                                            onClick={handlePTClose}
                                        />
                                    </div>
                                </form>
                            )}
                            {step === 2 && (
                                <AddPatientForm
                                    handleKeyPress2={handleKeyPress2}
                                    handleSelectChange={handleSelectChange}
                                    handleTextFieldChange={
                                        handleTextFieldChange
                                    }
                                    handleAddPatient={handleAddPatient}
                                    handleNextStep={handleNextStep}
                                    handlePTClose={handlePTClose}
                                    patient={patient}
                                    ptExist={ptExist}
                                />
                            )}
                            {step === 3 && (
                                <>
                                    <LabTestModal
                                        labTest={labTest}
                                        handleChange={handleChangeLab2}
                                        handleChangeTests={handleChangeTests2}
                                        handleClose={handlePTClose}
                                        metaData={metaData}
                                        handleAdd={handleAddLabTest}
                                    />
                                </>
                            )}
                        </>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default TestBooking;
