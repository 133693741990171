import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { FaRegEye } from "react-icons/fa";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import ReusableButton from "../ReusableButton";
import RedButton from "../RedButton";
import {
    Backdrop,
    Box,
    Fade,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetIPD } from "../../../query/hooks/ipd/ipds";
import {
    useAddIPDBed,
    useDeleteIPDBed,
    useGetIPDBeds,
    useUpdateIPDBed,
} from "../../../query/hooks/ipd/bedHistoryHook";
import { useGetBeds } from "../../../query/hooks/ipd/beds";
import { useGetRooms } from "../../../query/hooks/ipd/rooms";
import { useGetFloors } from "../../../query/hooks/ipd/floors";
import { showNotification } from "../../../services/helper";

const columns = [
    {
        id: "room_name",
        label: "Room Name",
        align: "center",
        paddingX: 2,
        minWidth: 120,
        width: "auto",
        maxWidth: "none",
    },
    {
        id: "bed_number",
        label: "Bed Number",
        align: "center",
        paddingX: 2,
        minWidth: 120,
        width: "auto",
        maxWidth: "none",
    },
    {
        id: "floor_name",
        label: "Floor",
        align: "center",
        paddingX: 2,
        minWidth: 120,
        width: "auto",
        maxWidth: "none",
    },
    {
        id: "bed_charge",
        label: "Bed Charge",
        align: "center",
        paddingX: 2,
        minWidth: 120,
        width: "auto",
        maxWidth: "none",
    },
    {
        id: "nurse_charge",
        label: "Nurse Charge",
        align: "center",
        paddingX: 2,
        minWidth: 120,
        width: "auto",
        maxWidth: "none",
    },
];

const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 200,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};
const BedHistory = () => {
    const params = useParams();
    const { bedsData } = useGetBeds();
    const { roomsData } = useGetRooms();

    const { floorsData } = useGetFloors();
    const { ipdData, isLoading: isPtLoading } = useGetIPD(params.id);
    // IPD Beds
    const {
        ipdBedsData,
        isLoading: isBedsLoading,
        isSuccess: isBedsSuccess,
        refetch: refetchBeds,
    } = useGetIPDBeds(ipdData?.result?.id);
    const { addIPDBed, isLoading: isAddingBed } = useAddIPDBed();
    const { updateIPDBed, isLoading: isUpdatingBed } = useUpdateIPDBed();
    const { deleteIPDBed, isLoading: isDeletingBed } = useDeleteIPDBed();

    const [formData, setFormData] = useState({
        floor: "",
        room: "",
        bed: "",
    });
    const [editBedData, setEditBedData] = useState(null);

    const [selectedFloor, setSelectedFloor] = useState("");
    const [selectedRoom, setSelectedRoom] = useState("");
    // State to store filtered rooms
    const [filteredRooms, setFilteredRooms] = useState([]);
    const [filteredBeds, setFilteredBeds] = useState([]);

    // Handler for floor selection change
    const handleFloorChange = (event) => {
        const selectedFloorId = event.target.value;
        setSelectedFloor(selectedFloorId);
        setFormData((prevState) => ({
            ...prevState,
            floor: selectedFloorId,
        }));

        // Filter rooms based on selected floor
        const filtered = roomsData?.result?.list?.filter(
            (room) => room.floor === parseInt(selectedFloorId)
        );
        setFilteredRooms(filtered || []);
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
        setEditBedData(ipdData?.result?.id);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({
            floor: "",
            room: "",
            bed: null,
        });
    };

    const handleRoomChange = (event) => {
        const selectedRoomId = event.target.value;
        setSelectedRoom(selectedRoomId);
        setFormData((prevState) => ({
            ...prevState,
            room: selectedRoomId,
        }));
        const filtered = bedsData?.result?.list?.filter(
            (bed) => bed.room === parseInt(selectedRoomId)
        );
        setFilteredBeds(filtered || []);
    };
    useEffect(() => {
        setFormData((prevState) => ({
            ...prevState,
            room: 0,
            bed: 0,
        }));
    }, [selectedFloor]);
    const handleAddBed = () => {
        if (!formData.bed) {
            return showNotification("error", "Select a Bed");
        }
        addIPDBed({
            ipdID: ipdData.result.id,
            body: { bed: formData.bed },
        });
        handleClose();
    };
    return (
        <div>
            <div className="mb-4">
                <ReusableButton title="Transfer Bed" onClick={handleOpen} />
            </div>
            <Paper elevation={24}>
                <TableContainer
                    component={Paper}
                    sx={{
                        height: 520,
                        overflowX: "scroll",
                        "&::-webkit-scrollbar": {
                            width: 0,
                        },
                    }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            paddingX: column.paddingX,
                                        }}
                                        style={{
                                            minWidth: column.minWidth,
                                            width: column.width,
                                            maxWidth: column.maxWidth,
                                            fontWeight: "bold",
                                            background: "#eeeeee",
                                            textTransform: "uppercase",
                                            backgroundColor: "#27272A",
                                            color: "white",
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ipdBedsData?.result?.list?.map((item, index) => (
                                <TableRow
                                    key={index}
                                    style={{
                                        backgroundColor:
                                            index % 2 === 0
                                                ? "#E4E4E7"
                                                : "white",
                                    }}
                                >
                                    <TableCell align="center">
                                        {item.room_name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.bed_number}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.floor_name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.bed_charge}
                                    </TableCell>

                                    <TableCell align="center">
                                        {item.nurse_charge}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <>
                            <div className="flex items-center w-full space-x-4">
                                <FormControl fullWidth>
                                    <InputLabel>Floor</InputLabel>

                                    <Select
                                        name="floor"
                                        label="Floor"
                                        value={formData.floor || ""}
                                        onChange={handleFloorChange}
                                    >
                                        {floorsData?.result?.list?.map(
                                            (floor) => (
                                                <MenuItem
                                                    key={floor.id}
                                                    value={floor.id}
                                                >
                                                    {floor.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Room</InputLabel>
                                    <Select
                                        id="demo-simple-select"
                                        name="room"
                                        label="Room"
                                        value={formData.room || ""}
                                        onChange={handleRoomChange}
                                        disabled={!filteredRooms}
                                    >
                                        {filteredRooms.map((room) => (
                                            <MenuItem
                                                key={room.id}
                                                value={room.id}
                                            >
                                                {room.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Bed</InputLabel>
                                    <Select
                                        id="demo-simple-select"
                                        disabled={!filteredBeds}
                                        name="bed"
                                        label="Bed"
                                        value={formData.bed || ""}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                bed: e.target.value,
                                            })
                                        }
                                    >
                                        {/* {filteredBeds?.map((bed) => (
                                            <MenuItem
                                                key={bed.id}
                                                value={bed.id}
                                            >
                                                {bed.bed_number}
                                            </MenuItem>
                                        ))} */}

                                        {filteredBeds
                                            .filter(
                                                (bed) => bed.is_occupied !== 1
                                            )
                                            .map((bed) => (
                                                <MenuItem
                                                    key={bed.id}
                                                    value={bed.id}
                                                >
                                                    {bed.bed_number}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="flex items-center mt-4 justify-evenly">
                                <ReusableButton
                                    title="Confirm Transfer"
                                    onClick={handleAddBed}
                                />
                                <RedButton
                                    title="Cancel"
                                    onClick={handleClose}
                                />
                            </div>
                        </>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default BedHistory;
