import React, { useEffect, useState } from "react";
import { useGetOPDFile } from "../../query/hooks/patientsSide/patientSideHooks";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { SaveAlt as SaveAltIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import { getOPDFiles, skynet } from "../../services/api";
import { queryKeys } from "../../query/keys";
import { useQuery } from "@tanstack/react-query";
import { MdOutlineFolderOff } from "react-icons/md";
import NoDataFound from "../common/NoDataFound";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const OpdFile = () => {
  const params = useParams();

  const { data } = useQuery({
    queryKey: [`${queryKeys.opdFile}-${params.id}`],
    queryFn: () => getOPDFiles(params.id),
    enabled: params.id ? true : false,
  });

  const handleDownload = (opd) => {
    const downloadUrl = `${skynet}/${opd.file}`;
    window.open(downloadUrl, "_blank");
  };
  return (
    <div>
      {data?.result?.count === 0 ? (
        <List>
          {data.result.list.map((opd, index) => (
            <div key={opd.id}>
              <ListItem
                sx={{
                  backgroundColor: "#27272A",
                  color: "#fff",
                  border: "1px solid #fff",
                  borderRadius: "5px",
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    color: "#fff",
                  }}
                  primary={opd.patient_name}
                  secondary={
                    <>
                      <Typography>{`Name: ${opd.title}`}</Typography>
                      <Typography>{`Created on: ${dayjs(opd.created_on).format(
                        "DD/MM/YYYY"
                      )}`}</Typography>
                    </>
                  }
                  secondaryTypographyProps={{
                    color: "#fff",
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="start"
                    onClick={() => handleDownload(opd)}
                    sx={{ color: "#fff" }}
                  >
                    <SaveAltIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              {index < data.result.list.length - 1 && (
                <Divider
                  sx={{ backgroundColor: "#fff" }}
                  variant="inset"
                  component="li"
                />
              )}
            </div>
          ))}
        </List>
      ) : (
        // <Paper
        //   elevation={5}
        //   sx={{
        //     width: 420,
        //     height: 200,
        //     display: "flex",
        //     justifyContent: "center",
        //     placeItems: "center",
        //     marginX: "auto",
        //   }}
        // >
        //   <Typography variant="h3" className="flex space-x-3">
        //     <MdOutlineFolderOff />
        //     No data
        //   </Typography>
        // </Paper>
        <NoDataFound />
      )}
    </div>
  );
};

export default OpdFile;
