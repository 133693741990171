import { isError, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { store } from "../../../redux/store";
import { useSelector } from "react-redux";
import { showNotification } from "../../../services/helper";
import { addFloor, deleteFloor, getFloors, updateFloor } from "../../../services/api";



export function useGetFloors(params = {
    name: "",
    offset: 0,
    limit: 10,
}) {
    const { name,
        offset,
        limit } = params
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.floors, {
            name,
            offset,
            limit
        }],
        queryFn: () => getFloors({
            name,
            offset,
            limit
        }),
        // queryFn: () => getServices({ offset: offset, limit: limit, }),
    });

    return { floorsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

export function useAddFloor() {

    // Replace with how you access your application state

    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (floorData) => addFloor(floorData), // Assuming you have a token in your Redux state

        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.floors] });
            if (data?.success) {
                showNotification("success", `Floor added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addFloor: mutate, isLoading };
}


export function useEditFloor() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (postData) => updateFloor(postData.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.floors] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { editFloors: mutate, isLoading };
}


export function useDeleteFloor() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (floorID) => deleteFloor(floorID),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.floors] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteFloor: mutate, isLoading };
}