import React from "react";
import { Paper, Tooltip, Box } from "@mui/material";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";

const DeptCard = ({
    imgSrc,
    icon,
    name,
    onEdit,
    onDelete,
    update_permission,
    delete_permission,
}) => {
    return (
        <Paper
            elevation={20}
            sx={{ borderRadius: "15px", height: 170, width: 300 }}
        >
            <Box
                sx={{
                    borderRadius: "15px",
                    padding: 1,
                    backgroundColor: "#white",
                }}
            >
                <section className="flex w-full space-x-3 justify-evenly">
                    <div className="">
                        <Box
                            sx={{
                                mt: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    overflow: "clip",
                                    width: "90px",
                                    height: "90px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#1D4ED8",
                                    background:
                                        "linear-gradient(225deg, #dcd8d8, #ffffff)",
                                    boxShadow:
                                        "-5px 5px 10px #9c9a9a, 5px -5px 10px #ffffff",
                                }}
                            >
                                {/* {icon} */}
                                <img
                                    src={imgSrc}
                                    alt="index"
                                    loading="lazy"
                                    className="object-contain object-center"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                    }}
                                />
                            </div>
                        </Box>
                        <div
                            style={{
                                textAlign: "center",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }}
                            className="px-3 mt-5"
                        >
                            <p
                                className="flex items-center space-x-4"
                                style={{
                                    color: "#111827",
                                }}
                            >
                                <span>
                                    <FaBuilding
                                        size={15}
                                        className="mr-2 text-2xl text-red-700"
                                    />
                                </span>
                                <span className="font-semibold">{name}</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col mt-5 space-y-5 ">
                        {update_permission && (
                            <Tooltip title="Edit" placement="top-end">
                                <button
                                    onClick={onEdit}
                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                >
                                    <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                </button>
                            </Tooltip>
                        )}

                        {delete_permission && (
                            <Tooltip title="Delete" placement="top-end">
                                <button
                                    onClick={onDelete}
                                    className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                >
                                    <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </section>
            </Box>
        </Paper>
    );
};

export default DeptCard;
