import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { useGetPublicMetaData } from "../query/hooks/metadata/metadataHook";
import { useLogin } from "../query/hooks/auth/authHook";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import {
    useGetPtOTP,
    useVerifyPtOTP,
} from "../query/hooks/patientsSide/patientSideHooks";
import { showNotification } from "../services/helper";
import OtpInput from "react-otp-input";

const LoginPage = () => {
    const navigate = useNavigate();
    const [showLoginbox, setShowLoginbox] = useState(false);
    const [entity, setEntity] = useState("");
    const [loginStep, setLoginStep] = useState(1); // 1 for entering mobile number, 2 for OTP
    const [mobileNumber, setMobileNumber] = useState("");
    const [otp, setOtp] = useState("");
    const { publicMetaData: metaData, isLoading } = useGetPublicMetaData();
    const { mutate: loginFunc } = useLogin(navigate);
    const { mutate: ptLoginFunc, isLoading: isLogging } =
        useGetPtOTP(setLoginStep);
    const { mutate: otpSubmitFunc, isLoading: isVerifying } =
        useVerifyPtOTP(navigate);

    const [login, setLogin] = useState({
        email: "",
        password: "",
        entity: "",
    });
    const handleForgetPassowrd = () => {
        navigate("/accountrecovery");
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setLogin((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        loginFunc(login);
    };

    const [showAdminLoginbox, setShowAdminLoginbox] = useState(false);
    const [showPatientLoginbox, setShowPatientLoginbox] = useState(false);

    const handleLogin = (role) => {
        // You can set up different logic based on the role
        if (role === "admin") {
            // Show admin login box
            setShowAdminLoginbox(true);
            setShowPatientLoginbox(false);
        } else if (role === "patient") {
            // Show patient login box
            setShowAdminLoginbox(false);
            setShowPatientLoginbox(true);
        }
    };

    const handlePtChange = (value, inputType) => {
        if (inputType === "mobileNumber") {
            setMobileNumber(value);
        } else if (inputType === "otp") {
            setOtp(value);
        }
    };

    const handleMobileNumberSubmit = (event) => {
        event.preventDefault();
        ptLoginFunc({ mobile: mobileNumber });
    };

    const handleOtpSubmit = (event) => {
        event.preventDefault();
        otpSubmitFunc({ otp: otp, mobile: mobileNumber });
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    };
    return (
        <>
            <div className="sticky h-[70px] top-0 z-50 flex items-center justify-end bg-black">
                <button
                    onClick={() => navigate("/")}
                    className="h-full px-4 mb-2 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    Home
                </button>
                <button
                    onClick={() => navigate("/aboutus")}
                    className="h-full px-4 mb-2 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    About Us
                </button>
                <button
                    onClick={() => navigate("/terms")}
                    className="h-full px-4 mb-2 mr-4 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    Terms & Conditions
                </button>
                <button
                    onClick={() => navigate("/contactus")}
                    className="h-full px-4 mb-2 mr-4 font-bold text-center text-white no-underline duration-500 ease-in-out border-white group hover:underline underline-offset-4 hover:scale-105 decoration-white"
                >
                    Contact Us
                </button>
            </div>

            <div className="relative flex flex-col items-center justify-start w-full h-screen overflow-hidden bg-black lg:flex-row lg:justify-evenly">
                <div className="w-1/2 mt-8 lg:mt-5">
                    <div className="flex items-center justify-center w-full">
                        <img
                            src={logo}
                            alt="Logo"
                            className="lg:w-[80%] w-[60%] max-w-[500px] h-auto opacity-80 animate-rotateAndScale"
                        />
                    </div>
                </div>

                <div className="w-1/2 py-5 shadow-lg bg-opacity-10 rounded-xl ">
                    <div className="flex flex-col items-center text-white">
                        <>
                            <p className="text-[100px] font-semibold text-white  font-oswald">
                                ARC
                            </p>
                            {!showAdminLoginbox && !showPatientLoginbox && (
                                <>
                                    <p className="lg:text-[20px] text-[14px] mt-2 text-white font-serif text-center">
                                        Bridging Technology and Healthcare
                                    </p>
                                    <div>
                                        <p className="mt-5 font-serif text-2xl text-center text-white">
                                            Login As
                                        </p>
                                        <div className="flex items-center mt-12 lg:mt-5 space-x-7">
                                            <button
                                                onClick={() =>
                                                    handleLogin("admin")
                                                }
                                                className="px-5 py-3 font-bold rounded-full group w-[200px] text-center text-black bg-white border-white hover"
                                            >
                                                Admin
                                            </button>
                                            <button
                                                onClick={() =>
                                                    handleLogin("patient")
                                                }
                                                className="px-5 py-3 font-bold rounded-full group w-[200px] text-center text-black bg-white border-white hover"
                                            >
                                                Patient
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>

                        {showAdminLoginbox && (
                            <form
                                className="w-full lg:w-1/2 animate-slide-in-left"
                                onSubmit={handleSubmit}
                            >
                                <div className="my-4 text-lg">
                                    <TextField
                                        onKeyPress={handleKeyPress}
                                        className="w-full"
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            className: "text-white rounded-3xl",
                                            style: {
                                                color: "white", // Set the text color to white
                                                "&::placeholder": {
                                                    color: "white", // Set the placeholder color to white
                                                },
                                            },
                                        }}
                                        placeholder="Enter Email"
                                        type="email"
                                        name="email"
                                        required
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    borderColor: "white", // White border
                                                },
                                                "&:hover fieldset": {
                                                    borderColor: "white", // White border on hover
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "white", // White border when focused
                                                },
                                            },
                                        }}
                                        value={login.email}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="mb-4 text-lg">
                                    <TextField
                                        onKeyPress={handleKeyPress}
                                        className="w-full"
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            className: "text-white rounded-3xl",
                                            style: {
                                                color: "white", // Set the text color to white
                                                "&::placeholder": {
                                                    color: "white", // Set the placeholder color to white
                                                },
                                            },
                                        }}
                                        required
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    borderColor: "white", // White border
                                                },
                                                "&:hover fieldset": {
                                                    borderColor: "white", // White border on hover
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "white", // White border when focused
                                                },
                                            },
                                        }}
                                        placeholder="Password"
                                        type="password"
                                        name="password"
                                        value={login.password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <FormControl
                                    sx={{
                                        width: "100%", // Set the same width as the textfields
                                        backgroundColor: "transparent", // Set background color to transparent
                                        borderRadius: "5px", // Set the same border radius as the textfields
                                        border: "1px solid white", // Add a white border
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none !important",
                                        },
                                    }}
                                    size="small"
                                >
                                    <Select
                                        sx={{
                                            border: "none",
                                            color: "white", // Set text color to white
                                            "&:focus": {
                                                backgroundColor: "transparent", // Set background color on focus to transparent
                                            },
                                            "& .MuiSelect-icon": {
                                                color: "white", // Set arrow color to white
                                            },
                                        }}
                                        value={login.entity}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{
                                            "aria-label": "Without label",
                                        }}
                                        name="entity"
                                    >
                                        <MenuItem value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {metaData?.result?.entities?.map(
                                            (entity) => (
                                                <MenuItem
                                                    key={entity.id}
                                                    value={entity.id}
                                                >
                                                    {entity.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>

                                <div className="flex flex-col items-center justify-center w-full mt-8 text-lg text-black">
                                    <button
                                        className="relative inline-flex items-center justify-start inline-block px-5 py-3 overflow-hidden font-bold rounded-full group w-[150px]"
                                        onClick={handleSubmit}
                                    >
                                        <span className="w-40 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]"></span>
                                        <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-white opacity-100 group-hover:-translate-x-8"></span>
                                        <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-gray-900">
                                            Submit
                                        </span>
                                        <span className="absolute inset-0 border-2 border-white rounded-full"></span>
                                    </button>

                                    <div className="w-full mt-7">
                                        <button
                                            onClick={handleForgetPassowrd}
                                            className="text-white duration-500 cursor-pointer hover:underline"
                                        >
                                            Forgot Password?
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}

                        {showPatientLoginbox && (
                            <div className="w-[50%]">
                                {loginStep === 1 && (
                                    <form
                                        className="flex flex-col items-center animate-slide-in-left"
                                        onSubmit={handleMobileNumberSubmit}
                                    >
                                        <div className="w-[200px] my-4 text-lg">
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    className:
                                                        "text-white rounded-3xl",
                                                    style: {
                                                        color: "white",
                                                    },
                                                    inputProps: {
                                                        inputMode: "numeric",
                                                        pattern: "[0-9]*", // Allow only numeric input
                                                        maxLength: 10,
                                                    },
                                                    onInvalid: () => {
                                                        showNotification(
                                                            "error",
                                                            "Please enter a valid number."
                                                        );
                                                    },
                                                    onInput: (e) => {
                                                        e.target.value =
                                                            e.target.value.replace(
                                                                /[^0-9]/g,
                                                                ""
                                                            ); // Remove non-numeric characters
                                                    },
                                                }}
                                                placeholder="Enter Mobile Number"
                                                type="tel"
                                                name="mobileNumber"
                                                required
                                                sx={{
                                                    "& .MuiOutlinedInput-root":
                                                        {
                                                            "& fieldset": {
                                                                borderColor:
                                                                    "white", // White border
                                                            },
                                                            "&:hover fieldset":
                                                                {
                                                                    borderColor:
                                                                        "white", // White border on hover
                                                                },
                                                            "&.Mui-focused fieldset":
                                                                {
                                                                    borderColor:
                                                                        "white", // White border when focused
                                                                },
                                                        },
                                                }}
                                                value={mobileNumber}
                                                // onChange={handlePtChange}
                                                onChange={(e) =>
                                                    handlePtChange(
                                                        e.target.value,
                                                        "mobileNumber"
                                                    )
                                                }
                                            />
                                        </div>

                                        <div className="flex flex-col items-center justify-center w-full mt-8 text-lg text-black">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="w-[150px]"
                                                disabled={isLogging}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </form>
                                )}

                                {loginStep === 2 && (
                                    <form
                                        className="flex flex-col items-center justify-center w-full animate-slide-in-left"
                                        onSubmit={handleOtpSubmit}
                                    >
                                        <div className="my-4 text-lg">
                                            <OtpInput
                                                value={otp}
                                                inputStyle={{
                                                    width: "4rem",
                                                    height: "4rem",
                                                    margin: "0 0.5rem",
                                                    fontSize: "1.5rem",
                                                    borderRadius: "8px",
                                                    border: "1px solid white",
                                                    color: "black",
                                                }}
                                                onChange={(value) =>
                                                    handlePtChange(value, "otp")
                                                }
                                                numInputs={4}
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => (
                                                    <input {...props} />
                                                )}
                                            />
                                        </div>

                                        <div className="flex flex-col items-center justify-center w-full mt-8 text-lg text-black">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="w-[150px]"
                                                disabled={isVerifying}
                                            >
                                                Verify
                                            </Button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginPage;
