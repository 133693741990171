import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
} from "@mui/material";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import dayjs from "dayjs";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const AlternatingRowTable = ({ data, columns }) => {
    return (
        <Paper
            elevation={3}
            sx={{
                // border: "1px solid gray",
                width: "100%",
                overflow: "hidden",
                borderRadius: "15px",
            }}
        >
            <TableContainer
                component={Paper}
                sx={{
                    height: 520,
                    overflowX: "scroll",
                    "&::-webkit-scrollbar": {
                        width: 0,
                    },
                }}
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead
                        sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            backgroundColor: "gray",
                            color: "white",
                        }}
                    >
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                        fontWeight: "bold",
                                        background: "#eeeeee",
                                        textTransform: "uppercase",
                                        backgroundColor: "#27272A",
                                        color: "white",
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow
                                key={index}
                                style={{
                                    backgroundColor:
                                        index % 2 === 0 ? "#E4E4E7" : "white",
                                }}
                            >
                                <TableCell align="center">
                                    {item.itemName}
                                </TableCell>
                                <TableCell align="center">
                                    {item.category}
                                </TableCell>
                                <TableCell align="center">
                                    {item.quantity}
                                </TableCell>
                                <TableCell align="center">
                                    {/* {dayjs(row.created_on).format("DD-MM-YYYY,h:mm:ss A")} */}
                                    {item.purchaseDate}
                                </TableCell>
                                <TableCell align="center">
                                    {item.price}
                                </TableCell>
                                <TableCell align="center">
                                    {item.details}
                                </TableCell>
                                <TableCell align="center">
                                    <div className="flex items-center justify-center space-x-4">
                                        <Tooltip
                                            title="Edit"
                                            placement="bottom-start"
                                        >
                                            <button className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500">
                                                <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                            </button>
                                        </Tooltip>

                                        <Tooltip
                                            title="Delete"
                                            placement="bottom-start"
                                        >
                                            <button className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500">
                                                <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default AlternatingRowTable;
