// Import necessary dependencies
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { getMots, addMot, updateMot, deleteMot, getMotbyID } from "../../../services/api"; // Assuming these functions are imported correctly
import { showNotification } from "../../../services/helper";


export function useGetMots(params = { offset: 0, limit: 1000, name: "", hid: "", startDate: "", endDate: "" }) {
    const { offset, limit, name, hid, startDate, endDate } = params;
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.mots, { offset, limit, name, hid, startDate, endDate }],
        queryFn: () => getMots({ offset, limit, name, hid, startDate, endDate }),
    });
    return { motsData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}
export function useGetMotbyiD(motID) {
    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.mot, motID],
        queryFn: () => getMotbyID(motID),
        enabled: motID ? true : false,
    });
    return { motServicesData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}

// Custom hook for adding a MOT
export function useAddMot(func) {
    const queryClient = useQueryClient();

    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: (motData) => addMot(motData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.minotServices] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.mots] });
            if (data?.success) {
                showNotification("success", data.message);
                func()
            } else {
                showNotification("error", data.message);

            }
        },
    });

    return { addMot: mutate, isLoading, isSuccess };
}

// Custom hook for editing a MOT
export function useEditMot(closeFunc) {
    const queryClient = useQueryClient();

    const { mutate, isLoading, isSuccess } = useMutation({
        mutationFn: (postData) => updateMot(postData.mot.id, postData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.mots] });
            if (data?.success) {
                closeFunc();
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);

            }
        },
    });

    return { editMot: mutate, isLoading, isSuccess };
}

// Custom hook for deleting a MOT
export function useDeleteMot() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (motId) => deleteMot(motId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.mots] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);

            }
        },
    });

    return { deleteMot: mutate, isLoading };
}
