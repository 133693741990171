import React, { useState } from "react";
import {
    Autocomplete,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";

import { useGetMOTServices } from "../../query/hooks/minotServices/minorOTServices";
import ReusableButton from "./ReusableButton";
import RedButton from "./RedButton";
import { useSelector } from "react-redux";
import { selectEmployeesParam } from "../../redux/features/filters/employeeSlice";
import { useGetEmployees } from "../../query/hooks/employee/employeeHooks";
const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-45%, -50%)",
    minWidth: 800,
    maxWidth: 1200,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};
const initialLab = {
    patient: 0,
    total_amount: 0,
    discount_amount: 0,
    discount_percent: 0,
    payable_amount: 0,
    payment_mode: "",
    payment_reference_number: "",
    tests: [],
};
const MinotOtModal = ({
    labTest,
    handleChange,
    handleChangeTests,
    handleClose,
    metaData,
    handleAdd,
    selectedDoctor,
    handleDoctorChange,
    handleDeptChange,
    inputDeptValue,
    handleDeptInputChange,
    selectedDept,
}) => {
    const limit = 1000;
    const [offset, setOffset] = useState(0);

    const employeeParams = useSelector(selectEmployeesParam);
    const { employeesData } = useGetEmployees(employeeParams);

    const { servicesData, isLoading: isMinorOtLoading } = useGetMOTServices();

    const filteredDoctors = employeesData?.result?.list?.filter(
        (employee) => employee.designation_name === "Doctor"
    );

    const isOptionEqualToValue = (option, value) => option.id === value.id;

    return (
        <div>
            <>
                <Typography variant="h5" className="text-center">
                    Add Minor OTs
                </Typography>

                <div className="grid grid-cols-2 gap-5">
                    <TextField
                        required
                        label="Patient"
                        name="patient"
                        value={labTest.patient}
                        disabled
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <div className="flex items-center justify-center">
                        <Autocomplete
                            fullWidth
                            multiple
                            options={servicesData?.result?.list || []}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) =>
                                handleChangeTests(newValue)
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Type to search test"
                                    fullWidth
                                />
                            )}
                        />
                    </div>
                    <TextField
                        required
                        label="Total Amount"
                        name="total_amount"
                        value={labTest.total_amount}
                        disabled
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Discount Amount"
                        name="discount_amount"
                        value={labTest.discount_amount}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Discount Percent"
                        name="discount_percent"
                        value={labTest.discount_percent}
                        disabled
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Payable Amount"
                        name="payable_amount"
                        value={labTest.payable_amount}
                        disabled
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <Autocomplete
                        margin="dense"
                        fullWidth
                        options={filteredDoctors}
                        disableClearable
                        getOptionLabel={(option) =>
                            option
                                ? option?.name.charAt(0).toUpperCase() +
                                  option?.name.slice(1)
                                : ""
                        }
                        value={selectedDoctor}
                        onChange={handleDoctorChange}
                        renderInput={(params) => (
                            <TextField {...params} label="Search Doctor" />
                        )}
                        isOptionEqualToValue={isOptionEqualToValue}
                    />

                    <Autocomplete
                        fullWidth
                        margin="dense"
                        options={metaData?.result?.departments || []}
                        getOptionLabel={(option) => option.name}
                        // value={selectedDept}
                        value={
                            metaData?.result?.departments.filter(
                                (dept) => dept.id === selectedDept
                            )[0] || null
                        }
                        onChange={handleDeptChange}
                        inputValue={inputDeptValue}
                        onInputChange={handleDeptInputChange}
                        disableClearable
                        isOptionEqualToValue={(option, value) => {
                            if (value) {
                                return option.id === value.id;
                            }

                            // option.id === value?.id;
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search Department"
                                variant="outlined"
                            />
                        )}
                    />
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel id="payment-mode-label">
                            Payment Mode
                        </InputLabel>
                        <Select
                            labelId="payment-mode-label"
                            label="Payment Mode"
                            name="payment_mode"
                            value={labTest.payment_mode || ""}
                            onChange={handleChange}
                            className="capitalize"
                        >
                            {metaData?.result?.payment_modes?.map((mode) => (
                                <MenuItem
                                    key={mode}
                                    value={mode}
                                    className="capitalize"
                                >
                                    {mode}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        label="Payment Reference Number"
                        name="payment_reference_number"
                        value={labTest.payment_reference_number}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                </div>
                <div className="flex py-4 justify-evenly">
                    <ReusableButton
                        title="Add"
                        onClick={handleAdd}
                        type="submit"
                    />

                    <RedButton title="Cancel" onClick={handleClose} />
                </div>
            </>
        </div>
    );
};

export default MinotOtModal;
