// ipdSlice.js

import { createSlice } from "@reduxjs/toolkit";

const ipdInitialState = {
    defaultParams: {
        name: "",
        hid: "",
        department: "",
        mobile: "",
        offset: 0,
        limit: 10,
        startDate: "",
        endDate: "",
        ptId: ""
    },
};

const ipdSlice = createSlice({
    name: "ipd",
    initialState: ipdInitialState,
    reducers: {
        setIPDParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },

        clearIPD: (state, action) => {
            state.defaultParams = ipdInitialState.defaultParams;

        },
    },
    extraReducers: {},
});

export const selectIPDParam = (state) => state.ipd.defaultParams;
export const { setIPDParams, clearIPD } = ipdSlice.actions;
export default ipdSlice.reducer;
