import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../keys";
import { showNotification } from "../../../services/helper";
import { addOTService, deleteOTService, getOTServices, updateOTService } from "../../../services/api";


export function useGetOTServices(params = {
    offset: 0,
    limit: 1000,
    ot_procedure: '',
    department: ''
}) {
    const { ot_procedure, department, offset, limit } = params;

    const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
        queryKey: [queryKeys.otServices, { offset, limit, ot_procedure, department }],
        queryFn: () => getOTServices({ offset, limit, ot_procedure, department }),
    });

    return { otServicesData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
}                            
// export function useGetOTServices(params = { offset: 0, limit: 1000, ot_procedure: '', department: "" }) {
//     const { offset, limit, ot_procedure, department } = params;
//     const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
//         queryKey: [queryKeys.otServices, { offset, limit, ot_procedure, department }],
//         queryFn: () => getOTServices({ offset, limit, ot_procedure, department }),
//     });

//     return { otServicesData: data, isLoading, isSuccess, isFetching, refetch, isRefetching };
// }

// export function useGetOTService(otServiceId) {
//     const { data, isLoading, isSuccess, isFetching, refetch, isRefetching } = useQuery({
//         queryKey: [`${queryKeys.otService}-${otServiceId}`],
//         queryFn: () => getOTService(otServiceId),
//         enabled: otServiceId ? true : false,
//     });

//     return {
//         otServiceData: data,
//         isLoading,
//         isSuccess,
//         isFetching,
//         refetch,
//         isRefetching,
//     };
// }

export function useAddOTService() {
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: (otServiceData) => addOTService(otServiceData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.otServices] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdOT] });
            if (data?.success) {
                showNotification("success", `OT Service added`);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });

    return { addOTService: mutate, isLoading };
}

export function useEditOTService() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ serviceId, otServiceData }) => updateOTService({ serviceId: serviceId, body: otServiceData }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.otServices] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdOT] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
            }
        },
    });
    return { editOTService: mutate, isLoading };
}

export function useDeleteOTService() {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: (otServiceId) => deleteOTService(otServiceId),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.otServices] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.ipdOT] });
            if (data?.success) {
                showNotification("success", data.message);
            } else {
                showNotification("error", data.message);
                console.error("Error:", data.message);
            }
        },
    });
    return { deleteOTService: mutate, isLoading };
}
