// expensesSlice.js

import { createSlice } from "@reduxjs/toolkit";

const expensesInitialState = {
    defaultParams: {
        offset: 0,
        limit: 10,
        startDate: "",
        endDate: "",
    },
};

const expensesSlice = createSlice({
    name: "expenses",
    initialState: expensesInitialState,
    reducers: {
        setExpensesParams: (state, action) => {
            state.defaultParams = { ...state.defaultParams, ...action.payload };
        },
        clearExpenses: (state, action) => {
            return expensesInitialState;
        },
    },
    extraReducers: {},
});

export const selectExpensesParams = (state) => state.expenses.defaultParams;
export const { setExpensesParams, clearExpenses } = expensesSlice.actions;
export default expensesSlice.reducer;
