import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { FaRegEye } from "react-icons/fa";
import {
    AiOutlineEdit,
    AiOutlineDelete,
    AiOutlineReload,
} from "react-icons/ai";
import {
    Backdrop,
    Box,
    Button,
    Fade,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import RedButton from "./common/RedButton";
import ReusableButton from "./common/ReusableButton";
import {
    useAddRoom,
    useDeleteRoom,
    useEditRoom,
    useGetRooms,
} from "../query/hooks/ipd/rooms";
import { getPermissionsByModule, showNotification } from "../services/helper";
import { useGetFloors } from "../query/hooks/ipd/floors";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import {
    selectRoomParam,
    setRoomParams,
} from "../redux/features/filters/roomSlice";

const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

const columns = [
    // {
    //     id: "id",
    //     label: "ID",
    //     align: "center",
    //     paddingX: 2,
    //     width: 120,
    //     maxWidth: "none",
    // },
    {
        id: "rooms",
        label: "Rooms",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },
    {
        id: "floor",
        label: "Floor",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },
    {
        id: "bed_charge",
        label: "Bed Charge",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },
    {
        id: "nurse_charge",
        label: "Nurse Charge",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },
    {
        id: "actions",
        label: "Actions",
        align: "center",
        paddingX: 2,
        minWidth: 120,

        maxWidth: "none",
    },
];
const Room = () => {
    const dispatch = useDispatch();
    const currentAuth = useSelector(selectCurrentAuth);
    const roomParams = useSelector(selectRoomParam);
    const permissionsList = currentAuth?.user?.permissions;
    const roomsPermissions = getPermissionsByModule(1014, permissionsList);
    const {
        add_permission,
        update_permission,
        delete_permission,
        view_permission,
    } = roomsPermissions;

    const intState = {
        name: "",
        floor: 0,
        nurse_charge: 0,
        bed_charge: 0,
    };
    const {
        refetch,
        roomsData,
        isFetching: isFetchingRooms,
        isLoading: isLoadingRooms,
        isRefetching: isRefetchingRooms,
    } = useGetRooms(roomParams);
    const { floorsData, isFetching } = useGetFloors();
    const { deleteRoom, isLoading: isDeletingRoom } = useDeleteRoom();
    const { editRoom, isLoading: isUpdatingRoom } = useEditRoom();
    const { addRoom, isLoading: isAddingRoom } = useAddRoom();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [formData, setFormData] = useState({
        intState,
    });
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editRoomData, setEditRoomData] = useState(null);
    const [toDelete, setToDelete] = useState("");

    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData(intState);
        setEditRoomData(null);
    };

    const handleDeleteConfirmed = () => {
        deleteRoom(toDelete);
        handleCloseDeleteDialog();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const validateFormData = (formData) => {
        const errors = {};

        // Check if name is empty
        if (!formData.name || formData.name.trim() === "") {
            errors.name = "Name is required";
        }

        // Check if floor is NaN or empty
        if (
            isNaN(formData.floor) ||
            formData.floor === null ||
            formData.floor === ""
        ) {
            errors.floor = "Floor must be a number";
        }

        // Check if nurse_charge is NaN or empty
        if (
            isNaN(formData.nurse_charge) ||
            formData.nurse_charge === null ||
            formData.nurse_charge === ""
        ) {
            errors.nurse_charge = "Nurse charge must be a number";
        }

        // Check if bed_charge is NaN or empty
        if (
            isNaN(formData.bed_charge) ||
            formData.bed_charge === null ||
            formData.bed_charge === ""
        ) {
            errors.bed_charge = "Bed charge must be a number";
        }

        return errors;
    };
    const handleAddRoom = () => {
        const updatedRoom = {
            name: formData.name,
            floor: parseInt(formData.floor),
            nurse_charge: parseInt(formData.nurse_charge),
            bed_charge: parseInt(formData.bed_charge),
        };
        const errors = validateFormData(updatedRoom);
        if (Object.keys(errors).length === 0) {
            addRoom(updatedRoom);
            handleClose();
        } else {
            // Handle errors, maybe display them to the user
            showNotification(
                "error",
                "Check the data you have entered",
                errors
            );
        }
    };
    const handleEditClick = (row) => {
        setEditRoomData(row);
        setOpen(true);
        setEditOpen(true);
    };

    const handleUpdateRoom = () => {
        const updatedRoom = {
            id: editRoomData.id,
            name: editRoomData.name,
            floor: parseInt(editRoomData.floor),
            nurse_charge: parseInt(editRoomData.nurse_charge),
            bed_charge: parseInt(editRoomData.bed_charge),
        };
        const errors = validateFormData(updatedRoom);
        if (Object.keys(errors).length === 0) {
            editRoom(updatedRoom);
            handleClose();
        } else {
            // Handle errors, maybe display them to the user
            showNotification(
                "error",
                "Check the data you have entered",
                errors
            );
        }
    };
    const [currentPage, setCurrentPage] = useState(1);

    const handlePaginationChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setRoomParams({
                ...roomParams,
                offset: (value - 1) * roomParams.limit,
            })
        );
    };

    return (
        <section>
            <div className="my-4">
                <div className="flex items-center justify-between space-x-3">
                    <div className="flex items-center space-x-3">
                        <Typography variant="h5">Rooms</Typography>

                        <Tooltip title="Refresh" placement="bottom-start">
                            <button
                                onClick={refetch}
                                className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                style={{ cursor: "pointer" }}
                            >
                                <AiOutlineReload size={20} />
                            </button>
                        </Tooltip>
                    </div>
                    {add_permission === 1 && (
                        <ReusableButton onClick={handleOpen} title="Add Room" />
                    )}
                </div>
            </div>

            <Paper
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: "15px",
                }}
                elevation={24}
            >
                <TableContainer
                    sx={{
                        height: 520,
                        overflowX: "scroll",
                        "&::-webkit-scrollbar": {
                            width: 0,
                        },
                    }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            paddingX: column.paddingX,
                                        }}
                                        style={{
                                            minWidth: column.minWidth,
                                            width: column.width,
                                            maxWidth: column.maxWidth,
                                            fontWeight: "bold",
                                            background: "#eeeeee",
                                            textTransform: "uppercase",
                                            backgroundColor: "#27272A",
                                            color: "white",
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {roomsData?.result?.list?.map((item, index) => (
                                <TableRow
                                    key={item.id}
                                    style={{
                                        backgroundColor:
                                            index % 2 === 0
                                                ? "#E4E4E7"
                                                : "white",
                                    }}
                                >
                                    <TableCell align="center">
                                        {item.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.floor}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.bed_charge}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.nurse_charge}
                                    </TableCell>

                                    <TableCell align="right">
                                        <div className="flex items-center justify-center space-x-4">
                                            {update_permission === 1 && (
                                                <Tooltip
                                                    title="Edit"
                                                    placement="bottom-start"
                                                >
                                                    <button
                                                        className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                        onClick={() =>
                                                            handleEditClick(
                                                                item
                                                            )
                                                        }
                                                    >
                                                        <AiOutlineEdit className="text-xl text-blue-500 rounded-md group-hover:text-white" />
                                                    </button>
                                                </Tooltip>
                                            )}
                                            {delete_permission && (
                                                <Tooltip
                                                    title="Delete"
                                                    placement="bottom-start"
                                                >
                                                    <button
                                                        className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                        onClick={() =>
                                                            handleOpenDeleteDialog(
                                                                item.id
                                                            )
                                                        }
                                                    >
                                                        <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                    </button>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="flex items-center justify-center my-4">
                    <Pagination
                        count={Math.ceil(
                            roomsData?.result?.count / roomParams.limit
                        ) || 0}
                        page={currentPage}
                        sx={{
                            "& .MuiSelect-select": {
                                display: "none !important",
                            },
                            "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                {
                                    display: "none !important",
                                },
                        }}
                        onChange={handlePaginationChange}
                    />
                </div>
            </Paper>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {editRoomData ? (
                            <div>
                                <Typography
                                    id="transition-modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                >
                                    Update Room
                                </Typography>
                                <section className="mb-5 space-y-5">
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Room Name"
                                            name="name"
                                            value={editRoomData.name}
                                            onChange={(e) =>
                                                setEditRoomData({
                                                    ...editRoomData,
                                                    name: e.target.value,
                                                })
                                            }
                                            fullWidth
                                            margin="normal"
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>Floor</InputLabel>
                                        <Select
                                            name="floor"
                                            label="Floor"
                                            value={editRoomData.floor}
                                            onChange={(e) =>
                                                setEditRoomData({
                                                    ...editRoomData,
                                                    floor: e.target.value,
                                                })
                                            }
                                            fullWidth
                                        >
                                            {floorsData?.result?.list?.map(
                                                (floor) => (
                                                    <MenuItem
                                                        key={floor.id}
                                                        value={floor.id}
                                                    >
                                                        {floor.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        label="Nurse Charge"
                                        name="nurse_charge"
                                        value={editRoomData.nurse_charge}
                                        onChange={(e) =>
                                            setEditRoomData({
                                                ...editRoomData,
                                                nurse_charge: e.target.value,
                                            })
                                        }
                                        fullWidth
                                        margin="normal"
                                        inputProps={{
                                            inputMode: "numeric",
                                            pattern: "[0-9]*", // Allow only numeric input
                                            maxLength: 10,
                                        }}
                                        onInvalid={(e) => {
                                            e.preventDefault(); // Prevent the default validation message
                                            showNotification(
                                                "error",
                                                "Please enter a valid number."
                                            );
                                        }}
                                        onInput={(e) => {
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                ); // Remove non-numeric characters
                                        }}
                                    />

                                    <TextField
                                        label="Bed Charge"
                                        name="bed_charge"
                                        value={editRoomData.bed_charge}
                                        onChange={(e) =>
                                            setEditRoomData({
                                                ...editRoomData,
                                                bed_charge: e.target.value,
                                            })
                                        }
                                        fullWidth
                                        margin="normal"
                                        inputProps={{
                                            inputMode: "numeric",
                                            pattern: "[0-9]*", // Allow only numeric input
                                            maxLength: 10,
                                        }}
                                        onInvalid={(e) => {
                                            e.preventDefault(); // Prevent the default validation message
                                            showNotification(
                                                "error",
                                                "Please enter a valid number."
                                            );
                                        }}
                                        onInput={(e) => {
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                ); // Remove non-numeric characters
                                        }}
                                    />
                                </section>
                                <div className="flex items-center justify-evenly">
                                    <ReusableButton
                                        onClick={handleUpdateRoom}
                                        title="Add"
                                    />
                                    <RedButton
                                        onClick={handleClose}
                                        title="Cancel"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Typography
                                    id="transition-modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                >
                                    Add Room
                                </Typography>
                                <section className="mb-5 space-y-5">
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={isAddingRoom}
                                            label="Room Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>Floor</InputLabel>
                                        <Select
                                            disabled={isAddingRoom}
                                            name="floor"
                                            label="Floor"
                                            value={formData.floor}
                                            onChange={handleChange}
                                            fullWidth
                                        >
                                            {floorsData?.result?.list?.map(
                                                (floor) => (
                                                    <MenuItem
                                                        key={floor.id}
                                                        value={floor.id}
                                                    >
                                                        {floor.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        label="Nurse Charge"
                                        name="nurse_charge"
                                        disabled={isAddingRoom}
                                        value={formData.nurse_charge}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        inputProps={{
                                            inputMode: "numeric",
                                            pattern: "[0-9]*", // Allow only numeric input
                                            maxLength: 10,
                                        }}
                                        onInvalid={(e) => {
                                            e.preventDefault(); // Prevent the default validation message
                                            showNotification(
                                                "error",
                                                "Please enter a valid number."
                                            );
                                        }}
                                        onInput={(e) => {
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                ); // Remove non-numeric characters
                                        }}
                                    />

                                    <TextField
                                        label="Bed Charge"
                                        name="bed_charge"
                                        disabled={isAddingRoom}
                                        value={formData.bed_charge}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        inputProps={{
                                            inputMode: "numeric",
                                            pattern: "[0-9]*", // Allow only numeric input
                                            maxLength: 10,
                                        }}
                                        onInvalid={(e) => {
                                            e.preventDefault(); // Prevent the default validation message
                                            showNotification(
                                                "error",
                                                "Please enter a valid number."
                                            );
                                        }}
                                        onInput={(e) => {
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                ); // Remove non-numeric characters
                                        }}
                                    />
                                </section>
                                <div className="flex items-center justify-evenly">
                                    <ReusableButton
                                        onClick={handleAddRoom}
                                        title="Add"
                                        isDisabled={isAddingRoom}
                                    />
                                    <RedButton
                                        onClick={handleClose}
                                        title="Cancel"
                                        isDisabled={isAddingRoom}
                                    />
                                </div>
                            </div>
                        )}
                    </Box>
                </Fade>
            </Modal>
            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeletingRoom}
            />
        </section>
    );
};

export default Room;
