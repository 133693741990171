import {
    Backdrop,
    Box,
    Fade,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { AiOutlineReload } from "react-icons/ai";
import { RiFileHistoryLine } from "react-icons/ri";
import {
    useDeletePatient,
    useEditPatient,
    useGetPatients,
} from "../query/hooks/patients/patientsHook";
import {
    getEndingTimeOfDate,
    getStartingTimeOfDate,
    showNotification,
    validateAddOPD,
} from "../services/helper";
import { useGetMetaData } from "../query/hooks/metadata/metadataHook";
import { useGetEmployees } from "../query/hooks/employee/employeeHooks";
import { useAddOPD } from "../query/hooks/opd/opdHook";
import { useAddLabTest } from "../query/hooks/labtest/labtestHook";
import LoaderLogo from "./common/LoaderLogo";
import { FaBookMedical } from "react-icons/fa";
import { GiMedicalThermometer } from "react-icons/gi";
import CommonDeleteModal from "./common/CommonDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuth } from "../redux/features/auth/authSlice";
import { BiSearchAlt } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { selectEmployeesParam } from "../redux/features/filters/employeeSlice";
import {
    clearPatientFilter,
    selectPatientFilterParams,
    setPatientFilterParams,
} from "../redux/features/filters/patientFilterSlice";
import ReusableButton from "./common/ReusableButton";
import ClearButton from "./common/ClearButton";
import LabTestModal from "./LabTestModal";
import { useNavigate } from "react-router-dom";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const columns = [
    { id: "hid", label: "HID", width: 200, align: "left", paddingX: 5 },
    {
        id: "name",
        label: "Name",
        width: 200,
        align: "left",
    },
    { id: "mobile", label: "Mobile", width: 80, align: "left" },
    { id: "email", label: "Email", width: 80, align: "left" },
    {
        id: "aadhaar",
        label: "Aadhaar",
        minWidth: 150,
        width: 100,
        align: "left",
    },
    { id: "actions", label: "Actions", align: "center", width: 220 },
];
const initialPatientState = {
    patient: 0,
    department: "",
    doctor: "",
    charge: 0.0,
    payable_amount: 0.0,
    payment_mode: "",
    payment_reference_number: "",
    discount_amount: 0.0,
    discount_percent: 0.0,
    discount_remark: "",
};
const paymentModes = ["cash", "card"];

const initialData = {
    aadhaar: "",
    title: "",
    name: "",
    mobile: "",
    email: "",
    age: 0,
    months: 0,
    days: 0,
    gender: "",
    marital_status: "",
    care_of: "",
    relationship: "",
    occupation: "",
    address: "",
    district: "",
    pincode: "",
    remarks: "",
};
const initialLab = {
    patient: 0,
    total_amount: 0,
    discount_amount: 0,
    discount_percent: 0,
    payable_amount: 0,
    payment_mode: "",
    payment_reference_number: "",
    tests: [],
};
const Patients = () => {
    const navigate = useNavigate();
    const patientParams = useSelector(selectPatientFilterParams);

    const currentAuth = useSelector(selectCurrentAuth);
    const dispatch = useDispatch();
    const patientsPermission = currentAuth?.user?.permissions.find(
        (permission) => permission.module === 1009
    );
    const { add_permission, view_permission, delete_permission } =
        patientsPermission;

    const { metaData } = useGetMetaData();

    const [patient, setPatient] = useState({ ...initialData });
    const employeeParams = useSelector(selectEmployeesParam);
    const { employeesData } = useGetEmployees(employeeParams);
    const { patientsData, isLoading, isFetching, isRefetching, refetch } =
        useGetPatients(patientParams); // Use the custom hook to fetch roles data
    // Use the custom hook for adding a role
    const { mutate: editPatient, isLoading: isEditingPatient } =
        useEditPatient(); // Use the custom hook for editing a role
    const { deletePatient, isLoading: isDeletingPatient } = useDeletePatient(); // Use the custom hook for deleting a role
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [editOpen, setEditOpen] = useState(false);
    const [editedPatient, setEditedPatient] = useState("");
    const [toDelete, setToDelete] = useState("");
    const [open, setOpen] = useState(false);
    const rowsPerPageOptions = [10, 25, 100];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const initialPatientId = { aadhar: "", hid: "" };
    const [patientId, setPatientId] = useState(initialPatientId);
    const [isAadharDisabled, setIsAadharDisabled] = useState(false);
    const [isHidDisabled, setIsHidDisabled] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleEditClick = (row) => {
        setEditedPatient(row);
        setEditOpen(true);
        setEditOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteConfirmed = () => {
        deletePatient(toDelete);
        handleCloseDeleteDialog();
    };

    const handleClear = () => {
        setPatientId(initialPatientId);
        setIsAadharDisabled(false);
        setIsHidDisabled(false);
    };

    const { addOPD, isLoading: isAddingOPD } = useAddOPD();

    const [patientState, setPatientState] = useState({
        ...initialPatientState,
    });
    useEffect(() => {
        const total = parseFloat(patientState.charge);
        const discount = parseFloat(patientState.discount_amount);

        // Check if discount amount is greater than the total charge
        if (!isNaN(total) && !isNaN(discount) && discount <= total) {
            let payable = total - discount;

            setPatientState((prevLabTest) => ({
                ...prevLabTest,
                payable_amount: payable,
            }));
        } else {
            showNotification(
                "error",
                "Discount Amount cannot be greater than Charge"
            );
            setPatientState((prevLabTest) => ({
                ...prevLabTest,
                payable_amount: 0,
                discount_amount: 0,
            }));
        }
    }, [patientState.discount_amount, patientState.charge]);

    useEffect(() => {
        // Calculate discount percentage based on total charge and discount amount
        const total = parseFloat(patientState.charge);
        const discount = parseFloat(patientState.discount_amount);

        if (!isNaN(total) && !isNaN(discount) && total > 0) {
            const discountPercent = (discount / total) * 100;

            // Update the state with the calculated discount percentage
            setPatientState((prevLabTest) => ({
                ...prevLabTest,
                discount_percent: discountPercent.toFixed(2),
            }));
        } else {
            // If invalid input, reset the discount percentage to 0
            setPatientState((prevLabTest) => ({
                ...prevLabTest,
                discount_percent: 0,
            }));
        }
    }, [patientState.discount_amount, patientState.charge]);

    const [openOPD, setOpenOPD] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState({});
    const [valErr, setValErr] = useState([]);

    const handleOpenOPD = (row) => {
        setSelectedPatient(row);
        setPatientState((prevState) => ({
            ...prevState,
            patient: row.id,
        }));
        setOpenOPD(true);
    };

    const handleCloseOPD = () => {
        setOpenOPD(false);
        setPatientState({ ...initialPatientState });
        setValErr([]);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === "doctor") {
            setPatientState((prevState) => ({
                ...prevState,
                doctor: value.id,
                department: value.department,
            }));
        } else {
            const numericValue =
                name === "charge" ||
                name === "payable_amount" ||
                name === "discount_amount" ||
                name === "discount_percent"
                    ? parseFloat(value.replace(/[^\d.]/g, ""))
                    : value;

            // Update state with new values
            setPatientState((prevState) => ({
                ...prevState,
                [name]: numericValue,
            }));

            // Calculate discount and payable amount if necessary
        }
    };

    const handleOPDSubmit = (event) => {
        event.preventDefault();

        const editedOPDS = {
            // id: parseInt(patientState.id),
            patient: parseInt(patientState.patient),
            department: parseInt(patientState.department),
            doctor: parseInt(patientState.doctor),
            charge: parseFloat(patientState.charge),
            payable_amount: parseFloat(patientState.payable_amount),
            payment_mode: patientState.payment_mode,
            payment_reference_number: patientState.payment_reference_number,
            discount_amount: parseFloat(patientState.discount_amount),
            discount_percent: parseFloat(patientState.discount_percent),
            discount_remark: patientState.discount_remark,
        };
        event.preventDefault();
        const { emptyFields, zeroValueFields } = validateAddOPD(editedOPDS);
        setValErr({ emptyFields, zeroValueFields });

        // If there are empty fields or fields with zero values, display error messages
        if (emptyFields.length > 0 || zeroValueFields.length > 0) {
            // Handle empty fields or zero value fields, e.g., display error messages to the user
            showNotification("error", "Fill All Fields Before Submitting");
        } else {
            // No empty fields or zero value fields, proceed with adding the OPD record
            addOPD(editedOPDS);
            handleCloseOPD();
        }
    };

    const filteredDoctors = employeesData?.result?.list?.filter(
        (employee) => employee.designation_name === "Doctor"
    );

    const [labTest, setLabTest] = useState({ ...initialLab });
    const [openLab, setOpenLab] = useState(false);

    useEffect(() => {
        let total = 0;
        for (let item of labTest.tests) {
            total += isNaN(parseFloat(item.charge))
                ? 0
                : parseFloat(item.charge);
        }

        const newTotalAmount = isNaN(total) ? 0 : parseFloat(total);
        const newPayableAmount = isNaN(total - labTest.discount_amount)
            ? 0
            : parseFloat(total - labTest.discount_amount);

        setLabTest((prevLabTest) => ({
            ...prevLabTest,
            total_amount: newTotalAmount,
            payable_amount: newPayableAmount,
        }));
    }, [labTest.tests]);

    useEffect(() => {
        const discountAmount = parseFloat(labTest.discount_amount);
        const totalAmount = parseFloat(labTest.total_amount);

        const discountPercent =
            !isNaN(discountAmount) && !isNaN(totalAmount)
                ? parseFloat((discountAmount / totalAmount) * 100).toFixed(2)
                : 0;

        const payableAmount =
            !isNaN(totalAmount) && !isNaN(discountAmount)
                ? parseFloat(totalAmount - discountAmount)
                : 0;

        setLabTest({
            ...labTest,
            discount_percent: discountPercent,
            payable_amount: payableAmount,
        });
    }, [labTest.discount_amount]);

    useEffect(() => {
        let total = 0;
        if (labTest.tests.length > 0) {
            for (let item of labTest.tests) {
                if (!isNaN(item.charge)) {
                    total += parseFloat(item.charge);
                }
            }
        }

        let discountAmount =
            labTest.tests.length > 0 &&
            !isNaN(parseFloat(labTest.discount_amount))
                ? parseFloat(labTest.discount_amount)
                : 0;

        let discountPercent =
            labTest.tests.length > 0 && total !== 0
                ? (discountAmount / total) * 100
                : 0;

        let payableAmount =
            labTest.tests.length > 0 && !isNaN(total) && !isNaN(discountAmount)
                ? total - discountAmount
                : 0;

        setLabTest((prevLabTest) => ({
            ...prevLabTest,
            total_amount: total,
            discount_amount: discountAmount,
            discount_percent: parseFloat(discountPercent.toFixed(2)),
            payable_amount: parseFloat(payableAmount.toFixed(2)),
        }));
    }, [labTest.tests, labTest.discount_amount]);

    const handleChangeLab = (e) => {
        const { name, value } = e.target;
        if (name === "tests" && labTest.total_amount === 0) {
            setLabTest((prevLabTest) => ({
                ...prevLabTest,
                discount_amount: 0,
                discount_percent: 0,
                payable_amount: 0,
                [name]: value,
            }));
        } else {
            setLabTest((prevLabTest) => ({
                ...prevLabTest,
                [name]: value,
            }));
        }
    };

    const handleChangeTests = (arr) => {
        const obj = { ...labTest };
        obj.tests = arr;
        setLabTest(obj);
    };

    const handleOpenLab = (row) => {
        setOpenLab(true);
        setLabTest((prevLabTest) => ({
            ...prevLabTest,
            patient: row.id,
        }));
    };

    const handleCloseLab = () => {
        setOpenLab(false);
        setLabTest({ ...initialLab });
    };
    const handlePTClose = () => {
        setLabTest({ ...initialLab });
        handleClear();
        handleCloseLab();
    };
    const {
        addLabTest,
        isLoading: isAddingLabTest,
        isSuccess,
    } = useAddLabTest(handlePTClose);

    const handleAddLabTest = (e) => {
        e.preventDefault();

        const requiredFields = [
            "patient",
            "total_amount",
            "discount_amount",
            "discount_percent",
            "payable_amount",
            "payment_mode",
        ];

        const missingFields = requiredFields.filter((field) => !labTest[field]);

        if (missingFields.length > 0 || labTest.tests.length === 0) {
            const errorMessage =
                missingFields.length > 0
                    ? `${missingFields
                          .map(
                              (field) =>
                                  field.charAt(0).toUpperCase() +
                                  field.slice(1).replace(/_/g, " ")
                          )
                          .join(", ")} field${
                          missingFields.length > 1 ? "s" : ""
                      } is required`
                    : "At least one test is required";

            showNotification("error", errorMessage);
            return;
        }

        const areTestsValid = labTest.tests.every(
            (test) => test.id && test.charge
        );

        if (!areTestsValid) {
            showNotification("error", "Invalid test data");
            return;
        }

        const parsedState = {
            investigation: {
                patient: parseInt(labTest.patient),
                total_amount:
                    labTest.tests.length > 0
                        ? parseFloat(labTest.total_amount)
                        : 0,
                discount_amount:
                    labTest.tests.length > 0
                        ? parseFloat(labTest.discount_amount)
                        : 0,
                discount_percent: parseFloat(labTest.discount_percent),
                payable_amount: parseFloat(labTest.payable_amount),
                payment_mode: labTest.payment_mode,
                payment_reference_number: labTest.payment_reference_number,
            },
            tests: labTest.tests.map((test) => ({
                test: parseInt(test.id),
                charge: parseFloat(test.charge),
            })),
        };

        addLabTest(parsedState);
        if (isSuccess) {
            setLabTest({ ...initialLab });
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState({
        name: "",
        hid: "",
        mobile: "",
        aadhaar: "",
    });
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Check if startDate is a valid date
        const isStartDateValid =
            startDate instanceof Date && !isNaN(startDate.getTime());

        // Check if endDate is a valid date
        const isEndDateValid =
            endDate instanceof Date && !isNaN(endDate.getTime());

        dispatch(
            setPatientFilterParams({
                ...patientParams,
                name: searchParams.name || "",
                hid: searchParams.hid || "",
                mobile: searchParams.mobile || "",
                aadhaar: searchParams.aadhaar || "",

                // startDate: isStartDateValid
                //     ? getStartingTimeOfDate(dayjs(startDate))
                //     : "",
                // endDate: isEndDateValid
                //     ? getEndingTimeOfDate(dayjs(endDate))
                //     : "",
            })
        );
    };
    const handleClearSearch = () => {
        dispatch(clearPatientFilter());
        setStartDate();
        setEndDate();
        setSearchParams({
            name: "",
            hid: "",
            mobile: "",
            aadhaar: "",
        });
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        dispatch(
            setPatientFilterParams({
                ...patientParams,
                offset: (value - 1) * patientParams.limit,
            })
        );
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSubmit(event);
        }
    };
    const handleKeyPressClear = (event) => {
        if (event.key === "Escape") {
            handleClearSearch();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", handleKeyPressClear);
        return () => {
            document.removeEventListener("keydown", handleKeyPressClear);
        };
    }, []);

    const handleChangeTests2 = (arr) => {
        const obj = { ...labTest };
        obj.tests = arr;
        setLabTest(obj);
    };

    const handleChangeLab2 = (e) => {
        const { name, value } = e.target;
        if (name === "tests" && labTest.total_amount === 0) {
            setLabTest((prevLabTest) => ({
                ...prevLabTest,
                discount_amount: 0,
                discount_percent: 0,
                payable_amount: 0,
                [name]: value,
            }));
        } else {
            setLabTest((prevLabTest) => ({
                ...prevLabTest,
                [name]: value,
            }));
        }
    };
    const [ipdID, setIpdID] = useState("");
    const handleGetIPDByID = (row) => {
        setIpdID(row.id);
        navigate(`/admin/patients/${row.id}`);
    };
    return (
        <>
            <div className="flex items-center justify-between px-10 my-5">
                <div>
                    <div className="flex items-center space-x-3">
                        <Typography variant="h5">Patients</Typography>
                        {add_permission === 1 && (
                            <Tooltip title="Refresh" placement="bottom-start">
                                <button
                                    onClick={refetch}
                                    className="flex items-center px-2 py-2 font-semibold transition-all duration-300 rounded-lg hover:text-white hover:bg-black focus:outline-none"
                                    style={{ cursor: "pointer" }}
                                >
                                    <AiOutlineReload size={20} />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
            <div className="px-4 my-4">
                <form
                    className="flex items-center space-x-8"
                    style={{ position: "relative", zIndex: "999" }}
                    onKeyPress={handleKeyPress}
                    onSubmit={handleSubmit}
                >
                    <TextField
                        label="Name"
                        variant="outlined"
                        name="name"
                        value={searchParams.name}
                        onChange={handleSearch}
                    />
                    <TextField
                        label="HID"
                        variant="outlined"
                        name="hid"
                        value={searchParams.hid}
                        onChange={handleSearch}
                    />
                    <TextField
                        label="Mobile"
                        variant="outlined"
                        name="mobile"
                        value={searchParams.mobile}
                        onChange={handleSearch}
                        inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*", // Allow only numeric input
                            maxLength: 10,
                        }}
                        onInvalid={(e) => {
                            e.preventDefault(); // Prevent the default validation message
                            showNotification(
                                "error",
                                "Please enter a valid Phone Number."
                            );
                        }}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                            ); // Remove non-numeric characters
                        }}
                    />
                    <TextField
                        label="Aadhaar"
                        variant="outlined"
                        name="aadhaar"
                        value={searchParams.aadhaar}
                        onChange={handleSearch}
                        inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*", // Allow only numeric input
                            maxLength: 12,
                        }}
                        onInvalid={(e) => {
                            e.preventDefault(); // Prevent the default validation message
                            showNotification(
                                "error",
                                "Please enter a valid Aadhaar."
                            );
                        }}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                            ); // Remove non-numeric characters
                        }}
                    />

                    <ReusableButton
                        title="Search"
                        onClick={handleSubmit}
                        type="submit"
                    />

                    <ClearButton title="Clear" onClick={handleClearSearch} />
                </form>
            </div>
            <section className="space-y-3">
                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "15px",
                    }}
                    elevation={24}
                >
                    <TableContainer
                        sx={{
                            height: 520,
                            overflowX: "scroll",
                            "&::-webkit-scrollbar": {
                                width: 0,
                            },
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            sx={{
                                                paddingX: column.paddingX,
                                            }}
                                            style={{
                                                minWidth: column.minWidth,
                                                width: column.width,
                                                maxWidth: column.maxWidth,
                                                fontWeight: "bold",
                                                background: "#eeeeee",
                                                textTransform: "uppercase",
                                                backgroundColor: "#27272A",
                                                color: "white",
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {!isLoading && !isFetching && !isRefetching ? (
                                <TableBody>
                                    {patientsData?.result?.list?.map((row) => (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            key={row.id}
                                            align="left"
                                        >
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    paddingX: 5,
                                                }}
                                            >
                                                {row.hid}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.mobile}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.email}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                style={{ maxWidth: 100 }}
                                                // sx={{
                                                //     width: "60px", // Adjust the width as needed
                                                //     overflow: "hidden",
                                                //     textOverflow:
                                                //         "ellipsis",
                                                //     whiteSpace: "nowrap",
                                                // }}
                                            >
                                                {row.aadhaar}
                                            </TableCell>

                                            <TableCell align="center">
                                                {/* <div className="flex items-center justify-center space-x-4">
                                                    {add_permission === 1 && (
                                                        <Tooltip
                                                            title="Add OPD"
                                                            placement="bottom-start"
                                                        >
                                                            <button
                                                                onClick={() =>
                                                                    handleOpenOPD(
                                                                        row
                                                                    )
                                                                }
                                                                className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                            >
                                                                <FaBookMedical className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                            </button>
                                                        </Tooltip>
                                                    )}
                                                    {add_permission === 1 && (
                                                        <Tooltip
                                                            title="Add Test"
                                                            placement="bottom-start"
                                                        >
                                                            <button
                                                                onClick={() =>
                                                                    handleOpenLab(
                                                                        row
                                                                    )
                                                                }
                                                                className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                            >
                                                                <GiMedicalThermometer className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                            </button>
                                                        </Tooltip>
                                                    )}
                                                </div> */}
                                                <div>
                                                    {view_permission === 1 && (
                                                        <Tooltip
                                                            title="View History"
                                                            placement="bottom-start"
                                                        >
                                                            <button
                                                                onClick={() =>
                                                                    handleGetIPDByID(
                                                                        row
                                                                    )
                                                                }
                                                                className="px-3 py-2 duration-300 rounded-lg group hover:bg-blue-500"
                                                            >
                                                                <RiFileHistoryLine className="text-xl text-blue-500 rounded-md group-hover:text-white " />
                                                            </button>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            colSpan={6}
                                            align="center"
                                            sx={{
                                                width: 120,
                                            }}
                                        >
                                            <div className="flex items-center justify-center">
                                                <LoaderLogo />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <div className="flex items-center justify-center my-4">
                        <Pagination
                            count={
                                Math.ceil(
                                    patientsData?.result?.count /
                                        patientParams.limit
                                ) || 0
                            }
                            page={currentPage}
                            onChange={handlePageChange}
                            sx={{
                                "& .MuiSelect-select": {
                                    display: "none !important",
                                },
                                "& > div.MuiToolbar-root > div.MuiInputBase-root > svg":
                                    {
                                        display: "none !important",
                                    },
                            }}
                        />
                    </div>
                </Paper>
            </section>

            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeletingPatient}
            />
            <Modal
                open={openOPD}
                onClose={handleCloseOPD}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openOPD}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            minWidth: 600,
                            maxWidth: 800,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                            borderRadius: "5px",
                        }}
                    >
                        <div>
                            <Typography variant="h5" className="text-center">
                                Add OPD
                            </Typography>
                            <FormControl fullWidth>
                                <div className="grid grid-cols-2 gap-5 p-2">
                                    <TextField
                                        required
                                        label="Patient ID"
                                        name="patient"
                                        value={patientState.patient}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                        disabled
                                    />

                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        error={valErr?.emptyFields?.includes(
                                            "doctor"
                                        )}
                                    >
                                        <InputLabel id="payment-mode-label">
                                            Doctor
                                        </InputLabel>
                                        <Select
                                            label="Doctor"
                                            id="doctor"
                                            name="doctor"
                                            value={
                                                filteredDoctors?.filter(
                                                    (doctor) =>
                                                        doctor.id ===
                                                        patientState.doctor
                                                )[0]
                                            }
                                            onChange={handleInputChange}
                                        >
                                            {filteredDoctors?.map((doctor) => (
                                                <MenuItem
                                                    key={doctor.id}
                                                    value={doctor}
                                                    className="capitalize"
                                                >
                                                    {doctor.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            {valErr?.emptyFields?.includes(
                                                "doctor"
                                            )
                                                ? "Doctor is required"
                                                : undefined}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        margin="dense"
                                        error={valErr?.emptyFields?.includes(
                                            "department"
                                        )}
                                    >
                                        <InputLabel id="department-label">
                                            Department
                                        </InputLabel>
                                        <Select
                                            id="department"
                                            label="Department"
                                            name="department"
                                            value={
                                                patientState.department || ""
                                            }
                                            onChange={handleInputChange}
                                            disabled
                                        >
                                            {metaData?.result?.departments?.map(
                                                (department) => (
                                                    <MenuItem
                                                        key={department.id}
                                                        value={department.id}
                                                        className="capitalize"
                                                    >
                                                        {department.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        <FormHelperText>
                                            {valErr?.emptyFields?.includes(
                                                "department"
                                            )
                                                ? "Department is required"
                                                : undefined}
                                        </FormHelperText>
                                    </FormControl>
                                    <TextField
                                        required
                                        label="Charge"
                                        name="charge"
                                        value={patientState.charge || 0}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                        helperText={
                                            valErr?.zeroValueFields?.includes(
                                                "charge"
                                            )
                                                ? "Charge is required"
                                                : null
                                        }
                                        error={valErr?.zeroValueFields?.includes(
                                            "charge"
                                        )}
                                    />

                                    <TextField
                                        required
                                        label="Payable Amount"
                                        name="payable_amount"
                                        value={patientState.payable_amount || 0}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                        disabled
                                        helperText={
                                            valErr?.zeroValueFields?.includes(
                                                "payable_amount"
                                            )
                                                ? "Payable Amount is required"
                                                : null
                                        }
                                        error={valErr?.zeroValueFields?.includes(
                                            "payable_amount"
                                        )}
                                    />
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        error={valErr?.emptyFields?.includes(
                                            "payment_mode"
                                        )}
                                    >
                                        <InputLabel id="payment-mode-label">
                                            Payment Mode
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId="payment-mode-label"
                                            label="Payment Mode"
                                            name="payment_mode"
                                            value={
                                                patientState.payment_mode || ""
                                            }
                                            onChange={handleInputChange}
                                        >
                                            {paymentModes.map((mode) => (
                                                <MenuItem
                                                    key={mode}
                                                    value={mode}
                                                    className="capitalize"
                                                >
                                                    {mode}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            {valErr?.emptyFields?.includes(
                                                "payment_mode"
                                            )
                                                ? "Payment Mode is required"
                                                : undefined}
                                        </FormHelperText>
                                    </FormControl>

                                    <TextField
                                        required
                                        label="Payment Reference Number"
                                        name="payment_reference_number"
                                        value={
                                            patientState.payment_reference_number
                                        }
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Discount Amount"
                                        name="discount_amount"
                                        value={
                                            patientState.discount_amount || 0
                                        }
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                    <TextField
                                        required
                                        label="Discount Percentage"
                                        name="discount_percent"
                                        value={patientState.discount_percent}
                                        disabled
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Discount Remark"
                                        name="discount_remark"
                                        value={patientState.discount_remark}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                </div>
                                <div className="flex items-center justify-evenly">
                                    <button
                                        type="submit"
                                        className="w-[20%] bg-blue-600 hover:bg-blue-700 text-white py-2 px-5 rounded-3xl duration-300"
                                        onClick={handleOPDSubmit}
                                    >
                                        Submit
                                    </button>
                                    <button
                                        type="cancel"
                                        className="w-[20%] bg-red-600 hover:bg-red-700 text-white py-2 px-5 rounded-3xl duration-300"
                                        onClick={handleCloseOPD}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </FormControl>
                        </div>
                    </Box>
                </Fade>
            </Modal>

            <Modal
                open={openLab}
                onClose={handleCloseLab}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openLab}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-55%, -50%)",
                            minWidth: 800,
                            maxWidth: 1200,
                            minHeight: 200,
                            maxHeight: 650,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                            borderRadius: "5px",
                            overflowY: "scroll",
                            "&::-webkit-scrollbar": {
                                width: 0,
                            }, // Add this line for vertical scrolling
                        }}
                    >
                        <LabTestModal
                            labTest={labTest}
                            handleChange={handleChangeLab2}
                            handleChangeTests={handleChangeTests2}
                            handleClose={handlePTClose}
                            metaData={metaData}
                            handleAdd={handleAddLabTest}
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default Patients;
