import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { FaRegEye } from "react-icons/fa";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import {
    useAddIPDDeposit,
    useDeleteIPDDeposit,
    useGetIPDDeposit,
    useUpdateIPDDeposit,
} from "../../../query/hooks/ipd/deposit";
import { useParams } from "react-router-dom";
import { useGetIPD } from "../../../query/hooks/ipd/ipds";
import { showNotification } from "../../../services/helper";
import CommonDeleteModal from "../CommonDeleteModal";
import ReusableButton from "../ReusableButton";
import RedButton from "../RedButton";
import {
    Backdrop,
    Box,
    Fade,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from "@mui/material";
import { useGetMetaData } from "../../../query/hooks/metadata/metadataHook";
import { IoMdAdd } from "react-icons/io";
import dayjs from "dayjs";


var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
const columns = [
    {
        id: 2,
        label: "Deposit Amount",
        align: "center",
        width: 190,
    },
    {
        id: 3,
        label: "Payment Mode",
        align: "center",
        minWidth: 120,
    },

    {
        id: 5,
        label: "Paid On",
        align: "center",
        width: 190,
    },
    {
        id: 6,
        label: "Action",
        align: "center",
        minWidth: 120,
    },
];

const Deposits = () => {
    const params = useParams();
    const { metaData } = useGetMetaData();
    const [dept, setDept] = useState({
        amount: 0,
        payment_mode: "",
    });
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [toDelete, setToDelete] = useState("");

    const { ipdData, isLoading: isPtLoading } = useGetIPD(params.id);
    const { ipdDepositData, isLoading, refetch, isSuccess } = useGetIPDDeposit(
        params.id
    );
    const { addIPDDeposit, isLoading: isAdding } = useAddIPDDeposit();
    const { updateIPDDeposit, isLoading: isUpdating } = useUpdateIPDDeposit();
    const { deleteIPDDeposit, isLoading: isDeleting } = useDeleteIPDDeposit();
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setDept({
            amount: 0,
            payment_mode: "",
        });

        setOpen(false);
    };

    const style = {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        height: "auto",

        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "5px",
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteConfirmed = () => {
        deleteIPDDeposit(toDelete);
        handleCloseDeleteDialog();
    };
    const handleOpenDeleteDialog = (row) => {
        setOpenDeleteDialog(true);
        setToDelete(row);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "amount") {
            const parsedValue = !isNaN(parseFloat(value))
                ? parseFloat(value)
                : 0;
            setDept((prevService) => ({
                ...prevService,
                [name]: parsedValue,
            }));
        } else {
            setDept((prevService) => ({
                ...prevService,
                [name]: value,
            }));
        }
    };
    const handlePaymentModeChange = (event) => {
        const selectedMode = event.target.value;
        setDept((prevDept) => ({
            ...prevDept,
            payment_mode: selectedMode.toLowerCase(),
        }));
    };
    const handleAdd = () => {
        const parsedAmount = parseInt(dept.amount);
        const parsedPaymentMode = dept.payment_mode;

        if (
            isNaN(parsedAmount) || // Check if amount is NaN
            parsedAmount === 0 || // Check if amount is 0
            !parsedPaymentMode // Check if payment_mode is null, undefined, or empty string
        ) {
            return showNotification(
                "error",
                "Check the Entered Deposit Details"
            );
        }

        const parsedState = {
            amount: parsedAmount,
            payment_mode: parsedPaymentMode,
        };

        addIPDDeposit({ depositID: ipdData?.result?.id, body: parsedState });
        if (!isAdding) {
            handleClose();
        }
    };

    return (
        <>
            <Tooltip title="Add Deposit" placement="bottom-start">
                <button
                    onClick={handleOpen}
                    className="inline-flex items-center px-4 py-2 mb-3 text-base font-semibold text-white bg-blue-700 border border-transparent rounded-md shadow-xl cursor-pointer hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-700"
                >
                    <IoMdAdd size={18} />
                </button>
            </Tooltip>
            <Paper
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: "15px",
                }}
                elevation={24}
            >
                <TableContainer
                    component={Paper}
                    sx={{
                        height: 520,
                        overflowX: "scroll",
                        "&::-webkit-scrollbar": {
                            width: 0,
                        },
                    }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            paddingX: column.paddingX,
                                        }}
                                        style={{
                                            minWidth: column.minWidth,
                                            width: column.width,
                                            maxWidth: column.maxWidth,
                                            fontWeight: "bold",
                                            background: "#eeeeee",
                                            textTransform: "uppercase",
                                            backgroundColor: "#27272A",
                                            color: "white",
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ipdDepositData?.result?.list?.map(
                                (item, index) => (
                                    <TableRow
                                        key={item.id}
                                        style={{
                                            backgroundColor:
                                                index % 2 === 0
                                                    ? "#E4E4E7"
                                                    : "white",
                                        }}
                                    >
                                        <TableCell align="center">
                                            ₹{""} {item.amount}
                                        </TableCell>
                                        <TableCell align="center" className="capitalize">
                                            {item.payment_mode}
                                        </TableCell>
                                        <TableCell align="center">
                                            {dayjs(item.created_on).format(
                                                "DD-MM-YYYY,h:mm:ss A"
                                            )}
                                        </TableCell>
                                        <TableCell align="center">
                                            <div className="flex items-center justify-center space-x-4">
                                                <Tooltip
                                                    title="Delete"
                                                    placement="bottom-start"
                                                >
                                                    <button
                                                        onClick={() =>
                                                            handleOpenDeleteDialog(
                                                                item.id
                                                            )
                                                        }
                                                        className="px-3 py-2 duration-300 rounded-lg group hover:bg-red-500"
                                                    >
                                                        <AiOutlineDelete className="text-xl text-red-500 duration-300 rounded-md group-hover:text-white" />
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <>
                            <section className="flex flex-col items-center justify-evenly">
                                <TextField
                                    sx={{
                                        width: "80%",
                                    }}
                                    margin="dense"
                                    id="ot_procedure"
                                    name="amount"
                                    label="Amount"
                                    type="text"
                                    fullWidth
                                    value={dept.amount}
                                    onChange={handleChange}
                                />
                                <FormControl
                                    sx={{
                                        width: "80%",
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                >
                                    <InputLabel id="payment-mode-label">
                                        Payment Mode
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId="payment-mode-label"
                                        label="Payment Mode"
                                        name="payment_mode"
                                        value={dept.payment_mode || ""} // Convert to lowercase and then capitalize
                                        onChange={handlePaymentModeChange}
                                    >
                                        {metaData?.result?.payment_modes?.map(
                                            (mode) => (
                                                <MenuItem
                                                    key={mode}
                                                    value={mode}
                                                    className="capitalize"
                                                >
                                                    {mode
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        mode.slice(1)}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </section>
                            <section className="flex py-5 justify-evenly">
                                <ReusableButton
                                    title="Save"
                                    onClick={handleAdd}
                                />
                                <RedButton
                                    title="Cancel"
                                    onClick={handleClose}
                                />
                            </section>
                        </>
                    </Box>
                </Fade>
            </Modal>
            <CommonDeleteModal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                onConfirm={handleDeleteConfirmed}
                isDeleting={isDeleting}
            />
        </>
    );
};

export default Deposits;
