import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import { useGetIPD } from "../../query/hooks/ipd/ipds";
import { useGetMetaData } from "../../query/hooks/metadata/metadataHook";
import { useGetIPDBeds } from "../../query/hooks/ipd/bedHistoryHook";
import { useGetIPDDeposit } from "../../query/hooks/ipd/deposit";
import { useGetLabInventory } from "../../query/hooks/ipd/labInvestigation";
import { useGetIPDOT } from "../../query/hooks/ipd/ots";
import { useGetIPDRefund } from "../../query/hooks/ipd/refund";
import { useGetIPDService } from "../../query/hooks/ipd/ipdServices";
import {
    getItem,
    getStartingTimeOfDate,
    showNotification,
} from "../../services/helper";
import { useAddBill, useGetBill } from "../../query/hooks/ipd/bill";
import dayjs from "dayjs";
import {
    Avatar,
    Backdrop,
    Box,
    Divider,
    Fade,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import ReactDatePicker from "react-datepicker";
import ReusableButton from "./ReusableButton";
import RedButton from "./RedButton";
import { useDispatch, useSelector } from "react-redux";
import {
    selectCurrentClinic,
    setClinic,
} from "../../redux/features/auth/authSlice";
import { skynet } from "../../services/api";
import { useGetClinic } from "../../query/hooks/clinic/clinicHook";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const OTheaders = [
    "OT Procedure",
    "OT Charge",
    "Surgeon Charge",
    "Assistant Surgeon Charge",
    "PWF Charge",
    "Anaesthesia Charge",
];
const totalHeaders = ["Charge Types", "Totals"];
const depositheaders = ["Created On", "Amount", "Payment Mode"];
const serviceHeaders = ["Service Charge", "Quantity", "Service Name"];
const columnsBeds = [
    { id: "bed_number", label: "Bed Number" },
    { id: "bed_charge", label: "Bed Charge" },
    { id: "nurse_charge", label: "Nurse Charge" },
];
const refundHeaders = [
    { id: "created_on", label: "Created On" },
    { id: "refund_amount", label: "Refunded Amount" },
];
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-55%, -50%)",
    minWidth: 800,
    maxWidth: 1200,
    minHeight: 200,
    maxHeight: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "2px",
    p: 4,
    borderRadius: "5px",
    "&::-webkit-scrollbar": {
        width: 0,
    }, // Add this line for vertical scrolling
};
const IPDFinalBill = ({ billData, isBillModalOpen, closeBillModal }) => {
    const dispatch = useDispatch();

    const currentClinic = useSelector(selectCurrentClinic);

    const { clinicData, isLoading } = useGetClinic();
    console.log("clinicData", clinicData);
    useEffect(() => {
        if (!currentClinic) {
            dispatch(setClinic(clinicData?.result));
        }
    }, [clinicData]);

    const billRef = useRef();
    const params = useParams();
    const { ipdData, isLoading: isPtLoading } = useGetIPD(billData?.ipd);
    const {
        summaryData,
        isLoading: isFetching,
        refetch,
    } = useGetBill(billData.id);

    const [bedtotal, setBedTotal] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [totalOt, setTotalOt] = useState(0);
    const [totalInvestigationCharge, setTotalInvestigationCharge] = useState(0);
    const [totalService, setTotalService] = useState(0);
    const [totalRefunds, setTotalRefunds] = useState(0);

    useEffect(() => {
        if (summaryData?.result?.beds) {
            const bedChargeTotal = summaryData?.result?.beds?.reduce(
                (acc, bed) => acc + bed.bed_charge + bed.nurse_charge,
                0
            );
            setBedTotal(bedChargeTotal);
        }
    }, [summaryData, params]);

    useEffect(() => {
        if (summaryData?.result?.ots) {
            const otTotal = summaryData?.result?.ots?.reduce(
                (acc, ot) =>
                    acc +
                    ot.ot_charge +
                    ot.surgeon_charge +
                    ot.assistant_surgeon_charge +
                    ot.pwf_charge +
                    ot.anaesthesia_charge,
                0
            );
            setTotalOt(otTotal);
        }
    }, [summaryData, params]);

    useEffect(() => {
        if (summaryData?.result?.services?.length > 0) {
            const serviceTotal = summaryData?.result?.services?.reduce(
                (acc, item) => {
                    return acc + item.service_charge * item.quantity;
                },
                0
            );
            // const serviceTotal = ipdServiceData.result.list.reduce(
            //     (acc, service) => acc + service.service_charge,
            //     0
            // );
            setTotalService(serviceTotal);
        }
    }, [summaryData, params]);

    useEffect(() => {
        if (summaryData?.result?.tests?.length > 0) {
            const investigationTotal = summaryData?.result?.tests?.reduce(
                (acc, investigation) => acc + investigation.test_charge,
                0
            );
            setTotalInvestigationCharge(investigationTotal);
        }
    }, [summaryData, params]);

    useEffect(() => {
        if (summaryData?.result?.deposits) {
            const depositTotal = summaryData?.result?.deposits?.reduce(
                (acc, deposit) => acc + deposit.amount,
                0
            );
            setTotalDeposit(depositTotal);
        }
    }, [summaryData?.result, params]);

    useEffect(() => {
        if (summaryData?.result?.refunds) {
            const refundTotal = summaryData?.result?.refunds.reduce(
                (acc, refunds) => acc + refunds.refunded_amount,
                0
            );
            setTotalRefunds(refundTotal);
        }
    }, [summaryData, params]);

    return (
        <>
            <Modal
                open={isBillModalOpen}
                onClose={closeBillModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isBillModalOpen}>
                    <Box sx={style}>
                        <div className="flex flex-col items-center ">
                            <section className="overflow-y-auto max-h-[550px] border rounded-sm border-slate-600 custom-scrollbar">
                                <div
                                    ref={billRef}
                                    id="opdbill"
                                    className="w-[210mm] min-h-[297mm] px-[8mm] py-[4mm] bg-white border "
                                >
                                    <div className="flex space-x-3 border-b-4 border-b-black w-full">
                                        {/* <Avatar
                                            className="w-[200px] object-center object-contain"
                                            src={`${skynet}/${clinicData?.result?.logo}`}
                                            alt="logo"
                                            variant="square"
                                        /> */}
                                        <img
                                            className="w-[60px] object-contain"
                                            src={`${skynet}/${clinicData?.result?.logo}`}
                                            alt="logo"
                                        />
                                        {/* <img
                                            className="w-[60px] object-contain"
                                            crossorigin="anonymous"
                                            src={`${skynet}/${clinicData?.result?.logo}`}
                                            alt=""
                                        /> */}
                                        <div className="flex justify-between w-full">
                                            <div className="flex flex-col items-start justify-start flex-1">
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {clinicData?.result?.name}
                                                </Typography>

                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    {clinicData?.result?.email}
                                                </Typography>

                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    {
                                                        clinicData?.result
                                                            ?.address
                                                    }
                                                </Typography>

                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    {clinicData?.result?.city},{" "}
                                                    {clinicData?.result?.state},{" "}
                                                    {
                                                        clinicData?.result
                                                            ?.pincode
                                                    }
                                                </Typography>
                                            </div>
                                            <div className="flex flex-col items-start justify-end w-[35%]">
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    <span className="font-medium">
                                                        GSTIN:
                                                    </span>{" "}
                                                    {clinicData?.result?.gst}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontWeight: "9px",
                                                    }}
                                                >
                                                    <span className="font-medium">
                                                        Reg No:
                                                    </span>{" "}
                                                    {clinicData?.result?.reg_no}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="space-y-4">
                                        <section className="pt-3">
                                            <Typography variant="h6" pt={1}>
                                                Bed Charges
                                            </Typography>
                                            <Divider />
                                            <TableContainer
                                                sx={{
                                                    height: "auto",
                                                }}
                                            >
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {columnsBeds.map(
                                                                (column) => (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align="center"
                                                                        // style={{
                                                                        //     fontWeight: "bold",
                                                                        //     background: "#eeeeee",
                                                                        //     textTransform: "uppercase",
                                                                        //     backgroundColor: "#27272A",
                                                                        //     color: "white",
                                                                        // }}
                                                                    >
                                                                        {
                                                                            column.label
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {summaryData?.result?.beds?.map(
                                                            (item, index) => (
                                                                <TableRow
                                                                    key={
                                                                        item?.id
                                                                    }
                                                                >
                                                                    <TableCell
                                                                        key={
                                                                            item.id
                                                                        }
                                                                        align="center"
                                                                    >
                                                                        {
                                                                            item.bed_number
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        key={
                                                                            item.id
                                                                        }
                                                                        align="center"
                                                                    >
                                                                        {
                                                                            item.bed_charge
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        key={
                                                                            item.id
                                                                        }
                                                                        align="center"
                                                                    >
                                                                        {
                                                                            item.nurse_charge
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                rowSpan={2}
                                                            />
                                                            <TableCell
                                                                align="center"
                                                                colSpan={1}
                                                            >
                                                                Total Bed Charge
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {`₹ ${bedtotal}/-`}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section className="">
                                            <Typography variant="h6" pt={1}>
                                                Lab Investigations
                                            </Typography>{" "}
                                            <Divider />
                                            <TableContainer
                                                sx={{
                                                    height: "auto",
                                                }}
                                            >
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center">
                                                                Test Name
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                Quantity
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                Test Charge
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {summaryData?.result?.tests?.map(
                                                            (item, index) => (
                                                                <TableRow
                                                                    key={
                                                                        item?.id
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.test_name
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.quantity
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.test_charge
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                rowSpan={3}
                                                                colSpan={1}
                                                            />
                                                            <TableCell
                                                                align="center"
                                                                colSpan={1}
                                                            >
                                                                Total Lab
                                                                Investigation
                                                                Charge
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {`₹ ${totalInvestigationCharge}/-`}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section className="">
                                            <Typography variant="h6" pt={1}>
                                                OTs
                                            </Typography>
                                            <Divider />
                                            <TableContainer
                                                sx={{
                                                    height: "auto",
                                                }}
                                            >
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {OTheaders.map(
                                                                (
                                                                    header,
                                                                    index
                                                                ) => (
                                                                    <TableCell
                                                                        key={
                                                                            index
                                                                        }
                                                                        align="center"
                                                                    >
                                                                        {header}
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {summaryData?.result?.ots?.map(
                                                            (item, index) => (
                                                                <TableRow
                                                                    key={
                                                                        item?.id
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.ot_procedure
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.ot_charge
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.surgeon_charge
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.assistant_surgeon_charge
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.pwf_charge
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.anaesthesia_charge
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                rowSpan={5}
                                                                colSpan={4}
                                                            />
                                                            <TableCell
                                                                align="center"
                                                                colSpan={1}
                                                            >
                                                                Total OT Charge
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {`₹ ${totalOt}/-`}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section>
                                            <Typography variant="h6" pt={1}>
                                                Services
                                            </Typography>
                                            <Divider />
                                            <TableContainer
                                                sx={{
                                                    height: "auto",
                                                }}
                                            >
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {serviceHeaders.map(
                                                                (
                                                                    header,
                                                                    index
                                                                ) => (
                                                                    <TableCell
                                                                        key={
                                                                            index
                                                                        }
                                                                        align="center"
                                                                    >
                                                                        {header}
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {summaryData?.result?.services?.map(
                                                            (item, index) => (
                                                                <TableRow
                                                                    key={
                                                                        item?.id
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {item?.service_charge *
                                                                            item?.quantity}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.quantity
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.service_name
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                rowSpan={2}
                                                            />
                                                            <TableCell
                                                                align="center"
                                                                colSpan={1}
                                                            >
                                                                Total Service
                                                                Charge
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {`₹ ${totalService}/-`}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section>
                                            <Typography variant="h6" pt={1}>
                                                Deposits
                                            </Typography>{" "}
                                            <Divider />
                                            <TableContainer
                                                sx={{
                                                    height: "auto",
                                                }}
                                            >
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {depositheaders.map(
                                                                (
                                                                    header,
                                                                    index
                                                                ) => (
                                                                    <TableCell
                                                                        key={
                                                                            index
                                                                        }
                                                                        align="center"
                                                                    >
                                                                        {header}
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {/* {ipdDepositData?.result?.list?.map( */}
                                                        {summaryData?.result?.deposits?.map(
                                                            (item, index) => (
                                                                <TableRow
                                                                    key={
                                                                        item?.id
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {dayjs(
                                                                            item?.created_on
                                                                        ).format(
                                                                            "DD-MM-YYYY,h:mm:ss A"
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.amount
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.payment_mode
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                rowSpan={2}
                                                            />
                                                            <TableCell
                                                                align="center"
                                                                colSpan={1}
                                                            >
                                                                Total Deposits
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {`₹ ${totalDeposit}/-`}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Divider />
                                        </section>

                                        <section className="">
                                            <Typography variant="h6" pt={1}>
                                                Refunds
                                            </Typography>{" "}
                                            <Divider />
                                            <TableContainer
                                                sx={{
                                                    height: "auto",
                                                }}
                                            >
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {refundHeaders.map(
                                                                (column) => (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align="center"
                                                                    >
                                                                        {
                                                                            column.label
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {summaryData?.result?.refunds?.map(
                                                            (item, index) => (
                                                                <TableRow
                                                                    key={
                                                                        item?.id
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {dayjs(
                                                                            item?.created_on
                                                                        ).format(
                                                                            "DD-MM-YYYY,h:mm:ss A"
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            item?.refund_amount
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                        <TableRow>
                                                            <TableCell />
                                                            <TableCell align="center">
                                                                Total Refund
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {`₹ ${totalRefunds}/-`}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section className="">
                                            <Typography
                                                variant="h6"
                                                className="pt-2"
                                            >
                                                Total Charges
                                            </Typography>
                                            <Divider />
                                            <TableContainer
                                                sx={{
                                                    height: "auto",
                                                }}
                                            >
                                                <Table
                                                    size="small"
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {totalHeaders.map(
                                                                (
                                                                    column,
                                                                    index
                                                                ) => (
                                                                    <TableCell
                                                                        key={
                                                                            index
                                                                        }
                                                                        align="left"
                                                                    >
                                                                        {column}
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                Total Bed Charge
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {bedtotal}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                Total Deposits
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {totalDeposit}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                Total OT Charge
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {totalOt}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                Total Lab
                                                                Investigation
                                                                Charge
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {
                                                                    totalInvestigationCharge
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                Total Service
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {totalService}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                Total Refunds
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {totalRefunds}
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell align="left">
                                                                Total Charge
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {`₹ ${
                                                                    totalService +
                                                                    bedtotal +
                                                                    totalOt +
                                                                    totalRefunds +
                                                                    totalInvestigationCharge -
                                                                    totalDeposit
                                                                }/-`}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </section>

                                        <section className="w-full p-5 h-fit">
                                            <div className="flex justify-between item-center">
                                                <section className="flex flex-col ">
                                                    <Typography>
                                                        Total Amount:{" "}
                                                        {billData.total_amount}
                                                    </Typography>

                                                    <Typography>
                                                        Deposit Amount:{" "}
                                                        {
                                                            billData.deposit_amount
                                                        }
                                                    </Typography>

                                                    <Typography>
                                                        Refund Amount:{" "}
                                                        {billData.refund_amount}
                                                    </Typography>
                                                    <Typography>
                                                        Payment Mode:{" "}
                                                        {billData.payment_mode}
                                                    </Typography>
                                                </section>
                                                <section className="flex flex-col ">
                                                    <Typography>
                                                        Discount Amount:{" "}
                                                        {
                                                            billData.discount_amount
                                                        }
                                                    </Typography>

                                                    <Typography>
                                                        Payable Amount:{" "}
                                                        {
                                                            billData.payable_amount
                                                        }
                                                    </Typography>

                                                    <Typography>
                                                        Payment Reference
                                                        Number:{" "}
                                                        {
                                                            billData.payment_reference_number
                                                        }
                                                    </Typography>

                                                    <Typography>
                                                        Created On:{" "}
                                                        {dayjs(
                                                            billData?.created_on
                                                        ).format(
                                                            "DD-MM-YYYY,h:mm:ss A"
                                                        )}
                                                    </Typography>
                                                </section>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </section>
                            <div className="w-[210mm] flex space-x-10 items-center pt-4">
                                <button
                                    onClick={useReactToPrint({
                                        content: () => billRef.current,
                                    })}
                                    className={`text-sm bg-green-500 text-white px-3 py-1.5 rounded shadow-lg-md`}
                                >
                                    Print
                                </button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default IPDFinalBill;
