import React from "react";
import { Modal, Fade, Box } from "@mui/material";
const defaultContainerStyle = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};
const CustomModal = ({ open, onClose, children, containerStyle = {} }) => {
    return (
        <Modal open={open} onClose={onClose} closeAfterTransition>
            <Fade in={open}>
                <Box sx={{ ...defaultContainerStyle, ...containerStyle }}>
                    {children}
                </Box>
            </Fade>
        </Modal>
    );
};

export default CustomModal;
