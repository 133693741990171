import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    defaultParams: {
        name: "",
        offset: 0,
        limit: 10
    },
};

const motFilterSlice = createSlice({
    name: "motFilters",
    initialState,
    reducers: {
        setMotParams: (state, action) => {
            state.defaultParams = action.payload;
        },
        clearMot: (state) => {
            return initialState;
        },
    },
    extraReducers: {},
});

export const selectMotParams = (state) => state.motFilters.defaultParams;

export const { setMotParams, clearMot } = motFilterSlice.actions;
export default motFilterSlice.reducer;
