import React, { useEffect, useState } from "react";
import { getUser, skynet, uploadFile } from "../services/api";
import { getItem, showNotification } from "../services/helper";
import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    Modal,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { MdEdit } from "react-icons/md";
import { useEditUser } from "../query/hooks/users/usersHook";
import EditIcon from "@mui/icons-material/Edit";
import ReusableButton from "./common/ReusableButton";
import RedButton from "./common/RedButton";
import LoaderLogo from "./common/LoaderLogo";

const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
        width: 0,
    },
};
const Profile = () => {
    const { editUser, isLoading: isEditingUser } = useEditUser();
    const [currentUser, setCurrentUser] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [image, setImage] = useState(null);
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const [isUploading, setIsUploading] = useState(false);


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        previewImage(file);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        previewImage(file);
    };

    const previewImage = (file) => {
        if (file && file.type && /^image\/(jpeg|jpg|png)$/.test(file.type)) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setSelectedImage(null);
            showNotification(
                "error",
                "Please select a valid JPEG, JPG, or PNG image file."
            );
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const uploadImage = async () => {
        if (!image) return;

        try {
            setIsUploading(true);
            const formData = new FormData();
            formData.append("file", image);
            const response = await uploadFile(formData);
            if (response.status === 200 && response.data.success) {
                setUploadedImageUrl(response.data.result);
                const updatedUser = {
                    ...currentUser,
                    image: response.data.result,
                };
                editUser(updatedUser);
                setIsUploading(false);
                setSelectedImage(null);
                handleClose();
                showNotification("success", "Image uploaded successfully");
            }
        } catch (error) {
            console.error(error);
            setIsUploading(false);
            showNotification("error", "Cannot Upload Image");
        }
    };

    const [editMode, setEditMode] = useState({
        name: false,
        email: false,
        mobile: false,
    });

    const [editedFields, setEditedFields] = useState({
        name: currentUser.name || "",
        email: currentUser.email || "",
        mobile: currentUser.mobile || "",
    });

    const handleEditEnable = (fieldName) => {
        setEditMode((prevEditMode) => ({
            ...Object.fromEntries(
                Object.entries(prevEditMode).map(([key]) => [
                    key,
                    key === fieldName,
                ])
            ),
        }));
    };
    const isNullOrEmptyOrNaN = (value) => {
        return (
            value === null ||
            value === undefined ||
            (typeof value === "number" && isNaN(value)) ||
            value.trim() === ""
        );
    };

    const validateFields = () => {
        for (const key in editedFields) {
            if (
                editedFields.hasOwnProperty(key) &&
                isNullOrEmptyOrNaN(editedFields[key])
            ) {
                return false; // Validation failed
            }
        }
        return true; // Validation passed
    };
    const handleSave = () => {
        const updatedUser = {
            id: currentUser.id,
            created_on: currentUser.created_on,
            name: editedFields.name,
            email: editedFields.email,
            mobile: editedFields.mobile,
            role: currentUser.role,
            entity: currentUser.entity,
            image: currentUser.image,
            role_name: currentUser.role_name,
            entity_name: currentUser.entity_name,
        };
        // Implement your logic to save the edited fields
        const isFieldsValid = validateFields();
        if (isFieldsValid) {
            editUser(updatedUser);
            setEditMode({
                name: false,
                email: false,
                mobile: false,
            });
        } else {
            showNotification("warning", "Please fill in all fields");
        }
    };

    const handleChange = (fieldName, value) => {
        setEditedFields((prevEditedFields) => ({
            ...prevEditedFields,
            [fieldName]: value,
        }));
    };
    useEffect(() => {
        const user = getItem("user");
        const fetchUserData = async () => {
            if (user) {
                try {
                    const res = await getUser(user.id);
                    if (res.success) {
                        setCurrentUser(res.result);
                        setEditedFields({
                            name: res.result.name,
                            email: res.result.email,
                            mobile: res.result.mobile,
                        });
                    } else {
                        showNotification("warning", "User Not Found");
                    }
                } catch (error) {
                    console.error(error);
                }
            } else {
                console.log("err");
            }
        };
        fetchUserData();
        return;
    }, []);
    const [isHovered, setIsHovered] = useState(false);

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setImage(null);
        setSelectedImage(null);
        setOpen(false);
    };

    return (
        <>
            <Container
                component="main"
                maxWidth="sm"
                sx={{
                    mt: "5px",
                }}
            >
                <Paper
                    elevation={3}
                    style={{
                        height: "auto",
                        padding: 12,
                        mt: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography component="h1" variant="h5" gutterBottom>
                        Profile
                    </Typography>
                    <div
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        className="relative inline-block"
                    >
                        <Avatar
                            alt="profile"
                            // src={editedFields.image || ""}
                            src={`${skynet}/${currentUser?.image}` || null}
                            sx={{ width: 156, height: 156 }}
                            className="object-contain"
                        />
                        {isHovered && (
                            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full transition-opacity duration-300 bg-black bg-opacity-50 rounded-full">
                                <Tooltip
                                    title="Upload New Image"
                                    placement="bottom-start"
                                >
                                    <EditIcon
                                        onClick={handleOpen}
                                        style={{ color: "white", fontSize: 42 }}
                                        className="duration-300 cursor-pointer"
                                    />
                                </Tooltip>
                            </div>
                        )}
                    </div>
                    <div className="flex items-center justify-around w-full gap-5 mt-5">
                        <div className="space-y-4">
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Name"
                                value={editedFields.name}
                                disabled={!editMode.name}
                                InputProps={{
                                    endAdornment: !editMode.name ? (
                                        <IconButton
                                            onClick={() =>
                                                handleEditEnable("name")
                                            }
                                        >
                                            <MdEdit />
                                        </IconButton>
                                    ) : null,
                                }}
                                onChange={(e) =>
                                    handleChange("name", e.target.value)
                                }
                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Email"
                                value={editedFields.email}
                                disabled={!editMode.email}
                                InputProps={{
                                    endAdornment: !editMode.email ? (
                                        <IconButton
                                            onClick={() =>
                                                handleEditEnable("email")
                                            }
                                        >
                                            <MdEdit />
                                        </IconButton>
                                    ) : null,
                                }}
                                onChange={(e) =>
                                    handleChange("email", e.target.value)
                                }
                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Mobile"
                                value={editedFields.mobile}
                                disabled={!editMode.mobile}
                                InputProps={{
                                    endAdornment: !editMode.mobile ? (
                                        <IconButton
                                            onClick={() =>
                                                handleEditEnable("mobile")
                                            }
                                        >
                                            <MdEdit />
                                        </IconButton>
                                    ) : null,
                                }}
                                onChange={(e) =>
                                    handleChange("mobile", e.target.value)
                                }
                            />
                        </div>
                        <div className="space-y-4">
                            <TextField
                                variant="outlined"
                                fullWidth
                                disabled
                                label="Role"
                                value={currentUser.role_name || ""}
                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                disabled
                                label="Entity"
                                value={currentUser.entity_name || ""}
                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Created On"
                                disabled
                                value={currentUser.created_on || ""}
                            />
                        </div>
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            mt: 2,
                        }}
                        onClick={handleSave}
                        disabled={Object.values(editMode).every(
                            (value) => !value
                        )}
                    >
                        Update Profile
                    </Button>
                </Paper>
            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography textAlign="center" variant="h5">
                        Upload New Profile
                    </Typography>
                    {isUploading ? (
                        <div className="flex items-start justify-center mt-8">
                            <LoaderLogo />
                        </div>
                    ) : (
                        <>
                            <div className="flex flex-wrap items-center gap-4">
                                <div
                                    className="p-4 border w-[200px] h-[200px] border-gray-400 border-dashed rounded-lg cursor-pointer"
                                    onClick={() => {
                                        document
                                            .getElementById("fileInput")
                                            .click();
                                    }}
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                >
                                    <input
                                        type="file"
                                        accept="image/jpeg,image/jpg,image/png"
                                        onChange={handleImageChange}
                                        id="fileInput"
                                        className="hidden"
                                    />
                                    <p className="text-center">
                                        Click to open file manager or drag &
                                        drop file here
                                    </p>
                                </div>
                                {selectedImage && (
                                    <div className="flex items-center justify-center p-2">
                                        <img
                                            src={selectedImage}
                                            alt="Selected"
                                            className="object-contain object-center w-[200px]"
                                            // style={{ w-[200px] }}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="flex items-center justify-around my-5">
                                <ReusableButton
                                    title="Upload"
                                    onClick={uploadImage}
                                    isDisabled={isUploading || !selectedImage}
                                />
                                <RedButton
                                    title="Cancel"
                                    onClick={handleClose}
                                />
                            </div>
                        </>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default Profile;
